import { ComponentType, useContext, useState } from 'react';
import {
  Window,
  WindowActionsBar,
  WindowProps
} from '@progress/kendo-react-dialogs';
import { FluidForm } from '../../../components/forms';
import { AssessmentEditor } from './assessment-editor';
import {
  AssessmentEntity,
  ResourceEntity
} from '../../../types/game-document/';
import { InputChangeEvent } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { EditorMode } from '../../../types/editor-mode';
import { EntityEditor } from '../../../types/game-document/entity-editor';
import { GameDocumentContext } from '../../../contexts/game-document';

interface AssessmentEditorWindowProps extends WindowProps {
  editorEntity: EntityEditor<AssessmentEntity>;
  editorResource: EntityEditor<ResourceEntity>[];
  toggleDialog: Function;
  onSubmit: (
    editorEntity: EntityEditor<AssessmentEntity>,
    resourceEntity: EntityEditor<ResourceEntity>[]
  ) => void;
  editorMode: EditorMode;
}

export interface RequiredInput {
  name: string;
  errorMessage: string;
}

export const AssessmentEditorWindow: ComponentType<
  AssessmentEditorWindowProps
> = ({
  editorEntity,
  editorResource,
  toggleDialog,
  onSubmit,
  editorMode,
  ...props
}: AssessmentEditorWindowProps) => {
  const [entity, setEntity] = useState({ ...editorEntity.entity });
  const [state, setState] = useContext(GameDocumentContext);
  const [resources, setResources] = useState<ResourceEntity[]>([
    ...editorResource.map((item) => item.entity)
  ]);
  const [requiredInputs, setRequiredInputs] = useState<RequiredInput[]>([
    { name: 'name', errorMessage: '' }
  ]);

  const handleInputChange = (event: InputChangeEvent) => {
    setEntity({ ...entity, [event.target.name!]: event.target.value });
  };

  const isInputValid = (): boolean => {
    const inputValidation = requiredInputs.map((input) => {
      const entityValue = entity[input.name as keyof AssessmentEntity];
      const itemIndex = state.gameDocument?.assets?.assessments?.findIndex(
        (item) => item.name === entityValue
      );

      if (input.name === 'name') {
        if (entityValue === '') {
          input.errorMessage = 'This field is required';
        } else if (
          itemIndex !== -1 &&
          state.gameDocument?.assets?.assessments![itemIndex!]?.id !== entity.id
        ) {
          input.errorMessage = 'Please enter unique name';
        } else {
          input.errorMessage = '';
        }
      }

      return input;
    });
    setRequiredInputs(inputValidation);
    return (
      inputValidation.findIndex((input) => input.errorMessage !== '') === -1
    );
  };

  return (
    <>
      <Window
        modal={true}
        minimizeButton={undefined}
        className={'shadow'}
        initialWidth={500}
        initialHeight={300}
        title={editorEntity.isNew ? 'New assessment' : editorEntity.entity.name}
        {...props}>
        <FluidForm>
          <AssessmentEditor
            editorMode={editorMode}
            entity={entity}
            resources={resources}
            handleInputChange={handleInputChange}
            requiredInputs={requiredInputs}></AssessmentEditor>
        </FluidForm>
        <WindowActionsBar>
          <Button themeColor={'secondary'} onClick={() => toggleDialog()}>
            Cancel
          </Button>
          <Button
            themeColor={'primary'}
            onClick={() => {
              if (isInputValid()) {
                const entityResources = resources.map((item, index) => ({
                  isNew: editorResource[index].isNew,
                  entity: item
                })) as EntityEditor<ResourceEntity>[];
                onSubmit(
                  { isNew: editorEntity.isNew, entity },
                  entityResources
                );
              }
            }}>
            Save
          </Button>
        </WindowActionsBar>
      </Window>
    </>
  );
};
