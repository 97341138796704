import merge from 'lodash.merge';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import YesNoDialog from '../../components/dialogs/yes-no-dialog';
import Toolbar from '../../components/page-toolbar';
import { PrivatePage } from '../../components/private-page';
import { FeedbackContentFullEditor } from '../../features/feedback/feedback-content-full-editor';
import { GetLanguagesAsync } from '../../services/country';
import {
  getDocumentLatestAsync,
  getDocumentsByFeedbackIdAsync,
  getFeedbackById,
  publishFeedbackAsync
} from '../../services/feedback';
import { appStore } from '../../stores/app-store';
import { toastStore } from '../../stores/toast-store';
import { YesNoDialogResult } from '../../types/dialog-result';
import { Feedback, FeedbackApproval } from '../../types/feedback';

const FeedbackDetailView = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [entity, setEntity] = useState<Feedback>();
  const [languages, setLanguages] = useState<string[]>([]);
  const [, setSelectedTab] = useState<string>('');
  const [openApprovalDialog, setOpenApprovalDialog] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState<string>('');
  const [confirmMessage, setConfirmMessage] = useState<string>();
  const [confirmTitle, setConfirmTitle] = useState<string>();
  const detailFeedbackBreadcrumb = [
    { label: 'Dashboard', href: 'dashboard' },
    { label: 'Administration', href: '../administration' },

    {
      label: 'Pending Approval',
      href: '../administration'
    },
    {
      label: 'Feedback Approval',
      href: 'administration/feedbacks/pending'
    },
    {
      label: 'Feedback Details',
      href: `administration/feedback-detail-view/${id}`
    }
  ];

  const getDetails = async () => {
    try {
      appStore.showLoading();

      // combine feedback and feedback documents (resources)
      let feed1 = await getFeedbackById(parseInt(id!));
      let feed2 = await getDocumentsByFeedbackIdAsync(parseInt(id!));

      let feedback = merge(feed1, feed2);

      setEntity(feedback);
    } catch (ex) {
      console.error(ex);
    } finally {
      appStore.hideLoading();
    }
  };

  const getLanguages = async () => {
    const response = await GetLanguagesAsync();
    const languages: string[] = [];
    response.data.forEach((f) => {
      if (!languages.some((c) => c === f.language)) {
        languages.push(f.language!);
      }
    });
    setLanguages(languages);
  };

  const showApproveSectionDialog = (approval: 'Approved' | 'Rejected') => {
    setApprovalStatus(approval);
    if (approval === 'Approved') {
      setConfirmTitle('Approve feedback');
      setConfirmMessage('Are you sure you want to approve this feedback?');
    } else {
      setConfirmTitle('Reject feedback');

      setConfirmMessage('Are you sure you want to reject this feedback?');
    }
    setOpenApprovalDialog(true);
  };

  const confirmApproveSectionDialog = async (result: YesNoDialogResult) => {
    if (result === 'yes') {
      try {
        let document = await getDocumentLatestAsync(parseInt(id!));
        let request: FeedbackApproval = {
          fileName: document?.fileName ?? '',
          version: document?.version ?? '',
          status: approvalStatus,
          isGlobal: true,
          isDeleted: false
        };

        let result = await publishFeedbackAsync(
          parseInt(id!),
          document.id,
          request
        );
        if (entity) {
          let newEntity = { ...entity };
          if (newEntity) {
            newEntity.status = result.status;
            setEntity(newEntity!);
          }
        }
        toastStore.show(
          'Feedback approval',
          <div>{`Feedback approved.`}.</div>,
          'success'
        );
        navigate(`../feedbacks/pending`);
      } catch (e: any) {
        toastStore.show(
          'Feedback approval',
          `Feedback cannot be approved.`,
          'error'
        );
      }
    }
    setOpenApprovalDialog(false);
  };
  const toggleLanguageTab = (activeTab: string) => {
    if (activeTab === 'language') {
      setSelectedTab(activeTab);
    } else {
      setSelectedTab('');
    }
  };

  useEffect(() => {
    if (id) {
      getDetails();
    }
    getLanguages();
  }, []);

  return (
    <PrivatePage breadcrumb={detailFeedbackBreadcrumb} pageTitle={'Feedback'}>
      <>
        <Toolbar title={id ? entity?.name ?? '' : 'New Feedback'}></Toolbar>
        <hr />
        <Container>
          <Row>
            <Col>
              {entity && (
                <FeedbackContentFullEditor
                  languages={languages}
                  entity={entity}
                  isViewMode={true}
                  toggleLanguageTab={toggleLanguageTab}
                />
              )}
            </Col>
          </Row>
          <Row className={'mt-3'}>
            <Col md={6}>
              <Button
                variant={'danger'}
                className={'w-100 mt-1'}
                onClick={() => showApproveSectionDialog('Rejected')}>
                Reject
              </Button>
            </Col>
            <Col md={6}>
              <Button
                variant={'success'}
                className={'w-100 mt-1'}
                onClick={() => showApproveSectionDialog('Approved')}>
                Approve
              </Button>
            </Col>
          </Row>
        </Container>
      </>
      {openApprovalDialog && (
        <YesNoDialog
          title={confirmTitle}
          onConfirm={confirmApproveSectionDialog}
          onClose={() => setOpenApprovalDialog(false)}>
          {confirmMessage}
        </YesNoDialog>
      )}
    </PrivatePage>
  );
};

export default FeedbackDetailView;
