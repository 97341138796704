import { useAuth } from 'react-oidc-context';
import MenuItem from '../components/menu-item';
import { Permission } from '../types/permission';

/**
 * The menu used for the administration area layouts.
 */
const AdministrationMenu = () => {
  const auth = useAuth();
  const userRole = auth.user?.profile?.role as string[];
  const isAdminLanguagesRole = userRole.includes('Admin.Languages') ?? false;
  const isAdminPendingApprovalRole =
    userRole.includes('Admin.PendingApproval') ?? false;

  const isOnlyLanguagesGlobal =
    isAdminLanguagesRole && isAdminPendingApprovalRole ? false : true;

  return (
    <aside className={'app-side-nav overflow-scroll'}>
      <div className={'logo'} />
      <hr />
      {/* prettier-ignore */}
      <div className={'aside-menu'}>
        <MenuItem to={'/dashboard'} icon={'chevron_left'} label={'Back to dashboard'} className={'text-success'} />
      </div>
      <hr />
      {/* prettier-ignore */}
      <div className={'aside-menu'}>
        <MenuItem to={''} icon={'admin_panel_settings'} label={'Overview'} />
        <MenuItem to={'users'} icon={'group'} label={'All users'} requiredRole={Permission['Admin.Users']} />
        <MenuItem to={'organisations'} icon={'domain'} label={'Organisations'} requiredRole={Permission['Admin.Organisations']} />
        <MenuItem to={'events/manage'} icon={'calendar_month'} label={'Events'} requiredRole={Permission['Admin.Events']} />
       {!isOnlyLanguagesGlobal && 
         <MenuItem to={'#'} icon={'collections_bookmark'} label={'Global library'} requiredRole={Permission['Admin.PendingApproval']}>
            <MenuItem to={'games/manage'} icon={'collections_bookmark'} label={'Games'} requiredRole={Permission['Admin.PendingApproval']} />
            <MenuItem to={'assessments/manage'} icon={'library_add_check'} label={'Assessments'} requiredRole={Permission['Admin.PendingApproval']} />
            <MenuItem to={'feedbacks/manage'} icon={'insert_chart'} label={'Feedback'} requiredRole={Permission['Admin.PendingApproval']} />
            <MenuItem to={'languages/manage'} icon={'translate'} label={'Languages'} requiredRole={Permission['Admin.Languages']} />
          </MenuItem> 
        }
       {isOnlyLanguagesGlobal && 
          <MenuItem to={'#'} icon={'collections_bookmark'} label={'Global library'} requiredRole={Permission['Admin.Languages']}>
            <MenuItem to={'languages/manage'} icon={'translate'} label={'Languages'} requiredRole={Permission['Admin.Languages']} />
          </MenuItem> 
        }
        <MenuItem to={'#'} icon={'library_add_check'} label={'Pending approval'} requiredRole={Permission['Admin.PendingApproval']}>
          <MenuItem to={'globallibraries/pending'} icon={'library_add_check'} label={'Games'} requiredRole={Permission['Admin.PendingApproval']} />
          <MenuItem to={'assessments/pending'} icon={'library_add_check'} label={'Assessments'} requiredRole={Permission['Admin.PendingApproval']} />
          <MenuItem to={'feedbacks/pending'} icon={'library_add_check'} label={'Feedback'} requiredRole={Permission['Admin.PendingApproval']} />
        </MenuItem>
        <MenuItem to={'environments'} icon={'dns'} label={'Environments'} requiredRole={Permission['Admin.Environments']} />
        <MenuItem to={'support_tickets'} icon={'help_center'} label={'Support Tickets'} requiredRole={Permission['Admin.SupportTickets']} />
        <MenuItem to={'active-events'} icon={'event_available'} label={'Active Events'} requiredRole={Permission['Admin.ActiveEvents']} />
      </div>
    </aside>
  );
};

export default AdministrationMenu;
