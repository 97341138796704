import { GameDocument } from '../types/game-document';
import { ResourceEntity } from '../types/game-document/';
import { GetResourceEntity } from './game-document';
import { UploadedImage } from '../features/game-document/image-resource/resource-window';

/**
 * Returns the extension of a base64-encoded image.
 * @param {string} base64Image - The base64-encoded image data.
 * @returns {string} The extension of the image data, or an empty string if the extension is not supported.
 */
export const getBase64ImageExtension = (base64Image: string) => {
  const imageDataTypeExtensions = {
    'data:image/svg+xml': 'svg',
    'data:image/jpeg': 'jpg',
    'data:image/png': 'png',
    'data:image/gif': 'gif',
    'data:image/bmp': 'bmp'
  };
  const typeMatch = base64Image.match(/data:image\/[^;]+/);
  const type = typeMatch ? typeMatch[0] : '';
  // @ts-ignore This is an object accessor.
  return imageDataTypeExtensions[type] || '';
};

/**
 * Returns the extension of a base64-encoded video.
 * @param {string} base64Video - The base64-encoded video data.
 * @returns {string} The extension of the video data, or an empty string if the extension is not supported.
 */
export const getBase64VideoExtension = (base64Video: string) => {
  const videoDataTypeExtensions = {
    'data:video/webm': 'webm',
    'data:video/mov': 'mov',
    'data:video/mpeg': 'mpeg',
    'data:video/mp4': 'mp4',
    'data:video/avi': 'avi'
  };
  const typeMatch = base64Video.match(/data:video\/[^;]+/);
  const type = typeMatch ? typeMatch[0] : '';
  // @ts-ignore This is an object accessor.
  return videoDataTypeExtensions[type] || '';
};

/**
 * Returns the extension of a base64-encoded audio.
 * @param {string} base64Audio - The base64-encoded audio data.
 * @returns {string} The extension of the audio data, or an empty string if the extension is not supported.
 */
export const getBase64AudioExtension = (base64Audio: string) => {
  const audioDataTypeExtensions = {
    'data:audio/webm': 'webm',
    'data:audio/wav': 'wav',
    'data:audio/ogg': 'ogg',
    'data:audio/mp3': 'mp3'
  };
  const typeMatch = base64Audio.match(/data:audio\/[^;]+/);
  const type = typeMatch ? typeMatch[0] : '';
  // @ts-ignore This is an object accessor.
  return audioDataTypeExtensions[type] || '';
};

/**
 * Retrieves the file extension from a given file path.
 *
 * @param {string} filePath - The file path to extract the extension from.
 * @returns {string} - The file extension of the given file path.
 */
export const getFileExtension = (filePath: string) => filePath.split('.').pop();

export const getUpdatedResourceFileWithUpload = (
  gameDocument: GameDocument,
  resourceId: string,
  uploadedMedia: UploadedImage
): ResourceEntity | undefined =>
  getUpdatedResourceFileById(
    gameDocument,
    resourceId,
    uploadedMedia.fileName,
    uploadedMedia.blobUrl,
    uploadedMedia.size
  );

export const getUpdatedResourceFileById = (
  gameDocument: GameDocument,
  resourceId: string,
  filename: string,
  url: string,
  size: number
): ResourceEntity | undefined =>
  getUpdatedResourceFile(
    GetResourceEntity(gameDocument, resourceId),
    filename,
    url,
    size
  );

export const getUpdatedResourceFile = (
  resource: ResourceEntity,
  filename: string,
  url: string,
  size: number
): ResourceEntity | undefined => {
  // exit early if no resource found.
  if (!resource) return;

  // update the resource entity
  return {
    ...resource,
    description: filename,
    value: url,
    size
  };
};

export const downloadJSON = (data: any, filename: string = 'data.json') => {
  const jsonString = JSON.stringify(data, null, 2);
  const blob = new Blob([jsonString], { type: 'application/json' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
};

export const handleFileUpload = async <T>(
  event: React.ChangeEvent<HTMLInputElement>,
  onSuccess: (json: T) => Promise<void>,
  onError: (errorMessage: string) => void
) => {
  const file = event.target.files?.[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const json = JSON.parse(e.target?.result as string) as T; // Generic type T
        await onSuccess(json);
      } catch (err) {
        onError('Failed to parse JSON or JSON structure is incorrect');
      }
    };
    reader.onerror = () => {
      onError('Error reading file');
    };
    reader.readAsText(file);
  }
};
