import React from 'react';
import { observer } from 'mobx-react';
import Header, { HeaderBreadcrumb } from './header';

export interface BreadcrumbLocation {
  href: string;
  label: string;
}

interface PageBaseProps {
  pageTitle: string;
  /**
   * value can be:
   * 'dashboard/users'
   * OR
   * [
   *   {
   *     href: '#',
   *     label: 'Dashboard'
   *   }
   * ]
   */
  breadcrumb?: BreadcrumbLocation[] | string;
  children?: React.ReactNode;
  error?: Error;
  className?: string;
}

export const PrivatePage = observer(
  ({
    children,
    pageTitle,
    breadcrumb: pageLocation,
    className
  }: PageBaseProps) => {
    const getCrumbs = () => {
      let items = Array<HeaderBreadcrumb>();
      if (pageLocation && typeof pageLocation === 'string') {
        let urlSegments = pageLocation.split('/');
        items = urlSegments.map<HeaderBreadcrumb>((path) => ({
          to: `/${path}`,
          text: path
        }));
      } else if (pageLocation && typeof pageLocation !== 'string') {
        items = pageLocation.map<HeaderBreadcrumb>((path) => ({
          to: `/${path.href}`,
          text: path.label
        }));
      }
      return items;
    };

    return (
      <div className={`page-base ${className ?? ''}`}>
        <Header title={pageTitle} breadCrumbs={getCrumbs()} />
        <main>{children}</main>
      </div>
    );
  }
);
