export interface CustomColumnProps {
  children?: React.ReactNode;
  columnClass?: string;
  containerClass?: string;
}

const CustomColumnContainer = (props: CustomColumnProps) => {
  return (
    <td className={props.columnClass}>
      <div className={props.containerClass}>{props.children}</div>
    </td>
  );
};

export default CustomColumnContainer;
