import { useEffect, useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { ListView, ListViewItemProps } from '@progress/kendo-react-listview';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { CardPropertiesState, newDataModified } from './dashboard-content';
import { KendoGridResult } from '../types/KendoGridResult';
import { Permission } from '../types/permission';
import { isEmpty } from 'lodash';
import { useAuth } from 'react-oidc-context';

interface DashboardCardProps {
  cardDescription: CardPropertiesState;
  position: string;
  data: CardPropertiesState[];
  additionalClassName?: string;
  icon: string;
  buttonTitle?: string;
  buttonHandler?: () => void;
  fetcher?: Promise<KendoGridResult<any>>;
  permission?: Permission,
  type?: string
}

const DashboardCardView = ({
  cardDescription,
  position,
  data,
  additionalClassName = 'outlined',
  icon,
  buttonTitle,
  buttonHandler = () => { },
  fetcher,
  permission,
  type
}: DashboardCardProps) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>();
  const [dataModified, setDataModified] = useState<newDataModified[]>([]);

  const auth = useAuth();

  const getPermissionKey = (value: string) => {
    const indexOfS = Object.values(Permission).indexOf(
      value as unknown as Permission
    );

    const key = Object.keys(Permission)[indexOfS];

    return key;
  };

  const isInRole = (requiredRole: string) => {
    const role = getPermissionKey(requiredRole!);

    return !isEmpty(requiredRole)
      ? (auth.user?.profile.role as string[]).includes(role!)
      : true;
  };

  const modifiedData = (data: [], type: string) => {
    let newData: newDataModified[] = [];
    const urlData = (id: number) => {
      if (type === 'events') {
        return `/myeventview/${id}`;
      } else if (type === 'games') {
        return `/designer/${id}`;
      } else if (type === 'assessments') {
        return `/assessment-details/${id}`;
      } else if (type === 'global-library') {
        return `/global-games/${id}?page=globallibraries`;
      } else if (type === 'feedback') {
        return `/feedback-details/${id}`;
      } else {
        return '';
      }
    };

    data &&
      data.slice(0, 3).forEach((item: any) => {
        newData.push({
          id: item.id,
          name: item.name,
          date: item.startDateUtc,
          url: urlData(item.id)
        });
      });
    return data ? newData : [];
  };

  useEffect(() => {

    if (fetcher) {
      (async function fetchData() {

        setIsLoading(true);

        if (isInRole(permission!)) {
          try {
            const response = await fetcher;
            setDataModified(modifiedData(response?.data as [], type!))
          } catch (error) {
            console.log(error)
          } finally {
            setIsLoading(false);
          }
        }

        setIsLoading(false)

      })()
    }
  }, [fetcher])

  const itemRender = (props: ListViewItemProps) => {
    let item = props.dataItem;

    const result =
      !item.isOpenInNewTab && !item.isShowPopup ? (
        <NavLink
          to={item.url}
          className={'d-flex justify-content-between'}
          style={{ padding: 0 }}
        >
          <div>{item.name}</div>
          {position === '1' && <div>{item.date.toLocaleDateString()}</div>}
        </NavLink>
      ) : (
        <Button
          className={'p-0'}
          fillMode={'link'}
          onClick={() =>
            item.isShowPopup
              ? navigate(`?${item.url}`)
              : window.open(item.url, '_blank')
          }
          themeColor={'primary'}>
          {item.name}
        </Button>
      );
    return result;
  };

  return (
    <div
      className={`dashboard-cardview d-flex w-100 h-100 ${additionalClassName}`}>
      <div className={'dashboard-cardview-description w-100'}>
        <span className={`material-symbols-outlined`}>{icon}</span>
        <div className={'title k-font-weight-bold'}>
          {cardDescription.title}
        </div>
        <hr className={'my-2 w-100'} />
        <div className={'descriptions'}>{cardDescription.descriptions}</div>
        {data && data.length > 0 && <ListView data={data} item={itemRender} />}
        {
          isLoading ? (
            <span
              className={`k-icon k-i-loading`}
              style={{
                fontSize: '20px',
                color: '#4698cb'
              }}
            />
          ) : modifiedData && modifiedData.length ?
            (<ListView data={dataModified} item={itemRender} />) : (<></>)
        }

        {buttonTitle && (
          <>
            <hr className={'my-3 w-100'} />
            <Button
              className={'mx-auto mb-1'}
              onClick={buttonHandler}
              themeColor={'primary'}>
              {buttonTitle}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default DashboardCardView;
