import React from 'react';
import {
  Input,
  TextArea,
  InputProps,
  TextAreaProps
} from '@progress/kendo-react-inputs';
import { Col } from 'react-bootstrap';
import {
  ComboBox,
  ComboBoxFilterChangeEvent,
  ComboBoxProps
} from '@progress/kendo-react-dropdowns';
import cloneDeep from 'lodash.clonedeep';
import { Error, Label } from '@progress/kendo-react-labels';
import { FilterDescriptor, filterBy } from '@progress/kendo-data-query';

export interface FormInputProps extends InputProps {
  label?: string;
  error?: string;
  labelStyle?: string;
}

export interface TextareaInputProps extends TextAreaProps {
  label?: string;
  error?: string;
  labelStyle?: string;
}

export interface ComboboxInputProps extends ComboBoxProps {
  label?: string;
  colSize?: number | undefined;
  error?: string;
  labelStyle?: string;
}

export function FormInput({
  label,
  error,
  labelStyle,
  ...inputProps
}: FormInputProps) {
  return (
    <>
      <Label className={labelStyle}>{label}</Label>
      <Input {...inputProps}></Input>
      {error && <Error>{error}</Error>}
    </>
  );
}

export function TextareaInput({
  label,
  error,
  labelStyle,
  ...inputProps
}: TextareaInputProps) {
  return (
    <>
      <Label className={labelStyle}>{label}</Label>
      <TextArea {...inputProps}></TextArea>
      {error && <Error>{error}</Error>}
    </>
  );
}

export function ComboboxInput({
  label,
  error,
  labelStyle,
  ...inputProps
}: ComboboxInputProps) {
  const dataRef = React.useRef<typeof inputProps.data>();
  const [dataList, setDataList] = React.useState<typeof inputProps.data>([]);

  React.useEffect(() => {
    if (inputProps.data && inputProps.data.length > 0) {
      if (dataRef.current && dataRef.current.length > 0) {
        setDataList(dataRef.current);
        return;
      }

      dataRef.current = cloneDeep(inputProps.data);
      setDataList(dataRef.current);
    }
  }, [inputProps.data]);

  React.useEffect(() => {
    if (dataList && dataList.length > 0) {
      dataList.sort((a, b) => {
        if (
          inputProps.textField ||
          inputProps.data?.every((e) => typeof e === 'object')
            ? a[inputProps.textField as string] <
              b[inputProps.textField as string]
            : a < b
        )
          return -1;
        if (
          inputProps.textField ||
          inputProps.data?.every((e) => typeof e === 'object')
            ? a[inputProps.textField as string] >
              b[inputProps.textField as string]
            : a > b
        )
          return 1;
        return 0;
      });

      const createNewIndex = dataList.findIndex((data) => data.id === '-');
      if (createNewIndex !== -1) {
        const createNew = dataList[createNewIndex];
        dataList.splice(createNewIndex, 1);
        dataList.splice(0, 0, createNew);
      }
    }
  }, [dataList, inputProps.textField]);

  const filterData = (filter: FilterDescriptor) => {
    const data = dataRef.current!.slice();
    return filterBy(data, filter);
  };

  const filterChange = (event: ComboBoxFilterChangeEvent) => {
    setDataList(filterData(event.filter));
  };

  return (
    <>
      <Col sm={12} md={inputProps.colSize ?? 12}>
        <Label className={labelStyle}>{label}</Label>
        <ComboBox
          {...inputProps}
          data={dataList}
          onFilterChange={filterChange}
          filterable
        />
        {inputProps?.validationMessage !== '' && (
          <Error>{inputProps?.validationMessage}</Error>
        )}
      </Col>
    </>
  );
}
