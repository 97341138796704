import merge from 'lodash.merge';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Toolbar from '../../components/page-toolbar';
import { PrivatePage } from '../../components/private-page';
import { FeedbackContentFullEditor } from '../../features/feedback/feedback-content-full-editor';
import { GetLanguagesAsync } from '../../services/country';
import {
  getDocumentsByFeedbackIdAsync,
  getFeedbackById
} from '../../services/feedback';
import { appStore } from '../../stores/app-store';
import { Feedback } from '../../types/feedback';

const FeedbackReadonly = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [entity, setEntity] = useState<Feedback>();
  const [languages, setLanguages] = useState<string[]>([]);
  const [, setSelectedTab] = useState<string>('');
  const [currentLocale, setCurrentLocale] = useState<string>();
  const detailFeedbackBreadcrumb = [
    { label: 'Dashboard', href: '../dashboard' },
    { label: 'Feedback', href: '../mylibrary/feedback' },
    {
      label: 'Feedback Detail',
      href: `../feedback-detail-vew/${id}`
    }
  ];

  const getDetails = async () => {
    try {
      appStore.showLoading();

      // combine feedback and feedback documents (resources)
      let feed1 = await getFeedbackById(parseInt(id!));
      let feed2 = await getDocumentsByFeedbackIdAsync(parseInt(id!));

      let feedback = merge(feed1, feed2);

      setEntity(feedback);
    } catch (ex) {
      console.error(ex);
    } finally {
      appStore.hideLoading();
    }
  };

  const getLanguages = async () => {
    const response = await GetLanguagesAsync();
    const languages: string[] = [];
    response.data.forEach((f) => {
      if (!languages.some((c) => c === f.language)) {
        languages.push(f.language!);
      }
    });
    setLanguages(languages);
  };

  const toggleLanguageTab = (activeTab: string) => {
    if (activeTab === 'language') {
      setSelectedTab(activeTab);
    } else {
      setSelectedTab('');
    }
  };

  useEffect(() => {
    if (id) {
      getDetails();
    }
    getLanguages();
  }, []);

  return (
    <PrivatePage breadcrumb={detailFeedbackBreadcrumb} pageTitle={'Feedback'}>
      <>
        <Toolbar title={entity?.name ?? ''}></Toolbar>
        <hr />
        <Container>
          <Row>
            <Col>
              {entity && (
                <FeedbackContentFullEditor
                  languages={languages}
                  entity={entity}
                  isViewMode={true}
                  currentLocale={currentLocale}
                  setCurrentLocale={setCurrentLocale}
                  toggleLanguageTab={toggleLanguageTab}
                />
              )}
            </Col>
          </Row>
        </Container>
      </>
    </PrivatePage>
  );
};

export default FeedbackReadonly;
