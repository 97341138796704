import { GameDocumentContext } from '../../../contexts/game-document';
import React, { useContext, useEffect, useState } from 'react';
import Toolbar from '../toolbar';
import {
  DeleteItemAsync,
  UpdateGameDocState,
  UpdateItemAsync,
  UpdateResourceAsync
} from '../../../utils/game-document';
import { Button } from '@progress/kendo-react-buttons';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CheckboxChangeEvent,
  InputChangeEvent
} from '@progress/kendo-react-inputs';
import { FluidForm } from '../../../components/forms';
import { ItemEntity, ResourceEntity } from '../../../types/game-document/';
import {
  ItemEditor,
  ItemEditorWindow
} from '../../../features/game-document/items';
import { EntityEditor } from '../../../types/game-document/entity-editor';
import cloneDeep from 'lodash.clonedeep';
import RequiredFields from '../../../types/required-fields';
import {
  ERR_DUPLICATE_NAME_VALUE,
  ERR_INPUT_REQUIRED
} from '../../../constants/text';
import { useGameDocumentResources } from '../../../hooks/use-game-document-resources';

const Item = () => {
  const { itemId } = useParams();
  const [state, setState] = useContext(GameDocumentContext);
  const navigate = useNavigate();

  const getEntityById = (entityId: string) =>
    state.gameDocument?.assets.items?.find((i) => i.id === entityId)!;

  const [entity, setEntity] = useState<ItemEntity>(getEntityById(itemId!));
  const [titleResource, setTitleResource] = useState<ResourceEntity>();
  const [summaryResource, setSummaryResource] = useState<ResourceEntity>();
  const [imageResource, setImageResource] = useState<ResourceEntity>();
  const [resources, setResources] = useState<ResourceEntity[]>(
    [] as ResourceEntity[]
  );
  const [requiredFields, setRequiredFields] = useState<
    RequiredFields<ItemEntity>[]
  >([{ name: 'name', errorMessage: '' }]);

  const gameDocumentFiles = useGameDocumentResources();

  useEffect(() => {
    setEntity(getEntityById(itemId!));
  }, [state]);

  useEffect(() => {
    // Set the page title.
    document.title = `${entity.name} - ${state.gameDocument?.name}`;
    if (entity) {
      const titleRes = state.gameDocument?.resources.find(
        (item) => item.id === entity.titleResId
      )! as ResourceEntity;
      const summaryRes = state.gameDocument?.resources.find(
        (item) => item.id === entity.summaryResId
      )! as ResourceEntity;
      const imageRes = state.gameDocument?.resources.find(
        (item) => item.id === entity.imageResId
      )! as ResourceEntity;
      setTitleResource(titleRes);
      setSummaryResource(summaryRes);
      setImageResource(imageRes);
      setResources([titleRes, summaryRes, imageRes]);
    }
  }, [state, entity]);

  const handleInputChange = (event: InputChangeEvent) => {
    const { name, value } = event.target;
    if (name === 'titleResId' || name === 'summaryResId') {
      setResources(
        resources.map((item) =>
          item.id === entity[name]
            ? { ...item, value: value as string }
            : { ...item }
        )
      );
      const resource = resources.find((item) => item.id === entity[name])!;
      resource.value = value as string;
      UpdateResourceAsync(state.gameDocument!, resource?.id, resource).then(
        (updatedGameDocument) => {
          setState((state) => UpdateGameDocState(state, updatedGameDocument));
        }
      );
    } else {
      setRequiredFields((prev) =>
        prev.map((item: RequiredFields<ItemEntity>) => {
          const copyItem = cloneDeep({ ...item });
          copyItem.errorMessage = '';
          if (item.name === (name as keyof ItemEntity)) {
            const duplicateValueIndex: number =
              state.gameDocument?.assets.items
                ?.filter((item: ItemEntity) => item.id !== entity.id)
                .findIndex(
                  (item: ItemEntity) =>
                    item.name.toLowerCase() === (value as string)?.toLowerCase()
                ) ?? -1;
            if ((value as string) === '') {
              copyItem.errorMessage = ERR_INPUT_REQUIRED;
            } else if (duplicateValueIndex !== -1) {
              copyItem.errorMessage = ERR_DUPLICATE_NAME_VALUE;
            }
          }
          return copyItem;
        })
      );
      setEntity((prev) => ({
        ...prev,
        [name!]: value
      }));
      UpdateItemAsync(
        state.gameDocument!,
        itemId!,
        {
          ...entity,
          [name!]: value
        },
        false
      ).then((updatedGameDocument) => {
        setState((state) => UpdateGameDocState(state, updatedGameDocument));
      });
    }
  };

  const handleImageSelected = (url: string, size: number, name?: string) => {
    gameDocumentFiles.updateResource(
      entity.imageResId!,
      url,
      size,
      name ?? '',
      (resource) => {
        // update the resources in state...
        // TODO: is this even needed? the game document state is updated which would trigger the effect on line 49...
        setResources((current) =>
          current.map((item) =>
            item.id === entity.imageResId ? resource : item
          )
        );
      }
    );
  };

  const handleHideItemChange = (event: CheckboxChangeEvent) => {
    const { name, value } = event.target;
    UpdateItemAsync(
      state.gameDocument!,
      itemId!,
      {
        ...entity,
        [name!]: value
      },
      false
    ).then((updatedGameDocument) => {
      setState((state) => UpdateGameDocState(state, updatedGameDocument));
    });
  };

  const [entityEditorIsVisible, setEntityEditorIsVisible] =
    useState<boolean>(false);
  const toggleEntityEditor = () => {
    setEntityEditorIsVisible(!entityEditorIsVisible);
  };

  const handleEntityEditorSubmit = (
    editorEntity: EntityEditor<ItemEntity>,
    resourceEntity: EntityEditor<ResourceEntity>[]
  ) => {
    const titleResource = resourceEntity.find(
      (resource) => resource.entity.id === editorEntity.entity.titleResId
    );
    const summaryResource = resourceEntity.find(
      (resource) => resource.entity.id === editorEntity.entity.summaryResId
    );
    const imageResource = resourceEntity.find(
      (resource) => resource.entity.id === editorEntity.entity.imageResId
    );
    const updateTitleResource = UpdateResourceAsync(
      state.gameDocument!,
      editorEntity.entity.titleResId!,
      titleResource?.entity!
    );
    const updateSummaryResource = UpdateResourceAsync(
      state.gameDocument!,
      editorEntity.entity.summaryResId!,
      summaryResource?.entity!
    );
    const updateImageResource = UpdateResourceAsync(
      state.gameDocument!,
      editorEntity.entity.imageResId!,
      imageResource?.entity!
    );
    Promise.all([
      updateTitleResource,
      updateSummaryResource,
      updateImageResource
    ]).then(() => {
      UpdateItemAsync(
        state.gameDocument!,
        editorEntity.entity.id,
        editorEntity.entity
      ).then((updatedGameDocument) => {
        setState((state) => UpdateGameDocState(state, updatedGameDocument));
        setEntityEditorIsVisible(false);
      });
    });
  };

  const onDeleteEntity = async (entityId: string) => {
    DeleteItemAsync(state.gameDocument!, entityId).then(
      (updatedGameDocument) => {
        setState((state) => UpdateGameDocState(state, updatedGameDocument));
        navigate('../');
      }
    );
  };

  return (
    <>
      <Toolbar title={entity?.name}>
        <Button
          onClick={() => onDeleteEntity(entity.id!)}
          themeColor={'error'}
          fillMode={'flat'}>
          <span className="material-symbols-outlined">delete</span>
        </Button>
        <Button
          onClick={toggleEntityEditor}
          themeColor={'success'}
          fillMode={'flat'}>
          <span className="material-symbols-outlined">edit</span>
        </Button>
        {entityEditorIsVisible && (
          <ItemEditorWindow
            toggleDialog={toggleEntityEditor}
            onSubmit={handleEntityEditorSubmit}
            onClose={toggleEntityEditor}
            editorEntity={{ isNew: false, entity: entity }}
            editorResource={[
              { isNew: false, entity: titleResource! },
              { isNew: false, entity: summaryResource! },
              { isNew: false, entity: imageResource! }
            ]}
            editorMode={'basic'}
          />
        )}
      </Toolbar>
      <FluidForm>
        <ItemEditor
          editorMode={'full'}
          entity={entity}
          resources={resources}
          imageResourceVisibility={true}
          handleInputChange={handleInputChange}
          handleImageSelected={handleImageSelected}
          requiredFields={requiredFields}
          handleHideItemChange={handleHideItemChange}
        />
      </FluidForm>
    </>
  );
};

export default Item;
