import GlobalLibraryPendingGrid from '../../features/global-library-pending-grid';
import { PrivatePage } from '../../components/private-page';
import { useEffect } from 'react';
import { ctGameDefault, ctGameTitle } from '../../constants/text';

export default function GlobalLibrariesPending() {

  useEffect(() => {
    document.title = ctGameTitle + ' - Pending Approval';

    return () => {
      // return default value when component unmounts
      document.title = ctGameDefault
    }
  }, []);

  return (
    <PrivatePage
      breadcrumb={[
        { label: 'Dashboard', href: 'dashboard' },
        { label: 'Administration', href: '../administration' },

        {
          label: ' Pending approval',
          href: 'administration/globallibraries/pending'
        }
      ]}
      pageTitle={'Administration'}>
      <GlobalLibraryPendingGrid />
    </PrivatePage>
  );
}
