/**
 * Get random number in range
 * @param min
 * @param max
 * @returns
 */
export const randomNumberInRange = (min: number, max: number) => {
  // 👇️ get number between min (inclusive) and max (inclusive)
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
