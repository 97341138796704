import { KendoGridResult } from '../types/KendoGridResult';
import {
  DeleteAsync,
  GetAllAsync,
  PostAsync,
  GetAsync,
  PatchAsync,
  PutAsync
} from './api';
import { OrganisationResponse } from '../types/responses/organisation-response';
import { PhoneNumberResponse } from '../types/responses/phone-number-response';

/**
 * Gets ALL OrganisationsPhoneNumbers encapsulated in a paged payload.
 * @returns all Organisation objects.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetOrganisationsPhoneNumbersAsync = async (
  organisationId: number
) =>
  GetAllAsync<PhoneNumberResponse>(
    `organisations/${organisationId}/phonenumbers`
  );

/**
 * Applies a POST to the OrganisationPhoneNumber.
 * requiring only the fields and values that require modification.
 * @param organisationId - the ID of the Organisation to POST
 * @param organisation - the Organisation or partial Organisation to update [POST]
 * @returns the updated Organisation object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/POST
 */
export const PostOrganisationPhoneNumberAsync = async (
  organisationId: number,
  organisationPhoneNumber: PhoneNumberResponse
) =>
  PostAsync<PhoneNumberResponse>(
    `organisations/${organisationId}/phonenumbers`,
    organisationPhoneNumber
  );

/**
 * Gets the OrganisationPhoneNumber by id.
 * @param organisationId - the ID of the Organisation to retrieve
 * @returns the Organisation object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetOrganisationPhoneNumberAsync = async (organisationId: number) =>
  GetAsync<PhoneNumberResponse>(`organisations/${organisationId}/phonenumbers`);

/**
 * Updates the OrganisationPhoneNumber by completely replacing it.
 * @param organisationId - the ID of the entity to update [PATCH]
 * @param organisation - the Organisation to update [PATCH]
 * @returns the updated Organisation object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PATCH
 */
export const PatchOrganisationPhoneNumberAsync = async (
  organisationId: number,
  organisationPhoneNumberId: number,
  organisationPhoneNumber: PhoneNumberResponse
) =>
  PatchAsync<PhoneNumberResponse>(
    `organisations/${organisationId}/phonenumbers/${organisationPhoneNumberId}`,
    organisationPhoneNumber
  );

/**
 * Applies a PUT to the OrganisationPhoneNumber. This can be considered a partial update
 * requiring only the fields and values that require modification.
 * @param organisationId - the ID of the Organisation to PUT
 * @param organisation - the Organisation or partial Organisation to update [PUT]
 * @returns the updated Organisation object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PUT
 */
export const PutOrganisationPhoneNumberAsync = async (
  organisationId: number,
  organisationPhoneNumberId: number,
  organisationPhoneNumber: PhoneNumberResponse
) =>
  PutAsync<PhoneNumberResponse>(
    `organisations/${organisationId}/phonenumbers/${organisationPhoneNumberId}`,
    organisationPhoneNumber
  );

/**
 * Deletes a OrganisationPhoneNumber by id.
 * @param organisationId - the ID of the Organisation to DELETE
 * @returns the deleted Organisation object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/DELETE
 */
export const DeleteOrganisationPhoneNumberAsync = async (
  organisationId: number,
  organisationPhoneNumberId: number
) =>
  DeleteAsync<PhoneNumberResponse>(
    `organisations/${organisationId}/phonenumbers/${organisationPhoneNumberId}`
  );
