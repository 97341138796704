import {
  MultiSelect,
  MultiSelectChangeEvent
} from '@progress/kendo-react-dropdowns';
import {
  PanelBar,
  PanelBarItem,
  PanelBarSelectEventArguments
} from '@progress/kendo-react-layout';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import SelectionItem from '../../types/selection-item';

export interface ArgumentSelectorProps {
  title: string;
  minimumSelected: number;
  argumentList: SelectionItem[];
  onArgumentSelected: Function;
  selectedArgument?: SelectionItem[];
  placeholder?: string;
}

export const MultipleArgumentSelector = ({
  ...props
}: ArgumentSelectorProps) => {
  const [originalValues, setOriginalValues] = useState<SelectionItem[]>(
    props.selectedArgument ?? []
  );

  const [values, setValues] = useState<SelectionItem[]>(
    props.selectedArgument ?? []
  );

  useEffect(() => {
    setValues(props.selectedArgument ?? []);
    setOriginalValues(props.selectedArgument ?? []);
  }, [props.selectedArgument]);

  const [expanded, setExpanded] = useState<string[]>([]);

  const onChangeFilterHandler = (e: MultiSelectChangeEvent) => {
    setValues(e.target.value);
  };

  const onSaveHandler = () => {
    if (values?.length < 2) {
      return;
    }
    props.onArgumentSelected(values);
    setExpanded([]);
  };

  const onCancelHandler = () => {
    props.onArgumentSelected(originalValues);
    setExpanded([]);
  };

  const handleSelect = (event: PanelBarSelectEventArguments) => {
    if (event.expandedItems) {
      setExpanded(event.expandedItems);
    }
  };

  React.useEffect(() => {
    if (props.argumentList.length > 0) {
      props.argumentList.sort((a, b) => {
        if (a.description && b.description) {
          if (a.description < b.description) return -1;
          if (a.description > b.description) return 1;
        }
        return 0;
      });
    }
  }, [props.argumentList]);

  return (
    <PanelBar
      expandMode={'single'}
      isControlled={true}
      expanded={expanded}
      onSelect={handleSelect}>
      <PanelBarItem title={props.title}>
        <Row className={'p-2'}>
          <Col>
            <MultiSelect
              className={'multi-select cursor-pointer text-decoration-none'}
              textField={'name'}
              data={props.argumentList}
              placeholder={props.placeholder}
              value={values}
              onChange={onChangeFilterHandler}
            />
          </Col>
        </Row>
        {values?.length < props.minimumSelected && (
          <Row className={'p-2'}>
            <Col>
              <code>{`Please select minimum ${props?.minimumSelected} task`}</code>
            </Col>
          </Row>
        )}

        <Row>
          <Col className={'d-flex justify-content-end'}>
            <span
              onClick={onCancelHandler}
              className={
                'material-symbols-outlined text-danger mr-1 p-2 cursor-pointer'
              }>
              close
            </span>
            <span
              onClick={onSaveHandler}
              className={
                'material-symbols-outlined text-success ml-1 p-2 cursor-pointer'
              }>
              check
            </span>
          </Col>
        </Row>
      </PanelBarItem>
    </PanelBar>
  );
};
