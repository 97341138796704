import { useContext, useEffect, useState } from 'react';
import { GameDocumentContext } from '../../../contexts/game-document';
import Toolbar from '../toolbar';
import {
  DeleteTimerAsync,
  UpdateGameDocState,
  GetResourceEntity,
  SaveTimerEntityEditorAsync
} from '../../../utils/game-document';
import { Button } from '@progress/kendo-react-buttons';
import { useNavigate, useParams } from 'react-router-dom';
import { TimerEditorWindow } from '../../../features/game-document/timers';
import { EntityEditor } from '../../../types/game-document/entity-editor';
import { TimerEntity, ResourceEntity } from '../../../types/game-document/';
import { FluidForm } from '../../../components/forms';
import { uuid } from '../../../types/common-helper';
import TimerEditorFull from '../../../features/game-document/timers/timer-editor-full';
import { ResourceType } from '../../../types/game-document/resource-type';
import cloneDeep from 'lodash.clonedeep';
import RequiredFields from '../../../types/required-fields';
import {
  ERR_DUPLICATE_NAME_VALUE,
  ERR_INPUT_REQUIRED
} from '../../../constants/text';

const Timer = () => {
  const { timerId } = useParams();
  const [state, setState] = useContext(GameDocumentContext);
  const navigate = useNavigate();
  const getEntityById = (entityId: string) =>
    state.gameDocument?.assets.timers?.find((i) => i.id === entityId)!;
  const [entity, setEntity] = useState<TimerEntity>(() =>
    cloneDeep(getEntityById(timerId!))
  );
  const [resources, setResources] = useState<ResourceEntity[]>([]);
  const [requiredFields, setRequiredFields] = useState<
    RequiredFields<TimerEntity>[]
  >([
    { name: 'name', errorMessage: '' },
    { name: 'titleResId', errorMessage: '' }
  ]);

  const [entityEditorIsVisible, setEntityEditorIsVisible] =
    useState<boolean>(false);

  const toggleEntityEditor = () => {
    setEntityEditorIsVisible(!entityEditorIsVisible);
  };

  const handleEntityEditorSubmit = (
    editorEntity: EntityEditor<TimerEntity>,
    resourceEntity: EntityEditor<ResourceEntity>[]
  ) => {
    submitEntityAsync(
      cloneDeep(editorEntity.entity),
      resourceEntity?.map((x) => x.entity)
    );
    toggleEntityEditor();
  };

  const onDeleteEntity = async (entityId: string) => {
    DeleteTimerAsync(state.gameDocument!, entityId).then(
      (updatedGameDocument) => {
        setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
        navigate('../');
      }
    );
  };

  const updateEntityAsync = async (
    name: keyof TimerEntity,
    value: string | number,
    type: ResourceType,
    isClearValue?: boolean
  ) => {
    setRequiredFields((prev) =>
      prev.map((task: RequiredFields<TimerEntity>) => {
        const copyTask = cloneDeep({ ...task });
        if (task.name === name) {
          copyTask.errorMessage = '';
          if (name === 'name') {
            const duplicateValueIndex: number =
              state.gameDocument?.assets?.timers
                ?.filter((item: TimerEntity) => item.id !== entity.id)
                .findIndex(
                  (item: TimerEntity) =>
                    item.name.toLowerCase() === (value as string)?.toLowerCase()
                ) ?? -1;
            if (value === '') {
              copyTask.errorMessage = ERR_INPUT_REQUIRED;
            } else if (duplicateValueIndex !== -1) {
              copyTask.errorMessage = ERR_DUPLICATE_NAME_VALUE;
            }
          } else if (name === 'titleResId') {
            if (value === '') {
              copyTask.errorMessage = ERR_INPUT_REQUIRED;
            }
          }
        }
        return copyTask;
      })
    );
    let newEntity: TimerEntity = cloneDeep({ ...entity });
    let newResources: ResourceEntity[] = [...resources];
    if (!name?.toLowerCase().includes('resid')) {
      newEntity[name] = value as never;
    } else {
      const resourceId = entity[name];
      const resIndex: number = newResources?.findIndex(
        (x) => x.id === resourceId
      );
      if (resIndex === -1) {
        const newId: string = uuid();
        let resourceEntity: ResourceEntity = {
          id: newId,
          description: '',
          name: name,
          type: type,
          value: value as string
        };
        newResources.push(resourceEntity);

        newEntity[name] = newId as never;
      } else {
        newResources[resIndex].value = value as never;
        newEntity[name] = resourceId as never;
      }
    }
    submitEntityAsync(newEntity, newResources);
  };

  const submitEntityAsync = async (
    newEntity: TimerEntity,
    newResources: ResourceEntity[]
  ) => {
    const entityResources = newResources?.map((item) => ({
      isNew: false,
      entity: item
    })) as EntityEditor<ResourceEntity>[];

    setEntity(newEntity);
    setResources(newResources);

    let response = await SaveTimerEntityEditorAsync(
      state?.gameDocument!,
      { isNew: false, entity: newEntity },
      entityResources,
      false
    );
    setState((prev) => UpdateGameDocState(prev, response));
  };

  useEffect(() => {
    // Set the page title.
    document.title = `${entity.name} - ${state.gameDocument?.name}`;
  }, [state, entity]);

  useEffect(() => {
    setResources([GetResourceEntity(state?.gameDocument!, entity.titleResId!)]);
  }, [timerId]);

  return (
    <>
      <Toolbar title={entity?.name}>
        <Button
          onClick={() => onDeleteEntity(entity.id!)}
          themeColor={'error'}
          fillMode={'flat'}>
          <span className="material-symbols-outlined">delete</span>
        </Button>

        <Button
          onClick={toggleEntityEditor}
          themeColor={'success'}
          fillMode={'flat'}>
          <span className="material-symbols-outlined">edit</span>
        </Button>

        {entityEditorIsVisible && (
          <TimerEditorWindow
            toggleDialog={toggleEntityEditor}
            onSubmit={handleEntityEditorSubmit}
            onClose={toggleEntityEditor}
            editorResource={[
              {
                isNew: false,
                entity: resources[0] ?? {}
              }
            ]}
            editorEntity={{ isNew: false, entity: entity }}
            editorMode={'full'}
          />
        )}
      </Toolbar>

      <FluidForm>
        <TimerEditorFull
          timer={entity}
          resources={resources}
          requiredFields={requiredFields}
          handleEntityChange={(
            name: keyof TimerEntity,
            value: string | number,
            type: ResourceType,
            isClearValue?: boolean
          ) => {
            updateEntityAsync(name, value, type, isClearValue);
          }}
          handleEventAction={(entity) => submitEntityAsync(entity, resources)}
        />
      </FluidForm>
    </>
  );
};

export default Timer;
