import { TimePicker } from '@progress/kendo-react-dateinputs';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  AnswerType,
  TaskContentAnswer
} from '../../types/game-document/entities/task-content';
import { getTimePickerFormat } from '../../utils/date';
import { FormInput } from '../form-input';

type ScoringSingleAnswerProps = {
  answerType?: AnswerType;
  answers?: TaskContentAnswer[];
  score?: number;
  isManualScoring?: boolean;
  onChangeInputScore?: (value: number) => void;
};
export function ScoringSingleAnswer({
  answerType,
  answers,
  score,
  isManualScoring,
  onChangeInputScore = () => {}
}: ScoringSingleAnswerProps) {
  const [isInputAnswer, setIsInputAnswer] = useState<boolean>(false);

  useEffect(() => {
    setIsInputAnswer(
      answerType === 'text' || answerType === 'number' || answerType === 'time'
    );
  }, [answerType]);

  return (
    <>
      <Row>
        <Col md={12}>
          {answerType === 'text' && <Label>Acceptable answer / pattern</Label>}
          {isInputAnswer && (
            <div>
              {answerType === 'text' && (
                <FormInput disabled={true} value={answers![0]?.description} />
              )}
              {answerType === 'number' && (
                <FormInput
                  disabled={true}
                  type={'number'}
                  value={answers![0]?.description}
                />
              )}
              {answerType === 'time' && (
                <TimePicker
                  disabled={true}
                  value={getTimePickerFormat(answers![0].description)}
                />
              )}
            </div>
          )}
        </Col>
      </Row>
      <Row className={'mt-3'}>
        {answerType !== 'radio' && answerType !== 'checkbox' && (
          <Col sm={12} md={6} lg={3} xl={3}>
            <div className={'d-flex justify-content-center flex-column w-100'}>
              <div className={'d-flex'}>
                <span className={'fw-bold'}>Score</span>
              </div>
              <div>
                <NumericTextBox
                  disabled={isManualScoring}
                  value={score as number}
                  onChange={(e) => onChangeInputScore(e.target.value as number)}
                />
              </div>
            </div>
          </Col>
        )}
      </Row>
    </>
  );
}
