import { ComponentType, useState } from 'react';
import {
  Window,
  WindowActionsBar,
  WindowProps
} from '@progress/kendo-react-dialogs';
import { FluidForm } from '../../../components/forms';
import { ItemEntity, ResourcePackEntity } from '../../../types/game-document/';
import {
  CheckboxChangeEvent,
  InputChangeEvent
} from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { EditorMode } from '../../../types/editor-mode';
import { EntityEditor } from '../../../types/game-document/entity-editor';
import { ResourcePackEditor } from './resource-pack-editor';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';

interface ResourcePackWindowProps extends WindowProps {
  editorEntity: EntityEditor<ResourcePackEntity>;
  toggleDialog: Function;
  onSubmit: (
    editorEntity: EntityEditor<ResourcePackEntity>,
    isCopyOriginal: boolean,
    displayLanguage: string
  ) => void;
  editorMode: EditorMode;
  isLanguageCombobox?: boolean;
  listedLanguages?: string[];
  // listedDisplayLanguages?: string[];
}

// Need to move somewhere else for other component to import
export interface RequiredInput {
  name: string;
  errorMessage: string;
}

export const ResourcePackEditorWindow: ComponentType<
  ResourcePackWindowProps
> = ({
  editorEntity,
  toggleDialog,
  onSubmit,
  editorMode,
  isLanguageCombobox,
  listedLanguages,
  // listedDisplayLanguages,
  ...props
}: ResourcePackWindowProps) => {
  const [entity, setEntity] = useState<ResourcePackEntity>({
    ...editorEntity.entity
  });

  const [isCopyOriginal, setIsCopyOriginal] = useState<boolean>(false);
  const [isLanguageDuplicate, setIsLanguageDuplicate] =
    useState<boolean>(false);

  const handleInputChange = (event: InputChangeEvent) => {
    let newEntity: ResourcePackEntity = { ...entity };
    newEntity.name = event?.target?.value?.toString() ?? '';
    setEntity(newEntity);
  };

  const handleCheckedChange = (event: CheckboxChangeEvent) => {
    setIsCopyOriginal(event?.target?.value as boolean);
  };

  const handleComboChange = (event: ComboBoxChangeEvent) => {
    let newEntity: ResourcePackEntity = { ...entity };
    newEntity.name = event?.target?.value?.toString() ?? '';

    if (listedLanguages && listedLanguages.length > 0) {
      setIsLanguageDuplicate(
        listedLanguages?.find((item) => item === newEntity.name!) ? true : false
      );
    }

    setEntity(newEntity);
  };
  const handleDisplayLanguageChange = (event: ComboBoxChangeEvent) => {
    let newEntity: ResourcePackEntity = { ...entity };

    newEntity.displayLanguage = event?.target?.value?.toString() ?? '';

    setEntity(newEntity);
  };

  return (
    <Window
      modal={true}
      minimizeButton={undefined}
      className={'shadow'}
      initialWidth={500}
      initialHeight={editorEntity.isNew ? 320 : 280}
      title={editorEntity.isNew ? 'Language title' : editorEntity.entity.name}
      {...props}>
      <FluidForm>
        <ResourcePackEditor
          editorMode={editorMode}
          entity={entity}
          isNew={editorEntity.isNew}
          handleInputChange={handleInputChange}
          handleCheckedChange={handleCheckedChange}
          handleComboChange={handleComboChange}
          handleDisplayLanguageChange={handleDisplayLanguageChange}
          isLanguageCombobox={isLanguageCombobox}
          isLanguageDuplicate={isLanguageDuplicate}></ResourcePackEditor>
      </FluidForm>
      <WindowActionsBar>
        <Button
          themeColor={'secondary'}
          onClick={() => {
            toggleDialog();
          }}>
          Cancel
        </Button>
        <Button
          disabled={
            isLanguageDuplicate || !entity.name || !entity.displayLanguage
          }
          themeColor={'primary'}
          onClick={() => {
            onSubmit(
              { isNew: editorEntity.isNew, entity },
              isCopyOriginal,
              entity.displayLanguage!
            );
          }}>
          Save
        </Button>
      </WindowActionsBar>
    </Window>
  );
};
