import { ComponentType, useContext, useEffect, useState } from 'react';
import {
  Window,
  WindowActionsBar,
  WindowProps
} from '@progress/kendo-react-dialogs';
import { FluidForm } from '../../../components/forms';
import { TaskContentEntity } from '../../../types/game-document/';
import { Button } from '@progress/kendo-react-buttons';
import { EditorMode } from '../../../types/editor-mode';
import { EntityEditor } from '../../../types/game-document/entity-editor';
import { TaskContentEditor } from './task-content-editor';
import { GameDocumentContext } from '../../../contexts/game-document';
import RequiredFields from '../../../types/required-fields';
import {
  ERR_DUPLICATE_NAME_VALUE,
  ERR_INPUT_REQUIRED
} from '../../../constants/text';

interface TaskContentEditorWindowProps extends WindowProps {
  editorEntity: EntityEditor<TaskContentEntity>;
  toggleDialog: Function;
  onSubmit: (editorEntity: EntityEditor<TaskContentEntity>) => void;
  editorMode: EditorMode;
}

export const TaskContentEditorWindow: ComponentType<
  TaskContentEditorWindowProps
> = ({
  editorEntity,
  toggleDialog,
  onSubmit,
  editorMode,
  ...props
}: TaskContentEditorWindowProps) => {
  const [entity, setEntity] = useState({ ...editorEntity.entity });
  const [state] = useContext(GameDocumentContext);
  const [requiredFields, setRequiredFields] = useState<
    RequiredFields<TaskContentEntity>[]
  >([{ name: 'name', errorMessage: '' }]);
  const handleEntityChange = (keyEntity: string, value: string) => {
    setEntity({ ...entity, [keyEntity]: value });
  };

  const isInputValid = (): boolean => {
    const inputValidation = requiredFields.map((input) => {
      const entityValue = entity[input.name as keyof TaskContentEntity];
      const itemIndex = state.gameDocument?.assets?.taskContents?.findIndex(
        (item) =>
          item.name.toLowerCase() === (entityValue as string)?.toLowerCase()
      );

      input.errorMessage = '';

      if (input.name === 'name') {
        if (entityValue === '') {
          input.errorMessage = ERR_INPUT_REQUIRED;
        }

        if (
          itemIndex! > -1 &&
          state.gameDocument?.assets?.taskContents![itemIndex!]?.id !==
            entity.id
        ) {
          input.errorMessage = ERR_DUPLICATE_NAME_VALUE;
        }
      }

      return input;
    });
    setRequiredFields(inputValidation);
    return (
      inputValidation.findIndex((input) => input.errorMessage !== '') === -1
    );
  };

  useEffect(() => {
    if (entity.bodyType === undefined) {
      setEntity({ ...entity, bodyType: 'HTML' });
    }
  }, []);

  return (
    <>
      <Window
        modal={true}
        minimizeButton={undefined}
        className={'shadow'}
        initialWidth={500}
        initialHeight={400}
        title={
          editorEntity.isNew ? 'New task content' : editorEntity.entity.name
        }
        {...props}>
        <FluidForm>
          <TaskContentEditor
            editorMode={editorMode}
            entity={entity}
            requiredFields={requiredFields}
            handleEntityChange={handleEntityChange}
          />
        </FluidForm>
        <WindowActionsBar>
          <Button themeColor={'secondary'} onClick={() => toggleDialog()}>
            Cancel
          </Button>
          <Button
            themeColor={'primary'}
            onClick={() => {
              if (isInputValid()) {
                onSubmit({ isNew: editorEntity.isNew, entity });
              }
            }}>
            Save
          </Button>
        </WindowActionsBar>
      </Window>
    </>
  );
};
