import { ComponentType, useRef } from 'react';
import { Col } from 'react-bootstrap';
import { Checkbox, CheckboxProps } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';

interface StandardCheckboxProps extends CheckboxProps {
  colSize?: number | undefined;
}

export const StandardCheckbox: ComponentType<StandardCheckboxProps> = ({
  label,
  colSize,
  ...props
}) => {
  const inputRef = useRef(null);
  return (
    <Col sm={12} md={colSize ?? 12}>
      <Label editorRef={inputRef}>{label}</Label>
      <br />
      <Checkbox ref={inputRef} {...props} />
    </Col>
  );
};

export default StandardCheckbox;
