import { GameDocumentState } from '../../contexts/game-document';
import {
  RadioButton,
  RadioButtonChangeEvent
} from '@progress/kendo-react-inputs';
import { useEffect, useState } from 'react';
import { UpdatePreGameSettingAsync } from '../../utils/game-document/settings';
import { PreGame } from '../../constants/settings';
import { GameDocument } from '../../types/game-document';

interface PreGameSettingsProps {
  state: GameDocumentState;
  onChange: (gameDocument: GameDocument) => void;
}

const PreGameSettings = (props: PreGameSettingsProps) => {
  const { state, onChange } = props;
  const [isEnableGameLobby, setIsEnableGameLobby] = useState<boolean>(
    state.gameDocument?.settings.preGame.showPreGameLobby!
  );

  const handlePreGameSettingChange = (e: RadioButtonChangeEvent) => {
    const preGameSetting = state.gameDocument?.settings.preGame!;

    UpdatePreGameSettingAsync(state.gameDocument!, {
      ...preGameSetting,
      [e.target.element?.name!]: e.value
    }).then((updatedGameDocument) => {
      onChange(updatedGameDocument);
    });
    if (e.target.element?.name === PreGame.lobby) {
      setIsEnableGameLobby(e.value);
    }
  };

  useEffect(() => {
    setIsEnableGameLobby(
      state.gameDocument?.settings.preGame.showPreGameLobby!
    );
  }, [state.gameDocument?.settings.preGame.showPreGameLobby]);

  //the logic for this setting is using IsShow, so the 'Show' Radio button will send "true" value, and for the inital value set on false (Hide)
  return (
    <>
      <div className={'d-flex gap-1 align-items-center'}>
        <span className={'mr-10 w-30'}>Game Lobby</span>
        <RadioButton
          name={PreGame.lobby}
          value={true}
          label={'Show'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.preGame.showPreGameLobby === true
          }
          onChange={handlePreGameSettingChange}
        />

        <RadioButton
          name={PreGame.lobby}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.preGame.showPreGameLobby === false
          }
          onChange={handlePreGameSettingChange}
        />
      </div>
      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Visibility of pre-game menu tabs.
        </span>
      </div>

      <div
        className={'d-flex flex-column gap-1 align-items-start mt-4 mb-2'}
        style={{ borderBottom: '1px solid #dee2e6' }}>
        <h2>Teams</h2>
      </div>
      <span className={'mb-2 fs-6 text-black-50'}>
        A team consists of <b>multiple devices</b> playing together for a team
        score. <b>One device</b> with many players is not a team.
      </span>

      <div className={'d-flex gap-1 align-items-center mt-2'}>
        <span className={'mr-10 w-30'}>Team Required</span>
        <RadioButton
          name={PreGame.teamRequired}
          value={true}
          label={'True'}
          disabled={!isEnableGameLobby}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.preGame.isPreGameTeamRequired &&
            isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />

        <RadioButton
          name={PreGame.teamRequired}
          value={false}
          label={'False'}
          className={'ml-4'}
          disabled={!isEnableGameLobby}
          checked={
            (state.gameDocument?.settings &&
              !state.gameDocument?.settings.preGame.isPreGameTeamRequired) ||
            !isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />
      </div>
      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Players must join a team to enter the game.
        </span>
      </div>

      <div
        className={'d-flex flex-column gap-1 align-items-start mt-4 mb-2'}
        style={{ borderBottom: '1px solid #dee2e6' }}>
        <h2>Menu + Functions</h2>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-3'}>
        <span className={'mr-10 w-30'}>Teams</span>
        <RadioButton
          name={PreGame.teams}
          value={true}
          label={'Show'}
          disabled={!isEnableGameLobby}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.preGame.showPreGameTeams &&
            isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />

        <RadioButton
          name={PreGame.teams}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          disabled={!isEnableGameLobby}
          checked={
            (state.gameDocument?.settings &&
              !state.gameDocument?.settings.preGame.showPreGameTeams) ||
            !isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />
      </div>
      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Visibility of teams tab in menu. Teams tab shows the teams in the game
          <br />
          and each team's members.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-3'}>
        <span className={'mr-10 w-30'}>Create teams</span>
        <RadioButton
          name={PreGame.createTeams}
          value={true}
          label={'Show'}
          disabled={!isEnableGameLobby}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.preGame.showPreGameCreateTeams &&
            isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />

        <RadioButton
          name={PreGame.createTeams}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          disabled={!isEnableGameLobby}
          checked={
            (state.gameDocument?.settings &&
              !state.gameDocument?.settings.preGame.showPreGameCreateTeams) ||
            !isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />
      </div>
      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Enables players to create teams. Displayed on team tab.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-3'}>
        <span className={'mr-10 w-30'}>Join / leave teams</span>
        <RadioButton
          name={PreGame.joinLeaveTeams}
          value={true}
          label={'Show'}
          disabled={!isEnableGameLobby}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.preGame.showPreGameJoinLeaveTeams &&
            isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />

        <RadioButton
          name={PreGame.joinLeaveTeams}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          disabled={!isEnableGameLobby}
          checked={
            (state.gameDocument?.settings &&
              !state.gameDocument?.settings.preGame
                .showPreGameJoinLeaveTeams) ||
            !isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />
      </div>
      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Enables players to join or leave a team. Displayed on team tab.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-3'}>
        <span className={'mr-10 w-30'}>Inventory</span>
        <RadioButton
          name={PreGame.inventory}
          value={true}
          label={'Show'}
          disabled={!isEnableGameLobby}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.preGame.showPreGameInventory &&
            isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />

        <RadioButton
          name={PreGame.inventory}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          disabled={!isEnableGameLobby}
          checked={
            (state.gameDocument?.settings &&
              !state.gameDocument?.settings.preGame.showPreGameInventory) ||
            !isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />
      </div>
      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Visibility of inventory in the pre-game menu.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-3'}>
        <span className={'mr-10 w-30'}>Titles</span>
        <RadioButton
          name={PreGame.roles}
          value={true}
          label={'Show'}
          disabled={!isEnableGameLobby}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.preGame.showPreGameRoles &&
            isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />

        <RadioButton
          name={PreGame.roles}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          disabled={!isEnableGameLobby}
          checked={
            (state.gameDocument?.settings &&
              !state.gameDocument?.settings.preGame.showPreGameRoles) ||
            !isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />
      </div>
      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Visibility of titles in the pre-game menu.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-3'}>
        <span className={'mr-10 w-30'}>Scoreboard</span>
        <RadioButton
          name={PreGame.scoreboard}
          value={true}
          label={'Show'}
          disabled={!isEnableGameLobby}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.preGame.showPreGameScoreboard &&
            isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />

        <RadioButton
          name={PreGame.scoreboard}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          disabled={!isEnableGameLobby}
          checked={
            (state.gameDocument?.settings &&
              !state.gameDocument?.settings.preGame.showPreGameScoreboard) ||
            !isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />
      </div>
      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Visibility of scoreboard in the pre-game menu.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-3'}>
        <span className={'mr-10 w-30'}>Chat</span>
        <RadioButton
          name={PreGame.chats}
          value={true}
          label={'Show'}
          disabled={!isEnableGameLobby}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.preGame.showPreGameChat &&
            isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />

        <RadioButton
          name={PreGame.chats}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          disabled={!isEnableGameLobby}
          checked={
            (state.gameDocument?.settings &&
              !state.gameDocument?.settings.preGame.showPreGameChat) ||
            !isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />
      </div>
      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Visibility of chat in the pre-game menu.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-3'}>
        <span className={'mr-10 w-30'}>Facilitator Chat</span>
        <RadioButton
          name={PreGame.facilitatorChat}
          value={true}
          label={'Show'}
          disabled={!isEnableGameLobby}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.preGame.showPreGameFacilitatorChat &&
            isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />

        <RadioButton
          name={PreGame.facilitatorChat}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          disabled={!isEnableGameLobby}
          checked={
            (state.gameDocument?.settings &&
              !state.gameDocument?.settings.preGame
                .showPreGameFacilitatorChat) ||
            !isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />
      </div>
      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Enables a player to message the game controller.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-3'}>
        <span className={'mr-10 w-30'}>All Player Chat</span>
        <RadioButton
          name={PreGame.allPlayerChat}
          value={true}
          label={'Show'}
          disabled={!isEnableGameLobby}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.preGame.showPreGameAllPlayerChat &&
            isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />

        <RadioButton
          name={PreGame.allPlayerChat}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          disabled={!isEnableGameLobby}
          checked={
            (state.gameDocument?.settings &&
              !state.gameDocument?.settings.preGame.showPreGameAllPlayerChat) ||
            !isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />
      </div>
      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Enables a player to message all players in the game.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-3'}>
        <span className={'mr-10 w-30'}>Direct Chat</span>
        <RadioButton
          name={PreGame.directChat}
          value={true}
          label={'Show'}
          disabled={!isEnableGameLobby}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.preGame.showPreGameDirectChat &&
            isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />

        <RadioButton
          name={PreGame.directChat}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          disabled={!isEnableGameLobby}
          checked={
            (state.gameDocument?.settings &&
              !state.gameDocument?.settings.preGame.showPreGameDirectChat) ||
            !isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />
      </div>
      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Enables a player to message another player.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-3'}>
        <span className={'mr-10 w-30'}>Team Chat</span>
        <RadioButton
          name={PreGame.teamChat}
          value={true}
          label={'Show'}
          disabled={!isEnableGameLobby}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.preGame.showPreGameTeamChat &&
            isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />

        <RadioButton
          name={PreGame.teamChat}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          disabled={!isEnableGameLobby}
          checked={
            (state.gameDocument?.settings &&
              !state.gameDocument?.settings.preGame.showPreGameTeamChat) ||
            !isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />
      </div>
      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Enables a player to message all players in a team.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-3'}>
        <span className={'mr-10 w-30'}>Group Chat</span>
        <RadioButton
          name={PreGame.groupChat}
          value={true}
          label={'Show'}
          disabled={!isEnableGameLobby}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.preGame.showPreGameGroupChat &&
            isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />

        <RadioButton
          name={PreGame.groupChat}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          disabled={!isEnableGameLobby}
          checked={
            (state.gameDocument?.settings &&
              !state.gameDocument?.settings.preGame.showPreGameGroupChat) ||
            !isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />
      </div>
      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Enables a player to message all players in a group.
        </span>
      </div>

      <div className={'d-flex gap-1 align-items-center mt-3'}>
        <span className={'mr-10 w-30'}>Trade</span>
        <RadioButton
          name={PreGame.trade}
          value={true}
          label={'Show'}
          disabled={!isEnableGameLobby}
          checked={
            state.gameDocument?.settings &&
            state.gameDocument?.settings.preGame.showPreGameTrade &&
            isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />

        <RadioButton
          name={PreGame.trade}
          value={false}
          label={'Hide'}
          className={'ml-4'}
          disabled={!isEnableGameLobby}
          checked={
            (state.gameDocument?.settings &&
              !state.gameDocument?.settings.preGame.showPreGameTrade) ||
            !isEnableGameLobby
          }
          onChange={handlePreGameSettingChange}
        />
      </div>
      <div className={'d-flex gap-1 align-items-center mb-2'}>
        <span className={'mb-2 fs-6 text-black-50'}>
          <span className={'material-symbols-outlined fs-6'}>lightbulb</span>
          Enables a player to trade with another player.
        </span>
      </div>
    </>
  );
};

export default PreGameSettings;
