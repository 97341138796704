import React from 'react';

export const NoRecords: React.FC = () => {
  return (
    <div className={'p-4 d-flex justify-content-center flex-column text-muted'}>
      <span className="material-symbols-outlined">zone_person_alert</span>
      <small>No data found</small>
    </div>
  );
};
