import React from 'react';

export interface AddStepProps {
  onAddControlStructureClick?: (event: any) => void;
  onAddStepClick?: (event: any) => void;
  onAddConjunctionClick?: (event: any) => void;
  onAddDisjunctionClick?: (event: any) => void;
  isCompoundStatement: boolean;
}

export class AddStep extends React.Component<AddStepProps> {
  constructor(props: AddStepProps) {
    super(props);
    this.handleAddControlStructure = this.handleAddControlStructure.bind(this);
    this.handleAddStep = this.handleAddStep.bind(this);
    this.handleAddConjunctionStep = this.handleAddConjunctionStep.bind(this);
  }

  handleAddControlStructure = (event: any) =>
    this.emitAddControlStructureDebounced(event);
  emitAddControlStructureDebounced = this.emitAddControlStructure;

  emitAddControlStructure(event: any) {
    if (this.props.onAddControlStructureClick)
      this.props.onAddControlStructureClick(event);
  }

  handleAddStep = (event: any) => this.emitAddStepDebounced(event);
  emitAddStepDebounced = this.emitAddStep;

  emitAddStep(event: any) {
    if (this.props.onAddStepClick) this.props.onAddStepClick(event);
  }

  handleAddConjunctionStep = (event: any) =>
    this.emitAddConjunctionStepDebounced(event);
  emitAddConjunctionStepDebounced = this.emitAddConjunctionStep;

  emitAddConjunctionStep(event: any) {
    if (this.props.onAddConjunctionClick)
      this.props.onAddConjunctionClick(event);
  }

  handleAddDisjunctionStep = (event: any) => this.emitAddDisjunctionStep(event);
  emitAddDisjunctionStepDebounced = this.emitAddConjunctionStep;

  emitAddDisjunctionStep(event: any) {
    if (this.props.onAddDisjunctionClick)
      this.props.onAddDisjunctionClick(event);
  }

  render() {
    return (
      <div className={'row mt-2 add-step'}>
        <div className={'col'}>
          <a className={'divider'}>
            {this.props.onAddControlStructureClick && (
              <button
                className={'btn btn-link btn-sm'}
                title={'Add Control Structure'}
                onClick={this.handleAddControlStructure}>
                <span className={'material-symbols-outlined'}>alt_route</span>
              </button>
            )}
            {this.props.onAddStepClick && (
              <button
                className={'btn btn-link btn-sm'}
                title={'Add Step'}
                onClick={this.handleAddStep}>
                <span className={'material-symbols-outlined'}>
                  add_circle_outline
                </span>
              </button>
            )}
            {this.props.isCompoundStatement && (
              <button
                className={'btn btn-link btn-sm'}
                title={'Add Conjunction'}
                onClick={this.handleAddConjunctionStep}>
                <span className="material-symbols-outlined">
                  expand_circle_up
                </span>
              </button>
            )}
            {this.props.isCompoundStatement && (
              <button
                className={'btn btn-link btn-sm'}
                title={'Add Disjunction'}
                onClick={this.handleAddDisjunctionStep}>
                <span className="material-symbols-outlined">
                  expand_circle_down
                </span>
              </button>
            )}
          </a>
        </div>
      </div>
    );
  }
}
