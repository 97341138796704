import { Button } from '@progress/kendo-react-buttons';
import merge from 'lodash.merge';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Toolbar from '../../components/page-toolbar';
import { PrivatePage } from '../../components/private-page';
import { AssessmentContentFullEditor } from '../../features/assessment/assessment-content-full-editor';
import {
  AddResourcePackAsync,
  UpdateAssessmentResourcePackDetail
} from '../../features/assessment/assessment-document';
import { ResourcePackEditorWindow } from '../../features/game-document/resource-packs/resource-pack-editor-window';
import {
  addAssessmentAsync,
  getAssessmentById,
  getDocumentLatestAsync,
  getDocumentsByAssessmentIdAsync,
  publishAssessmentAsync,
  updateDocumentsByAssessmentIdAsync
} from '../../services/assessment';
import { GetLanguagesAsync } from '../../services/country';
import { appStore } from '../../stores/app-store';
import { toastStore } from '../../stores/toast-store';
import {
  Assessment,
  AssessmentApproval,
  AssessmentSettings
} from '../../types/assessment';
import { ResourcePackEntity } from '../../types/game-document/entities';
import { EntityEditor } from '../../types/game-document/entity-editor';
import { GetUpdatedVersionAsync } from '../../utils/game-document';
import { Dialog } from '@progress/kendo-react-dialogs';
import SubmitConfirmation from '../../components/submit-to-global-library';
import HelpSupport from '../../components/help-support';
import {
  DisplayLanguagePublished,
  GetDisplayLanguagePublished
} from '../../services/admin-display-language';
import { CountryResponse } from '../../types/country-response';
import { DefaultAssessmentSettings } from '../../constants/default-asessment-settings';
import { isArrayEqual } from '@progress/kendo-react-layout';
import { validateSettings } from '../../utils/assessment-document/validate-setting';

const defaultEntity = (): Assessment => {
  return {
    id: 0,
    name: '',
    title: '',
    organisationId: 1,
    htmlContent: '',
    description: '',
    version: 'v0.0.1',
    status: 'New',
    settings: DefaultAssessmentSettings
  };
};

const resourcePackEntity: ResourcePackEntity = {
  id: '',
  name: '',
  description: '',
  resources: []
};

const AssessmentDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [entity, setEntity] = useState<Assessment>();
  const [languages, setLanguages] = useState<CountryResponse[]>([]);
  const [displayLanguages, setDisplayLanguages] = useState<
    DisplayLanguagePublished[]
  >([]);
  const [selectedTab, setSelectedTab] = useState<string>('');
  const [entityEditorIsVisible, setEntityEditorIsVisible] =
    useState<boolean>(false);
  const [entityEditorValue, setEntityEditorValue] =
    useState<EntityEditor<ResourcePackEntity>>();
  const [currentLocale, setCurrentLocale] = useState<string>();
  const [latestStatus, setLatestStatus] = useState<string>('');
  const [assessmentLanguages, setAssessmentLanguages] = useState<string[]>([]);

  const [showDialog, setShowDialog] = useState<boolean>(false);

  const toggleDialog = () => {
    setShowDialog(!showDialog);
  };

  const newAssessmentBreadcrumb = [
    { label: 'Dashboard', href: '../dashboard' },
    { label: 'Assessment', href: '../mylibrary/assessment' }
  ];

  const detailAssessmentBreadcrumb = [
    { label: 'Dashboard', href: '../dashboard' },
    { label: 'Assessment', href: '../mylibrary/assessment' },
    {
      label: 'Assessment Details',
      href: `../assessment-details/${id}`
    }
  ];

  const getDetails = async () => {
    try {
      appStore.showLoading();

      // combine assessment and assessment documents (resources)
      let assess1 = await getAssessmentById(parseInt(id!));
      let assess2 = await getDocumentsByAssessmentIdAsync(parseInt(id!));

      let assessment = merge(assess1, assess2);

      //set default value for enableAssignToOnActionPlan and enableDueDateOnActionPlan #5766
      if (!assessment.settings) {
        assessment.settings = DefaultAssessmentSettings;
      }

      const defaultSetting = Object.keys(DefaultAssessmentSettings).sort();
      const currentSetting = Object.keys(assessment.settings).sort();

      // handle old Asessment settings, replace with new Asessment V2 settings
      if (
        !isArrayEqual(defaultSetting, currentSetting) ||
        !validateSettings(DefaultAssessmentSettings, assessment.settings)
      ) {
        assessment.settings = DefaultAssessmentSettings;
      }

      setEntity(assessment);
    } catch (ex) {
      console.error(ex);
    } finally {
      appStore.hideLoading();
    }
  };

  const setDefaultEntity = () => {
    setEntity(defaultEntity);
  };

  const handleEntityChange = (newEntity: Assessment) => {
    setEntity(newEntity);
  };

  const handleAssessmentLanguages = (assessmentLanguages: string[]) => {
    if (entity?.language && entity?.language?.toString()) {
      let index = assessmentLanguages.indexOf(entity?.language?.toString()!);

      if (index === -1) {
        setAssessmentLanguages(
          assessmentLanguages.concat(entity?.language?.toString()!)
        );
      } else {
        setAssessmentLanguages(assessmentLanguages);
      }
    } else {
      setAssessmentLanguages(assessmentLanguages);
    }
  };

  const onSubmitHandler = async () => {
    try {
      appStore.showLoading();
      if (id) {
        // update assessment (only docs returned) and combine it with assessment object
        let assess1 = await updateDocumentsByAssessmentIdAsync(
          parseInt(id!),
          entity!
        );
        let assess2 = await getAssessmentById(parseInt(id!));

        let assessment = merge(assess1, assess2);
        setEntity(assessment);
      } else {
        await addAssessmentAsync(entity!);
        navigate(`/mylibrary/assessment`);
      }
    } catch (ex) {
      console.error(ex);
    } finally {
      appStore.hideLoading();
    }
  };

  /**
   * Publish assessment
   */
  const onPublishHandler = async () => {
    try {
      appStore.showLoading();
      if (id) {
        let newVersion = await GetUpdatedVersionAsync('minor', entity!.version);

        entity!.version = newVersion ?? '';
        let resultUpdate = await updateDocumentsByAssessmentIdAsync(
          parseInt(id!),
          entity!
        );

        let document = await getDocumentLatestAsync(parseInt(id!));

        let request: AssessmentApproval = {
          assessmentId: parseInt(id!),
          fileName: document?.fileName ?? '',
          version: resultUpdate.version ?? '',
          status: 'Published',
          isDeleted: false
        };

        let result = await publishAssessmentAsync(
          parseInt(id!),
          document.id,
          request
        );

        if (entity) {
          let newEntity = { ...entity };
          if (newEntity) {
            newEntity.status = result.status;
            newEntity.version = resultUpdate.version ?? '';
            newEntity.modifiedDateUtc = result.modifiedDateUtc;
            setEntity(newEntity!);
            setLatestStatus(result?.status ?? '');
          }
        }

        toastStore.show(
          'Assessment Detail',
          <div>Assessment published.</div>,
          'success'
        );
      }
    } catch (ex) {
      toastStore.show(
        'Assessment Detail',
        <div>Failed to publish assessment.</div>,
        'error'
      );
      console.error(ex);
    } finally {
      appStore.hideLoading();
    }
  };

  /**
   * Submit assessment for approval
   */
  const onSubmitGlobalLibraryHandler = async () => {
    try {
      appStore.showLoading();
      if (id) {
        let newVersion = await GetUpdatedVersionAsync('minor', entity!.version);
        entity!.version = newVersion ?? '';

        let resultUpdate = await updateDocumentsByAssessmentIdAsync(
          parseInt(id!),
          entity!
        );

        let document = await getDocumentLatestAsync(parseInt(id!));

        let request: AssessmentApproval = {
          assessmentId: parseInt(id!),
          fileName: document?.fileName ?? '',
          version: resultUpdate?.version ?? '',
          status: 'Pending',
          isDeleted: false
        };

        let result = await publishAssessmentAsync(
          parseInt(id!),
          document.id,
          request
        );

        if (entity) {
          let newEntity = { ...entity };
          if (newEntity) {
            newEntity.status = result.status;
            setEntity(newEntity!);
            setLatestStatus(result?.status ?? '');
          }
        }

        toastStore.show(
          'Assessment Detail',
          <div>Assessment submitted and waiting approval.</div>,
          'success'
        );
      }
    } catch (ex) {
      toastStore.show(
        'Assessment Detail',
        <div>Failed to publish assessment.</div>,
        'error'
      );
      console.error(ex);
    } finally {
      appStore.hideLoading();
      toggleDialog();
    }
  };

  const getLatestDocumentAsync = async () => {
    let document = await getDocumentLatestAsync(parseInt(id!));
    setLatestStatus(document?.status ?? '');
  };

  const getLanguages = async () => {
    const response = await GetLanguagesAsync();
    setLanguages(response.data);
  };

  const getDisplayLanguages = async () => {
    const response = await GetDisplayLanguagePublished();
    setDisplayLanguages(response.data);
  };

  const onAddEntity = () => {
    setEntityEditorValue({
      isNew: true,
      entity: resourcePackEntity
    });

    toggleEntityEditor();
  };

  const onEditEntity = () => {
    const resourcePack = entity?.resourcePacks!;
    const resourcePackIndex = entity?.resourcePacks?.findIndex(
      (i) => i.name === currentLocale
    );

    if (resourcePackIndex !== undefined && resourcePackIndex > -1) {
      let currentEntity = resourcePack[resourcePackIndex!];

      setEntityEditorValue({
        isNew: false,
        entity: {
          id: currentEntity.id,
          description: currentEntity.description,
          resources: currentEntity.resources,
          name: currentEntity.name,
          displayLanguageIso: currentEntity.displayLanguageIso,
          displayLanguage: currentEntity.displayLanguage,
          displayLanguageUrl: currentEntity.displayLanguageUrl
        }
      });
    }
    toggleEntityEditor();
  };

  const handleEntityEditorSubmit = async (
    editorEntity: EntityEditor<ResourcePackEntity>,
    isCopyOriginal?: boolean,
    displayLanguage?: string
  ) => {
    const selectedDisplayLanguage = displayLanguages.find(
      (i) => i.languageName === displayLanguage
    );
    if (editorEntity.isNew) {
      await AddResourcePackAsync(
        entity!,
        editorEntity?.entity?.name,
        languages.find((i) => i.language === editorEntity?.entity?.name)
          ?.countryIso ?? 'en',
        isCopyOriginal,
        selectedDisplayLanguage!.languageName,
        selectedDisplayLanguage!.blobFileUrl
      ).then((response) => {
        setEntity((prev) => ({ prev, ...response }));
        setCurrentLocale(editorEntity?.entity?.name);
      });
    } else {
      /* Updates the resource pack's detail in the Assessment Document.
       * Not Update resource.
       */
      UpdateAssessmentResourcePackDetail(
        entity!,
        editorEntity.entity.name,
        languages.find((i) => i.language === editorEntity?.entity?.name)
          ?.countryIso ?? 'en',
        editorEntity.entity.id,
        selectedDisplayLanguage!.languageName!,
        selectedDisplayLanguage!.blobFileUrl!
      ).then((response) => {
        setEntity((prev) => ({ prev, ...response }));
        setCurrentLocale(editorEntity?.entity?.name);
      });
    }

    toggleEntityEditor();
  };

  const toggleLanguageTab = (activeTab: string) => {
    if (activeTab === 'language') {
      setSelectedTab(activeTab);
    } else {
      setSelectedTab('');
    }
  };

  const toggleEntityEditor = () => {
    setEntityEditorIsVisible(!entityEditorIsVisible);
  };

  const disableSave = (): boolean => {
    if (latestStatus === 'Pending') {
      return true;
    } else if (entity && (!entity.name || !entity.language)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (id) {
      getDetails();
      getLatestDocumentAsync();
    } else {
      setDefaultEntity();
    }

    getLanguages();
    getDisplayLanguages();
  }, []);

  return (
    <PrivatePage
      breadcrumb={id ? detailAssessmentBreadcrumb : newAssessmentBreadcrumb}
      pageTitle={'Assessment'}>
      <>
        <Toolbar title={id ? (entity?.name ?? '') : 'New Assessment'}>
          {id === undefined && (
            <HelpSupport
              title="My Library - Assessment - Creating, Copying + Editing"
              url="https://forum.catalystglobal.com/t/my-library-assessment-new"
            />
          )}
          <Button
            disabled={disableSave()}
            themeColor={'secondary'}
            onClick={onSubmitHandler}>
            Save
          </Button>
          {entity?.status === 'Published' && (
            <Button onClick={toggleDialog} themeColor={'primary'}>
              Submit to Global Library
            </Button>
          )}
          {id && (
            <Button
              disabled={disableSave() ?? undefined}
              themeColor={'warning'}
              onClick={onPublishHandler}>
              Publish
            </Button>
          )}

          {entityEditorIsVisible && (
            <ResourcePackEditorWindow
              toggleDialog={toggleEntityEditor}
              onClose={toggleEntityEditor}
              editorEntity={entityEditorValue!}
              editorMode={'basic'}
              onSubmit={handleEntityEditorSubmit}
              isLanguageCombobox={true}
              listedLanguages={assessmentLanguages}></ResourcePackEditorWindow>
          )}
        </Toolbar>
        <hr />
        <Container>
          {entity && (
            <>
              <AssessmentContentFullEditor
                languages={languages}
                displayLanguages={displayLanguages}
                entity={entity}
                currentLocale={currentLocale}
                setCurrentLocale={setCurrentLocale}
                onAddEntity={onAddEntity}
                onEditEntity={onEditEntity}
                handleEntityChange={handleEntityChange}
                handleAssessmentLanguages={handleAssessmentLanguages}
                toggleLanguageTab={toggleLanguageTab}
              />
            </>
          )}
        </Container>

        {showDialog && (
          <Dialog
            width={'50vw'}
            title={
              <span className="fw-bold text-primary">
                Submit to global library
              </span>
            }
            onClose={toggleDialog}>
            <SubmitConfirmation
              toggleDialog={toggleDialog}
              onSubmit={onSubmitGlobalLibraryHandler}
              isPendingApproval={entity?.status === 'Pending'}
            />
          </Dialog>
        )}
      </>
    </PrivatePage>
  );
};

export default AssessmentDetail;
