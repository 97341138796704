type AsideProps = {
  client?: string;
  agency?: string;
  children?: any;
};

const Aside = ({ client, agency, ...props }: AsideProps) => {
  return (
    <div className={'flex-grow-1 sticky-top z-index-10'}>
      <div className={' border border-1 bg-light p-2 rounded'}>
        <div className={'d-flex flex-column gap-2'}>
          <div className={'d-flex flex-column'}>
            <span>Client</span>
            <b className={'text-success'}>{client}</b>
          </div>
          <div className={'d-flex flex-column'}>
            <span>Agency</span>
            <b className={'text-success'}>{agency}</b>
          </div>
        </div>
        {props.children}
      </div>
    </div>
  );
};

export default Aside;
