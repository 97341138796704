import merge from 'lodash.merge';
import { Assessment, AssessmentApproval, Language } from '../types/assessment';
import {
  DeleteAsync,
  GetAllAsync,
  GetAsync,
  PostAsync,
  PatchAsync
} from './api';
import {
  GetResourceEntity,
  UpdateResourceAsync
} from '../features/assessment/assessment-document';

const AssessmentEndpoint = 'assessments';
const PublishedAssessmentEndpoint = 'event/assessments';

/**
 *
 * @param params Get assessment list
 * @returns
 */
export const getAssessments = (params?: string) =>
  GetAllAsync<Assessment>(`${AssessmentEndpoint}/?${params}`);

/**
 *
 * @param params Get apublished ssessment list
 * @returns
 */
export const getPublishedAssessments = (params?: string) =>
  GetAllAsync<Assessment>(`${PublishedAssessmentEndpoint}/?${params}`);

/**
 *
 * @param params Get assessment list
 * @returns
 */
export const getGlobalAssessments = (params?: string) =>
  GetAllAsync<Assessment>(`library/${AssessmentEndpoint}/?${params}`);

/**
 *
 * @param params Get assessment list
 * @returns
 */
export const getAssessmentsPendingApproval = (params?: string) =>
  GetAllAsync<Assessment>(`admin/${AssessmentEndpoint}/?${params}`);

/**
 * Deletes a assessment by id.
 * @param id - the ID of the Assessment to DELETE
 * @returns the deleted Assessment object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/DELETE
 */
export const DeleteAssessmentAsync = async (id: number) =>
  DeleteAsync<Assessment>(`${AssessmentEndpoint}/${id}`);

/**
 * Duplicate a assessment by id.
 * @param id - the ID of the Assessment to Duplicate
 * @returns the duplicated Assessment object.
 */
export const DuplicateAssessmentAsync = async (id: number) =>
  PostAsync<boolean>(`${AssessmentEndpoint}/duplicate/${id}`, {} as any);

/**
 *
 * @param id Get assessment by id
 * @returns
 */
export const getAssessmentById = (id: number) =>
  GetAsync<Assessment>(`${AssessmentEndpoint}/${id}`);

/**
 *
 * @param id Get assessment by id
 * @returns
 */
export const getGlobalAssessmentById = (id: number) =>
  GetAsync<Assessment>(`library/${AssessmentEndpoint}/${id}`);

/**
 *
 * @param id - the ID of the entity to update [PATCH]
 * @param assessment - the User to update [PATCH]
 * @returns
 */
export const updateAssessmentAsync = async (
  id: number,
  assessment: Assessment
) => PatchAsync<Assessment>(`${AssessmentEndpoint}/${id}`, assessment);

/**
 *
 * @param assessment - the assessment to add
 * @returns
 */
export const addAssessmentAsync = async (assessment: Assessment) =>
  PostAsync<Assessment>(`${AssessmentEndpoint}`, assessment);

/**
 *
 * @param id Get resource documents by assessment id (for language tab in main portal assessment tab)
 * @returns
 */
export const getDocumentsByAssessmentIdAsync = (id: number) =>
  GetAsync<Assessment>(`${AssessmentEndpoint}/${id}/documents/latest/content`);

/**
 *
 * @param assessmentId Get resource documents by assessment id (for language tab in main portal assessment tab)
 * @param documentId Get resource documents by document id
 * @returns
 */
export const getGlobalContentByAssessmentIdAsync = (
  assessmentId: number,
  documentId: number
) =>
  GetAsync<Assessment>(
    `library/${AssessmentEndpoint}/${assessmentId}/document/${documentId}/contents`
  );

/**
 * Get latest document
 * @param id
 * @returns
 */
export const getDocumentLatestAsync = (id: number) =>
  GetAsync<Assessment>(`${AssessmentEndpoint}/${id}/documents/latest`);

/**
 * Get latest document
 * @param id
 * @returns
 */
export const getGlobalDocumentLatestAsync = (id: number) =>
  GetAsync<Assessment>(`library/${AssessmentEndpoint}/${id}/documents/latest`);

/**
 *
 * @param id - the assessment id of the entity to update [PATCH] (for language tab in main portal assessment tab)
 * @param assessment - the resource documents to update [PATCH] (for language tab in main portal assessment tab)
 * @returns
 */
export const updateDocumentsByAssessmentIdAsync = async (
  id: number,
  assessment: Assessment
) =>
  PatchAsync<Assessment>(`${AssessmentEndpoint}/${id}/documents`, assessment);

/**
 *
 * @param id Get language list from resources by assessment id (for language tab in main portal assessment tab)
 * @returns
 */
export const getDocumentLanguagesByAssessmentIdAsync = (id: number) =>
  GetAsync<Language[]>(`${AssessmentEndpoint}/${id}/documents/languages`);

/**
 * Publish assessment
 * @param id
 * @param documentId
 * @param assessment
 * @returns
 */
export const publishAssessmentAsync = async (
  id: number,
  documentId: number,
  assessment: AssessmentApproval
) =>
  PatchAsync<Assessment>(
    `${AssessmentEndpoint}/${id}/documents/${documentId}`,
    assessment
  );

/**
 * Post Assessment Documents
 * @param assessment - the assessment data
 */
export const addAssessmentDocuments = async (assessment: Assessment) => {
  const param = {
    status: assessment.status,
    version: assessment.version,
    filename: assessment.name
  };
  return PostAsync<boolean>(
    `${AssessmentEndpoint}/${assessment.id}/documents`,
    param as any
  );
};

/**
 * Post Assessment Documents
 * @param assessment - the assessment data
 * @param documentId - the assessment document data id
 */
export const addAssessmentDocumentContent = async (
  assessment: Assessment,
  documentId: number
) => {
  PostAsync<boolean>(
    `${AssessmentEndpoint}/${assessment.id}/document/${documentId}/contents`,
    '' as any
  );
};

/**
 * Copy Global Assessment into my library assessment
 * @param assessment - the assessment to add
 */
export const copyAssessmentToLibrary = async (assessment: Assessment) => {
  const assessmentEntity = { ...assessment };

  assessmentEntity.id = 0;
  assessmentEntity.name = 'COPY_' + assessment.name;
  assessmentEntity.status = 'New';

  // add new assessment in my library
  const newAssessment = await addAssessmentAsync(assessmentEntity);

  // merge newly-added assessment with inserted assessment documents / resources
  let assessmentComplete = merge(assessment, newAssessment);

  // to update name, title and description from the resource of the copied assessment to become "COPY_[copied_assessment]"
  let updateInfo: string[] = [
    assessmentComplete.nameResId!,
    assessmentComplete.titleResId!,
    assessmentComplete.descriptionResId!
  ];

  // update its resource
  for (let i = 0; i < updateInfo.length; i++) {
    let resource = GetResourceEntity(assessmentComplete, updateInfo[i]);
    if (resource) {
      resource.value = 'COPY_' + resource.value;
      assessmentComplete = await UpdateResourceAsync(
        assessmentComplete,
        resource?.id,
        resource
      );
    }
  }

  // update its resource packs
  // currently left empty here, just in case if each of the packs needed to be added the "COPY_" text for its name, title and description, just override this comment

  // update assessment with its updated documents / resources
  await updateDocumentsByAssessmentIdAsync(
    assessmentComplete.id,
    assessmentComplete!
  );
};
