import { GameDocumentContext } from '../../../contexts/game-document';
import React, { useContext, useEffect, useState } from 'react';
import Toolbar from '../toolbar';
import {
  DeleteMapAsync,
  DeleteResourceAsync,
  UpdateGameDocState,
  UpdateMapAsync,
  UpdateResourceAsync
} from '../../../utils/game-document';
import { Button } from '@progress/kendo-react-buttons';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CheckboxChangeEvent,
  InputChangeEvent
} from '@progress/kendo-react-inputs';
import {
  MapEditor,
  MapEditorWindow
} from '../../../features/game-document/maps';
import { EntityEditor } from '../../../types/game-document/entity-editor';
import { MapEntity, ResourceEntity } from '../../../types/game-document/';
import { FluidForm } from '../../../components/forms';

const Map = () => {
  const { mapId } = useParams();
  const [state, setState] = useContext(GameDocumentContext);
  const navigate = useNavigate();

  const getEntityById = (entityId: string) =>
    state.gameDocument?.assets.maps?.find((i) => i.id === entityId)!;

  const [entity, setEntity] = useState<MapEntity>(() => getEntityById(mapId!));
  const [titleResource, setTitleResource] = useState<ResourceEntity>();
  const [resources, setResources] = useState<ResourceEntity[]>([]);

  useEffect(() => {
    setEntity(getEntityById(mapId!));
  }, [state]);

  useEffect(() => {
    // Set the page title.
    document.title = `${entity.name} - ${state.gameDocument?.name}`;
    if (entity) {
      const titleRes = state.gameDocument?.resources.find(
        (item) => item.id === entity.titleResId
      )! as ResourceEntity;
      setTitleResource(titleRes);
      setResources([titleRes]);
    }
  }, [state, entity]);

  const handleInputChange = (event: InputChangeEvent | CheckboxChangeEvent) => {
    const { name, value } = event.target;

    if (name === 'titleResId') {
      setResources(
        resources.map((item) =>
          item.id === entity[name]
            ? { ...item, value: value! as string }
            : { ...item }
        )
      );
      const resource = resources.find((item) => item.id === entity[name])!;
      resource.value = value as string;

      UpdateResourceAsync(state.gameDocument!, resource?.id, resource).then(
        (updatedGameDocument) => {
          setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
        }
      );
    } else {
      setEntity((prev) => ({
        ...prev,
        [name!]: value
      }));
      UpdateMapAsync(state.gameDocument!, mapId!, {
        ...entity,
        [name!]: value
      }).then((updatedGameDocument) => {
        setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
      });
    }
  };

  const [entityEditorIsVisible, setEntityEditorIsVisible] =
    useState<boolean>(false);
  const toggleEntityEditor = () => {
    setEntityEditorIsVisible(!entityEditorIsVisible);
  };

  const handleEntityEditorSubmit = (
    editorEntity: EntityEditor<MapEntity>,
    resourceEntity: EntityEditor<ResourceEntity>[]
  ) => {
    const mapResource = resourceEntity.find(
      (resource) => resource.entity.id === editorEntity.entity.titleResId
    );

    const updateMapResource = UpdateResourceAsync(
      state.gameDocument!,
      editorEntity.entity.titleResId!,
      mapResource?.entity!
    );

    Promise.all([updateMapResource]).then(() => {
      UpdateMapAsync(
        state.gameDocument!,
        editorEntity.entity.id,
        editorEntity.entity
      ).then((updatedGameDocument) => {
        setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
        setEntityEditorIsVisible(false);
      });
    });
  };

  const onDeleteEntity = async (entityId: string) => {
    const deletedEntity = state.gameDocument?.assets.maps?.find(
      (i) => i.id === entityId
    )!;

    Promise.all([
      DeleteResourceAsync(state.gameDocument!, deletedEntity.titleResId!)
    ]).then(() => {
      DeleteMapAsync(state.gameDocument!, entityId).then(
        (updatedGameDocument) => {
          setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
          navigate('../');
        }
      );
    });
  };

  return (
    <>
      <Toolbar title={entity?.name}>
        <Button
          onClick={() => onDeleteEntity(entity.id!)}
          themeColor={'error'}
          fillMode={'flat'}>
          <span className="material-symbols-outlined">delete</span>
        </Button>
        <Button
          onClick={toggleEntityEditor}
          themeColor={'success'}
          fillMode={'flat'}>
          <span className="material-symbols-outlined">edit</span>
        </Button>
        {entityEditorIsVisible && (
          <MapEditorWindow
            toggleDialog={toggleEntityEditor}
            onSubmit={handleEntityEditorSubmit}
            onClose={toggleEntityEditor}
            editorEntity={{ isNew: false, entity: entity }}
            editorMode={'basic'}
            editorResource={[{ isNew: false, entity: titleResource! }]}
          />
        )}
      </Toolbar>
      <FluidForm>
        <MapEditor
          editorMode={'full'}
          entity={entity}
          handleInputChange={handleInputChange}
          resources={resources}
        />
      </FluidForm>
    </>
  );
};

export default Map;
