import { GetNextAssetNameAsync, MergeAssets } from '..';
import { GameDocument, MapImage } from '../../../types/game-document';

/**
 * Updates the identified Map Image in the Game Document.
 * @param gameDocument - The Game Document to modify
 * @param mapImageId - The ID of the Map Image to update
 * @param mapImage - The updated Map Image
 * @param renameDuplicate - Auto rename 'name' key if duplicate
 * @constructor
 */
export const UpdateMapImageAsync = async (
  gameDocument: GameDocument,
  mapImageId: string,
  mapImage: MapImage,
  renameDuplicate: boolean = true
) => {
  let mapImages = gameDocument.assets.mapImages ?? [];
  let mapImageIndex = mapImages.findIndex((i) => i.id === mapImageId)!;
  if (renameDuplicate)
    mapImage.name = await GetNextAssetNameAsync(
      mapImages,
      mapImage.name,
      mapImage.id
    );
  mapImages[mapImageIndex] = mapImage;
  return MergeAssets(gameDocument, mapImages, 'mapImages');
};
