import { process, State } from '@progress/kendo-data-query';
import { Button, Button as KendoButton } from '@progress/kendo-react-buttons';
import {
  DropDownList,
  DropDownListChangeEvent
} from '@progress/kendo-react-dropdowns';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridNoRecords,
  GridToolbar
} from '@progress/kendo-react-grid';
import { Error, Label } from '@progress/kendo-react-labels';
import cloneDeep from 'lodash.clonedeep';
import {
  cloneElement,
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import LanguageComboBox from '../../components/combobox/language-combobox';
import { HtmlEditor } from '../../components/editor/html-editor';
import { ComboboxInput, FormInput } from '../../components/form-input';
import { StandardInput, StandardTextarea } from '../../components/forms';
import {
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../../components/grid/grid-tool-bar';
import { NoRecords } from '../../components/grid/no-records';
import { AnswerCheckboxFeedback } from '../../components/questionnaire/answer-checkbox-feedback';
import { AnswerOptionsFeedback } from '../../components/questionnaire/answer-options-feedback';
import { DefaultGridSettings } from '../../constants/grid-settings';
import { uuid } from '../../types/common-helper';
import {
  Feedback,
  FeedbackDetailOptions,
  FeedbackDetails
} from '../../types/feedback';
import { ResourceEntity } from '../../types/game-document/entities';
import {
  LanguageMapping,
  ResourcePack
} from '../../types/game-document/entities/resource-pack';
import RequiredFields from '../../types/required-fields';
import { formatDateToDDMMYYYY } from '../../utils/date';

import YesNoDialog from '../../components/dialogs/yes-no-dialog';
import {
  CustomColumnImage,
  CustomColumnPlainText,
  CustomColumnTextarea,
  CustomColumnVideo
} from '../../components/grid/custom-column';
import { YesNoDialogResult } from '../../types/dialog-result';
import {
  ResourceWindow,
  UploadedImage
} from '../game-document/image-resource/resource-window';
import { ResourcePackHtmlEditorWindow } from '../game-document/resource-packs/resource-pack-html-editor-window';
import {
  AddResourceEntityAsync,
  AddResourcePackResourceAsync,
  copyResourceAndPackById,
  DeleteResourceAsync,
  DeleteResourcePackResourceAsync,
  GetResourceById,
  GetResourceEntity,
  GetResourcePackByName,
  GetResourcePackResource,
  GetResourcePacks,
  GetResourceValue,
  UpdateResourceAsync,
  UpdateResourcePackResourceAsync
} from './feedback-document';
import CoverImage from '../../components/cover-image';
import { PostMediaAsync } from '../../services/files';
import PopupMenu, { popupMenu } from '../../components/popupMenu';
import { DisplayLanguagePublished } from '../../services/admin-display-language';

export interface FeedbackContentFullEditorProps {
  languages?: string[];
  displayLanguages?: DisplayLanguagePublished[];
  entity: Feedback;
  requiredFields?: RequiredFields<Feedback>[];
  isViewMode?: boolean;
  currentLocale?: string | undefined;
  setCurrentLocale?: Dispatch<SetStateAction<string | undefined>>;
  onAddEntity?: () => void;
  onEditEntity?: () => void;
  toggleLanguageTab: (activeTab: string) => void;
  handleEntityChange?: (newEntity: Feedback) => void;
  handleFeedbackLanguages?: (newEntity: string[]) => void;
}

type selectedResource = {
  isOriginal?: boolean;
  resourceId?: string;
  type?: string;
  value?: string;
};

export const FeedbackContentFullEditor = ({
  languages,
  displayLanguages,
  entity,
  requiredFields,
  isViewMode,
  currentLocale,
  setCurrentLocale,
  toggleLanguageTab,
  onAddEntity,
  onEditEntity,
  handleEntityChange = () => {},
  handleFeedbackLanguages = () => {}
}: FeedbackContentFullEditorProps) => {
  const [questionForm, setQuestionForm] = useState<
    FeedbackDetails[] | undefined
  >(entity?.feedbackDetails ?? []);
  const [showUploadLogo, setShowUploadLogo] = useState<boolean>(false);
  const [showDeleteLogo, setShowDeletedLogo] = useState<boolean>(false);
  const contentTypes = [
    { id: 'radio', description: 'Multi choice' },
    { id: 'checkbox', description: 'Checkboxes' },
    { id: 'text', description: 'Short answer' },
    { id: 'textarea', description: 'Paragraph' },
    { id: 'number', description: 'Number' },
    { id: 'time', description: 'Time' },
    { id: 'file', description: 'File' },
    { id: 'image', description: 'Image' },
    { id: 'video', description: 'Video' }
  ];

  const initialDataState: State = {
    ...DefaultGridSettings.initialDataState
  };
  const [dataState, setDataState] = useState<State>(initialDataState);
  const [languageMap, setLanguageMap] = useState<LanguageMapping[]>([]);
  const [feedbackLanguage, setFeedbackLanguage] = useState<string[]>();
  const [uploadMediaIsVisible, setUploadMediaIsVisible] =
    useState<boolean>(false);
  const [htmlEditorVisible, setHtmlEditorVisible] = useState<boolean>(false);
  const [selectedResource, setSelectedResource] = useState<selectedResource>();
  const [showDeleteLanguageConfirm, setShowDeleteLanguageConfirm] =
    useState<boolean>(false);
  const [menuList] = useState<popupMenu[]>([
    { classIcon: 'edit', textMenu: 'Edit', textClass: '' },
    { classIcon: 'delete', textMenu: 'Delete', textClass: 'text-danger' }
  ]);

  const defaultForm = (): FeedbackDetails => {
    return {
      id: uuid(),
      question: '',
      questionResId: uuid(),
      answerType: ''
    };
  };

  const defaultResource = (): ResourceEntity => {
    return {
      id: '',
      name: '',
      description: '',
      value: '',
      type: ''
    };
  };

  const addForms = () => {
    let newEntity = cloneDeep(entity);
    let forms = cloneDeep(questionForm);
    let newForm = defaultForm();
    forms?.push(newForm);

    setQuestionForm(forms);

    // add question resource
    let questionResource = defaultResource();
    questionResource[`id`] = newForm.questionResId!;
    questionResource[`name`] = 'QuestionResId';
    questionResource[`description`] = 'QuestionResId';
    questionResource[`type`] = 'text';

    AddResourceEntityAsync(newEntity, questionResource).then(
      (responseTitleEntity) => {
        responseTitleEntity[`feedbackDetails`] = forms;
        handleEntityChange(responseTitleEntity);
      }
    );
  };

  const handleUploadLogo = (uploadedFile: UploadedImage) => {
    fetch(uploadedFile.blobUrl)
      .then((response) => response.blob())
      .then((blobData) => {
        const uniqueFilename = uuid() + uploadedFile.fileName;

        // Creating a Blob
        const blob = new Blob([blobData], { type: 'application/octet-stream' });

        // Creating a File by converting the Blob
        const fileObject = new File([blob], uniqueFilename, {
          type: 'application/octet-stream',
          lastModified: Date.now()
        });

        if (fileObject) {
          PostMediaAsync(fileObject).then((response) => {
            let newEntity = cloneDeep(entity);
            newEntity.logoUrl = response.mediaUrl;
            handleEntityChange(newEntity);
            setShowUploadLogo(false);
          });
        }
      })
      .catch((error) => {
        console.error('Error fetching blob data:', error);
      });
  };

  const handleDeleteLogo = (result: YesNoDialogResult) => {
    if (result === 'yes') {
      let newEntity = cloneDeep(entity);
      newEntity.logoUrl = '';
      handleEntityChange(newEntity);
    }

    setShowDeletedLogo(false);
  };

  const onMenuSelected = (id: number, menu: popupMenu, idString?: string) => {
    if (menu.textMenu === 'Delete') {
      setShowDeletedLogo(true);
    }
    if (menu.textMenu === 'Edit') {
      setShowUploadLogo(true);
    }
  };

  const onChangeFeedbackHandler = (value: any, keyId: keyof Feedback) => {
    let newEntity = cloneDeep(entity);
    newEntity[`${keyId}`] = value as never;

    // edit related fields via its resource
    if (newEntity.resources && newEntity.resources?.length > 0) {
      if (keyId === 'name') {
        updateResourceValue(newEntity.nameResId!, value, 'name');
      } else if (keyId === 'title') {
        updateResourceValue(newEntity.titleResId!, value, 'title');
      } else if (keyId === 'description') {
        updateResourceValue(newEntity.descriptionResId!, value, 'description');
      } else if (keyId === 'htmlContent') {
        updateResourceValue(newEntity.htmlContentResId!, value, 'htmlContent');
      } else if (keyId === 'htmlOutroContent') {
        updateResourceValue(
          newEntity.htmlOutroContentResId!,
          value,
          'htmlOutroContent'
        );
      } else if (keyId === 'displayLanguage') {
        newEntity.displayLanguageUrl = displayLanguages?.find(
          (i) => i.languageName === value
        )?.blobFileUrl;
      }
    }
    handleEntityChange(newEntity);
  };

  const onChangeQuestionTypeHandler = async (e: any, index: number) => {
    let newEntity = cloneDeep(entity);
    let forms = cloneDeep(questionForm);
    let updatedResource: ResourceEntity = defaultResource();

    forms![index].question = e.target.value;
    updatedResource = GetResourceById(newEntity, forms![index].questionResId!);
    updatedResource[`value`] = e.target.value;

    setQuestionForm(forms);
    newEntity[`feedbackDetails`] = forms;

    await UpdateResourceAsync(
      newEntity,
      forms![index].questionResId!,
      updatedResource
    ).then((response) => {
      handleEntityChange(response);
    });
  };

  const onChangeAnswerTypeHandler = (e: any, index: number) => {
    let newEntity = cloneDeep(entity);
    let forms = cloneDeep(questionForm);
    forms![index].answerType = e.target.value.id;

    if (e.target.value.id === 'radio' || e.target.value.id === 'checkbox') {
      forms![index]['feedbackDetailOptions'] = [
        {
          id: uuid(),
          optionResId: uuid(),
          option: ''
        }
      ];
    } else {
      forms![index]['feedbackDetailOptions'] = undefined;
    }

    setQuestionForm(forms);
    newEntity[`feedbackDetails`] = forms;
    handleEntityChange(newEntity);
  };

  const onChangeOptionAnswers = (
    feedbackDetailsOptions: FeedbackDetailOptions[],
    groupId: string,
    value?: string,
    indexOption?: number,
    action?: string
  ) => {
    let newForms = cloneDeep(questionForm);

    let newEntity = cloneDeep(entity);

    let formIndex = questionForm?.findIndex((item) => item.id === groupId) ?? 0;
    newForms![formIndex].feedbackDetailOptions = feedbackDetailsOptions;

    if (
      feedbackDetailsOptions &&
      indexOption !== undefined &&
      value !== undefined &&
      action === 'change'
    ) {
      // add or edit option answer, also add or edit related resource
      newForms![formIndex].feedbackDetailOptions![indexOption!].option =
        value ?? '';
      let resourceId =
        newForms![formIndex].feedbackDetailOptions![indexOption].optionResId;

      if (resourceId) {
        let resource = GetResourceEntity(newEntity, resourceId);

        if (resource) {
          // update existing resource

          resource.value = value;
          UpdateResourceAsync(newEntity, resource?.id, resource).then(
            (response) => {
              setQuestionForm(newForms);
              response[`feedbackDetails`] = newForms;
              handleEntityChange(response);
            }
          );
        } else {
          // add new resource

          let optionResource = defaultResource();
          optionResource[`id`] = resourceId;
          optionResource[`name`] = 'OptionResId';
          optionResource[`description`] = 'OptionResId';
          optionResource[`type`] = 'text';
          optionResource[`value`] = value;

          AddResourceEntityAsync(newEntity, optionResource).then((response) => {
            setQuestionForm(newForms);
            response[`feedbackDetails`] = newForms;
            handleEntityChange(response);
          });
        }
      }
    } else if (
      feedbackDetailsOptions &&
      indexOption !== undefined &&
      value !== undefined &&
      action === 'delete'
    ) {
      // delete existing resources and resource pack(s), if any, if user deletes option answer

      deleteResourceAndPacks(newEntity, value).then((response) => {
        setQuestionForm(newForms);
        response[`feedbackDetails`] = newForms;
        handleEntityChange(response);
      });
    } else {
      setQuestionForm(newForms);
      newEntity[`feedbackDetails`] = newForms;
      handleEntityChange(newEntity);
    }
  };

  const deleteResourceAndPacks = async (
    feedbackEntity: Feedback,
    resourceId: string
  ) => {
    DeleteResourceAsync(feedbackEntity, resourceId).then((response) => {
      if (response.resourcePacks && response.resourcePacks.length > 0) {
        DeleteResourcePackResourceAsync(response, resourceId).then(
          (response) => {
            return response;
          }
        );
      }
      return response;
    });

    return feedbackEntity;
  };

  const onDeleteFormHandler = (index: number) => {
    let newEntity = cloneDeep(entity);

    let deletedForm = questionForm?.find(
      (item, indexItem) => indexItem === index
    );

    let deletedResourceIds: string[] = [];
    deletedResourceIds.push(deletedForm?.questionResId!);
    if (
      deletedForm?.feedbackDetailOptions &&
      deletedForm?.feedbackDetailOptions.length > 0
    ) {
      deletedForm?.feedbackDetailOptions.forEach((detail) => {
        deletedResourceIds.push(detail.optionResId!);
      });
    }

    const newForms =
      questionForm && questionForm.length > 0
        ? questionForm.filter((item, indexItem) => indexItem !== index)
        : [];

    setQuestionForm(newForms);

    let deletedEntity = cloneDeep(newEntity);

    if (deletedResourceIds.length > 0) {
      deletedResourceIds.forEach((id) => {
        deleteResourceAndPacks(deletedEntity, id).then((response) => {
          deletedEntity = cloneDeep(response);
        });
      });
    }

    deletedEntity[`feedbackDetails`] = newForms;
    handleEntityChange(deletedEntity);
  };

  const onCopyFormHandler = async (index: number) => {
    let newForms = cloneDeep(questionForm);
    let newEntity = cloneDeep(entity);

    let originalIdList: string[] = [];
    let newIdList: string[] = [];
    const newForm = cloneDeep(questionForm![index]);
    if (newForm && newForm.id) {
      newForm['id'] = uuid();
      newForm['questionResId'] = uuid();
      originalIdList.push(questionForm![index].questionResId!);
      newIdList.push(newForm.questionResId!);

      if (
        newForm.feedbackDetailOptions &&
        newForm.feedbackDetailOptions.length > 0
      ) {
        newForm[`feedbackDetailOptions`].forEach((detail, detailIndex) => {
          detail[`id`] = uuid();
          detail[`optionResId`] = uuid();
          originalIdList.push(
            questionForm![index].feedbackDetailOptions![detailIndex]
              .optionResId!
          );
          newIdList.push(
            newForm.feedbackDetailOptions![detailIndex].optionResId!
          );
        });
      }
    }

    for (let index = 0; index < originalIdList.length; index++) {
      newEntity = await copyResourceAndPackById(
        newEntity,
        originalIdList[index],
        newIdList[index]
      );
    }

    newForms!.push(newForm);
    setQuestionForm(newForms);

    newEntity[`feedbackDetails`] = newForms;

    handleEntityChange(newEntity);
  };

  const toggleUploadMediaEditor = () => {
    setUploadMediaIsVisible(!uploadMediaIsVisible);
  };

  const toggleHtmlEditor = () => {
    setHtmlEditorVisible(!htmlEditorVisible);
  };

  const rowRender = (trElement: ReactElement<HTMLTableRowElement>) => {
    const trProps: any = { className: 'k-master-row k-grid-edit-row d-flex' };
    return cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children as any
    );
  };

  const languageSelectedEvent = (event: DropDownListChangeEvent) => {
    setCurrentLocale && setCurrentLocale(event.value);
  };

  const onDeleteHandler = () => {
    setShowDeleteLanguageConfirm(true);
  };

  const updateResourceValue = (
    resourceId: string,
    value: string,
    key?: string
  ) => {
    let newEntity = cloneDeep(entity);
    let resource = GetResourceEntity(newEntity, resourceId);

    if (resource) {
      // used for updating related fields while updating the related resource
      if (key === 'name') {
        newEntity.name = value;
      } else if (key === 'title') {
        newEntity.title = value;
      } else if (key === 'description') {
        newEntity.description = value;
      } else if (key === 'htmlContent') {
        newEntity.htmlContent = value;
        resource.type = 'text-html';
      } else if (key === 'htmlOutroContent') {
        newEntity.htmlOutroContent = value;
        resource.type = 'text-html';
      }

      resource.value = value;
      UpdateResourceAsync(newEntity, resource?.id, resource).then(
        (response) => {
          handleEntityChange(response);
        }
      );
    }
  };

  const updateResourcePackValue = (resourceId: string, value: string) => {
    if (currentLocale && currentLocale !== '') {
      let resource = GetResourcePackResource(entity, currentLocale, resourceId);

      // update existing resource pack resource
      if (resource) {
        resource.value = value;
        UpdateResourcePackResourceAsync(
          entity,
          currentLocale,
          resource.id,
          resource
        ).then((response) => {
          handleEntityChange(response!);
        });
      }

      // add new resource pack resource
      if (!resource) {
        let originalResource = cloneDeep(GetResourceEntity(entity, resourceId));
        originalResource.value = value;

        AddResourcePackResourceAsync(
          entity,
          currentLocale,
          originalResource!
        ).then((response) => {
          handleEntityChange(response!);
        });
      }
    }
  };

  const updateResourceHandler = (
    isOriginal: boolean,
    resourceId: string,
    value: string
  ) => {
    if (isOriginal) {
      // Update the original resource on entity.resources
      updateResourceValue(resourceId, value);
    }
    if (!isOriginal) {
      //Update the resource packs on entity.resourcePacks
      updateResourcePackValue(resourceId, value);
    }
  };

  const handleUploadMedia = (
    isOriginal: boolean,
    resourceId: string,
    type: 'image' | 'video'
  ) => {
    setSelectedResource({
      isOriginal: isOriginal,
      resourceId: resourceId,
      type: type
    });

    toggleUploadMediaEditor();
  };

  const handleHtmlEditor = (
    isOriginal: boolean,
    resourceId: string,
    type: 'Markdown' | 'Html'
  ) => {
    let value: string = '';

    if (isOriginal) {
      value = GetResourceValue(entity, resourceId);
    }

    if (!isOriginal) {
      value =
        GetResourcePackResource(entity, currentLocale!, resourceId)?.value ??
        '';
    }

    setSelectedResource({
      isOriginal: isOriginal,
      resourceId: resourceId,
      type: type,
      value: value
    });

    toggleHtmlEditor();
  };

  const handleUpoadMediaSubmit = (uploadMdia: UploadedImage) => {
    updateResourceHandler(
      selectedResource?.isOriginal!,
      selectedResource?.resourceId!,
      uploadMdia?.blobUrl!
    );

    toggleUploadMediaEditor();
  };

  const handleHtmlEditorSubmit = (value: string) => {
    updateResourceHandler(
      selectedResource?.isOriginal!,
      selectedResource?.resourceId!,
      value!
    );

    toggleHtmlEditor();
  };

  const onConfirmDeleteLanguage = (result: YesNoDialogResult) => {
    if (result === 'yes') {
      const newFeedback = {
        ...entity,
        resourcePacks: entity.resourcePacks?.filter(
          (resourcePack) => resourcePack.name !== currentLocale
        )
      } as Feedback;

      handleEntityChange(newFeedback);
      setCurrentLocale && setCurrentLocale('');
    }
    setShowDeleteLanguageConfirm(false);
  };

  const CustomCell = (isOriginal: boolean, props: GridCellProps) => {
    const resource = languageMap?.find((x) => x.id === props.dataItem.id);

    if (!isOriginal && (!currentLocale || currentLocale === '')) {
      return <></>;
    }

    const resourcePack = entity.resourcePacks!.find(
      (resPack: ResourcePack) => resPack.name === currentLocale
    );

    if (resource?.type === 'image') {
      return (
        <CustomColumnImage
          columnClass={'w-50'}
          containerClass={'d-flex justify-content-end w-full p-2'}
          url={isOriginal ? resource?.originalLanguage : resource?.newLanguage}
          id={resource?.id}
          onEdit={(id) => {
            if (!isViewMode) {
              handleUploadMedia(isOriginal, id!, 'image');
            }
          }}
          maxHeight={100}></CustomColumnImage>
      );
    }
    if (resource?.type === 'video') {
      return (
        <CustomColumnVideo
          columnClass={'w-50'}
          containerClass={'d-flex justify-content-end w-full p-2'}
          url={isOriginal ? resource?.originalLanguage : resource?.newLanguage}
          id={resource?.id}
          onEdit={(id) => {
            if (!isViewMode) {
              handleUploadMedia(isOriginal, id!, 'video');
            }
          }}
          maxHeight={100}></CustomColumnVideo>
      );
    }

    if (resource?.type === 'text') {
      const newLanguage = resourcePack
        ? resourcePack.resources.find(
            (res: any) => res.id === props.dataItem.id
          )?.value
        : resource?.newLanguage;
      return (
        <CustomColumnTextarea
          columnClass={'w-50'}
          containerClass={
            'd-flex flex-column gap-1 justify-content-between w-full p-2'
          }
          label={resource.label}
          id={`${isOriginal ? 'original' : ''}-${resource?.id}`}
          onChange={(id, value) =>
            updateResourceHandler(isOriginal, resource?.id!, value!)
          }
          text={isOriginal ? resource?.originalLanguage : newLanguage}
          rows={1}
          autoSize
          isDisable={isViewMode}
        />
      );
    }
    if (resource?.type === 'text-long') {
      return (
        <CustomColumnTextarea
          columnClass={'w-50'}
          id={resource?.id}
          containerClass={'d-flex justify-content-center w-100 p-2'}
          onChange={(id, value) =>
            updateResourceHandler(isOriginal, id!, value!)
          }
          text={isOriginal ? resource?.originalLanguage : resource?.newLanguage}
          isDisable={isViewMode}
        />
      );
    }
    if (resource?.type === 'text-md') {
      return (
        <CustomColumnPlainText
          columnClass={'w-50'}
          id={resource?.id}
          onEdit={(id) => handleHtmlEditor(isOriginal, id!, 'Markdown')}
          containerClass={'d-flex justify-content-between w-full p-2'}
          text={'Markdown'}></CustomColumnPlainText>
      );
    }
    if (resource?.type === 'text-html') {
      return (
        <CustomColumnPlainText
          columnClass={'w-50'}
          id={resource?.id}
          onEdit={(id) => handleHtmlEditor(isOriginal, id!, 'Html')}
          containerClass={'d-flex justify-content-between w-full p-2'}
          text={resource.label}></CustomColumnPlainText>
      );
    }
    return (
      <CustomColumnPlainText
        columnClass={'w-50'}
        containerClass={'d-flex justify-content-between w-full p-2'}
        text={
          isOriginal ? resource?.originalLanguage : resource?.newLanguage
        }></CustomColumnPlainText>
    );
  };

  const languageOrder = useCallback(() => {
    const {
      nameResId,
      titleResId,
      descriptionResId,
      htmlContentResId,
      htmlOutroContentResId
    } = entity;

    let questionIds: { label: string; id: string }[] = [];
    if (questionForm) {
      questionIds = [
        ...questionForm.flatMap((q, id) => {
          const question = {
            label: `Question ${id + 1} (${
              contentTypes.find((c) => c.id === q.answerType)?.description
            })`,
            id: q.questionResId as string
          };
          let option: { label: string; id: string }[] = [];
          if (q.feedbackDetailOptions) {
            option = [
              ...q.feedbackDetailOptions.flatMap((qf, idx) => [
                {
                  label: `Question ${id + 1} ${
                    q.answerType === 'checkbox' ? 'Checkbox' : 'Option'
                  } ${idx + 1}`,
                  id: qf.optionResId as string
                }
              ])
            ];
          }

          return [question, ...option];
        })
      ];
    }

    return [
      { label: 'Feedback name', id: nameResId },
      { label: 'Feedback title', id: titleResId },
      { label: 'Feedback description', id: descriptionResId },
      { label: 'HTML intro', id: htmlContentResId },
      { label: 'HTML outro', id: htmlOutroContentResId },
      ...questionIds
    ];
  }, [entity, questionForm]);

  useEffect(() => {
    const { nameResId } = entity;
    // set language mapping (language table contents, original - other language(s))
    let map: LanguageMapping[] = GetResourcePackByName(
      entity!,
      currentLocale ?? ''
    );
    const orderedIds = languageOrder();
    map.sort(
      (a, b) =>
        orderedIds.findIndex((ordered) => ordered.id === a.id) -
        orderedIds.findIndex((ordered) => ordered.id === b.id)
    );

    const languageMapWithLabel = map
      .map((lang) => ({
        ...lang,
        label: orderedIds.find((ordered) => ordered.id === lang.id)?.label
      }))
      .filter((lang) => lang.id !== nameResId);
    setLanguageMap(languageMapWithLabel);

    // set feedback language (language list from resource pack(s)) for language dropdown picker
    let resourcePacks = GetResourcePacks(entity);
    let languageList: string[] = [];
    resourcePacks.forEach((resourcePack) => {
      languageList.push(resourcePack.name);
    });
    setFeedbackLanguage(languageList);
    handleFeedbackLanguages(languageList);

    // set currentLocale (chosen language) to be default when empty
    if (currentLocale === undefined || currentLocale === '') {
      setCurrentLocale && setCurrentLocale(languageList[0]);
    }
  }, [entity, currentLocale]);

  return (
    <>
      <Row className={'mt-3'}>
        <Tabs
          defaultActiveKey={'information'}
          id={'feedback-tabs'}
          className={'mb-3'}
          onSelect={(selectedTab) => toggleLanguageTab(selectedTab ?? '')}>
          <Tab eventKey={'information'} title={'Information'}>
            <div className={'gap-2'}>
              <Row className={'flex-grow-1 mb-3'}>
                <Col sm={'6'} md={'6'} lg={'6'} xl={'6'}>
                  <div className={'d-flex w-full flex-wrap'}>
                    <CoverImage
                      imageUrl={entity?.logoUrl}
                      containerStyle={'rounded'}
                      className={'mr-5 mb-3'}
                      onDropFiles={(uploadedFile) => {
                        // handleEntityEditorSubmit(uploadedFile, 'Logo')
                      }}>
                      <PopupMenu
                        menus={menuList}
                        id={Math.floor(Math.random() * (30 - 1 + 1)) + 1}
                        onMenuSelected={onMenuSelected}></PopupMenu>
                    </CoverImage>

                    <div
                      className={
                        'author-card border border-1 bg-light p-2 rounded d-grid flex-grow-1'
                      }>
                      <Label>Author: {entity?.createdBy}</Label>
                      <Label>
                        Created:{' '}
                        {entity?.createdDateUtc
                          ? formatDateToDDMMYYYY(entity?.createdDateUtc, true)
                          : ''}
                      </Label>
                      <Label>
                        Last Updated:{' '}
                        {entity?.modifiedDateUtc
                          ? formatDateToDDMMYYYY(entity?.modifiedDateUtc, true)
                          : ''}
                      </Label>
                      <Label>
                        Version:{' '}
                        <code>
                          {entity?.version?.includes('v')
                            ? entity?.version
                            : `v${entity?.version}`}
                        </code>
                      </Label>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={'6'} md={'6'} lg={'3'} xl={'3'}>
                  <StandardInput
                    disabled={isViewMode}
                    name={'name'}
                    label={'Feedback name'}
                    value={entity.name}
                    onChange={(e) => {
                      onChangeFeedbackHandler(
                        e.target.value as string,
                        e.target.name! as keyof Feedback
                      );
                    }}
                    labelStyle={'fw-normal'}
                  />
                </Col>
                <Col sm={'6'} md={'6'} lg={'3'} xl={'3'}>
                  <StandardInput
                    disabled={isViewMode}
                    name={'title'}
                    label={'Feedback title'}
                    labelStyle={'fw-normal'}
                    value={entity?.title}
                    onChange={(e) => {
                      onChangeFeedbackHandler(
                        e.target.value as string,
                        e.target.name! as keyof Feedback
                      );
                    }}
                  />
                </Col>
              </Row>
              {!entity.name && <Error>{'Name is required'}</Error>}
              <Row className={'mt-3'}>
                <Col sm={'6'} md={'6'} lg={'3'} xl={'3'}>
                  <Label>Language</Label>
                  <LanguageComboBox
                    disabled={isViewMode}
                    name={'language'}
                    value={
                      entity?.language
                        ? languages?.find((item) => item === entity.language)
                        : null
                    }
                    onChange={(e) => {
                      onChangeFeedbackHandler(
                        e.target.value as string,
                        e.target.name! as keyof Feedback
                      );
                    }}
                  />
                  {!entity.language && <Error>{'Language is required'}</Error>}
                </Col>
                <Col sm={'6'} md={'6'} lg={'3'} xl={'3'}>
                  <Label className="fw-normal">Display Language</Label>
                  <LanguageComboBox
                    isDisplayLanguages={true}
                    disabled={isViewMode}
                    name={'displayLanguage'}
                    value={entity?.displayLanguage}
                    onChange={(e) => {
                      onChangeFeedbackHandler(
                        e.target.value as string,
                        e.target.name! as keyof Feedback
                      );
                    }}
                  />
                  {!entity.displayLanguage && (
                    <Error>{'Display Language is required'}</Error>
                  )}
                </Col>
              </Row>
              <Row className={'mt-4'}>
                <Col sm={'12'} md={'12'} lg={'6'} xl={'6'}>
                  <StandardTextarea
                    disabled={isViewMode}
                    name={'description'}
                    label={'Feedback description'}
                    labelStyle={'fw-normal'}
                    rows={10}
                    value={entity?.description}
                    onChange={(e) => {
                      onChangeFeedbackHandler(
                        e.target.value as string,
                        e.target.name! as keyof Feedback
                      );
                    }}
                  />
                </Col>
              </Row>
            </div>
          </Tab>
          <Tab eventKey={'bodyType'} title={'Intro'}>
            <Row>
              <Col sm={'8'} md={'8'} lg={'8'} xl={'8'}>
                <HtmlEditor
                  value={entity.htmlContent}
                  handleHtmlChange={(e) => {
                    if (!isViewMode) {
                      onChangeFeedbackHandler(e.html, 'htmlContent');
                    }
                  }}
                />
              </Col>
            </Row>
          </Tab>
          <Tab eventKey={'htmlOutro'} title={'Outro'}>
            <Row>
              <Col sm={'8'} md={'8'} lg={'8'} xl={'8'}>
                <HtmlEditor
                  value={entity.htmlOutroContent ?? ''}
                  handleHtmlChange={(e) => {
                    if (!isViewMode) {
                      onChangeFeedbackHandler(e.html, 'htmlOutroContent');
                    }
                  }}
                />
              </Col>
            </Row>
          </Tab>
          <Tab eventKey={'Form'} title={'Forms'}>
            <Row>
              {questionForm &&
                questionForm.map((item, index) => {
                  return (
                    <Row key={index}>
                      <div
                        className={
                          'card d-flex flex-row justify-content-between p-3 mt-3 ml-3 bg-base-bg'
                        }>
                        <Col sm={6} className="mr-2">
                          <FormInput
                            disabled={isViewMode}
                            name={'questionResId'}
                            label={'Question'}
                            className={'w-100'}
                            value={item.question}
                            onChange={(e) => {
                              onChangeQuestionTypeHandler(e, index);
                            }}
                          />
                        </Col>
                        <Col sm={6} className="mr-2">
                          <Row>
                            <ComboboxInput
                              disabled={isViewMode}
                              label={'Answer type'}
                              value={contentTypes.find(
                                (x) => x.id === item.answerType
                              )}
                              onChange={(e) => {
                                onChangeAnswerTypeHandler(e, index);
                              }}
                              textField={'description'}
                              data={contentTypes}
                              clearButton={false}
                            />
                            {item.answerType === 'radio' &&
                              item &&
                              item.feedbackDetailOptions && (
                                <AnswerOptionsFeedback
                                  isDisabled={isViewMode}
                                  groupId={item?.id}
                                  options={item!.feedbackDetailOptions!}
                                  onChangeOptionAnswers={onChangeOptionAnswers}
                                />
                              )}
                            {item.answerType === 'checkbox' &&
                              item &&
                              item.feedbackDetailOptions && (
                                <AnswerCheckboxFeedback
                                  isDisabled={isViewMode}
                                  groupId={item?.id}
                                  options={item!.feedbackDetailOptions!}
                                  onChangeOptionAnswers={onChangeOptionAnswers}
                                />
                              )}
                          </Row>
                          {!isViewMode && (
                            <div className={'d-flex justify-content-end'}>
                              <span
                                className={
                                  'material-symbols-outlined cursor-pointer p-2'
                                }
                                onClick={() => onCopyFormHandler(index)}>
                                content_copy
                              </span>
                              <span
                                className={
                                  'material-symbols-outlined cursor-pointer text-danger p-2'
                                }
                                onClick={() => onDeleteFormHandler(index)}>
                                delete
                              </span>
                            </div>
                          )}
                        </Col>
                      </div>
                    </Row>
                  );
                })}
            </Row>
            {!isViewMode && (
              <Row>
                <Col>
                  <div
                    className={'card cursor-pointer p-3 mt-3 bg-base-bg'}
                    onClick={addForms}>
                    <div className={'card-body d-flex justify-content-center'}>
                      <span className={'material-symbols-outlined'}>dns</span>
                      <Label className={'cursor-pointer'}>
                        {questionForm && questionForm?.length === 0
                          ? 'Add form'
                          : 'Add question'}
                      </Label>
                    </div>
                  </div>
                </Col>
              </Row>
            )}
          </Tab>
          {entity.id !== 0 && (
            <Tab eventKey={'language'} title={'Language'}>
              <div className={'pt-2'}>
                <GridToolBar
                  searchPlaceholder={'Search items'}
                  columnsToSearch={['newLanguage', 'originalLanguage']}
                  showCardMode={false}
                  {...dataState}
                  onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
                    setDataState(e.dataState);
                  }}></GridToolBar>

                {/* {languageMap && languageMap.length > 0 && ( */}
                <Grid
                  pageable={DefaultGridSettings.pagerSettings}
                  editField={'isEditing'}
                  className={'cg-grid3 w-full'}
                  data={process(languageMap ?? [], dataState)}
                  dataItemKey={'id'}
                  {...dataState}
                  onDataStateChange={(e: GridDataStateChangeEvent) => {
                    setDataState(e.dataState);
                  }}
                  rowRender={rowRender}>
                  <GridToolbar>
                    <Row
                      className={
                        'd-flex justify-content-between w-100 align-items-center'
                      }>
                      <Col>
                        <span className={'fw-bold'}>Original</span>
                      </Col>
                      <Col>
                        <div className="d-flex justify-content-between gap-2">
                          <DropDownList
                            className={'bg-white fw-bold w-75'}
                            value={currentLocale}
                            onChange={languageSelectedEvent}
                            data={feedbackLanguage}
                          />
                          {!isViewMode && feedbackLanguage && currentLocale && (
                            <KendoButton
                              disabled={isViewMode}
                              title={'Edit'}
                              onClick={onEditEntity}
                              themeColor={'secondary'}
                              size={'medium'}
                              className={'medium'}
                              fillMode={'flat'}>
                              <span className="material-symbols-outlined">
                                {'edit'}
                              </span>
                            </KendoButton>
                          )}
                          {!isViewMode && feedbackLanguage && currentLocale && (
                            <KendoButton
                              title={'Delete'}
                              onClick={onDeleteHandler}
                              themeColor={'error'}
                              size={'medium'}
                              className={'medium'}
                              fillMode={'flat'}>
                              <span className="material-symbols-outlined">
                                {'delete'}
                              </span>
                            </KendoButton>
                          )}

                          {!isViewMode && (
                            <Button
                              themeColor={'success'}
                              onClick={onAddEntity}>
                              + Create new
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </GridToolbar>
                  <Column
                    groupable={false}
                    field={'originalLanguage'}
                    editable={false}
                    headerClassName={'d-none'}
                    cell={(props) => CustomCell(true, props)}
                  />

                  <Column
                    groupable={false}
                    field={'newLanguage'}
                    headerClassName={'d-none'}
                    className={'w-50'}
                    cell={(props) => CustomCell(false, props)}
                  />

                  <GridNoRecords>
                    <NoRecords />
                  </GridNoRecords>
                </Grid>
                {/* )} */}
              </div>
              {uploadMediaIsVisible && (
                <ResourceWindow
                  toggleDialog={toggleUploadMediaEditor}
                  onSubmit={handleUpoadMediaSubmit}
                  acceptedExtension={`${selectedResource?.type}/*`}
                  title={`Upload ${selectedResource?.type}`}
                  onClose={(e) => toggleUploadMediaEditor()}
                />
              )}
              {htmlEditorVisible && (
                <ResourcePackHtmlEditorWindow
                  isViewMode={isViewMode}
                  toggleDialog={toggleHtmlEditor}
                  text={selectedResource?.value}
                  type={selectedResource?.type as any}
                  onSubmit={handleHtmlEditorSubmit}
                  onClose={toggleHtmlEditor}
                />
              )}
              {showDeleteLanguageConfirm && (
                <YesNoDialog
                  title="Confirm removal"
                  onConfirm={onConfirmDeleteLanguage}
                  onClose={() => setShowDeleteLanguageConfirm(false)}>
                  Are you sure you want to remove the language?
                </YesNoDialog>
              )}
            </Tab>
          )}
        </Tabs>
      </Row>
      {showUploadLogo ? (
        <ResourceWindow
          toggleDialog={() => {
            setShowUploadLogo(false);
          }}
          onSubmit={(uploadedFile) => handleUploadLogo(uploadedFile)}
          acceptedExtension={'image/*'}
          imageUrl={''}
          onClose={() => {
            setShowUploadLogo(false);
          }}
          hideResourcesTab={true}
        />
      ) : null}
      {showDeleteLogo && (
        <YesNoDialog
          title={'Confirm removal?'}
          onConfirm={handleDeleteLogo}
          onClose={() => setShowDeletedLogo(false)}>
          Are you sure you want to remove the logo?
        </YesNoDialog>
      )}
    </>
  );
};
