import { useCallback, useContext, useEffect, useState } from 'react';
import { Row, Tab, Tabs } from 'react-bootstrap';
import PopupMenu, {
  popupMenu as MenuList
} from '../../../components/popupMenu';
import { ComboboxInput } from '../../../components/form-input';
import YesNoDialog from '../../../components/dialogs/yes-no-dialog';
import EventAlgorithm from '../../../components/map/event-configuration/event--algorithm';
import { Algorithm } from '../../../types/algorithm';
import { Label } from '@progress/kendo-react-labels';
import { GetTimers } from '../../../utils/game-document/assets/index';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { TaskEditor } from '.';
import {
  ResourceEntity,
  TaskContentEntity,
  TaskEntity,
  TimerEntity
} from '../../../types/game-document/entities';
import { TaskEvent } from '../../../types/game-document/entities/task';
import { YesNoDialogResult } from '../../../types/dialog-result';
import RequiredFields from '../../../types/required-fields';
import { GameDocumentContext } from '../../../contexts/game-document';
import cloneDeep from 'lodash.clonedeep';
import CoverImage from '../../../components/cover-image';
import {
  ResourceWindow,
  UploadedImage
} from '../image-resource/resource-window';
import { Checkbox } from '@progress/kendo-react-inputs';
import { GetResourceValue } from '../../../utils/game-document';

export interface TaskEditorWindowProps {
  entity: TaskEntity;
  timer?: TimerEntity;
  taskContents?: TaskContentEntity[];
  requiredFields?: RequiredFields<TaskEntity>[];
  resources: ResourceEntity[];
  handleTaskChange?: (
    field: keyof TaskEntity,
    value: boolean | string | TaskEvent,
    name?: string
  ) => void;
  handleTaskResourceUpload?: (
    field: keyof TaskEntity,
    upload: UploadedImage
  ) => void;
}

const defaultAvailableImageUrl =
  'https://cdn.catalystglobal.games/resources/map-task.png';
const defaultCompleteImageUrl =
  'https://cdn.catalystglobal.games/resources/map-task--complete.png';

export default function TaskEditorFull({
  entity,
  taskContents,
  requiredFields,
  resources,
  handleTaskChange = () => {},
  handleTaskResourceUpload = () => {}
}: TaskEditorWindowProps) {
  const [state] = useContext(GameDocumentContext);
  const [taskData, setTaskData] = useState<TaskEntity>({
    id: '',
    name: '',
    description: ''
  });
  const [timers, setTimers] = useState<TimerEntity[]>([]);
  const [iconUrl, setIconUrl] = useState<string>('');
  const [completeIconUrl, setCompleteIconUrl] = useState<string>('');
  const [dialogUploadVisible, setDialogUploadVisible] =
    useState<boolean>(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState<string>('');
  const [fileUpload, setFileUpload] = useState<string>('');
  const [showDeleteIconConfirm, setShowDeleteIconConfirm] =
    useState<boolean>(false);
  const [showDeleteCompleteIconConfirm, setShowDeleteCompleteIconConfirm] =
    useState<boolean>(false);

  const taskMenu: MenuList[] = [
    { classIcon: 'edit', textMenu: 'Edit', textClass: '' },
    { classIcon: 'delete', textMenu: 'Delete', textClass: 'text-danger' }
  ];

  const eventActionHandler = (type: keyof TaskEvent, algorithm: Algorithm) => {
    let newTask: TaskEntity = cloneDeep({ ...taskData });
    if (newTask.events === undefined) {
      newTask.events = {};
    }
    newTask.events[type] = algorithm;
    handleTaskChange('events', newTask.events);
  };

  const handleEventOnOpenChange = (algorithm: Algorithm) => {
    eventActionHandler('onOpenTask', algorithm);
  };

  const handleEventOnCompleteChange = (algorithm: Algorithm) => {
    eventActionHandler('onCompleteTask', algorithm);
  };

  const handleEventOnCloseChange = (algorithm: Algorithm) => {
    eventActionHandler('onCloseTask', algorithm);
  };

  const handleEventOnProximityChange = (algorithm: Algorithm) => {
    eventActionHandler('onProximity', algorithm);
  };

  const handleEditorSubmit = (
    uploadedFile: UploadedImage,
    fileUpload: string
  ) => {
    setDialogUploadVisible(false);
    handleTaskResourceUpload(fileUpload as keyof TaskEntity, uploadedFile);
  };

  const handleTaskIconSelected =
    (field: string) => (id: number, menu: MenuList) => {
      if (field === 'availableImage' && menu.textMenu === 'Edit') {
        setDialogUploadVisible(true);
        setFileUpload('imageResId');
        setSelectedImageUrl(
          iconUrl === '' ? defaultAvailableImageUrl : iconUrl
        );
      } else if (field === 'completeImage' && menu.textMenu === 'Edit') {
        setDialogUploadVisible(true);
        setFileUpload('completeImageResId');
        setSelectedImageUrl(
          iconUrl === '' ? defaultCompleteImageUrl : completeIconUrl
        );
      } else if (field === 'availableImage' && menu.textMenu === 'Delete') {
        setShowDeleteIconConfirm(true);
      } else if (field === 'completeImage' && menu.textMenu === 'Delete') {
        setShowDeleteCompleteIconConfirm(true);
      }
    };

  const onConfirmDeleteIcon = (result: YesNoDialogResult) => {
    if (result === 'yes') {
      const availableTaskIcon = GetResourceValue(
        state.gameDocument!,
        state.gameDocument?.settings.designer?.defaultTaskAvailableIconResId ||
          ''
      );
      handleTaskChange(
        'imageResId',
        availableTaskIcon || defaultAvailableImageUrl
      );
    }

    setShowDeleteIconConfirm(false);
  };

  const onConfirmDeleteCompleteIcon = (result: YesNoDialogResult) => {
    if (result === 'yes') {
      const completeTaskIcon = GetResourceValue(
        state.gameDocument!,
        state.gameDocument?.settings.designer?.defaultTaskCompletedIconResId ||
          ''
      );
      handleTaskChange(
        'completeImageResId',
        completeTaskIcon || defaultCompleteImageUrl
      );
    }

    setShowDeleteCompleteIconConfirm(false);
  };

  const populateTimers = () => {
    const timers = GetTimers(state.gameDocument!);

    if (timers) {
      setTimers(timers);
    }
  };

  const toggleUploadDialog = () => setDialogUploadVisible(!dialogUploadVisible);

  useEffect(() => {
    if (entity) {
      setTaskData(entity);
      setCompleteIconUrl(
        resources?.find((x) => x?.id === entity?.completeImageResId)?.value ??
          ''
      );

      setIconUrl(
        resources?.find((x) => x?.id === entity?.imageResId)?.value ?? ''
      );
    }
  }, [entity, resources]);

  useEffect(() => {
    if (state.gameDocument) populateTimers();
  }, [state]);

  useEffect(() => {
    let gameDocTaskContents = [];
    gameDocTaskContents = state.gameDocument?.assets?.taskContents!;
    let newTaskContent: TaskContentEntity = {
      id: '-',
      bodyType: 'HTML',
      forms: [],
      contentResId: '',
      preMessageResId: '',
      title: `${entity.name} - task content`,
      name: `Create new`,
      description: `${entity.name} - task content`
    };

    let taskContentIndex = gameDocTaskContents.findIndex((x) => x.id === '-');
    if (taskContentIndex === -1) {
      gameDocTaskContents.unshift(newTaskContent);
    }
  }, []);

  const imageUrl = useCallback(
    (res: 'imageResId' | 'completeImageResId') => {
      if (
        state.gameDocument?.settings &&
        state.gameDocument?.settings.designer
      ) {
        const availableTaskIcon = GetResourceValue(
          state.gameDocument,
          state.gameDocument.settings.designer.defaultTaskAvailableIconResId
        );
        const completeTaskIcon = GetResourceValue(
          state.gameDocument,
          state.gameDocument.settings.designer.defaultTaskCompletedIconResId
        );
        return res === 'imageResId' ? availableTaskIcon : completeTaskIcon;
      } else {
        return res === 'imageResId'
          ? defaultAvailableImageUrl
          : defaultCompleteImageUrl;
      }
    },
    [state.gameDocument?.settings]
  );

  return (
    <>
      <Row className={'mt-3'}>
        <Tabs
          defaultActiveKey={'task-information'}
          id={'task-tabs'}
          className={'mb-3'}>
          <Tab eventKey={'task-information'} title={'Information'}>
            <div className={'d-flex flex-row gap-4'}>
              <div className={'task-icon d-flex flex-column gap-2'}>
                <Label>Available Icon</Label>
                <CoverImage
                  containerStyle={'square'}
                  imageUrl={iconUrl === '' ? imageUrl('imageResId') : iconUrl}>
                  <PopupMenu
                    id={0}
                    menus={taskMenu}
                    onMenuSelected={handleTaskIconSelected('availableImage')}
                  />
                </CoverImage>
                <Checkbox
                  id={'complete-icon'}
                  name={'checkbox-use-default'}
                  value={!Boolean(entity.hideWhenComplete)}
                  onChange={(e) =>
                    handleTaskChange(
                      'hideWhenComplete',
                      !e.target.value as boolean
                    )
                  }>
                  <label
                    className={'k-checkbox-label '}
                    style={{
                      fontWeight: 600
                    }}
                    htmlFor={'complete-icon'}>
                    Complete Icon
                  </label>
                </Checkbox>
                {!Boolean(entity.hideWhenComplete) && (
                  <CoverImage
                    containerStyle={'square'}
                    imageUrl={
                      completeIconUrl === ''
                        ? imageUrl('completeImageResId')
                        : completeIconUrl
                    }>
                    <PopupMenu
                      id={0}
                      menus={taskMenu}
                      onMenuSelected={handleTaskIconSelected('completeImage')}
                    />
                  </CoverImage>
                )}
              </div>
              <div
                className={
                  'task-information d-flex flex-column flex-grow-1 gap-2'
                }>
                <TaskEditor
                  editorMode={'basic'}
                  entity={entity!}
                  resources={resources}
                  taskContents={taskContents}
                  handleTaskChange={(field, value) => {
                    handleTaskChange(field, value as string);
                  }}
                  requiredFields={requiredFields}
                />
                <ComboboxInput
                  label={'Timer'}
                  placeholder={'Select a timer'}
                  data={timers}
                  textField={'name'}
                  value={
                    taskData?.timerAssId &&
                    timers?.find((x) => x.id === taskData?.timerAssId)
                  }
                  onChange={(e: ComboBoxChangeEvent) => {
                    handleTaskChange(
                      'timerAssId',
                      (e.target.value as TimerEntity)?.id ?? ''
                    );
                  }}
                />
              </div>
            </div>
          </Tab>
          <Tab eventKey={'task-onopen'} title={'onOpen'}>
            <EventAlgorithm
              title={'Task.onOpen'}
              description={'is triggered every time a player opens a task.'}
              onChange={handleEventOnOpenChange}
              algorithm={entity.events?.onOpenTask}
            />
          </Tab>
          <Tab eventKey={'task-oncomplete'} title={'onComplete(score)'}>
            <EventAlgorithm
              title={'Task.onComplete(score)'}
              description={
                'is triggered when a player completes the requirements of a task against the player/team "give score" action.'
              }
              onChange={handleEventOnCompleteChange}
              algorithm={entity.events?.onCompleteTask}
            />
          </Tab>
          <Tab eventKey={'task-onclose'} title={'onClose'}>
            <EventAlgorithm
              title={'Task.onClose'}
              description={'is triggered when a task is closed.'}
              onChange={handleEventOnCloseChange}
              algorithm={entity.events?.onCloseTask}
            />
          </Tab>
          <Tab eventKey={'task-onproximity'} title={'onProximity'}>
            <EventAlgorithm
              title={'Task.onProximity'}
              description={
                'is triggered when a player comes within proximity of a task.'
              }
              onChange={handleEventOnProximityChange}
              algorithm={entity.events?.onProximity}
            />
          </Tab>
        </Tabs>
      </Row>

      {dialogUploadVisible && (
        <ResourceWindow
          toggleDialog={toggleUploadDialog}
          onSubmit={(uploadedFile) => {
            handleEditorSubmit(uploadedFile, fileUpload);
          }}
          acceptedExtension={'image/*'}
          imageUrl={selectedImageUrl}
          imageSource={
            fileUpload === 'imageResId'
              ? 'Game Design | Assets - Task - Available Icon'
              : fileUpload === 'completeImageResId'
                ? 'Game Design | Assets - Task - Complete Icon'
                : undefined
          }
        />
      )}

      {showDeleteIconConfirm && (
        <YesNoDialog
          title={'Confirm removal'}
          onConfirm={onConfirmDeleteIcon}
          onClose={() => setShowDeleteIconConfirm(false)}>
          Are you sure you want to remove the task icon?
        </YesNoDialog>
      )}

      {showDeleteCompleteIconConfirm && (
        <YesNoDialog
          title={'Confirm removal'}
          onConfirm={onConfirmDeleteCompleteIcon}
          onClose={() => setShowDeleteCompleteIconConfirm(false)}>
          Are you sure you want to remove the task icon?
        </YesNoDialog>
      )}
    </>
  );
}
