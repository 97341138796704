import { useEffect } from 'react';

import {
  Dialog as KendoDialog,
  DialogProps as KendoDialogProps
} from '@progress/kendo-react-dialogs';

const ProfileDialogWindow = (props: KendoDialogProps) => {
  const handleDismiss = () => {
    const elements = document.getElementsByClassName('k-overlay');
    if (elements && elements[0]) {
      const overlay = elements[0] as HTMLDivElement;
      if (props.onClose) {
        overlay.addEventListener('click', () => {
          if (props.onClose) {
            props.onClose({} as any);
          }
        });
      }
    }
  };

  useEffect(() => {
    handleDismiss();
  }, []);

  return (
    <KendoDialog
      width={props.width}
      minWidth={'350px'}
      className={props.className}
      {...props}>
      {props.children}
    </KendoDialog>
  );
};

export default ProfileDialogWindow;
