import { useEffect, useState } from 'react';
import {
  ColorPicker as KendoColorPicker,
  ColorPickerChangeEvent,
  ColorPickerView
} from '@progress/kendo-react-inputs';

type ColorPickerProps = {
  view: ColorPickerView;
  description?: string;
  value?: string;
  onChange?: (value?: string) => void;
};

export const ColorPicker = ({
  onChange = () => {},
  ...props
}: ColorPickerProps) => {
  const [value, setValue] = useState<string>(props.value ?? '');
  const [open, setOpen] = useState<boolean>(false);

  const onChangeColorPicker = (e: ColorPickerChangeEvent) => onChange(e.value);

  const handleOnClick = () => setOpen(!open);
  const handleOnClickParent = () => {
    setOpen(true);
  };

  useEffect(() => {
    setValue(props.value!);
  }, [props.value]);

  return (
    <div className={'d-flex flex-column'}>
      <div className={'d-flex'}>
        <div
          className={'color-picker'}
          role={'button'}
          onClick={handleOnClickParent}>
          <KendoColorPicker
            open={open}
            view={props.view}
            value={value}
            onChange={onChangeColorPicker}
          />
        </div>
        <div className={'color-picker-action'} tabIndex={0}>
          <span
            className={'material-symbols-outlined fw-bold cursor-pointer'}
            role={'button'}
            onClick={handleOnClick}>
            {open ? `close` : `more_vert`}
          </span>
        </div>
      </div>
      {props?.description && (
        <div className={'mt-2'}>
          <span>{props?.description}</span>
        </div>
      )}
    </div>
  );
};
