import { GameDocument } from '../../../types/game-document';
import { InGameSettings } from '../../../types/game-document/settings';
import { uuid } from '../../../types/common-helper';
import { MergeGameSettings } from './index';

/**
 * Adds a new In Game Setting to the Game document.
 * @param gameDocument - The Game Document to modify
 * @param name - The Name of the new In Game Setting
 * @param description - The Description for the new In Game Setting
 * @returns The updated Game Document
 */
export const AddInGameSettingAsync = async (
  gameDocument: GameDocument,
  name: string,
  description: string
) => {
  let inGameSetting = {
    id: uuid(),
    name: name,
    description: description,
    showInGameInfo: true,
    showInGameTeams: false,
    showInGameCreateTeams: false,
    showInGameJoinLeaveTeams: false,
    showInGameInventory: false,
    showInGameRoles: false,
    showInGameScoreboard: false,
    showInGamePlayerTeamScore: false,
    showInGameLeaderboard: false,
    showInGameChat: true,
    showInGameFacilitatorChat: true,
    showInGameAllPlayerChat: false,
    showInGameDirectChat: false,
    showInGameTeamChat: false,
    showInGameGroupChat: false,
    showInGameTrade: false,
    tasksRequireProximity: true,
    enablePremessageOutsideRange: true,
    showTasknameVisibility: false,
    showOtherPlayerOrTeamOnMap: false,
    showPlayerScoreOnMap: false,
    showPlayerDetailByClickOnMap: true
  };
  return MergeGameSettings(gameDocument, inGameSetting, 'inGame');
};

/**
 * Updates the identified Setting in the Game Document.
 * @param gameDocument - The Game Document to modify
 * @param inGameSetting - The new in game settings
 * @constructor
 */
export const UpdateInGameSettingAsync = async (
  gameDocument: GameDocument,
  inGameSetting: InGameSettings
) => {
  return MergeGameSettings(gameDocument, inGameSetting, 'inGame');
};
