import { GameDocumentContext } from '../../../contexts/game-document';
import { useCallback, useContext, useEffect, useState } from 'react';
import Toolbar from '../toolbar';
import {
  DeleteTaskAsync,
  UpdateGameDocState,
  UpdateResourceAsync,
  UpdateTaskAsync
} from '../../../utils/game-document';
import { Button } from '@progress/kendo-react-buttons';
import { useNavigate, useParams } from 'react-router-dom';
import {
  TaskEditor,
  TaskEditorWindow
} from '../../../features/game-document/tasks';
import { EntityEditor } from '../../../types/game-document/entity-editor';
import { ResourceEntity, TaskEntity } from '../../../types/game-document/';
import { FluidForm } from '../../../components/forms';
import RequiredFields from '../../../types/required-fields';
import { TaskEvent } from '../../../types/game-document/entities/task';
import cloneDeep from 'lodash.clonedeep';
import {
  ERR_DUPLICATE_NAME_VALUE,
  ERR_INPUT_REQUIRED
} from '../../../constants/text';
import { UploadedImage } from '../../../features/game-document/image-resource/resource-window';
import { useGameDocumentResources } from '../../../hooks/use-game-document-resources';

const Task = () => {
  const { taskId } = useParams();
  const [state, setState] = useContext(GameDocumentContext);
  const navigate = useNavigate();
  const resourceFiles = useGameDocumentResources();
  const getEntityById = (entityId: string) =>
    state.gameDocument?.assets.tasks?.find((i) => i.id === entityId)!;

  const [entity, setEntity] = useState(getEntityById(taskId!));
  const [titleResource, setTitleResource] = useState<ResourceEntity>();
  const [imageResource, setImageResource] = useState<ResourceEntity>();
  const [completeImageResource, setCompleteImageResource] =
    useState<ResourceEntity>();
  const [resources, setResources] = useState<ResourceEntity[]>(
    [] as ResourceEntity[]
  );

  const [requiredFields, setRequiredFields] = useState<
    RequiredFields<TaskEntity>[]
  >([
    { name: 'name', errorMessage: '' },
    { name: 'titleResId', errorMessage: '' }
  ]);

  const [entityEditorIsVisible, setEntityEditorIsVisible] =
    useState<boolean>(false);
  const toggleEntityEditor = () => {
    setEntityEditorIsVisible(!entityEditorIsVisible);
  };

  const handleEntityEditorSubmit = (
    editorEntity: EntityEditor<TaskEntity>,
    resourceEntity: EntityEditor<ResourceEntity>[]
  ) => {
    const titleResource = resourceEntity.find(
      (resource) => resource.entity.id === editorEntity.entity.titleResId
    );
    const imageResource = resourceEntity.find(
      (resource) => resource.entity.id === editorEntity.entity.imageResId
    );
    const completeImageResource = resourceEntity.find(
      (resource) =>
        resource.entity.id === editorEntity.entity.completeImageResId
    );
    const updateTitleResource = UpdateResourceAsync(
      state.gameDocument!,
      editorEntity.entity.titleResId!,
      titleResource?.entity!
    );
    const updateImageResource = UpdateResourceAsync(
      state.gameDocument!,
      editorEntity.entity.imageResId!,
      imageResource?.entity!
    );
    const updateCompleteImageResource = UpdateResourceAsync(
      state.gameDocument!,
      editorEntity.entity.completeImageResId!,
      completeImageResource?.entity!
    );
    Promise.all([
      updateTitleResource,
      updateImageResource,
      updateCompleteImageResource
    ]).then(() => {
      UpdateTaskAsync(
        state.gameDocument!,
        editorEntity.entity.id,
        editorEntity.entity
      ).then((updatedGameDocument) => {
        setState((state) => UpdateGameDocState(state, updatedGameDocument));
        setEntityEditorIsVisible(false);
      });
    });
  };

  const onDeleteEntity = async (entityId: string) => {
    DeleteTaskAsync(state.gameDocument!, entityId).then(
      (updatedGameDocument) => {
        setState((state) => UpdateGameDocState(state, updatedGameDocument));
        navigate('../');
      }
    );
  };

  const handleTaskResourceUpload = useCallback(
    (field: keyof TaskEntity, upload: UploadedImage) => {
      const resourceId = entity[field] as string;
      resourceFiles.updateResourceFile(resourceId, upload);
    },
    [entity, resourceFiles]
  );

  const handleTaskChange = useCallback(
    (field: keyof TaskEntity, value: boolean | string | TaskEvent) => {
      setRequiredFields((prev) =>
        prev.map((task: RequiredFields<TaskEntity>) => {
          const copyTask = cloneDeep({ ...task });
          if (task.name === field) {
            copyTask.errorMessage = '';
            if (field === 'name') {
              const duplicateValueIndex: number =
                state.gameDocument?.assets.tasks
                  ?.filter((item: TaskEntity) => item.id !== entity.id)
                  .findIndex(
                    (item: TaskEntity) =>
                      item.name.toLowerCase() ===
                      (value as string)?.toLowerCase()
                  ) ?? -1;
              if (value === '') {
                copyTask.errorMessage = ERR_INPUT_REQUIRED;
              } else if (duplicateValueIndex !== -1) {
                copyTask.errorMessage = ERR_DUPLICATE_NAME_VALUE;
              }
            } else if (field === 'titleResId') {
              if (value === '') {
                copyTask.errorMessage = ERR_INPUT_REQUIRED;
              }
            }
          }

          return copyTask;
        })
      );
      if (field.toLowerCase().endsWith('resid')) {
        setResources((prev) =>
          prev.map((resource: ResourceEntity) =>
            resource?.id === entity[field]
              ? { ...resource, value: value as string }
              : { ...resource }
          )
        );
        const resource = resources.find((item) => item.id === entity[field])!;
        resource.value = value as string;
        UpdateResourceAsync(state.gameDocument!, resource.id, resource).then(
          (response) => setState((prev) => UpdateGameDocState(prev, response))
        );
      } else if (field === 'events') {
        setEntity((prev) => ({ ...prev, events: value as TaskEvent }));
        UpdateTaskAsync(
          state.gameDocument!,
          taskId!,
          {
            ...entity,
            events: value as TaskEvent
          },
          false
        ).then((response) =>
          setState((prev) => UpdateGameDocState(prev, response))
        );
      } else {
        setEntity((prev) => ({
          ...prev,
          [field]: value
        }));
        UpdateTaskAsync(
          state.gameDocument!,
          taskId!,
          {
            ...entity,
            [field]: value as string
          },
          false
        ).then((updatedGameDocument) => {
          setState((state) => UpdateGameDocState(state, updatedGameDocument));
        });
      }
    },
    [entity, resources]
  );

  useEffect(() => {
    // Set the page title.
    document.title = `${entity.name} - ${state.gameDocument?.name}`;
    if (entity) {
      const titleRes: ResourceEntity = state.gameDocument?.resources.find(
        (task) => task.id === entity.titleResId
      )!;
      const imageRes: ResourceEntity = state.gameDocument?.resources.find(
        (task) => task.id === entity.imageResId
      )!;
      const completeImageRes: ResourceEntity =
        state.gameDocument?.resources.find(
          (task) => task.id === entity.completeImageResId
        )!;
      setTitleResource(titleRes);
      setImageResource(imageRes);
      setCompleteImageResource(completeImageRes);
      setResources([titleRes, imageRes, completeImageRes]);
    }
  }, [state, entity]);

  useEffect(() => {
    getEntityById(taskId!);
  }, [state]);

  return (
    <>
      <Toolbar title={entity?.name}>
        <Button
          onClick={() => onDeleteEntity(entity.id!)}
          themeColor={'error'}
          fillMode={'flat'}>
          <span className={'material-symbols-outlined'}>delete</span>
        </Button>
        <Button
          onClick={toggleEntityEditor}
          themeColor={'success'}
          fillMode={'flat'}>
          <span className={'material-symbols-outlined'}>edit</span>
        </Button>
        {entityEditorIsVisible && (
          <TaskEditorWindow
            toggleDialog={toggleEntityEditor}
            onSubmit={handleEntityEditorSubmit}
            onClose={toggleEntityEditor}
            editorEntity={{ isNew: false, entity: entity }}
            editorResource={[
              { isNew: false, entity: titleResource! },
              { isNew: false, entity: imageResource! },
              { isNew: false, entity: completeImageResource! }
            ]}
            editorMode={'basic'}
          />
        )}
      </Toolbar>
      <FluidForm>
        <TaskEditor
          editorMode={'full'}
          entity={entity}
          resources={resources!}
          imageResourceVisibility={true}
          taskContents={state.gameDocument?.assets?.taskContents}
          handleTaskChange={handleTaskChange}
          handleTaskResourceUpload={handleTaskResourceUpload}
          requiredFields={requiredFields}
        />
      </FluidForm>
    </>
  );
};

export default Task;
