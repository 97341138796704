import { createContext, PropsWithChildren } from 'react';

export interface InstanceProviderConfig {
  siteName: string;
  version: string;
  copyright: string;
  clientId: string;
  redirectUri: string;
  silentRedirectUri: string;
  authority: string;
  apiBaseUrl: string;
}

const InstanceContext = createContext<InstanceProviderConfig>({
  siteName: '',
  version: '',
  copyright: '',
  clientId: '',
  redirectUri: '',
  silentRedirectUri: '',
  authority: '',
  apiBaseUrl: ''
});

export interface InstanceProviderProps
  extends PropsWithChildren<InstanceProviderConfig> {}

/**
 * Component that provides instance configuration data to its children through context.
 *
 * @component
 * @param {Object} props - The props object.
 * @param {ReactNode} props.children - The children of the component.
 * @returns {ReactNode} The rendered component.
 */
const InstanceProvider = ({ children, ...props }: InstanceProviderProps) => {
  return (
    <InstanceContext.Provider value={props}>
      {children}
    </InstanceContext.Provider>
  );
};

export { InstanceContext, InstanceProvider };
