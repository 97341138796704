import {
  AttachmentPayload,
  SupportTicketPayload,
  SupportTicketResponse
} from '../types/responses/support-ticket';
import { GetAllAsync, PostAsync } from './api';

export const GetMySupportTickets = async () =>
  GetAllAsync<SupportTicketResponse>('supports/mytickets');

export const PostSupportTicket = async (supportTicket: SupportTicketPayload) =>
  PostAsync<SupportTicketPayload | SupportTicketResponse>(
    'supports',
    supportTicket
  );

export const PostSupportTicketAttachment = async (
  id: number,
  attachments: AttachmentPayload[]
) => PostAsync<AttachmentPayload[]>(`supports/${id}/attachments`, attachments);
