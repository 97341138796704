import { AreaEntity } from '../../../types/game-document/index';
import { GameDocument } from '../../../types/game-document';
import { GetNextAssetNameAsync, MergeAssets } from '.';
import { uuid } from '../../../types/common-helper';

/**
 * Add Area.
 * @param gameDocument - The Game Document to modify
 * @param area - The new area
 * @return The updated Game Document
 */
export const AddAreaEntityAsync = async (
  gameDocument: GameDocument,
  area: AreaEntity
) => {
  let areas = gameDocument.assets.areas ?? [];
  let areaName = await GetNextAssetNameAsync(areas, area?.name);
  area.name = areaName;
  areas.push(area);
  return MergeAssets(gameDocument, areas, 'areas');
};

/**
 * Updates the identified Area in the Game Document.
 * @param gameDocument - The Game Document to modify
 * @param areaId - The ID of the Area to update
 * @param area - The updated Area
 * @constructor
 */
export const UpdateAreaAsync = async (
  gameDocument: GameDocument,
  areaId: string,
  area: AreaEntity,
  checkName: boolean = true
) => {
  let areas = gameDocument.assets.areas ?? [];
  let areaIndex = areas.findIndex((i) => i.id === areaId)!;
  if (checkName)
    area.name = await GetNextAssetNameAsync(areas, area.name, area.id);
  areas[areaIndex] = area;
  return MergeAssets(gameDocument, areas, 'areas');
};

/**
 * Get all areas from game document local storage.
 * @param gameDocument - The Game Document as datasource
 * @constructor
 */
export const GetAreas = (gameDocument: GameDocument | undefined) => {
  return gameDocument?.assets?.areas ?? [];
};

/**
 * Deletes the identified Area from the Game Document.
 * @param gameDocument - The Game Document to modify
 * @param areaId - The ID of the Area to delete
 * @returns The updated Game Document
 */
export const DeleteAreaAsync = async (
  gameDocument: GameDocument,
  areaId: string
) => {
  let areas = gameDocument.assets.areas ?? [];
  let areaIndex = areas.findIndex((i) => i.id === areaId)!;

  if (areaIndex !== -1) {
    areas.splice(areaIndex, 1);
  }

  return MergeAssets(gameDocument, areas, 'areas');
};

/**
 * Adds a new Area to the Game document.
 * @param gameDocument - The Game Document to modify
 * @param name - The Name of the new Area
 * @param description - The Description for the new Area
 * @returns The updated Game Document
 */
export const AddAreaAsync = async (
  gameDocument: GameDocument,
  name: string,
  description: string
) => {
  let areas = gameDocument.assets.areas ?? [];
  let areaName = await GetNextAssetNameAsync(areas, name);
  areas.push({
    id: uuid(),
    name: areaName,
    description
  });
  return MergeAssets(gameDocument, areas, 'areas');
};

/**
 * Create a copy of the Area in the Game document.
 * @param gameDocument - The Game Document to modify
 * @param areaId - The ID of the Area to copy
 * @param copiedAreaId - The new ID of the copied Area
 * @returns The updated Game Document
 */
export const CopyAreaAsync = async (
  gameDocument: GameDocument,
  areaId: string,
  copiedAreaId: string = uuid()
) => {
  let areas = gameDocument.assets.areas ?? [];
  let areaIndex = areas.findIndex((i) => i.id === areaId)!;

  if (areaIndex !== -1) {
    let areaCopy: AreaEntity = {
      ...areas[areaIndex]
    };

    areaCopy.id = copiedAreaId;
    areaCopy.name += '-copy';
    areaCopy.name = await GetNextAssetNameAsync(
      areas,
      areaCopy.name,
      areaCopy.id
    );
    areas.push(areaCopy);
  }
  return MergeAssets(gameDocument, areas, 'areas');
};

/**
 * Get areas in world map from game document local storage.
 * @param gameDocument - The Game Document as datasource
 * @constructor
 */
export const GetAreasWorldMap = (gameDocument: GameDocument | undefined) => {
  const areas: AreaEntity[] = [];

  if (gameDocument) {
    const zones = gameDocument.assets.zones;

    gameDocument.assets.areas?.forEach((area) => {
      let found = false;
      zones?.forEach((zone) => {
        const zoneAreas = zone.areas?.map((e) => e.areaAssId);
        if (zoneAreas?.includes(area.id)) {
          found = true;
          return;
        }
      });

      if (!found) areas.push(area);
    });
  }

  return areas;
};

/**
 * Get areas by Zone Id from game document local storage.
 * @param gameDocument - The Game Document as datasource
 * @param areaId - The zone id you're looking for
 * @constructor
 */
export const GetAreasByZoneId = (
  gameDocument: GameDocument | undefined,
  zoneId: string
) => {
  let tasks: AreaEntity[] = [];

  if (gameDocument) {
    let zone = gameDocument?.assets?.zones?.find((x) => x.id === zoneId);

    const areaIds = zone?.areas?.map(function (e) {
      return e.areaAssId;
    });

    if (areaIds) {
      return GetAreasByIds(gameDocument, areaIds);
    }
  }

  return tasks;
};

/**
 * Get areas by Ids from game document local storage.
 * @param gameDocument - The Game Document as datasource
 * @constructor
 */
export const GetAreasByIds = (
  gameDocument: GameDocument | undefined,
  ids: string[]
) => {
  return (
    gameDocument?.assets?.areas?.filter((x) => ids.indexOf(x.id) !== -1) ?? []
  );
};
