import { useState, useRef } from 'react';
import { DialogProps } from './dialog';
import { Dialog } from '@progress/kendo-react-dialogs';
import { QRCode } from '@progress/kendo-react-barcodes';
import { Tooltip } from '@progress/kendo-react-tooltip';

export interface QrCodeDialogProps extends DialogProps {
  title: string;
  url: string;
  onClose: () => void;
}

export function QrCodeDialog({
  title,
  url,
  onClose = () => {}
}: QrCodeDialogProps) {
  const anchor = useRef<HTMLButtonElement>(null);
  const [show, setShow] = useState(false);

  return (
    <Dialog onClose={onClose}>
      <div>
        <div className={'k-card-deck'}>
          <div className={'k-card k-text-center'}>
            <div
              className={
                'k-card-header justify-content-center d-flex align-items-center w-100'
              }>
              <div className={'k-card-title fw-bold fs-2 col-11'}>{title}</div>
              <div
                className={
                  'd-flex float-right justify-content-end align-items-center col-1'
                }>
                <button
                  id={'qr-code-dialog-close'}
                  className={'btn btn-link btn-sm p-0'}
                  type={'button'}
                  onClick={onClose}>
                  <span className={'material-symbols-outlined'}>close</span>
                </button>
              </div>
            </div>
            <div className={'k-card-body d-flex flex-column'}>
              <QRCode size={'350'} value={`${url}`} errorCorrection="M" />
              <div className={'input-group mb-3 mt-3'}>
                <input
                  type={'text'}
                  className={'form-control text-primary'}
                  disabled={true}
                  value={`${url}`}
                />
                <div className={'input-group-append'}>
                  <Tooltip
                    anchorElement="target"
                    open={show}
                    targetElement={anchor.current}
                    openDelay={1}
                    appendTo={anchor.current}
                    position={'right'}
                  />
                  <button
                    id={'qr-code-dialog-copy'}
                    title="Url copied!"
                    className={
                      'btn btn-success text-light btn-copy-url-feedbacks'
                    }
                    ref={anchor}
                    type={'button'}
                    onClick={() => {
                      navigator.clipboard.writeText(`${url}`);
                      setShow(true);
                      setTimeout(() => {
                        setShow(false);
                      }, 400);
                    }}>
                    Copy
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default QrCodeDialog;
