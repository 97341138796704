import { Resource } from '../types/game-document';
import { TaskEntity } from '../types/game-document/';
import { Style, Fill, Text, Stroke, Icon } from 'ol/style';
import {
  DefaultFontFamily,
  DefaultStrokeColor,
  DefaultTextcolor
} from '../constants/map';

export const ScaleImage = (
  imageUrl: string,
  imageWidth?: number,
  imageHeight?: number
) => {
  const defaultResolution = 256; // resolution of default image task (256 x 256)
  const defaultScale = 0.25; // size of default scale
  const img = new Image();
  img.src = imageUrl;
  const imgResolution = Math.sqrt(
    (imageWidth ?? img.naturalWidth) * (imageHeight ?? img.naturalHeight)
  );

  // return default scale if img resolution lower than default image resolution
  if (imgResolution <= defaultResolution) return defaultScale;

  return defaultScale / (imgResolution / defaultResolution);
};

export const GetTaskPointStyle = (
  task: TaskEntity,
  imageResources?: Resource[],
  taskName?: string,
  opacity?: number
) => {
  // find the tasks pin image resource
  const findImageSource = imageResources?.find((o) => {
    return o.id === task?.imageResId;
  });

  // either use the defined pin or the default pin
  const imageUrl =
    findImageSource?.id === '' || !findImageSource?.value
      ? 'https://cdn.catalystglobal.games/resources/map-task.png'
      : findImageSource?.value;

  return GetPointStyle(imageUrl, taskName, opacity);
};

export const GetPointStyle = (
  imageUrl: string,
  taskName?: string,
  opacity?: number
) => {
  let imageStyle = new Style({
    image: new Icon({
      anchor: [0.5, 0.5],
      opacity: opacity ?? 1,
      scale: ScaleImage(imageUrl),
      src: imageUrl
    }),
    fill: new Fill({ color: [0, 0, 1, 0.6] })
  });

  return [
    imageStyle,
    new Style({
      text: new Text({
        text: taskName,
        offsetY: -40,
        fill: new Fill({
          color: DefaultTextcolor
        }),
        stroke: new Stroke({
          color: DefaultStrokeColor,
          width: 10
        }),
        font: DefaultFontFamily // Set the font weight and size
      })
    })
  ];
};

export const ScaleMapImage = (imageUrl: string, size?: number) => {
  const defaultResolution = 256; // resolution of default image task (256 x 256)
  const img = new Image();
  img.src = imageUrl;
  const imgResolution = Math.sqrt(
    (size ?? defaultResolution) * (size ?? defaultResolution)
  );

  return 256;
};

export const GetImageStyle = (
  imageUrl: string,
  taskName?: string,
  opacity?: number,
  mapResolution?: number,
  imageScale?: number
) => {
  // const baseScale = 0.25; // ScaleMapImage(imageUrl); // Base scale factor for the image
  // const dynamicScale = mapResolution ? 1 / mapResolution : 1; // Adjust scale based on map resolution
  // const scale = baseScale * dynamicScale;
  // console.log(`${taskName} : ${scale}`);

  let imageStyle = new Style({
    image: new Icon({
      anchor: [0.5, 0.5],
      opacity: opacity ?? 1,
      scale: imageScale,
      src: imageUrl
    }),
    fill: new Fill({ color: [0, 0, 1, 0.6] })
  });

  return [
    imageStyle,
    new Style({
      text: new Text({
        text: taskName,
        offsetY: -40,
        fill: new Fill({
          color: DefaultTextcolor
        }),
        stroke: new Stroke({
          color: DefaultStrokeColor,
          width: 10
        }),
        font: DefaultFontFamily
      })
    })
  ];
};
