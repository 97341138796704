import { useEffect, useState } from 'react';

import DropdownSelector from './dropdown-selector';
import Conditions from '../../constants/conditions';
import SelectionItem from '../../types/selection-item';

export interface ConditionSelectorProps {
  condition?: string;
  onChange?: (condition: string) => void;
  selectedIdentifier?: string;
}

export const ConditionSelector = ({ ...props }: ConditionSelectorProps) => {
  const [className, setClassName] = useState<string>('');
  const [conditionList, setConditionList] = useState<SelectionItem[]>(Conditions);

  const handleConditionChangeChange = (condition: string) => {
    if (props.onChange) {
      props.onChange(condition);
    }
  };

  useEffect(() => {
    setClassName(`condition--${props?.condition ?? 'select'}`);
  }, [props.condition]);

  useEffect(() => {

    let filteredIdentifiers = Conditions.filter(item =>
      props.selectedIdentifier
        ? item.availableOnIdentifiers?.includes(props.selectedIdentifier)
        : !item.availableOnIdentifiers
    );

    if (props.selectedIdentifier && !filteredIdentifiers.length) {
      filteredIdentifiers = Conditions.filter(item =>
        !item.availableOnIdentifiers
      );
    }

    setConditionList(filteredIdentifiers);

  }, [props.selectedIdentifier])

  return (
    <DropdownSelector<SelectionItem, 'name'>
      fieldName="name"
      items={conditionList}
      additionalClassname={className}
      onClick={(e) => handleConditionChangeChange(e.name)}
      selected={
        Conditions?.find((x) => x.name === props.condition)?.description
      }
    />
  );
};
