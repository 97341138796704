import React, { useEffect } from 'react';
import { BreadcrumbLocation, PrivatePage } from '../../components/private-page';
import PageToolbar from '../../components/page-toolbar';
import { ctGameDefault, ctGameTitle } from '../../constants/text';

interface DiagnosticsProps {}

const Diagnostics = (props: DiagnosticsProps) => {
  const breadcrumbs: BreadcrumbLocation[] = [
    { label: 'Dashboard', href: '/' },
    { label: 'Diagnostics', href: '/diagnostics' }
  ];

  useEffect(() => {
    document.title = ctGameTitle + '- Diagnostics';
    return () => {
      document.title = ctGameDefault;
    };
  }, []);
  return (
    <PrivatePage pageTitle={'Diagnostics'} breadcrumb={breadcrumbs}>
      <PageToolbar title={'Diagnostics'}></PageToolbar>
      <pre style={{ fontSize: '0.7rem' }} className={'text-break mt-4'}>
        TODO
      </pre>
    </PrivatePage>
  );
};

export { default as UserDiagnostics } from './user';
export { default as AuthDiagnostics } from './auth-settings';
export default Diagnostics;
