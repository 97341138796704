import { useRef, useState } from 'react';
import { Popup, Align } from '@progress/kendo-react-popup';
import { Assessment } from '../types/assessment';
import { Feedback } from '../types/feedback';

export interface popupMenu {
  classIcon?: string;
  textMenu?: string;
  textClass?: string;
}

export interface popupMenuProps {
  id: number;
  menus: popupMenu[];
  data?: Assessment | Feedback;
  assessment?: Assessment;
  onMenuSelected: (
    id: number,
    popupMenu: popupMenu,
    idString?: string,
    assessment?: Assessment,
    data?: Assessment | Feedback
  ) => void;
  iconClass?: string;
  idString?: string;
}

export default function PopupMenu({
  id,
  menus,
  data,
  assessment,
  onMenuSelected,
  idString
}: popupMenuProps) {
  const [show, setShow] = useState<boolean>(false);
  const anchor = useRef<HTMLSpanElement | null>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const blurTimeoutRef = useRef<NodeJS.Timeout>();

  const onMenuOpen = () => menuRef.current?.focus();

  const onMenuFocus = () => clearTimeout(blurTimeoutRef.current);

  const onMenuBlurTimeout = () => setShow(false);

  const onMenuBlur = () => {
    clearTimeout(blurTimeoutRef.current);
    blurTimeoutRef.current = setTimeout(onMenuBlurTimeout, 200);
  };

  const popupAlign: Align = {
    horizontal: 'right',
    vertical: 'top'
  };

  const onClick = () => {
    setShow((prev) => !prev);
  };

  const onSelected = (id: number, popupMenu: popupMenu, idString?: string) => {
    if (onMenuSelected) {
      onMenuSelected(id, popupMenu, idString, assessment, data);
      setShow(false);
    }
  };

  return (
    <div>
      <span
        onClick={onClick}
        ref={(span) => (anchor.current = span)}
        className={'material-symbols-outlined fw-bold cursor-pointer'}>
        more_vert
      </span>
      <Popup
        anchor={anchor.current}
        popupAlign={popupAlign}
        onOpen={onMenuOpen}
        show={show}
        className={'popup'}>
        <div
          className={'py-3 d-flex flex-column'}
          ref={menuRef}
          tabIndex={0}
          onFocus={onMenuFocus}
          onBlur={onMenuBlur}>
          {menus &&
            menus.map((mn, index) => {
              return (
                <div
                  className={'px-3 my-2 d-flex align-items-center'}
                  role={'button'}
                  key={index}
                  onClick={() => onSelected(id, mn, idString)}>
                  <span className={`material-symbols-outlined ${mn.textClass}`}>
                    {mn.classIcon}
                  </span>
                  <span className={'mx-3'}>{mn.textMenu}</span>
                </div>
              );
            })}
        </div>
      </Popup>
    </div>
  );
}
