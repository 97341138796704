import EnvironmentGrid from '../../features/environments-grid';
import { PrivatePage } from '../../components/private-page';
import { useEffect } from 'react';
import { ctGameDefault, ctGameTitle } from '../../constants/text';

export default function Environments() {

  useEffect(() => {
    document.title = ctGameTitle + ' - Environments';

    return () => {
      document.title = ctGameDefault
    }
  }, []);
  return (
    <PrivatePage
      breadcrumb={[
        {
          label: 'Dashboard',
          href: 'dashboard'
        },
        {
          label: 'Administration',
          href: 'administration'
        },
        {
          label: 'Environments',
          href: 'administration/environments'
        }
      ]}
      pageTitle={'Administration'}>
      <EnvironmentGrid></EnvironmentGrid>
    </PrivatePage>
  );
}
