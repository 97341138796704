import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Represents the menu model
 */
export interface Menu {
  label: string;
  path?: string;
  icon?: string;
  expandable?: boolean;
  isExpanded?: boolean;
  selected?: boolean;
  submenus?: Menu[];
  className?: string;
}

export interface AsideMenuProps {
  initalMenus: Menu[];
}

export function AsideMenu({ initalMenus }: AsideMenuProps) {
  const [menus, setMenus] = useState<Menu[]>(initalMenus);
  const navigate = useNavigate();

  const deselected = (menu: Menu) => {
    menu.selected = false;

    if (menu.submenus) {
      for (let index = 0; index < menu.submenus.length; index++) {
        const submenu = menu.submenus[index];
        deselected(submenu);
      }
    }
  };

  const handleClick = (selectedMenu: Menu) => {
    const copy: Menu[] = [];

    for (let index = 0; index < menus.length; index++) {
      const menu = menus[index];
      deselected(menu);
      copy.push(menu);
    }

    const selectedMenuIndex = copy.findIndex(
      (x) => x.label === selectedMenu.label && x.path === selectedMenu.path
    );

    if (selectedMenuIndex !== -1) {
      copy[selectedMenuIndex] = selectedMenu;
    }

    setMenus(copy);
    if (selectedMenu.path) {
      navigate(selectedMenu.path);
    }
  };

  return <TreeMenu menus={menus} onClick={handleClick} />;
}

export interface TreeMenuProps {
  menus: Menu[];
  onClick?: (menu: Menu) => void;
  className?: string;
}

export function TreeMenu({ menus, onClick, className }: TreeMenuProps) {
  return (
    <div className={`aside-menu ${className ?? ''}`.trim()}>
      {menus.map((menu, index) => (
        <>
          <Menu key={index} menu={menu} onClick={onClick} />
          {menu.expandable && menu.isExpanded && menu.submenus && (
            <TreeMenu
              key={index + menu.label}
              onClick={onClick}
              menus={menu.submenus}
              className={'aside-menu--sub'}
            />
          )}
        </>
      ))}
    </div>
  );
}

interface MenuProps {
  menu: Menu;
  onClick?: (menu: Menu) => void;
}

export function Menu({ menu, onClick }: MenuProps) {
  const [state, setState] = useState(menu);

  const handleClick = () => {
    if (menu.path) {
      // navigate(menu.path)
    }

    const copy = { ...state };
    copy.selected = true;

    if (copy.expandable) {
      copy.isExpanded = !copy.isExpanded;
    }

    if (onClick) {
      onClick(copy);
    }
    setState(copy);
  };

  const renderChevron = () => {
    if (!state.expandable) {
      return <></>;
    }
    return (
      <span className={'item__chevron material-symbols-outlined'}>
        {state.isExpanded ? 'expand_more' : 'chevron_right'}
      </span>
    );
  };

  const renderIcon = () => {
    if (!state.icon) {
      return <span></span>;
    }
    return (
      <span
        className={[
          'material-symbols-outlined',
          menu.className ? menu.className : ''
        ]
          .join(' ')
          .trim()}
      >
        {state.icon}
      </span>
    );
  };

  return menu.label === '-' ? (
    <hr />
  ) : (
    <button className={'aside-menu__item'} onClick={handleClick}>
      <span className={'item__icon'}>{renderIcon()} </span>
      <span
        className={['item__label', menu.className ? menu.className : '']
          .join(' ')
          .trim()}
      >
        {state.label}
      </span>
      {renderChevron()}
    </button>
  );
}
