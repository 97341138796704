import merge from 'lodash.merge';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import YesNoDialog from '../../components/dialogs/yes-no-dialog';
import Toolbar from '../../components/page-toolbar';
import { PrivatePage } from '../../components/private-page';
import { AssessmentContentFullEditor } from '../../features/assessment/assessment-content-full-editor';
import { AddResourcePackAsync } from '../../features/assessment/assessment-document';
import { ResourcePackEditorWindow } from '../../features/game-document/resource-packs/resource-pack-editor-window';
import {
  getAssessmentById,
  getDocumentLatestAsync,
  getDocumentsByAssessmentIdAsync,
  publishAssessmentAsync
} from '../../services/assessment';
import { GetLanguagesAsync } from '../../services/country';
import { appStore } from '../../stores/app-store';
import { toastStore } from '../../stores/toast-store';
import { Assessment, AssessmentApproval } from '../../types/assessment';
import { YesNoDialogResult } from '../../types/dialog-result';
import { ResourcePackEntity } from '../../types/game-document/entities';
import { EntityEditor } from '../../types/game-document/entity-editor';
import { CountryResponse } from '../../types/country-response';

const defaultEntity = (): Assessment => {
  return {
    id: 0,
    name: '',
    title: '',
    organisationId: 1,
    htmlContent: '',
    description: '',
    version: 'v0.0.1',
    status: 'New'
  };
};

const AssessmentDetailView = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [entity, setEntity] = useState<Assessment>();
  const [languages, setLanguages] = useState<CountryResponse[]>([]);
  const [, setSelectedTab] = useState<string>('');
  const [entityEditorIsVisible, setEntityEditorIsVisible] =
    useState<boolean>(false);
  const [entityEditorValue] = useState<EntityEditor<ResourcePackEntity>>();
  const [currentLocale, setCurrentLocale] = useState<string>();
  const [, setLatestStatus] = useState<string>('');
  const [openApprovalDialog, setOpenApprovalDialog] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState<string>('');
  const [confirmMessage, setConfirmMessage] = useState<string>();
  const [confirmTitle, setConfirmTitle] = useState<string>();

  const detailAssessmentBreadcrumb = [
    { label: 'Dashboard', href: 'dashboard' },
    { label: 'Administration', href: '../administration' },

    {
      label: 'Pending Approval',
      href: 'administration/assessments/pending'
    },
    {
      label: 'Assessment Approval',
      href: 'administration/assessments/pending'
    },
    {
      label: 'Assessment Details View',
      href: `../administration/assessment-detail-view/${id}`
    }
  ];

  const getDetails = async () => {
    try {
      appStore.showLoading();

      // combine assessment and assessment documents (resources)
      let assess1 = await getAssessmentById(parseInt(id!));
      let assess2 = await getDocumentsByAssessmentIdAsync(parseInt(id!));

      let assessment = merge(assess1, assess2);

      setEntity(assessment);
    } catch (ex) {
      console.error(ex);
    } finally {
      appStore.hideLoading();
    }
  };

  const setDefaultEntity = () => {
    setEntity(defaultEntity);
  };

  const handleEntityChange = (newEntity: Assessment) => {
    setEntity(newEntity);
  };

  const getLatestDocumentAsync = async () => {
    let document = await getDocumentLatestAsync(parseInt(id!));
    setLatestStatus(document?.status ?? '');
  };

  const getLanguages = async () => {
    const response = await GetLanguagesAsync();
    setLanguages(response.data);
  };

  const handleEntityEditorSubmit = async (
    editorEntity: EntityEditor<ResourcePackEntity>,
    isCopyOriginal?: boolean
  ) => {
    await AddResourcePackAsync(
      entity!,
      editorEntity?.entity?.name,
      languages.find((i) => i.language === editorEntity?.entity?.name)
        ?.countryIso ?? 'en',
      isCopyOriginal
    ).then((response) => {
      setEntity((prev) => ({ prev, ...response }));
      setCurrentLocale(editorEntity?.entity?.name);
    });

    toggleEntityEditor();
  };

  const toggleLanguageTab = (activeTab: string) => {
    if (activeTab === 'language') {
      setSelectedTab(activeTab);
    } else {
      setSelectedTab('');
    }
  };

  const toggleEntityEditor = () => {
    setEntityEditorIsVisible(!entityEditorIsVisible);
  };

  const showApproveSectionDialog = (approval: 'Approved' | 'Rejected') => {
    setApprovalStatus(approval);
    if (approval === 'Approved') {
      setConfirmTitle('Approve assessment');
      setConfirmMessage('Are you sure you want to approve this assessment?');
    } else {
      setConfirmTitle('Reject assessment');

      setConfirmMessage('Are you sure you want to reject this assessment?');
    }
    setOpenApprovalDialog(true);
  };

  const confirmApproveSectionDialog = async (result: YesNoDialogResult) => {
    if (result === 'yes') {
      try {
        let document = await getDocumentLatestAsync(parseInt(id!));

        let request: AssessmentApproval = {
          assessmentId: parseInt(id!),
          fileName: document?.fileName ?? '',
          version: document?.version ?? '',
          status: approvalStatus,
          isGlobal: true,
          isDeleted: false
        };

        let result = await publishAssessmentAsync(
          parseInt(id!),
          document.id,
          request
        );

        if (entity) {
          let newEntity = { ...entity };
          if (newEntity) {
            newEntity.status = result.status;
            setEntity(newEntity!);
            setLatestStatus(result?.status ?? '');
          }
        }

        toastStore.show(
          'Assessment approval',
          <div>{`Assessment approved.`}</div>,
          'success'
        );

        navigate(`../assessments/pending`);
      } catch (e: any) {
        toastStore.show(
          'Assessment approval',
          `Assessment cannot be approved.`,
          'error'
        );
      }
    }
    setOpenApprovalDialog(false);
  };

  useEffect(() => {
    if (id) {
      getDetails();
      getLatestDocumentAsync();
    } else {
      setDefaultEntity();
    }

    getLanguages();
  }, []);

  return (
    <PrivatePage
      breadcrumb={detailAssessmentBreadcrumb}
      pageTitle={'Assessment'}>
      <>
        <Toolbar title={entity?.name ?? ''}>
          {entityEditorIsVisible && (
            <ResourcePackEditorWindow
              toggleDialog={toggleEntityEditor}
              onClose={toggleEntityEditor}
              editorEntity={entityEditorValue!}
              editorMode={'basic'}
              onSubmit={handleEntityEditorSubmit}></ResourcePackEditorWindow>
          )}
          {entityEditorIsVisible && (
            <ResourcePackEditorWindow
              toggleDialog={toggleEntityEditor}
              onClose={toggleEntityEditor}
              editorEntity={entityEditorValue!}
              editorMode={'basic'}
              onSubmit={handleEntityEditorSubmit}></ResourcePackEditorWindow>
          )}
        </Toolbar>
        <hr />
        <Container>
          {entity && (
            <AssessmentContentFullEditor
              isViewMode={true}
              languages={languages}
              entity={entity}
              currentLocale={currentLocale}
              setCurrentLocale={setCurrentLocale}
              handleEntityChange={handleEntityChange}
              toggleLanguageTab={toggleLanguageTab}
            />
          )}
          <Row className={'mt-3'}>
            <Col md={6}>
              <Button
                variant={'danger'}
                className={'w-100 mt-1'}
                onClick={() => showApproveSectionDialog('Rejected')}>
                Reject
              </Button>
            </Col>
            <Col md={6}>
              <Button
                variant={'success'}
                className={'w-100 mt-1'}
                onClick={() => showApproveSectionDialog('Approved')}>
                Approve
              </Button>
            </Col>
          </Row>
        </Container>
      </>
      {openApprovalDialog && (
        <YesNoDialog
          title={confirmTitle}
          onConfirm={confirmApproveSectionDialog}
          onClose={() => setOpenApprovalDialog(false)}>
          {confirmMessage}
        </YesNoDialog>
      )}
    </PrivatePage>
  );
};

export default AssessmentDetailView;
