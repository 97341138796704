import { TextArea, TextAreaChangeEvent } from '@progress/kendo-react-inputs';
import { useState } from 'react';
import CustomColumnContainer, {
  CustomColumnProps
} from './custom-column-container';
import { Label } from '@progress/kendo-react-labels';

interface CustomColumnTextareaProps extends CustomColumnProps {
  id?: string;
  text?: string;
  rows?: number;
  autoSize?: boolean;
  label?: string;
  isDisable?: boolean;
  onChange?: (id?: string, text?: string) => void;
}

const CustomColumnTextarea = ({
  onChange = () => {},
  rows = 4,
  autoSize = false,
  ...props
}: CustomColumnTextareaProps) => {
  const [textState, setTextState] = useState<string>(props?.text ?? '');
  const onChangeHandler = (e: TextAreaChangeEvent) => {
    setTextState(e.target.value!.toString());
  };
  const onBlurHandler = () => {
    onChange(props?.id, textState);
  };
  return (
    <CustomColumnContainer
      columnClass={props?.columnClass}
      containerClass={props?.containerClass}>
      {props.label && <Label editorId={props.id}>{props.label}</Label>}
      <TextArea
        id={props.id}
        value={textState}
        rows={rows}
        autoSize={autoSize}
        disabled={props.isDisable}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}></TextArea>
    </CustomColumnContainer>
  );
};

export default CustomColumnTextarea;
