import { GameDocumentContext } from '../../contexts/game-document';
import { matchPath, Outlet, useLocation, useParams } from 'react-router-dom';
import { useContext } from 'react';
import Header, { HeaderBreadcrumb } from '../../components/header';
import { GetResourceValue, GetZoneById } from '../../utils/game-document';

interface PageProps {
  className?: string;
}

const Page = (props: PageProps) => {
  const [state, setState] = useContext(GameDocumentContext);
  const location = useLocation();
  const { zoneId } = useParams();
  // HACK: This Is Horrible
  const getCrumbs = () => {
    let items = Array<HeaderBreadcrumb>();
    let match = matchPath('/designer/:gameId/*', location.pathname);
    if (match) {
      items.push({ text: 'Overview', to: match.pathnameBase });
    }
    if ((match = matchPath('/designer/:gameId/map/*', location.pathname))) {
      items.push({ text: 'Map', to: match.pathnameBase });
    }
    if (
      (match = matchPath('/designer/:gameId/map/:zoneId/*', location.pathname))
    ) {
      items.push({ text: `${getZoneTitle(zoneId)}`, to: match.pathnameBase });
    }
    if ((match = matchPath('/designer/:gameId/items/*', location.pathname))) {
      items.push({ text: 'Inventory', to: match.pathnameBase });
    }
    if ((match = matchPath('/designer/:gameId/titles/*', location.pathname))) {
      items.push({ text: 'Titles', to: match.pathnameBase });
    }
    if (
      (match = matchPath('/designer/:gameId/assessment/*', location.pathname))
    ) {
      items.push({ text: 'Assessment', to: match.pathnameBase });
    }
    return items;
  };
  const getResourceValue = (resourceId: string | undefined) => {
    if (resourceId && resourceId !== '') {
      return GetResourceValue(state.gameDocument!, resourceId!);
    } else {
      return 'New Game';
    }
  };
  const getZoneTitle = (zoneId: string | undefined) => {
    if (zoneId) {
      const zone = GetZoneById(state.gameDocument, zoneId);
      if (zone) {
        return GetResourceValue(state.gameDocument!, zone.titleResId!);
      }
    }
  };

  return (
    <div className={`page-base ${props.className ?? 'page-base--full'}`}>
      <Header
        title={state.gameDocument?.name ?? ''}
        breadCrumbs={getCrumbs()}
      />
      <main>
        <Outlet />
      </main>
    </div>
  );
};

export default Page;
