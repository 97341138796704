import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridNoRecords
} from '@progress/kendo-react-grid';
import { DataResult, State } from '@progress/kendo-data-query';
import { DefaultGridSettings } from '../constants/grid-settings';
import PageToolbar from '../components/page-toolbar';
import {
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../components/grid/grid-tool-bar';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { ColumnMenu } from '../components/columnMenu';
import { NoRecords } from '../components/grid/no-records';
import { appStore } from '../stores/app-store';
import { GetEventReportsAsync } from '../services/event-reports';
import { formatDateToDDMMYYYY } from '../utils/date';
import HelpSupport from '../components/help-support';

const initialDataState: State = {
  sort: [{ field: 'name', dir: 'asc' }],
  ...DefaultGridSettings.initialDataState
};

const ReportsGrid = () => {
  const navigate = useNavigate();
  const [dataState, setDataState] = useState<State>(initialDataState);
  const [gridMode, setGridMode] = useState<string>('grid');
  // data grid
  const [reports, setReports] = useState<DataResult>({
    data: [],
    total: 0
  });

  const handleTitleClick = (id: number) => {
    navigate(`../reports/${+id.toString()}`);
  };

  const onColumnTitleClick = (props: GridCellProps) => (
    <EditCommandCell {...props} />
  );

  const EditCommandCell = (props: GridCellProps) => {
    return (
      <td>
        <span
          className={'link-primary cursor-pointer p-0 text-start'}
          onClick={() => handleTitleClick(props.dataItem.id)}>
          {props.dataItem.name}
        </span>
      </td>
    );
  };

  const gridExportRef = useRef<ExcelExport | null>(null);

  const fetchReports = async () => {
    try {
      appStore.showLoading();
      await GetEventReportsAsync(dataState)
        .then((response) => {
          setReports(response);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (ex) {
      console.error(ex);
    } finally {
      appStore.hideLoading();
    }
  };

  useEffect(() => {
    fetchReports();
  }, [dataState]);

  const DateCell = (props: GridCellProps) => {
    return (
      <td>{formatDateToDDMMYYYY(props.dataItem.actualStartDateUtc, true)}</td>
    );
  };

  return (
    <>
      <PageToolbar title={'My reports'}>
        <HelpSupport
          title="Reports"
          url="https://forum.catalystglobal.com/t/reports"
        />
      </PageToolbar>

      <div className={'pt-2'}>
        <GridToolBar
          searchPlaceholder={'Search reports'}
          columnsToSearch={['name']}
          exportRef={gridExportRef}
          onGridModeChange={(e) => {
            setGridMode(e.gridMode);
          }}
          {...dataState}
          onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
            setDataState(e.dataState);
          }}></GridToolBar>
        <ExcelExport
          data={reports.data}
          ref={gridExportRef}
          fileName={`reports.xlsx`}>
          {gridMode === 'grid' && (
            <Grid
              id={'grid-reports'}
              pageable={DefaultGridSettings.pagerSettings}
              sortable={true}
              data={reports}
              {...dataState}
              onDataStateChange={(e: GridDataStateChangeEvent) => {
                setDataState(e.dataState);
              }}>
              <Column
                field={'actualStartDateUtc'}
                title={'Event Date'}
                cell={DateCell}
              />
              <Column
                field={'name'}
                title={'Report'}
                columnMenu={ColumnMenu}
                cell={onColumnTitleClick}
              />
              <Column
                field={'organisation'}
                title={'Organisation'}
                columnMenu={ColumnMenu}
              />
              <Column
                field={'agencyOrClient'}
                title={'Agency/Client'}
                columnMenu={ColumnMenu}
              />
              <Column field={'pax'} title={'Pax'} columnMenu={ColumnMenu} />
              <GridNoRecords>
                <NoRecords />
              </GridNoRecords>
            </Grid>
          )}
        </ExcelExport>
      </div>
    </>
  );
};

export default ReportsGrid;
