import { useEffect, useState } from 'react';
import { BreadcrumbLocation, PrivatePage } from '../components/private-page';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Label } from '@progress/kendo-react-labels';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toastStore } from '../stores/toast-store';
import { formatDateToDDMMYYYY } from '../utils/date';
import Hero from '../components/hero';
import { getParameterByName } from '../utils/urlParameter';
import { GameResponse } from '../types/responses/game-response';
import { GameDocument, MediaOverview } from '../types/game-document';
import { GameDocumentVersionResponse } from '../types/responses/game-document-version-response';
import {
  CopyGameAsync,
  GetApprovedGameAsyncById,
  GetGameAsyncById
} from '../services/games';
import {
  GetApprovedGameDocumentContentAsync,
  GetApprovedGameDocumentLatestAsync,
  GetGameDocumentContentAsync,
  GetGameDocumentLatestAsync
} from '../services/json-document';
import { GetResourceValue } from '../utils/game-document';
import { Summary } from '../components/overview';
import { appStore } from '../stores/app-store';
import Video from '../components/video';
import { FileUrl } from './game-designer/game-overview';

export type PageType = 'My library' | 'Global library';

export function LibraryPage() {
  const { id } = useParams();
  const libraryID = parseInt(id!);

  const [game, setGames] = useState<GameResponse>();
  const [gameDocumentContent, setGameDocumentContent] =
    useState<GameDocument>();
  const [gameDocumentLatest, setGameDocumentLatest] =
    useState<GameDocumentVersionResponse>();
  const navigate = useNavigate();
  const [page, setPage] = useState<string>('');
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbLocation[]>([]);
  const [pageType, setPageType] = useState<PageType>('Global library');
  const [previewItem, setPreviewItem] = useState<string>('');
  const [previewItemType, setPreviewItemType] = useState<string>('');

  useEffect(() => {
    let page = getParameterByName('page', window.location.href);

    if (page) {
      setPage(page);
    }
  }, []);

  useEffect(() => {
    if (page && page !== '') {
      if (id) {
        fetchGame(libraryID);
      }
      if (page.toLowerCase() === 'mylibrary') {
        setPageType('My library');
        setBreadcrumbs([
          {
            label: 'Dashboard',
            href: 'dashboard'
          },
          {
            label: 'My Library',
            href: 'mylibrary/games'
          },
          {
            label: 'Details',
            href: `global-games/${libraryID}`
          }
        ] as BreadcrumbLocation[]);
      } else {
        setPageType('Global library');
        setBreadcrumbs([
          {
            label: 'Dashboard',
            href: 'dashboard'
          },
          {
            label: 'Global Library',
            href: 'global-games'
          },
          {
            label: 'Details',
            href: `global-games/${libraryID}`
          }
        ] as BreadcrumbLocation[]);
      }
    }
  }, [page]);

  const fetchGame = async (gameId: number) => {
    try {
      appStore.showLoading();
      let game;
      let gameDocumentLatest;
      let gameDocumentContent;

      if (page.toLowerCase() === 'mylibrary') {
        game = await GetGameAsyncById(gameId);
        gameDocumentLatest = await GetGameDocumentLatestAsync(gameId);
        gameDocumentContent = await GetGameDocumentContentAsync(
          gameId,
          gameDocumentLatest.id!
        );
      } else {
        game = await GetApprovedGameAsyncById(gameId);
        gameDocumentLatest = await GetApprovedGameDocumentLatestAsync(gameId);
        gameDocumentContent = await GetApprovedGameDocumentContentAsync(
          gameId,
          gameDocumentLatest.id!
        );
      }

      setGames(game);
      setGameDocumentLatest(gameDocumentLatest);
      setGameDocumentContent(gameDocumentContent);
    } catch (ex) {
      console.error('Error message:', ex);
    } finally {
      appStore.hideLoading();
    }
  };

  const handleBack = () => {
    if (pageType === 'Global library') {
      navigate('/global-games');
    } else if (pageType === 'My library') {
      navigate('/mylibrary/games');
    }
  };

  const onCopyLibrary = async () => {
    try {
      appStore.showLoading();

      await CopyGameAsync(libraryID, page.toLowerCase() !== 'mylibrary');

      toastStore.show(
        'My library',
        <div>
          Game copied to My Library.
          <br />
          <Link to={'/mylibrary/games'}>Go to My Library.</Link>
        </div>,
        'success'
      );
    } catch (err: any) {
      toastStore.show('My library', 'Failed to copy to My Library.', 'error');
    } finally {
      appStore.hideLoading();
    }
  };

  const onClickMedia = async (url: FileUrl) => {
    if (url && url.url && url.type) {
      setPreviewItem(url.url.replace('/thumbnails', ''));

      setPreviewItemType(url.type);
      if (url.type.includes('video')) {
        setPreviewItem(url.url);
      }
    }
  };
  return (
    <PrivatePage breadcrumb={breadcrumbs} pageTitle={pageType}>
      <h2>Overview</h2>
      <hr />
      <Container>
        <div className={'d-flex'}>
          <Row>
            <Col xs={9}>
              <div className={'border-end'}>
                <Row>
                  <Col xs={12} className={'mb-3 w-100'}>
                    <Hero
                      src={
                        gameDocumentContent &&
                        GetResourceValue(
                          gameDocumentContent!,
                          gameDocumentContent?.overview?.headerResId!
                        )
                      }
                      editable={false}
                    />
                    <div
                      className={'d-flex align-items-center h-4'}
                      style={{
                        background: 'rgb(0, 58, 100)'
                      }}>
                      <div>
                        <h4 className={'text-light fw-bold mb-0 ml-22'}>
                          {game?.name}
                        </h4>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} className={'mb-5 w-100'}>
                    <Col md={3}>
                      <div className={'d-flex flex-column'}>
                        <div
                          className={'round-card rounded-full h-9 w-9 bg-white'}
                          style={{
                            marginTop: '-130px',
                            backgroundImage: `url('${
                              gameDocumentContent &&
                              GetResourceValue(
                                gameDocumentContent!,
                                gameDocumentContent?.overview?.logoResId!
                              )
                            }')`
                          }}></div>
                      </div>
                    </Col>
                  </Col>
                  <Col xs={12} className={'mb-5 mr-12'}>
                    <Label className={'fw-bold'}>Short Description</Label>
                    <div className={'pr-3'}>
                      <p>{game?.shortDescription}</p>
                    </div>
                  </Col>
                  <Col xs={12} className={'mb-5 mr-12'}>
                    <Label className={'fw-bold'}>Long Description</Label>
                    <p>
                      {gameDocumentContent &&
                        GetResourceValue(
                          gameDocumentContent!,
                          gameDocumentContent?.overview?.longDescriptionResId!
                        )}
                    </p>
                  </Col>
                  <Col xs={12}>
                    <Video
                      url={previewItem}
                      loop={true}
                      type={previewItemType}
                    />
                  </Col>
                  <Col xs={12} className={'mb-5 mr-12'}>
                    <Label className={'fw-bold'}>Media</Label>
                    <div className={'thumb-nails d-flex flex-wrap'}>
                      {gameDocumentContent?.overview?.medias?.length! > 0 &&
                        gameDocumentContent?.overview?.medias?.map(
                          (file: MediaOverview, index: number) => (
                            <div
                              key={index}
                              className={
                                'w-8 h-8 bg-light position-relative rounded d-flex overflow-hidden flex-column justify-content-center align-items-center'
                              }
                              onClick={() =>
                                onClickMedia({
                                  url: gameDocumentContent?.resources.find(
                                    (f) => f.id === file.value
                                  )?.value as string,
                                  type: file.type
                                })
                              }>
                              {file && file.type === 'video' ? (
                                <>
                                  <video className={'rounded'}>
                                    <source
                                      src={
                                        gameDocumentContent &&
                                        GetResourceValue(
                                          gameDocumentContent!,
                                          file.value
                                        )
                                      }
                                      type={'video/mp4'}
                                    />
                                  </video>
                                  <div
                                    className={
                                      'mask position-absolute top-0 end-0 h-full w-full d-flex align-items-center justify-content-center'
                                    }
                                    style={{
                                      backgroundImage: `url("${
                                        gameDocumentContent?.resources.find(
                                          (f) => f.id === file.thumbnailValue
                                        )?.value ?? ''
                                      }")`,
                                      backgroundPosition: 'center'
                                    }}>
                                    <button className={'btn text-light'}>
                                      <span
                                        className={'material-symbols-outlined'}>
                                        play_arrow
                                      </span>
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <img
                                    src={
                                      gameDocumentContent &&
                                      GetResourceValue(
                                        gameDocumentContent!,
                                        file.value
                                      )
                                    }
                                    className={'rounded'}
                                    alt={''}
                                  />
                                </>
                              )}
                            </div>
                          )
                        )}
                    </div>
                  </Col>
                  <Col xs={12} className={'mb-5'}>
                    <Row>
                      <Col xs={4}>
                        <Label className={'fw-bold'}>
                          Recommended number of players
                        </Label>
                        <p className={'text-success fw-bold'}>
                          {gameDocumentContent &&
                            GetResourceValue(
                              gameDocumentContent!,
                              gameDocumentContent?.overview
                                ?.recommendedNumberOfPlayersResId!
                            )}
                        </p>
                      </Col>
                      <Col xs={4}>
                        <Label className={'fw-bold'}>
                          Recommended duration
                        </Label>
                        <p className={'text-success fw-bold'}>
                          {gameDocumentContent &&
                            GetResourceValue(
                              gameDocumentContent!,
                              gameDocumentContent?.overview
                                ?.recommendedDurationResId!
                            )}
                        </p>
                      </Col>
                      <Col xs={4}>
                        <Label className={'fw-bold'}>Players per Team</Label>
                        <p className={'text-success fw-bold'}>
                          {gameDocumentContent?.overview?.playersPerTeam}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} className={'mb-5 mr-12'}>
                    <Label className={'fw-bold'}>Localisations</Label>
                    <p className={'text-success fw-bold'}>{game?.languages}</p>
                  </Col>
                  <Col xs={12} className={'mb-5 mr-12'}>
                    <Label className={'fw-bold'}>Learning Outcome</Label>
                    <p>
                      {gameDocumentContent &&
                        GetResourceValue(
                          gameDocumentContent!,
                          gameDocumentContent?.overview?.learningOutcomeResId!
                        )}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className={'mb-5'}>
                    <hr />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div
                      className={
                        'd-flex gap-3 align-items-center justify-content-end pr-2'
                      }>
                      <Button
                        variant={'secondary'}
                        className={'pl-11 pr-11 text-white'}
                        onClick={handleBack}>
                        Back
                      </Button>
                      <Button
                        variant={'primary'}
                        className={'pl-20 pr-20 text-white'}
                        onClick={onCopyLibrary}>
                        Copy to My library
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={3}>
              <div className={'rounded bg-light border border-2 p-3'}>
                <Summary
                  author={gameDocumentLatest?.createdBy}
                  createdDate={
                    gameDocumentLatest?.createdDateUtc &&
                    formatDateToDDMMYYYY(
                      gameDocumentLatest?.createdDateUtc!,
                      true
                    )
                  }
                  updateDate={
                    gameDocumentLatest?.modifiedDateUtc &&
                    formatDateToDDMMYYYY(
                      gameDocumentLatest?.modifiedDateUtc!,
                      true
                    )
                  }
                  version={gameDocumentLatest?.version}
                />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </PrivatePage>
  );
}
