import { GridPagerSettings } from '@progress/kendo-react-grid';
import { State } from '@progress/kendo-data-query';

interface GridSettings {
  pageSize: number;
  pagerSettings: GridPagerSettings;
  initialDataState: State;
}

const smallestPageSize: number = 20;

export const DefaultGridSettings: GridSettings = {
  pageSize: smallestPageSize,
  pagerSettings: {
    info: true,
    pageSizes: [smallestPageSize, 50, 100],
    previousNext: true
  },
  initialDataState: {
    take: smallestPageSize,
    skip: 0
  }
};

export const DefaultPagerSettings = {
  info: true,
  pageSizes: [smallestPageSize, 50, 100],
  previousNext: true
};
