import { Checkbox, NumericTextBox } from '@progress/kendo-react-inputs';
import { Col, Row } from 'react-bootstrap';
import { FormInput } from '../form-input';
import { TaskContentAnswer } from '../../types/game-document/entities/task-content';
import cloneDeep from 'lodash.clonedeep';

export interface AnswerOptionProps {
  group?: string;
  options?: TaskContentAnswer[];
  answerOptionIndex?: number;
  onChangeAnswers?: (answers: TaskContentAnswer[]) => void;
}
export function AnswerCheckboxScoring({
  group,
  options,
  onChangeAnswers = () => {}
}: AnswerOptionProps) {
  const onChangeScore = (index: number, value: number) => {
    if (options) {
      let newAnswers: TaskContentAnswer[] = cloneDeep([...options]);
      newAnswers[index].score = value;
      onChangeAnswers(newAnswers);
    }
  };

  return (
    <>
      {options &&
        options.map((option: TaskContentAnswer, index: number) => (
          <Row className={'p-2'} key={index}>
            <Col md={8} className={'d-flex align-items-end pr-0'}>
              <Checkbox
                disabled={true}
                className={'p-1'}
                size={'large'}
                name={group}
                value={option?.description}
                checked={option?.isChecked}
              />
              <FormInput
                name={'answerResId'}
                className={'w-100'}
                disabled={true}
                value={options[index]?.description}
              />
            </Col>
            <Col md={4} className={'pl-0'}>
              {index === 0 && <span className={'pl-2 fw-bold'}>Score</span>}
              <NumericTextBox
                name={'score'}
                className={'ml-1'}
                value={options[index]?.score ?? 0}
                onChange={(e) => onChangeScore(index, e.target.value as number)}
              />
            </Col>
          </Row>
        ))}
    </>
  );
}
