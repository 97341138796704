import React, { useEffect, useState } from 'react';
import {
  Editor,
  EditorChangeEvent,
  EditorMountEvent,
  EditorProps,
  EditorTools
} from '@progress/kendo-react-editor';
import { createView } from './create-markdown-view';
import { InsertMedia } from '../editor/insert-media-tools';
import { defaultMarkdownParser } from 'prosemirror-markdown';

interface EditorInputProps extends EditorProps {
  handleMarkdownChange?: (event: EditorChangeEvent) => void;
}

export function MarkdownEditor({ ...props }: EditorInputProps) {
  const [value, setValue] = useState<any>('');
  const {
    Bold,
    Italic,
    Link,
    Unlink,
    FormatBlock,
    Undo,
    Redo,
    OrderedList,
    UnorderedList
  } = EditorTools;

  const onMount = (event: EditorMountEvent) => createView(event);

  const onChange = (e: EditorChangeEvent) => {
    setValue(e.value);
    if (props.handleMarkdownChange) props.handleMarkdownChange(e);
  };

  useEffect(() => {
    setValue(defaultMarkdownParser.parse(props.value as string));
  }, [props.value]);

  return (
    <Editor
      contentStyle={{ height: 500, width: '100%' }}
      onMount={onMount}
      tools={[
        [
          Bold,
          Italic,
          Link,
          Unlink,
          (pluginProps: any) =>
            InsertMedia({ ...pluginProps, resizeImage: false })
        ],
        [Undo, Redo],
        [OrderedList, UnorderedList],
        FormatBlock
      ]}
      onChange={onChange}
      value={value!}
    />
  );
}
