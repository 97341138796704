import React from 'react';
import { useAuth } from 'react-oidc-context';
import { Navigate, Outlet } from 'react-router-dom';
import { Permission } from '../types/permission';

interface ProtectedProps {
  allowedRole: string;
}

const getPermissionKey = (value: string) => {
  const indexOfS = Object.values(Permission).indexOf(
    value as unknown as Permission
  );

  const key = Object.keys(Permission)[indexOfS];

  return key;
};

const Protected = ({ allowedRole }: ProtectedProps) => {
  const auth = useAuth();
  const role = getPermissionKey(allowedRole);
  const isInRole = (auth.user?.profile.role as string[]).includes(role);
  if (!isInRole) {
    return <Navigate to={'/'} replace />;
  }

  return <Outlet />;
};

export default Protected;
