import { useEffect, useRef, useState } from 'react';
import {
  Grid,
  GridColumn as Column,
  GridNoRecords
} from '@progress/kendo-react-grid';
import { DataResult, State } from '@progress/kendo-data-query';
import { ColumnMenu } from '../components/columnMenu';
import {
  ExcelExport,
  ExcelExportColumn
} from '@progress/kendo-react-excel-export';
import {
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../components/grid/grid-tool-bar';
import Toolbar from '../components/page-toolbar';
import { DefaultGridSettings } from '../constants/grid-settings';
import { NoRecords } from '../components/grid/no-records';
import { appStore } from '../stores/app-store';
import {
  GetAllUsersForNetworkAsync,
  GetAllUsersForNetworkReportAsync
} from '../services/users';
import {
  CardViewDataStateChangeEvent,
  NetworkMembersCard
} from './network-members-card';
import HelpSupport from '../components/help-support';

const initialDataState: State = {
  sort: [{ field: 'username', dir: 'asc' }],
  ...DefaultGridSettings.initialDataState
};

const NetworkMembersGrid = () => {
  const [dataState, setDataState] = useState<State>(initialDataState);
  const [gridMode, setGridMode] = useState<string>('grid');
  const [users, setUsers] = useState<DataResult>({
    data: [],
    total: 0
  });

  useEffect(() => {
    async function fetchUsers() {
      try {
        const response = await GetAllUsersForNetworkAsync(dataState);
        setUsers(response);
      } catch (ex) {
        console.error(ex);
      }
    }
    fetchUsers();
  }, [dataState]);

  const reportExportRef = useRef<ExcelExport | null>(null);

  const onExportExcel = async () => {
    appStore.showLoading();
    try {
      await GetAllUsersForNetworkReportAsync().then((response) => {
        if (reportExportRef.current !== null) {
          reportExportRef.current.save(response);
        }
      });
    } catch (error) {
      console.error(error);
    } finally {
      appStore.hideLoading();
    }
  };

  return (
    <>
      <Toolbar title={'All users'}>
        <HelpSupport
          title="Network Members"
          url="https://forum.catalystglobal.com/t/my-network"
        />
      </Toolbar>
      <ExcelExport ref={reportExportRef} fileName={'UsersReport.xlsx'}>
        <ExcelExportColumn field="fullName" title="Name" width={300} />
        <ExcelExportColumn field="username" title="Email address" width={350} />
        <ExcelExportColumn field="phoneNumber" title="Phone" width={200} />
        <ExcelExportColumn field="department" title="Department" width={200} />
        <ExcelExportColumn
          field="organisation"
          title="Organisation"
          width={250}
        />
      </ExcelExport>
      <div className={'pt-2'}>
        <GridToolBar
          searchPlaceholder={'Search users'}
          columnsToSearch={['username', 'fullName']}
          onGridModeChange={(e) => {
            setGridMode(e.gridMode);
          }}
          exportRef={reportExportRef}
          useCustomExportExcel={true}
          handleCustomExportToExcel={onExportExcel}
          {...dataState}
          onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
            setDataState(e.dataState);
          }}></GridToolBar>

        {gridMode === 'grid' && (
          <Grid
            id={'grid-user'}
            pageable={DefaultGridSettings.pagerSettings}
            sortable={true}
            data={users}
            {...dataState}
            onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
              setDataState(e.dataState);
            }}>
            <Column field={'fullName'} title={'Name'} columnMenu={ColumnMenu} />
            <Column
              field={'username'}
              filter={'text'}
              title={'Email address'}
              columnMenu={ColumnMenu}
            />
            <Column
              field={'phoneNumber'}
              title={'Phone'}
              columnMenu={ColumnMenu}
            />
            <Column
              field={'department'}
              title={'Department'}
              columnMenu={ColumnMenu}
            />
            <Column
              field={'organisation'}
              title={'Organisation'}
              columnMenu={ColumnMenu}
            />
            <GridNoRecords>
              <NoRecords />
            </GridNoRecords>
          </Grid>
        )}
        {gridMode === 'card' && (
          <NetworkMembersCard
            data={users.data}
            total={users.total}
            {...dataState}
            onDataStateChange={(e: CardViewDataStateChangeEvent) => {
              setDataState(e.dataState);
            }}
          />
        )}
      </div>
    </>
  );
};
export default NetworkMembersGrid;
