import { InstanceProviderConfig } from './contexts/instance-provider';

// prettier-ignore
const instanceConfig: InstanceProviderConfig = {
  siteName: window?._env_?.VITE_SITE_NAME || "Catalyst Games",
  version: window?._env_?.VITE_VERSION || "1.x.x",
  copyright: window?._env_?.VITE_COPYRIGHT || "Catalyst Global",
  clientId: window?._env_?.REACT_APP_CLIENT_ID || process.env.REACT_APP_CLIENT_ID,
  redirectUri: window?._env_?.REACT_APP_REDIRECT_URI || process.env.REACT_APP_REDIRECT_URI,
  silentRedirectUri: window?._env_?.REACT_APP_SILENT_REDIRECT_URI || process.env.REACT_APP_SILENT_REDIRECT_URI,
  authority: window?._env_?.REACT_APP_AUTHORITY || process.env.REACT_APP_AUTHORITY,
  apiBaseUrl: window?._env_?.REACT_APP_API_BASE_URL || process.env.REACT_APP_API_BASE_URL,
};

export default instanceConfig;
