import { useEffect, useState } from 'react';
import { GetOrganisationAsync } from '../services/organisations';

import { Button, Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { PrivatePage } from '../components/private-page';
import { getFormattedDateString } from '../utils/date';
import { Media } from '../types/media';
import { GetOrganisationEmailAsync } from '../services/organisation-email';
import { OrganisationResponse } from '../types/responses/organisation-response';
import UploadFileDialog from '../components/dialogs/upload-file-dialog';
import { FileExtension } from '../types/fileExtensions';
import { EmailAddressResponse } from '../types/responses/email-address-response';
import { AddressResponse } from '../types/responses/address-response';
import { GetOrganisationAddressesAsync } from '../services/organisation-address';

export default function OrganisationView() {
  const [organisation, setOrganisation] = useState<OrganisationResponse>({
    name: '',
    languages: '',
    country: '',
    status: '',
    taxId: '',
    tradeId: '',
    type: '',
    organisationLogoMediaId: null,
    avatarMediaId: null
  });
  const [view, setView] = useState('');
  const [id, setId] = useState('');
  const navigate = useNavigate();

  const location = useLocation();

  const [organisationAddress, setOrganisationAddress] =
    useState<AddressResponse>({
      line1: '',
      countryIso: ''
    });
  const [organisationEmail, setOrganisationEmail] =
    useState<EmailAddressResponse>({
      address: ''
    });

  const [openFileDialog, setOpenFileDialog] = useState<boolean>(false);

  const [logoSrc, setLogoSrc] = useState<
    string | ArrayBuffer | null | undefined
  >();

  const onBack = () => {
    navigate('/administration/organisations/?view=' + view);
  };

  const handleFilesSelected = (files?: Media[]) => {
    if (!files || files.length <= 0) {
      return;
    }
    const file = files[0];

    if (file.mediaId) {
      setOrganisation({
        ...organisation,
        avatarMediaId: file.mediaId
      });
    }

    let fr = new FileReader();
    fr.readAsDataURL(file.file);
    fr.onload = function () {
      setLogoSrc(fr.result);
    };
    setOpenFileDialog(false);
  };

  const fetchOrganisation = async (orgId: number) => {
    GetOrganisationAsync(orgId)
      .then(async (response) => {
        if (response.organisationLogoMediaId !== null) {
          // set logo here
          setLogoSrc(response.logoUrl!);
        }
        setOrganisation(response);
      })
      .catch((error) => console.error('messages:', error));

    await GetOrganisationAddressesAsync(orgId)
      .then((response) => {
        const address = response.data.find((a) => a.type === 'Primary');

        if (address) {
          setOrganisationAddress(address);
        }
      })
      .catch((error) => console.error('messages:', error));

    await GetOrganisationEmailAsync(orgId)
      .then((response) => {
        const email = response.data.find((e) => e.type === 'Primary');

        if (email) {
          setOrganisationEmail(email);
        }
      })
      .catch((error) => console.error('messages:', error));
  };

  const navigateToOrganisation = (id: number) => {
    navigate('/organisation/' + id);
  };

  const getOrganisationId = () => {
    const pathName = location.pathname;
    const splittedPath = pathName.split('/');
    const organisationId = splittedPath[splittedPath.length - 1];
    if (organisationId) {
      setId(organisationId);
    }
    return organisationId;
  };

  useEffect(
    () => {
      getOrganisationId();

      if (id) {
        fetchOrganisation(parseInt(id));
      }
    },
    // eslint-disable-next-line
    [id]
  );

  return (
    <>
      <PrivatePage
        pageTitle="Organisation details"
        breadcrumb={[
          {
            label: 'Administration',
            href: 'administration'
          },
          {
            label: 'Organisations',
            href: 'administration/organisations'
          },
          {
            label: 'Details',
            href: 'administration/organisation/:id'
          }
        ]}>
        <Row>
          <Col xs="2">
            <div
              className={
                'position-relative h-9 w-9 d-flex align-items-center justify-content-center organisation-logo'
              }>
              <div className={'logo-overlay'}>
                <button
                  className={
                    'btn d-flex align-items-center edit-icon justify-content-center rounded-full p-0 h-2 w-2 position-absolute top-50 start-50 translate-middle z-index-10 bg-light hidden'
                  }
                  onClick={() => navigateToOrganisation(Number(id))}>
                  <span className={'material-symbols-outlined'}>edit</span>
                </button>
              </div>

              {logoSrc && (
                <div
                  onClick={() => setOpenFileDialog(true)}
                  className={'round-card rounded-full h-9 w-9'}
                  style={{
                    backgroundImage: `url('${logoSrc}')`
                  }}></div>
              )}
              {!logoSrc && (
                <div
                  onClick={() => setOpenFileDialog(true)}
                  className={
                    'rounded-circle bg-secondary d-flex justify-content-center align-items-center'
                  }
                  style={{ width: 150, height: 150 }}>
                  <span
                    className={
                      'cover-image__placeholder material-symbols-outlined'
                    }>
                    add_photo_alternate
                  </span>
                </div>
              )}
            </div>
          </Col>
          <Col xs="8">
            <div
              className={
                'card p-4 border-top-0 border-right-0 border-bottom-0'
              }>
              <Row>
                <Col md={'4'} className={'d-flex flex-column'}>
                  <small className={'text-muted'}>Organisation name</small>
                  <span className={'fw-bold'}>{organisation?.name}</span>
                </Col>
                <Col md={'8'} className={'d-flex flex-column'}>
                  <small className={'text-muted'}>Address</small>
                  <span className={'fw-bold'}>{organisationAddress.line1}</span>
                </Col>
              </Row>
              <Row className={'mt-3'}>
                <Col md={'4'} className={'d-flex flex-column'}>
                  <small className={'text-muted'}>Country</small>
                  <span className={'fw-bold'}>{organisation?.country}</span>
                </Col>
                <Col md={'4'} className={'d-flex flex-column'}>
                  <small className={'text-muted'}>Language</small>
                  <span className={'fw-bold'}>{organisation.languages}</span>
                </Col>
              </Row>
              <Row className={'mt-3'}>
                <Col xl={'12'} className={'d-flex flex-column'}>
                  <small className={'text-muted'}>Primary email address</small>
                  <span className={'fw-bold'}>
                    {organisationEmail?.address}
                  </span>
                </Col>
              </Row>
            </div>
            <Row>
              <span className={'mt-4'}>Financial details</span>
            </Row>
            <div className={'card p-4'}>
              <Row>
                <Col md="4" className={'d-flex flex-column'}>
                  <small className={'text-muted'}>Tax Identification</small>
                  <span className={'fw-bold'}>{organisation.taxId}</span>
                </Col>
                <Col md={'4'} className={'d-flex flex-column'}>
                  <small className={'text-muted'}>Trade Identification</small>
                  <span className={'fw-bold'}>{organisation.tradeId}</span>
                </Col>
              </Row>
            </div>
            <Row className={'d-flex align-items-center mt-3'}>
              <Col md={'4'} className={'d-flex flex-column'}>
                <div className={'d-flex items-center gap-2'}>
                  <small className={'text-muted'}>Status: </small>
                  <span className={'fs-6 text-primary'}>
                    {organisation.status}
                  </span>
                </div>
                <div className={'d-flex items-center gap-2'}>
                  <small className={'text-muted'}>Date Created: </small>
                  <span className={'fs-6 text-muted'}>
                    {organisation.createdDateUtc &&
                      getFormattedDateString(
                        new Date(organisation.createdDateUtc)
                      )}
                  </span>
                </div>
              </Col>
              <Col md={'8'} className={'d-flex justify-content-end'}>
                <Button
                  variant="secondary"
                  className={'mt-1 text-light px-12'}
                  onClick={onBack}>
                  Back
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        {openFileDialog && (
          <UploadFileDialog
            isMultiple={false}
            isVisibleDropFile={true}
            isVisibleUploadPhoto={true}
            isVisibleTakePhoto={false}
            isDirectUpload={true}
            maxImageSize={1}
            allowedExtension={[FileExtension.JPG, FileExtension.PNG]}
            onFilesSelected={handleFilesSelected}
            onConfirm={() => {
              setOpenFileDialog(false);
            }}
            isIcon={true}
            title={'Upload user profile'}
          />
        )}
      </PrivatePage>
    </>
  );
}
