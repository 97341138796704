import { useEffect, useRef, useState } from 'react';
import { Input, Switch } from '@progress/kendo-react-inputs';
import { Col, Container, Row } from 'react-bootstrap';
import { PrivatePage } from '../components/private-page';
import { Error, Label } from '@progress/kendo-react-labels';
import {
  AddOrganisationTerritoriesAsync,
  DeleteOrganisationAsync,
  GetOrganisationAsync,
  GetTerritoriesOrganisationAsync,
  GetUsersOnOrganisationCheckAsync,
  PostOrganisationAsync,
  PostOrganisationEmailAvailabilityAsync,
  PutOrganisationAsync,
  PutOrganisationTerritoriesAsync
} from '../services/organisations';
import { OrganisationRequest } from '../types/organisation';
import { ValidationRules, validator } from 'nm-validator';
import { useNavigate, useParams } from 'react-router-dom';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import PopupMenu, { popupMenu as MenuList } from '../components/popupMenu';
import YesNoDialog from '../components/dialogs/yes-no-dialog';
import {
  GetOrganisationAddressesAsync,
  PostOrganisationAddressAsync,
  PutOrganisationAddressAsync
} from '../services/organisation-address';
import {
  GetOrganisationEmailAsync,
  PostOrganisationEmailAsync,
  PutOrganisationEmailAsync
} from '../services/organisation-email';
import { toastStore } from '../stores/toast-store';
import { OrganisationResponse } from '../types/responses/organisation-response';
import { BadRequestError } from '../types/errors';
import { StringifiedType } from '../types/stringified-type';
import {
  emailAddress,
  regularExpression,
  required
} from 'nm-validator/dist/validationRules';
import { FormInput } from '../components/form-input';
import { formatDateToDDMMYYYY } from '../utils/date';
import LanguageComboBox from '../components/combobox/language-combobox';
import CountryComboBox from '../components/combobox/country-combobox';
import {
  AddressResponse,
  TerritoriesResponse
} from '../types/responses/address-response';
import {
  AvailabilityCheck,
  EmailAddressResponse
} from '../types/responses/email-address-response';
import CoverImage from '../components/cover-image';
import { Button } from '@progress/kendo-react-buttons';
import { YesNoDialogResult } from '../types/dialog-result';
import { appStore } from '../stores/app-store';
import {
  ResourceWindow,
  UploadedImage
} from '../features/game-document/image-resource/resource-window';
import { PostMediaAsync } from '../services/files';
import cloneDeep from 'lodash.clonedeep';
import { CountryResponse } from '../types/responses/country-response';
import { GetCountriesAsync } from '../services/country';
import { Autocomplete } from '../components/auto-complete';
import CountryCodeCombobox from '../components/combobox/country-code-combobox';
import { StandardInput } from '../components/forms';
import { PhoneNumberResponse } from '../types/responses/phone-number-response';
import {
  GetOrganisationsPhoneNumbersAsync,
  PostOrganisationPhoneNumberAsync,
  PutOrganisationPhoneNumberAsync
} from '../services/organisation-phone-number';

export default function Organisation() {
  const { id } = useParams();

  //if param is undefined or not contain any valid organisation Id, it will be treated as new organisation page
  const isNewOrg = !id; // return true if id is not a number
  const orgId = isNewOrg ? 0 : parseInt(id!);

  const [menuList, setMenuList] = useState<MenuList[]>([]);

  const navigate = useNavigate();

  const [validationError, setValidationError] =
    useState<StringifiedType<OrganisationRequest>>();

  const [phoneNumberRequest, setPhoneNumberRequest] =
    useState<PhoneNumberResponse>({
      type: 'Work',
      number: '',
      countryCode: '',
      countryIso: ''
    });
  const [organisation, setOrganisation] = useState<OrganisationResponse>({
    name: '',
    languages: '',
    country: '',
    status: '',
    taxId: '',
    tradeId: '',
    type: '',
    organisationLogoMediaId: null
  });
  const [organisationAddress, setOrganisationAddress] =
    useState<AddressResponse>({
      line1: '',
      countryIso: ''
    });
  const [organisationTerritories, setOrganisationTerritories] = useState<
    TerritoriesResponse[]
  >([]);
  const [organisationEmail, setOrganisationEmail] =
    useState<EmailAddressResponse>({
      address: ''
    });

  // set to store origin email
  const [originEmail, setOriginEmail] = useState<EmailAddressResponse>({
    address: ''
  });

  const [status, setStatus] = useState({
    state: false,
    text: '',
    className: ''
  });

  const [openFileDialog, setOpenFileDialog] = useState<boolean>(false);
  const [logo, setLogo] = useState({
    url: '',
    fileName: ''
  });

  const [imageLogo] = useState<Blob>();
  const countries = useRef<CountryResponse[]>();
  const [countryData, setCountryData] = useState<CountryResponse[]>([]);

  const handleFilesSelected = (uploadedFiles: UploadedImage) => {
    setLogo({ url: uploadedFiles.blobUrl, fileName: uploadedFiles.fileName });
    setOpenFileDialog(false);
  };

  useEffect(() => {
    if (!imageLogo) {
      return;
    }

    const objectUrl = URL.createObjectURL(imageLogo);
    setOrganisation((prev) => ({ ...prev, imageLogoUrl: objectUrl }));
    return () => URL.revokeObjectURL(objectUrl);
  }, [imageLogo]);

  const generateLogoMenu = () => {
    let newMenus: MenuList[] = [
      { classIcon: 'edit', textMenu: 'Edit', textClass: '' },
      { classIcon: 'delete', textMenu: 'Delete', textClass: 'text-danger' }
    ];

    setMenuList(newMenus);
  };

  const fetchOrganisation = async (orgId: number) => {
    try {
      appStore.showLoading();

      GetOrganisationAsync(orgId)
        .then(async (response) => {
          setOrganisation(response);
          if (response.organisationLogoMediaId) {
            // set logo here
            setLogo({ url: response.logoUrl!, fileName: '' });
          }
        })
        .catch((error) => console.error('mesages:', error));

      GetOrganisationAddressesAsync(orgId)
        .then((response) => {
          const address = response?.data.find((a) => a.type === 'Primary');

          if (address) {
            setOrganisationAddress(address);
          }
        })
        .catch((error) => console.error('mesages:', error));

      GetOrganisationsPhoneNumbersAsync(orgId)
        .then((response) => {
          if (response) {
            const phone = response?.data.find((a) => a.type === 'Work');

            if (phone) {
              setPhoneNumberRequest({
                ...phoneNumberRequest,
                id: phone?.id!,
                number: phone?.number,
                countryCode: phone?.countryCode ? `+${phone.countryCode}` : '',
                countryIso: phone?.countryIso
              });
            }
          }
        })
        .catch((error) => console.error('mesages:', error));

      GetOrganisationEmailAsync(orgId)
        .then((response) => {
          const email = response.data.find((e) => e.type === 'Primary');

          if (email) {
            setOrganisationEmail(email);
            setOriginEmail(email);
          }
        })
        .catch((error) => console.error('mesages:', error));
    } catch (ex) {
      console.error(ex);
    } finally {
      appStore.hideLoading();
    }
  };
  const fetchTerritoriesOrganisation = async (
    orgId: number,
    countries?: CountryResponse[]
  ) => {
    const territories = await GetTerritoriesOrganisationAsync(orgId);
    if (territories) {
      if (countries) {
        const data = countries.filter((country) =>
          territories.includes(country.iso!)
        );
        setOrganisationTerritories(data);
      } else {
        setOrganisationTerritories([]);
      }
    }
  };

  const createOrganisation = async () => {
    try {
      appStore.showLoading();

      let newResource;
      if (logo.url) {
        const blobResponse = await fetch(logo.url);
        const blobContent = await blobResponse.blob();
        newResource = await PostMediaAsync(
          new File([blobContent], logo.fileName)
        );
      }

      let newOrganisation = await PostOrganisationAsync({
        ...organisation,
        organisationLogoMediaId: newResource ? newResource.id : null,
        logoUrl: newResource ? newResource.mediaUrl : undefined,
        status: status.text,
        type: 'Organisation'
      });

      if (newOrganisation) {
        if (newOrganisation.id !== undefined) {
          await PostOrganisationAddressAsync(newOrganisation.id, {
            ...organisationAddress,
            line1: organisationAddress?.line1,
            countryIso:
              organisationAddress?.countryIso === ''
                ? null
                : organisationAddress?.countryIso // Country not mandatory
          } as AddressResponse);

          await PostOrganisationEmailAsync(newOrganisation.id, {
            ...organisationEmail,
            address: organisationEmail?.address!
          } as EmailAddressResponse);

          // add the orgnaisation phone number
          if (phoneNumberRequest.number !== '') {
            await PostOrganisationPhoneNumberAsync(
              newOrganisation.id,
              phoneNumberRequest
            );
          }

          // add organisation territories
          if (organisationTerritories.length > 0) {
            const paramTerritories = organisationTerritories.map((i) => i.iso!);
            await AddOrganisationTerritoriesAsync(
              newOrganisation.id,
              paramTerritories
            );
          }
        }
      }
    } catch (error: any) {
      if (error instanceof BadRequestError) {
        setValidationError(error.errors);
      }
    } finally {
      appStore.hideLoading();
    }

    toastStore.show(
      'Organisations',
      <div>Organisation created.</div>,
      'success'
    );

    navigate('/administration/organisations');
  };

  const updateOrganisation = async () => {
    try {
      appStore.showLoading();

      const updatedData = cloneDeep(organisation);

      if (logo && organisation.logoUrl !== logo.url) {
        const blobResponse = await fetch(logo.url);
        const blobContent = await blobResponse.blob();

        if (logo.url === '') {
          updatedData.organisationLogoMediaId = null;
          updatedData.logoUrl = '';
        } else {
          const newResource = await PostMediaAsync(
            new File([blobContent], logo.fileName)
          );
          updatedData.organisationLogoMediaId = newResource.id;
          updatedData.logoUrl = newResource.mediaUrl;
        }
      }
      // organisations
      await PutOrganisationAsync(orgId, updatedData!);
      // organisation address
      await PutOrganisationAddressAsync(orgId, organisationAddress.id!, {
        ...organisationAddress,
        line1: organisationAddress?.line1!,
        countryIso: organisationAddress?.countryIso!
      });

      // Phone nummber request
      if (phoneNumberRequest?.id! > 0) {
        //patch phone number
        await PutOrganisationPhoneNumberAsync(
          orgId,
          phoneNumberRequest.id!,
          phoneNumberRequest!
        );
      } else if (phoneNumberRequest.number !== '') {
        // Post
        await PostOrganisationPhoneNumberAsync(orgId, phoneNumberRequest);
      }

      // organisation email
      await PutOrganisationEmailAsync(orgId, organisationEmail.id!, {
        ...organisationEmail,
        address: organisationEmail?.address!
      });
      // update organisation territories
      const paramTerritories = organisationTerritories.map((i) => i.iso!) ?? [];
      await PutOrganisationTerritoriesAsync(orgId, paramTerritories);
    } catch (error: any) {
      if (error instanceof BadRequestError) {
        setValidationError(error.errors);
      }
    } finally {
      appStore.hideLoading();
    }

    toastStore.show('Organisations', <div>Organisation saved.</div>, 'success');
    navigate('/administration/organisations');
  };

  const saveOrganisation = async () => {
    const request: OrganisationRequest = {
      name: organisation?.name!,
      line1: organisationAddress?.line1!,
      country: organisationAddress?.countryIso,
      emailAddress: organisationEmail?.address!,
      website: organisation?.website!
    };

    try {
      if (organisation && organisation?.id) {
        const validationResult = validateUpdateRequest(request);

        if (!validationResult.isValid) {
          return;
        } else {
          await updateOrganisation();
          imageHandler(parseInt(id!));
        }
      } else {
        const validationResult = validateUpdateRequest(request);
        if (!validationResult.isValid) {
          return;
        } else {
          createOrganisation();
        }
      }

      // check organisation email has been taken by other organisation
      // only applied for new or when origin != organisationEmail
      if (isNewOrg || originEmail?.address !== organisationEmail?.address) {
        const isAvailable = await PostOrganisationEmailAvailabilityAsync({
          emailAddress: organisationEmail?.address
        } as AvailabilityCheck);
        if (!isAvailable) {
          toastStore.show(
            'Users',
            <div>Organisation email is already registered.</div>,
            'error'
          );
          return;
        }
      }
    } catch (error) {
      console.error('mesages:', error);
    }
  };

  const imageHandler = async (id: number) => {
    GetOrganisationAsync(id).then(async (response) => {
      setOrganisation(response);
      if (response.organisationLogoMediaId) {
        setLogo({ url: response.logoUrl!, fileName: '' });
      }
    });
  };

  const validateUpdateRequest = (request: OrganisationRequest) => {
    const websiteUrlRegExp: RegExp =
      /^(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+\.[a-zA-Z]{2,}|[a-zA-Z0-9-]+\.[a-zA-Z]{2,}\.[a-zA-Z]{2,})(?:\/\S*)?$/;
    const rules: ValidationRules<OrganisationRequest> = {
      name: [required()],
      emailAddress: [
        required(),
        emailAddress('Please enter a valid email address')
      ],
      website: [
        regularExpression(websiteUrlRegExp, 'Please enter a valid website URL')
      ]
    };

    const validationResult = validator.validateObject<OrganisationRequest>(
      request,
      rules
    );
    console.log(`Organisationrequest: ${request}`);
    console.log(`Validation result: ${validationResult.errors}`);
    setValidationError(validationResult.errors);
    return validationResult;
  };

  const [openDeleteSectionDialog, setOpenDeleteSectionDialog] = useState(false);

  const showDeleteSectionDialog = () => {
    setOpenDeleteSectionDialog(true);
  };

  const closeDeleteSectionDialog = () => {
    setOpenDeleteSectionDialog(false);
  };

  const confirmDeleteSectionDialog = async (result: YesNoDialogResult) => {
    if (result === 'yes') {
      const isAvailable = await GetUsersOnOrganisationCheckAsync(orgId);
      if (isAvailable) {
        toastStore.show(
          'Organisation',
          <div>Failed to delete organisation. Delete all members first.</div>,
          'error'
        );
      } else {
        await DeleteOrganisationAsync(orgId);
        navigate('../organisations');
      }
    }

    closeDeleteSectionDialog();
  };

  const onBack = () => {
    navigate('/administration/organisations');
  };

  const handleMenuSelected = (id: number, menu: MenuList) => {
    if (menu.textMenu === 'Edit') {
      setOpenFileDialog(true);
    } else if (menu.textMenu === 'Delete') {
      setOrganisation({ ...organisation, organisationLogoMediaId: null });
      setLogo({ url: '', fileName: '' });
    }
  };

  const onChangeCountryEvent = (event: ComboBoxChangeEvent) => {
    setOrganisationAddress({
      ...organisationAddress,
      country: event.value?.country ?? '',
      countryIso: event.value?.iso ?? ''
    });
  };

  const onChangeTerritoriesEventHandler = (country: any) => {
    if (country) {
      let newOrganisationTerritories = [...organisationTerritories];
      newOrganisationTerritories.push({
        country: country.country ?? '',
        iso: country.iso ?? ''
      });
      setOrganisationTerritories(newOrganisationTerritories);
    }
  };

  const onDeleteFacilitator = (country: CountryResponse) => {
    if (organisationTerritories) {
      const idx = organisationTerritories.indexOf(country);
      if (idx > -1 && organisationTerritories.length > 1) {
        let newData = cloneDeep(organisationTerritories);
        newData.splice(idx, 1);
        setOrganisationTerritories(newData);
      } else if (organisationTerritories.length === 1) {
        setOrganisationTerritories([]);
      }
    }
  };

  const onChangeLanguageEvent = (event: ComboBoxChangeEvent) => {
    setOrganisation({
      ...organisation,
      languages: event.value ?? ''
    });
  };

  const onEditLogo = () => {
    setOpenFileDialog(true);
  };

  const toggleUploadDialog = () => setOpenFileDialog(!openFileDialog);

  const getCountries = async () => {
    // fetch new data
    const contries = await GetCountriesAsync();
    if (contries) {
      // sort alphabetically and store in ref with an intermediate collection for filtering
      countries.current = contries.data.sort((a, b) =>
        a.country!.localeCompare(b.country!)
      );
      setCountryData(countries.current);
      if (!isNewOrg) {
        fetchTerritoriesOrganisation(orgId, countries.current);
      }
    }
  };

  useEffect(
    () => {
      if (!isNewOrg) {
        fetchOrganisation(orgId);
      }
      getCountries();
      generateLogoMenu();
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      setStatus({
        state: organisation?.status === 'Active' ? true : false,
        className: organisation?.status === 'Active' ? 'p-2' : 'p-2 text-muted',
        text: organisation?.status === 'Active' ? 'Active' : 'Disabled'
      });
    },
    // eslint-disable-next-line
    [organisation?.status]
  );

  return (
    <PrivatePage
      breadcrumb={[
        {
          label: 'Administration',
          href: 'administration'
        },
        {
          label: 'Organisations',
          href: 'administration/organisations'
        },
        {
          label: 'Details',
          href: `administration/organisation/${orgId}`
        }
      ]}
      pageTitle={'Organisation details'}>
      <Container>
        <Row className={'mb-3 border-bottom pb-4 align-items-center'}>
          <Col md={6}>
            <h1 className={'m-0'}>
              {isNewOrg ? 'Create organisation' : 'Update organisation'}
            </h1>
          </Col>
          <Col md={6}>
            <div className={'d-flex justify-content-end gap-2'}>
              <Button
                themeColor={'secondary'}
                className={'text-light'}
                onClick={onBack}>
                Back
              </Button>
              <Button
                themeColor={'primary'}
                className={'text-light'}
                onClick={saveOrganisation}>
                {isNewOrg ? 'Save' : 'Update'}
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={2}>
            <div className={'d-flex'}>
              <CoverImage imageUrl={logo.url!} containerStyle={'rounded'}>
                <Button
                  fillMode={'flat'}
                  onClick={onEditLogo}
                  rounded={'full'}></Button>
              </CoverImage>

              <div>
                <PopupMenu
                  id={organisation?.id ?? 0}
                  menus={menuList}
                  onMenuSelected={handleMenuSelected}></PopupMenu>
              </div>
            </div>
          </Col>
          <Col xs={8}>
            <div className={'mb-3'}>Required Information</div>
            <div
              className={'card p-4 border-top-0 border-right-0 border-bottom-0'}
              style={{ borderRight: 'none' }}>
              <Row>
                <Col md={4}>
                  <Label>Organisation name</Label>
                  <FormInput
                    id={'name'}
                    value={organisation?.name}
                    onChange={(e) => {
                      setOrganisation({ ...organisation, name: e.value });
                    }}
                    maxLength={256}
                    error={validationError?.name}
                  />
                </Col>
                <Col md={8}>
                  <Label>Address</Label>
                  <Input
                    value={organisationAddress?.line1}
                    onChange={(e) => {
                      setOrganisationAddress({
                        ...organisationAddress,
                        line1: e.value
                      });
                    }}></Input>
                  {validationError?.line1 && (
                    <Error>{validationError?.line1}</Error>
                  )}
                </Col>
              </Row>
              <Row className={'mt-3'}>
                <Col md={4}>
                  <Label>Country</Label>
                  <CountryComboBox
                    value={{
                      iso: organisationAddress.countryIso,
                      country: organisationAddress.country
                    }}
                    clearButton={false}
                    onChange={(e) => onChangeCountryEvent(e)}
                  />
                  {validationError?.country && (
                    <Error>{validationError?.country}</Error>
                  )}
                </Col>
                <Col md={4}>
                  <Label>Language</Label>
                  <LanguageComboBox
                    value={organisation.languages}
                    clearButton={false}
                    onChange={(e) => onChangeLanguageEvent(e)}
                  />
                </Col>
              </Row>
              <Row className={'mt-3'}>
                <Col md={4}>
                  <Label>Country Code</Label>
                  <CountryCodeCombobox
                    value={phoneNumberRequest?.countryCode ?? ''}
                    columns={[
                      {
                        field: 'code',
                        header: 'Code',
                        width: '100px'
                      },
                      {
                        field: 'countryIso',
                        header: 'Iso',
                        width: '50px'
                      },
                      {
                        field: 'country',
                        header: 'Country'
                      }
                    ]}
                    onChange={(e) => {
                      setPhoneNumberRequest({
                        ...phoneNumberRequest,
                        countryIso: e.value.countryIso,
                        countryCode: e.value.code
                      });
                    }}
                  />
                </Col>
                <Col md={8}>
                  <Label>Phone Number</Label>
                  <StandardInput
                    name={'Phone Number'}
                    value={phoneNumberRequest?.number ?? ''}
                    onChange={(e) => {
                      setPhoneNumberRequest({
                        ...phoneNumberRequest,
                        number: e.value
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row className={'mt-3'}>
                <Col xl={12}>
                  <Label>Primary email address</Label>
                  <Input
                    value={organisationEmail?.address}
                    placeholder={'example@gmail.com'}
                    onChange={(e) => {
                      setOrganisationEmail({
                        ...organisationEmail,
                        address: e.value
                      });
                    }}
                    maxLength={256}></Input>
                  {!orgId
                    ? validationError?.emailAddress && (
                        <Error>{validationError?.emailAddress}</Error>
                      )
                    : validationError?.emailAddress && (
                        <Error>{validationError?.emailAddress}</Error>
                      )}
                </Col>
              </Row>
              <Row className={'mt-3'}>
                <Col xl={12}>
                  <Label>Website</Label>
                  <Input
                    value={organisation?.website}
                    placeholder={'www.catalystglobal.games'}
                    onChange={(e) => {
                      setOrganisation({
                        ...organisation,
                        website: e.value
                      });
                    }}
                    maxLength={256}></Input>
                  {!orgId
                    ? validationError?.website && (
                        <Error>{validationError?.website}</Error>
                      )
                    : validationError?.website && (
                        <Error>{validationError?.website}</Error>
                      )}
                </Col>
              </Row>
              <Row className={'mt-3'}>
                <Col md={12}>
                  <Label>Territory</Label>
                  <Autocomplete<CountryResponse>
                    className={'d-flex justify-content-start w-100'}
                    items={countryData ?? []}
                    textField="country"
                    onChange={onChangeTerritoriesEventHandler}></Autocomplete>
                </Col>
                <Col md="12" className={'mt-2'}>
                  <ul className="list-group list-group-flush">
                    {organisationTerritories &&
                      organisationTerritories
                        .filter((x) => !x.isDeleted)
                        .map((country, index) => {
                          return (
                            <li
                              key={index}
                              className="list-group-item d-flex justify-content-between">
                              <span className={'text-primary'}>
                                {country?.country ?? ''}
                              </span>
                              <span
                                className={
                                  'material-symbols-outlined text-danger'
                                }
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  onDeleteFacilitator(country);
                                }}>
                                close
                              </span>
                            </li>
                          );
                        })}
                  </ul>
                </Col>
              </Row>
            </div>
            <div className={'mt-4'}>Financial details</div>
            <div className={'card p-4'}>
              <Row>
                <Col md={4}>
                  <Label>Tax Identification</Label>
                  <Input
                    value={organisation?.taxId}
                    onChange={(e) => {
                      setOrganisation({ ...organisation, taxId: e.value });
                    }}></Input>
                </Col>
                <Col md={4}>
                  <Label>Trade Identification</Label>
                  <Input
                    value={organisation?.tradeId}
                    onChange={(e) => {
                      setOrganisation({ ...organisation, tradeId: e.value });
                    }}></Input>
                </Col>
              </Row>
            </div>
            <Row className={'d-flex align-items-center mt-3'}>
              <Col md={4}>
                <div className={'mt-3'}>
                  <Label>Status</Label>
                </div>
                <div className={'text-primary'}>
                  <Switch
                    checked={status.state}
                    value={status.state}
                    onChange={(e) => {
                      setOrganisation({
                        ...organisation,
                        status: e.value === true ? 'Active' : 'Disabled'
                      });
                    }}
                  />
                  <span className={status.className}>{status.text}</span>
                </div>
              </Col>
            </Row>

            <hr />
            <Row className={'mt-3 align-items-center'}>
              <Col>
                <span className={'text-muted'}>
                  Date Created: &nbsp;
                  {organisation?.createdDateUtc &&
                    formatDateToDDMMYYYY(organisation?.createdDateUtc, true)}
                </span>
              </Col>
              <Col className={'d-flex justify-content-end'}>
                {!isNewOrg && (
                  <span
                    className={'btn btn-link'}
                    onClick={showDeleteSectionDialog}>
                    Remove
                  </span>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      {openDeleteSectionDialog && (
        <YesNoDialog
          title={'Remove Organisation'}
          onConfirm={confirmDeleteSectionDialog}
          onClose={closeDeleteSectionDialog}>
          Are you sure you want to remove this organisation?
        </YesNoDialog>
      )}
      {openFileDialog && (
        <ResourceWindow
          toggleDialog={toggleUploadDialog}
          onSubmit={handleFilesSelected}
          acceptedExtension={'image/*'}
          imageUrl={logo.url}
        />
      )}
    </PrivatePage>
  );
}
