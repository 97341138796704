import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const PageNotFound = () => {
  return (
    <Container>
      <Row>
        <Col>
          <h1>Game over, man! Game over!</h1>
          <p className="lead">The requested page could not be found!</p>
          <p>
            When the going gets tough—like, say, you're stranded on an alien
            planet and you're facing sudden death—you can either hold it
            together, or you can lose your cool and start shouting helplessly...
            We'd recommend <Link to={'/'}>going back to the beginning</Link>.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default PageNotFound;
