import { AlgorithmEditor } from '../../algorithm-editor';
import { Algorithm, AlgorithmStep } from '../../../types/algorithm';
import { Col, Row } from 'react-bootstrap';

interface WorldMapEventProps {
  title?: String;
  description?: String;
  algorithm?: Algorithm;
  onChange?: (algorithm: Algorithm) => void;
}

const EventAlgorithm = ({
  title,
  description,
  algorithm,
  onChange
}: WorldMapEventProps) => {
  const handleOnChange = (steps: Array<AlgorithmStep>) => {
    let gameSteps: Algorithm = { ...algorithm };
    gameSteps.steps = steps;

    if (onChange) {
      onChange(gameSteps);
    }
  };

  return (
    <>
      <Row>
        <Col>
          <div className={'p-2'}>
            <small>
              <code>{title}</code> {description}
            </small>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className={'p-2'}>
            <AlgorithmEditor
              steps={algorithm?.steps ?? []}
              onChange={handleOnChange}
              action={title}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};
export default EventAlgorithm;
