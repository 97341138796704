import { useEffect } from 'react';
import FeedbackGrid from '../../features/feedback-grid';
import { PrivatePage } from '../../components/private-page';
import { ctGameDefault, ctGameTitle } from '../../constants/text';

export default function Feedback() {
  useEffect(() => {
    document.title = ctGameTitle + ' - Feedback';
    return () => {
      document.title = ctGameDefault;
    };
  }, []);

  return (
    <PrivatePage
      breadcrumb={[
        { label: 'Dashboard', href: '../dashboard' },
        { label: 'My Library', href: '../mylibrary/feedback' },
        { label: 'Feedback', href: '../mylibrary/feedback' }
      ]}
      pageTitle={'My Library'}>
      <FeedbackGrid />
    </PrivatePage>
  );
}
