import { useEffect } from 'react';
import { PrivatePage } from '../components/private-page';
import { ctGameDefault, ctGameTitle } from '../constants/text';
import DashboardContent from '../features/dashboard-content';

export default function Dashboard() {
  useEffect(() => {
    document.title = ctGameTitle + ' - Dashboard';
    return () => {
      document.title = ctGameDefault;
    };
  }, []);
  return (
    <PrivatePage
      breadcrumb={[
        {
          label: 'Dashboard',
          href: 'dashboard'
        }
      ]}
      pageTitle={'Welcome to Catalyst Games'}>
      <DashboardContent />
    </PrivatePage>
  );
}
