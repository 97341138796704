import React from 'react';
import OperationSelector from './operation-selector';
import {
  AlgorithmControlStructure,
  AlgorithmStep
} from '../../types/algorithm';
import Operations from '../../constants/operations';
import { isEqual } from 'lodash';
import { IdentifierSelector } from './identifier-selector';
import SelectionItem from '../../types/selection-item';

export interface StepEditorState {
  step?: AlgorithmStep | AlgorithmControlStructure;
  onChange?: (step: AlgorithmStep) => void;
  onDelete?: (step: AlgorithmStep) => void;
  onMoveUp?: (step: AlgorithmStep) => void;
  onMoveDown?: (step: AlgorithmStep) => void;
  onCopy?: (step: AlgorithmStep) => void;
  operationList?: SelectionItem[];
}

export class StepEditor extends React.Component<
  AlgorithmStep,
  StepEditorState
> {
  constructor(props: AlgorithmStep) {
    super(props);
    this.state = {
      step: props,
      onChange: this.handleDataChange.bind(this),
      onDelete: this.handleDelete.bind(this),
      onMoveUp: this.handleMoveUp.bind(this),
      onMoveDown: this.handleMoveDown.bind(this),
      onCopy: this.handleCopy.bind(this),
      operationList: Operations
    };
  }

  handleDataChange(step: AlgorithmStep) {
    if (this.props.onChange) {
      this.props.onChange(step);
    }
  }

  handleDelete(step: AlgorithmStep) {
    if (this.props.onDelete) {
      this.props.onDelete(step);
    }
  }

  handleMoveUp(step: AlgorithmStep) {
    if (this.props.onMoveUp) {
      this.props.onMoveUp(step);
    }
  }

  handleMoveDown(step: AlgorithmStep) {
    if (this.props.onMoveDown) {
      this.props.onMoveDown(step);
    }
  }

  handleCopy(step: AlgorithmStep) {
    if (this.props.onCopy) {
      this.props.onCopy(step);
    }
  }

  componentDidMount(): void {
    let operationList = Operations;

    if (this.props.action) {
      operationList = Operations.filter(
        (item) =>
          !item.availableOnActions ||
          item.availableOnActions.includes(this.props.action!)
      );
    }

    this.setState({
      ...this.state,
      step: this.props,
      operationList
    });
  }

  componentDidUpdate(prevProps: Readonly<AlgorithmStep>): void {
    if (!isEqual(prevProps, this.props)) {
      this.setState({
        ...this.state,
        step: this.props
      });
    }
  }

  render() {
    const gameStep = this.props;
    const {
      identifier,
      operation,
      argumentAssetId,
      argumentQuantity,
      useFallbackValue
    } = this.props;

    const handleIdentifierChange = (identifier: string) => {
      let stp: AlgorithmStep | AlgorithmControlStructure = { ...gameStep } as
        | AlgorithmStep
        | AlgorithmControlStructure;

      stp.identifier = identifier;
      stp.operation = '';
      stp.argumentAssetId = '';
      stp.argumentQuantity = 0;

      if (this.handleDataChange) {
        this.handleDataChange(stp);
      }
    };

    const handleChangeOperation = (operation: string) => {
      let stp: AlgorithmStep | AlgorithmControlStructure = { ...gameStep } as
        | AlgorithmStep
        | AlgorithmControlStructure;

      stp.operation = operation;
      stp.argumentAssetId = '';
      stp.argumentQuantity = 0;

      if (stp.operation === 'giveScore(score)') {
        stp.useFallbackValue = true;
      }

      if (this.handleDataChange) {
        this.handleDataChange(stp);
      }
    };

    const handleChangeArgument = (argument: string | string[] | undefined) => {
      let stp: AlgorithmStep | AlgorithmControlStructure = { ...gameStep } as
        | AlgorithmStep
        | AlgorithmControlStructure;
      stp.argumentAssetId = argument;
      stp.argumentQuantity = argumentQuantity ?? 0;

      if (this.handleDataChange) {
        this.handleDataChange(stp);
      }
    };

    const handleChangeQuantity = (quantity: number) => {
      let stp: AlgorithmStep | AlgorithmControlStructure = { ...gameStep } as
        | AlgorithmStep
        | AlgorithmControlStructure;

      stp.argumentQuantity = quantity;

      if (this.handleDataChange) {
        this.handleDataChange(stp);
      }
    };

    const handleChangeCheckbox = (isChecked: boolean) => {
      let stp: AlgorithmStep | AlgorithmControlStructure = { ...gameStep } as
        | AlgorithmStep
        | AlgorithmControlStructure;

      stp.useFallbackValue = isChecked;

      if (this.handleDataChange) {
        this.handleDataChange(stp);
      }
    };

    const handleDeleteStep = () => {
      if (this.handleDelete) {
        this.handleDelete(gameStep);
      }
    };

    const handleMoveUp = () => {
      if (this.handleMoveUp) {
        this.handleMoveUp(gameStep);
      }
    };

    const handleMoveDown = () => {
      if (this.handleMoveDown) {
        this.handleMoveDown(gameStep);
      }
    };

    const handleCopy = () => {
      if (this.handleCopy) {
        this.handleCopy(gameStep);
      }
    };

    return (
      <>
        <div className={'step-editor container-fluid'}>
          <div className={'row mt-2'}>
            <div className={'col'}>
              <IdentifierSelector
                identifier={identifier}
                onChange={handleIdentifierChange}
                isOnConditional={false}
              />
              <OperationSelector
                operationsList={this.state.operationList!}
                operation={operation}
                argumentAssetId={argumentAssetId}
                argumentQuantity={argumentQuantity}
                useFallbackValue={useFallbackValue}
                onChangeOperation={handleChangeOperation}
                onChangeArgument={handleChangeArgument}
                onChangeQuantity={handleChangeQuantity}
                onChangeCheckbox={handleChangeCheckbox}
              />
            </div>
            <div className={'col-auto'}>
              <div
                className={
                  'material-symbols-outlined text-primary me-1 cursor-pointer'
                }
                style={{ fontSize: '1rem', paddingTop: '0.25rem' }}
                title={'Move Down'}
                onClick={handleMoveDown}>
                move_down
              </div>
              <div
                className={
                  'material-symbols-outlined text-primary me-1 cursor-pointer'
                }
                style={{ fontSize: '1rem', paddingTop: '0.25rem' }}
                title={'Move Up'}
                onClick={handleMoveUp}>
                move_up
              </div>
              <div
                className={
                  'material-symbols-outlined me-1 text-primary cursor-pointer'
                }
                style={{ fontSize: '1rem', paddingTop: '0.25rem' }}
                title={'Copy'}
                onClick={handleCopy}>
                content_copy
              </div>
              <div
                className={
                  'material-symbols-outlined me-1 text-danger cursor-pointer'
                }
                style={{ fontSize: '1rem', paddingTop: '0.25rem' }}
                title={'Delete'}
                onClick={handleDeleteStep}>
                delete
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
