import React, { useContext, useEffect, useState } from 'react';

import { ArgumentSelector } from './argument-selector';
import SelectionItem from '../../types/selection-item';
import {
  GetZones,
  GetTasks,
  GetTimers,
  GetAreas,
  GetTitles,
  GetItems,
  GetTasksHasBoundary
} from '../../utils/game-document/assets/index';
import { GameDocument } from '../../types/game-document';
import { MultipleArgumentSelector } from './multiple-argument-selector';
import { GameDocumentContext } from '../../contexts/game-document';
import DropdownSelector from './dropdown-selector';
import { Checkbox } from '@progress/kendo-react-inputs';

export interface OperationSelectorProps {
  gameDocument?: GameDocument;
  operation?: string;
  argumentQuantity?: number;
  argumentAssetId?: string | string[] | undefined;
  useFallbackValue?: boolean;
  operationsList: SelectionItem[];
  onChangeOperation?: (operation: string) => void;
  onChangeArgument?: (argument: string | string[] | undefined) => void;
  onChangeQuantity?: (quantity: number) => void;
  onChangeCheckbox?: (isChecked: boolean) => void;
}

function GetArgumentList(
  listName: string | undefined,
  gameDocument: GameDocument | undefined
) {
  switch (listName) {
    case 'zone':
      return GetZones(gameDocument) as SelectionItem[];
    case 'task':
      return GetTasks(gameDocument) as SelectionItem[];
    case 'task-has-boundary':
      return GetTasksHasBoundary(gameDocument) as SelectionItem[];
    case 'area':
      return GetAreas(gameDocument) as SelectionItem[];
    case 'item':
      return GetItems(gameDocument) as SelectionItem[];
    case 'title':
      return GetTitles(gameDocument) as SelectionItem[];
    case 'timer':
      return GetTimers(gameDocument) as SelectionItem[];
    default:
      return undefined;
  }
}

const OperationSelector = ({ ...props }: OperationSelectorProps) => {
  const [state, setState] = useContext(GameDocumentContext);
  const [selectedOperation, setSelectedOperation] = useState<SelectionItem>();
  const [selectedArgument, setSelectedArgument] = useState<
    SelectionItem | SelectionItem[] | undefined
  >();

  useEffect(() => {
    setSelectedOperation(
      props.operationsList.find(
        (i) => i.name === props.operation
      ) as SelectionItem
    );
  }, [props.operation]);

  useEffect(() => {
    if (selectedOperation) {
      if (selectedOperation?.isMultipleArgument) {
        setSelectedArgument(
          GetArgumentList(selectedOperation?.list, state?.gameDocument)?.filter(
            (i) => props.argumentAssetId?.includes(i.id)
          ) as SelectionItem[]
        );
      } else {
        setSelectedArgument(
          GetArgumentList(selectedOperation?.list, state?.gameDocument)?.find(
            (i) => props.argumentAssetId?.includes(i.id)
          ) as SelectionItem
        );
      }
    }
  }, [props.argumentAssetId, selectedOperation]);

  let className = `operation--${selectedOperation?.name ?? 'select'}`;

  const onClick = (item: SelectionItem) => {
    if (props.onChangeOperation) {
      props.onChangeOperation(item.name);
    }
  };

  const onChange = (value: number) => {
    if (props.onChangeQuantity) {
      props.onChangeQuantity(value);
    }
  };

  const onChangeCheckbox = (isChecked: boolean) => {
    if (props.onChangeCheckbox) {
      props.onChangeCheckbox(isChecked);
    }
  };

  const onArgumentSelected = (
    selectedArgument: SelectionItem | SelectionItem[]
  ) => {
    if (props.onChangeArgument) {
      if (selectedOperation?.isMultipleArgument) {
        const ids = (selectedArgument as SelectionItem[]).map((x) => x.id);
        props.onChangeArgument(ids);
      }

      if (!selectedOperation?.isMultipleArgument) {
        props.onChangeArgument((selectedArgument as SelectionItem).id);
      }
    }
  };

  const argumentList = GetArgumentList(
    selectedOperation?.list,
    state?.gameDocument
  );

  React.useEffect(() => {
    if (props.operationsList.length > 0) {
      props.operationsList.sort((a, b) => {
        if (a.description && b.description) {
          if (a.description < b.description) return -1;
          if (a.description > b.description) return 1;
        }
        return 0;
      });
    }
  }, [props.operationsList]);

  return (
    <>
      <DropdownSelector<SelectionItem, 'description'>
        fieldName="description"
        onClick={(e) => onClick(e)}
        items={props.operationsList}
        additionalClassname={className}
        selected={selectedOperation?.description}
      />

      {selectedOperation?.isMultipleArgument && (
        <>
          <code className={'mr-2'}>
            {`Select (${(selectedArgument as SelectionItem[])?.length ?? 0})`}
          </code>
        </>
      )}

      {argumentList &&
        !selectedOperation?.isMultipleArgument &&
        !selectedOperation?.noActionShowed && (
          <ArgumentSelector
            argumentList={argumentList}
            onArgumentSelected={onArgumentSelected}
            selectedArgument={selectedArgument as SelectionItem}
          />
        )}

      {selectedOperation?.showQuantity && (
        <input
          className={'form-control form-event-control-inline'}
          value={props.argumentQuantity}
          type={'number'}
          min={0}
          disabled={
            props.operation === 'giveScore(score)' && props.useFallbackValue
          }
          onChange={(e) => onChange(parseInt(e.target.value))}
        />
      )}

      {selectedOperation?.checkboxAction && (
        <div style={{ display: 'inline-flex', marginLeft: '1rem' }}>
          <Checkbox
            label={selectedOperation?.checkboxAction.label}
            value={props.useFallbackValue}
            onChange={(e) => onChangeCheckbox(e.value)}
            name={selectedOperation?.name}
          />
        </div>
      )}

      {selectedArgument && (
        <div
          className={`selector__description text-muted d-flex mt-1 flex-fill w-100`}>
          <div
            className={'material-symbols-outlined me-1'}
            style={{
              opacity: '0.25',
              fontSize: '1rem',
              paddingTop: '0.25rem'
            }}>
            lightbulb
          </div>
          <div>
            {selectedOperation?.isMultipleArgument &&
              `The player receives ${
                selectedOperation?.name === 'randomTask' ? 'any' : 'sequential'
              } ${
                isNaN(props.argumentQuantity!) || !props.argumentQuantity
                  ? 0
                  : props.argumentQuantity
              } tasks from the list of specified tasks.`}

            {!selectedOperation?.isMultipleArgument &&
              `The '${(selectedArgument as SelectionItem)?.name}' ${
                selectedOperation?.list
              } ${(selectedArgument as SelectionItem)?.description}`}
          </div>
        </div>
      )}

      {selectedOperation?.isMultipleArgument && argumentList && (
        <div className={'mt-3'}>
          <MultipleArgumentSelector
            minimumSelected={2}
            title={'Task selector'}
            placeholder={'Select tasks'}
            argumentList={argumentList}
            onArgumentSelected={onArgumentSelected}
            selectedArgument={selectedArgument as SelectionItem[]}
          />
        </div>
      )}
      {selectedOperation?.instruction && (
        <div
          className={`selector__description text-muted d-flex mt-1 flex-fill w-100`}>
          {selectedOperation?.instruction}
        </div>
      )}
    </>
  );
};

export default OperationSelector;
