import merge from 'lodash.merge';
import { Feedback, FeedbackApproval } from '../types/feedback';
import {
  DeleteAsync,
  GetAllAsync,
  GetAsync,
  PostAsync,
  PatchAsync
} from './api';
import {
  GetResourceEntity,
  UpdateResourceAsync
} from '../features/feedback/feedback-document';

const FeedbackEndpoint = 'feedback';
const PublishedFeedbackEndpoint = 'event/feedback';

/**
 * Get feedback pending approval list
 * @param params
 * @returns
 */
export const getFeedbackPendingApprovalList = (params?: string) =>
  GetAllAsync<Feedback>(`admin/${FeedbackEndpoint}/?${params}`);

/**
*
* @param params Get published feedback list
* @returns
*/
export const getPublishedFeedback = (params?: string) =>
  GetAllAsync<Feedback>(`${PublishedFeedbackEndpoint}/?${params}`);

/**
 *
 * @param params Get feedback list
 * @returns
 */
export const getFeedback = (params?: string) =>
  GetAllAsync<Feedback>(`${FeedbackEndpoint}/?${params}`);

export const getGlobalFeedback = (params?: string) =>
  GetAllAsync<Feedback>(`library/${FeedbackEndpoint}/?${params}`);

/**
 *
 * @param id Get feedback by id
 * @returns
 */
export const getFeedbackById = (id: number) =>
  GetAsync<Feedback>(`${FeedbackEndpoint}/${id}`);

/**
 *
 * @param id Get global feedback by id
 * @returns
 */
export const getGlobalFeedbackById = (id: number) =>
  GetAsync<Feedback>(`library/${FeedbackEndpoint}/${id}`);

/**
 *
 * @param feedback - the feedback to add
 * @returns
 */
export const addFeedbackAsync = async (feedback: Feedback) =>
  PostAsync<Feedback>(`${FeedbackEndpoint}`, feedback);

/**
 *
 * @param id - the ID of the entity to update [PATCH]
 * @param feedback - the User to update [PATCH]
 * @returns
 */
export const updateFeedbackAsync = async (id: number, feedback: Feedback) =>
  PatchAsync<Feedback>(`${FeedbackEndpoint}/${id}`, feedback);

/**
 * Deletes a feedback by id.
 * @param id - the ID of the Feedback to DELETE
 * @returns the deleted Feedback object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/DELETE
 */
export const DeleteFeedbackAsync = async (id: number) =>
  DeleteAsync<Feedback>(`${FeedbackEndpoint}/${id}`);

/**
 * Duplicate a feedback by id.
 * @param id - the ID of the Feedback to Duplicate
 * @returns the duplicated Feedback object.
 */
export const DuplicateFeedbackAsync = async (id: number) =>
  PostAsync<boolean>(`${FeedbackEndpoint}/duplicate/${id}`, {} as any);

/**
 *
 * @param id Get resource documents by assessment id (for language tab in main portal assessment tab)
 * @returns
 */
export const getDocumentsByFeedbackIdAsync = (id: number) =>
  GetAsync<Feedback>(`${FeedbackEndpoint}/${id}/documents/latest/content`);

/**
 *
 * @param id Get resource documents by feedback id (for language tab in main portal feedback tab)
 * @returns
 */
export const getDocumentLatestAsync = (id: number) =>
  GetAsync<Feedback>(`${FeedbackEndpoint}/${id}/documents/latest`);

/**
 *
 * @param id Get resource global documents by feedback id (for language tab in main portal feedback tab)
 * @returns
 */
export const getGlobalDocumentLatestAsync = (id: number) =>
  GetAsync<Feedback>(`library/${FeedbackEndpoint}/${id}/documents/latest`);

/**
 *
 * @param id Get resource global contents by assessment id (for language tab in main portal assessment tab)
 * @returns
 */
export const getGlobalContentsByFeedbackIdAsync = (
  id: number,
  documentId: number
) =>
  GetAsync<Feedback>(
    `library/${FeedbackEndpoint}/${id}/document/${documentId}/contents`
  );

/**
 *
 * @param id - the feedback id of the entity to update [PATCH] (for language tab in main portal feedback tab)
 * @param feedback - the resource documents to update [PATCH] (for language tab in main portal feedback tab)
 * @returns
 */
export const updateDocumentsByFeedbackIdAsync = async (
  id: number,
  feedback: Feedback
) => PatchAsync<Feedback>(`${FeedbackEndpoint}/${id}/documents`, feedback);

/**
 * Publish feedback
 * @param id
 * @param documentId
 * @param assessment
 * @returns
 */
export const publishFeedbackAsync = async (
  id: number,
  documentId: number,
  feedback: FeedbackApproval
) =>
  PatchAsync<Feedback>(
    `${FeedbackEndpoint}/${id}/documents/${documentId}`,
    feedback
  );

/**
 *
 * @param id Get language list from resources by feedback id (for language tab in main portal feedback tab)
 * @returns
 */
export const getFeedbackLanguagesByFeedbackIdAsync = (feedbackId: number) =>
  GetAsync<Feedback[]>(`${FeedbackEndpoint}/${feedbackId}/documents/languages`);

/**
 * Post Feedback Documents
 * @param feedback - the feedback data
 */
export const addFeedbackDocuments = async (feedback: Feedback) => {
  const param = {
    status: feedback.status,
    version: feedback.version,
    filename: feedback.name
  };
  return PostAsync<boolean>(
    `${FeedbackEndpoint}/${feedback.id}/documents`,
    param as any
  );
};

/**
 * Post Feedback Documents Content
 * @param feedback - the feedback data
 * @param documentId - the feedback document data id
 */
export const addFeedbackDocumentContent = async (
  feedback: Feedback,
  documentId: number
) => {
  PostAsync<boolean>(
    `${FeedbackEndpoint}/${feedback.id}/document/${documentId}/contents`,
    '' as any
  );
};

/**
 * Copy Global Feedback into my library feedback
 * @param feedback - the feedback
 */
export const copyGlobalFeedbackToLibrary = async (feedback: Feedback) => {
  const feedbackEntity = { ...feedback };

  feedbackEntity.id = 0;
  feedbackEntity.name = 'COPY_' + feedback.name;
  feedbackEntity.status = 'New';

  // add new feedback in my library
  const newFeedback = await addFeedbackAsync(feedbackEntity);

  // merge newly-added feedback with inserted feedback documents / resources
  let feedbackComplete = merge(feedback, newFeedback);

  // to update name, title and description from the resource of the copied feedback to become "COPY_[copied_feedback]"
  let updateInfo: string[] = [
    feedbackComplete.nameResId!,
    feedbackComplete.titleResId!,
    feedbackComplete.descriptionResId!
  ];

  // update its resource
  for (let i = 0; i < updateInfo.length; i++) {
    let resource = GetResourceEntity(feedbackComplete, updateInfo[i]);
    if (resource) {
      resource.value = 'COPY_' + resource.value;
      feedbackComplete = await UpdateResourceAsync(
        feedbackComplete,
        resource?.id,
        resource
      );
    }
  }

  // update its resource packs
  // currently left empty here, just in case if each of the packs needed to be added the "COPY_" text for its name, title and description, just override this comment

  // update feedback with its updated documents / resources
  await updateDocumentsByFeedbackIdAsync(
    feedbackComplete.id,
    feedbackComplete!
  );
};
