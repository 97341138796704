import { useEffect, useState } from 'react';
import { PrivatePage } from '../../components/private-page';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Label } from '@progress/kendo-react-labels';
import { useNavigate, useParams } from 'react-router-dom';
import { toastStore } from '../../stores/toast-store';
import YesNoDialog from '../../components/dialogs/yes-no-dialog';
import { Summary } from '../../components/overview';
import Hero from '../../components/hero';
import { formatDateToDDMMYYYY } from '../../utils/date';
import { GetPendingGameAsyncById } from '../../services/games';
import { GameResponse } from '../../types/responses/game-response';
import {
  GetPendingGameDocumentContentAsync,
  GetPendingGameDocumentLatestAsync
} from '../../services/json-document';
import { GameDocument, MediaOverview } from '../../types/game-document';
import { GameDocumentVersionResponse } from '../../types/responses/game-document-version-response';

import {
  ApprovalDocumentAsync,
  GetResourceValue
} from '../../utils/game-document';
import { FileUrl } from '../game-designer/game-overview';
import Video from '../../components/video';
import { YesNoDialogResult } from '../../types/dialog-result';
import { appStore } from '../../stores/app-store';
export function LibrarApprovalPage() {
  const { id } = useParams();
  const libApprovalID = parseInt(id!);
  const [game, setGames] = useState<GameResponse>();
  const [gameDocumentContent, setGameDocumentContent] =
    useState<GameDocument>();
  const [gameDocumentLatest, setGameDocumentLatest] =
    useState<GameDocumentVersionResponse>();
  const [openApproveSectionDialog, setopenApproveSectionDialog] =
    useState(false);
  const [approvalStatus, setApprovalStatus] = useState<string>(''); //'Approve' | 'Reject'
  const [confirmMessage, setConfirmMessage] = useState<string>();
  const [confirmTitle, setConfirmTitle] = useState<string>();
  const [previewItem, setPreviewItem] = useState<string>('');
  const [previewItemType, setPreviewItemType] = useState<string>('');

  const navigate = useNavigate();

  const showApproveSectionDialog = (approval: 'Approved' | 'Rejected') => {
    setApprovalStatus(approval);
    if (approval === 'Approved') {
      setConfirmTitle('Approve game');
      setConfirmMessage('Are you sure you want to approve this game?');
    } else {
      setConfirmTitle('Reject game');

      setConfirmMessage('Are you sure you want to reject this game?');
    }
    setopenApproveSectionDialog(true);
  };

  const closeApproveSectionDialog = () => {
    setopenApproveSectionDialog(false);
  };

  const confirmApproveSectionDialog = async (result: YesNoDialogResult) => {
    if (result === 'yes') {
      try {
        await ApprovalDocumentAsync(parseInt(id!), approvalStatus as any, true);
        navigate('../globallibraries/pending');
      } catch (e: any) {
        toastStore.show(
          'Library approval',
          'Failed to approve template.',
          'error'
        );
      }
    }
    closeApproveSectionDialog();
  };

  useEffect(() => {
    fetchGame(libApprovalID);
  }, [libApprovalID]);

  const fetchGame = async (gameId: number) => {
    try {
      appStore.showLoading();
      const game = await GetPendingGameAsyncById(gameId);
      const gameDocumentLatest = await GetPendingGameDocumentLatestAsync(
        gameId
      );
      const gameDocumentContent = await GetPendingGameDocumentContentAsync(
        gameId,
        gameDocumentLatest.id!
      );
      setGames(game);
      setGameDocumentLatest(gameDocumentLatest);
      setGameDocumentContent(gameDocumentContent);
    } catch (ex) {
      console.error(ex);
    } finally {
      appStore.hideLoading();
    }
  };

  const onClickMedia = async (url: FileUrl) => {
    if (url && url.url && url.type) {
      setPreviewItem(url.url.replace('/thumbnails', ''));

      setPreviewItemType(url.type);
      if (url.type.includes('video')) {
        setPreviewItem(url.url);
      }
    }
  };
  return (
    <PrivatePage
      breadcrumb={[
        {
          label: 'Dashboard',
          href: 'dashboard'
        },
        {
          label: 'Administration',
          href: 'Administration'
        },
        {
          label: 'Pending approval',
          href: 'administration/globallibraries/pending'
        },
        {
          label: 'Pending approval details',
          href: 'administration/libraryapproval/' + id
        }
      ]}
      pageTitle={'Administration'}>
      <h2>Pending approval details</h2>
      <hr />
      <Container>
        <div className={'d-flex'}>
          <Row>
            <Col xs={9}>
              <div className={'border-end'}>
                <Row>
                  <Col xs={12} className={'mb-3'}>
                    <Hero
                      src={
                        gameDocumentContent &&
                        GetResourceValue(
                          gameDocumentContent!,
                          gameDocumentContent?.overview?.headerResId!
                        )
                      }
                      editable={false}
                    />
                    <div
                      className={'d-flex h-4 align-items-center'}
                      style={{
                        background: 'rgb(0, 58, 100)'
                      }}>
                      <div>
                        <h4 className={'text-light fw-bold ml-23 mb-0'}>
                          {game?.name}
                        </h4>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} className={'mb-5 w-100'}>
                    <Col md={3}>
                      <div className={'d-flex flex-column'}>
                        <div
                          className={
                            'round-card rounded-full h-9 w-9 mr-12 bg-white'
                          }
                          style={{
                            marginTop: '-130px',
                            backgroundImage: `url("${
                              gameDocumentContent &&
                              GetResourceValue(
                                gameDocumentContent!,
                                gameDocumentContent?.overview?.logoResId!
                              )
                            }")`
                          }}></div>
                      </div>
                    </Col>
                  </Col>
                  <Col xs={12} className={'mb-5 pr-3'}>
                    <Label className={'fw-bold'}>Short Description</Label>
                    <p>{game?.shortDescription}</p>
                  </Col>
                  <Col xs={12} className={'mb-5 pr-3'}>
                    <Label className={'fw-bold'}>Long Description</Label>
                    <p>
                      {gameDocumentContent &&
                        GetResourceValue(
                          gameDocumentContent!,
                          gameDocumentContent?.overview?.longDescriptionResId!
                        )}
                    </p>
                  </Col>
                  <Col xs={12}>
                    <Video
                      url={previewItem}
                      loop={true}
                      type={previewItemType}
                    />
                  </Col>
                  <Col xs={12} className={'mb-5 pr-3'}>
                    <Label className={'fw-bold'}>Media</Label>
                    <div className={'thumb-nails d-flex flex-wrap'}>
                      {gameDocumentContent?.overview?.medias?.length! > 0 &&
                        gameDocumentContent?.overview?.medias?.map(
                          (file: MediaOverview, index: number) => (
                            <div
                              key={index}
                              className={
                                'w-8 h-8 bg-light position-relative rounded d-flex overflow-hidden flex-column justify-content-center align-items-center'
                              }
                              onClick={() =>
                                onClickMedia({
                                  url: gameDocumentContent?.resources.find(
                                    (f) => f.id === file.value
                                  )?.value as string,
                                  type: file.type
                                })
                              }>
                              {file && file.type === 'video' ? (
                                <>
                                  <video className={'rounded'}>
                                    <source
                                      src={
                                        gameDocumentContent &&
                                        GetResourceValue(
                                          gameDocumentContent!,
                                          file.value
                                        )
                                      }
                                      type={'video/mp4'}
                                    />
                                  </video>
                                  <div
                                    className={
                                      'mask position-absolute top-0 end-0 h-full w-full d-flex align-items-center justify-content-center'
                                    }
                                    style={{
                                      backgroundImage: `url("${
                                        gameDocumentContent?.resources.find(
                                          (f) => f.id === file.thumbnailValue
                                        )?.value ?? ''
                                      }")`,
                                      backgroundPosition: 'center'
                                    }}>
                                    <button className={'btn text-light'}>
                                      <span
                                        className={'material-symbols-outlined'}>
                                        play_arrow
                                      </span>
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <img
                                    src={
                                      gameDocumentContent &&
                                      GetResourceValue(
                                        gameDocumentContent!,
                                        file.value
                                      )
                                    }
                                    className={'rounded'}
                                    alt={''}
                                  />
                                </>
                              )}
                            </div>
                          )
                        )}
                    </div>
                  </Col>
                  <Col xs={12} className={'mb-5'}>
                    <Row>
                      <Col xs={4}>
                        <Label className={'fw-bold'}>
                          Recommended number of players
                        </Label>
                        <p className={'text-success fw-bold'}>
                          {gameDocumentContent &&
                            GetResourceValue(
                              gameDocumentContent!,
                              gameDocumentContent?.overview
                                ?.recommendedNumberOfPlayersResId!
                            )}
                        </p>
                      </Col>
                      <Col xs={4}>
                        <Label className={'fw-bold'}>
                          Recommended duration
                        </Label>
                        <p className={'text-success fw-bold'}>
                          {gameDocumentContent &&
                            GetResourceValue(
                              gameDocumentContent!,
                              gameDocumentContent?.overview
                                ?.recommendedDurationResId!
                            )}
                        </p>
                      </Col>
                      <Col xs={4}>
                        <Label className={'fw-bold'}>Players per Team</Label>
                        <p className={'text-success fw-bold'}>
                          {gameDocumentContent?.overview?.playersPerTeam}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} className={'mb-5'}>
                    <Label className={'fw-bold'}>Localisations</Label>
                    <p className={'text-success fw-bold'}>{game?.languages}</p>
                  </Col>
                  <Col xs={12} className={'mb-5 pr-12'}>
                    <Label className={'fw-bold'}>Learning Outcome</Label>
                    <p>
                      {gameDocumentContent &&
                        GetResourceValue(
                          gameDocumentContent!,
                          gameDocumentContent?.overview?.learningOutcomeResId!
                        )}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className={'mb-5'}>
                    <hr />
                  </Col>
                </Row>
                {gameDocumentLatest?.status === 'Pending' && (
                  <Row>
                    <Col md={6}>
                      <Button
                        variant={'danger'}
                        className={'w-100'}
                        onClick={() => showApproveSectionDialog('Rejected')}>
                        Reject
                      </Button>
                    </Col>
                    <Col md={6}>
                      <Button
                        variant={'success'}
                        className={'w-100'}
                        onClick={() => showApproveSectionDialog('Approved')}>
                        Approve
                      </Button>
                    </Col>
                  </Row>
                )}
              </div>
            </Col>
            <Col xs={3}>
              <Summary
                author={gameDocumentLatest?.createdBy}
                createdDate={
                  gameDocumentLatest?.createdDateUtc &&
                  formatDateToDDMMYYYY(
                    gameDocumentLatest?.createdDateUtc!,
                    true
                  )
                }
                updateDate={
                  gameDocumentLatest?.modifiedDateUtc &&
                  formatDateToDDMMYYYY(
                    gameDocumentLatest?.modifiedDateUtc!,
                    true
                  )
                }
                version={gameDocumentLatest?.version}
              />
            </Col>
          </Row>
        </div>
      </Container>

      {openApproveSectionDialog && (
        <YesNoDialog
          title={confirmTitle}
          onConfirm={confirmApproveSectionDialog}
          onClose={closeApproveSectionDialog}>
          {confirmMessage}
        </YesNoDialog>
      )}
    </PrivatePage>
  );
}
