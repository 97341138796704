import { ActionCell } from '../../components/grid/actions-cell';

interface Props {
  dataItem: any;
  canView?: boolean;
  canCopy?: boolean;
  canEdit?: boolean;
  canDelete?: boolean;
  onCopy?: (id: number) => void;
  onView?: (id: number) => void;
  onEdit?: (id: number) => void;
  onDelete?: (id: number) => void;
  onCreateEvent?: (id: number) => void;
}

export const ActionsMylibrary = ({
  dataItem,
  onCopy,
  onView,
  onEdit,
  onDelete,
  onCreateEvent,
  canView = true,
  canCopy = true,
  canEdit = true,
  canDelete = true
}: Props) => {
  const handleCopy = (id: number) => {
    if (onCopy) {
      onCopy(id);
    }
  };

  const handleView = (id: number) => {
    if (onView) {
      onView(id);
    }
  };

  const handleEdit = (id: number) => {
    if (onEdit) {
      onEdit(id);
    }
  };

  const handleDelete = (id: number) => {
    if (onDelete) {
      onDelete(id);
    }
  };

  const handleCreateEvent = (id: number) => {
    if (onCreateEvent) {
      onCreateEvent(id);
    }
  };

  return (
    <td>
      <div className={'d-flex justify-content-start align-items-center'}>
        {canView && (
          <ActionCell
            title={'View game'}
            icon={'visibility'}
            themeColor={'primary'}
            className={'fs-2'}
            onClick={() => handleView(dataItem.id)}
          />
        )}
        {canEdit && (
          <ActionCell
            title={'Edit game'}
            icon={'edit'}
            themeColor={'primary'}
            className={'fs-2'}
            onClick={() => handleEdit(dataItem.id)}
          />
        )}
        {canCopy && (
          <ActionCell
            title={'Copy game'}
            icon={'content_copy'}
            themeColor={'primary'}
            className={'fs-2'}
            onClick={() => handleCopy(dataItem.id)}
          />
        )}
        {canDelete && (
          <ActionCell
            title={'Delete game'}
            icon={'delete'}
            themeColor={'error'}
            className={'fs-2'}
            onClick={() => handleDelete(dataItem.id)}
          />
        )}
      </div>
    </td>
  );
};
