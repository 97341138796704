import redaxios from 'redaxios';
import { appStore } from '../stores/app-store';
import { toastStore } from '../stores/toast-store';
import {
  ApiError,
  BadRequestError,
  InternalServerError
} from '../types/errors';
import { StringifiedType } from '../types/stringified-type';
import { AxiosRequestConfig } from 'axios';
import { authStore } from '../stores/auth-store';
import { User } from 'oidc-client-ts';
import { GetUser } from './api';

const base_url = process.env.REACT_APP_API_BASE_URL;

export type HttpStatus = 200 | 400 | 500;

/**
 * Gets the bearer/access token from the current user.
 */
export const BearerHeader = {
  authorization: `Bearer ${GetUser()?.access_token ?? ''}`
};

export interface HttpResponse<T> {
  isError: boolean;
  statusCode: HttpStatus;
  message: string;
  result: T;
}

function getUser() {
  const oidcStorage = sessionStorage.getItem(
    `oidc.user:${
      window?._env_?.REACT_APP_AUTHORITY || process.env.REACT_APP_AUTHORITY
    }:${window?._env_?.REACT_APP_CLIENT_ID || process.env.REACT_APP_CLIENT_ID}`
  );
  if (!oidcStorage) {
    return null;
  }
  return User.fromStorageString(oidcStorage);
}

function getAxiosConfig(isJson = true): AxiosRequestConfig {
  let user = getUser();
  let token = user?.access_token;

  let defaultConfig = {};
  let secureConfig = {};

  if (isJson) {
    defaultConfig = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    };
    secureConfig = {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    };
  } else {
    defaultConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: '*/*'
      }
    };
    secureConfig = {
      headers: {
        Authorization: 'Bearer ' + token,
        Accept: '*/*'
      }
    };
  }

  return token && token !== '' ? secureConfig : defaultConfig;
}

/**
 * Concats base url + endpoint and returns it as a complete api url
 * @param endpoint
 * @returns
 */
export const getUrl = (endpoint: string, params?: string) => {
  let url =
    (window?._env_?.REACT_APP_API_BASE_URL ||
      process.env.REACT_APP_API_BASE_URL) + endpoint;
  if (params) {
    url = url + `/?${params}`;
  }
  return url;
};

function throwHttpError<T>(response: HttpResponse<T>) {
  if (response.statusCode === 400) {
    const apiError = response.result as any as ApiError<T>;
    const isApiError = !!apiError.title;
    if (isApiError) {
      var errors: StringifiedType<T> = {};

      for (const key in apiError.errors) {
        if (Object.prototype.hasOwnProperty.call(apiError.errors, key)) {
          const val = apiError.errors[key]?.join('. ');
          var keyOfT = key as keyof StringifiedType<T>;
          errors[keyOfT] = val;
        }
      }

      throw new BadRequestError<T>(apiError.title, errors);
    }
    throw new BadRequestError('Bad Request');
  }
  if (response.statusCode === 500) {
    throw new InternalServerError();
  }
}

export const getFileAsync = async (endpoint: string, params?: string) => {
  try {
    var url = getUrl(endpoint);
    appStore.showLoading();

    return window
      .fetch(url, { headers: { ...BearerHeader } })
      .then((response) => response.blob())
      .then((blob) => URL.createObjectURL(blob));
  } catch (error) {
    if (error instanceof Error) {
      toastStore.show('Error', error.message, 'error');
    }
    throw error;
  } finally {
    appStore.hideLoading();
  }
};

/**
 * Sends a http GET request
 * @param url the api endpoint resource
 * @param params
 * @returns
 */
export const getAsync = async <TResponse>(
  endpoint: string,
  params?: string,
  config: AxiosRequestConfig = getAxiosConfig()
): Promise<HttpResponse<TResponse>> => {
  try {
    var url = getUrl(endpoint);
    appStore.showLoading();

    config.params = params;

    var response = await redaxios.get<HttpResponse<TResponse>>(
      url,
      config as any
    );
    const hasError = response.data.isError || response.data.statusCode !== 200;
    if (hasError) {
      throwHttpError(response.data);
    }

    var data = response.data;

    return data;
  } catch (error) {
    if (error instanceof Error) {
      toastStore.show('Error', error.message, 'error');
    }
    throw error;
  } finally {
    appStore.hideLoading();
  }
};

/**
 * Sends a http POST request
 * @param url
 * @param payload
 * @returns
 */
export const postAsync = async <TRequest, TResponse>(
  endpoint: string,
  payload?: TRequest,
  isJson = true
): Promise<HttpResponse<TResponse>> => {
  try {
    var url = getUrl(endpoint);
    appStore.showLoading();

    let config: AxiosRequestConfig = getAxiosConfig(isJson);

    var response = await redaxios.post<HttpResponse<TResponse>>(
      url,
      payload,
      config as any
    );
    const isError = response.data.isError || response.data.statusCode !== 200;
    if (isError) {
      throwHttpError(response.data);
    }

    var data = response.data;

    return data;
  } catch (error) {
    if (error instanceof Error) {
      toastStore.show('Error', error.message, 'error');
    }
    throw error;
  } finally {
    appStore.hideLoading();
  }
};

/**
 * Sends a http PUT request
 * @param url
 * @param payload
 * @returns
 */
export const putAsync = async <TRequest, TResponse>(
  endpoint: string,
  payload?: TRequest,
  isJson = true
): Promise<HttpResponse<TResponse>> => {
  try {
    var url = getUrl(endpoint);
    appStore.showLoading();

    let config: AxiosRequestConfig = getAxiosConfig(isJson);

    var response = await redaxios.put<HttpResponse<TResponse>>(
      url,
      payload,
      config as any
    );
    const isError = response.data.isError || response.data.statusCode !== 200;
    if (isError) {
      throwHttpError(response.data);
    }

    var data = response.data;

    return data;
  } catch (error) {
    if (error instanceof Error) {
      toastStore.show('Error', error.message, 'error');
    }
    throw error;
  } finally {
    appStore.hideLoading();
  }
};

/**
 * Sends a http DELETE request
 * @param url
 * @returns
 */
export const deleteAsync = async <TRequest, TResponse>(
  endpoint: string,
  config: AxiosRequestConfig = getAxiosConfig()
): Promise<HttpResponse<TResponse>> => {
  try {
    var url = getUrl(endpoint);
    appStore.showLoading();
    var response = await redaxios.delete<HttpResponse<TResponse>>(
      url,
      config as any
    );
    const isError = response.data.isError || response.data.statusCode !== 200;
    if (isError) {
      throwHttpError(response.data);
    }

    var data = response.data;

    return data;
  } catch (error) {
    if (error instanceof Error) {
      toastStore.show('Error', error.message, 'error');
    }
    throw error;
  } finally {
    appStore.hideLoading();
  }
};
