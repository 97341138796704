import { observer } from 'mobx-react';
import { Outlet } from 'react-router-dom';
import { Spinner } from '../components/spinner';
import { Toast } from '../components/toast';
import { appStore } from '../stores/app-store';
import { toastStore } from '../stores/toast-store';
import { AuthRequired } from '../components/authentication/auth-required';
import PrivateMenu from './private-menu';

/**
 * The layout for private pages.
 * @returns
 */
const Private = observer(() => {
  const getStyle = () => {
    const style = toastStore.getStyle();
    return style ? style : 'none';
  };

  return (
    <AuthRequired>
      <div className={'layout layout--private'}>
        {appStore.isLoading() && <Spinner />}
        <PrivateMenu />
        <div className={'app-main'}>
          <Outlet />
        </div>
        <Toast
          style={getStyle()}
          show={toastStore.isShow()}
          title={toastStore.getTitle()}>
          {toastStore.getMessage()}
        </Toast>
      </div>
    </AuthRequired>
  );
});

export default Private;
