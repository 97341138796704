import { Button } from '@progress/kendo-react-buttons';
import merge from 'lodash.merge';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Toolbar from '../../components/page-toolbar';
import { PrivatePage } from '../../components/private-page';
import {
  copyAssessmentToLibrary,
  getGlobalAssessmentById,
  getGlobalContentByAssessmentIdAsync,
  getGlobalDocumentLatestAsync
} from '../../services/assessment';
import { GetLanguagesAsync } from '../../services/country';
import { appStore } from '../../stores/app-store';
import { toastStore } from '../../stores/toast-store';
import { Assessment } from '../../types/assessment';
import { AssessmentContentFullEditor } from '../../features/assessment/assessment-content-full-editor';
import { CountryResponse } from '../../types/country-response';
import { DefaultAssessmentSettings } from '../../constants/default-asessment-settings';
import { isArrayEqual } from '@progress/kendo-react-layout';
import { validateSettings } from '../../utils/assessment-document/validate-setting';

const defaultEntity = (): Assessment => {
  return {
    id: 0,
    name: '',
    title: '',
    organisationId: 1,
    htmlContent: '',
    description: '',
    version: 'v0.0.1',
    status: 'New'
  };
};

export const GlobalAssessmentDetail = () => {
  const { id } = useParams();
  const [entity, setEntity] = useState<Assessment>();
  const [languages, setLanguages] = useState<CountryResponse[]>([]);
  const [, setSelectedTab] = useState<string>('');
  const [, setLatestStatus] = useState<string>('');
  const [currentLocale, setCurrentLocale] = useState<string>();
  const newAssessmentBreadcrumb = [
    { label: 'Dashboard', href: '../dashboard' },
    { label: 'Assessment', href: '../global-assessment' }
  ];

  const detailAssessmentBreadcrumb = [
    { label: 'Dashboard', href: '../dashboard' },
    { label: 'Global Assessment', href: '../global-assessment' },
    {
      label: 'Global Assessment Details',
      href: `../global-assessment-details/${id}`
    }
  ];

  const getDetails = async () => {
    try {
      appStore.showLoading();
      const document = await getLatestDocumentAsync();

      // combine assessment and assessment documents (resources)
      let assess1 = await getGlobalAssessmentById(parseInt(id!));
      let assess2 = await getGlobalContentByAssessmentIdAsync(
        parseInt(id!),
        document.id
      );

      let assessment = merge(assess1, assess2);

      //set default value for enableAssignToOnActionPlan and enableDueDateOnActionPlan #5766
      if (!assessment.settings) {
        assessment.settings = DefaultAssessmentSettings;
      }

      const defaultSetting = Object.keys(DefaultAssessmentSettings).sort();
      const currentSetting = Object.keys(assessment.settings).sort();

      // handle old Asessment settings, replace with new Asessment V2 settings
      if (
        !isArrayEqual(defaultSetting, currentSetting) ||
        !validateSettings(DefaultAssessmentSettings, assessment.settings)
      ) {
        assessment.settings = DefaultAssessmentSettings;
      }

      setEntity(assessment);
    } catch (ex) {
      console.error(ex);
    } finally {
      appStore.hideLoading();
    }
  };

  const setDefaultEntity = () => {
    setEntity(defaultEntity);
  };

  const handleEntityChange = (newEntity: Assessment) => {
    setEntity(newEntity);
  };

  const getLatestDocumentAsync = async () => {
    let document = await getGlobalDocumentLatestAsync(parseInt(id!));
    setLatestStatus(document?.status ?? '');
    return document;
  };

  const getLanguages = async () => {
    const response = await GetLanguagesAsync();
    setLanguages(response.data);
  };

  const toggleLanguageTab = (activeTab: string) => {
    if (activeTab === 'language') {
      setSelectedTab(activeTab);
    } else {
      setSelectedTab('');
    }
  };

  const onCopyAssessment = async () => {
    try {
      appStore.showLoading();

      await copyAssessmentToLibrary(entity!);

      toastStore.show(
        'Assessment List',
        <div>Assessment copied to Assessment list.</div>,
        'success'
      );
    } catch (err: any) {
      toastStore.show(
        'Assessment List',
        <div>Failed to copy to Assessment list.</div>,
        'error'
      );
    } finally {
      appStore.hideLoading();
    }
  };

  useEffect(() => {
    if (id) {
      getDetails();
    } else {
      setDefaultEntity();
    }

    getLanguages();
    //eslint-disable-next-line
  }, []);

  return (
    <PrivatePage
      breadcrumb={id ? detailAssessmentBreadcrumb : newAssessmentBreadcrumb}
      pageTitle={'Global Assessment'}>
      <>
        <Toolbar title={id ? (entity?.name ?? '') : 'New Assessment'}>
          <Button themeColor={'primary'} onClick={onCopyAssessment}>
            Copy to My library
          </Button>
        </Toolbar>
        <hr />
        <Container>
          {entity && (
            <AssessmentContentFullEditor
              isViewMode={true}
              languages={languages}
              entity={entity}
              currentLocale={currentLocale}
              setCurrentLocale={setCurrentLocale}
              handleEntityChange={handleEntityChange}
              toggleLanguageTab={toggleLanguageTab}
            />
          )}
        </Container>
      </>
    </PrivatePage>
  );
};
