import React, { useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { useAuth } from 'react-oidc-context';
import Logout from '../dialogs/logout';

export const LogoutButton = () => {
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const auth = useAuth();

  const onConfirmLogout = () => {
    setShowLogoutConfirmation(false);
    auth.signoutRedirect();
  };

  return (
    <>
      <Button
        fillMode={'flat'}
        className={
          'list-group-item list-group-item-action justify-content-between'
        }
        onClick={() => setShowLogoutConfirmation(true)}>
        <span className={'material-symbols-outlined'}>logout</span>
        <span>Log out</span>
      </Button>

      {showLogoutConfirmation && (
        <Logout
          onCancelLogout={() => setShowLogoutConfirmation(false)}
          onConfirmLogout={onConfirmLogout}
        />
      )}
    </>
  );
};

export default LogoutButton;
