import { ComponentType, useContext, useState } from 'react';
import {
  Window,
  WindowActionsBar,
  WindowProps
} from '@progress/kendo-react-dialogs';
import { FluidForm } from '../../../components/forms';
import { ItemEditor } from './item-editor';
import { ItemEntity, ResourceEntity } from '../../../types/game-document/';
import {
  CheckboxChangeEvent,
  InputChangeEvent
} from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { EditorMode } from '../../../types/editor-mode';
import { EntityEditor } from '../../../types/game-document/entity-editor';
import { GameDocumentContext } from '../../../contexts/game-document';
import RequiredFields from '../../../types/required-fields';
import {
  ERR_DUPLICATE_NAME_VALUE,
  ERR_INPUT_REQUIRED
} from '../../../constants/text';
import { Typography } from '@progress/kendo-react-common';

interface ItemEditorWindowProps extends WindowProps {
  editorEntity: EntityEditor<ItemEntity>;
  editorResource: EntityEditor<ResourceEntity>[];
  toggleDialog: Function;
  onSubmit: (
    editorEntity: EntityEditor<ItemEntity>,
    resourceEntity: EntityEditor<ResourceEntity>[]
  ) => void;
  editorMode: EditorMode;
}

export const ItemEditorWindow: ComponentType<ItemEditorWindowProps> = ({
  editorEntity,
  editorResource,
  toggleDialog,
  onSubmit,
  editorMode,
  ...props
}: ItemEditorWindowProps) => {
  const [state] = useContext(GameDocumentContext);
  const [entity, setEntity] = useState<ItemEntity>({ ...editorEntity.entity });
  const [resources, setResources] = useState<ResourceEntity[]>([
    ...editorResource.map((item) => item.entity)
  ]);
  const [requiredFields, setRequiredFields] = useState<
    RequiredFields<ItemEntity>[]
  >([{ name: 'name', errorMessage: '' }]);

  const handleInputChange = (event: InputChangeEvent) => {
    const { name, value } = event.target;
    if (name === 'titleResId' || name === 'summaryResId') {
      const key = entity[name]!;
      setResources(
        resources.map((item) =>
          item.id === key ? { ...item, value: value! as string } : { ...item }
        )
      );
    } else {
      setEntity({ ...entity, [name!]: value });
    }
  };

  const handleImageSelected = (url: string, size: number, name?: string) => {
    setResources(
      resources.map((item) =>
        item.id === entity.imageResId
          ? {
              ...item,
              value: url,
              size: size,
              name: item.id,
              description: name ?? ''
            }
          : { ...item }
      )
    );
  };

  const isInputValid = (): boolean => {
    const inputValidation = requiredFields.map((input) => {
      const entityValue = entity[input.name as keyof ItemEntity];
      const itemIndex = state.gameDocument?.assets?.items?.findIndex(
        (item) =>
          typeof item.name === 'string' &&
          typeof entityValue === 'string' &&
          item.name.toLowerCase() === entityValue?.toLowerCase()
      );

      if (input.name === 'name') {
        // Check validation for input name
        if (entityValue === '') {
          input.errorMessage = ERR_INPUT_REQUIRED;
        } else if (
          itemIndex !== -1 &&
          state.gameDocument?.assets?.items![itemIndex!]?.id !== entity.id
        ) {
          input.errorMessage = ERR_DUPLICATE_NAME_VALUE;
        } else {
          input.errorMessage = '';
        }
      }

      return input;
    });
    setRequiredFields(inputValidation);
    return (
      inputValidation.findIndex((input) => input.errorMessage !== '') === -1
    );
  };

  const handleHideItemChange = (event: CheckboxChangeEvent) => {
    const { name, value } = event.target;
    setEntity({ ...entity, [name!]: value });
  };

  return (
    <Window
      modal={true}
      minimizeButton={undefined}
      className={'shadow'}
      initialWidth={550}
      initialHeight={450}
      title={editorEntity.isNew ? 'New item' : editorEntity.entity.name}
      {...props}>
      <FluidForm>
        <ItemEditor
          editorMode={editorMode}
          entity={entity}
          resources={resources}
          handleInputChange={handleInputChange}
          handleImageSelected={handleImageSelected}
          requiredFields={requiredFields}
          handleHideItemChange={handleHideItemChange}>
          <div className={'mt-1'}>
            <Typography.p fontSize={'xsmall'}>
              Notes: Please use images with a 1:1 aspect ratio to achieve a
              better experience on the Player Screen.
            </Typography.p>
          </div>
        </ItemEditor>
      </FluidForm>
      <WindowActionsBar>
        <Button
          themeColor={'secondary'}
          onClick={() => {
            toggleDialog();
          }}>
          Cancel
        </Button>
        <Button
          themeColor={'primary'}
          onClick={() => {
            if (isInputValid()) {
              const entityResources = resources.map((item, index) => ({
                isNew: editorResource[index].isNew,
                entity: item
              })) as EntityEditor<ResourceEntity>[];
              onSubmit({ isNew: editorEntity.isNew, entity }, entityResources);
            }
          }}>
          Save
        </Button>
      </WindowActionsBar>
    </Window>
  );
};
