import {
  filterBy,
  FilterDescriptor,
  FilterOperator
} from '@progress/kendo-data-query';
import {
  AutoComplete,
  AutoCompleteChangeEvent,
  AutoCompleteCloseEvent
} from '@progress/kendo-react-dropdowns';
import { useEffect, useState } from 'react';
import { getProperty } from '../utils/object-utils';

export interface AutocompleteProps<T> {
  label?: string;
  placeholder?: string;
  items: T[];
  textField: string;
  value?: T;
  className?: string;
  isReadOnly?: boolean;
  isClearButton?: boolean;
  onFocus?: () => void;
  onChange: (item: T) => void;
  onClear?: () => void;
}

export function Autocomplete<T extends {}>(props: AutocompleteProps<T>) {
  const {
    isReadOnly,
    isClearButton,
    placeholder,
    items,
    textField,
    className,
    onChange,
    value,
    onClear = () => {},
    onFocus = () => {}
  } = props;
  const [state, setState] = useState({ data: items, value: '' });
  const filterData = (value: string, filterType: FilterOperator) => {
    const data = items;
    const filter: FilterDescriptor = {
      value: value,
      field: textField,
      operator: filterType,
      ignoreCase: true
    };
    return value ? filterBy(data, filter) : data;
  };

  const onChangeHandler = (event: AutoCompleteChangeEvent) => {
    const value = event.value;
    const filteredData = filterData(value, FilterOperator.Contains);

    setState({
      data: filteredData,
      value: value
    });

    if (event.syntheticEvent.type === 'click') {
      onClear();
    }
  };

  const onFocusHandler = () => {
    onFocus();
  };

  const onClose = (event: AutoCompleteCloseEvent) => {
    if (['click', 'keydown'].includes(event.syntheticEvent.type)) {
      const value = event.target.value;
      const filteredData = filterData(value, FilterOperator.Contains);

      if (filteredData.length === 1 && onChange) {
        onChange(filteredData[0]);
      } else {
        const custFilterData = filterData(value, FilterOperator.EqualTo);
        if (onChange && value !== '' && custFilterData.length > 0) {
          onChange(custFilterData[0]);
        }
      }
    }
  };

  useEffect(() => {
    if (value) {
      setState({
        data: items,
        value: getProperty(value, textField as never)
      });
    }
  }, [items, textField, value]);

  return (
    <div className={className}>
      <AutoComplete
        data={state.data}
        value={state.value ?? ''}
        textField={textField}
        onChange={onChangeHandler}
        placeholder={placeholder}
        readonly={isReadOnly}
        onFocus={onFocusHandler}
        clearButton={!isReadOnly && (isClearButton ?? true)}
        onClose={(e) => onClose(e)}
      />
    </div>
  );
}
