import { useEffect, useState } from 'react';

import DropdownSelector from './dropdown-selector';
import Identifiers from '../../constants/identifiers';
import SelectionItem from '../../types/selection-item';

export interface IdentifierSelectorProps {
  identifier?: string;
  onChange?: (identifier: string) => void;
  action?: String;
  isOnConditional?: boolean;
}

export const IdentifierSelector = ({ ...props }: IdentifierSelectorProps) => {
  const [className, setClassName] = useState<string>('');
  const [identifierList, setIdentifierList] =
    useState<SelectionItem[]>(Identifiers);

  const handleIdentifierChangeChange = (identifier: string) => {
    if (props.onChange) {
      props.onChange(identifier);
    }
  };

  useEffect(() => {
    setClassName(`identifier--${props?.identifier ?? 'select'}`);
    if (props.action) {
      const filteredIdentifiers = Identifiers.filter(
        (item) =>
          !item.availableOnActions ||
          (item.availableOnActions.includes(props.action!) &&
            (item.isShowOnConditionalOnly == undefined ||
              item.isShowOnConditionalOnly == props.isOnConditional))
      );

      setIdentifierList(filteredIdentifiers);

      return;
    }

    const filteredIdentifiers = Identifiers.filter(
      (item) =>
        item.isShowOnConditionalOnly == undefined ||
        item.isShowOnConditionalOnly == props.isOnConditional
    );

    setIdentifierList(filteredIdentifiers);

    return;
  }, [props.identifier]);

  return (
    <DropdownSelector<SelectionItem, 'name'>
      fieldName="name"
      items={identifierList}
      selected={props.identifier}
      additionalClassname={className}
      onClick={(e) => handleIdentifierChangeChange(e.name)}
    />
  );
};
