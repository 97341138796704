import React from 'react';
import { Progress } from '../../layouts';
import { ReactComponent as Logo } from '../../assets/cg-icon-globe.svg';
import { useAuth } from 'react-oidc-context';

interface SessionLostProps {
  children?: React.ReactNode;
}

const SessionLost = ({ children }: SessionLostProps) => {
  const auth = useAuth();
  return (
    <Progress>
      <div className={'text-center'}>
        <Logo className={'spin'} style={{ width: 100 }} />
        <h1 className={'mt-3'}>Session timed out</h1>
        <p>Your session has expired. Please re-authenticate.</p>
        <>{children}</>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => auth.signinRedirect()}>
          Log in
        </button>
      </div>
    </Progress>
  );
};

export default SessionLost;
