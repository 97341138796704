import React from 'react';
import { Overlay } from 'ol';
import { fromLonLat } from 'ol/proj';
import { Coordinate } from 'ol/coordinate';

import { MapContext } from '../components/map/map-context';
import { GameDocumentContext } from '../contexts/game-document';
import { createCustomTaskPosition } from '../utils/map/task-position';
import { MapIllustrationContext } from '../components/map/map-illustration-context';

export const useGameTaskPosition = ({ mapType }: { mapType: string }) => {
  const map = React.useContext(MapContext);
  const mapIllustration = React.useContext(MapIllustrationContext);
  const [gameDocument] = React.useContext(GameDocumentContext);

  //#region Add task element and its overlay
  const addTaskOverlay = React.useCallback(
    (
      id: string,
      name: string,
      iconUrl: string,
      position: Coordinate | number[],
      isAddTaskMode: boolean = false
    ) => {
      if (map || mapIllustration) {
        const taskExist = (
          mapType === 'openStreetMap' ? map : mapIllustration
        ).getOverlayById(id);
        // prevent create task overlay more than once
        if (taskExist) {
          updateTaskOverlay({ id, name, icon: iconUrl });
          return;
        }

        const { showTasknameVisibility } = gameDocument.gameDocument?.overview!;
        const taskElement = createCustomTaskPosition({
          id,
          name,
          iconUrl,
          isAddTaskMode,
          showTasknameVisibility
        });

        const overlay = new Overlay({
          id,
          position: fromLonLat(position || [0, 0]),
          positioning: 'center-center',
          element: taskElement,
          ...(isAddTaskMode && { className: 'task-pointer-none' })
        });

        (mapType === 'openStreetMap' ? map : mapIllustration).addOverlay(
          overlay
        );
      }
    },
    [map, mapIllustration, mapType, gameDocument.gameDocument?.settings.inGame]
  );

  const removeTaskOverlay = React.useCallback(
    (id: string) => {
      if (map || mapIllustration) {
        const getTask = (
          mapType === 'openStreetMap' ? map : mapIllustration
        ).getOverlayById(id);
        // if task not found, return
        if (!getTask) return;

        (mapType === 'openStreetMap' ? map : mapIllustration).removeOverlay(
          getTask
        );
      }
    },
    [map, mapIllustration, mapType]
  );

  const updateTaskOverlay = React.useCallback(
    ({ id, icon, name }: { id: string; icon?: string; name?: string }) => {
      const taskIcon = document.getElementById(
        `icon-${id}`
      ) as HTMLImageElement;
      if (taskIcon && icon) {
        taskIcon.src = icon;
      }

      const taskName = document.getElementById(
        `taskName-${id}`
      ) as HTMLSpanElement;
      if (taskName && name) {
        taskName.innerHTML = name;
      }
    },
    []
  );

  //#region Update task overlay by its id
  const updateTaskOverlayPosition = React.useCallback(
    (id: string, newPosition: Coordinate | number[]) => {
      const task = (
        mapType === 'openStreetMap' ? map : mapIllustration
      ).getOverlayById(id);
      if (task) {
        task.setPosition(fromLonLat(newPosition));
      }
    },
    [map, mapIllustration, mapType, mapIllustration]
  );

  return {
    addTaskOverlay,
    updateTaskOverlay,
    removeTaskOverlay,
    updateTaskOverlayPosition
  };
};
