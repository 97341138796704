import {
  DeleteAsync,
  GetAllAsync,
  PostAsync,
  GetAsync,
  PatchAsync,
  PutAsync
} from './api';
import { OrganisationResponse } from '../types/responses/organisation-response';
import { EmailAddressResponse } from '../types/responses/email-address-response';

/**
 * Gets ALL OrganisationsEmails encapsulated in a paged payload.
 * @returns all Organisation objects.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetOrganisationsEmailsAsync = async (organisationId: number) =>
  GetAllAsync<OrganisationResponse>(
    `organisations/${organisationId}/emailaddresses`
  );

/**
 * Applies a POST to the OrganisationEmail.
 * requiring only the fields and values that require modification.
 * @param organisationId - the ID of the Organisation to POST
 * @param organisation - the Organisation or partial Organisation to update [POST]
 * @returns the updated Organisation object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/POST
 */
export const PostOrganisationEmailAsync = async (
  organisationId: number,
  organisationEmail: EmailAddressResponse
) =>
  PostAsync<EmailAddressResponse>(
    `organisations/${organisationId}/emailaddresses`,
    organisationEmail
  );

/**
 * Gets the OrganisationEmail by id.
 * @param organisationId - the ID of the Organisation to retrieve
 * @returns the Organisation object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetOrganisationEmailAsync = async (organisationId: number) =>
  GetAllAsync<OrganisationResponse>(
    `organisations/${organisationId}/emailaddresses`
  );

/**
 * Updates the OrganisationEmail by completely replacing it.
 * @param organisationId - the ID of the entity to update [PATCH]
 * @param organisation - the Organisation to update [PATCH]
 * @returns the updated Organisation object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PATCH
 */
export const PatchOrganisationEmailAsync = async (
  organisationId: number,
  organisationEmailId: number,
  organisationEmail: any | EmailAddressResponse
) =>
  PatchAsync<EmailAddressResponse>(
    `organisations/${organisationId}/emailaddresses/${organisationEmailId}`,
    organisationEmail
  );

/**
 * Applies a PUT to the OrganisationEmail. This can be considered a partial update
 * requiring only the fields and values that require modification.
 * @param organisationId - the ID of the Organisation to PUT
 * @param organisation - the Organisation or partial Organisation to update [PUT]
 * @returns the updated Organisation object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PUT
 */
export const PutOrganisationEmailAsync = async (
  organisationId: number,
  organisationEmailId: number,
  organisationEmail: EmailAddressResponse
) =>
  PutAsync<EmailAddressResponse>(
    `organisations/${organisationId}/emailaddresses/${organisationEmailId}`,
    organisationEmail
  );

/**
 * Deletes a OrganisationEmail by id.
 * @param organisationId - the ID of the Organisation to DELETE
 * @returns the deleted Organisation object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/DELETE
 */
export const DeleteOrganisationEmailAsync = async (
  organisationId: number,
  organisationEmailId: number
) =>
  DeleteAsync<EmailAddressResponse>(
    `organisations/${organisationId}/emailaddresses/${organisationEmailId}`
  );
