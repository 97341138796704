import { Checkbox, NumericTextBox } from '@progress/kendo-react-inputs';
import { Label, Error } from '@progress/kendo-react-labels';
import { Col, Row } from 'react-bootstrap';
import {
  ManualScoring as ManualScoringEntity,
  TaskContentAnswer
} from '../../types/game-document/entities/task-content';

type ManualScoringProps = {
  id?: string;
  answers?: TaskContentAnswer[];
  isManualScoring?: boolean;
  manualScoring?: ManualScoringEntity;
  errorMessage?: string;
  onChangeAnswers?: (
    answers: TaskContentAnswer[],
    isManualScoring?: boolean,
    manualScoring?: ManualScoringEntity
  ) => void;
};
export function ManualScoring({
  answers,
  isManualScoring,
  manualScoring,
  errorMessage,
  onChangeAnswers = () => {}
}: ManualScoringProps) {
  const onChangeManualScoring = (
    isManualScoring: boolean,
    field?: keyof ManualScoringEntity,
    value?: number
  ) => {
    let newAnswers: TaskContentAnswer[] = [...answers!];
    let newManualScoring: ManualScoringEntity = { ...manualScoring };

    if (newManualScoring) {
      if (field === 'min') {
        newManualScoring.min = value;
      }

      if (field === 'max') {
        newManualScoring.max = value;
      }
    }

    onChangeAnswers(newAnswers, isManualScoring, newManualScoring);
  };

  return (
    <>
      <Row>
        <Col>
          <Checkbox
            label={'Enable manual scoring'}
            checked={isManualScoring}
            onChange={(e) =>
              onChangeManualScoring(e.target.value as boolean)
            }></Checkbox>
        </Col>
      </Row>
      {isManualScoring && (
        <>
          <Row className={'d-flex align-items-center mt-2 p-1'}>
            <Col sm={'12'} md={'12'} lg={'4'} xl={'4'}>
              <Label>Suggested score range</Label>
            </Col>
            <Col
              sm={'12'}
              md={'12'}
              lg={'8'}
              xl={'8'}
              className={'d-flex align-items-center'}>
              <Label className={'mr-2'}>From</Label>
              <NumericTextBox
                className={'mr-2'}
                value={manualScoring?.min as number}
                onChange={(e) =>
                  onChangeManualScoring(
                    isManualScoring,
                    'min',
                    e.target.value as number
                  )
                }
              />

              <Label className={'mr-2'}>To</Label>
              <NumericTextBox
                value={manualScoring?.max as number}
                onChange={(e) =>
                  onChangeManualScoring(
                    isManualScoring,
                    'max',
                    e.target.value as number
                  )
                }
              />
            </Col>
          </Row>
          <Row>
            <Col sm={'12'} md={'12'} lg={'4'} xl={'4'}></Col>
            <Col>{errorMessage !== '' && <Error>{errorMessage}</Error>}</Col>
          </Row>
        </>
      )}
    </>
  );
}
