import { PropsWithChildren } from 'react';
import { OrganisationRequest } from '../types/organisation';
import { State } from '@progress/kendo-data-query';
import { PageChangeEvent, Pager } from '@progress/kendo-react-data-tools';
import {
  DefaultGridSettings,
  DefaultPagerSettings
} from '../constants/grid-settings';
import MediaCardBackground from './mediacard-bg';

export interface CardViewDataStateChangeEvent {
  dataState: State;
}

export interface OrganisationsCardViewProps extends State {
  data?: OrganisationRequest[];
  onNameClick?: (id: number, viewSource?: string) => void;
  onDataStateChange?: (event: CardViewDataStateChangeEvent) => void;
  total?: number;
}

export const OrganisationsCardView = ({
  data,
  onNameClick,
  total = 0,
  ...props
}: PropsWithChildren<OrganisationsCardViewProps>) => {
  const handleNameClick = (id: number) => {
    if (onNameClick) {
      onNameClick(id, 'card');
    }
  };

  const handlePageChange = (event: PageChangeEvent) => {
    raiseDataStateChangeEvent({ skip: event.skip, take: event.take });
  };

  /**
   * Raise the Grid DataState change event. I.e. filters have changed.
   * @param moreData
   */
  const raiseDataStateChangeEvent = (moreData: State) => {
    props.onDataStateChange?.({
      dataState: { ...getDataState(), ...moreData }
    });
  };

  const getDataState = () => {
    return {
      filter: props.filter,
      sort: props.sort,
      skip: props.skip,
      take:
        props.take !== undefined ? props.take : DefaultGridSettings.pageSize,
      group: props.group
    };
  };

  return (
    <div className={'mt-3'}>
      <div className={'row'}>
        {data &&
          data.map((org, index) => (
            <div
              className={
                'col-sm-12 col-md-6 col-lg-6 col-xl-4 d-flex align-items-stretch'
              }
              key={index}>
              <div className={'card mt-4 p-2 flex-fill'}>
                <div className={'row'}>
                  <div className={'d-grid col-3 col-md-5 col-lg-3'}>
                    <MediaCardBackground imageUrl={org.logoUrl!} />
                  </div>
                  <div className={'col-9 col-md-7 col-lg-9'}>
                    <div className={'card-body-right p-2 d-flex flex-column'}>
                      <span
                        className={'card-text align-middle '}
                        onClick={() => handleNameClick(org?.id!)}>
                        Name:
                        <span className={'link-primary ml-1 cursor-pointer'}>
                          {org.name}
                        </span>
                      </span>
                      <span className={'card-text align-middle'}>
                        Territory: {org.territory}
                      </span>
                      <span className={'card-text align-middle'}>
                        Status: {org.status}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className={'row mt-3'}>
        <div className={'col-md-12'}>
          <Pager
            onPageChange={handlePageChange}
            total={total}
            take={
              props.take !== undefined
                ? props.take
                : DefaultGridSettings.pageSize
            }
            skip={props.skip !== undefined ? props.skip : 0}
            {...DefaultPagerSettings}
          />
        </div>
      </div>
    </div>
  );
};
