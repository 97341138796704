import {
  ComboBox,
  ComboBoxFilterChangeEvent,
  ComboBoxProps
} from '@progress/kendo-react-dropdowns';
import { UserResponse } from '../../types/responses/user-response';
import React, { useEffect, useRef, useState } from 'react';
import { GetUsersAsync } from '../../services/users';
import { FilterDescriptor, filterBy } from '@progress/kendo-data-query';

export const UserComboBox = (props: ComboBoxProps) => {
  const users = useRef<UserResponse[]>();
  const [data, setData] = useState<UserResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const delay: number = 500;

  // load the initial set of users
  useEffect(() => {
    // cache hit
    if (users.current) {
      setData(users.current);
      setLoading(false);
      return;
    }
    // fetch new data
    GetUsersAsync().then((res) => {
      // sort alphabetically and store in ref with an intermediate collection for filtering
      users.current = res.data.sort((a, b) =>
        !a.fullName || b.fullName ? 0 : a.fullName!.localeCompare(b.fullName!)
      );
      setData(users.current);
      setLoading(false);
    });
  }, []);

  // allow filtering of the user data
  const filterData = (filter: FilterDescriptor) => {
    const data = users.current!.slice();
    return filterBy(data, filter);
  };

  // debounce 500ms so filtering doesnt take affect after every keystroke
  const timeout = useRef<any>();
  const filterChange = (event: ComboBoxFilterChangeEvent) => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setData(filterData(event.filter));
      setLoading(false);
    }, delay);
    setLoading(true);
  };

  return (
    <ComboBox
      data={data}
      textField={'fullName'}
      dataItemKey={'userId'}
      filterable={true}
      onFilterChange={filterChange}
      loading={loading}
      allowCustom={false}
      clearButton={props.clearButton}
      {...props}
    />
  );
};

export default UserComboBox;
