import { GameDocument } from '../../../types/game-document';
import { MergeAssets } from './index';
import { TaskRoute } from '../../../types/game-document/entities/routes';
import cloneDeep from 'lodash.clonedeep';

/**
 * Updates the identified Map in the Game Document.
 * @param gameDocument - The Game Document to modify
 * @param route - The updated Routes
 * @constructor
 */
export const UpdateRoutesAsync = async (
  gameDocument: GameDocument,
  route: TaskRoute
) => {
  let routes = gameDocument.assets?.routes ?? [];
  let routeIndex = routes.findIndex(
    (i) => i.id === route.id && i.mapId === route.mapId
  )!;
  routeIndex >= 0 ? (routes[routeIndex] = route) : routes.push(route);
  return await MergeAssets(gameDocument, routes, 'routes');
};

/**
 * Get all routes by map ID.
 * @param gameDocument - The Game Document
 * @param mapId - The ID of the Map to get the Route Entity
 * @constructor
 */
export const GetAssetRoutesByMapId = (
  gameDocument: GameDocument,
  mapAssetId: string
) => {
  return (
    gameDocument?.assets?.routes?.filter((item) => item.mapId === mapAssetId) ||
    []
  );
};

/**
 * Get detail routes by map ID.
 * @param gameDocument - The Game Document
 * @param mapId - The ID of the Map to get the Route Entity
 * @constructor
 */
export const GetDetailRoutesByMapId = async (
  gameDocument: GameDocument,
  mapAssetId: string
) => {
  return (
    gameDocument.assets.routes?.find((item) => item.mapId === mapAssetId) ?? {}
  );
};

/**
 * Get detail routes by route ID and map ID.
 * @param gameDocument - The Game Document
 * @param mapId - The ID of the Map to get the Route Entity
 * @param routeId - The ID of the Route to get the Route Entity
 * @constructor
 */
export const GetDetailRoutesById = async (
  gameDocument: GameDocument,
  mapAssetId: string,
  routeId: string
) => {
  return (
    gameDocument.assets.routes?.find(
      (item) => item.mapId === mapAssetId && item.id === routeId
    ) ?? {}
  );
};

/**
 * Get detail routes by route ID and map ID.
 * @param gameDocument - The Game Document
 * @param mapId - The ID of the Map to get the Route Entity
 * @param routeId - The ID of the Route to get the Route Entity
 * @constructor
 */
export const GetDetailRoutesByRouteId = async (
  gameDocument: GameDocument,
  routeId: string
) => {
  return (
    (await gameDocument.assets.routes?.find((item) => item.id === routeId)) ??
    {}
  );
};

/**
 * Updates the identified Map in the Game Document.
 * @param gameDocument - The Game Document to modify
 * @param route - The updated Routes
 * @constructor
 */
export const RemoveRoutesAsync = async (
  gameDocument: GameDocument,
  route: TaskRoute
) => {
  let gamedoc = cloneDeep(gameDocument);
  gamedoc.assets.routes = gamedoc.assets.routes?.filter(
    (item) => item.id !== route.id
  );

  return gamedoc;
};
