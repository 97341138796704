import Map from 'ol/Map';
import React from 'react';
import { Projection } from 'ol/proj';
import View from 'ol/View';
import { getCenter } from 'ol/extent';

const extent = [0, 0, 1200, 680];
const projection = new Projection({
  code: 'xkcd-image',
  units: 'pixels',
  extent: extent
});

export const MapIllustrationContext = React.createContext<Map>(
  new Map({
    layers: [],
    target: 'map',
    view: new View({
      projection: projection,
      center: getCenter(extent),
      zoom: 1,
      maxZoom: 8,
      resolution: 1
    })
  })
);
