import { GameDocument } from '../types/game-document';
import { GetAsync, PatchAsync, PostAsync } from './api';
import { GameDocumentVersionResponse } from '../types/responses/game-document-version-response';

const GamesEndpoint = `games`;

export async function updateDocument(
  id: number,
  documentId: number,
  request: GameDocument
) {
  return await AddGameDocumentContentAsync(id, documentId, request);
}

export const AddGameDocumentAsync = async (
  gameId: number,
  request: GameDocumentVersionResponse
) =>
  PostAsync<GameDocumentVersionResponse>(
    `${GamesEndpoint}/${gameId}/documents`,
    request
  );

export const AddGameDocumentContentAsync = async (
  gameId: number,
  documentId: number,
  request: GameDocument
) =>
  PostAsync<GameDocumentVersionResponse>(
    `${GamesEndpoint}/${gameId}/document/${documentId}/contents`,
    request
  );

export const GetGameDocumentLatestAsync = async (gameId: number) =>
  GetAsync<GameDocumentVersionResponse>(
    `${GamesEndpoint}/${gameId}/documents/latest`
  );

export const GetApprovedGameDocumentLatestAsync = async (gameId: number) =>
  GetAsync<GameDocumentVersionResponse>(
    `library/${GamesEndpoint}/${gameId}/documents/latest`
  );

export const GetPendingGameDocumentLatestAsync = async (gameId: number) =>
  GetAsync<GameDocumentVersionResponse>(
    `admin/${GamesEndpoint}/${gameId}/documents/latest`
  );

/**
 * https://localhost:7238/api/v2/games/5003/document/1002/contents
 * Gets game document by game id.
 * @param gameId - the ID of the Game
 * @param documentId - the ID of the Game Document
 * @returns the Game document Json.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetGameDocumentContentAsync = async (
  gameId: number,
  documentId: number
) =>
  GetAsync<GameDocument>(
    `${GamesEndpoint}/${gameId}/document/${documentId}/contents`
  );

/**
 * https://localhost:7238/api/v2/library/games/5003/document/1002/contents
 * Gets approved game document by game id.
 * @param gameId - the ID of the Game
 * @param documentId - the ID of the Game Document
 * @returns the Game document Json.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetApprovedGameDocumentContentAsync = async (
  gameId: number,
  documentId: number
) =>
  GetAsync<GameDocument>(
    `library/${GamesEndpoint}/${gameId}/document/${documentId}/contents`
  );

/**
 * https://localhost:7238/api/v2/admin/games/5003/document/1002/contents
 * Gets pending game document by game id.
 * @param gameId - the ID of the Game
 * @param documentId - the ID of the Game Document
 * @returns the Game document Json.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetPendingGameDocumentContentAsync = async (
  gameId: number,
  documentId: number
) =>
  GetAsync<GameDocument>(
    `admin/${GamesEndpoint}/${gameId}/document/${documentId}/contents`
  );

export const PatchGameDocumentAsync = async (
  gameId: number,
  documentId: number,
  request: GameDocumentVersionResponse
) =>
  PatchAsync<GameDocumentVersionResponse>(
    `${GamesEndpoint}/${gameId}/documents/${documentId}`,
    request
  );
