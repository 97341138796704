import { makeAutoObservable } from 'mobx';

export class AppStore {
  private loading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }
  showLoading() {
    this.loading = true;
  }
  hideLoading() {
    this.loading = false;
  }
  isLoading() {
    return this.loading;
  }
}

export const appStore = new AppStore();
