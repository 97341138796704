import merge from 'lodash.merge';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { PrivatePage } from '../../components/private-page';
import { AssessmentContentFullEditor } from '../../features/assessment/assessment-content-full-editor';
import {
  getAssessmentById,
  getDocumentLatestAsync,
  getDocumentsByAssessmentIdAsync
} from '../../services/assessment';
import { GetLanguagesAsync } from '../../services/country';
import { appStore } from '../../stores/app-store';
import { Assessment } from '../../types/assessment';
import { CountryResponse } from '../../types/country-response';

const defaultEntity = (): Assessment => {
  return {
    id: 0,
    name: '',
    title: '',
    organisationId: 1,
    htmlContent: '',
    description: '',
    version: 'v0.0.1',
    status: 'New'
  };
};

const AssessmentReadonly = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [entity, setEntity] = useState<Assessment>();
  const [languages, setLanguages] = useState<CountryResponse[]>([]);
  const [, setSelectedTab] = useState<string>('');

  const [currentLocale, setCurrentLocale] = useState<string>();
  const [, setLatestStatus] = useState<string>('');

  const detailAssessmentBreadcrumb = [
    { label: 'Dashboard', href: '../dashboard' },
    { label: 'Assessment', href: '../mylibrary/assessment' },
    {
      label: 'Assessment Detail',
      href: `../assessment-detail-view/${id}`
    }
  ];

  const getDetails = async () => {
    try {
      appStore.showLoading();

      // combine assessment and assessment documents (resources)
      let assess1 = await getAssessmentById(parseInt(id!));
      let assess2 = await getDocumentsByAssessmentIdAsync(parseInt(id!));

      let assessment = merge(assess1, assess2);

      setEntity(assessment);
    } catch (ex) {
      console.error(ex);
    } finally {
      appStore.hideLoading();
    }
  };

  const setDefaultEntity = () => {
    setEntity(defaultEntity);
  };

  const handleEntityChange = (newEntity: Assessment) => {
    setEntity(newEntity);
  };

  const getLatestDocumentAsync = async () => {
    let document = await getDocumentLatestAsync(parseInt(id!));
    setLatestStatus(document?.status ?? '');
  };

  const getLanguages = async () => {
    const response = await GetLanguagesAsync();
    setLanguages(response.data);
  };

  const toggleLanguageTab = (activeTab: string) => {
    if (activeTab === 'language') {
      setSelectedTab(activeTab);
    } else {
      setSelectedTab('');
    }
  };

  useEffect(() => {
    if (id) {
      getDetails();
      getLatestDocumentAsync();
    } else {
      setDefaultEntity();
    }

    getLanguages();
  }, []);

  return (
    <PrivatePage
      breadcrumb={detailAssessmentBreadcrumb}
      pageTitle={'Assessment'}>
      <>
        <Container>
          {entity && (
            <AssessmentContentFullEditor
              isViewMode={true}
              languages={languages}
              entity={entity}
              currentLocale={currentLocale}
              setCurrentLocale={setCurrentLocale}
              handleEntityChange={handleEntityChange}
              toggleLanguageTab={toggleLanguageTab}
            />
          )}
        </Container>
      </>
    </PrivatePage>
  );
};

export default AssessmentReadonly;
