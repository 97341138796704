import { Outlet } from 'react-router-dom';
import { Spinner } from '../components/spinner';
import { Toast } from '../components/toast';
import { appStore } from '../stores/app-store';
import { toastStore } from '../stores/toast-store';
import { observer } from 'mobx-react';

/**
 * The layout for authentication based pages. I.e. Login and Signup..
 * @returns
 */
const Authentication = observer(() => {
  const getStyle = () => {
    const style = toastStore.getStyle();
    return style ? style : 'none';
  };

  return (
    <>
      <div className={'layout layout--authentication'}>
        <div className={'layout__left'}>
          <Outlet />
          {appStore.isLoading() && <Spinner />}
        </div>
        <div className={'layout__right'}>
          <img src="/cg-logo-stacked.svg" className="logo" />
        </div>
      </div>
      <Toast
        style={getStyle()}
        show={toastStore.isShow()}
        title={toastStore.getTitle()}
      >
        {toastStore.getMessage()}
      </Toast>
    </>
  );
});

export default Authentication;
