import { useEffect, useState } from 'react';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridNoRecords
} from '@progress/kendo-react-grid';
import {
  DataResult,
  State,
  toDataSourceRequestString
} from '@progress/kendo-data-query';
import {
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../components/grid/grid-tool-bar';
import { DefaultGridSettings } from '../constants/grid-settings';
import { NoRecords } from '../components/grid/no-records';
import { ActionCell } from '../components/grid/actions-cell';
import { appStore } from '../stores/app-store';
import { GetEnvironmentAsync } from '../services/environments';
import Toolbar from '../components/page-toolbar';

const initialDataState: State = {
  sort: [{ field: 'name', dir: 'asc' }],
  filter: DefaultGridSettings.initialDataState.filter,
  skip: DefaultGridSettings.initialDataState.skip,
  group: DefaultGridSettings.initialDataState.group
};

const ActiveEventsGrid = () => {
  const [dataState, setDataState] = useState<State>({
    ...initialDataState,
    take: DefaultGridSettings.initialDataState.take
  });
  const [environments, setEnvironments] = useState<DataResult>(() => ({
    data: [],
    total: 0
  }));
  const [gridMode, setGridMode] = useState<string>('grid');

  const fetchEnvironments = async () => {
    try {
      appStore.showLoading();
      const response = await GetEnvironmentAsync(
        `${toDataSourceRequestString(dataState)}`
      );
      setEnvironments(response);
    } catch (ex) {
      console.error(ex);
    } finally {
      appStore.hideLoading();
    }
  };

  useEffect(() => {
    fetchEnvironments();
    //eslint-disable-next-line
  }, [dataState]);

  const gridActionsColumn = (props: GridCellProps) => {
    return <AddCustomAction {...props} />;
  };

  const AddCustomAction = (props: GridCellProps) => {
    return (
      <>
        <ActionCell
          title={'Open in new tab'}
          icon={'open_in_new'}
          themeColor={'primary'}
          className={'fs-2'}
          onClick={() =>
            window.open(`${props.dataItem.hostUrl}/admin/`, '_blank')
          }
        />
      </>
    );
  };

  return (
    <>
      <Toolbar title={'Active events'}></Toolbar>
      <div className={'pt-2'}>
        <GridToolBar
          searchPlaceholder={'Search environments'}
          columnsToSearch={['name']}
          showCalendarMode={false}
          showCardMode={false}
          onGridModeChange={(e) => {
            const newState = {
              ...initialDataState
            };
            if (e.gridMode === 'grid') {
              newState.take = DefaultGridSettings.initialDataState.take;
            }
            setDataState(newState);
            setGridMode(e.gridMode);
          }}
          {...dataState}
          onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
            setDataState(e.dataState);
          }}></GridToolBar>
        {gridMode === 'grid' && (
          <Grid
            id={'grid-active-events'}
            pageable={DefaultGridSettings.pagerSettings}
            sortable={true}
            data={environments}
            {...dataState}
            onDataStateChange={(e: GridDataStateChangeEvent) => {
              setDataState(e.dataState);
            }}>
            <Column field={'name'} title={'Name'} />
            <Column field={'region'} title={'Region'} />
            <Column field={'hostUrl'} title={'Host URL'} />
            <Column title={'Actions'} cell={gridActionsColumn} width={110} />
            <GridNoRecords>
              <NoRecords />
            </GridNoRecords>
          </Grid>
        )}
      </div>
    </>
  );
};
export default ActiveEventsGrid;
