import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Button } from '@progress/kendo-react-buttons';
import { Window } from '@progress/kendo-react-dialogs';
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab
} from '@progress/kendo-react-layout';
import { EditorUtils } from '@progress/kendo-react-editor';
import { Media } from '../../types/media';
import { Col, Row } from 'react-bootstrap';
import { insertMediaFiles } from './insert-image-plugin';
import { Error } from '@progress/kendo-react-labels';
import { capitalize } from 'lodash';
import { GameDocumentContext } from '../../contexts/game-document';
import { Checkbox } from '@progress/kendo-react-inputs';

const fileSize = {
  image: 512000, // 500KB
  audio: 5242880, // 5MB
  video: 20971520 // 20MB
};

const fileSizeDesc = {
  image: '500KB',
  audio: '5MB',
  video: '20MB'
};

const fileType = {
  image: 'image/svg, image/jpeg, image/jpg, image/png, image/gif, image/bmp',
  video: 'video/webm, video/mov, video/mpeg, video/mp4, video/avi',
  audio: 'audio/webm, audio/wav, audio/ogg, audio/mp3'
};

export const InsertMediaDialog = (props: any) => {
  const [game] = React.useContext(GameDocumentContext);
  const [selected, setSelected] = React.useState(0);
  const [files, setFiles] = React.useState<Media[]>([]);
  const [src, setSrc] = React.useState<string>();
  const fileUpload = React.useRef<HTMLInputElement>(null);
  const [isLargeMediaSize, setIsLargeMediaSize] =
    React.useState<boolean>(false);
  const [isSaving, setIsSaving] = React.useState<boolean>(false);

  const toggleIsSaving = () => setIsSaving((prev) => !prev);

  let altText: any;
  let title: any;
  let width: any;
  let height: any;
  const [fitToScreen, setFitToScreen] = React.useState<boolean>(false);

  const onTabSelect = (event: TabStripSelectEventArguments) => {
    setSelected(event.selected);
  };

  const onClose = () => {
    props.onClose.call(undefined);
  };

  const onUploadPicture = (fls: FileList | null) => {
    if (fls) {
      // check image max size for Game Design | Assets - Task Content - HTML
      const maxImageSizeInByte = fileSize[mediaType as keyof typeof fileSize]; // 500 KB for image, 2MB for video and audio

      if (fls[0].size > maxImageSizeInByte) {
        setIsLargeMediaSize(true);
        return;
      }

      setIsLargeMediaSize(false);
      setFiles([{ file: fls[0] }]);
    }
  };

  const onInsert = (currentSrc: string | null) => {
    if (game) {
      const gameId = game.gameId;
      const currentVersion = game.gameDocument?.version;
      const { view, mediaType } = props;
      const nodes = view.state.schema.nodes;
      const nodeType = nodes[mediaType];
      const position = null;
      const data = {
        src: src ? src : currentSrc,
        title: title ? title.value : null,
        alt: altText ? altText.value : null,
        width: width && !fitToScreen ? width.value : null,
        height: height && !fitToScreen ? height.value : null,
        ...(mediaType === 'video' && {
          fitToScreen
        })
      } as any;

      const attrs = Object.keys(data)
        .filter((key) => data[key] !== null && data[key] !== '')
        .reduce((acc, curr) => Object.assign(acc, { [curr]: data[curr] }), {});

      if (files.length && !selected) {
        toggleIsSaving();
        insertMediaFiles({
          view,
          files,
          nodeType,
          position,
          gameId,
          version: currentVersion,
          toggleSaving: toggleIsSaving,
          onClose,
          attrs
        });
      } else {
        const newMedia = nodeType.createAndFill(attrs);
        EditorUtils.insertNode(view, newMedia, true);
        view.focus();
        onClose();
      }
    }
  };

  const { view, mediaType } = props;
  const state = view && view.state;

  let attrs: any = {};

  if (
    state &&
    state.selection.node &&
    state.selection.node.type === state.schema.nodes[mediaType]
  ) {
    attrs = state.selection.node.attrs;
  }

  const fields = (
    <React.Fragment>
      <div className="k-edit-label">
        <label htmlFor="k-editor-image-alt">Alternate text</label>
      </div>
      <div className="k-edit-field">
        <input
          type="text"
          className="k-textbox"
          id="k-editor-image-alt"
          defaultValue={attrs.alt}
          ref={(e) => (altText = e)}
        />
      </div>
      <div className="k-edit-label">
        <label htmlFor="k-editor-image-title">Title</label>
      </div>
      <div className="k-edit-field">
        <input
          type="text"
          className="k-textbox"
          id="k-editor-image-title"
          defaultValue={attrs.title}
          ref={(e) => (title = e)}
        />
      </div>
      {props.resizeMedia && (
        <div>
          {mediaType === 'video' && (
            <>
              <div className="k-edit-label">Fit to screen</div>
              <div className="k-edit-field d-flex align-items-start mt-1">
                <Checkbox
                  name={'checkbox-use-default'}
                  // value={fitToScreen}
                  // defaultValue={fitToScreen}
                  onChange={(e) => setFitToScreen(e.value)}
                  // ref={(e) => (fitToScreen = e)}
                />
              </div>
            </>
          )}
          <div className="k-edit-label">
            <label htmlFor="k-editor-image-width">Width (px)</label>
          </div>
          <div className="k-edit-field">
            <input
              disabled={fitToScreen}
              type="text"
              className="k-textbox"
              id="k-editor-image-width"
              defaultValue={attrs.width}
              ref={(e) => (width = e)}
            />
          </div>
          <div className="k-edit-label">
            <label htmlFor="k-editor-image-height">Height (px)</label>
          </div>
          <div className="k-edit-field">
            <input
              disabled={fitToScreen}
              type="text"
              className="k-textbox"
              id="k-editor-image-height"
              defaultValue={attrs.height}
              ref={(e) => (height = e)}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );

  const buttons = (
    <Row className="mt-3">
      <Col md="12" className="d-flex justify-content-end align-items-center">
        <Button
          themeColor={'secondary'}
          className={'w-5 text-light'}
          disabled={isSaving}
          onClick={onClose}>
          Cancel
        </Button>

        <Button
          themeColor={'primary'}
          className={'text-light ml-2'}
          disabled={
            (selected === 0 ? files.length === 0 : !Boolean(src)) || isSaving
          }
          onClick={() => onInsert(attrs.src ? attrs.src : null)}>
          {isSaving && (
            <span
              className={`k-icon k-i-loading mr-2`}
              style={{
                fontSize: '20px',
                color: 'white'
              }}
            />
          )}
          {isSaving ? 'Saving' : 'Save'}
        </Button>
      </Col>
    </Row>
  );

  const onUploadClick = () => {
    if (fileUpload && fileUpload.current) {
      fileUpload.current.click();
    }
  };

  const handledelete = (file: Media) => {
    if (files) {
      const fls: Media[] = [...files];

      const index = fls.indexOf(file);
      if (index > -1) {
        fls.splice(index, 1);
      }
      setFiles(fls);
      setIsLargeMediaSize(false);
    }
  };

  return (
    <>
      {ReactDOM.createPortal(
        <Window
          title={`Insert ${capitalize(mediaType)}`}
          onClose={onClose}
          initialWidth={500}
          initialHeight={props.resizeImage ? 540 : 420}>
          <TabStrip
            selected={selected}
            onSelect={onTabSelect}
            animation={false}>
            {Object.entries(attrs).length === 0 && (
              <TabStripTab title="Upload">
                <div className="k-edit-form-container pt-3 pb-3">
                  <div className="k-edit-label">
                    <label htmlFor="k-editor-image-width">
                      {capitalize(mediaType)}
                    </label>
                  </div>
                  <div className="k-edit-field">
                    <div className={'col-md-6'}>
                      <Button
                        className={'w-100 upload-button mt-2 mb-2'}
                        onClick={onUploadClick}>
                        <div
                          className={
                            'd-flex justify-content-center align-items-center gap-3'
                          }>
                          <span
                            className={
                              'material-symbols-outlined mr-2 text-success'
                            }
                            role={'button'}>
                            {(() => {
                              switch (mediaType) {
                                case 'image':
                                  return 'add_photo_alternate';
                                case 'video':
                                  return 'video_file';
                                case 'audio':
                                  return 'audio_file';
                              }
                            })()}
                          </span>
                          <span>Upload</span>
                        </div>
                      </Button>
                      <input
                        type={'file'}
                        id={'file-upload'}
                        ref={fileUpload}
                        multiple={false}
                        accept={fileType[mediaType as keyof typeof fileType]}
                        className={'d-none'}
                        onChange={(e) => onUploadPicture(e.target.files)}
                        onClick={(event) => {
                          event.currentTarget.value = '';
                        }}
                      />
                      {isLargeMediaSize && (
                        <Error>{`Please reduce your ${mediaType} size below ${
                          fileSizeDesc[mediaType as keyof typeof fileSizeDesc]
                        }`}</Error>
                      )}
                    </div>
                    <div className={'col-12'}>
                      <ul className={'list-group w-100'}>
                        {files &&
                          files.map((file, i) => {
                            return (
                              <li
                                className={'list-group-item rounded-0'}
                                key={i}>
                                <div
                                  className={'d-flex justify-content-between'}>
                                  <div className={'d-flex'}>
                                    <span
                                      className={
                                        'material-symbols-outlined mr-2 p-2'
                                      }>
                                      add_photo_alternate
                                    </span>
                                    <span className={'p-2 text-break'}>
                                      {file.file.name}
                                    </span>
                                  </div>
                                  <span
                                    className={
                                      'material-symbols-outlined mr-2 p-2'
                                    }
                                    role={'button'}
                                    onClick={() => handledelete(file)}>
                                    close
                                  </span>
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                  {fields}
                </div>
              </TabStripTab>
            )}
            <TabStripTab title="By URL">
              <div className="k-edit-form-container pt-3 pb-3">
                <div className="k-edit-label">
                  <label htmlFor="k-editor-image-url">Web address</label>
                </div>
                <div className="k-edit-field">
                  <input
                    type="text"
                    className="k-textbox"
                    id="k-editor-image-url"
                    defaultValue={attrs.src}
                    value={src}
                    disabled={new RegExp(`/^data:${mediaType}/`).test(
                      attrs.src || ''
                    )}
                    autoFocus={true}
                    onChange={(e) => setSrc(e.target.value)}
                  />
                </div>
                {fields}
              </div>
            </TabStripTab>
          </TabStrip>
          {buttons}
          <style>{`.k-dropzone { width: 100%; }`}</style>
        </Window>,
        document.body,
        'insertImageDialog'
      )}
    </>
  );
};
