import { Col, Container, Row } from 'react-bootstrap';

export const PrivacyPolicy = () => {
  return (
    <Container>
      <Row>
        <Col>
          <h1>Privacy Policy</h1>
          <p className="lead">
            Go Team is owned and operated by Catalyst Global Limited. This
            Privacy Policy describes Catalyst Global's policies and procedures
            on the collection, use and disclosure of your information when you
            use the Go Team website, application and services offered by
            Catalyst Global and its licensees and tells you about your privacy
            rights. This information should be read in conjunction with the{' '}
            <a
              href="https://www.catalystglobal.com/privacy-policy/"
              target="_blank"
            >
              Catalyst Global Privacy Policy
            </a>
            .
          </p>
          <h2>1. WHO WE ARE</h2>
          <p>
            Catalyst Global Limited is the Controller (for the purposes of the
            GDPR) of your Personal Data (referred to as “Catalyst Global”, “Go
            Team”, “we”, “us” or “our” in this Privacy Policy).
          </p>
          <p>
            At Catalyst Global we have a culture of privacy. We believe that
            privacy is a fundamental human right. At Catalyst we take privacy
            seriously.
          </p>
          <h3>HOW TO CONTACT US</h3>
          <p>
            For any request, question, comment or action you have in connection
            to this Privacy Policy, please contact us at info@goteam.guru or
            info@catalystglobal.com
          </p>
          <h2>2. PERSONAL INFORMATION WE COLLECT AND HOW WE COLLECT IT</h2>
          <p>
            Catalyst Global collects personally identifiable information about
            you that you specifically and voluntarily provide. The information
            we collect from you allows us to personalise and improve the
            Catalyst Global experience. We use the information to handle orders,
            deliver products and services, process payments, communicate with
            you about orders, products, services and promotional offers, update
            our records and generally maintain your accounts with us, display
            personalisation content, customer reviews and recommend services
            that might be of interest to you. We also use this information to
            improve our site, prevent or detect fraud or abuses of our website
            and enable third parties to carry out technical, logistical or other
            functions on our behalf.
          </p>
          <h3>INFORMATION PROVIDED BY YOU</h3>
          <p>
            We only collect personally identifiable information that you choose
            to provide. You can choose not to provide certain information. Where
            possible, we will enable you to select how we will use this
            information. You may choose to provide us with personally
            identifiable information if, for example, you: contact us with an
            enquiry; fill out a survey or other form with your personally
            identifiable information included; or, request us to provide you
            with information. By providing any personally identifiable
            information to us, you fully understand and clearly consent to the
            transfer of such personally identifiable information to, and the
            collection and processing of such information in, other countries or
            territories. Any such transfer and processing by us will be in
            accordance with our gneral privacy policy.
          </p>
          <h2>3. PERSONAL DATA COLLECTED IN GO TEAM ACTIVITIES</h2>
          <h3>GO TEAM ACTIVITIES, PLAYERS AND TEAMS</h3>
          <p>
            Catalyst Global allows its licensees to create activity templates
            for its clients. Participants are individual players or teams.
            Participants are not required to have a registration or subscription
            and do not need to provide any personal information to play an
            activity. To participate in an activity Participants need to provide
            a name for the entity (team or person) which can be real or
            fictional. This provided name is used only within the specific game
            and is not used elsewhere.
          </p>
          <h3>ANSWERS TO CHALLENGES AND TASKS</h3>
          <p>
            We advise our licensees against asking for any kind of personal
            information within an activity. It is possible,however, that a
            licensees has built the game in a way that asks for personal
            information from the Participants. It is also possible that
            Participants will need to take photos or videos about themselves,
            other players or their teams during the activity. Answers to
            activity challenges, either in the form of text, photos and/or
            videos, are stored on the relevant licensee's server.
          </p>
          <h2>4. STORAGE OF DATA COLLECTED</h2>
          <p>
            Catalyst Global Limited relies on third party providers (
            <a href="https://help.rescuetime.com/article/122-what-are-your-sub-processors-as-defined-by-the-gdpr">
              sub-processors
            </a>
            ) to store and process your data. Here is a list of some of the
            sub-processors that we use and the service they provide:
          </p>
          <ul>
            <li>
              Amazon AWS - USA Cloud Infrastructure for our apps and services
            </li>
            <li>Mailchimp - USA Customer interactions</li>
            <li>SurveyMonkey - USA Customer feedback</li>
            <li>
              Google, Inc - USA Site analytics (web) and customer interactions
              (email)
            </li>
          </ul>
          <p></p>
          <h3>HOW LONG WE STORE YOUR PERSONAL DATA</h3>
          <p>
            Information and media gathered during your event is held for a
            period of 28 days to give you time to request answers and media.
            After 28 days the data is archived and stored in a limited function
            account (the "archive period"). Catalyst Global will delete the data
            including any cached or backedup copies, 28 days after the start of
            the archive period. You have the right to request the data is
            removed at any time after the event. Please contact the licensee
            organisation who you booked your activity with. Alternatively,
            please contact Catalyst Global directly at info@catalystglobal.com
            specifying the exact information that should be removed.
          </p>
          <h2>5. PROTECTING YOUR PERSONAL DATA</h2>
          <p>
            Your personal information is important to us. Please read the
            Catalyst Global General Privacy Policy. We are committed to
            providing you with a secure environment during storage and
            transmission of your personally identifiable information. We have
            created appropriate physical, electronic and procedural safeguards
            to collect, store and disclose your personally identifiable
            information. Although Catalyst Global (and advise our licensees to
            do accordingly) has put in place stringent physical, managerial and
            technical safeguards to protect personal data we cannot, however,
            ensure or warrant the security of any information you provide or
            guarantee that your information on the Services may not be accessed,
          </p>
          <h2>6. YOUR RIGHTS RELATED TO YOUR PERSONAL DATA</h2>
          <p>You have the right by law to request :</p>
          <ul>
            <li>Access to your Personal Data.</li>
            <li>Information about the way your Personal Data is processed.</li>
            <li>Amendments to any of Personal Data held by us.</li>
            <li>
              We stop using your Personal Data for the purposes of marketing.
            </li>
            <li>We delete some or all of your Personal Data.</li>
          </ul>
          <p></p>
          <h3>EXERCISING YOUR RIGHTS</h3>
          <p>
            To exercise any of the rights described above, please email
            info@goteam.guru or info@catalystglobal.com.
          </p>
          <h2>CHANGES TO OUR PRIVACY POLICY</h2>
          <p>
            We reserve the right, in our sole discretion, to change, modify,
            add, or remove portions of this Privacy Policy at any time. Any
            changes or updates will be effective immediately upon posting to
            this page. Accordingly, we recommend that you review this Privacy
            Policy regularly.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicy;
