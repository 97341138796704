import CardItem from './card-item';
import { GetPendingGamesAsync } from '../../../services/games';
import { getAssessmentsPendingApproval } from '../../../services/assessment';
import { getFeedbackPendingApprovalList } from '../../../services/feedback';

const ApprovalRequestCard = () => {
  const approval = [
    {
      text: 'Games pending approval',
      url: '/administration/globallibraries/pending',
      fetcher: GetPendingGamesAsync(`pageSize=1`)
    },
    {
      text: 'Feedback pending approval',
      url: '/administration/feedbacks/pending',
      fetcher: getFeedbackPendingApprovalList(`pageSize=1`)
    },
    {
      text: 'Assessment pending approval',
      url: '/administration/assessments/pending',
      fetcher: getAssessmentsPendingApproval(`pageSize=1`)
    }
  ];

  return (
    <div className={'d-flex flex-column py-2 w-100 h-100 gap-1'}>
      <span className={`material-symbols-outlined`}>empty_dashboard</span>
      <div
        className={
          'title d-flex justify-content-between align-items-center fw-bold'
        }>
        <span>Approval Request</span>
        <span className={`material-symbols-outlined fw-bold text-primary`}>
          chevron_right
        </span>
      </div>
      <hr className={'my-2 w-100'} />
      <div className={'flex-grow-1'}>
        {approval.map((item, idx) => (
          <CardItem
            key={idx}
            goTo={item.url}
            text={item.text}
            isLastIndex={idx === approval.length - 1}
            // @ts-ignore
            fetcher={item.fetcher}
          />
        ))}
      </div>
      <hr className={'my-2 w-100'} />
      {/* <Button themeColor={'primary'}>View all</Button> */}
      <div
        style={{
          height: '38px'
        }}
      />
    </div>
  );
};

export default ApprovalRequestCard;
