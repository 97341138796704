import { TimeZoneResponse } from '../types/responses/timezone-response';
import { GetAllAsync } from './api';

const TimeZoneEndpoint = `timezones`;

/**
 * Get all timezones
 * @returns all timezone objects.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetTimeZonesAsync = async () =>
  GetAllAsync<TimeZoneResponse>(TimeZoneEndpoint);
