import cloneDeep from 'lodash.clonedeep';
import { MergeAssets } from '.';
import { GameDocument, MapImage } from '../../../types/game-document';

/**
 * Get all map images by map ID.
 * @param gameDocument - The Game Document
 * @param mapId - The ID of the Map to get the Map Image Entity
 * @constructor
 */
export const GetAssetMapImagesByMapId = (
  gameDocument: GameDocument,
  mapId: string
) => {
  return (
    gameDocument?.assets?.mapImages?.filter((item) => item.mapId === mapId) ||
    []
  );
};

/**
 * Get detail Map Image by Map Image ID and map ID.
 * @param gameDocument - The Game Document
 * @param mapId - The ID of the Map to get the Map Image Entity
 * @param mapImageId - The ID of the Map Image to get the Map Image Entity
 * @constructor
 */
export const GetDetailMapImageByMapImageId = async (
  gameDocument: GameDocument,
  mapImageId: string
) => {
  return await gameDocument.assets.mapImages?.find(
    (item) => item.id === mapImageId
  );
};

/**
 * Updates the identified Map in the Game Document.
 * @param gameDocument - The Game Document to modify
 * @param mapImage - The updated mapImage
 * @constructor
 */
export const UpdateMapImagesAsync = async (
  gameDocument: GameDocument,
  mapImage: MapImage
) => {
  let mapImages: MapImage[] = gameDocument.assets?.mapImages ?? [];
  let mapImagesIndex = mapImages.findIndex(
    (i) => i.id === mapImage.id && i.mapId === mapImage.mapId
  )!;
  mapImagesIndex >= 0
    ? (mapImages[mapImagesIndex] = mapImage)
    : mapImages.push(mapImage);
  return await MergeAssets(gameDocument, mapImages, 'mapImages');
};

/**
 * Updates the identified Map in the Game Document.
 * @param gameDocument - The Game Document to modify
 * @param mapImage - The updated mapImage
 * @constructor
 */
export const RemoveMapImageAsync = async (
  gameDocument: GameDocument,
  mapImage: MapImage
) => {
  let gamedoc = cloneDeep(gameDocument);
  gamedoc.assets.mapImages = gamedoc.assets.mapImages?.filter(
    (item) => item.id !== mapImage.id
  );

  return gamedoc;
};
