import React, { useEffect } from 'react';
import {
  Dialog as KendoDialog,
  DialogProps as KendoDialogProps
} from '@progress/kendo-react-dialogs';

export interface DialogProps extends KendoDialogProps {}

export function Dialog(props: DialogProps) {
  const getClassName = () => {
    let className = '';
    if (props.className) {
      className = `${className} ${props.className}`;
    }
    return className;
  };

  const handleDismiss = () => {
    const elements = document.getElementsByClassName('k-overlay');
    if (elements && elements[0]) {
      const overlay = elements[0] as HTMLDivElement;
      if (props.onClose) {
        overlay.addEventListener('click', () => {
          if (props.onClose) {
            props.onClose({} as any);
          }
        });
      }
    }
  };

  useEffect(() => {
    handleDismiss();
  }, []);

  return (
    <KendoDialog
      style={{ zIndex: 100 }}
      width={props.width}
      minWidth={'350px'}
      className={getClassName()}
      {...props}>
      {props.children}
    </KendoDialog>
  );
}

export default Dialog;
