import React, { useRef, useEffect } from 'react';

export interface ActionCellProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  text: string;
  url: string | undefined;
  type: string | undefined;
  className?: string;
}

export const CellWithThumbnail = ({
  text,
  url = '',
  className,
  type,
  ...props
}: ActionCellProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    videoRef.current?.load();
  }, [url, videoRef.current]);

  return (
    <td>
      <div className={'d-flex justify-content-start align-items-center'}>
        {url && url !== '' && (
          <div className={'cell-thumbnail-container mr-2'}>
            {type === 'video' ? (
              <video ref={videoRef} className={'rounded w-100 h-100'}>
                <source src={`${url}`} type={'video/mp4'} />
              </video>
            ) : type === 'image' ? (
              <img className={'w-100 h-100'} src={url} alt="" />
            ) : type === 'audio' ? (
              <span
                style={{ fontSize: '36px' }}
                className="material-symbols-outlined">
                music_note
              </span>
            ) : (
              <></>
            )}
          </div>
        )}
        <span>{text}</span>
      </div>
    </td>
  );
};
