import React from 'react';
import { ReactComponent as Logo } from '../assets/cg-icon-globe.svg';

export function Spinner() {
  return (
    <div className="overlay w-100 h-100 position-absolute d-flex justify-content-center align-items-center">
      <Logo className={'spin'} style={{ width: 100 }} role="status" />
    </div>
  );
}
