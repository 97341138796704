import { TabStripSelectEventArguments } from '@progress/kendo-react-layout';
import React, { useCallback, useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { GameDocumentState } from '../../contexts/game-document';
import {
  GetGameDocumentContentAsync,
  GetGameDocumentLatestAsync
} from '../../services/json-document';
import { appStore } from '../../stores/app-store';
import { GameDocument } from '../../types/game-document';
import { UpdateGameDocState } from '../../utils/game-document';
import { InGameSetting, PreGameSetting } from '../settings';
import { TabContentCG } from '../tabs';
import Dialog, { DialogProps } from './dialog';
import { Button } from '@progress/kendo-react-buttons';

interface GameSettingsDialogProps extends DialogProps {
  gameId: number;
  onConfirm: (gameDocument: GameDocument) => void;
  onCancel: () => void;
}

const GameSettingsDialog = (props: GameSettingsDialogProps) => {
  const { gameId, onConfirm, onCancel } = props;
  const [state, setState] = useState<GameDocumentState>({
    isLoaded: false,
    isDirty: false,
    isValid: true
  });
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const onChangeHandler = useCallback(
    (updatedGameDocument: GameDocument) => {
      setState((state) => UpdateGameDocState(state!, updatedGameDocument));
    },
    [setState]
  );

  const handleConfirm = () => {
    onConfirm(state.gameDocument!);
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleTabSelect = (e: TabStripSelectEventArguments) => {
    setSelectedTab(e.selected);
  };

  useEffect(() => {
    async function GetGameDocuments(gameId: number) {
      const gameDocumentLatest = await GetGameDocumentLatestAsync(gameId);
      const gameDocumentContent = await GetGameDocumentContentAsync(
        gameId,
        gameDocumentLatest.id!
      );

      setState((state) => ({
        ...state,
        isLoaded: true,
        gameDocument: gameDocumentContent!
      }));
    }
    if (gameId > 0) {
      try {
        appStore.showLoading();
        GetGameDocuments(gameId);
      } catch (err) {
        console.error(err);
      } finally {
        appStore.hideLoading();
      }
    }
  }, [gameId]);

  return (
    <Dialog width={'60%'} className="" {...props}>
      <Container fluid>
        <TabContentCG
          selected={selectedTab}
          handleSelect={handleTabSelect}
          items={[
            {
              title: 'Pre-Game',
              content: (
                <PreGameSetting state={state} onChange={onChangeHandler} />
              )
            },
            {
              title: 'In-Game',
              content: (
                <InGameSetting state={state} onChange={onChangeHandler} />
              )
            },
            {
              title: 'Post-Game'
            }
          ]}
        />
        <Row>
          <div className="col-12 d-flex flex-row-reverse gap-3 mt-3">
            <Button
              themeColor={'secondary'}
              className="w-10"
              onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              themeColor={'primary'}
              className="w-10"
              onClick={handleConfirm}>
              Confirm
            </Button>
          </div>
        </Row>
      </Container>
    </Dialog>
  );
};

export default GameSettingsDialog;
