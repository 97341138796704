import { GameDocumentContext } from '../../contexts/game-document';
import { useContext, useEffect } from 'react';
import Toolbar from './toolbar';

const GameAnnouncements = () => {
  const [state] = useContext(GameDocumentContext);

  useEffect(() => {
    // Set the page title.
    document.title = `Announcements - ${state.gameDocument?.name}`;
  }, [state]);

  return (
    <div>
      <Toolbar title={'Announcements'}></Toolbar>
      Announcements for game {state.gameId}.
    </div>
  );
};

export default GameAnnouncements;
