import { Label } from '@progress/kendo-react-labels';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

type ColViewProps = {
  className?: string;
  colSpec?:
    | number
    | '1'
    | '2'
    | '3'
    | '4'
    | '5'
    | '6'
    | '7'
    | '8'
    | '9'
    | '10'
    | '11'
    | '12';
  labelClassName?: string;
  label?: string;
  value?: string;
  link?: string;
};

export const ColView = ({ link, ...props }: ColViewProps) => {
  return (
    <Col md={props?.colSpec} className={props?.className}>
      <small className={props?.labelClassName}>{props?.label}</small>
      <Label>
        {link ? <Link to={`../${link}`}>{props?.value}</Link> : props?.value}
      </Label>
    </Col>
  );
};
