import { GameDocument } from '../../../types/game-document';
import {
  EntityEditor,
  ResourceEntity,
  TaskEntity
} from '../../../types/game-document/';
import { uuid } from '../../../types/common-helper';
import { GetNextAssetNameAsync, MergeAssets } from './index';
import {
  CopyResourceAsync,
  DeleteResourceAsync,
  SaveResourceAsync
} from '../resources';
import cloneDeep from 'lodash.clonedeep';
import merge from 'lodash.merge';
import { AlgorithmControlStructure } from '../../../types/algorithm';
import { randomNumberInRange } from '../../number';
import { AddActionToTask } from '../settings';

/**
 * Adds a new Task to the Game document.
 * @param gameDocument - The Game Document to modify
 * @param name - The Name of the new Task
 * @param description - The Description for the new Task
 * @returns The updated Game Document
 */
export const AddTaskAsync = async (
  gameDocument: GameDocument,
  name: string,
  description: string,
  titleResId: string,
  imageResId: string
) => {
  let tasks = gameDocument.assets.tasks ?? [];
  let taskName = await GetNextAssetNameAsync(tasks, name);
  tasks.push({
    id: uuid(),
    name: taskName,
    description,
    titleResId,
    imageResId
  });
  return MergeAssets(gameDocument, tasks, 'tasks');
};

/**
 * Add task.
 * @param gameDocument - The Game Document to modify
 * @param tasks - The new tasks
 * @return The updated Game Document
 */
export const AddTaskEntityAsync = async (
  gameDocument: GameDocument,
  task: TaskEntity
) => {
  let tasks = gameDocument.assets.tasks ?? [];
  let taskName = await GetNextAssetNameAsync(tasks, task?.name);
  task.name = taskName;
  tasks.push(task);
  return MergeAssets(gameDocument, tasks, 'tasks');
};

/**
 * Deletes the identified Task from the Game Document.
 * @param gameDocument - The Game Document to modify
 * @param taskId - The ID of the Task to delete
 * @returns The updated Game Document
 */
export const DeleteTaskAsync = async (
  gameDocument: GameDocument,
  taskId: string
) => {
  let tasks = gameDocument.assets.tasks ?? [];
  let taskIndex = tasks.findIndex((i) => i.id === taskId)!;

  if (taskIndex !== -1) {
    await DeleteResourceAsync(gameDocument, tasks[taskIndex].titleResId!);
    await DeleteResourceAsync(gameDocument, tasks[taskIndex].imageResId!);
    tasks.splice(taskIndex, 1);
  }
  return MergeAssets(gameDocument, tasks, 'tasks');
};

/**
 * Remove boundary from task
 * @param gameDocument
 * @param taskId
 * @returns
 */
export const RemoveTaskBoundaryAsync = async (
  gameDocument: GameDocument,
  taskId: string
) => {
  let tasks = gameDocument.assets.tasks ?? [];
  let taskIndex = tasks.findIndex((i) => i.id === taskId)!;

  if (taskIndex > -1) {
    delete tasks[taskIndex].boundary;
    return MergeAssets(gameDocument, tasks, 'tasks');
  }
  return gameDocument;
};

/**
 * Updates the identified Task in the Game Document.
 * @param gameDocument - The Game Document to modify
 * @param taskId - The ID of the Task to update
 * @param task - The updated Task
 * @param renameDuplicate - Auto rename 'name' key if duplicate
 * @constructor
 */
export const UpdateTaskAsync = async (
  gameDocument: GameDocument,
  taskId: string,
  task: TaskEntity,
  renameDuplicate: boolean = true
) => {
  let tasks = gameDocument.assets.tasks ?? [];
  let taskIndex = tasks.findIndex((i) => i.id === taskId)!;
  if (renameDuplicate)
    task.name = await GetNextAssetNameAsync(tasks, task.name, task.id);
  tasks[taskIndex] = task;
  return MergeAssets(gameDocument, tasks, 'tasks');
};

/**
 * Create a copy of the Task in the Game document.
 * @param gameDocument - The Game Document to modify
 * @param taskId - The ID of the Task to copy
 * @returns The updated Game Document
 */
export const CopyTaskAsync = async (
  gameDocument: GameDocument,
  taskId: string,
  copiedTaskId: string = uuid()
) => {
  let tasks = gameDocument.assets.tasks ?? [];
  let taskIndex = tasks.findIndex((i) => i.id === taskId)!;

  if (taskIndex !== -1) {
    let taskCopy: TaskEntity = cloneDeep({
      ...tasks[taskIndex],
      titleResId: uuid(),
      imageResId: uuid(),
      completeImageResId: uuid()
    });

    if (taskCopy.boundary) {
      taskCopy.boundary.id = uuid();
      if (taskCopy.boundary.geometry) {
        taskCopy.boundary.geometry.coordinates[0] += randomNumberInRange(1, 5);
        taskCopy.boundary.geometry.coordinates[1] += randomNumberInRange(1, 5);
      }
    }

    taskCopy?.events?.onOpenTask?.steps?.forEach((item) => {
      let algorithmStructure = item as AlgorithmControlStructure;
      if (algorithmStructure.ifSteps) {
        algorithmStructure?.ifSteps?.forEach((ifItem) => {
          ifItem.id = uuid();
        });
        algorithmStructure?.elseSteps?.forEach((elseItem) => {
          elseItem.id = uuid();
        });
      } else {
        item.id = uuid();
      }
    });

    taskCopy?.events?.onCompleteTask?.steps?.forEach((item) => {
      let algorithmStructure = item as AlgorithmControlStructure;
      if (algorithmStructure.ifSteps) {
        algorithmStructure?.ifSteps?.forEach((ifItem) => {
          ifItem.id = uuid();
        });
        algorithmStructure?.elseSteps?.forEach((elseItem) => {
          elseItem.id = uuid();
        });
      } else {
        item.id = uuid();
      }
    });

    taskCopy?.events?.onCloseTask?.steps?.forEach((item) => {
      let algorithmStructure = item as AlgorithmControlStructure;
      if (algorithmStructure.ifSteps) {
        algorithmStructure?.ifSteps?.forEach((ifItem) => {
          ifItem.id = uuid();
        });
        algorithmStructure?.elseSteps?.forEach((elseItem) => {
          elseItem.id = uuid();
        });
      } else {
        item.id = uuid();
      }
    });

    await CopyResourceAsync(
      gameDocument,
      tasks[taskIndex].titleResId!,
      taskCopy.titleResId,
      false
    );

    await CopyResourceAsync(
      gameDocument,
      tasks[taskIndex].imageResId!,
      taskCopy.imageResId
    );

    await CopyResourceAsync(
      gameDocument,
      tasks[taskIndex].completeImageResId!,
      taskCopy.completeImageResId
    );

    taskCopy.id = copiedTaskId;
    taskCopy.name += '-copy';
    taskCopy.name = await GetNextAssetNameAsync(
      tasks,
      taskCopy.name,
      taskCopy.id
    );
    tasks.push(taskCopy);
  }
  return MergeAssets(gameDocument, tasks, 'tasks');
};
/**
 * Get all tasks from game document local storage.
 * @param gameDocument - The Game Document as datasource
 * @constructor
 */
export const GetTasks = (gameDocument: GameDocument | undefined) => {
  return gameDocument?.assets?.tasks ?? [];
};

/**
 * Get all tasks from game document local storage.
 * @param gameDocument - The Game Document as datasource
 * @constructor
 */
export const GetTasksHasBoundary = (gameDocument: GameDocument | undefined) => {
  return (
    gameDocument?.assets?.tasks?.filter((x) => x.boundary !== undefined) ?? []
  );
};

/**
 * Get all tasks that has no boundary from game document local storage that has.
 * @param gameDocument - The Game Document as datasource
 * @constructor
 */
export const GetTasksHasNoBoundary = (
  gameDocument: GameDocument | undefined
) => {
  return (
    gameDocument?.assets?.tasks?.filter((x) => x.boundary === undefined) ?? []
  );
};

/**
 * Get tasks by Zone Id from game document local storage.
 * @param gameDocument - The Game Document as datasource
 * @constructor
 */
export const GetTasksByZoneId = (
  zoneId: string,
  gameDocument: GameDocument | undefined
) => {
  let tasks: TaskEntity[] = [];

  if (gameDocument) {
    let zone = gameDocument?.assets?.zones?.find((x) => x.id === zoneId);

    const taskIds = zone?.tasks?.map(function (e) {
      return e.taskAssId;
    });

    if (taskIds) {
      return GetTasksByIds(gameDocument, taskIds);
    }
  }

  return tasks;
};

/**
 * Get tasks by Ids from game document local storage.
 * @param gameDocument - The Game Document as datasource
 * @constructor
 */
export const GetTasksByIds = (
  gameDocument: GameDocument | undefined,
  ids: string[]
) => {
  return (
    gameDocument?.assets?.tasks?.filter((x) => ids.indexOf(x.id) !== -1) ?? []
  );
};

/**
 * Get tasks by Id from game document local storage.
 * @param gameDocument - The Game Document as datasource
 * @param id - The task ID
 * @constructor
 */
export const GetTaskById = (gameDocument: GameDocument, id: string) => {
  return gameDocument.assets?.tasks?.find((x) => x.id === id);
};

export const SaveEntityEditorAsync = async (
  gameDocument: GameDocument,
  editorEntity: EntityEditor<TaskEntity>,
  resourceEntity: EntityEditor<ResourceEntity>[]
) => {
  let entity = editorEntity.entity;
  let newGameDocument: GameDocument = cloneDeep(gameDocument);

  const saveTitleResource = SaveResourceAsync(
    newGameDocument,
    resourceEntity?.find((x) => x.entity.id === entity.titleResId)?.entity!
  );

  let imageResource: ResourceEntity;

  if (entity.completeImageResId) {
    imageResource = resourceEntity?.find(
      (x) => x.entity.id === entity.imageResId
    )?.entity!;
  } else {
    const newId: string = uuid();
    imageResource = {
      id: newId,
      name: '',
      description: '',
      type: 'text',
      value: ''
    };
    editorEntity.entity.imageResId = newId;
  }

  const saveImageResource = SaveResourceAsync(newGameDocument, imageResource);

  let completeImageResource: ResourceEntity;

  if (entity.completeImageResId) {
    completeImageResource = resourceEntity?.find(
      (x) => x.entity.id === entity.completeImageResId
    )?.entity!;
  } else {
    const newId: string = uuid();
    completeImageResource = {
      id: newId,
      name: '',
      description: '',
      type: 'text',
      value: ''
    };
    editorEntity.entity.completeImageResId = newId;
  }

  const saveCompleteImageResource = SaveResourceAsync(
    newGameDocument,
    completeImageResource
  );

  const [titleResponse, imageResponse, completeImageResponse] =
    await Promise.all([
      saveTitleResource,
      saveImageResource,
      saveCompleteImageResource
    ]);

  if (editorEntity.isNew) {
    editorEntity.entity.id = uuid();
    await AddActionToTask(newGameDocument, editorEntity.entity);
    newGameDocument = merge(
      await AddTaskEntityAsync(newGameDocument, editorEntity.entity)
    );
  } else {
    newGameDocument = merge(
      await UpdateTaskAsync(
        newGameDocument,
        editorEntity.entity.id,
        editorEntity.entity
      )
    );
  }

  newGameDocument = merge(newGameDocument, titleResponse);
  newGameDocument = merge(newGameDocument, imageResponse);
  newGameDocument = merge(newGameDocument, completeImageResponse);

  return newGameDocument;
};
