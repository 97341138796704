import {
  DeleteAsync,
  GetAllAsync,
  PostAsync,
  GetAsync,
  PatchAsync,
  PutAsync
} from './api';
import { PhoneNumberResponse } from '../types/responses/phone-number-response';

/**
 * Gets ALL UsersPhoneNumbers encapsulated in a paged payload.
 * @returns all User objects.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetUsersPhoneNumbersAsync = async (userId: number) =>
  GetAllAsync<PhoneNumberResponse>(`users/${userId}/phonenumbers`);

/**
 * Applies a POST to the UserPhoneNumber.
 * requiring only the fields and values that require modification.
 * @param userId - the ID of the User to POST
 * @param user - the User or partial User to update [POST]
 * @returns the updated User object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/POST
 */
export const PostUserPhoneNumberAsync = async (
  userId: number,
  userPhoneNumber: PhoneNumberResponse
) =>
  PostAsync<PhoneNumberResponse>(
    `users/${userId}/phonenumbers`,
    userPhoneNumber
  );

/**
 * Applies a POST to the UserPhoneNumber.
 * requiring only the fields and values that require modification.
 * @param userAddress - the User or partial User to update [POST]
 * @returns the updated User object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/POST
 */
export const PostMyUserPhoneNumberAsync = async (
  userPhoneNumber: PhoneNumberResponse
) => PostAsync<PhoneNumberResponse>(`users/myphonenumbers`, userPhoneNumber);

/**
 * Applies a POST to the UserPhoneNumber.
 * requiring only the fields and values that require modification.
 * @param organisationId - the organisation ID of the User belong to
 * @param userId - the ID of the User to POST
 * @param user - the User or partial User to update [POST]
 * @returns the updated User object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/POST
 */
export const PostUserPhoneNumberByOrganisationAsync = async (
  organisationId: number,
  userId: number,
  userPhoneNumber: PhoneNumberResponse
) =>
  PostAsync<PhoneNumberResponse>(
    `organisations/${organisationId}/users/${userId}/phonenumbers`,
    userPhoneNumber
  );

/**
 * Gets the UserPhoneNumber by id.
 * @param userId - the ID of the User to retrieve
 * @returns the User object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetUserPhoneNumberAsync = async (userId: number) =>
  GetAsync<PhoneNumberResponse>(`users/${userId}/phonenumbers`);

/**
 * Applies a PATCH to the UserPhoneNumber. This can be considered a partial update
 * requiring only the fields and values that require modification.
 * @param userId - the ID of the User to PATCH
 * @param user - the User or partial User to update [PATCH]
 * @returns the updated User object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PATCH
 */
export const UpdateUserPhoneNumberAsync = async (
  userId: number,
  userPhoneNumber: PhoneNumberResponse
) =>
  PatchAsync<PhoneNumberResponse>(
    `users/${userId}/phonenumbers/${userPhoneNumber.id!}`,
    userPhoneNumber
  );

/**
 * Applies a PATCH to the UserPhoneNumber. This can be considered a partial update
 * requiring only the fields and values that require modification.
 * @param user - the User or partial User to update [PATCH]
 * @returns the updated User object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PATCH
 */
export const UpdateMyUserPhoneNumberAsync = async (
  userPhoneNumber: PhoneNumberResponse
) =>
  PatchAsync<PhoneNumberResponse>(
    `users/myphonenumbers/${userPhoneNumber.id!}`,
    userPhoneNumber
  );

/**
 * Applies a PATCH to the UserPhoneNumber. This can be considered a partial update
 * requiring only the fields and values that require modification.
 * @param organisationId - the organisation ID of the User belong to
 * @param userId - the ID of the User to PATCH
 * @param user - the User or partial User to update [PATCH]
 * @returns the updated User object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PATCH
 */
export const UpdateUserPhoneNumberByOrganisationAsync = async (
  organisationId: number,
  userId: number,
  userPhoneNumber: PhoneNumberResponse
) =>
  PatchAsync<PhoneNumberResponse>(
    `organisations/${organisationId}/users/${userId}/phonenumbers/${userPhoneNumber.id!}`,
    userPhoneNumber
  );

/**
 * Updates the UserPhoneNumber by completely replacing it.
 * @param userId - the ID of the entity to update [PUT]
 * @param user - the User to update [PUT]
 * @returns the updated User object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PUT
 */
export const PutUserPhoneNumberAsync = async (
  userId: number,
  userPhoneNumberId: number,
  userPhoneNumber: PhoneNumberResponse
) =>
  PutAsync<PhoneNumberResponse>(
    `users/${userId}/phonenumbers/${userPhoneNumberId}`,
    userPhoneNumber
  );

/**
 * Deletes a UserPhoneNumber by id.
 * @param userId - the ID of the User to DELETE
 * @returns the deleted User object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/DELETE
 */
export const DeleteUserPhoneNumberAsync = async (
  userId: number,
  userPhoneNumberId: number
) =>
  DeleteAsync<PhoneNumberResponse>(
    `users/${userId}/phonenumbers/${userPhoneNumberId}`
  );

/**
 * Gets ALL UsersPhoneNumbers from the response of Phone number
 * @returns all User objects.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetAllUserPhoneNumbersAsync = async (userId: number) =>
  GetAllAsync<PhoneNumberResponse>(`users/${userId}/phonenumbers`);

/**
 * Gets ALL UsersPhoneNumbers from the response of Phone number
 * @returns all User objects.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetMyUserPhoneNumbersAsync = async () =>
  GetAllAsync<PhoneNumberResponse>(`users/myphonenumbers`);

/**
 * Gets ALL UsersPhoneNumbers by OrganisationId from the response of Phone number
 * @returns all User objects.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetAllUserPhoneNumbersByOrganisationAsync = async (
  organisationId: number,
  userId: number
) =>
  GetAllAsync<PhoneNumberResponse>(
    `organisations/${organisationId}/users/${userId}/phonenumbers`
  );
