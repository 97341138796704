import { ComponentType, useRef } from 'react';
import { Col } from 'react-bootstrap';
import { Input, InputProps } from '@progress/kendo-react-inputs';
import { Label, Error } from '@progress/kendo-react-labels';

interface StandardInputProps extends InputProps {
  labelStyle?: string;
  colSize?: number | undefined;
}

export const StandardInput: ComponentType<StandardInputProps> = ({
  label,
  colSize,
  labelStyle,
  ...props
}) => {
  const inputRef = useRef(null);
  return (
    <Col sm={12} md={colSize ?? 12}>
      <Label className={labelStyle} editorRef={inputRef}>
        {label}
      </Label>
      <Input ref={inputRef} {...props} />
      {props?.validationMessage !== '' && (
        <Error>{props?.validationMessage}</Error>
      )}
    </Col>
  );
};

export default StandardInput;
