import { StandardInput } from '../../../components/forms';
import React, { ComponentType, ReactNode } from 'react';
import {
  AssessmentEntity,
  ResourceEntity
} from '../../../types/game-document/';
import { InputChangeEvent } from '@progress/kendo-react-inputs';
import { EditorMode } from '../../../types/editor-mode';
import { RequiredInput } from './assessment-editor-window';

interface AssessmentEditorProps {
  editorMode: EditorMode;
  entity: AssessmentEntity;
  resources: ResourceEntity[];
  handleInputChange: (event: InputChangeEvent) => void;
  children?: ReactNode | undefined;
  requiredInputs?: RequiredInput[];
}

export const AssessmentEditor: ComponentType<AssessmentEditorProps> = ({
  editorMode,
  entity,
  handleInputChange,
  requiredInputs,
  ...props
}: AssessmentEditorProps) => {
  return (
    <>
      <StandardInput
        name={'name'}
        label={'Name'}
        value={entity?.name}
        validationMessage={
          requiredInputs?.find((item) => item.name === 'name')?.errorMessage
        }
        onChange={handleInputChange}
      />
      <StandardInput
        name={'description'}
        label={'Description'}
        value={entity?.description}
        onChange={handleInputChange}
      />
      {editorMode === 'full' && (
        <StandardInput
          name={'titleResId'}
          label={'Title'}
          value={entity?.titleResId}
          onChange={handleInputChange}
        />
      )}
      {props.children}
    </>
  );
};
