import { PDFExport } from '@progress/kendo-react-pdf';
import { useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { ColView } from '../../components/col-view';

import { Label } from '@progress/kendo-react-labels';
import Avatar from '../../components/avatar';
import Aside from '../../components/overview/aside';
import {
  EventReportDetail,
  EventReportFacilitator,
  EventReportHeader
} from '../../types/event-report';
import { formatDateToDDMMYYYY } from '../../utils/date';

interface ReportInformationProps {
  summaryHeaderReport: EventReportHeader;
  summaryDetailReport: EventReportDetail[];
}

export const ReportInformation = ({ ...props }: ReportInformationProps) => {
  // const { id } = useParams();

  const [hasExported, setHasExported] = useState<boolean>(false);

  // const [summaryHeaderReport, setSummaryHeaderReport] =
  //   useState<EventReportHeader>();
  // const [summaryDetailReport, setSummaryDetailReport] =
  //   useState<EventReportDetail[]>();

  const pdfExportComponent = useRef<PDFExport>(null);

  // const populateSummaryReport = async () => {
  //   GetEventReportHeaderAsync(id!).then((response) => {
  //     setSummaryHeaderReport(response as EventReportHeader);
  //   });

  //   GetEventReportDetailsAsync(id!).then((detailResponse) => {
  //     setSummaryDetailReport(detailResponse.data as EventReportDetail[]);
  //   });
  // };

  const exportPDFWithComponent = () => {
    new Promise(() => {
      setTimeout(() => {
        if (pdfExportComponent.current) {
          setHasExported(true);
          pdfExportComponent.current.save(() => setHasExported(false));
        }
      }, 1000);
    });
  };

  const formatTime = (time: number) => {
    if (time !== undefined) {
      let hours = Math.floor(time / 60);
      let minutes = Math.round(time % 60);

      // if hours or minutes are greater than 1 return text in plural
      let hText = hours > 1 ? 'hours' : 'hour';
      let mText = minutes > 1 ? 'minutes' : 'minute';

      // show hour if hours > 0
      let hour = hours > 0 ? hours + hText : '';

      return hour + ' ' + minutes + mText;
    }
  };

  // useEffect(() => {
  //   populateSummaryReport();
  // }, []);

  return (
    <PDFExport
      ref={pdfExportComponent}
      paperSize={'auto'}
      margin={40}
      fileName={`Report event - ${new Date()}`}>
      <div className={'container-fluid p-relative'}>
        <Row className={'pb-4 border-bottom border-gray bg-white'}>
          <div className={'col d-flex align-items-center'}>
            <h1 className={'m-0 p-0'}>{props.summaryHeaderReport?.name}</h1>
          </div>
          {!hasExported && (
            <div className={'col-md-4 col-lg-3 d-flex justify-content-end'}>
              <Button
                variant={'success'}
                title={'Export to PDF'}
                className={'text-white px-8 mb-0'}
                onClick={exportPDFWithComponent}>
                Download
              </Button>
            </div>
          )}
        </Row>

        <Row className={'mt-3 p-relative'}>
          <Col>
            <Row className={'py-2'}>
              <Col md={6}>
                <ColView
                  className={'d-flex flex-column'}
                  colSpec={12}
                  labelClassName={'text-muted'}
                  label={'Event date'}
                  value={`${formatDateToDDMMYYYY(
                    props.summaryHeaderReport?.actualStartDateUtc!,
                    true
                  )} - ${formatDateToDDMMYYYY(
                    props.summaryHeaderReport?.actualEndDateUtc!,
                    true
                  )}`}
                />
              </Col>
              <Col md={6}>
                <ColView
                  className={'d-flex flex-column'}
                  colSpec={12}
                  labelClassName={'text-muted'}
                  label={'Duration'}
                  value={`${formatTime(props.summaryHeaderReport?.duration!)}`}
                />
              </Col>
            </Row>
            <Row className={'py-2'}>
              <Col>
                <ColView
                  className={'d-flex flex-column'}
                  colSpec={12}
                  labelClassName={'text-muted'}
                  label={'Pax'}
                  value={props.summaryHeaderReport?.pax! as unknown as string}
                />
              </Col>
            </Row>

            {props.summaryDetailReport &&
              props.summaryDetailReport.map((detail, index) => {
                return (
                  <Row className={'mt-4 gap-2'} key={index}>
                    <Col lg={12} md={12} sm={12}>
                      <span className={'label'}>{detail?.name}</span>
                    </Col>
                    <div className={'card p-3'}>
                      <Row>
                        <Col md={6} lg={8}>
                          <ColView
                            className={'d-flex flex-column'}
                            colSpec={12}
                            labelClassName={'text-muted'}
                            label={'Game date'}
                            value={`${formatDateToDDMMYYYY(
                              detail?.actualStartDateUtc!,
                              true
                            )} - ${formatDateToDDMMYYYY(
                              detail?.actualEndDateUtc!,
                              true
                            )}`}
                          />
                        </Col>
                        <Col md={6} lg={4}>
                          <ColView
                            className={'d-flex flex-column'}
                            colSpec={12}
                            labelClassName={'text-muted'}
                            label={'Duration'}
                            value={`${formatTime(detail?.duration!)}`}
                          />
                        </Col>
                      </Row>

                      <Row className={'mt-3'}>
                        <Col md={4} lg={4}>
                          <ColView
                            className={'d-flex flex-column'}
                            colSpec={12}
                            labelClassName={'text-muted'}
                            label={'Players'}
                            value={detail?.players! as unknown as string}
                          />
                        </Col>
                        <Col md={4} lg={4}>
                          <ColView
                            className={'d-flex flex-column'}
                            colSpec={12}
                            labelClassName={'text-muted'}
                            label={'Teams'}
                            value={detail?.teams! as unknown as string}
                          />
                        </Col>
                        <Col md={4} lg={4}>
                          <ColView
                            className={'d-flex flex-column'}
                            colSpec={12}
                            labelClassName={'text-muted'}
                            label={'Environment'}
                            value={detail?.environment!}
                          />
                        </Col>
                      </Row>

                      <Row className={'mt-3'}>
                        <Col md={4} lg={4}>
                          <ColView
                            className={'d-flex flex-column'}
                            colSpec={12}
                            labelClassName={'text-muted'}
                            label={'Timezone'}
                            value={detail?.timeZone!}
                          />
                        </Col>
                        <Col md={4} lg={4}>
                          <ColView
                            className={'d-flex flex-column'}
                            colSpec={12}
                            labelClassName={'text-muted'}
                            label={'Location'}
                            value={detail?.locations ?? ''}
                          />
                        </Col>
                        <Col md={4} lg={4}>
                          <ColView
                            className={'d-flex flex-column'}
                            colSpec={12}
                            labelClassName={'text-muted'}
                            label={'Facilitator'}
                            value={detail.facilitators
                              ?.map<string>(
                                (item: EventReportFacilitator) =>
                                  item.fullName ?? ''
                              )
                              .join(', ')}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Row>
                );
              })}
          </Col>
          <Col md={4} lg={3}>
            <Aside
              client={props.summaryHeaderReport?.client}
              agency={props.summaryHeaderReport?.agency}>
              <hr />
              <div className={'mt-3'}>
                <Label>{'Event Director'}</Label>
                <Avatar
                  name={props.summaryHeaderReport?.director}
                  img={''}
                  className={'text-success'}
                />
              </div>
              <div className={'mt-3'}>
                <Label>{'Lead Facilitator'}</Label>
                <Avatar
                  name={props.summaryHeaderReport?.leadFacilitator}
                  img={''}
                  className={'text-success'}
                />
              </div>
              <div className={'mt-3'}>
                <Label>{'Sales Person'}</Label>
                <Avatar
                  name={props.summaryHeaderReport?.salesPerson}
                  img={''}
                  className={'text-success'}
                />
              </div>
            </Aside>
          </Col>
        </Row>
      </div>
    </PDFExport>
  );
};
