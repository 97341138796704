import { ReactElement, useCallback } from 'react';
import {
  RadioButton,
  RadioButtonChangeEvent
} from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { MapEntity, ResourceEntity } from '../../types/game-document/';
import {
  AutoComplete,
  AutoCompleteChangeEvent,
  AutoCompleteCloseEvent
} from '@progress/kendo-react-dropdowns';
import {
  MapImageOverlayEditor,
  MapImageOverlayEditorProps
} from '../../features/game-document/maps/map-images-overlay-editor';

interface MapSettingProps extends MapImageOverlayEditorProps {
  selectedMapOption: string;
  onSelectedMapChange: (e: RadioButtonChangeEvent) => void;
  onCenterMap?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  mapInfo?: MapEntity;
  cities: string[];
  onSearchPlace: (e: AutoCompleteChangeEvent) => void;
  onPlaceSelected: (e: AutoCompleteCloseEvent) => void;
  onSearchCoordinates: (lat: string, lon: string) => void;
  onSelectIllustrationMapClick: () => void;
  onDeleteIlustrationMap: () => void;
  illustrationMapMedia?: ResourceEntity;
  onEventButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  children?: ReactElement;
}

interface EventButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const isValidCoordinates = (value: string) => {
  // Regular expression to check if string is a latitude and longitude
  const regexExp = /^((\-?|\+?)?\d+(\.\d+)?),\s*((\-?|\+?)?\d+(\.\d+)?)$/gi;

  return regexExp.test(value);
};

const EventButton = ({ onClick }: EventButtonProps) => {
  return (
    <Button className={'mt-4 w-full'} themeColor={'tertiary'} onClick={onClick}>
      <span className={'material-symbols-outlined'}>data_object</span>
      <span className={'mx-2'}>Events & Actions</span>
    </Button>
  );
};

const MapSettings = ({
  selectedMapOption,
  mapInfo,
  onCenterMap,
  cities,
  onSelectedMapChange,
  onSearchPlace,
  onSearchCoordinates,
  onPlaceSelected,
  onSelectIllustrationMapClick,
  illustrationMapMedia,
  onDeleteIlustrationMap,
  onEventButtonClick,
  children
}: MapSettingProps) => {
  const onSelectedMapChangeHandler = useCallback(
    (e: RadioButtonChangeEvent) => {
      onSelectedMapChange(e);
    },
    [onSelectedMapChange]
  );

  const onAutoCompleteChangeHandler = useCallback(
    (e: AutoCompleteChangeEvent) => {
      if (!e.value || e.syntheticEvent.type === 'click') {
        return;
      } else if (!e.value || (e.syntheticEvent as any).key === 'Enter') {
        if (isValidCoordinates(e.value)) {
          const coord = e.value.split(',');
          onSearchCoordinates(coord[0], coord[1]);
        }
      } else {
        onSearchPlace(e);
      }
    },
    [onSearchCoordinates, onSearchPlace]
  );

  const onAutoCompleteCloseHandler = useCallback(
    (e: AutoCompleteCloseEvent) => {
      onPlaceSelected(e);
    },
    [onPlaceSelected]
  );

  const selectMapHandler = useCallback(() => {
    onSelectIllustrationMapClick();
  }, [onSelectIllustrationMapClick]);

  return (
    <>
      <h5 className={'mt-1'}>Choose a map type</h5>
      <div className={'d-flex gap-1 flex-column mt-3'}>
        <div className={'fw-bold'}>
          <RadioButton
            name={'map'}
            value={'Illustration Map'}
            checked={selectedMapOption === 'Illustration Map'}
            label={'Illustration Map'}
            onChange={onSelectedMapChangeHandler}
          />
        </div>
        <div className={'fw-bold'}>
          <RadioButton
            name={'map'}
            value={'GPS Map'}
            checked={selectedMapOption === 'GPS Map'}
            label={'GPS Map'}
            onChange={onSelectedMapChangeHandler}
          />
        </div>
        <div className={'fw-bold'}>
          <RadioButton
            name={'map'}
            value={'GPS + Illustration Overlay'}
            checked={selectedMapOption === 'GPS + Illustration Overlay'}
            label={'GPS + Illustration Overlay'}
            onChange={onSelectedMapChangeHandler}
          />
        </div>
        {selectedMapOption === 'GPS Map' ||
        selectedMapOption === 'GPS + Illustration Overlay' ? (
          <div className={'my-2'}>
            <div className={'my-2'}>
              <div className={'position-relative'}>
                <AutoComplete
                  data={cities}
                  onChange={onAutoCompleteChangeHandler}
                  onClose={onAutoCompleteCloseHandler}
                  placeholder={'Search'}
                />
              </div>
            </div>
            <span
              className={
                'text-decoration-underline cursor-pointer text-center d-block'
              }
              onClick={onCenterMap}>
              Centre on my current location
            </span>
            <div className={'mt-4'}>
              <small className={'break-all text-center'}>
                <div>Lat: {mapInfo !== undefined && mapInfo.latitude}</div>
                <div>Long: {mapInfo !== undefined && mapInfo.longitude}</div>
              </small>

              <EventButton onClick={onEventButtonClick} />
              {children}
            </div>
          </div>
        ) : (
          <div>
            <div className={'my-2'}>
              <Button
                className={'w-full'}
                themeColor={'inverse'}
                onClick={selectMapHandler}>
                Select map
              </Button>
            </div>
            <div className={'my-2'}>
              <EventButton onClick={onEventButtonClick} />
              {children}
            </div>

            {illustrationMapMedia?.id !== '' &&
              illustrationMapMedia?.id !== undefined &&
              illustrationMapMedia?.id !== null && (
                <div className={'my-2 d-flex justify-content-between'}>
                  <span>{illustrationMapMedia!.name}</span>
                  <span
                    className={
                      'material-symbols-outlined ml-3 text-danger cursor-pointer'
                    }
                    onClick={onDeleteIlustrationMap}>
                    delete
                  </span>
                </div>
              )}
          </div>
        )}

        {selectedMapOption === 'GPS + Illustration Overlay' && (
          <MapImageOverlayEditor mapInfo={mapInfo} />
        )}
      </div>
    </>
  );
};

export default MapSettings;
