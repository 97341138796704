import { StandardInput } from '../../../components/forms';
import { ComponentType, useContext } from 'react';
import { TaskEntity } from '../../../types/game-document/';
import { ComboboxInput } from '../../../components/form-input';
import { Link, useParams } from 'react-router-dom';
import { GameDocumentContext } from '../../../contexts/game-document';
import {
  GetResourceValue,
  GetTaskContents
} from '../../../utils/game-document';
interface TaskEditorProps {
  entity: TaskEntity;
}

export const TaskBasicView: ComponentType<TaskEditorProps> = ({
  entity,
  ...props
}: TaskEditorProps) => {
  const [state] = useContext(GameDocumentContext);
  const { gameId } = useParams();
  return (
    <>
      <StandardInput
        name={'name'}
        label={'Name'}
        value={entity.name}
        disabled={true}
      />
      <StandardInput
        name={'description'}
        label={'Description'}
        value={entity.description}
        disabled={true}
      />
      <StandardInput
        name={'titleResId'}
        className={'mb-2'}
        label={'Title'}
        value={GetResourceValue(state?.gameDocument!, entity?.titleResId ?? '')}
        disabled={true}
      />

      <div
        className={'d-flex justify-content-space-between align-items-center'}>
        <ComboboxInput
          label={'Task content'}
          placeholder={'Select a task'}
          data={GetTaskContents(state?.gameDocument!)}
          textField={'name'}
          value={
            entity.taskContentId &&
            GetTaskContents(state?.gameDocument!)?.find(
              (x) => x.id === entity?.taskContentId
            )
          }
          name={'taskContentId'}
          disabled={true}
        />
        {entity.taskContentId && entity.id && (
          <div className="d-flex align-items-end pb-2 h-100 text-primary">
            <Link
              to={`/designer/${gameId}/assets/task-content/${entity.taskContentId}/${entity.id}`}>
              <span className={'material-symbols-outlined'}>edit_square</span>
            </Link>
          </div>
        )}
      </div>
    </>
  );
};
