import { Button } from '@progress/kendo-react-buttons';
import merge from 'lodash.merge';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Toolbar from '../../components/page-toolbar';
import { PrivatePage } from '../../components/private-page';
import { GetLanguagesAsync } from '../../services/country';
import {
  copyGlobalFeedbackToLibrary,
  getGlobalContentsByFeedbackIdAsync,
  getGlobalDocumentLatestAsync,
  getGlobalFeedbackById
} from '../../services/feedback';
import { appStore } from '../../stores/app-store';
import { toastStore } from '../../stores/toast-store';
import { uuid } from '../../types/common-helper';
import { Feedback } from '../../types/feedback';
import { FeedbackContentFullEditor } from '../../features/feedback/feedback-content-full-editor';

const defaultEntity: Feedback = {
  id: 0,
  name: '',
  title: '',
  organisationId: 1,
  language: '',
  htmlContent: '',
  description: '',
  version: 'v0.0.1',
  feedbackDetails: [
    {
      id: uuid(),
      question: '',
      answerType: ''
    }
  ]
};

const GlobalFeedbackDetail = () => {
  const { id } = useParams();
  const [entity, setEntity] = useState<Feedback>();
  const [languages, setLanguages] = useState<string[]>([]);
  const [, setSelectedTab] = useState<string>('');
  const [currentLocale, setCurrentLocale] = useState<string>();
  const detailFeedbackBreadcrumb = [
    { label: 'Dashboard', href: '../dashboard' },
    { label: 'Global feedback', href: '../global-feedback' },
    {
      label: 'Global Feedback Details',
      href: `../global-feedback-details/${id}`
    }
  ];

  const getDetails = async () => {
    try {
      appStore.showLoading();
      const document = await getGlobalDocumentLatestAsync(parseInt(id!));

      // combine feedback and feedback documents (resources)
      let feed1 = await getGlobalFeedbackById(parseInt(id!));
      let feed2 = await getGlobalContentsByFeedbackIdAsync(
        parseInt(id!),
        document.id
      );

      let feedback = merge(feed1, feed2);

      setEntity(feedback);
    } catch (ex) {
      console.error(ex);
    } finally {
      appStore.hideLoading();
    }
  };

  const setDefaultEntity = () => {
    setEntity(defaultEntity);
  };

  const handleEntityChange = (newnEntity: Feedback) => {
    setEntity(newnEntity);
  };

  const getLanguages = async () => {
    const response = await GetLanguagesAsync();
    const languages: string[] = [];
    response.data.forEach((f) => {
      if (!languages.some((c) => c === f.language)) {
        languages.push(f.language!);
      }
    });
    setLanguages(languages);
  };

  const toggleLanguageTab = (activeTab: string) => {
    if (activeTab === 'language') {
      setSelectedTab(activeTab);
    } else {
      setSelectedTab('');
    }
  };

  const onCopyFeedback = async (feedback: Feedback) => {
    try {
      appStore.showLoading();

      await copyGlobalFeedbackToLibrary(feedback);

      toastStore.show(
        'Feedback list',
        <div>Feedback copied to Feedback list.dback list</div>,
        'success'
      );
    } catch (err: any) {
      toastStore.show(
        'Feedback list',
        <div>Failed to copy to Feedback list.</div>,
        'error'
      );
    } finally {
      appStore.hideLoading();
      getDetails();
    }
  };

  useEffect(() => {
    if (id) {
      getDetails();
    } else {
      setDefaultEntity();
    }
    getLanguages();
    //eslint-disable-next-line
  }, []);

  return (
    <PrivatePage
      breadcrumb={detailFeedbackBreadcrumb}
      pageTitle={'Global Feedback'}>
      <>
        <Toolbar title={entity?.name ?? ''}>
          <Button
            themeColor={'primary'}
            onClick={() => onCopyFeedback(entity!)}>
            Copy to My library
          </Button>
        </Toolbar>
        <hr />
        <Container>
          {entity && (
            <FeedbackContentFullEditor
              isViewMode={true}
              languages={languages}
              entity={entity}
              currentLocale={currentLocale}
              setCurrentLocale={setCurrentLocale}
              handleEntityChange={handleEntityChange}
              toggleLanguageTab={toggleLanguageTab}
            />
          )}
        </Container>
      </>
    </PrivatePage>
  );
};

export default GlobalFeedbackDetail;
