import { PrivatePage } from '../components/private-page';
import { useNavigate, useParams } from 'react-router-dom';
import EventsView from '../features/events-view';
export type dictionary = {
  id: number;
  name: string;
};

export default function MyEventView() {
  const { id } = useParams();
  const navigate = useNavigate();

  const backHandler = () => {
    navigate('/myevents');
  };

  return (
    <PrivatePage
      breadcrumb={[
        {
          label: 'Dashboard',
          href: 'dashboard'
        },
        {
          label: 'My events',
          href: 'myevents'
        },
        {
          label: 'Event details',
          href: `myeventview/${id}`
        }
      ]}
      pageTitle={'My events'}>
      <EventsView id={id} onBackButtonClick={backHandler} />
    </PrivatePage>
  );
}
