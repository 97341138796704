import { useEffect } from 'react';
import { ctGameDefault, ctGameTitle } from '../../../../constants/text';
import { PrivatePage } from '../../../../components/private-page';
import LanguagesManagementGrid from '../../../../features/languages-management-grid';

export default function LanguagesManagement() {
  useEffect(() => {
    document.title = ctGameTitle + ' - Games management';

    return () => {
      // return default value when component unmounts
      document.title = ctGameDefault;
    };
  }, []);

  return (
    <PrivatePage
      breadcrumb={[
        { label: 'Dashboard', href: 'dashboard' },
        { label: 'Administration', href: '../administration' },

        {
          label: ' Languages management',
          href: 'administration/languages/manage'
        }
      ]}
      pageTitle={'Administration'}>
      <LanguagesManagementGrid />
    </PrivatePage>
  );
}
