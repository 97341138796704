import { Outlet, useParams } from 'react-router-dom';
import { Spinner } from '../components/spinner';
import { Toast } from '../components/toast';
import { AsideMenu } from '../components/aside-menu';
import { appStore } from '../stores/app-store';
import { toastStore } from '../stores/toast-store';
import { observer } from 'mobx-react';
import { GameDocumentProvider } from '../contexts/game-document';
import { AuthRequired } from '../components/authentication';

/**
 * The layout for game designer [portal] pages.
 * @returns
 */
const Designer = observer(() => {
  const { gameId } = useParams();

  const getStyle = () => {
    const style = toastStore.getStyle();
    return style ? style : 'none';
  };

  return (
    <GameDocumentProvider gameId={gameId}>
      <AuthRequired>
        <div className={'layout layout--designer'}>
          {appStore.isLoading() && <Spinner />}
          <aside className="app-side-nav">
            <div className="logo mb-5" />
            <AsideMenu
              initalMenus={[
                {
                  label: '-'
                },
                {
                  label: 'Back to main portal',
                  path: '/dashboard',
                  selected: false,
                  icon: 'arrow_back_ios',
                  className: 'text-success'
                },
                {
                  label: '-'
                },
                {
                  label: 'Overview',
                  path: `/game-library/${gameId}/overview`,
                  selected: false,
                  icon: 'draw'
                },
                {
                  label: 'Map',
                  path: `/game-library/${gameId}/map`,
                  selected: false,
                  icon: 'Map'
                },
                {
                  label: 'Zones',
                  path: '#',
                  selected: false,
                  icon: 'activity_zone'
                },
                {
                  label: 'Resources',
                  selected: false,
                  expandable: true,
                  isExpanded: false,
                  icon: 'Category',
                  submenus: [
                    {
                      label: 'Timers',
                      path: '#',
                      selected: false
                    },
                    {
                      label: 'Tasks',
                      path: `/game-library/${gameId}/tasks`,
                      selected: false
                    },
                    {
                      label: 'Task content',
                      path: `/game-library/${gameId}/task-contents`,
                      selected: false
                    },
                    {
                      label: 'Role',
                      path: '#',
                      selected: false
                    },
                    {
                      label: 'Inventory',
                      path: '#',
                      selected: false
                    },
                    {
                      label: 'Announcements',
                      path: '#',
                      selected: false
                    },
                    {
                      label: 'Assesments',
                      path: '#',
                      selected: false
                    }
                  ]
                },
                {
                  label: 'Theme',
                  path: '#',
                  selected: false,
                  icon: 'palette'
                },
                {
                  label: 'Language',
                  path: '#',
                  selected: false,
                  icon: 'translate'
                },
                {
                  label: 'Settings',
                  path: '#',
                  selected: false,
                  icon: 'settings'
                }
              ]}
            />
          </aside>
          <div className={'app-main'}>
            <Outlet />
          </div>
          <Toast
            style={getStyle()}
            show={toastStore.isShow()}
            title={toastStore.getTitle()}>
            {toastStore.getMessage()}
          </Toast>
        </div>
      </AuthRequired>
    </GameDocumentProvider>
  );
});

export default Designer;
