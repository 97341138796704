import React, { ComponentType } from 'react';
import { Progress } from '../../layouts';
import { ReactComponent as Logo } from '../../assets/cg-icon-globe.svg';

interface AuthenticatingProps {
  children?: React.ReactNode;
}

const Authenticating: ComponentType<any> = ({
  children
}: AuthenticatingProps) => (
  <Progress>
    <div className={'text-center'}>
      <Logo className={'spin'} style={{ width: 100 }} />
      <h1 className={'mt-3'}>Authentication in progress</h1>
      <p>You will be redirected to the login page.</p>
      <>{children}</>
    </div>
  </Progress>
);

export default Authenticating;
