import {
  AreaEntity,
  ResourceEntity
} from '../../../types/game-document/entities';
import { ComponentType, useContext } from 'react';
import { Algorithm } from '../../../types/algorithm';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import EventAlgorithm from '../../../components/map/event-configuration/event--algorithm';
import { StandardInput, StandardTextarea } from '../../../components/forms';
import {
  Checkbox,
  CheckboxChangeEvent,
  InputChangeEvent,
  TextAreaChangeEvent
} from '@progress/kendo-react-inputs';
import { AreaAlgorithmEvent } from '../../../types/game-document/area';
import RequiredFields from '../../../types/required-fields';
import { GameDocumentContext } from '../../../contexts/game-document';

interface AreaEditorProps {
  entity: AreaEntity;
  resources?: ResourceEntity[];
  requiredFields?: RequiredFields<AreaEntity>[];
  handleInputChange?: (
    e: InputChangeEvent | TextAreaChangeEvent | CheckboxChangeEvent
  ) => void;
  handleAlgorithmEventChange?: (
    algorithm: Algorithm,
    type: AreaAlgorithmEvent
  ) => void;
}

export const AreaEditorFeature: ComponentType<AreaEditorProps> = ({
  entity,
  requiredFields,
  handleInputChange = () => {},
  handleAlgorithmEventChange = () => {},
  ...props
}: AreaEditorProps) => {
  const [state] = useContext(GameDocumentContext);
  const handleEventOnEntryChange = (algorithm: Algorithm) => {
    handleAlgorithmEventChange(algorithm, 'onEntry');
  };

  const handleEventOnExitChange = (algorithm: Algorithm) => {
    handleAlgorithmEventChange(algorithm, 'onExit');
  };

  return (
    <Container className={'d-flex align-items-start flex-column'}>
      <Row className={'w-full'}>
        <Col md={12}>
          <Tabs
            defaultActiveKey={'information'}
            id={'area-tabs'}
            className={'mb-3'}>
            <Tab eventKey={'information'} title={'Information'}>
              <div className={'d-flex flex-column gap-2'}>
                <StandardInput
                  name={'name'}
                  label={'Name'}
                  labelStyle={'fw-normal'}
                  value={entity.name}
                  onChange={handleInputChange}
                  validationMessage={
                    requiredFields?.find((item) => item.name === 'name')
                      ?.errorMessage
                  }
                  autoFocus={true}
                />
                <StandardTextarea
                  name={'description'}
                  label={'Description'}
                  labelStyle={'fw-normal'}
                  value={entity.description}
                  onChange={handleInputChange}
                />
                {state.gameDocument?.settings.inGame.tasksRequireProximity && (
                  <Checkbox
                    name={'disableProximity'}
                    value={entity.disableProximity}
                    size={'large'}
                    onChange={handleInputChange}
                    label={'Disable task proximity setting on this area'}
                  />
                )}
              </div>
            </Tab>
            <Tab eventKey={'onEntry'} title={'onEntry'}>
              <EventAlgorithm
                onChange={handleEventOnEntryChange}
                algorithm={entity.events?.onEntry}
                title={'Area.onEntry'}
                description={'is triggered when entry the area.'}
              />
            </Tab>
            <Tab eventKey={'onExit'} title={'onExit'}>
              <EventAlgorithm
                onChange={handleEventOnExitChange}
                algorithm={entity.events?.onExit}
                title={'Area.onExit'}
                description={'is triggered when exit the area.'}
              />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};
