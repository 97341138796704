import { useEffect } from 'react';
import { ctGameDefault, ctGameTitle } from '../../../constants/text';
import { PrivatePage } from '../../../components/private-page';
import AssessmentsManagementGrid from '../../../features/assessments-management-grid';

export default function AssessmentsManagement() {
  useEffect(() => {
    document.title = ctGameTitle + ' - Assessment management';

    return () => {
      // return default value when component unmounts
      document.title = ctGameDefault;
    };
  }, []);

  return (
    <PrivatePage
      breadcrumb={[
        { label: 'Dashboard', href: 'dashboard' },
        { label: 'Administration', href: '../administration' },

        {
          label: ' Assessment management',
          href: 'administration/assessments/manage'
        }
      ]}
      pageTitle={'Administration'}>
      <AssessmentsManagementGrid />
    </PrivatePage>
  );
}
