import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { DialogProps } from './dialog';
import {
  ExternalDropZone,
  Upload,
  UploadOnAddEvent
} from '@progress/kendo-react-upload';
import {
  DeleteMediaAsync,
  PostMediaAsync,
  PostResourceAsync
} from '../../services/files';
import { FileExtension } from '../../types/fileExtensions';
import { UploaderError } from '../../types/errors';
import { Media } from '../../types/media';
import { Window, WindowActionsBar } from '@progress/kendo-react-dialogs';
import CoverImage from '../cover-image';
import { appStore } from '../../stores/app-store';
import { YesNoDialogResult } from '../../types/dialog-result';

export interface YesNoDialogProps extends DialogProps {
  isVisibleTakePhoto?: boolean;
  isVisibleUploadPhoto?: boolean;
  isVisibleDropFile?: boolean;
  allowedFileTypes?: string;
  maxFileSize?: number;
  isMultiple?: boolean;
  title?: string;
  isDirectUpload?: boolean;
  allowedExtension?: FileExtension[];
  maxImageSize?: number; //IN MB
  maxVideoSize?: number; //IN MB
  isIcon?: boolean;
  isUploadGame?: boolean;
  onFilesSelected?: (medias: Media[]) => void;
  onConfirm?: (result: YesNoDialogResult) => void;
  currentFile?: string;
}

export function UploadFileDialog(props: YesNoDialogProps) {
  const uploadRef = React.createRef<Upload>();
  const inputUploadRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<Media[]>([]);
  const [lastImageUrl, setLastImageUrl] = useState('');
  const [lastImageName, setLastImageName] = useState('');
  const [showDefaultPhoto, setShowDefaultPhoto] = useState(true);
  const [showdeleteFile, setShowDeleteFile] = useState(false);
  const [allowedExtensionKendo, setAllowedExtensionKendo] = useState<string[]>(
    []
  );
  const [allowedExtensionInput, setAllowedExtensionInInput] =
    useState<string>('');
  const [allowedExtensionDescription, setAllowedExtensionDescription] =
    useState('');
  const [errorMessages, setErrorMessages] = useState<UploaderError[]>([]);
  const initializeAllowedExtension = () => {
    if (props.allowedExtension) {
      let allowedExtension = '';

      props.allowedExtension.forEach((ext) => {
        if (ext === FileExtension.JPG) {
          allowedExtension += 'image/jpeg,';
          allowedExtension += 'image/jpg,';
        }
        if (ext === FileExtension.PNG) {
          allowedExtension += 'image/png,';
        }
        if (ext === FileExtension.MP4) {
          allowedExtension += 'video/mp4,';
        }
      });

      if (allowedExtension) {
        const arrayExtension = allowedExtension
          .replace('image/png', '.png')
          .replace('image/jpeg', '.jpeg')
          .replace('image/jpg', '.jpg')
          .replace('video/mp4', '.mp4')
          .split(',');

        setAllowedExtensionKendo(arrayExtension);

        setAllowedExtensionDescription(arrayExtension.join(', ').slice(0, -2));

        setAllowedExtensionInInput(allowedExtension);
      }
    }
  };

  const handleConfirm = (result: YesNoDialogResult) => {
    if (props.onConfirm) {
      props.onConfirm(result);
    }
  };

  const handleFilesToUpload = () => {
    if (files) {
      if (props.isMultiple) {
        if (props.onFilesSelected) {
          props.onFilesSelected(files);
        }
      } else {
        if (props.onFilesSelected) {
          let lastFiles: Media[] = [];
          if (files.length > 0) {
            lastFiles.push(files[files.length - 1]);

            if (props.isDirectUpload) {
              for (let index = 0; index < files.length - 1; index++) {
                let deletedFile = files[index];
                if (deletedFile) {
                  handledelete(deletedFile);
                }
              }
            }

            props.onFilesSelected(lastFiles);
          }
        }
      }
    }
  };

  const onUploadClick = () => {
    if (inputUploadRef && inputUploadRef.current) {
      inputUploadRef.current.click();
    }
  };

  const validateFiles = async () => {
    appStore.showLoading();
    const oneMB = 1024;
    let newFiles: Media[] = [...files];
    let errors: UploaderError[] = [];

    for (let index = 0; index < newFiles.length; index++) {
      const file = newFiles[index];

      //Start validate extension

      const estension = file.file.type
        .toLowerCase()
        .replace('video/', '.')
        .replace('image/', '.');

      const found = allowedExtensionKendo.find((ext) => ext === estension);

      if (!found) {
        const errorTypes = `${file.file.name} - Types accepted: ${allowedExtensionDescription}`;
        if (errorTypes) {
          errors.push({ title: '', message: errorTypes });
          setErrorMessages(errors);
          handledelete(file);
        }
      } else {
        if (file.file.type.includes('image')) {
          if (props.maxImageSize && props.maxImageSize > 0) {
            if (file.file.size > props.maxImageSize * oneMB * oneMB) {
              const error = `Please choose an image that is under ${props.maxImageSize} MB`;

              errors.push({ title: 'Image too large', message: error });
              setErrorMessages(errors);
              handledelete(file);
            } else {
              if (file.mediaId === null || file.mediaId === undefined) {
                if (props.isDirectUpload) {
                  if (props.isUploadGame) {
                    const resourceResponse = await PostResourceAsync(file.file);
                    file.resource = resourceResponse;
                  } else {
                    const response = await PostMediaAsync(file.file);
                    file.mediaId = response.id;
                  }

                  setFiles(files);
                }
              }
            }
          } else {
            if (file.mediaId === null || file.mediaId === undefined) {
              if (props.isDirectUpload) {
                if (props.isUploadGame) {
                  const resourceResponse = await PostResourceAsync(file.file);
                  file.resource = resourceResponse;
                } else {
                  const response = await PostMediaAsync(file.file);
                  file.mediaId = response.id;
                }

                setFiles(files);
              }
            }
          }
        } else {
          if (file.file.type.includes('video')) {
            if (props.maxVideoSize) {
              if (file.file.size > props.maxVideoSize * oneMB * oneMB) {
                const error = `Please choose an video that is under ${props.maxVideoSize} MB`;

                errors.push({ title: 'Video too large', message: error });

                setErrorMessages(errors);
                handledelete(file);
              } else {
                if (file.mediaId === null || file.mediaId === undefined) {
                  if (props.isDirectUpload) {
                    if (props.isUploadGame) {
                      const resourceResponse = await PostResourceAsync(
                        file.file
                      );
                      file.resource = resourceResponse;
                    } else {
                      const response = await PostMediaAsync(file.file);
                      file.mediaId = response.id;
                    }

                    setFiles(files);
                  }
                }
              }
            } else {
              if (file.mediaId === null || file.mediaId === undefined) {
                if (props.isDirectUpload) {
                  if (props.isUploadGame) {
                    const resourceResponse = await PostResourceAsync(file.file);
                    file.resource = resourceResponse;
                  } else {
                    const response = await PostMediaAsync(file.file);
                    file.mediaId = response.id;
                  }

                  setFiles(files);
                }
              }
            }
          }
        }
      }
      //End validate extension
    }
    appStore.hideLoading();
  };

  const onUploadPicture = (fls: FileList | null) => {
    setErrorMessages([]);

    let uploadedFiles: Media[] = [...files];

    if (fls) {
      for (let index = 0; index < fls.length; index++) {
        uploadedFiles.push({ file: fls[index] });
      }
    }

    setFiles(uploadedFiles);
  };

  const onDropFiles = (event: UploadOnAddEvent) => {
    setErrorMessages([]);

    let uploadedFiles: Media[] = [...files];

    event.newState.forEach((file) => {
      if (file && file.getRawFile) {
        uploadedFiles.push({ file: file.getRawFile() });
      }
    });

    setFiles(uploadedFiles);
  };

  const removeFileFromStorage = async (mediaId: number) => {
    appStore.showLoading();
    await DeleteMediaAsync(mediaId);
    appStore.hideLoading();
  };

  const handledelete = (file: Media) => {
    if (files) {
      const fls: Media[] = [...files];

      const index = fls.indexOf(file);
      if (index > -1) {
        // only splice array when item is found
        fls.splice(index, 1); // 2nd parameter means remove one item only
      }

      if (props.isDirectUpload) {
        if (file.mediaId && !props.isUploadGame) {
          removeFileFromStorage(file.mediaId);
        }
      }
      setFiles(fls);
    }
  };

  const loadFile = () => {
    if (files.length > 0) {
      setLastImageUrl(URL.createObjectURL(files[files.length - 1].file));
      setLastImageName(files[files.length - 1].file.name);
    }
  };

  const handleDeleteAll = () => {
    if (files) {
      files.forEach((file) => {
        handledelete(file);
      });
    }

    setFiles([]);
  };

  useEffect(
    () => {
      loadFile();

      if (files.length > 0) {
        setShowDefaultPhoto(false);
        setShowDeleteFile(true);
      } else {
        setShowDefaultPhoto(true);
        setShowDeleteFile(false);
      }

      validateFiles();
    },
    // eslint-disable-next-line
    [files]
  );

  useEffect(
    () => {
      initializeAllowedExtension();
    },
    // eslint-disable-next-line
    []
  );

  return (
    <Window
      modal={true}
      minimizeButton={undefined}
      className={'shadow'}
      initialWidth={700}
      initialHeight={500}
      onClose={() => {
        handleConfirm('no');
      }}
      title={props.title}>
      <div className={'container'}>
        <div className={'row'}>
          {errorMessages &&
            errorMessages.map((msg, index) => {
              return (
                <div className={'col-12'} key={index}>
                  <div
                    className={'alert alert-danger alert-dismissible fade show'}
                    role={'alert'}>
                    {msg.title && (
                      <div>
                        <b>{msg.title}</b> <br />
                      </div>
                    )}
                    {msg.message}
                  </div>
                </div>
              );
            })}
        </div>
        {!props.isMultiple && (
          <div>
            <div className={'row mt-3'}>
              <div
                className={
                  'col-12 d-flex justify-content-center align-items-center mt-2 mb-2'
                }>
                <div>
                  {showDefaultPhoto && (
                    <CoverImage
                      imageUrl={
                        lastImageUrl !== '' ? lastImageUrl : props?.currentFile
                      }
                      containerStyle={'rounded'}
                    />
                  )}
                  {props.isIcon && !showDefaultPhoto && (
                    <div
                      className={
                        'd-flex justify-content-center align-items-center h-11'
                      }>
                      {lastImageUrl && (
                        <img
                          id={'last-image'}
                          src={lastImageUrl}
                          className={'h-11 rounded'}
                        />
                      )}
                    </div>
                  )}
                  {!props.isIcon && !showDefaultPhoto && (
                    <div
                      className={
                        'd-flex justify-content-center align-items-center h-25'
                      }>
                      <img
                        src={lastImageUrl}
                        className={'img-fluid custom-img-fluid-height rounded'}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={'row mt-2 mb-3'}>
              {showdeleteFile && (
                <div className={'col-12 d-flex'}>
                  <span>{lastImageName}</span>
                  <span
                    className={'material-symbols-outlined ml-3 text-danger'}
                    role={'button'}
                    onClick={handleDeleteAll}>
                    delete
                  </span>
                </div>
              )}
            </div>
            <div className={'row'}>
              {props.isVisibleTakePhoto && (
                <div className={'col-6'}>
                  <div
                    className={
                      'card text-center w-100 visible-take-photo-height'
                    }>
                    <div
                      className={
                        'd-flex justify-content-center align-items-center p-2'
                      }>
                      <span
                        className={'material-symbols-outlined mr-2'}
                        role={'button'}>
                        add_a_photo
                      </span>
                      <span className={'p-1'}>Take Photo</span>
                    </div>
                  </div>
                </div>
              )}

              {props.isVisibleDropFile && (
                <div>
                  <div className={'row p-2'}>
                    <div className={'col-md-12 dropzone-upload'}>
                      <div className={'text-center'}>
                        <ExternalDropZone
                          className={
                            'w-100 h-100 text-primary p-4 mt-2 external-dropzone-background'
                          }
                          uploadRef={uploadRef}
                          customNote={'Drop File'}
                        />
                        <Upload
                          className={'d-none'}
                          ref={uploadRef}
                          batch={false}
                          multiple={props.isMultiple}
                          withCredentials={false}
                          restrictions={{
                            allowedExtensions: allowedExtensionKendo
                          }}
                          onAdd={onDropFiles}
                          showFileList={true}
                          saveUrl={(files, options, onProgress) => {
                            return new Promise((resolve, reject) => {
                              resolve({ uid: files[0].uid });
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {props.isVisibleUploadPhoto && (
                <div
                  className={
                    'd-flex justify-content-center align-items-center'
                  }>
                  <Button
                    className={'w-80 upload-button p-2'}
                    onClick={onUploadClick}>
                    <div className={''}>
                      <span
                        className={
                          'material-symbols-outlined mr-2 text-success'
                        }
                        role={'button'}>
                        add_photo_alternate
                      </span>
                      <span>Upload</span>
                    </div>
                  </Button>
                  <input
                    type={'file'}
                    ref={inputUploadRef}
                    multiple={props.isMultiple}
                    className={'d-none'}
                    onChange={(e) => onUploadPicture(e.target.files)}
                    onClick={(event) => {
                      event.currentTarget.value = '';
                    }}
                    accept={allowedExtensionInput}
                  />
                </div>
              )}
            </div>
          </div>
        )}

        {props.isMultiple && (
          <div>
            <div className={'row mt-2 multiple-background'}>
              <div className={'w-100 p-2'}>
                {props.isVisibleUploadPhoto && (
                  <div className={'col-md-6'}>
                    <Button
                      className={'w-100 upload-button mt-2 mb-2'}
                      onClick={onUploadClick}>
                      <div
                        className={
                          'd-flex justify-content-center align-items-center gap-3'
                        }>
                        <span
                          className={
                            'material-symbols-outlined mr-2 text-success'
                          }
                          role={'button'}>
                          add_photo_alternate
                        </span>
                        <span>Upload</span>
                      </div>
                    </Button>
                    <input
                      type={'file'}
                      id={'file-upload'}
                      multiple={props.isMultiple}
                      className={'d-none'}
                      onChange={(e) => onUploadPicture(e.target.files)}
                      onClick={(event) => {
                        event.currentTarget.value = '';
                      }}
                      accept={allowedExtensionInput}
                    />
                  </div>
                )}
                <div className={'col-12'}>
                  <ul className={'list-group w-100'}>
                    {files &&
                      files.map((file, i) => {
                        return (
                          <li className={'list-group-item rounded-0'} key={i}>
                            <div className={'d-flex justify-content-between'}>
                              <div className={'d-flex'}>
                                <span
                                  className={
                                    'material-symbols-outlined mr-2 p-2'
                                  }>
                                  add_photo_alternate
                                </span>
                                <span className={'p-2'}>{file.file.name}</span>
                              </div>
                              <span
                                className={'material-symbols-outlined mr-2 p-2'}
                                role={'button'}
                                onClick={() => handledelete(file)}>
                                close
                              </span>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className={'row'}>
        <div className={'col text-center'}>
          <span>Types accepted: {allowedExtensionDescription}</span>
        </div>
      </div>
      <WindowActionsBar>
        <Button
          className={'btn-secondary text-light btn btn-primary'}
          onClick={() => {
            handleConfirm('no');
          }}>
          Cancel
        </Button>

        <Button
          className={'text-light btn btn-primary'}
          color={'secondary'}
          onClick={() => handleFilesToUpload()}>
          Confirm
        </Button>
      </WindowActionsBar>
    </Window>
  );
}

export default UploadFileDialog;
