export const DisplayLanguageIds = {
  TotalAssetsId: '5d7d9dae-f5d4-45ba-993d-22fa61ca16e5',
  IncorrectPointId: 'f46afd85-3307-480b-97ed-03a12645c906',
  TryAttempsId: '298f372e-f97b-42c6-b1a7-a0bf7866ec7b'
};
export const DisplayLanguageIdShowNote = [
  DisplayLanguageIds.TotalAssetsId,
  DisplayLanguageIds.IncorrectPointId,
  DisplayLanguageIds.TryAttempsId
];

export default DisplayLanguageIdShowNote;
