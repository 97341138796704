import { StandardInput } from '../../../components/forms';
import React, { ComponentType, ReactNode } from 'react';
import { ItemEntity, ResourceEntity } from '../../../types/game-document/';
import {
  Checkbox,
  CheckboxChangeEvent,
  InputChangeEvent
} from '@progress/kendo-react-inputs';
import { EditorMode } from '../../../types/editor-mode';
import ImageResource from '../image-resource/resource';
import RequiredFields from '../../../types/required-fields';

interface ItemEditorProps {
  editorMode: EditorMode;
  entity: ItemEntity;
  resources: ResourceEntity[];
  handleInputChange: (event: InputChangeEvent) => void;
  handleImageSelected?:
    | ((url: string, size: number, name?: string) => void)
    | (() => {});
  children?: ReactNode | undefined;
  imageResourceVisibility?: boolean | undefined;
  requiredFields?: RequiredFields<ItemEntity>[];
  handleHideItemChange: (event: CheckboxChangeEvent) => void;
}

export const ItemEditor: ComponentType<ItemEditorProps> = ({
  editorMode,
  entity,
  resources,
  handleInputChange,
  handleImageSelected = () => {},
  imageResourceVisibility,
  requiredFields,
  handleHideItemChange,
  ...props
}: ItemEditorProps) => {
  const handleSelectImage = (url: string, size: number, name?: string) => {
    handleImageSelected(url, size, name);
  };

  return (
    <>
      <div className={'d-flex flex-row'}>
        <div className={'flex-column w-10'}>
          <ImageResource
            title={'Image'}
            imageResource={
              resources.find((item) => item.id === entity.imageResId)!
            }
            onSubmit={handleSelectImage}
            source="Inventory"
          />
        </div>
        <div className={'flex-column flex-fill'}>
          <StandardInput
            name={'name'}
            label={'Name'}
            value={entity?.name}
            onChange={handleInputChange}
            validationMessage={
              requiredFields?.find((item) => item.name === 'name')?.errorMessage
            }
            autoFocus={true}
          />
          <StandardInput
            name={'description'}
            label={'Description'}
            value={entity?.description}
            onChange={handleInputChange}
          />
          <StandardInput
            name={'titleResId'}
            label={'Title'}
            value={
              resources.find((resource) => resource.id === entity?.titleResId)
                ?.value ?? ''
            }
            onChange={handleInputChange}
          />
          <StandardInput
            name={'summaryResId'}
            label={'Summary'}
            value={
              resources.find((resource) => resource.id === entity?.summaryResId)
                ?.value ?? ''
            }
            onChange={handleInputChange}
          />
          <Checkbox
            name={'hideInGame'}
            label={'Hide inventory items from players.'}
            size={'large'}
            value={entity?.hideInGame}
            onChange={handleHideItemChange}
          />
        </div>
      </div>

      {props.children}
    </>
  );
};
