import { Button } from '@progress/kendo-react-buttons';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import cloneDeep from 'lodash.clonedeep';
import { Col, Row } from 'react-bootstrap';
import { uuid } from '../../types/common-helper';
import { FeedbackDetailOptions } from '../../types/feedback';
import { FormInput } from '../form-input';

export interface AnswerCheckboxFeedbackProps {
  groupId?: string;
  options?: FeedbackDetailOptions[];
  answerOptionIndex?: number;
  isDisabled?: boolean;
  onChangeOptionAnswers?: (
    answers: FeedbackDetailOptions[],
    groupId: string,
    value?: string,
    indexOption?: number,
    action?: string
  ) => void;
}

export function AnswerCheckboxFeedback({
  groupId,
  options,
  isDisabled = false,
  onChangeOptionAnswers = () => {}
}: AnswerCheckboxFeedbackProps) {
  const onChange = (indexOption: number, type: string, value: string) => {
    let newAnswers: FeedbackDetailOptions[] = cloneDeep([...options!]);
    if (type === 'answer' && typeof value === 'string') {
      newAnswers.map((answer, answerIndex) => {
        if (indexOption === answerIndex) {
          answer.option = value;
        }
      });
    }

    onChangeOptionAnswers(newAnswers!, groupId!, value, indexOption, 'change');
  };

  const onAddOptionHandler = () => {
    let newAnswers: FeedbackDetailOptions[] = cloneDeep([...options!]);
    newAnswers.push({
      id: uuid(),
      optionResId: uuid(),
      option: ''
    });
    onChangeOptionAnswers(newAnswers!, groupId!);
  };

  const onDeleteOptionHandler = (indexOption: number) => {
    let value = options![indexOption].optionResId;
    let newAnswers: FeedbackDetailOptions[] =
      options?.filter((item, index) => index !== indexOption) ?? [];

    onChangeOptionAnswers(newAnswers!, groupId!, value, indexOption, 'delete');
  };

  return (
    <Col sm={12}>
      {options &&
        options?.map((item: FeedbackDetailOptions, index: number) => (
          <Row className={'p-2'} key={index}>
            <Col className={'d-flex align-items-center'}>
              <Checkbox
                disabled={true}
                size={'large'}
                name={groupId}
                value={item?.option}
                checked={false}
              />
              <FormInput
                name={'answerResId'}
                className={'w-90 ml-1'}
                disabled={isDisabled}
                value={item?.option}
                onChange={(e) =>
                  onChange(index, 'answer', e.target.value as string)
                }
              />
              {!isDisabled && (
                <span
                  className={
                    'material-symbols-outlined text-danger cursor-pointer'
                  }
                  onClick={() => onDeleteOptionHandler(index)}>
                  delete
                </span>
              )}
            </Col>
          </Row>
        ))}
      {!isDisabled && (
        <Row className={'mt-3'}>
          <Col className={'d-flex'}>
            <Button
              size={'small'}
              themeColor={'primary'}
              className={'text-light p-0'}
              onClick={onAddOptionHandler}>
              <span className={'material-symbols-outlined'}>add</span>
            </Button>
            <Label className={'ml-2'}>Add</Label>
          </Col>
        </Row>
      )}
    </Col>
  );
}
