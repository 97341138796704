import { GameDocument } from '../../../types/game-document';
import {
  EntityEditor,
  ResourceEntity,
  TimerEntity
} from '../../../types/game-document/';
import { uuid } from '../../../types/common-helper';
import { GetNextAssetNameAsync, MergeAssets } from './index';
import { CopyResourceAsync, SaveResourceAsync } from '../resources';
import { DeleteResourceAsync } from '../resources';
import cloneDeep from 'lodash.clonedeep';
import merge from 'lodash.merge';

/**
 * Adds a new Timer to the Game document.
 * @param gameDocument - The Game Document to modify
 * @param name - The Name of the new Timer
 * @param description - The Description for the new Timer
 * @returns The updated Game Document
 */
export const AddTimerAsync = async (
  gameDocument: GameDocument,
  name: string,
  description: string,
  titleResId: string
) => {
  let timers = gameDocument.assets.timers ?? [];
  let itemName = await GetNextAssetNameAsync(timers, name);

  timers.push({
    id: uuid(),
    name: itemName,
    description,
    titleResId,
    direction: 'down',
    repeats: false
  });

  return MergeAssets(gameDocument, timers, 'timers');
};

/**
 * Adds a new Timer to the Game document.
 * @param gameDocument - The Game Document to modify
 * @param timer - The new timer
 * @returns The updated Game Document
 */
export const AddTimerEntityAsync = async (
  gameDocument: GameDocument,
  timer: TimerEntity
) => {
  let timers = gameDocument.assets.timers ?? [];
  let itemName = await GetNextAssetNameAsync(timers, timer.name);
  timer.name = itemName;
  timers.push(timer);

  return MergeAssets(gameDocument, timers, 'timers');
};

/**
 * Deletes the identified Timer from the Game Document.
 * @param gameDocument - The Game Document to modify
 * @param timerId - The ID of the Timer to delete
 * @returns The updated Game Document
 */
export const DeleteTimerAsync = async (
  gameDocument: GameDocument,
  timerId: string
) => {
  let timers = gameDocument.assets.timers ?? [];
  let timerIndex = timers.findIndex((i) => i.id === timerId)!;
  if (timerIndex !== -1) {
    await DeleteResourceAsync(gameDocument, timers[timerIndex].titleResId!);
    timers.splice(timerIndex, 1);
  }
  return MergeAssets(gameDocument, timers, 'timers');
};

/**
 * Updates the identified Timer in the Game Document.
 * @param gameDocument - The Game Document to modify
 * @param timerId - The ID of the Timer to update
 * @param timer - The updated Timer
 * @constructor
 */
export const UpdateTimerAsync = async (
  gameDocument: GameDocument,
  timerId: string,
  timer: TimerEntity,
  renameDuplicate: boolean = true
) => {
  let timers = gameDocument.assets.timers ?? [];
  let timerIndex = timers.findIndex((i) => i.id === timerId)!;
  if (renameDuplicate)
    timer.name = await GetNextAssetNameAsync(timers, timer.name, timer.id);

  timers[timerIndex] = timer;

  return MergeAssets(gameDocument, timers, 'timers');
};

/**
 * Create a copy of the Title in the Game document.
 * @param gameDocument - The Game Document to modify
 * @param timerId - The ID of the Timer to copy
 * @param copiedTitleId - The title id to copy but different
 * @returns The updated Game Document
 */
export const CopyTimerAsync = async (
  gameDocument: GameDocument,
  timerId: string,
  copiedTitleId: string = uuid()
) => {
  let timers = gameDocument.assets.timers ?? [];
  let timerIndex = timers.findIndex((i) => i.id === timerId)!;

  if (timerIndex !== -1) {
    let timerCopy: TimerEntity = {
      ...timers[timerIndex],
      titleResId: uuid()
    };
    await CopyResourceAsync(
      gameDocument,
      timers[timerIndex].titleResId!,
      timerCopy.titleResId
    );

    timerCopy.id = copiedTitleId;
    timerCopy.name += '-copy';
    timerCopy.name = await GetNextAssetNameAsync(
      timers,
      timerCopy.name,
      timerCopy.id
    );

    timers.push(timerCopy);
  }
  return MergeAssets(gameDocument, timers, 'timers');
};

/**
 * Get all timers from game document local storage.
 * @param gameDocument - The Game Document as datasource
 * @constructor
 */
export const GetTimers = (gameDocument: GameDocument | undefined) => {
  return gameDocument?.assets?.timers ?? [];
};

/**
 * Get tasks by Ids from game document local storage.
 * @param gameDocument - The Game Document as datasource
 * @constructor
 */
export const GetTimersByIds = (
  gameDocument: GameDocument | undefined,
  ids: string[]
) => {
  return (
    gameDocument?.assets?.timers?.filter((x) => ids.indexOf(x.id) !== -1) ?? []
  );
};

/**
 * Get timers by Zone Id from game document local storage.
 * @param gameDocument - The Game Document as datasource
 * @constructor
 */
export const GetTimersByZoneId = (
  zoneId: string,
  gameDocument: GameDocument | undefined
) => {
  let timers: TimerEntity[] = [];

  if (gameDocument) {
    let zone = gameDocument?.assets?.zones?.find((x) => x.id === zoneId);
    const timerIds = zone?.timers?.map(function (e) {
      return e.timerAssId;
    });

    if (timerIds) {
      timers = GetTimersByIds(gameDocument, timerIds);
    }
  }

  return timers;
};

export const SaveTimerEntityEditorAsync = async (
  gameDocument: GameDocument,
  editorEntity: EntityEditor<TimerEntity>,
  resourceEntity: EntityEditor<ResourceEntity>[],
  renameDuplicate: boolean = true
) => {
  let entity = editorEntity.entity;
  let newGameDocument: GameDocument = cloneDeep(gameDocument);
  const saveTitleResource = SaveResourceAsync(
    newGameDocument,
    resourceEntity?.find((x) => x.entity?.id === entity?.titleResId)?.entity!
  );

  const [titleResponse] = await Promise.all([saveTitleResource]);

  if (editorEntity.entity.direction === undefined)
    editorEntity.entity.direction = 'down';
  if (editorEntity.entity.repeats === undefined)
    editorEntity.entity.repeats = false;

  if (editorEntity.isNew) {
    editorEntity.entity.id = uuid();
    newGameDocument = merge(
      await AddTimerEntityAsync(newGameDocument, editorEntity.entity)
    );
  } else {
    newGameDocument = merge(
      await UpdateTimerAsync(
        newGameDocument,
        editorEntity.entity.id,
        editorEntity.entity,
        renameDuplicate
      )
    );
  }

  newGameDocument = merge(newGameDocument, titleResponse);

  return newGameDocument;
};
