import { User } from '../types/user';
import { State } from '@progress/kendo-data-query';
import {
  DefaultGridSettings,
  DefaultPagerSettings
} from '../constants/grid-settings';
import { PageChangeEvent, Pager } from '@progress/kendo-react-data-tools';
import MediaCardBackground from './mediacard-bg';

export interface CardViewDataStateChangeEvent {
  dataState: State;
}

export interface AllUsersCardViewProps extends State {
  data?: User[];
  onUsernameClick?: (id: number) => void;
  onOrganisationClick?: (id?: number) => void;
  onDataStateChange?: (event: CardViewDataStateChangeEvent) => void;
  total?: number;
}
export const AllUsersCardView = ({
  data,
  onUsernameClick,
  onOrganisationClick,
  total = 0,
  ...props
}: AllUsersCardViewProps) => {
  const handleUsernameClick = (id: number) => {
    if (onUsernameClick) {
      onUsernameClick(id);
    }
  };

  const handlePageChange = (event: PageChangeEvent) => {
    raiseDataStateChangeEvent({ skip: event.skip, take: event.take });
  };

  /**
   * Raise the Grid DataState change event. I.e. filters have changed.
   * @param moreData
   */
  const raiseDataStateChangeEvent = (moreData: State) => {
    props.onDataStateChange?.({
      dataState: { ...getDataState(), ...moreData }
    });
  };

  const getDataState = () => {
    return {
      filter: props.filter,
      sort: props.sort,
      skip: props.skip,
      take:
        props.take !== undefined ? props.take : DefaultGridSettings.pageSize,
      group: props.group
    };
  };

  return (
    <div className={'mt-3'}>
      <div className={'row'}>
        {data &&
          data.map((user, index) => (
            <div
              className={
                'col-sm-12 col-md-6 col-lg-6 col-xl-4 d-flex align-items-stretch'
              }
              key={index}>
              <div className={'card mt-4 p-2 flex-fill'}>
                <div className={'d-flex gap-2'}>
                  <div
                    className={
                      'd-flex flex-shrink-0 justify-content-center align-items-center'
                    }>
                    <MediaCardBackground imageUrl={user.avatarMediaUrl!} />
                  </div>
                  <div className={'card-body-right p-2 d-flex flex-column'}>
                    <span
                      className={
                        'card-text align-middle link-primary cursor-pointer'
                      }
                      onClick={() => handleUsernameClick(user.id)}
                      role={'button'}>
                      {user?.fullName}
                    </span>
                    <span className={'card-text align-middle'}>
                      Organisation: {user.organisation}
                    </span>
                    <span className={'card-text align-middle'}>
                      Email: {user?.username}
                    </span>
                    <span className={'card-text align-middle'}>
                      Phone: {user?.phoneNumber}
                    </span>
                    <span className={'card-text align-middle'}>
                      Department: {user?.department}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className={'row mt-3'}>
        <div className={'col-md-12'}>
          <Pager
            onPageChange={handlePageChange}
            total={total}
            take={
              props.take !== undefined
                ? props.take
                : DefaultGridSettings.pageSize
            }
            skip={props.skip !== undefined ? props.skip : 0}
            {...DefaultPagerSettings}
          />
        </div>
      </div>
    </div>
  );
};
