import React, { useEffect, useState, useRef } from 'react';

interface VideoPlayer {
  url: string;
  type?: string;
  loop?: boolean;
}

export default function Video(props: VideoPlayer) {
  return (
    <>
      <div className="video-wrapper">
        {props.type === 'video' ? (
          <video key={props.url} className="video" loop controls preload="none">
            <source src={props.url} type="video/mp4"></source>
          </video>
        ) : (
          <img src={props.url} className={'img-fluid'} />
        )}
      </div>
    </>
  );
}
