import { StandardInput } from '../../../components/forms';
import React, { ComponentType, ReactNode } from 'react';
import { ResourceEntity, ZoneEntity } from '../../../types/game-document/';
import {
  CheckboxChangeEvent,
  InputChangeEvent
} from '@progress/kendo-react-inputs';
import { EditorMode } from '../../../types/editor-mode';
import StandardCheckbox from '../../../components/forms/standard-checkbox';
import { RequiredInput } from './zone-editor-window';

interface ZoneEditorProps {
  editorMode: EditorMode;
  entity: ZoneEntity;
  resources: ResourceEntity[];
  handleInputChange: (event: InputChangeEvent | CheckboxChangeEvent) => void;
  children?: ReactNode | undefined;
  requiredInputs?: RequiredInput[];
}

export const ZoneEditor: ComponentType<ZoneEditorProps> = ({
  editorMode,
  entity,
  resources,
  handleInputChange,
  requiredInputs,
  ...props
}: ZoneEditorProps) => {
  return (
    <>
      <StandardInput
        name={'name'}
        label={'Name'}
        value={entity?.name}
        onChange={handleInputChange}
        validationMessage={
          requiredInputs?.find((item) => item.name === 'name')?.errorMessage
        }
        autoFocus={true}
      />
      <StandardInput
        name={'description'}
        label={'Description'}
        value={entity?.description}
        onChange={handleInputChange}
      />
      <StandardInput
        name={'titleResId'}
        label={'Title'}
        value={
          resources.find((resource) => resource.id === entity?.titleResId)
            ?.value ?? ''
        }
        onChange={handleInputChange}
      />
      {editorMode === 'full' && (
        <>
          <StandardCheckbox
            name={'isVisible'}
            label={'Visible'}
            value={entity?.isVisible}
            onChange={handleInputChange}
          />
        </>
      )}
      {props.children}
    </>
  );
};
