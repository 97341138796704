import { Button } from '@progress/kendo-react-buttons';
import { ComboBox, ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { Error, Label } from '@progress/kendo-react-labels';
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab
} from '@progress/kendo-react-layout';
import { Col, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { StandardInput } from '../../components/forms';
import { BreadcrumbLocation, PrivatePage } from '../../components/private-page';
import { useAuth } from 'react-oidc-context';
import { appStore } from '../../stores/app-store';
import { useEffect, useState } from 'react';
import { Organisation } from '../../types/organisation';
import React from 'react';
import { InviteUserAsync } from '../../services/users';
import { toastStore } from '../../stores/toast-store';
import { PostEmailAvailabilityAsync } from '../../services/user-email';
import { PostAdminUserRolesAsync } from '../../services/roles';
import {
  Checkbox,
  CheckboxChangeEvent,
  InputChangeEvent
} from '@progress/kendo-react-inputs';
import { Permission, PermissionType } from '../../types/permission';
import Toolbar from '../../components/page-toolbar';
import { GetAllAsync } from '../../services/api';
import { PutAppNotificationByUserIdAsync } from '../../services/notification';
import { NotificationName } from '../../types/responses/notification-type-response';

export default function AdminInviteUser() {
  const auth = useAuth();
  const navigate = useNavigate();
  const breadcrumbs: BreadcrumbLocation[] = [
    { label: 'Dashboard', href: '/' },
    { label: 'Administration', href: '../administration' },
    { label: 'Users', href: '../administration/users' }
  ];

  const orgId = parseInt(auth.user?.profile.catalyst_org_id! as string);
  const [selected, setSelected] = React.useState<number>(0);
  const [emailError, setEmailError] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [organisationId, setOrganisationId] = useState<number>(orgId);
  const [organisationList, setOrganisationList] = useState<Organisation[]>([]);
  const [userPermission, setUserPermission] = useState<string[]>();
  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected);
  };

  const onBackHandler = () => {
    navigate('/administration/users');
  };

  const getOrganisations = async () => {
    GetAllAsync('organisations')
      .then((response) => {
        setOrganisationList(response.data as Organisation[]);
      })
      .catch((err) => console.error(err));
  };

  const onChangeOrganisationHandler = (e: ComboBoxChangeEvent) => {
    if (e?.value?.id !== undefined) {
      setOrganisationId(parseInt(e?.value?.id));
    }
  };

  const isEmailValid = (value: string) => {
    const emailRegex: RegExp = new RegExp(/\S+@\S+\.\S+/);

    return emailRegex.test(value) ? '' : 'Please enter a valid email.';
  };

  const emailChangeHandler = (e: InputChangeEvent) => {
    setEmail(e.target.value?.toString() ?? '');
  };

  const OnChangeRoleHandler = (e: CheckboxChangeEvent) => {
    onPermissionChange(e.target.name as PermissionType, e.value);
  };

  const onPermissionChange = (permission: string, value: boolean) => {
    let newPermission: string[] = [...(userPermission! ?? [])];

    let index = newPermission?.findIndex((x) => x === permission);

    if (value) {
      if (index === -1) {
        if (permission === 'Admin') {
          (auth.user?.profile.role as string[])
            .filter((o) => o.includes('Admin'))
            .forEach((role) => {
              if (!newPermission?.find((x) => x === role)) {
                newPermission.push(role);
                setUserPermission(newPermission);
              }
            });
        } else {
          newPermission.push(permission);
          setUserPermission(newPermission);
        }
      }
    } else {
      if (permission === 'Admin') {
        (auth.user?.profile.role as string[])
          .filter((o) => o.includes('Admin'))
          .forEach((role) => {
            let index = newPermission?.findIndex((x) => x === role);

            if (index > -1) {
              newPermission.splice(index, 1);
              setUserPermission(newPermission);
            }
          });
      } else {
        setUserPermission((prev) => prev?.filter((o) => o !== permission));
      }
    }
  };

  const onClickInviteHandler = async () => {
    try {
      appStore.showLoading();

      let isValidEmail = isEmailValid(email!);

      if (email === '') {
        let emailEmpty = 'Email is required.';
        toastStore.show('Email', <div>{emailEmpty}</div>, 'error');
        setEmailError(emailEmpty);
        return;
      }

      if (isValidEmail !== '') {
        toastStore.show('Email', <div>{isValidEmail}</div>, 'error');
        setEmailError(isValidEmail);
        return;
      }

      const available = await PostEmailAvailabilityAsync({
        emailAddress: email!
      });

      if (available) {
        let response = await InviteUserAsync(organisationId! ?? orgId, {
          firstName: '',
          lastName: '',
          emailAddress: { address: email }
        });

        if (response.id !== undefined) {
          const inAppNotification = [
            'Event.Created',
            'Event.Completed',
            'Game.Created',
            'Game.Published',
            'Game.Submitted',
            'Game.Approved',
            'Game.Duplicated',
            'Assessment.Created',
            'Assessment.Published',
            'Assessment.Submitted',
            'Assessment.Approved',
            'Assessment.Duplicated',
            'Feedback.Created',
            'Feedback.Published',
            'Feedback.Submitted',
            'Feedback.Approved',
            'Feedback.Duplicated',
            'GlobalLibrary.Game.Added',
            'GlobalLibrary.Assessment.Added',
            'GlobalLibrary.Feedback.Added'
          ];

          // set all in-app notification settings on for new registered user
          await PutAppNotificationByUserIdAsync(
            response?.id,
            inAppNotification as NotificationName[]
          );

          await PostAdminUserRolesAsync(
            organisationId! ?? orgId,
            response?.id,
            userPermission!
          );
          setEmail('');
        }

        toastStore.show(
          'Invite user',
          <div>
            User added.<br></br>
            <Link to={'/administration/users'}>Go to all users.</Link>
          </div>,
          'success'
        );
      } else {
        toastStore.show(
          'Users',
          <div>{`Email already registered.`}</div>,
          'error'
        );
      }
    } catch (err) {
      console.error(err);
    } finally {
      appStore.hideLoading();
    }
  };

  const defaultPermissions = () => {
    let userDefaultPermissions: string[] = [];
    (auth.user?.profile.role as PermissionType[]).forEach((f) => {
      if (!f.includes('Admin')) userDefaultPermissions.push(f);
    });
    setUserPermission(userDefaultPermissions!);
  };

  const childPermission = (auth.user?.profile.role as PermissionType[]).filter(
    (permission, index) =>
      permission.split('.')[0] === Permission.Admin && index > 0
  );

  const permissionKeyList = Object.keys(Permission) as PermissionType[];

  const currentUserRoles = auth.user?.profile.role as PermissionType[];

  const childUserRoles = (Object.keys(Permission) as PermissionType[]).filter(
    (permission, index) =>
      permission.split('.').length > 1 &&
      permission.split('.')[0] === Permission.Admin &&
      index > 0
  );

  const setRoleToDisable = (permission?: string, isMenuAdmin?: boolean) => {
    let result: PermissionType | undefined;
    result = currentUserRoles.find(
      (role) =>
        role.includes(permission!) &&
        (isMenuAdmin || (!isMenuAdmin && !role.includes('Admin')))
    );

    if (result) return false;
    return true;
  };

  useEffect(() => {
    getOrganisations();
    defaultPermissions();
  }, []);

  return (
    <PrivatePage breadcrumb={breadcrumbs} pageTitle={'Users'}>
      <Toolbar title={'New User'}>
        <Button
          className={'me-1'}
          themeColor={'secondary'}
          onClick={() => onBackHandler()}>
          Back
        </Button>
        <Button
          className={'me-1'}
          themeColor={'primary'}
          onClick={onClickInviteHandler}>
          Send invite
        </Button>
      </Toolbar>
      <Row className={'mt-3'}>
        <TabStrip selected={selected} onSelect={handleSelect}>
          <TabStripTab title={'Info'}>
            <div>
              <p>
                New users will receive an email to complete their log in
                details.
              </p>
              <StandardInput
                label={'Email'}
                value={email ?? ''}
                onChange={emailChangeHandler}></StandardInput>
              <Error>{emailError}</Error>

              <Label> Organisation</Label>
              <ComboBox
                data={organisationList}
                textField={'name'}
                filterable={true}
                clearButton={false}
                value={organisationList?.find((x) => x.id === organisationId)}
                onChange={onChangeOrganisationHandler}
                disabled={false}
              />
            </div>
          </TabStripTab>
          <TabStripTab title={'Permissions'}>
            <div>
              <div className={'ml-10'}>
                {permissionKeyList
                  .filter((fp) => fp.split('.')[0] !== Permission.Admin)
                  .map((key, index) => {
                    const marginTop = index > 0 ? 'mt-4' : '';
                    const marginLeft = key.split('.').length > 1 ? 'ml-5' : '';
                    return (
                      <Row className={`${marginTop} ${marginLeft}`} key={index}>
                        <Col size={'md-4'}>
                          <Checkbox
                            name={key}
                            label={Permission[key]}
                            size={'large'}
                            value={userPermission?.includes(key)}
                            onChange={OnChangeRoleHandler}
                            defaultChecked={true}
                            disabled={setRoleToDisable(key, false)}
                          />
                        </Col>
                      </Row>
                    );
                  })}

                {/* Admin role */}
                <Row className={'mt-4'}>
                  <Col size={'md-4'}>
                    <Checkbox
                      name={Permission.Admin}
                      label={Permission.Admin}
                      size={'large'}
                      value={userPermission?.includes(Permission.Admin)}
                      onChange={OnChangeRoleHandler}
                      disabled={setRoleToDisable(Permission.Admin, true)}
                    />
                  </Col>
                </Row>

                {/* child admin role */}
                {childUserRoles.map((key, index) => (
                  <Row className={'mt-4 ml-5'} key={`child_${index}`}>
                    <Col size={'md-4'}>
                      <Checkbox
                        name={key}
                        label={Permission[key]}
                        size={'large'}
                        value={userPermission?.includes(key)}
                        onChange={OnChangeRoleHandler}
                        disabled={setRoleToDisable(key, true)}
                      />
                    </Col>
                  </Row>
                ))}
              </div>
            </div>
          </TabStripTab>
        </TabStrip>
      </Row>
    </PrivatePage>
  );
}
