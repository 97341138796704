import { Checkbox } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import { Col, Row } from 'react-bootstrap';
import { Button } from '@progress/kendo-react-buttons';
import { FormInput } from '../form-input';
import { TaskContentAnswer } from '../../types/game-document/entities/task-content';
import cloneDeep from 'lodash.clonedeep';

export interface AnswerOptionProps {
  group?: string;
  options?: TaskContentAnswer[];
  answerOptionIndex?: number;
  isDisabled?: boolean;
  onAddOption?: () => void;
  onDeleteOption?: (index: number) => void;
  onChangeAnswers?: (answers: TaskContentAnswer[]) => void;
  handleInputChange?: (
    value: string,
    type: 'question' | 'answer',
    answerResId?: string
  ) => void;
}
export function AnswerCheckbox({
  group,
  options,
  isDisabled = false,
  onAddOption = () => {},
  onDeleteOption = () => {},
  onChangeAnswers = () => {},
  handleInputChange = () => {}
}: AnswerOptionProps) {
  const onCheckButtonChange = (index: number) => {
    if (options) {
      let newAnswers: TaskContentAnswer[] = cloneDeep([...options]);
      newAnswers[index].isChecked = !newAnswers[index].isChecked;
      onChangeAnswers(newAnswers);
    }
  };

  const onAddOptionHandler = () => {
    onAddOption();
  };

  const onDeleteOptionHandler = (index: number) => {
    onDeleteOption(index);
  };

  return (
    <>
      {options &&
        options.map((option: TaskContentAnswer, index: number) => (
          <Row className={'p-2'} key={index}>
            <Col className={'d-flex align-items-center'}>
              <Checkbox
                disabled={isDisabled}
                className={'p-1'}
                size={'large'}
                name={group}
                value={option?.description}
                checked={option?.isChecked}
                onChange={() => onCheckButtonChange(index)}
              />
              <FormInput
                name={'answerResId'}
                className={'w-90'}
                disabled={isDisabled}
                value={options[index]?.description}
                onChange={(e) => {
                  handleInputChange(
                    e.target.value as string,
                    'answer',
                    options[index].answerResId
                  );
                }}
              />
              {!isDisabled && (
                <span
                  className={
                    'material-symbols-outlined text-danger cursor-pointer'
                  }
                  onClick={() => onDeleteOptionHandler(index)}>
                  delete
                </span>
              )}
            </Col>
          </Row>
        ))}
      {!isDisabled && (
        <Row className={'mt-3'}>
          <Col className={'d-flex'}>
            <Button
              size={'small'}
              themeColor={'primary'}
              className={'text-light p-0'}
              onClick={onAddOptionHandler}>
              <span className={'material-symbols-outlined'}>add</span>
            </Button>
            <Label className={'ml-2'}>Add</Label>
          </Col>
        </Row>
      )}
    </>
  );
}
