import { PostAsync, PutAsync } from './api';

/**
 * Updates the Roles by completely replacing it.
 * @param organisationId - the organisation ID of the entity to update [PUT]
 * @param userId - the ID of the entity to update [PUT]
 * @param roles - the Roles to update [PUT]
 * @returns the updated User object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PUT
 */
export const PutUserRolesAsync = async (
  organisationId: number,
  userId: number,
  roles: string[]
) =>
  PutAsync<string[]>(
    `organisations/${organisationId}/users/${userId}/roles`,
    roles
  );

/**
 * Reseller Updates the Roles by completely replacing it.
 * @param userId - the ID of the entity to update [PUT]
 * @param roles - the Roles to update [PUT]
 * @returns the updated User object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PUT
 */
export const PutResellerUserRolesAsync = async (
  userId: number,
  roles: string[]
) => PutAsync<string[]>(`users/${userId}/roles`, roles);

/**
 * Reseller Applies a POST to the Role.
 * requiring only the fields and values that require modification.
 * @param userId - the ID of the entity to update [PUT]
 * @param roles - the Roles to update [PUT]
 * @returns the updated User object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/POST
 */
export const PostResellerUserRolesAsync = async (
  userId: number,
  roles: string[]
) => PostAsync<string[]>(`users/${userId}/roles`, roles);

/**
 * Admin Applies a POST to the Role.
 * requiring only the fields and values that require modification.
 * @param userId - the ID of the entity to update [PUT]
 * @param roles - the Roles to update [PUT]
 * @returns the updated User object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/POST
 */
export const PostAdminUserRolesAsync = async (
  organisationId: number,
  userId: number,
  roles: string[]
) =>
  PostAsync<string[]>(
    `organisations/${organisationId}/users/${userId}/roles`,
    roles
  );
