import React, { useEffect, useState } from 'react';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridNoRecords,
  GridDetailRowProps,
  GridExpandChangeEvent
} from '@progress/kendo-react-grid';
import {
  DataResult,
  State,
  toDataSourceRequestString
} from '@progress/kendo-data-query';
import { GetExpandedEventsAsAdmin } from '../services/events';
import { ColumnMenu } from '../components/columnMenu';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import {
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../components/grid/grid-tool-bar';
import { DefaultGridSettings } from '../constants/grid-settings';
import { NoRecords } from '../components/grid/no-records';
import { formatDateToDDMMYYYY } from '../utils/date';
import EventsScheduler from './events-scheduler';
import { ExpandedEvent } from '../types/event';

const initialDataState: State = {
  sort: [{ field: 'startDateUtc', dir: 'desc' }],
  filter: DefaultGridSettings.initialDataState.filter,
  skip: DefaultGridSettings.initialDataState.skip,
  group: DefaultGridSettings.initialDataState.group
};

const GridSection = <T extends { code?: string; name?: string }>({
  title,
  data,
  headers,
  cellRenderer
}: {
  title: string;
  data: T[];
  headers: string[];
  cellRenderer: (item: T) => React.ReactNode;
}) => (
  <>
    {data.length !== 0 && (
      <>
        <h2>{title}</h2>
        <div
          className="grid-event-detail"
          style={{ gridTemplateColumns: `repeat(${headers.length}, 1fr)` }}>
          {headers.map((header, index) => (
            <div key={`header-${index}`} className="grid-header">
              {header}
            </div>
          ))}
          {data.map((item, index) => (
            <div key={`row-${index}`} className="grid-row">
              {cellRenderer(item)}
            </div>
          ))}
        </div>
      </>
    )}
  </>
);

const DetailComponent = (props: GridDetailRowProps) => {
  const dataItem = props.dataItem as ExpandedEvent;

  return (
    <>
      <GridSection
        title="Assessments"
        data={dataItem.eventPlaylists}
        headers={[
          'Assessment Code',
          'Assessment Name',
          'Game Name',
          'Assessment Approx Pax',
          'Game Approx Pax'
        ]}
        cellRenderer={(item) => (
          <>
            <div className="grid-cell">{item.code}</div>
            <div className="grid-cell">{item.assessmentName}</div>
            <div className="grid-cell">{item.gameName}</div>
            <div className="grid-cell">{item.assessmentApproxPax}</div>
            <div className="grid-cell">{item.gameApproxPax}</div>
          </>
        )}
      />
      <GridSection
        title="Feedbacks"
        data={dataItem.eventFeedbacks}
        headers={['Code', 'Name']}
        cellRenderer={(item) => (
          <>
            <div className="grid-cell">{item.code}</div>
            <div className="grid-cell">{item.name}</div>
          </>
        )}
      />
    </>
  );
};

const EventsManagementGrid = () => {
  const [dataState, setDataState] = useState<State>({
    ...initialDataState,
    take: DefaultGridSettings.initialDataState.take
  });
  const [events, setEvents] = useState<DataResult>(() => ({
    data: [],
    total: 0
  }));
  const [gridMode, setGridMode] = useState<string>('grid');

  const fetchEvents = async () => {
    GetExpandedEventsAsAdmin(`${toDataSourceRequestString(dataState)}`)
      .then((response) => {
        const data = response.data.map((item) => ({
          ...item,
          expanded: false
        }));

        response.data = data;
        setEvents(response);
      })
      .catch((ex) => {
        console.error(ex);
      });
  };

  useEffect(() => {
    fetchEvents();
    //eslint-disable-next-line
  }, [dataState]);

  const gameColumn = (props: GridCellProps) => {
    return (
      <td>
        <span>{props.dataItem.type}</span>
      </td>
    );
  };

  const gridExportRef = React.useRef<ExcelExport | null>(null);

  const DateCell = (props: GridCellProps) => {
    return <td>{formatDateToDDMMYYYY(props.dataItem.startDateUtc, true)}</td>;
  };

  const expandChange = (event: GridExpandChangeEvent) => {
    const updatedData = events.data.map((item) => ({
      ...item,
      expanded:
        item.id === event.dataItem.id ? !event.dataItem.expanded : item.expanded
    }));

    setEvents({
      ...events,
      data: updatedData
    });
  };

  return (
    <>
      <div className={'pt-2'}>
        <GridToolBar
          searchPlaceholder={'Search events'}
          columnsToSearch={['name', 'organisation']}
          showCalendarMode={true}
          showCardMode={false}
          exportRef={gridExportRef}
          onGridModeChange={(e) => {
            const newState = {
              ...initialDataState
            };
            if (e.gridMode === 'grid') {
              newState.take = DefaultGridSettings.initialDataState.take;
            }
            setDataState(newState);
            setGridMode(e.gridMode);
          }}
          {...dataState}
          onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
            setDataState(e.dataState);
          }}></GridToolBar>
        <ExcelExport
          data={events.data}
          ref={gridExportRef}
          fileName={`all-events.xlsx`}>
          {gridMode === 'grid' && (
            <Grid
              id={'grid-events'}
              pageable={DefaultGridSettings.pagerSettings}
              sortable={true}
              data={events}
              {...dataState}
              onDataStateChange={(e: GridDataStateChangeEvent) => {
                setDataState(e.dataState);
              }}
              detail={DetailComponent}
              expandField="expanded"
              onExpandChange={expandChange}>
              <Column
                field={'startDateUtc'}
                title={'Start date'}
                cell={DateCell}
              />
              <Column field={'endDateUtc'} title={'End date'} cell={DateCell} />
              <Column field={'name'} title={'Name'} columnMenu={ColumnMenu} />
              <Column cell={gameColumn} field="Type" title={'Type'} />
              <Column
                field={'organisation'}
                title={'Organisation'}
                columnMenu={ColumnMenu}
              />
              <Column
                field={'approxPax'}
                title={'Pax'}
                columnMenu={ColumnMenu}
              />
              <Column
                field={'status'}
                title={'Status'}
                columnMenu={ColumnMenu}
              />
              <GridNoRecords>
                <NoRecords />
              </GridNoRecords>
            </Grid>
          )}
          {gridMode === 'cal' && <EventsScheduler eventData={events} />}
        </ExcelExport>
      </div>
    </>
  );
};
export default EventsManagementGrid;
