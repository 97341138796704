import {
  DataResult,
  State,
  toDataSourceRequestString
} from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import {
  GridColumn as Column,
  Grid,
  GridCellProps,
  GridColumnMenuFilter,
  GridDataStateChangeEvent,
  GridNoRecords
} from '@progress/kendo-react-grid';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ColumnMenu } from '../components/columnMenu';
import { ActionCell, ActionsCell } from '../components/grid/actions-cell';
import {
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../components/grid/grid-tool-bar';
import { NoRecords } from '../components/grid/no-records';
import Toolbar from '../components/page-toolbar';
import { DefaultGridSettings } from '../constants/grid-settings';
import {
  copyAssessmentToLibrary,
  getGlobalAssessmentById,
  getGlobalAssessments,
  getGlobalContentByAssessmentIdAsync,
  getGlobalDocumentLatestAsync
} from '../services/assessment';
import { appStore } from '../stores/app-store';
import { toastStore } from '../stores/toast-store';
import { Assessment } from '../types/assessment';
import { CustomFilterLanguage } from './filter/custom-filter-language';
import { GlobalAssessmentCardView } from './global-assessment-cardview';
import { CardViewDataStateChangeEvent } from './organisations-cardview';
import merge from 'lodash.merge';
import { formatDateToDDMMYYYY } from '../utils/date';
import HelpSupport from '../components/help-support';

const initialDataState: State = {
  sort: [{ field: 'title', dir: 'asc' }],
  ...DefaultGridSettings.initialDataState
};

const GlobalAssessmentGrid = () => {
  const navigate = useNavigate();

  const [dataState, setDataState] = useState<State>(initialDataState);
  const [gridMode, setGridMode] = useState<string>('grid');
  const [assessments, setAssessments] = useState<DataResult>(() => ({
    data: [],
    total: 0
  }));

  const fetchAssessments = async () => {
    try {
      appStore.showLoading();
      const response = await getGlobalAssessments(
        `${toDataSourceRequestString(dataState)}`
      );

      setAssessments(response);
    } catch (ex) {
      console.error(ex);
    } finally {
      appStore.hideLoading();
    }
  };

  useEffect(() => {
    fetchAssessments();
  }, [dataState]);

  const handleTitleClick = (id: number) => {
    navigate(`../global-assessment-details/${id}`);
  };

  const onColumnTitleClick = (props: GridCellProps) => (
    <EditCommandCell {...props} />
  );

  const EditCommandCell = (props: GridCellProps) => {
    return (
      <td>
        <span
          className={'link-primary cursor-pointer p-0 text-start'}
          onClick={() => handleTitleClick(props.dataItem.id)}>
          {props.dataItem.name}
        </span>
      </td>
    );
  };

  const onActionRow = (props: GridCellProps) => {
    return (
      <>
        <ActionsCell {...props}>
          <ActionCell
            title={'View assessment'}
            icon={'visibility'}
            themeColor={'primary'}
            className={'fs-2'}
            onClick={() => handleTitleClick(props.dataItem.id)}
          />
          <ActionCell
            title={'Copy assessment'}
            icon={'content_copy'}
            themeColor={'primary'}
            className={'fs-2'}
            onClick={() => onCopyAssessment(props.dataItem)}
          />
        </ActionsCell>
      </>
    );
  };

  const onCopyAssessment = async (assessments: Assessment) => {
    try {
      appStore.showLoading();

      const document = await getGlobalDocumentLatestAsync(assessments.id);

      // combine assessment and assessment documents (resources)
      let assess1 = await getGlobalAssessmentById(assessments.id);
      assess1.source = 'Imported';
      let assess2 = await getGlobalContentByAssessmentIdAsync(
        assessments.id,
        document.id
      );

      let mergedAssessment = merge(assess1, assess2);

      await copyAssessmentToLibrary(mergedAssessment);

      toastStore.show(
        'Assessment List',
        <div>Assessment copied to Assessment list.</div>,
        'success'
      );
    } catch (err: any) {
      toastStore.show(
        'Assessment List',
        <div>Failed to copy to Assessment list.</div>,
        'error'
      );
    } finally {
      appStore.hideLoading();
      fetchAssessments();
    }
  };

  const gridExportRef = useRef<ExcelExport | null>(null);

  const DateCell = (props: GridCellProps) => {
    return <td>{formatDateToDDMMYYYY(props.dataItem.createdDateUtc, true)}</td>;
  };

  const CellEllipsisAndTooltip = (props: GridCellProps) => {
    return (
      <td title={props.dataItem.language}>
        <div className={'grid-ellipsis-cell'}>{props.dataItem.language}</div>
      </td>
    );
  };

  return (
    <>
      <Toolbar title={`${' Global Assessment'}`}>
        <HelpSupport
          title="Global Library - Assessment"
          url="https://forum.catalystglobal.com/t/global-library-assessment"
        />
      </Toolbar>
      <div className={'pt-2'}>
        <GridToolBar
          searchPlaceholder={'Search assessment'}
          columnsToSearch={['name']}
          exportRef={gridExportRef}
          onGridModeChange={(e) => {
            setGridMode(e.gridMode);
          }}
          {...dataState}
          onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
            setDataState(e.dataState);
          }}></GridToolBar>
        <ExcelExport
          data={assessments.data}
          ref={gridExportRef}
          fileName={`assessment.xlsx`}>
          {gridMode === 'grid' && (
            <Grid
              id={'grid-assessment'}
              pageable={DefaultGridSettings.pagerSettings}
              sortable={true}
              data={assessments}
              {...dataState}
              onDataStateChange={(e: GridDataStateChangeEvent) => {
                setDataState(e.dataState);
              }}>
              <Column
                field={'name'}
                title={'Name'}
                columnMenu={ColumnMenu}
                cell={onColumnTitleClick}
              />
              <Column
                field={'createdDateUtc'}
                title={'Created'}
                cell={DateCell}
              />
              <Column
                field={'version'}
                title={'Version'}
                columnMenu={ColumnMenu}
              />
              <Column
                field={'language'}
                title={'Languages'}
                cell={CellEllipsisAndTooltip}
                columnMenu={(props: any) => (
                  <GridColumnMenuFilter
                    {...props}
                    filterUI={CustomFilterLanguage}
                  />
                )}
              />
              <Column
                field={'organisation'}
                title={'Organisation Name'}
                columnMenu={ColumnMenu}
              />
              <Column title={'Actions'} cell={onActionRow} width={120} />
              <GridNoRecords>
                <NoRecords />
              </GridNoRecords>
            </Grid>
          )}

          {gridMode === 'card' && (
            <GlobalAssessmentCardView
              data={assessments.data}
              onTitlelick={handleTitleClick}
              onCopy={onCopyAssessment}
              onView={handleTitleClick}
              total={assessments.total}
              {...dataState}
              onDataStateChange={(e: CardViewDataStateChangeEvent) => {
                setDataState(e.dataState);
              }}
            />
          )}
        </ExcelExport>
      </div>
    </>
  );
};
export default GlobalAssessmentGrid;
