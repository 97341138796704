import MenuItem from '../components/menu-item';

/**
 * The menu used for the diagnostics area layouts.
 */
const DiagnosticsMenu = () => {
  return (
    <aside className={'app-side-nav overflow-scroll'}>
      <div className={'logo'} />
      <hr />
      {/* prettier-ignore */}
      <div className={'aside-menu'}>
        <MenuItem to={'/'} icon={'chevron_left'} label={'Back to dashboard'} className={'text-success'} />
      </div>
      <hr />
      {/* prettier-ignore */}
      <div className={'aside-menu'}>
        <MenuItem to={''} icon={'developer_mode'} label={'Overview'} />
        <MenuItem to={'user'} icon={'data_object'} label={'User'} />
        <MenuItem to={'auth'} icon={'data_object'} label={'Auth settings'} />
      </div>
    </aside>
  );
};

export default DiagnosticsMenu;
