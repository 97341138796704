import React from 'react';
import { EditorTools, EditorToolsSettings } from '@progress/kendo-react-editor';

const CustomFormatBlock: React.FC = (props) => {
  const CustomFormatBlock = EditorTools.createFormatBlockDropDownList(
    EditorToolsSettings.formatBlock
  );

  return (
    <CustomFormatBlock
      {...props}
      style={{
        minWidth: '210px'
      }}
    />
  );
};

export default CustomFormatBlock;
