import { PrivatePage } from '../../components/private-page';
import { useEffect } from 'react';
import { ctGameDefault, ctGameTitle } from '../../constants/text';
import UserCard from './overview/user-card';
import SupportTicketCard from './overview/support-ticket-card';
import ApprovalRequestCard from './overview/approval-request-card';

const Administration = () => {
  useEffect(() => {
    document.title = ctGameTitle + ' - Overview';

    return () => {
      // return default value when component unmounts
      document.title = ctGameDefault;
    };
  }, []);

  return (
    <PrivatePage
      pageTitle={'Administration'}
      breadcrumb={[
        { label: 'Dashboard', href: 'dashboard' },
        { label: 'Administration', href: 'administration' }
      ]}>
      <div
        className={'row gap-4'}
        style={{
          paddingLeft: '0.75rem',
          paddingRight: '0.75rem'
        }}>
        {[<UserCard />, <SupportTicketCard />, <ApprovalRequestCard />].map(
          (card, idx) => (
            <div className={'col-3 dashboard-content-bg border'} key={idx}>
              {card}
            </div>
          )
        )}
      </div>
    </PrivatePage>
  );
};

export { default as Environment } from './environment';
export { default as Environments } from './environments';
export default Administration;
