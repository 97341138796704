import { useEffect } from 'react';
import { PrivatePage } from '../../components/private-page';
import { ctGameDefault, ctGameTitle } from '../../constants/text';
import AssessmentGrid from '../../features/assessment-grid';
export default function Assessment() {
  useEffect(() => {
    document.title = ctGameTitle + '- Assessment';
    return () => {
      document.title = ctGameDefault;
    };
  }, []);
  return (
    <PrivatePage
      breadcrumb={[
        {
          label: 'Dashboard',
          href: '../dashboard'
        },
        {
          label: 'My Library',
          href: '../mylibrary/assessment'
        },
        {
          label: 'Assessment',
          href: '../mylibrary/assessment'
        }
      ]}
      pageTitle={'My library'}>
      <AssessmentGrid />
    </PrivatePage>
  );
}
