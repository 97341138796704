import { LinkProps, NavLink } from 'react-router-dom';
import React, { useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { isEmpty } from 'lodash';
import { Permission } from '../types/permission';

interface MenuItemProps extends LinkProps {
  icon: string;
  label: string;
  end?: boolean;
  expanded?: boolean;
  iconSet?: string | 'outlined' | 'light';
  requiredRole?: string;
}

const getPermissionKey = (value: string) => {
  const indexOfS = Object.values(Permission).indexOf(
    value as unknown as Permission
  );

  const key = Object.keys(Permission)[indexOfS];

  return key;
};

const MenuItem = ({
  icon,
  label,
  children,
  to,
  end,
  expanded = false,
  iconSet = 'outlined',
  requiredRole,
  ...props
}: MenuItemProps) => {
  const auth = useAuth();
  const role = getPermissionKey(requiredRole!);
  const isInRole = !isEmpty(requiredRole)
    ? (auth.user?.profile.role as string[]).includes(role!)
    : true;
  const [isExpanded, setIsExpanded] = useState(expanded);
  const chevronIcon = isExpanded ? 'expand_more' : 'chevron_right';
  const chevronClass = isExpanded
    ? 'aside-menu__sub--expanded'
    : 'aside-menu__sub--collapsed';
  return (
    <>
      {isInRole && (
        <>
          <NavLink
            to={to}
            end={end ?? true}
            onClick={() => (children ? setIsExpanded(!isExpanded) : null)}
            {...props}
            className={`aside-menu__item ${
              children ? 'aside-menu__parent' : props.className ?? ''
            }`.trim()}>
            <span className={'item__icon'}>
              <span
                className={`material-symbols-outlined material-symbols-outlined--${iconSet}`}>
                {icon}
              </span>
            </span>
            <span className={'item__label'}>{label}</span>
            {children && (
              <span
                className={'item__chevron material-symbols-outlined'}
                onClick={() => setIsExpanded(!isExpanded)}>
                {chevronIcon}
              </span>
            )}
          </NavLink>
          {children && (
            <div className={`aside-menu__sub ${chevronClass}`}>{children}</div>
          )}
        </>
      )}
    </>
  );
};

export default MenuItem;
