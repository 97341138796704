import { StandardInput } from '../../../components/forms';
import { ComponentType, ReactNode, useContext, useState } from 'react';
import {
  ResourceEntity,
  TaskContentEntity,
  TaskEntity
} from '../../../types/game-document/';
import { EditorMode } from '../../../types/editor-mode';
import { ComboboxInput } from '../../../components/form-input';
import RequiredFields from '../../../types/required-fields';
import TaskEditorFull from './task-editor-full';
import { TaskEvent } from '../../../types/game-document/entities/task';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AddResourceAsync,
  AddTaskContentAsync,
  GetTasksHasNoBoundary,
  UpdateGameDocState
} from '../../../utils/game-document';
import { GameDocumentContext } from '../../../contexts/game-document';
import { TaskBasicView } from './task-basic-view';
import { UploadedImage } from '../image-resource/resource-window';
import { uuid } from '../../../types/common-helper';

interface TaskEditorProps {
  editorMode: EditorMode;
  entity: TaskEntity;
  resources: ResourceEntity[];
  handleTaskChange: (
    field: keyof TaskEntity,
    value: boolean | string | TaskEvent
  ) => void;
  handleTaskResourceUpload?: (
    field: keyof TaskEntity,
    upload: UploadedImage
  ) => void;
  children?: ReactNode | undefined;
  imageResourceVisibility?: boolean | undefined;
  requiredFields?: RequiredFields<TaskEntity>[];
  taskContents?: TaskContentEntity[];
  //Existing task only show tasks that have no boundaries/coordinate
  showExistingTask?: boolean;
  handleExistingTask?: (taskId: string) => void;
}

export const TaskEditor: ComponentType<TaskEditorProps> = ({
  editorMode,
  entity,
  resources,
  handleTaskChange,
  handleTaskResourceUpload = () => {},
  taskContents,
  imageResourceVisibility,
  requiredFields,
  showExistingTask,
  handleExistingTask = () => {},
  ...props
}: TaskEditorProps) => {
  const navigate = useNavigate();
  const [state, setState] = useContext(GameDocumentContext);
  const [selectedTask, setSelectedTask] = useState<TaskEntity | undefined>(
    undefined
  );
  const { gameId } = useParams();

  const editTask = () => {
    if (entity.taskContentId === '-') {
      // create new one if selected `create new`
      if (state.gameDocument) {
        const taskContentId = uuid();
        const titleGuid = uuid();
        const preMessageGuid = uuid();
        const contentGuid = uuid();
        const addTitleResource = AddResourceAsync(
          state.gameDocument!,
          'Title',
          '',
          'text',
          '',
          titleGuid
        );
        const addContentResource = AddResourceAsync(
          state.gameDocument!,
          'Content',
          '',
          'text-md',
          '',
          contentGuid
        );
        const addPreMessageResource = AddResourceAsync(
          state.gameDocument!,
          'PreMessage',
          '',
          'text-md',
          '',
          preMessageGuid
        );

        Promise.all([
          addTitleResource,
          addContentResource,
          addPreMessageResource
        ]).then(() => {
          AddTaskContentAsync(
            state.gameDocument!,
            `${entity.name} - task content`,
            `${entity.name} - task content`,
            'HTML',
            titleGuid,
            preMessageGuid,
            contentGuid,
            taskContentId
          ).then((updatedGameDocument) => {
            setState((state) => UpdateGameDocState(state, updatedGameDocument));
          });
          handleTaskChange('taskContentId', taskContentId);
          navigate(`/designer/${gameId}/assets/task-content/${taskContentId}`);
        });
      }
    } else {
      // navigate if selected existing task content
      navigate(
        `/designer/${gameId}/assets/task-content/${entity.taskContentId}`
      );
    }
  };

  return (
    <>
      {showExistingTask && (
        <div
          className={'d-flex justify-content-space-between align-items-center'}>
          <ComboboxInput
            label={'Use existing task'}
            placeholder={'Select existing task'}
            data={GetTasksHasNoBoundary(state?.gameDocument)}
            textField={'name'}
            name={'existing-task-id'}
            onChange={(e) => {
              if (!e.value) {
                setSelectedTask(undefined);
                handleExistingTask('');
              } else {
                setSelectedTask(e.target.value as TaskEntity);
                handleExistingTask(e?.target?.value?.id);
              }
            }}
          />
        </div>
      )}
      {selectedTask && <TaskBasicView entity={selectedTask} />}
      {!selectedTask && (
        <>
          {editorMode === 'basic' && (
            <>
              <StandardInput
                name={'name'}
                label={'Name'}
                value={entity.name}
                onChange={(e) =>
                  handleTaskChange('name', e.target.value as string)
                }
                validationMessage={
                  requiredFields?.find((task) => task.name === 'name')
                    ?.errorMessage ?? ''
                }
                autoFocus={true}
              />
              <StandardInput
                name={'description'}
                label={'Description'}
                value={entity.description}
                onChange={(e) =>
                  handleTaskChange('description', e.target.value as string)
                }
              />
              <StandardInput
                name={'titleResId'}
                className={'mb-2'}
                label={'Title'}
                value={
                  resources.find(
                    (resource) => resource?.id === entity.titleResId
                  )?.value ?? ''
                }
                validationMessage={
                  requiredFields?.find((task) => task.name === 'titleResId')
                    ?.errorMessage ?? ''
                }
                onChange={(e) =>
                  handleTaskChange('titleResId', e.target.value as string)
                }
              />
              <div
                className={
                  'd-flex justify-content-space-between align-items-center'
                }>
                <ComboboxInput
                  label={'Task content'}
                  placeholder={'Select task content'}
                  data={taskContents}
                  textField={'name'}
                  value={
                    entity.taskContentId &&
                    taskContents?.find((x) => x.id === entity.taskContentId)
                  }
                  name={'taskContentId'}
                  onChange={(e) =>
                    handleTaskChange(
                      'taskContentId',
                      (e.target.value as TaskContentEntity)?.id ?? ''
                    )
                  }
                />
                {entity.taskContentId && entity.id && (
                  <div className="d-flex align-items-end pb-2 h-100 text-primary">
                    <span
                      className={
                        'material-symbols-outlined cursor-pointer text-decoration-none'
                      }
                      onClick={editTask}>
                      edit_square
                    </span>
                  </div>
                )}
              </div>
            </>
          )}
          {editorMode === 'full' && (
            <TaskEditorFull
              entity={entity}
              taskContents={taskContents}
              requiredFields={requiredFields}
              resources={resources}
              handleTaskChange={handleTaskChange}
              handleTaskResourceUpload={handleTaskResourceUpload}
            />
          )}

          {props.children}
        </>
      )}
    </>
  );
};
