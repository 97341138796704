import { KendoGridResult } from '../types/KendoGridResult';
import { User } from 'oidc-client-ts';
import { parseDate } from '@progress/kendo-intl';

/**
 * The V2 API Endpoint Url for Catalyst Games Portal API.
 * @remarks DOES NOT End with a trailing slash /
 */
export const ApiBaseUrl = `${
  window?._env_?.REACT_APP_API_BASE_URL || process.env.REACT_APP_API_BASE_URL
}/api/v2`;

export const GetUser = () => {
  const storageKey = `oidc.user:${
    window?._env_?.REACT_APP_AUTHORITY || process.env.REACT_APP_AUTHORITY
  }:${window?._env_?.REACT_APP_CLIENT_ID || process.env.REACT_APP_CLIENT_ID}`;
  const oidcStorage = sessionStorage.getItem(storageKey);
  if (!oidcStorage) {
    return null;
  }
  return User.fromStorageString(oidcStorage);
};

/**
 * Accept and Content-Type headers for PUT and PATCH methods.
 * @internal
 */
export const jsonHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

/**
 * Gets the bearer/access token from the current user.
 */
export const GetBearerHeader = () => ({
  authorization: `Bearer ${GetUser()?.access_token ?? ''}`
});

/**
 * Horrible way of converting all date properties of an object from strings to dates...
 * @param intl - Kendo IntlService / locale for conversion
 * @param o - Object to parse
 * @remarks
 * Not resilient to case or naming convention changes.
 * Dates must end with DateUtc.
 */
export const ConvertObjectDates = (o: any) => {
  Object.keys(o).forEach((k: string) => {
    if (k.endsWith('DateUtc') && o[k]) {
      o[k] = parseDate(o[k]);
    }
  });
  return o;
};

/**
 * Gets ALL entities encapsulated in a paged payload.
 * @param endpoint - the API v2 Endpoint for entity GET
 * @returns all entity objects.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export async function GetAllAsync<ResponseType>(
  endpoint: string
): Promise<KendoGridResult<ResponseType>> {
  return fetch(`${ApiBaseUrl}/${endpoint}`, {
    method: 'GET',
    headers: { ...GetBearerHeader() }
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json().then((data) => {
      const dataResult = data as KendoGridResult<ResponseType>;
      dataResult.data.forEach((o: any) => {
        ConvertObjectDates(o);
      });
      return dataResult;
    });
  });
}

/**
 * adds new entity.
 * @param endpoint - the API v2 Endpoint for entity POST
 * @param entity - the entity to add [POST]
 * @returns the added entity object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/POST
 */
export async function PostAsync<ResponseType>(
  endpoint: string,
  entity: ResponseType
): Promise<ResponseType> {
  return fetch(`${ApiBaseUrl}/${endpoint}`, {
    method: 'POST',
    headers: {
      ...jsonHeaders,
      ...GetBearerHeader()
    },
    body: JSON.stringify(entity)
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response
        .json()
        .then((data) => ConvertObjectDates(data) as ResponseType);
    })
    .catch((error) => {
      console.log(error);
      throw new Error(error);
    });
}

/**
 * Gets the entity by id.
 * @param endpoint - the API v2 Endpoint for entity GET [by id]
 * @returns the entity object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export async function GetAsync<ResponseType>(
  endpoint: string
): Promise<ResponseType> {
  return fetch(`${ApiBaseUrl}/${endpoint}`, {
    method: 'GET',
    headers: { ...GetBearerHeader() }
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response
      .json()
      .then((data) => ConvertObjectDates(data) as ResponseType);
  });
}

/**
 * Updates the entity by completely replacing the object.
 * @param endpoint - the API v2 Endpoint for entity PUT
 * @param entity - the entity to update [PUT]
 * @returns the updated entity object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PUT
 */
export async function PutAsync<ResponseType>(
  endpoint: string,
  entity: ResponseType
): Promise<ResponseType> {
  return fetch(`${ApiBaseUrl}/${endpoint}`, {
    method: 'PUT',
    headers: {
      ...jsonHeaders,
      ...GetBearerHeader()
    },
    body: JSON.stringify(entity)
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response
      .json()
      .then((data) => ConvertObjectDates(data) as ResponseType);
  });
}

/**
 * Applies a PATCH to the entity. This can be considered a partial update
 * requiring only the fields and values that require modification.
 * @param endpoint - the API v2 Endpoint for entity PATCH
 * @param entity - the entity or partial entity to update [PATCH]
 * @returns the updated entity object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PATCH
 */
export async function PatchAsync<ResponseType>(
  endpoint: string,
  entity: ResponseType | any
): Promise<ResponseType> {
  return fetch(`${ApiBaseUrl}/${endpoint}`, {
    method: 'PATCH',
    headers: {
      ...jsonHeaders,
      ...GetBearerHeader()
    },
    body: JSON.stringify(entity)
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response
      .json()
      .then((data) => ConvertObjectDates(data) as ResponseType);
  });
}

/**
 * Deletes an Entity <ResponseType> based on the entityId.
 * @param endpoint - the API V2 Endpoint for entity DELETE
 * @returns the deleted entity object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/DELETE
 */
export async function DeleteAsync<ResponseType>(
  endpoint: string,
  entity?: ResponseType | any
): Promise<ResponseType> {
  return fetch(`${ApiBaseUrl}/${endpoint}`, {
    method: 'DELETE',
    headers: {
      ...jsonHeaders,
      ...GetBearerHeader()
    },
    body: JSON.stringify(entity)
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response
      .json()
      .then((data) => ConvertObjectDates(data) as ResponseType);
  });
}

/**
 * Applied a Upload file to the media
 * @param endpoint - the API V2 Endpoint for upload file
 * @param file - file upload
 * @returns the added entity object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/POST
 */
export async function PostFileAsync<ResponseType>(
  endpoint: string,
  file: File | Blob
): Promise<ResponseType> {
  let formData = new FormData();
  formData.append('file', file as Blob);
  return fetch(`${ApiBaseUrl}/${endpoint}`, {
    method: 'POST',
    headers: {
      ...GetBearerHeader()
    },
    body: formData
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response
        .json()
        .then((data) => ConvertObjectDates(data) as ResponseType);
    })
    .catch((error) => {
      console.log(error);
      throw new Error(error);
    });
}

/**
 * Download file.
 * @param endpoint - the API v2 Endpoint for entity POST
 * @param entity - the entity to add [POST]
 * @returns the added entity object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/POST
 */
export async function DownloadAsync(
  endpoint: string,
  fileName: string,
  entity: any
) {
  try {
    const response = await fetch(`${ApiBaseUrl}/${endpoint}`, {
      method: 'POST',
      headers: {
        ...jsonHeaders,
        ...GetBearerHeader()
      },
      body: JSON.stringify(entity)
    });
  
    if(!response.ok) {
      throw new Error;
    }

    const fileData = await response.blob();
  
    const url = URL.createObjectURL(fileData);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName || 'downloaded-file';
      a.click();
  } catch (error) {
    throw new Error;
  }

}
