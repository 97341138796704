import { useEffect } from 'react';
import { ctGameDefault, ctGameTitle } from '../../../constants/text';
import { PrivatePage } from '../../../components/private-page';
import FeedbacksManagementGrid from '../../../features/feedbacks-management-grid';

export default function FeedbacksManagement() {
  useEffect(() => {
    document.title = ctGameTitle + ' - Feedback management';

    return () => {
      // return default value when component unmounts
      document.title = ctGameDefault;
    };
  }, []);

  return (
    <PrivatePage
      breadcrumb={[
        { label: 'Dashboard', href: 'dashboard' },
        { label: 'Administration', href: '../administration' },

        {
          label: ' Feedback management',
          href: 'administration/feedbacks/manage'
        }
      ]}
      pageTitle={'Administration'}>
      <FeedbacksManagementGrid />
    </PrivatePage>
  );
}
