import SelectionItem from '../types/selection-item';

export const ConditionalOperations: SelectionItem[] = [
  {
    id: '1',
    name: 'haveItem',
    description: 'have the item',
    list: 'item',
    showQuantity: true
  },
  { id: '2', name: 'haveRole', description: 'have the title', list: 'title' },
  {
    id: '3',
    name: 'haveScore',
    description: 'have the score',
    showQuantity: true
  },
  {
    id: '4',
    name: 'greaterOrEqual',
    description: 'greater or equal to (>=)',
    showQuantity: true,
    availableOnConditions: ['is', 'isNot']
  },
  {
    id: '5',
    name: 'less',
    description: 'less than (<)',
    showQuantity: true,
    availableOnConditions: ['is', 'isNot']
  }
];

export default ConditionalOperations;
