import React from 'react';
import {
  GRID_COL_INDEX_ATTRIBUTE,
  GridCellProps
} from '@progress/kendo-react-grid';
import { useTableKeyboardNavigation } from '@progress/kendo-react-data-tools';
import { ActionCellProps } from './action-cell';

interface ActionsCellProps extends GridCellProps {
  children?: React.ReactElement<ActionCellProps>[];
}

export const ActionsCell = ({ children, ...props }: ActionsCellProps) => {
  const navigationAttributes = useTableKeyboardNavigation(props.id);
  return (
    <td
      colSpan={props.colSpan}
      role={'gridcell'}
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      {...{ [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex }}
      {...navigationAttributes}>
      <div className={'d-flex justify-content-start align-items-center'}>
        {children}
      </div>
    </td>
  );
};

export { ActionCell } from './action-cell';
