import { Button } from 'react-bootstrap';
import UserProfile from './user-profile';

type SaveSectionProps = {
  onClose?: () => void;
  onSave?: () => void;
};

interface UserProfile extends SaveSectionProps {
  createdDate?: Date;
}

const SaveSection = ({ ...props }: UserProfile) => {
  return (
    <div
      className={
        'w-100 py-3 border-top border-2 d-flex position-sticky bottom-0 bg-white'
      }>
      <div
        className={'col d-flex align-items-center justify-content-end gap-3'}>
        <Button
          variant={'success'}
          className={'text-white w-14'}
          onClick={props?.onSave}>
          Update
        </Button>
      </div>
    </div>
  );
};

export default SaveSection;
