import { GameDocumentContext } from '../../../contexts/game-document';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Toolbar from '../toolbar';
import {
  CopyTaskAsync,
  DeleteTaskAsync,
  GetResourceEntity,
  GetResourceKeys,
  SaveEntityEditorAsync,
  UpdateGameDocState
} from '../../../utils/game-document';
import { Button } from '@progress/kendo-react-buttons';
import { process, State } from '@progress/kendo-data-query';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridNoRecords
} from '@progress/kendo-react-grid';
import { LinkCell } from '../../../components/grid';
import { ResourceEntity, TaskEntity } from '../../../types/game-document/';
import { TaskEditorWindow } from '../../../features/game-document/tasks';
import { EntityEditor } from '../../../types/game-document/entity-editor';
import EditDeleteCloneCell from '../../../components/grid/edit-delete-clone-cell';
import {
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../../../components/grid/grid-tool-bar';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { NoRecords } from '../../../components/grid/no-records';
import { DefaultGridSettings } from '../../../constants/grid-settings';
import { uuid } from '../../../types/common-helper';
import { useSessionStorage } from 'usehooks-ts';
import { useGameDocumentResources } from '../../../hooks/use-game-document-resources';

const initialDataState: State = {
  sort: [{ field: 'name', dir: 'asc' }],
  ...DefaultGridSettings.initialDataState
};

const Tasks = () => {
  const [state, setState] = useContext(GameDocumentContext);
  const gameDocumentFiles = useGameDocumentResources();
  const [dataState, setDataState] = useSessionStorage<State>(
    'datastate-tasks',
    initialDataState
  );
  const [entityEditorValue, setEntityEditorValue] =
    useState<EntityEditor<TaskEntity>>();
  const [resourceEditorValue, setResourceEditorValue] =
    useState<EntityEditor<ResourceEntity>[]>();
  const [entityEditorIsVisible, setEntityEditorIsVisible] =
    useState<boolean>(false);

  const toggleEntityEditor = () => {
    setEntityEditorIsVisible(!entityEditorIsVisible);
  };

  const titleGuid = uuid();
  const imageGuid = uuid();
  const completeImageGuid = uuid();
  const entity: TaskEntity = {
    id: '',
    name: '',
    description: '',
    titleResId: titleGuid,
    imageResId: imageGuid,
    completeImageResId: completeImageGuid,
    hideWhenComplete:
      state.gameDocument && !state.gameDocument.settings.designer
        ? false
        : !Boolean(
            state.gameDocument?.settings.designer?.taskCompletionVisibility
          )
  };

  const titleResource: ResourceEntity = {
    id: titleGuid,
    name: 'Title',
    description: '',
    type: 'text',
    value: ''
  };

  const { imageRes: imageResource, completeImageRes: completeImageResource } =
    gameDocumentFiles.createNewTaskResources(imageGuid, completeImageGuid);

  const LinkedNameCell = (props: GridCellProps) => (
    <LinkCell to={props.dataItem['id'].toString()} {...props} />
  );

  const DeleteCell = (props: GridCellProps) => (
    <EditDeleteCloneCell
      onEditClick={() => onEditEntity(props.dataItem['id'])}
      onCloneClick={() => onCloneEntity(props.dataItem['id'])}
      onDeleteClick={() => onDeleteEntity(props.dataItem['id'])}
      {...props}
    />
  );

  const handleEntityEditorSubmit = useCallback(
    (
      editorEntity: EntityEditor<TaskEntity>,
      resourceEntity: EntityEditor<ResourceEntity>[]
    ) => {
      SaveEntityEditorAsync(
        state?.gameDocument!,
        editorEntity,
        resourceEntity
      ).then((response) => {
        if (response) {
          setState((prev) => UpdateGameDocState(prev, response));
        }

        toggleEntityEditor();
      });
    },
    [imageGuid, setState, state.gameDocument, titleGuid]
  );

  const onAddEntity = () => {
    setEntityEditorValue({
      isNew: true,
      entity: entity
    });
    // Create 3 resources for task (titleResId, summaryResId, imageResId)
    setResourceEditorValue([
      {
        isNew: true,
        entity: titleResource
      },
      {
        isNew: true,
        entity: imageResource
      },
      {
        isNew: true,
        entity: completeImageResource
      }
    ]);
    toggleEntityEditor();
  };

  const onEditEntity = (entityId: string) => {
    const editedEntity = state.gameDocument?.assets.tasks?.find(
      (i) => i.id === entityId
    )!;

    setEntityEditorValue({
      isNew: false,
      entity: editedEntity
    });

    let resources: EntityEditor<ResourceEntity>[] = [];

    for (const res of GetResourceKeys(editedEntity)) {
      resources.push({
        isNew: false,
        entity: GetResourceEntity(state.gameDocument!, editedEntity[res])
      });
    }

    setResourceEditorValue(resources);

    toggleEntityEditor();
  };

  const onCloneEntity = (entityId: string) => {
    CopyTaskAsync(state.gameDocument!, entityId).then((updatedGameDocument) => {
      setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
    });
  };

  const onDeleteEntity = async (entityId: string) => {
    DeleteTaskAsync(state.gameDocument!, entityId).then(
      (updatedGameDocument) => {
        setState((state) => UpdateGameDocState(state, updatedGameDocument));
      }
    );
  };

  const gridExportRef = React.useRef<ExcelExport | null>(null);

  useEffect(() => {
    // Set the page task.
    document.title = `Tasks - ${state.gameDocument?.name}`;
  }, [state]);

  return (
    <>
      <Toolbar title={'Tasks'}>
        <Button onClick={onAddEntity} themeColor={'primary'}>
          Add task
        </Button>
        {entityEditorIsVisible && (
          <TaskEditorWindow
            toggleDialog={toggleEntityEditor}
            onSubmit={handleEntityEditorSubmit}
            onClose={toggleEntityEditor}
            editorEntity={entityEditorValue!}
            editorResource={resourceEditorValue!}
            editorMode={'basic'}
          />
        )}
      </Toolbar>
      <div className={'pt-2'}>
        <GridToolBar
          searchPlaceholder={'Search tasks'}
          columnsToSearch={['name', 'description']}
          showCardMode={false}
          exportRef={gridExportRef}
          {...dataState}
          onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
            setDataState(e.dataState);
          }}
        />
        <ExcelExport
          data={state.gameDocument?.assets.tasks ?? []}
          ref={gridExportRef}
          fileName={`${state.gameDocument?.name} inventory.xlsx`}>
          <Grid
            pageable={DefaultGridSettings.pagerSettings}
            sortable={true}
            className={'cg-grid3'}
            data={process(state.gameDocument?.assets.tasks ?? [], dataState)}
            {...dataState}
            onDataStateChange={(e: GridDataStateChangeEvent) => {
              setDataState(e.dataState);
            }}>
            <Column field={'name'} title={'Name'} cell={LinkedNameCell} />
            <Column field={'description'} title={'Description'} />
            <Column cell={DeleteCell} />
            <GridNoRecords>
              <NoRecords />
            </GridNoRecords>
          </Grid>
        </ExcelExport>
      </div>
    </>
  );
};

export default Tasks;
