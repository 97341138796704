const resetDatastateSession = () => {
  sessionStorage.removeItem('datastate-inventory');
  sessionStorage.removeItem('datastate-tasks');
  sessionStorage.removeItem('datastate-task-contents');
  sessionStorage.removeItem('datastate-timers');
  sessionStorage.removeItem('datastate-titles');
  sessionStorage.removeItem('datastate-zones');
  sessionStorage.removeItem('datastate-maps');
  sessionStorage.removeItem('datastate-areas');
  sessionStorage.removeItem('datastate-game-assessments');
  sessionStorage.removeItem('datastate-game-resources');
  sessionStorage.removeItem('datastate-game-languages');
};

export { resetDatastateSession };
