import { AssessmentSettings } from '../types/assessment';

export const DefaultAssessmentSettings: AssessmentSettings = {
  isRequiredJoinTeam: false,
  showPurposeStatement: true,
  showActionsToAllParticipants: false,
  showDueDate: false,
  showAssignee: false,
  showMeasurableStep: false,
  showResultsStep: false,
  showDownloadReport: false,
  showParticipantInitials: false,
  competenciesStepSettings: {
    showCompetencyDescription: true
  },
  actionsStepSettings: {
    showCompetencyDescription: true,
    showCompetencyScore: true
  },
  summaryStepSettings: {
    showCompetencyDescription: true,
    showCompetencyScore: true,
    showCompetenciesWithoutActions: false
  },
  voteStepSettings: {
    showCompetencyDescription: true,
    showCompetencyScore: true,
    showCompetenciesWithoutActions: false
  },
  actionPlanStepSettings: {
    showCompetencyDescription: true,
    showCompetencyScore: true,
    showCompetenciesWithoutActions: false
  },
  measurableStepSettings: {
    showCompetencyDescription: true,
    showCompetencyScore: true,
    showCompetenciesWithoutActions: false
  }
};
