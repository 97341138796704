import {
  ExternalDropZone,
  Upload,
  UploadOnAddEvent
} from '@progress/kendo-react-upload';
import React from 'react';
import { ComponentPropsWithRef } from 'react';
import { UploadedImage } from '../features/game-document/image-resource/resource-window';
import { isFullWidth } from 'validator';

interface CoverImageProps extends ComponentPropsWithRef<'div'> {
  imageUrl?: string | undefined;
  containerStyle?: 'rounded' | 'square' | undefined;
  onDropFiles?: ((uploadedImage: UploadedImage) => void) | undefined;
  opacity?: number;
  isfullWidth?: boolean;
}

export const CoverImage = ({
  imageUrl,
  containerStyle = undefined,
  opacity = 1,
  isfullWidth,
  ...props
}: CoverImageProps) => {
  const uploadRef = React.createRef<Upload>();
  const onDropHandler = (event: UploadOnAddEvent) => {
    if (props.onDropFiles && event.newState.length > 0) {
      const file = event.newState[event.newState.length - 1].getRawFile!();
      const blobUrl = URL.createObjectURL(file);
      const mimeType = file.type;
      const fileName = file.name;
      const size = file.size;
      props.onDropFiles({ blobUrl, mimeType, fileName, size });
    }
  };
  return (
    <div
      {...props}
      className={`cover-image ${props.className ?? ''} ${
        containerStyle && `cover-image--${containerStyle}`
      }`.trim()}>
      <div
        className={`cover-image__image ${!isFullWidth ? '' : isfullWidth !== undefined && isfullWidth === false ? '' : 'w-100'}`}>
        {imageUrl ? (
          <img
            src={imageUrl}
            alt={props.title ?? imageUrl}
            style={{
              opacity: opacity
            }}
          />
        ) : (
          <span
            className={'cover-image__placeholder material-symbols-outlined'}>
            add_photo_alternate
          </span>
        )}
        {props.onDropFiles && (
          <>
            <ExternalDropZone
              uploadRef={uploadRef}
              className={'cover-image__dropzone'}
            />
            <Upload
              ref={uploadRef}
              className={'d-none'}
              batch={false}
              multiple={true}
              defaultFiles={[]}
              withCredentials={false}
              showFileList={false}
              onAdd={onDropHandler}
              saveUrl={(files) => {
                return new Promise((resolve) => {
                  resolve({ uid: files[0].uid });
                });
              }}
            />
          </>
        )}
      </div>
      {props.children && (
        <div className={'cover-image__container'}>{props.children}</div>
      )}
    </div>
  );
};

export default CoverImage;
