import { StandardInput } from '../../../components/forms';
import { ComponentType, ReactNode } from 'react';
import { MapEntity, ResourceEntity } from '../../../types/game-document/';
import {
  CheckboxChangeEvent,
  InputChangeEvent
} from '@progress/kendo-react-inputs';
import { EditorMode } from '../../../types/editor-mode';
import { RequiredInput } from './map-editor-window';

interface MapEditorProps {
  editorMode: EditorMode;
  entity: MapEntity;
  resources: ResourceEntity[];
  handleInputChange: (event: InputChangeEvent | CheckboxChangeEvent) => void;
  children?: ReactNode | undefined;
  requiredInputs?: RequiredInput[];
}

export const MapEditor: ComponentType<MapEditorProps> = ({
  editorMode,
  entity,
  resources,
  handleInputChange,
  requiredInputs,
  ...props
}: MapEditorProps) => {
  return (
    <>
      <StandardInput
        name={'name'}
        label={'Name'}
        validationMessage={
          requiredInputs?.find((item) => item.name === 'name')?.errorMessage
        }
        value={entity?.name}
        onChange={handleInputChange}
      />
      <StandardInput
        name={'description'}
        label={'Description'}
        value={entity?.description}
        onChange={handleInputChange}
      />
      {editorMode === 'full' && (
        <>
          <StandardInput
            name={'titleResId'}
            label={'Title'}
            value={entity?.titleResId}
            onChange={handleInputChange}
            readOnly
          />
          <StandardInput
            name={'type'}
            label={'Type'}
            value={entity?.type}
            onChange={handleInputChange}
            readOnly
          />
          {entity.type !== 'illustration' && (
            <>
              <StandardInput
                name={'latitude'}
                label={'Latitude'}
                value={entity?.latitude}
                onChange={handleInputChange}
                readOnly
              />
              <StandardInput
                name={'longitude'}
                label={'Longitude'}
                value={entity?.longitude}
                onChange={handleInputChange}
                readOnly
              />
            </>
          )}

          <StandardInput
            name={'zoomLevel'}
            label={'Zoom Level'}
            value={entity?.zoomLevel}
            onChange={handleInputChange}
            readOnly
          />
          <StandardInput
            name={'imageResId'}
            label={'imageResId'}
            value={entity?.imageResId}
            onChange={handleInputChange}
            readOnly
          />
          <StandardInput
            name={'url'}
            label={'URL'}
            value={entity?.url}
            onChange={handleInputChange}
            readOnly
          />
        </>
      )}
      {props.children}
    </>
  );
};
