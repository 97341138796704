import { ComponentType } from 'react';

/**
 * The progress layout is designed for the OidcProvider states.
 * @returns
 */
const Progress: ComponentType<any> = ({ children }) => {
  return (
    <div className={'layout layout--progress'}>
      <div className={'layout__container'}>{children}</div>
    </div>
  );
};

export default Progress;
