import CustomColumnContainer, {
  CustomColumnProps
} from './custom-column-container';

interface CustomColumnVideoProps extends CustomColumnProps {
  id?: string;
  url?: string;
  maxHeight?: number;
  onEdit?: (id?: string) => void;
}

const CustomColumnVideo = ({
  onEdit = () => {},
  ...props
}: CustomColumnVideoProps) => {
  const onEditHandler = (id: string) => {
    onEdit(id);
  };
  return (
    <CustomColumnContainer
      columnClass={props?.columnClass}
      containerClass={props?.containerClass}>
      <div className={'w-50'}>
        <video height={props?.maxHeight} src={props?.url}></video>
      </div>
      <span
        onClick={() => onEditHandler(props.id!)}
        className={'material-symbols-outlined'}
        style={{ cursor: 'pointer' }}>
        edit
      </span>
    </CustomColumnContainer>
  );
};

export default CustomColumnVideo;
