import React from 'react';
import { Point, Polygon } from 'ol/geom';
import { toLonLat } from 'ol/proj';
import { Feature, Overlay } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

import { MapImage } from '../types/game-document';
import { MapContext } from '../components/map/map-context';
import { useGameTaskPosition } from './use-game-task-position';
import { GameDocumentContext } from '../contexts/game-document';
import { GetResourceValue } from '../utils/game-document/resources';
import { TaskEntity, ZoneEntity } from '../types/game-document/entities';
import { UpdateGameDocState, UpdateTaskAsync } from '../utils/game-document';
import { MapIllustrationContext } from '../components/map/map-illustration-context';

export const useGameTask = ({
  tasks,
  zones,
  mapType,
  mapImages,
  selectAllAssets
}: {
  mapType: string;
  tasks: TaskEntity[];
  zones: ZoneEntity[];
  mapImages: MapImage[];
  selectAllAssets: string;
}) => {
  const map = React.useContext(MapContext);
  const mapIllustration = React.useContext(MapIllustrationContext);
  const [gameDocument, setGameDocument] = React.useContext(GameDocumentContext);
  const { addTaskOverlay, removeTaskOverlay, updateTaskOverlayPosition } =
    useGameTaskPosition({ mapType });

  const populateTasks = () => {
    if (tasks && Array.isArray(tasks) && tasks.length > 0) {
      tasks.forEach((task) => {
        if (task.boundary && task.boundary.geometry.coordinates) {
          const imgUrl = GetResourceValue(
            gameDocument.gameDocument!,
            task.imageResId || ''
          );
          const title = GetResourceValue(
            gameDocument.gameDocument!,
            task.titleResId || ''
          );

          task.isVisible &&
            addTaskOverlay(
              task.id,
              title,
              imgUrl,
              toLonLat(task.boundary.geometry.coordinates)
            );
        }
      });
    }
  };

  // remove task if its not in the location
  React.useEffect(() => {
    const taskContainer = document.querySelectorAll('[id^="taskContainer-"]');
    taskContainer.forEach((task) => {
      const taskId = task.id.replace('taskContainer-', '');
      const taskFound = tasks.find((list) => list.id === taskId);
      if (!taskFound) {
        removeTaskOverlay(taskId);
      }
    });
  }, [tasks, removeTaskOverlay]);

  React.useEffect(() => {
    const taskContainer = document.querySelectorAll('[id^="taskContainer-"]');
    taskContainer.forEach((task) => {
      task.classList[
        ['ALL', 'TASK'].includes(selectAllAssets) ? 'add' : 'remove'
      ]('selected');
    });
  }, [mapType, map, mapIllustration, selectAllAssets]);

  // drag and drop task location
  React.useEffect(() => {
    if (map || mapIllustration) {
      const mapContext = mapType === 'openStreetMap' ? map : mapIllustration;

      const startPixels = { x: 0, y: 0 };
      const startCoords = { x: 0, y: 0 };

      const overlays: Overlay[] = [];
      const taskIds: string[] = [];
      const updatedPositions: number[][] = [];
      const updatedZoneFeature: number[][][][] = [];
      const updatedImageFeature: number[][] = [];

      const handleMouseDown = (event: MouseEvent) => {
        const overlayElement = event.target as HTMLElement;
        let taskContainer = overlayElement;
        while (
          taskContainer.parentElement &&
          !taskContainer.id.startsWith('taskContainer-')
        ) {
          taskContainer = taskContainer.parentElement;
        }

        const id = taskContainer.id.replace('taskContainer-', '');
        if (!id) return;

        if (['ALL', 'TASK'].includes(selectAllAssets)) {
          tasks.forEach((task) => {
            taskIds.push(task.id);
          });
        } else {
          taskIds.push(id);
        }

        taskIds.forEach((id) => {
          const ov = mapContext.getOverlayById(id);
          ov && overlays.push(ov);
        });

        if (
          overlays.length > 0 &&
          overlays.some((ov) => ov.getElement()?.contains(event.target as Node))
        ) {
          startPixels.x = event.clientX;
          startPixels.y = event.clientY;

          overlays.forEach((ov) => {
            ov.getElement()
              ?.querySelector('[class^="task-center-dot"]')
              ?.classList.remove('none');
          });

          const coord = mapContext.getCoordinateFromPixel([
            startPixels.x,
            startPixels.y
          ]);
          startCoords.x = coord[0];
          startCoords.y = coord[1];

          taskIds.forEach((id, index) => {
            const ov = overlays[index];
            const currentCoordinate = ov.getPosition();

            if (currentCoordinate) {
              updatedPositions[index] = currentCoordinate;
            }
          });

          document.addEventListener('mousemove', handleMouseMove);
          document.addEventListener('mouseup', handleMouseUp);
        }
      };

      const handleMouseMove = (event: MouseEvent) => {
        if (overlays.length > 0 && taskIds.length > 0) {
          const currentPixel = [event.clientX, event.clientY];
          const currentCoord = mapContext.getCoordinateFromPixel(currentPixel);

          const deltaX = currentCoord[0] - startCoords.x;
          const deltaY = currentCoord[1] - startCoords.y;

          taskIds.forEach((id, index) => {
            const ov = overlays[index];
            const currentCoordinate = ov.getPosition();

            if (currentCoordinate) {
              const newPosition = [
                currentCoordinate[0] + deltaX,
                currentCoordinate[1] + deltaY
              ];
              ov.setPosition(newPosition);
              updateTaskOverlayPosition(id, toLonLat(newPosition));
              updatedPositions[index] = newPosition;
            }
          });
          startCoords.x = currentCoord[0];
          startCoords.y = currentCoord[1];

          if (selectAllAssets === 'ALL') {
            mapContext.getLayers().forEach((layer) => {
              if (layer instanceof VectorLayer) {
                const source = layer.getSource();
                if (source instanceof VectorSource) {
                  const features = source.getFeatures();

                  if (Array.isArray(features)) {
                    features.forEach((f, idx) => {
                      const geometry = f.getGeometry();
                      geometry.translate(deltaX, deltaY);
                      if (geometry instanceof Point) {
                        updatedImageFeature[idx] = geometry.getCoordinates();
                      } else if (geometry instanceof Polygon) {
                        updatedZoneFeature[idx] = geometry.getCoordinates();
                      }
                    });
                  }
                }
              }
            });
          }
        }
      };

      const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);

        overlays.forEach((ov) => {
          ov.getElement()
            ?.querySelector('[class^="task-center-dot"]')
            ?.classList.add('none');
        });

        if (taskIds.length > 0 && updatedPositions.length > 0) {
          taskIds.forEach(async (id, index) => {
            const selectedTask = tasks.find((task) => task.id === id);
            if (
              gameDocument.gameDocument &&
              selectedTask &&
              selectedTask.boundary
            ) {
              selectedTask.boundary.geometry.coordinates =
                updatedPositions[index];

              UpdateTaskAsync(gameDocument.gameDocument, id, selectedTask).then(
                (response) => {
                  setGameDocument((prev) => UpdateGameDocState(prev, response));
                }
              );
            }
          });
        }

        updatedZoneFeature.length > 0 &&
          zones.forEach((zone, idx) => {
            if (zone.boundary) {
              zone.boundary.geometry.coordinates = updatedZoneFeature[idx];
            }
          });

        updatedImageFeature.length > 0 &&
          mapImages.forEach((image, idx) => {
            if (image.boundary) {
              image.boundary.geometry.coordinates = updatedImageFeature[
                idx
              ] as any;
            }
          });
      };

      const overlayStop = document.getElementsByClassName(
        'ol-overlaycontainer-stopevent'
      );
      overlayStop.length > 0 &&
        document.addEventListener('mousedown', handleMouseDown);

      return () => {
        document.removeEventListener('mousedown', handleMouseDown);
      };
    }
  }, [
    map,
    tasks,
    zones,
    mapType,
    mapImages,
    selectAllAssets,
    mapIllustration,
    gameDocument.gameDocument,
    updateTaskOverlayPosition
  ]);

  return {
    addTaskOverlay,
    updateTaskOverlayPosition,
    populateTasks,
    removeTaskOverlay
  };
};
