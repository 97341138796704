import { formatDate } from '@progress/kendo-intl';
import { EditorUtils, ProseMirror } from '@progress/kendo-react-editor';

import { uuid12 } from '../../types/common-helper';
import {
  getBase64AudioExtension,
  getBase64VideoExtension
} from '../../utils/files';
import { PostResourceAsync } from '../../services/files';

export const onImageInsert = (args: any) => {
  const { files, view, event } = args;
  const nodeType = view.state.schema.nodes.image;
  const position =
    event.type === 'drop'
      ? view.posAtCoords({
          left: event.clientX,
          top: event.clientY
        })
      : null;
  insertMediaFiles({
    view,
    files,
    nodeType,
    position
  });
  return files.length > 0;
};

export const insertMediaFiles = ({
  view,
  files,
  nodeType,
  position,
  gameId,
  version,
  toggleSaving,
  onClose,
  attrs = {}
}: any) => {
  if (EditorUtils.canInsert(view.state, nodeType)) {
    const file = files[0];
    if (file) {
      let reader = new FileReader();
      reader.onload = async (e: ProgressEvent<FileReader>) => {
        if (e.target) {
          try {
            let src: string = e.target.result as string;
            if (['audio', 'video'].includes(nodeType.name)) {
              const dateString = formatDate(new Date(), 'yyMMdd');
              const fileNamePrefix = `${gameId}-${dateString}-${version}`;
              const extension =
                nodeType.name === 'audio'
                  ? getBase64AudioExtension(src as string)
                  : getBase64VideoExtension(src as string);
              const fileName = `${fileNamePrefix}-${uuid12()}.${extension}`;
              const blob = await fetch(src as string).then((r) => r.blob());
              const extractedBlob: File = new File([blob], fileName, {
                type: blob.type
              });
              const resourceResponse = await PostResourceAsync(extractedBlob);
              src = resourceResponse.value;
            }
            const media = nodeType.createAndFill({
              ...attrs,
              src
            });
            if (position) {
              view.dispatch(view.state.tr.insert(position.pos, media));
            } else {
              EditorUtils.insertNode(view, media, true);
            }
          } catch (error) {
            console.log('Something error when saving media: ', error);
          } finally {
            toggleSaving();
            view.focus();
            onClose();
          }
        }
      };
      const data = 'file' in file ? file.file : file;
      reader.readAsDataURL(data);
    }
  }
};

export const insertImagePlugin = (onInsert: any) =>
  new ProseMirror.Plugin({
    props: {
      handleDOMEvents: {
        paste: (view: typeof ProseMirror.EditorView, event: ClipboardEvent) =>
          insertImages(view, event, onInsert),
        drop: (view: typeof ProseMirror.EditorView, event: DragEvent) =>
          insertImages(view, event, onInsert)
      }
    },
    key: new ProseMirror.PluginKey('insert-image-plugin')
  });

const insertImages = (view: any, event: any, onInsert: any) => {
  const items =
    (event.clipboardData && event.clipboardData.items) ||
    (event.dataTransfer && event.dataTransfer.files);
  if (items) {
    const files: Array<any> = [];
    let file: any;
    for (let i = 0; i < items.length; i++) {
      file =
        items[i].type.indexOf('image') !== -1
          ? 'getAsFile' in items[i]
            ? items[i].getAsFile()
            : items[i]
          : null;
      if (file) {
        files.push(file);
      }
    }

    if (files.length) {
      event.preventDefault();
    }

    return onInsert({ view, files, event });
  }
};
