import {
  Checkbox,
  CheckboxChangeEvent,
  InputChangeEvent
} from '@progress/kendo-react-inputs';
import { ComponentType, ReactNode } from 'react';
import { Col, Row } from 'react-bootstrap';
import LanguageComboBox from '../../../components/combobox/language-combobox';
import { StandardInput } from '../../../components/forms';
import { EditorMode } from '../../../types/editor-mode';
import {
  EntityEditor,
  ResourceEntity,
  ResourcePackEntity
} from '../../../types/game-document/';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { Error, Label } from '@progress/kendo-react-labels';

interface ResourcePackEditorProps {
  editorMode: EditorMode;
  entity?: ResourcePackEntity;
  isNew?: boolean;
  handleInputChange: (event: InputChangeEvent) => void;
  handleCheckedChange: (event: CheckboxChangeEvent) => void;
  handleComboChange: (event: ComboBoxChangeEvent) => void;
  handleDisplayLanguageChange: (event: ComboBoxChangeEvent) => void;
  isLanguageCombobox?: boolean;
  isLanguageDuplicate?: boolean;
  children?: ReactNode | undefined;
}

export const ResourcePackEditor: ComponentType<ResourcePackEditorProps> = ({
  editorMode,
  entity,
  isNew = true,
  handleInputChange,
  handleCheckedChange,
  handleComboChange,
  handleDisplayLanguageChange,
  isLanguageCombobox = false,
  isLanguageDuplicate = false,
  ...props
}: ResourcePackEditorProps) => {
  return (
    <>
      <Row>
        <Col md={'12'}>
          {isLanguageCombobox ? (
            <div>
              <Label>Language title</Label>
              <LanguageComboBox
                name={'language'}
                value={entity?.name}
                onChange={handleComboChange}
              />
            </div>
          ) : (
            <StandardInput
              name={'language'}
              label={'Language title'}
              value={entity?.name}
              onChange={handleInputChange}
              autoFocus={true}
            />
          )}
        </Col>
      </Row>

      {isLanguageDuplicate && (
        <Row className="mt-1">
          <Error>
            The selected language has already been added, please choose another.
          </Error>
        </Row>
      )}
      <Row>
        <Col md={'12'}>
          <div>
            <Label>Display Language</Label>
            <LanguageComboBox
              isDisplayLanguages={true}
              name={'displayLanguages'}
              value={entity?.displayLanguage}
              onChange={handleDisplayLanguageChange}
            />
          </div>
        </Col>
      </Row>

      {isNew && (
        <Row className={'mt-3'}>
          <Col md={'12'}>
            <Checkbox
              label={'Copy original text to translation'}
              className={'mt-3'}
              onChange={handleCheckedChange}></Checkbox>
          </Col>
        </Row>
      )}

      {props.children}
    </>
  );
};
