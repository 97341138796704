import { useEffect } from 'react';
import { PrivatePage } from '../../../components/private-page';
import AssessmentGrid from '../../../features/assessment-grid';
import { ctGameDefault, ctGameTitle } from '../../../constants/text';

export default function AssessmentApproval() {
  useEffect(() => {
    document.title = ctGameTitle + ' - Assessments Pending Approval';

    return () => {
      // return default value when component unmounts
      document.title = ctGameDefault;
    };
  }, []);

  return (
    <PrivatePage
      breadcrumb={[
        { label: 'Dashboard', href: 'dashboard' },
        { label: 'Administration', href: '../administration' },

        {
          label: ' Pending approval',
          href: '../administration'
        },
        {
          label: ' Assessment approval',
          href: 'administration/assessments/pending'
        }
      ]}
      pageTitle={'Administration'}>
      <AssessmentGrid isApproval={true} />
    </PrivatePage>
  );
}
