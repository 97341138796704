/**
 * generate selected id grid
 * @param selectedState
 * @returns
 */

export const getSelectedIds = (selectedState: {
  [id: string]: boolean | number[];
}): any[] => {
  const ids = selectedState;
  let newIds = [];
  for (const key in ids) {
    if (ids[key]) {
      newIds.push(key as any);
    }
  }
  return newIds;
};
