import { Outlet } from 'react-router-dom';
import { AuthRequired } from '../components/authentication';
import DiagnosticsMenu from './diagnostics-menu';

/**
 * The layout for diagnostics pages.
 * @returns
 */
const Diagnostics = () => {
  return (
    <AuthRequired>
      <div className={'layout layout--admin'}>
        <DiagnosticsMenu />
        <div className={'app-main'}>
          <Outlet />
        </div>
      </div>
    </AuthRequired>
  );
};

export default Diagnostics;
