import { useContext } from 'react';
import { InstanceContext } from '../../contexts/';
import { User } from 'oidc-client-ts';
import { parseDate } from '@progress/kendo-intl';
import { Api } from './api';

export const useApi = (): Api => {
  const instance = useContext(InstanceContext);

  // the base url for the environment api [v2].
  const apiBaseUrl = `${instance.apiBaseUrl}/api/v2`;

  // gets the currently logged-in user token from session storage.
  const getUser = () => {
    const storageKey = `oidc.user:${instance.authority}:${instance.clientId}`;
    const oidcStorage = sessionStorage.getItem(storageKey);
    if (!oidcStorage) return null;
    return User.fromStorageString(oidcStorage);
  };

  // fetch headers for json request/response.
  const jsonHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };

  // creates a bearer authorisation header token based on the logged in user.
  const getBearerHeader = () => ({
    authorization: `Bearer ${getUser()?.access_token ?? ''}`
  });

  // Preloaded Fetch init for json post fetches.
  const initJsonPost: RequestInit = {
    method: 'POST',
    headers: { ...jsonHeaders, ...getBearerHeader() }
  };

  // recursive function for converting api response UTC date strings into Date objects.
  const convertObjectDates = (o: any) => {
    if (o && typeof o === 'object') {
      Object.keys(o).forEach((k: string) => {
        // if the current value is an object or array, recursively process it
        if (o[k] && typeof o[k] === 'object') {
          convertObjectDates(o[k]);
        }

        // if the property ends with 'DateUtc'
        else if (k.endsWith('DateUtc') && o[k]) {
          o[k] = parseDate(o[k]);
        }
      });
    }
    return o;
  };

  // swr fetcher for get requests against the environment api.
  // automatically converts object dates.
  const apiFetcher = (url: string) =>
    fetch(`${apiBaseUrl}/${url}`).then((res) =>
      res.json().then((json) => {
        return new Promise<never>((resolve) => {
          resolve(convertObjectDates(json));
        });
      })
    );

  // swr fetcher with authorisation token for get requests against the environment api.
  // automatically converts object dates.
  const apiFetcherWithAuth = (url: string) =>
    fetch(`${apiBaseUrl}/${url}`, { headers: { ...getBearerHeader() } }).then(
      (res) =>
        res.json().then((json) => {
          return new Promise<never>((resolve) => {
            resolve(convertObjectDates(json));
          });
        })
    );

  const patchAssessmentDocument = (
    assessmentId: number | string,
    documentId: number | string,
    document: any
  ) =>
    fetch(
      `${apiBaseUrl}/assessments/${assessmentId}/document/${documentId}/contents`,
      {
        ...initJsonPost,
        body: JSON.stringify(document)
      }
    ).then((res) => res.json());

  return {
    apiBaseUrl,
    headers: {
      json: jsonHeaders,
      postJson: initJsonPost,
      bearer: getBearerHeader()
    },
    getUser,
    fetchers: {
      api: apiFetcher,
      apiWithAuth: apiFetcherWithAuth
    },
    convertObjectDates,
    assessments: {
      patchAssessmentDocument
    }
  };
};
