import React from 'react';
import { useAuth } from 'react-oidc-context';
import { BreadcrumbLocation, PrivatePage } from '../../components/private-page';
import PageToolbar from '../../components/page-toolbar';
import { Button } from '@progress/kendo-react-buttons';

interface UserDiagnosticsProps {}

const UserDiagnostics = (props: UserDiagnosticsProps) => {
  const breadcrumbs: BreadcrumbLocation[] = [
    { label: 'Dashboard', href: '/' },
    { label: 'Diagnostics', href: '/diagnostics' },
    { label: 'Users', href: '/diagnostics/users' }
  ];

  const auth = useAuth();

  const onReload = async () => {};

  return (
    <PrivatePage pageTitle={'Diagnostics'} breadcrumb={breadcrumbs}>
      <PageToolbar title={'User'}>
        <Button
          onClick={() => onReload()}
          themeColor={'info'}
          className={'me-1'}
          fillMode={'flat'}>
          <span className="material-symbols-outlined">sync</span>
        </Button>
      </PageToolbar>
      <pre style={{ fontSize: '0.7rem' }} className={'text-break mt-4'}>
        {JSON.stringify(auth.user, null, 2)}
      </pre>
    </PrivatePage>
  );
};

export default UserDiagnostics;
