import { AssessmentSettings } from '../../types/assessment';

export const validateSettings = (
  defaultSettings: AssessmentSettings,
  currentSettings: AssessmentSettings
) => {
  const checkRecursively = (defaultObj: any, currentObj: any): boolean => {
    for (const key in defaultObj) {
      if (currentObj[key] === null) {
        return false;
      } else if (
        typeof defaultObj[key] === 'object' &&
        !Array.isArray(defaultObj[key])
      ) {
        if (!checkRecursively(defaultObj[key], currentObj[key])) {
          return false;
        }
      }
    }
    return true;
  };

  const isValid = checkRecursively(defaultSettings, currentSettings);
  return isValid;
};
