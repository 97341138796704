import { useContext } from 'react';
import { GameDocumentContext } from '../contexts/game-document';
import { UploadedImage } from '../features/game-document/image-resource/resource-window';
import {
  AddResourceAsync,
  UpdateGameDocState,
  UpdateResourceAsync
} from '../utils/game-document';
import { ResourceEntity } from '../types/game-document/';
import { UpdateResourcePackResourceAsync } from '../utils/game-document/resource-packs';

/**
 * Retrieves resources from the game document.
 * If the game document and resources exist, returns an array of ResourceEntities.
 * If not, returns an empty array.
 *
 * @returns {ResourceEntity[]} Array of ResourceEntities.
 */
export const useGameDocumentResources = () => {
  const [state, setState] = useContext(GameDocumentContext);

  /**
   * Creates new task resources.
   *
   * @param {string} imageResId - The ID of the image resource.
   * @param {string} completeImageResId - The ID of the complete image resource.
   * @returns {Object} - An object containing the image resource and the complete image resource.
   */
  const createNewTaskResources = (
    imageResId: string,
    completeImageResId: string
  ): { imageRes: ResourceEntity; completeImageRes: ResourceEntity } => {
    let availableIcon = '';
    if (state.gameDocument && state.gameDocument.settings.designer) {
      availableIcon =
        getResourceEntity(
          state.gameDocument?.settings.designer.defaultTaskAvailableIconResId
        ).value || '';
    }

    let completeIcon = '';
    if (state.gameDocument && state.gameDocument.settings.designer) {
      completeIcon =
        getResourceEntity(
          state.gameDocument?.settings.designer.defaultTaskCompletedIconResId ||
            ''
        ).value || '';
    }

    return {
      imageRes: {
        id: imageResId,
        name: imageResId,
        description: 'map-task.png',
        type: 'image',
        value:
          availableIcon ||
          'https://cdn.catalystglobal.games/resources/map-task.png',
        size: 15150
      },
      completeImageRes: {
        id: completeImageResId,
        name: completeImageResId,
        description: 'map-task--complete.png',
        type: 'image',
        value:
          completeIcon ||
          'https://cdn.catalystglobal.games/resources/map-task--complete.png',
        size: 10909
      }
    };
  };

  /**
   * Retrieves resources from the game document.
   * If the game document and resources exist, returns an array of ResourceEntities.
   * If not, returns an empty array.
   *
   * @returns {ResourceEntity[]} Array of ResourceEntities.
   */
  const getResources = (): ResourceEntity[] =>
    (state.gameDocument?.resources as ResourceEntity[]) ?? [];

  /**
   * Retrieves the resource entity based on the provided resource ID.
   *
   * @param {string} resourceId - The unique identifier of the resource.
   * @returns {ResourceEntity} - The resource entity matching the provided resource ID.
   */
  const getResourceEntity = (resourceId: string): ResourceEntity => {
    const resources = getResources();
    const resourceIndex = resources.findIndex((i) => i.id === resourceId)!;
    return resources[resourceIndex];
  };

  /**
   * Returns the resources for the resource pack with the given packName, if found.
   *
   * @param {string} packName - The name of the resource pack to find.
   * @return {Array} - Returns an array of resources if found, otherwise an empty array.
   */
  const getResourcePack = (packName: string) =>
    state.gameDocument?.resourcePacks?.find((x) => x.name === packName)
      ?.resources ?? [];

  /**
   * Retrieves a specific resource entity from a resource pack.
   *
   * @param {string} packName - The name of the resource pack.
   * @param {string} resourceId - The id of the resource entity to retrieve.
   * @returns {ResourceEntity} - The resource entity matching the given resourceId from the resource pack.
   */
  const getResourcePackEntity = (
    packName: string,
    resourceId: string
  ): ResourceEntity => {
    const resources = getResourcePack(packName);
    const resourceIndex = resources.findIndex((i) => i.id === resourceId)!;
    return resources[resourceIndex];
  };

  /**
   * Updates a resource with the given information.
   *
   * @param {string} resourceId - The ID of the resource to update.
   * @param {string} blobUrl - The URL of the new resource's blob.
   * @param {number} size - The size of the new resource in bytes.
   * @param {string} fileName - The name of the new resource.
   * @param {Function} [callback] - An optional callback function to be called after the resource is updated.
   *                               It takes a single parameter, the updated resource entity.
   * @returns {void}
   */
  const updateResource = (
    resourceId: string,
    blobUrl: string,
    size: number,
    fileName: string,
    callback?: (resource: ResourceEntity) => void
  ) =>
    updateResourceFile(
      resourceId,
      { blobUrl, size, fileName, mimeType: '' },
      callback
    );

  /**
   * Updates a resource file in the game document.
   *
   * @param {string} resourceId - The ID of the resource to be updated.
   * @param {UploadedImage} uploadedMedia - The uploaded image data.
   * @param {Function} [callback] - An optional callback function to be called after the resource is updated.
   *                               It takes a single parameter, the updated resource entity.
   * @returns {void}
   */
  const updateResourceFile = (
    resourceId: string,
    uploadedMedia: UploadedImage,
    callback?: (resource: ResourceEntity) => void
  ) => {
    // load the resource and exit early if not found.
    const resource = getResourceEntity(resourceId);
    if (!resource) return;

    // update the resource with the uploaded file details.
    const updatedResource = mergeResource(resource, uploadedMedia);

    // update the resource in the game document.
    UpdateResourceAsync(
      state.gameDocument!,
      updatedResource.id,
      updatedResource
    ).then((response) => {
      setState((prevState) => UpdateGameDocState(prevState, response));
      if (callback) callback(updatedResource);
    });
  };

  /**
   * Updates a resource file in the game document.
   *
   * @param {string} resourceId - The ID of the resource to be updated.
   * @param {UploadedImage} uploadedMedia - The uploaded image data.
   * @param {Function} [callback] - An optional callback function to be called after the resource is updated.
   *                               It takes a single parameter, the updated resource entity.
   * @returns {void}
   */
  const addResourceFile = (
    resourceId: string,
    uploadedMedia: UploadedImage | '',
    callback?: (resource: ResourceEntity) => void
  ) => {
    // update the resource with the uploaded file details.
    const resource = {
      id: resourceId,
      name: resourceId,
      description: uploadedMedia === '' ? '' : uploadedMedia.fileName,
      value: uploadedMedia === '' ? '' : uploadedMedia.blobUrl,
      size: uploadedMedia === '' ? 0 : uploadedMedia.size,
      type: uploadedMedia === '' ? '' : uploadedMedia.mimeType?.split('/')[0]
    };

    // update the resource in the game document.
    AddResourceAsync(
      state.gameDocument!,
      resource.name,
      resource.name,
      resource.type,
      resource.value,
      resourceId,
      resource.size
    ).then((response) => {
      setState((prevState) => UpdateGameDocState(prevState, response));
      if (callback) callback(resource);
    });
  };

  /**
   * Updates a resource pack file.
   *
   * @param {string} packName - The name of the resource pack.
   * @param {string} resourceId - The ID of the resource.
   * @param {UploadedImage} uploadedMedia - The uploaded image object.
   * @returns {void}
   */
  const updateResourcePackFile = (
    packName: string,
    resourceId: string,
    uploadedMedia: UploadedImage
  ) => {
    // load the resource and exit early if not found.
    const resource = getResourcePackEntity(packName, resourceId);
    if (!resource) return;

    // update the resource with the uploaded file details.
    const updatedResource = mergeResource(resource, uploadedMedia);

    // update the resource in the game document.
    UpdateResourcePackResourceAsync(
      state.gameDocument!,
      packName,
      updatedResource.id,
      updatedResource
    ).then((response) => {
      setState((prevState) => UpdateGameDocState(prevState, response));
    });
  };

  /**
   * Merges the information from an uploaded image with a resource entity.
   *
   * @param {ResourceEntity} resource - The existing resource entity.
   * @param {UploadedImage} uploadedMedia - The uploaded image to merge with the resource entity.
   * @returns {ResourceEntity} - The resource entity with merged information from the uploaded image.
   */
  const mergeResource = (
    resource: ResourceEntity,
    uploadedMedia: UploadedImage
  ) => ({
    ...resource,
    name: uploadedMedia.fileName,
    description: uploadedMedia.fileName,
    value: uploadedMedia.blobUrl,
    size: uploadedMedia.size
  });

  return {
    createNewTaskResources,
    updateResource,
    updateResourceFile,
    updateResourcePackFile,
    mergeResource,
    addResourceFile
  };
};
