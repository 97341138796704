import { EnvironmentList } from '../types/environment';
import {
  GetAllAsync,
  GetAsync,
  PatchAsync,
  PostAsync,
  DeleteAsync
} from './api';

const EnvironmentEndpoint = `environments`;

export const AddEnvironmentAsync = async (request: EnvironmentList) =>
  PostAsync<EnvironmentList>(`${EnvironmentEndpoint}`, request);

export const GetEnvironmentAsync = async (params?: string) =>
  GetAllAsync<EnvironmentList>(`${EnvironmentEndpoint}?${params}`);

export const GetEnvironmentByIdAsync = async (environmentId: number) =>
  GetAsync<EnvironmentList>(`${EnvironmentEndpoint}/${environmentId}`);

export const PatchEnvironmentAsync = async (
  environmentId: number,
  environment: any | EnvironmentList
) =>
  PatchAsync<EnvironmentList>(
    `${EnvironmentEndpoint}/${environmentId}`,
    environment
  );

export const DeleteEnvironmentAsync = async (environmentId: number) =>
  DeleteAsync<EnvironmentList>(`${EnvironmentEndpoint}/${environmentId}`);
