import { PrivatePage } from '../../components/private-page';
import { useEffect } from 'react';
import { ctGameDefault, ctGameTitle } from '../../constants/text';
import SupportTicketsGrid from '../../features/support-tickets-grid';

export default function SupportTickets() {
  useEffect(() => {
    document.title = ctGameTitle + ' - Environments';

    return () => {
      document.title = ctGameDefault;
    };
  }, []);
  return (
    <PrivatePage
      breadcrumb={[
        {
          label: 'Dashboard',
          href: 'dashboard'
        },
        {
          label: 'Administration',
          href: 'administration'
        },
        {
          label: 'Support Tickets',
          href: 'administration/support_tickets'
        }
      ]}
      pageTitle={'Administration'}>
      <SupportTicketsGrid />
    </PrivatePage>
  );
}
