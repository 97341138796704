import SelectionItem from '../types/selection-item';

export const Conditions: SelectionItem[] = [
  { id: '1', name: 'does', description: 'does', colorClass: 'success' },
  { id: '2', name: 'doesNot', description: 'does not', colorClass: 'danger' },
  {
    id: '3',
    name: 'is',
    description: 'is',
    colorClass: 'danger',
    availableOnIdentifiers: ['score']
  },
  {
    id: '4',
    name: 'isNot',
    description: 'is not',
    colorClass: 'danger',
    availableOnIdentifiers: ['score']
  }
];

export default Conditions;
