import React, { useEffect, useState } from 'react';
import {
  ComboBox,
  ComboBoxFilterChangeEvent,
  ComboBoxProps
} from '@progress/kendo-react-dropdowns';
import { filterBy, FilterDescriptor } from '@progress/kendo-data-query';
import { GetLanguagesAsync } from '../../services/country';
import { Error } from '@progress/kendo-react-labels';
import { GetDisplayLanguagePublished } from '../../services/admin-display-language';
const delay: number = 500;

interface LanguagesComboBox extends ComboBoxProps {
  isDisplayLanguages?: boolean;
}

export const LanguageComboBox = (props: LanguagesComboBox) => {
  const languages = React.useRef<string[]>();
  const [data, setData] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState(false);

  // load the initial set of languages spoken by each country
  useEffect(() => {
    // cache hit
    if (languages.current) {
      setData(languages.current);
      setLoading(false);
      return;
    }
    // fetch new data
    if (!props.isDisplayLanguages) {
      GetLanguagesAsync().then((res) => {
        // sort alphabetically and store in ref with an intermediate collection for filtering
        const countries = res.data
          .filter((i) => i.language !== undefined)
          .sort((a, b) => a.language!.localeCompare(b.language!));
        // get DISTINCT languages. the country API returns all languages per country...
        // there are many countries that speak en...
        languages.current = [
          ...new Set(countries.map((item) => item.language!))
        ];
        setData(languages.current);
        setLoading(false);
      });
    } else {
      GetDisplayLanguagePublished().then((res) => {
        // sort alphabetically and store in ref with an intermediate collection for filtering
        const countries = res.data
          .filter((i) => i.languageName !== undefined)
          .sort((a, b) => a.languageName!.localeCompare(b.languageName!));
        // get DISTINCT languages. the country API returns all languages per country...
        // there are many countries that speak en...
        languages.current = [
          ...new Set(countries.map((item) => item.languageName!))
        ];
        setData(languages.current);
        setLoading(false);
      });
    }
  }, []);

  // allow filtering of the languages
  const filterData = (filter: FilterDescriptor) => {
    const data = languages.current!.slice();
    return filterBy(data, filter);
  };

  // debounce 500ms so filtering doesnt take affect after every keystroke
  const timeout = React.useRef<any>();
  const filterChange = (event: ComboBoxFilterChangeEvent) => {
    clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      setData(filterData(event.filter));
      setLoading(false);
    }, delay);
    setLoading(true);
  };

  return (
    <>
      <ComboBox
        data={data}
        filterable={true}
        onFilterChange={filterChange}
        loading={loading}
        allowCustom={false}
        {...props}
      />
      {props?.validationMessage && <Error>{props.validationMessage}</Error>}
    </>
  );
};

export default LanguageComboBox;
