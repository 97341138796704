import React from 'react';
import { ControlStructureEditor } from './control-structure-editor';
import { StepEditor } from './step-editor';
import { AddStep } from './add-step';
import { uuid } from '../../types/common-helper';
import {
  Algorithm,
  AlgorithmControlStructure,
  AlgorithmStep
} from '../../types/algorithm';
import { GameDocument } from '../../types/game-document';
import { isEqual } from 'lodash';
import cloneDeep from 'lodash.clonedeep';

interface AlgorithmEditorState {
  gameDocument?: GameDocument;
  steps: Array<AlgorithmStep | AlgorithmControlStructure>;
  onChange?: (steps: Array<AlgorithmStep>) => void;
}

export class AlgorithmEditor extends React.Component<
  Algorithm,
  AlgorithmEditorState
> {
  constructor(props: Algorithm) {
    super(props);

    this.state = {
      steps: props.steps ?? [],
      onChange: this.handleDataChange.bind(this)
    };
  }

  handleDataChange(steps: Array<AlgorithmStep>) {
    if (this.props.onChange) {
      this.props.onChange(steps);
    }
  }

  componentDidMount(): void {
    this.setState({
      ...this.state,
      steps: this.props.steps ?? []
    });
  }

  componentDidUpdate(prevProps: Readonly<Algorithm>): void {
    if (!isEqual(prevProps.steps, this.props.steps)) {
      this.setState({
        ...this.state,
        steps: this.props.steps ?? []
      });
    }
  }

  render() {
    const isControlStructure = (x: any): x is AlgorithmControlStructure =>
      !x.condition;

    const addStep = (index: number) => {
      let newSteps = this.props.steps;

      if (newSteps) {
        newSteps.splice(index, 0, { id: uuid() });
        this.setState({ steps: newSteps });
        this.handleDataChange(newSteps);
      }
    };

    const addControlStructure = (index: number) => {
      let newSteps = this.props.steps;
      if (newSteps) {
        newSteps.splice(index, 0, {
          id: uuid(),
          condition: 'select',
          ifSteps: [],
          elseSteps: []
        });
        this.setState({ steps: newSteps });
        this.handleDataChange(newSteps);
      }
    };

    const steps: any[] = [
      <AddStep
        key={uuid()}
        onAddStepClick={addStep}
        onAddControlStructureClick={addControlStructure}
        isCompoundStatement={false}
      />
    ];

    const handleStepChange = (
      step: AlgorithmStep | AlgorithmControlStructure
    ) => {
      const newSteps = this.props.steps as Array<
        AlgorithmStep | AlgorithmControlStructure
      >;

      for (let index = 0; index < newSteps.length; index++) {
        const stp = newSteps[index];

        if (stp.id === step.id) {
          newSteps[index] = step;
          break;
        }
      }

      if (this.handleDataChange) {
        this.handleDataChange(newSteps);
      }
    };

    const onDeleteStep = (step: AlgorithmStep) => {
      const newSteps = this.props.steps as Array<AlgorithmStep>;

      if (newSteps) {
        newSteps.forEach((stp, index) => {
          if (stp.id === step.id) {
            newSteps?.splice(index, 1); // 2nd parameter means remove one item only
          }
        });
      }

      if (this.handleDataChange) {
        this.handleDataChange(newSteps);
      }
    };

    const onDeleteStructureStep = (step: AlgorithmControlStructure) => {
      const newSteps = this.props.steps as Array<AlgorithmControlStructure>;

      if (newSteps) {
        newSteps.forEach((stp, index) => {
          if (stp.id === step.id) {
            newSteps?.splice(index, 1); // 2nd parameter means remove one item only
          }
        });
      }

      if (this.handleDataChange) {
        this.handleDataChange(newSteps);
      }
    };

    const handleMoveUp = (step: AlgorithmStep) => {
      const newSteps = this.props.steps as Array<AlgorithmControlStructure>;

      if (newSteps) {
        let index = -1;

        const data = newSteps.find(function (item, i) {
          if (item.id === step.id) {
            index = i;
            return i;
          }
        });

        if (index > 0) {
          stepMove(newSteps, index, index - 1);
        }
      }
    };

    const handleMoveDown = (step: AlgorithmStep) => {
      const newSteps = this.props.steps as Array<AlgorithmControlStructure>;

      if (newSteps) {
        let index = -1;

        const data = newSteps.find(function (item, i) {
          if (item.id === step.id) {
            index = i;
            return i;
          }
        });

        if (index > -1 && index < newSteps.length) {
          stepMove(newSteps, index, index + 1);
        }
      }
    };

    const handleCopy = (step: AlgorithmStep) => {
      let newStep = cloneDeep(step);
      if (newStep) {
        newStep.id = uuid();
        let newSteps = cloneDeep(this.props.steps);

        if (newSteps) {
          newSteps.push(newStep);
          this.handleDataChange(newSteps);
        }
      }
    };

    const handleControlStructureCopy = (step: AlgorithmControlStructure) => {
      let newStep = cloneDeep(step);
      if (newStep) {
        newStep.id = uuid();
        let newSteps = cloneDeep(this.props.steps);

        if (newSteps) {
          newStep?.ifSteps?.forEach((item) => {
            item.id = uuid();
          });

          newStep?.elseSteps?.forEach((item) => {
            item.id = uuid();
          });

          newSteps.push(newStep);
          this.handleDataChange(newSteps);
        }
      }
    };

    const stepMove = (
      steps: Array<AlgorithmControlStructure | AlgorithmStep>,
      fromIndex: number,
      toIndex: number
    ) => {
      const element = steps[fromIndex];
      steps.splice(fromIndex, 1);
      steps.splice(toIndex, 0, element);
      if (this.handleDataChange) {
        this.handleDataChange(steps);
      }
    };

    this.props.steps?.forEach((step, index) => {
      if (isControlStructure(step))
        steps.push(
          <StepEditor
            key={step.id}
            {...step}
            onChange={handleStepChange}
            onDelete={onDeleteStep}
            onMoveUp={handleMoveUp}
            onMoveDown={handleMoveDown}
            onCopy={handleCopy}
            action={this.props.action}
          />
        );
      else
        steps.push(
          <ControlStructureEditor
            key={step.id}
            {...step}
            onChangeStructure={handleStepChange}
            onDelete={onDeleteStructureStep}
            onMoveUp={handleMoveUp}
            onMoveDown={handleMoveDown}
            onCopy={handleControlStructureCopy}
            action={this.props.action}
          />
        );
      steps.push(
        <AddStep
          key={uuid()}
          onAddStepClick={() => addStep(index + 1)}
          onAddControlStructureClick={() => addControlStructure(index + 1)}
          isCompoundStatement={false}
        />
      );
    });

    return (
      <div className={'algorithm-editor container-fluid pb-5'}>{steps}</div>
    );
  }
}
