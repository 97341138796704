import EventGrid from '../features/events-grid';
import { PrivatePage } from '../components/private-page';
import { useEffect } from 'react';
import { ctGameDefault, ctGameTitle } from '../constants/text';

export default function MyEvents() {
  useEffect(() => {
    document.title = ctGameTitle + ' - My events';
    return () => {
      document.title = ctGameDefault;
    };
  }, []);
  return (
    <PrivatePage
      breadcrumb={[
        { label: 'Dashboard', href: 'dashboard' },
        { label: 'My events', href: 'myevents' }
      ]}
      pageTitle={'Events'}>
      <EventGrid></EventGrid>
    </PrivatePage>
  );
}
