import { State } from '@progress/kendo-data-query';
import { PageChangeEvent, Pager } from '@progress/kendo-react-data-tools';
import {
  DefaultGridSettings,
  DefaultPagerSettings
} from '../constants/grid-settings';
import { GameResponse } from '../types/responses/game-response';

export interface CardViewDataStateChangeEvent {
  dataState: State;
}

export interface LibraryCardViewProps extends State {
  data?: GameResponse[];
  onTitleClick?: (id: number) => void;
  onDataStateChange?: (event: CardViewDataStateChangeEvent) => void;
  total?: number;
}
export const GlobalLibraryPendingCardView = ({
  data,
  onTitleClick,
  total = 0,
  ...props
}: LibraryCardViewProps) => {
  const handleTitleClick = (id: number) => {
    if (onTitleClick) {
      onTitleClick(id);
    }
  };

  const handlePageChange = (event: PageChangeEvent) => {
    raiseDataStateChangeEvent({ skip: event.skip, take: event.take });
  };

  /**
   * Raise the Grid DataState change event. I.e. filters have changed.
   * @param moreData
   */
  const raiseDataStateChangeEvent = (moreData: State) => {
    props.onDataStateChange?.({
      dataState: { ...getDataState(), ...moreData }
    });
  };

  const getDataState = () => {
    return {
      filter: props.filter,
      sort: props.sort,
      skip: props.skip,
      take:
        props.take !== undefined ? props.take : DefaultGridSettings.pageSize,
      group: props.group
    };
  };

  return (
    <div className={'mt-3'}>
      <div className={'row'}>
        {data &&
          data.map((lib, index) => {
            return (
              <div key={index} className={'col-sm-4'}>
                <div className={'card mt-4 card-view'}>
                  <div className={'row p-3'}>
                    <div className={'col-sm-4'}>
                      <div className={'avatar'}>
                        <i className={'bi bi-person icon'} />
                      </div>
                    </div>
                    <div className={'col-sm-8'}>
                      <div className={'card-body-right p-2'}>
                        <div className={'d-flex justify-content-between align-items-center'}>
                          <h3
                            className={'title link-primary cursor-pointer'}
                            onClick={() => handleTitleClick(lib.id!)}>
                            {' '}
                            {lib?.name}
                          </h3>
                        </div>
                        <hr className={'mt-0'} />
                        <span className={'text-label'}>Game Designer :</span>
                        <span className={'text-value p-1'}>{lib.createdBy}</span>
                        <br />
                        <span className={'text-label'}>Short Description :</span>
                        <span className={'text-value p-1'}>
                          {lib?.shortDescription}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <div className={'row mt-3'}>
        <div className={'col-md-12'}>
          <Pager
            onPageChange={handlePageChange}
            total={total}
            take={
              props.take !== undefined
                ? props.take
                : DefaultGridSettings.pageSize
            }
            skip={props.skip !== undefined ? props.skip : 0}
            {...DefaultPagerSettings}
          />
        </div>
      </div>
    </div>
  );
};
