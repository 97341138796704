import { useNavigate } from 'react-router-dom';
import { Button } from '@progress/kendo-react-buttons';

import CardItem from './card-item';
import { GetGridAllUsersAsync } from '../../../services/allusers';

const UserCard = () => {
  const navigate = useNavigate();

  return (
    <div className={'d-flex flex-column py-2 w-100 h-100 gap-1'}>
      <span className={`material-symbols-outlined`}>group</span>
      <div
        className={'d-flex justify-content-between align-items-center fw-bold'}>
        <span>User Management</span>
        <span className={`material-symbols-outlined fw-bold text-primary`}>
          chevron_right
        </span>
      </div>
      <hr className={'my-2 w-100'} />
      <div className={'flex-grow-1'}>
        <CardItem
          goTo={'/administration/users?isNewUser=true'}
          text={'New Users'}
          fetcher={GetGridAllUsersAsync({
            take: 1,
            filter: {
              logic: 'and',
              filters: [
                {
                  field: 'emailConfirmed',
                  operator: 'eq',
                  value: false
                }
              ]
            }
          })}
          isLastIndex
        />
      </div>
      <hr className={'my-2 w-100'} />
      <div className={'d-flex flex-column gap-2'}>
        <Button
          themeColor={'primary'}
          onClick={() => navigate('/administration/users/invite-user')}>
          <span className={`material-symbols-outlined`}>add</span>
          Add user
        </Button>
        <Button
          themeColor={'primary'}
          onClick={() => navigate('/administration/organisations/new')}>
          <span className={`material-symbols-outlined`}>add</span>Add
          organisation
        </Button>
      </div>
    </div>
  );
};

export default UserCard;
