import { v6 as uuidv6 } from 'uuid';

/**
 * Generates a random UUID (Universally Unique Identifier) in the format 'aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa'.
 *
 * @function
 * @returns {string} - The generated UUID.
 */
export const uuid = () => uuidv6();

/**
 * Generates a random UUID (Universally Unique Identifier) in the format 'aaaaaaaaaaaa'.
 *
 * @function
 * @returns {string} The unique identifier.
 */
export const uuid12 = () => {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  //return id of format 'aaaaaaaaaaaa'
  return s4() + s4() + s4();
};
