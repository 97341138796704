import OrganisationsGrid from '../features/organisations-grid';
import { PrivatePage } from '../components/private-page';
import { useEffect } from 'react';
import { ctGameDefault, ctGameTitle } from '../constants/text';

export default function Organisations() {
  useEffect(() => {
    document.title = ctGameTitle + ' - Organisations';

    return () => {
      // return default value when component unmounts
      document.title = ctGameDefault;
    };
  }, []);

  return (
    <PrivatePage
      breadcrumb={[
        {
          label: 'Dashboard',
          href: 'dashboard'
        },
        {
          label: 'Administration',
          href: '../administration'
        },

        {
          label: 'Organisations',
          href: '../administration/organisations'
        }
      ]}
      pageTitle={'Administration'}>
      <OrganisationsGrid />
    </PrivatePage>
  );
}
