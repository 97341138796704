import { Button } from 'react-bootstrap';
import { DialogProps } from './dialog';
import { Window, WindowActionsBar } from '@progress/kendo-react-dialogs';
import { YesNoDialogResult } from '../../types/dialog-result';

export interface YesNoDialogProps extends DialogProps {
  onConfirm?: (result: YesNoDialogResult) => void;
}

export function YesNoDialog(props: YesNoDialogProps) {
  const handleConfirm = (result: YesNoDialogResult) => {
    if (props.onConfirm) {
      props.onConfirm(result);
    }
  };
  return (
    <Window
      title={props.title}
      draggable={false}
      modal={true}
      maximizeButton={undefined}
      resizable={false}
      initialWidth={500}
      initialHeight={200}
      onClose={() => handleConfirm('no')}>
      <div>{props.children}</div>
      <WindowActionsBar>
        <Button
          className={'btn-secondary text-light'}
          onClick={() => {
            handleConfirm('no');
          }}>
          Cancel
        </Button>
        <Button
          className={'text-light'}
          color={'secondary'}
          onClick={() => {
            handleConfirm('yes');
          }}
        >
          Confirm
        </Button>
      </WindowActionsBar>
    </Window>
  );
}

export default YesNoDialog;
