import { PrivatePage } from '../../components/private-page';
import { useEffect } from 'react';
import { ctGameDefault, ctGameTitle } from '../../constants/text';
import { Row } from 'react-bootstrap';
import NetworkMembersGrid from '../../features/network-members-grid';

export default function NetworkMembers() {
  useEffect(() => {
    document.title = ctGameTitle + ' - Members';
    return () => {
      document.title = ctGameDefault;
    };
  }, []);

  return (
    <>
      <PrivatePage
        breadcrumb={[
          { label: 'Dashboard', href: 'dashboard' },
          { label: 'Members', href: 'networkmembers' }
        ]}
        pageTitle={'Members'}>
        <Row>
          <NetworkMembersGrid />
        </Row>
      </PrivatePage>
    </>
  );
}
