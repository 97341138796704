import MenuItem from '../components/menu-item';

/**
 * The menu used for the game designer area layouts.
 */
const GameDesignerMenu = () => {
  return (
    <aside className={'app-side-nav overflow-scroll'}>
      <div className={'logo'} />
      <hr />
      {/* prettier-ignore */}
      <div className={'aside-menu'}>
        <MenuItem to={'/'} icon={'chevron_left'} label={'Back to dashboard'} className={'text-success'} />
      </div>
      <hr />
      {/* prettier-ignore */}
      <div className={'aside-menu'}>
        <MenuItem to={''} icon={'draw'} label={'Overview'} />
        <MenuItem to={'map'} icon={'map'} label={'Map'} end={false} />
        <MenuItem to={'assets/inventory'} icon={'category'} label={'Assets'} expanded={true}>
         {/** temporary hide related ticket #4887 <MenuItem to={'assets/assessments'} icon={'fact_check'} label={'Assessments'} end={false} iconSet={'light'} /> */} 
          <MenuItem to={'assets/inventory'} icon={'inventory_2'} label={'Inventory'} end={false} iconSet={'light'} />
          <MenuItem to={'assets/tasks'} icon={'checklist'} label={'Tasks'} end={false} iconSet={'light'} />
          <MenuItem to={'assets/task-content'} icon={'checklist'} label={'Task Content'} end={false} iconSet={'light'} />
          <MenuItem to={'assets/timers'} icon={'timer'} label={'Timers'} end={false} iconSet={'light'} />
          <MenuItem to={'assets/titles'} icon={'military_tech'} label={'Titles'} end={false} iconSet={'light'} />
          <MenuItem to={'assets/zones'} icon={'activity_zone'} label={'Zones'} end={false} iconSet={'light'} />
          <MenuItem to={'assets/maps'} icon={'map'} label={'Maps'} end={false} iconSet={'light'} />
          <MenuItem to={'assets/areas'} icon={'flag'} label={'Areas'} end={false} iconSet={'light'} />
        </MenuItem>
        <MenuItem to={'resources/resource'} icon={'perm_media'} label={'Resource'} />
        {/* regarding task 5957 Hide menu assessment on designer */}
        {/* <MenuItem to={'assessment'} icon={'task'} label={'Assessment'} /> */}
        <MenuItem to={'resources/theme'} icon={'palette'} label={'Theme'} />
        <MenuItem to={'resources/language'} icon={'translate'} label={'Language'} />
        <MenuItem to={'settings'} icon={'settings'} label={'Settings'} />
        <MenuItem to={'diagnostics'} icon={'data_object'} label={'Diagnostics'} />
      </div>
    </aside>
  );
};

export default GameDesignerMenu;
