import React from 'react';
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab
} from '@progress/kendo-react-layout';
import { ContentItem } from '../../types/tab-content';

type TabContentProps = {
  selected?: number;
  handleSelect?: ((e: TabStripSelectEventArguments) => void) | (() => void);
  items?: ContentItem[];
};

const TabContentSCG = ({
  selected = 0,
  handleSelect = () => {},
  items = []
}: TabContentProps) => {
  return (
    <TabStrip selected={selected} onSelect={handleSelect}>
      {items.length > 0 &&
        items.map((item, index) => (
          <TabStripTab title={item.title} key={index}>
            {item.content}
          </TabStripTab>
        ))}
    </TabStrip>
  );
};

export default TabContentSCG;
