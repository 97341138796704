import cloneDeep from 'lodash.clonedeep';
import merge from 'lodash.merge';
import { Assessment } from '../../types/assessment';
import { uuid } from '../../types/common-helper';
import { Resource } from '../../types/game-document';
import {
  ResourceEntity,
  ResourcePackEntity
} from '../../types/game-document/entities';
import {
  LanguageMapping,
  ResourcePack
} from '../../types/game-document/entities/resource-pack';
import { GetNextAssetNameAsync } from '../../utils/game-document/assets';

/**
 * Retrieves all resources the game document.
 * @param assessmentDocument - The Game Document to modify
 */
export const GetResources = (
  assessmentDocument: Assessment
): ResourceEntity[] => {
  return (assessmentDocument.resources as ResourceEntity[]) ?? [];
};

/**
 * Retrieves resource by ID from the game document.
 * @param assessmentDocument - The Game Document to modify
 * @param resourceId - The Resource ID to be obtained
 */
export const GetResourceById = (
  assessmentDocument: Assessment,
  resourceId: string
) =>
  assessmentDocument.resources?.find((resource) => resource.id === resourceId);

/**
 * Retrieves all resource packs from the game document.
 * @param assessmentDocument - The Game Document to modify
 */
export const GetResourcePacks = (
  assessmentDocument: Assessment
): ResourcePackEntity[] => {
  return (assessmentDocument.resourcePacks as ResourcePackEntity[]) ?? [];
};

/**
 * Retrieves resource from resource pack's resource by ID from the game document.
 * @param assessmentDocument - The Game Document to modify
 * @param resourceId - The Resource ID to be obtained
 */
export const GetResourcePackResourcesById = (
  assessmentDocument: Assessment,
  resourcePackId: string
): ResourceEntity[] => {
  let selectedResourcePack: ResourcePackEntity =
    assessmentDocument.resourcePacks?.find(
      (resourcePack) => resourcePack.id === resourcePackId
    );
  return (selectedResourcePack.resources as ResourceEntity[]) ?? [];
};

/**
 * Retrieves resource pack by name from the game document.
 * @param assessmentDocument - The Game Document to modify
 * @param name - The Resource Pack's name to be obtained
 */
export const GetResourcePackByName = (
  assessmentDocument: Assessment,
  name: string
) => {
  const resources = cloneDeep(assessmentDocument?.resources);
  let newLanguageMapping: LanguageMapping[] = [];
  const selectedResourcePack: ResourcePack =
    assessmentDocument?.resourcePacks?.find((x) => x.name === name);

  resources?.forEach((resource) => {
    let selectedNewLanguage = selectedResourcePack?.resources?.find(
      (x) => x.id === resource?.id
    );

    newLanguageMapping.push({
      id: resource?.id,
      type: resource.type,
      originalLanguage: resource?.value,
      newLanguage: selectedNewLanguage && selectedNewLanguage?.value,
      isEditing: true
    });
  });

  return newLanguageMapping;
};

/**
 * Retrieves a resource entity from the assessment document by id.
 * @param assessmentDocument - The Assessment Document to check resources
 * @param resourceId - The ID of the Resource to update
 */
export const GetResourceEntity = (
  assessmentDocument: Assessment,
  resourceId: string
): ResourceEntity => {
  let resources = GetResources(assessmentDocument);
  let resourceIndex = resources.findIndex((i) => i.id === resourceId)!;
  return resources[resourceIndex];
};

/**
 * Retrieves a resource value from the assessment document by id.
 * @param assessmentDocument - The Assessment Document to check resources
 * @param resourceId - The ID of the Resource to update
 */
export const GetResourceValue = (
  assessmentDocument: Assessment,
  resourceId: string
) => {
  let resourceEntity = GetResourceEntity(assessmentDocument, resourceId);
  return resourceEntity?.value ?? '';
};

export const MergeResources = (
  assessmentDocument: Assessment,
  resources: Array<ResourceEntity>
) =>
  merge(assessmentDocument, {
    resources
  });

export const GetNextResourceNameAsync = async (
  resources: Array<ResourceEntity>,
  name: string,
  id?: string
) => {
  let resourceExists = await ResourceExistsAsync(resources, name, id);
  let counter = 1;
  let nextResourceName = name;
  while (resourceExists) {
    nextResourceName = `${name}-${counter}`;
    resourceExists = await ResourceExistsAsync(resources, nextResourceName, id);
    counter += 1;
  }
  return nextResourceName;
};

export const ResourceExistsAsync = async (
  resources: Array<ResourceEntity>,
  name: string,
  id?: string
) => {
  let resourceIndex = resources.findIndex(
    (i) => i.name === name && i.id !== id
  );
  return resourceIndex !== -1;
};

/**
 * Retrieve resource entity from resource pack's resource from the game document.
 * @param assessmentDocument - The Game Document to modify
 * @param name - The Resource Pack name
 * @param resourceId - Resource ID to be obtained
 */
export const GetResourcePackResource = (
  assessmentDocument: Assessment,
  name: string,
  resourceId: string
) => {
  const selectedResourcePack: ResourcePack =
    assessmentDocument?.resourcePacks?.find((x) => x.name === name);

  return selectedResourcePack?.resources?.find((x) => x.id === resourceId);
};

/**
 * Add new resource to the game document.
 * @param assessmentDocument - The Game Document to modify
 * @param entity - New resource entity
 */
export const AddResourceEntityAsync = async (
  assessmentDocument: Assessment,
  entity: ResourceEntity
) => {
  let resources = GetResources(assessmentDocument);
  let resourceName = await GetNextResourceNameAsync(resources, entity.name);
  resources.push({ ...entity, name: resourceName });
  return MergeResources(assessmentDocument, resources);
};

/**
 * Update existing resource from the game document.
 * @param assessmentDocument - The Game Document to modify
 * @param resourceId - Resource ID to be updated
 * @param resource - Updated resource entity
 */
export const UpdateResourceAsync = async (
  assessmentDocument: Assessment,
  resourceId: string,
  resource: ResourceEntity
) => {
  let resources = GetResources(assessmentDocument);
  let resourceIndex = resources.findIndex((i) => i.id === resourceId)!;
  resource.name = await GetNextResourceNameAsync(
    resources,
    resource.name,
    resource.id
  );
  resources[resourceIndex] = resource;
  return MergeResources(assessmentDocument, resources);
};

/**
 * Delete existing resource from the game document.
 * @param assessmentDocument - The Game Document to modify
 * @param resourceId - Resource ID to be deleted
 */
export const DeleteResourceAsync = async (
  assessmentDocument: Assessment,
  resourceId: string
) => {
  let resources = GetResources(assessmentDocument);
  let resourceIndex = resources.findIndex((i) => i.id === resourceId)!;
  if (resourceIndex < 0) {
    return assessmentDocument;
  }
  resources.splice(resourceIndex, 1);
  return MergeResources(assessmentDocument, resources);
};

/**
 * Add new resource pack from the Assessment document.
 * @param assessmentDocument - The Assessment Document to modify
 * @param name - The name of the Resource Pack
 * @param displayLanguageIso - The country iso of the language
 * @param isCopyOriginaltext - Trigger to copy the original resource's values
 * @param displayLanguage - name of display language selected
 * @param displayLanguageUrl - blob file url of display language selected
 * @return The updated Assessment Document
 */
export const AddResourcePackAsync = async (
  assessmentDocument: Assessment,
  name: string,
  displayLanguageIso: string,
  isCopyOriginaltext?: boolean,
  displayLanguage?: string,
  displayLanguageUrl?: string
) => {
  let resourcePacks = cloneDeep(assessmentDocument.resourcePacks) ?? [];
  let resourcePackName = await GetNextAssetNameAsync(resourcePacks, name);

  let originalResources: Resource[] =
    cloneDeep(assessmentDocument?.resources) ?? [];

  let newResourcePack: ResourcePackEntity = {
    id: uuid(),
    description: '',
    name: resourcePackName,
    displayLanguageIso,
    displayLanguage,
    displayLanguageUrl,
    resources: []
  };

  originalResources.forEach((resource) => {
    if (!isCopyOriginaltext) {
      resource.value = '';
    }
    newResourcePack.resources!.push(resource as ResourceEntity);
  });

  resourcePacks.push(newResourcePack);

  return merge(assessmentDocument, {
    resourcePacks
  });
};

/**
 * Updates the resources pack detail in the Assessment.
 * Not Update resources of resource pack.
 * @param Assessment - The Assessment to modify
 * @param name - The name of the Resource Pack
 * @param displayLanguageIso - The country iso of the language
 * @param resourcePackId - The ID of the Resource pack to update
 * @param displayLanguage - name of display language selected
 * @param displayLanguageUrl - blob file url of display language selected
 * @return The updated Assessment Document
 */
export const UpdateAssessmentResourcePackDetail = async (
  assessmentDocument: Assessment,
  name: string,
  displayLanguageIso: string,
  resourcePackId: string,
  displayLanguage: string,
  displayLanguageUrl: string
) => {
  let resourcePacks = GetResourcePacks(assessmentDocument);

  let resourcePacksIndex = resourcePacks.findIndex(
    (i) => i.id === resourcePackId
  )!;
  if (resourcePacksIndex > -1) {
    let newResourcePack = resourcePacks[resourcePacksIndex];
    newResourcePack.name = name;
    newResourcePack.displayLanguageIso = displayLanguageIso;
    newResourcePack.displayLanguage = displayLanguage;
    newResourcePack.displayLanguageUrl = displayLanguageUrl;

    resourcePacks[resourcePacksIndex] = newResourcePack;
  }
  return merge(assessmentDocument, {
    resourcePacks
  });
};

/**
 * Updates the resource pack's resource in the Assessment Document.
 * @param gameDocument - The Assessment Document to modify
 * @param resourceId - The ID of the Resource to update
 * @param resource - The updated Resource
 */
export const UpdateResourcePackResourceAsync = async (
  assessmentDocument: Assessment,
  name: string,
  resourceId: string,
  resource: ResourceEntity
) => {
  let resourcePacks = GetResourcePacks(assessmentDocument);

  let resourcePacksIndex = resourcePacks.findIndex((i) => i.name === name)!;

  if (resourcePacksIndex > -1) {
    let resourcePack = resourcePacks[resourcePacksIndex];

    let resourceIndex = resourcePack?.resources?.findIndex(
      (x) => x.id === resourceId
    )!;

    if (resourceIndex > -1) {
      resourcePacks[resourcePacksIndex].resources![resourceIndex] = resource;
    }

    return merge(assessmentDocument, {
      resourcePacks
    });
  }
};

/**
 * Delete a resource inside resource pack's resource in the Assessment Document.
 * @param assessmentDocument - The Assessment Document to modify
 * @param resourcePackResourceId - The ID of the Resource to delete
 */
export const DeleteResourcePackResourceAsync = async (
  assessmentDocument: Assessment,
  resourcePackResourceId: string
) => {
  let resourcePacks = GetResourcePacks(assessmentDocument);

  if (resourcePacks.length > 0) {
    resourcePacks.forEach((resPack) => {
      let resourcePackResourceIndex = resPack.resources!.findIndex(
        (i) => i.id === resourcePackResourceId
      )!;
      if (resourcePackResourceIndex > -1) {
        resPack.resources!.splice(resourcePackResourceIndex, 1);
      }
    });

    return merge(assessmentDocument, {
      resourcePacks
    });
  } else {
    return assessmentDocument;
  }
};

/**
 * Add the resource pack's resource in the Assessment Document.
 * @param assessmentDocument - The Assessment Document to modify
 * @param resourceId - The ID of the Resource to update
 * @param resource - The updated Resource
 */
export const AddResourcePackResourceAsync = async (
  assessmentDocument: Assessment,
  name: string,
  resource: ResourceEntity
) => {
  let resourcePacks = GetResourcePacks(assessmentDocument);

  let resourcePacksIndex = resourcePacks.findIndex((i) => i.name === name)!;

  if (resourcePacksIndex > -1) {
    resourcePacks[resourcePacksIndex].resources?.push(resource);

    return merge(assessmentDocument, {
      resourcePacks
    });
  } else {
    return assessmentDocument;
  }
};

/**
 * Add the resource pack's resource in the Assessment Document.
 * @param assessmentDocument - The Assessment Document to modify
 * @param originalResourceId - The original ID of the Resource to be searched
 * @param newResourceId - The new ID of the copied Resource
 */
export const copyResourceAndPackById = async (
  assessmentDocument: Assessment,
  originalResourceId: string,
  newResourceId: string
) => {
  let newDocument = cloneDeep(assessmentDocument);
  let newResource = cloneDeep(GetResourceById(newDocument, originalResourceId));

  if (newResource !== undefined) {
    newResource.id = newResourceId;

    await AddResourceEntityAsync(newDocument, newResource).then((response) => {
      if (
        response.resourcePacks !== undefined &&
        response.resourcePacks.length > 0
      ) {
        let newResourcePack: ResourceEntity | undefined;

        response.resourcePacks.forEach((resPack) => {
          newResourcePack = cloneDeep(
            GetResourcePackResourcesById(newDocument, resPack.id).find(
              (x) => x.id === originalResourceId
            )
          );
          if (newResourcePack !== undefined) {
            newResourcePack.id = newResourceId;
            AddResourcePackResourceAsync(
              newDocument,
              resPack.name,
              newResourcePack
            ).then((response) => {
              if (response !== undefined) {
                newDocument = response;
              }
            });
          } else {
            return;
          }
        });

        return newDocument;
      } else {
        return response;
      }
    });

    return newDocument;
  } else {
    return newDocument;
  }
};
