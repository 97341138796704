import { PropsWithChildren } from 'react';
import { Resource as ResourceEntity } from '../../types/game-document/entities/resource';
import { State } from '@progress/kendo-data-query';
import { PageChangeEvent, Pager } from '@progress/kendo-react-data-tools';
import {
  DefaultGridSettings,
  DefaultPagerSettings
} from '../../constants/grid-settings';
import { ResourceType } from '../../types/game-document/resource-type';
import { ResourceThumbnailCell } from '../../components/resource/resource-thumbnail-cell';
import { ActionCell } from '../../components/grid/action-cell';

export interface CardViewDataStateChangeEvent {
  dataState: State;
}

export interface ResourcesCardViewProps extends State {
  data?: ResourceEntity[];
  onNameClick?: (entity: ResourceEntity) => void;
  onDataStateChange?: (event: CardViewDataStateChangeEvent) => void;
  onDeleteClick: (id: string) => void;
  onEditClick?: (entity: ResourceEntity) => void;
  total?: number;
}

export const ResourcesCardView = ({
  data,
  onNameClick,
  onDeleteClick,
  onEditClick,
  total = 0,
  ...props
}: PropsWithChildren<ResourcesCardViewProps>) => {
  const handleNameClick = (entity: ResourceEntity) => {
    if (onNameClick) {
      onNameClick(entity);
    }
  };

  const handleEditClick = (entity: ResourceEntity) => {
    if (onEditClick) {
      onEditClick(entity);
    }
  };

  const handlePageChange = (event: PageChangeEvent) => {
    raiseDataStateChangeEvent({ skip: event.skip, take: event.take });
  };

  /**
   * Raise the Grid DataState change event. I.e. filters have changed.
   * @param moreData
   */
  const raiseDataStateChangeEvent = (moreData: State) => {
    props.onDataStateChange?.({
      dataState: { ...getDataState(), ...moreData }
    });
  };

  const getDataState = () => {
    return {
      filter: props.filter,
      sort: props.sort,
      skip: props.skip,
      take:
        props.take !== undefined ? props.take : DefaultGridSettings.pageSize,
      group: props.group
    };
  };

  const getIconName = (type: ResourceType) => {
    switch (type) {
      case 'video':
        return 'play_circle';
      default:
        return type;
    }
  };

  return (
    <div className={'mt-3'}>
      <div className={'row'}>
        {data &&
          data.map((resource, index) => (
            <div
              className={
                'col-sm-12 col-md-6 col-lg-6 col-xl-4 d-flex align-items-stretch'
              }
              key={index}>
              <div className={'card mt-4 p-2 flex-fill'}>
                <div className={'row'}>
                  <div className={'d-grid col-3 col-md-5 col-lg-3'}>
                    <ResourceThumbnailCell
                      type={resource.type}
                      url={getIconName(resource.value!)}
                    />
                  </div>
                  <div className={'col-9 col-md-7 col-lg-9'}>
                    <div className={'card-body-right p-2 d-flex flex-column'}>
                      <span
                        className={'card-text align-middle '}
                        onClick={() => handleNameClick(resource!)}>
                        Name:
                        <span className={'link-primary ml-1 cursor-pointer'}>
                          {resource.name}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="d-flex flex-row-reverse">
                    <ActionCell
                      title={'Delete'}
                      onClick={() => {
                        onDeleteClick(resource.id);
                      }}
                      icon={'delete'}
                      themeColor={'error'}
                    />
                    <ActionCell
                      title={'Edit'}
                      onClick={() => {
                        handleEditClick(resource);
                      }}
                      icon={'edit'}
                      themeColor={'primary'}
                    />
                    <ActionCell
                      title={'view'}
                      onClick={() => {
                        handleNameClick(resource);
                      }}
                      icon={'visibility'}
                      themeColor={'primary'}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className={'row mt-3'}>
        <div className={'col-md-12'}>
          <Pager
            onPageChange={handlePageChange}
            total={total}
            take={
              props.take !== undefined
                ? props.take
                : DefaultGridSettings.pageSize
            }
            skip={props.skip !== undefined ? props.skip : 0}
            {...DefaultPagerSettings}
          />
        </div>
      </div>
    </div>
  );
};
