import React, { useContext } from 'react';
import { Switch, SwitchChangeEvent } from '@progress/kendo-react-inputs';

import { fromLonLat, toLonLat } from 'ol/proj';
import { Coordinate } from '../../types/place';
import { MapContext } from './map-context';

interface MoveMapProps {
  onViewChange?: (value?: any) => void | undefined;
  isOpenStreetMap?: boolean;
}
export default function MoveMapPad({
  onViewChange = () => {},
  isOpenStreetMap
}: MoveMapProps) {
  const map = useContext(MapContext);
  const onViewChangeMapType = (e: SwitchChangeEvent) => onViewChange(e);
  const onMoveLeft = () => {
    const center = map.getView().getCenter();
    if (!center || !center[0] || !center[1]) {
      return undefined;
    }
    const latLon = toLonLat(center);
    const currentCoordinate: Coordinate = {
      lat: latLon[1],
      lng: latLon[0]
    };
    const zoom = map.getView().getZoom() || 4;
    let optionalZoom = zoom;
    if (optionalZoom > 1 && optionalZoom < 2) {
      optionalZoom = zoom / 10;
    }
    if (optionalZoom > 2 && optionalZoom < 3) {
      optionalZoom = zoom / 20;
    }
    if (optionalZoom > 3 && optionalZoom < 5) {
      optionalZoom = zoom / 30;
    }
    if (optionalZoom > 5 && optionalZoom < 7) {
      optionalZoom = zoom / 100;
    }
    if (optionalZoom > 7 && optionalZoom < 9) {
      optionalZoom = zoom / 1024;
    }
    if (optionalZoom > 9 && optionalZoom < 11) {
      optionalZoom = zoom / 2048;
    }
    if (optionalZoom > 11 && optionalZoom < 14) {
      optionalZoom = zoom / 17096;
    }
    if (optionalZoom > 14 && optionalZoom < 17) {
      optionalZoom = zoom / 110096;
    }
    if (optionalZoom > 17) {
      optionalZoom = zoom / 1405096;
    }

    const mapInfo = {
      longitude: currentCoordinate.lng - optionalZoom,
      latitude: currentCoordinate.lat,
      zoomLevel: zoom
    };

    map.getView().setCenter(fromLonLat([mapInfo.longitude, mapInfo.latitude]));
  };

  const onMoveRight = () => {
    const center = map.getView().getCenter();
    if (!center || !center[0] || !center[1]) {
      return undefined;
    }
    const latLon = toLonLat(center);
    const currentCoordinate: Coordinate = {
      lat: latLon[1],
      lng: latLon[0]
    };
    const zoom = map.getView().getZoom() || 4;
    let optionalZoom = zoom;

    if (optionalZoom > 1 && optionalZoom < 2) {
      optionalZoom = zoom / 10;
    }
    if (optionalZoom > 2 && optionalZoom < 3) {
      optionalZoom = zoom / 20;
    }
    if (optionalZoom > 3 && optionalZoom < 5) {
      optionalZoom = zoom / 30;
    }
    if (optionalZoom > 5 && optionalZoom < 7) {
      optionalZoom = zoom / 100;
    }
    if (optionalZoom > 7 && optionalZoom < 9) {
      optionalZoom = zoom / 1024;
    }
    if (optionalZoom > 9 && optionalZoom < 11) {
      optionalZoom = zoom / 2048;
    }
    if (optionalZoom > 11 && optionalZoom < 14) {
      optionalZoom = zoom / 17096;
    }
    if (optionalZoom > 14 && optionalZoom < 17) {
      optionalZoom = zoom / 110096;
    }
    if (optionalZoom > 17) {
      optionalZoom = zoom / 1405096;
    }

    const mapInfo = {
      longitude: currentCoordinate.lng + optionalZoom,
      latitude: currentCoordinate.lat,
      zoomLevel: zoom
    };

    map.getView().setCenter(fromLonLat([mapInfo.longitude, mapInfo.latitude]));
  };

  const onMoveTop = () => {
    const center = map.getView().getCenter();
    if (!center || !center[0] || !center[1]) {
      return undefined;
    }
    const latLon = toLonLat(center);
    const currentCoordinate: Coordinate = {
      lat: latLon[1],
      lng: latLon[0]
    };
    const zoom = map.getView().getZoom() || 4;
    let optionalZoom = zoom;

    if (optionalZoom > 1 && optionalZoom < 2) {
      optionalZoom = zoom / 10;
    }
    if (optionalZoom > 2 && optionalZoom < 3) {
      optionalZoom = zoom / 20;
    }
    if (optionalZoom > 3 && optionalZoom < 5) {
      optionalZoom = zoom / 30;
    }
    if (optionalZoom > 5 && optionalZoom < 7) {
      optionalZoom = zoom / 100;
    }
    if (optionalZoom > 7 && optionalZoom < 9) {
      optionalZoom = zoom / 1024;
    }
    if (optionalZoom > 9 && optionalZoom < 11) {
      optionalZoom = zoom / 2048;
    }
    if (optionalZoom > 11 && optionalZoom < 14) {
      optionalZoom = zoom / 17096;
    }
    if (optionalZoom > 14 && optionalZoom < 17) {
      optionalZoom = zoom / 110096;
    }
    if (optionalZoom > 17) {
      optionalZoom = zoom / 1405096;
    }

    const mapInfo = {
      longitude: currentCoordinate.lng,
      latitude: currentCoordinate.lat + optionalZoom,
      zoomLevel: zoom
    };

    map.getView().setCenter(fromLonLat([mapInfo.longitude, mapInfo.latitude]));
  };

  const onMoveDown = () => {
    const center = map.getView().getCenter();
    if (!center || !center[0] || !center[1]) {
      return undefined;
    }
    const latLon = toLonLat(center);
    const currentCoordinate: Coordinate = {
      lat: latLon[1],
      lng: latLon[0]
    };
    const zoom = map.getView().getZoom() || 4;
    let optionalZoom = zoom;

    if (optionalZoom > 1 && optionalZoom < 2) {
      optionalZoom = zoom / 10;
    }
    if (optionalZoom > 2 && optionalZoom < 3) {
      optionalZoom = zoom / 20;
    }
    if (optionalZoom > 3 && optionalZoom < 5) {
      optionalZoom = zoom / 30;
    }
    if (optionalZoom > 5 && optionalZoom < 7) {
      optionalZoom = zoom / 100;
    }
    if (optionalZoom > 7 && optionalZoom < 9) {
      optionalZoom = zoom / 1024;
    }
    if (optionalZoom > 9 && optionalZoom < 11) {
      optionalZoom = zoom / 2048;
    }
    if (optionalZoom > 11 && optionalZoom < 14) {
      optionalZoom = zoom / 17096;
    }
    if (optionalZoom > 14 && optionalZoom < 17) {
      optionalZoom = zoom / 110096;
    }
    if (optionalZoom > 17) {
      optionalZoom = zoom / 1405096;
    }

    const mapInfo = {
      longitude: currentCoordinate.lng,
      latitude: currentCoordinate.lat - optionalZoom,
      zoomLevel: zoom
    };

    map.getView().setCenter(fromLonLat([mapInfo.longitude, mapInfo.latitude]));
  };

  return (
    <>
      <div className="map-pad shadow">
        <button onClick={onMoveLeft}>
          <span className="material-symbols-outlined">keyboard_arrow_up</span>
        </button>
        <button onClick={onMoveTop}>
          <span className="material-symbols-outlined">keyboard_arrow_up</span>
        </button>
        <button onClick={onMoveDown}>
          <span className="material-symbols-outlined">keyboard_arrow_up</span>
        </button>
        <button onClick={onMoveRight}>
          <span className="material-symbols-outlined">keyboard_arrow_up</span>
        </button>
      </div>
      <div className={'map-view-option'}>
        <span>Street View</span>
        <Switch
          size={'small'}
          onLabel={'Street View'}
          offLabel={'Satelite View'}
          onChange={(e) => onViewChangeMapType(e)}
          checked={isOpenStreetMap}
        />
      </div>
    </>
  );
}
