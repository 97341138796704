import { KendoGridResult } from '../types/KendoGridResult';
import {
  EventReportHeaderResponse,
  EventReportResponse
} from '../types/responses/event-report-response';
import { DownloadAsync, GetAllAsync, GetAsync, PostAsync } from './api';
import {
  DataResult,
  State,
  toDataSourceRequestString
} from '@progress/kendo-data-query';

const ReportsEndpoint = `events/reports`;

export const GetEventReportsAsync = async (dataState: State) =>
  GetAllAsync<EventReportResponse>(
    `${ReportsEndpoint}?${toDataSourceRequestString(dataState)}`
  );

export const GetEventReportHeaderAsync = async (eventId: string) =>
  GetAsync<EventReportHeaderResponse>(`${ReportsEndpoint}/${eventId}/headers`);

export const GetEventReportDetailsAsync = async (eventId: string) =>
  GetAsync<KendoGridResult<EventReportResponse>>(
    `${ReportsEndpoint}/${eventId}/details`
  );

export const GetEventReportGroupAsync = async (
  eventId: string,
  param: string
) =>
  GetAsync<KendoGridResult<DataResult>>(
    `${ReportsEndpoint}/group/${eventId}?${param}`
  );

export const GetEventReportTeamAsync = async (eventId: string, param: string) =>
  GetAsync<KendoGridResult<DataResult>>(
    `${ReportsEndpoint}/team/${eventId}?${param}`
  );

export const GetEventReportPlayerAsync = async (
  eventId: string,
  param: string
) =>
  GetAsync<KendoGridResult<DataResult>>(
    `${ReportsEndpoint}/player/${eventId}?${param}`
  );

export const GetEventReportTaskAsync = async (eventId: string, param: string) =>
  GetAsync<KendoGridResult<DataResult>>(
    `${ReportsEndpoint}/task/${eventId}?${param}`
  );

export const GetEventReportAssessmentAsync = async (
  eventId: string,
  param: string
) =>
  GetAsync<KendoGridResult<DataResult>>(
    `${ReportsEndpoint}/assessment/${eventId}?${param}`
  );

export const GetEventReportFeedbackAsync = async (
  eventId: string,
  param: string
) =>
  GetAsync<KendoGridResult<DataResult>>(
    `${ReportsEndpoint}/feedback/${eventId}?${param}`
  );

export const GetEventReportResourceAsync = async (
  eventId: string,
  param: string
) =>
  GetAsync<KendoGridResult<DataResult>>(
    `${ReportsEndpoint}/resource/${eventId}?${param}`
  );

export const DownloadReportPlayerAsync = async (
  eventId: number,
  fileName: string,
  param: any
) => {
  try {
    return DownloadAsync(
      `${ReportsEndpoint}/player/download/${eventId}`,
      fileName,
      param
    );
  } catch (error) {
    throw new Error();
  }
};

export const DownloadReportTaskAsync = async (
  eventId: number,
  fileName: string,
  param: any
) => {
  try {
    return DownloadAsync(
      `${ReportsEndpoint}/task/download/${eventId}`,
      fileName,
      param
    );
  } catch (error) {
    throw new Error();
  }
};

export const DownloadReportResourceAsync = async (
  eventId: number,
  fileName: string,
  param: any
) => {
  try {
    return DownloadAsync(
      `${ReportsEndpoint}/resource/download/${eventId}`,
      fileName,
      param
    );
  } catch (error) {
    throw new Error();
  }
};

export const DownloadReportAssessmentAsync = async (
  eventId: number,
  fileName: string,
  param: any
) => {
  try {
    return DownloadAsync(
      `${ReportsEndpoint}/assessment/download/${eventId}`,
      "Assessment-Report.zip",
      param
    );
  } catch (error) {
    throw new Error();
  }
};

export const DownloadReportFeedbackAsync = async (
  eventId: number,
  fileName: string,
  param: any
) => {
  try {
    return DownloadAsync(
      `${ReportsEndpoint}/feedback/download/${eventId}`,
      fileName,
      param
    );
  } catch (error) {
    throw new Error();
  }
};

export const DownloadReportTeamAsync = async (
  eventId: number,
  fileName: string,
  param: any
) => {
  try {
    return DownloadAsync(
      `${ReportsEndpoint}/team/download/${eventId}`,
      fileName,
      param
    );
  } catch (error) {
    throw new Error();
  }
};

export const DownloadReportGroupAsync = async (
  eventId: number,
  fileName: string,
  param: any
) => {
  try {
    return DownloadAsync(
      `${ReportsEndpoint}/group/download/${eventId}`,
      fileName,
      param
    );
  } catch (error) {
    throw new Error();
  }
};
