import { ComponentType, useContext, useState } from 'react';
import {
  Window,
  WindowActionsBar,
  WindowProps
} from '@progress/kendo-react-dialogs';
import { FluidForm } from '../../../components/forms';
import { AreaEntity } from '../../../types/game-document/';
import {
  CheckboxChangeEvent,
  InputChangeEvent,
  TextAreaChangeEvent
} from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { EditorMode } from '../../../types/editor-mode';
import { EntityEditor } from '../../../types/game-document/entity-editor';
import { AreaEditor, AreaEditorFeature } from '../areas';
import { GameDocumentContext } from '../../../contexts/game-document';
import { Algorithm } from '../../../types/algorithm';
import cloneDeep from 'lodash.clonedeep';
import { AreaEvent } from '../../../types/game-document/entities/area';
import { AreaAlgorithmEvent } from '../../../types/game-document/area';
import RequiredFields from '../../../types/required-fields';

interface AreaEditorWindowProps extends WindowProps {
  editorEntity: EntityEditor<AreaEntity>;
  toggleDialog: Function;
  onSubmit: (editorEntity: EntityEditor<AreaEntity>) => void;
  editorMode: EditorMode;
}

export const AreaEditorWindow: ComponentType<AreaEditorWindowProps> = ({
  editorEntity,
  toggleDialog,
  onSubmit,
  editorMode,
  ...props
}: AreaEditorWindowProps) => {
  const [state] = useContext(GameDocumentContext);
  const [entity, setEntity] = useState<AreaEntity>(
    cloneDeep(editorEntity.entity)
  );

  const [requiredFields, setRequiredFields] = useState<
    RequiredFields<AreaEntity>[]
  >([{ name: 'name', errorMessage: '' }]);

  const handleInputChange = (
    event: InputChangeEvent | TextAreaChangeEvent | CheckboxChangeEvent
  ) => {
    const { name, value } = event.target;

    setEntity({ ...entity, [name!]: value });
  };

  const isInputValid = (): boolean => {
    const inputValidation = requiredFields.map(
      (input: RequiredFields<AreaEntity>) => {
        const entityValue = entity[input.name as keyof AreaEntity];
        const itemIndex = state.gameDocument?.assets?.areas?.findIndex(
          (item) => item.name === entityValue
        );

        input.errorMessage = '';
        if (input.name === 'name') {
          // Check validation for input name
          if (entityValue === '') {
            input.errorMessage = 'This field is required';
          }
          if (
            itemIndex! > -1 &&
            state.gameDocument?.assets?.areas![itemIndex!]?.id !== entity.id
          ) {
            input.errorMessage = 'Please enter unique name';
          }
        }

        return input;
      }
    );
    setRequiredFields(inputValidation);
    return (
      inputValidation.findIndex((input) => input.errorMessage !== '') === -1
    );
  };

  const handleAlgorithmEventChange = (
    algorithm: Algorithm,
    type: AreaAlgorithmEvent
  ) => {
    const copyEntity = cloneDeep({ ...entity });
    if (copyEntity.events === undefined) {
      copyEntity.events = {
        onEntry: {} as Algorithm,
        onExit: {} as Algorithm
      };
    }
    copyEntity.events[type as keyof AreaEvent] = algorithm;
    setEntity((prev) => ({ ...prev, ...copyEntity }));
  };

  return (
    <Window
      modal={true}
      minimizeButton={undefined}
      className={'shadow'}
      initialWidth={editorEntity.isNew ? 500 : 700}
      initialHeight={editorEntity.isNew ? 450 : 650}
      title={editorEntity.isNew ? 'New area' : editorEntity.entity.name}
      {...props}>
      <FluidForm>
        {editorEntity.isNew ? (
          <AreaEditor
            editorMode={editorMode}
            entity={entity}
            handleInputChange={handleInputChange}
            requiredFields={requiredFields}
          />
        ) : (
          <AreaEditorFeature
            entity={entity}
            handleInputChange={handleInputChange}
            handleAlgorithmEventChange={handleAlgorithmEventChange}
            requiredFields={requiredFields}
          />
        )}
      </FluidForm>
      <WindowActionsBar>
        <Button themeColor={'secondary'} onClick={() => toggleDialog()}>
          Cancel
        </Button>
        <Button
          themeColor={'primary'}
          onClick={() => {
            if (isInputValid()) {
              onSubmit({ isNew: editorEntity.isNew, entity });
            }
          }}>
          Save
        </Button>
      </WindowActionsBar>
    </Window>
  );
};
