import { EditorMountEvent, ProseMirror } from '@progress/kendo-react-editor';
import {
  insertImagePlugin,
  onImageInsert
} from '../editor/insert-image-plugin';

export const createView = ({ dom, viewProps }: EditorMountEvent) => {
  const state = ProseMirror.EditorState.create({
    doc: viewProps.state.doc,
    plugins: [
      ProseMirror.keymap({ 'Mod-u': () => true }),
      insertImagePlugin(onImageInsert),
      ...viewProps.state.plugins
    ]
  });

  return new ProseMirror.EditorView(
    { mount: dom },
    {
      ...viewProps,
      state
    }
  );
};
