import { useEffect } from 'react';
import { ctGameDefault, ctGameTitle } from '../../constants/text';
import { PrivatePage } from '../../components/private-page';
import ActiveEventsGrid from '../../features/active-events-grid';

export default function ActiveEvents() {
  useEffect(() => {
    document.title = ctGameTitle + ' - Active events';

    return () => {
      // return default value when component unmounts
      document.title = ctGameDefault;
    };
  }, []);

  return (
    <PrivatePage
      breadcrumb={[
        { label: 'Dashboard', href: 'dashboard' },
        { label: 'Administration', href: '../administration' },

        {
          label: 'Active events',
          href: 'administration/active-events'
        }
      ]}
      pageTitle={'Administration'}>
      <ActiveEventsGrid></ActiveEventsGrid>
    </PrivatePage>
  );
}
