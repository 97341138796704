import { GameDocumentContext } from '../../../contexts/game-document';
import React, { useContext, useEffect, useState } from 'react';
import Toolbar from '../toolbar';
import {
  AddAssessmentAsync,
  CopyAssessmentAsync,
  DeleteAssessmentAsync,
  UpdateAssessmentAsync,
  UpdateGameDocState
} from '../../../utils/game-document';
import { Button } from '@progress/kendo-react-buttons';
import { process, State } from '@progress/kendo-data-query';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridNoRecords
} from '@progress/kendo-react-grid';
import { LinkCell } from '../../../components/grid';
import { AssessmentEntity } from '../../../types/game-document/';
import { AssessmentEditorWindow } from '../../../features/game-document/assessments';
import { EntityEditor } from '../../../types/game-document/entity-editor';
import EditDeleteCloneCell from '../../../components/grid/edit-delete-clone-cell';
import {
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../../../components/grid/grid-tool-bar';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { NoRecords } from '../../../components/grid/no-records';
import { DefaultGridSettings } from '../../../constants/grid-settings';

const initialDataState: State = {
  sort: [{ field: 'name', dir: 'asc' }],
  ...DefaultGridSettings.initialDataState
};

const Assessments = () => {
  const [state, setState] = useContext(GameDocumentContext);
  const [dataState, setDataState] = React.useState<State>(initialDataState);

  useEffect(() => {
    // Set the page assessment.
    document.title = `Assessments - ${state.gameDocument?.name}`;
  }, [state]);

  const LinkedNameCell = (props: GridCellProps) => (
    <LinkCell to={props.dataItem['id'].toString()} {...props}></LinkCell>
  );

  const DeleteCell = (props: GridCellProps) => (
    <EditDeleteCloneCell
      onEditClick={() => onEditEntity(props.dataItem['id'])}
      onCloneClick={() => onCloneEntity(props.dataItem['id'])}
      onDeleteClick={() => onDeleteEntity(props.dataItem['id'])}
      {...props}></EditDeleteCloneCell>
  );

  const [entityEditorValue, setEntityEditorValue] =
    useState<EntityEditor<AssessmentEntity>>();
  const [entityEditorIsVisible, setEntityEditorIsVisible] =
    useState<boolean>(false);
  const toggleEntityEditor = () => {
    setEntityEditorIsVisible(!entityEditorIsVisible);
  };

  const handleEntityEditorSubmit = (
    editorEntity: EntityEditor<AssessmentEntity>
  ) => {
    if (editorEntity.isNew) {
      AddAssessmentAsync(
        state.gameDocument!,
        editorEntity.entity.name,
        editorEntity.entity.description
      ).then((updatedGameDocument) => {
        setState((state) => UpdateGameDocState(state, updatedGameDocument));
        setEntityEditorIsVisible(false);
      });
    } else {
      UpdateAssessmentAsync(
        state.gameDocument!,
        editorEntity.entity.id,
        editorEntity.entity
      ).then((updatedGameDocument) => {
        setState((state) => UpdateGameDocState(state, updatedGameDocument));
        setEntityEditorIsVisible(false);
      });
    }
  };

  const onAddEntity = () => {
    setEntityEditorValue({
      isNew: true,
      entity: { id: '', name: '', description: '' }
    });
    toggleEntityEditor();
  };

  const onEditEntity = (entityId: string) => {
    setEntityEditorValue({
      isNew: false,
      entity: state.gameDocument?.assets.assessments?.find(
        (i) => i.id == entityId
      )!
    });
    toggleEntityEditor();
  };

  const onCloneEntity = (entityId: string) => {
    CopyAssessmentAsync(state.gameDocument!, entityId).then(
      (updatedGameDocument) => {
        setState((state) => UpdateGameDocState(state, updatedGameDocument));
      }
    );
  };

  const onDeleteEntity = async (entityId: string) => {
    DeleteAssessmentAsync(state.gameDocument!, entityId).then(
      (updatedGameDocument) => {
        setState((state) => UpdateGameDocState(state, updatedGameDocument));
      }
    );
  };

  const gridExportRef = React.useRef<ExcelExport | null>(null);

  return (
    <div className={'overflow-scroll'}>
      <Toolbar title={'Assessments'}>
        <Button onClick={onAddEntity} themeColor={'primary'}>
          Add assessment
        </Button>
        {entityEditorIsVisible && (
          <AssessmentEditorWindow
            toggleDialog={toggleEntityEditor}
            onSubmit={handleEntityEditorSubmit}
            onClose={toggleEntityEditor}
            editorEntity={entityEditorValue!}
            editorMode={'basic'}
            editorResource={[]}></AssessmentEditorWindow>
        )}
      </Toolbar>
      <div className={'pt-2'}>
        <GridToolBar
          searchPlaceholder={'Search assessments'}
          columnsToSearch={['name', 'description']}
          showCardMode={false}
          exportRef={gridExportRef}
          {...dataState}
          onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
            setDataState(e.dataState);
          }}></GridToolBar>
        <ExcelExport
          data={state.gameDocument?.assets.assessments ?? []}
          ref={gridExportRef}
          fileName={`${state.gameDocument?.name} inventory.xlsx`}>
          <Grid
            pageable={DefaultGridSettings.pagerSettings}
            sortable={true}
            className={'cg-grid3'}
            data={process(
              state.gameDocument?.assets.assessments ?? [],
              dataState
            )}
            {...dataState}
            onDataStateChange={(e: GridDataStateChangeEvent) => {
              setDataState(e.dataState);
            }}>
            <Column field={'name'} title={'Name'} cell={LinkedNameCell} />
            <Column field={'description'} title={'Description'} />
            <Column cell={DeleteCell} />
            <GridNoRecords>
              <NoRecords />
            </GridNoRecords>
          </Grid>
        </ExcelExport>
      </div>
    </div>
  );
};

export default Assessments;
