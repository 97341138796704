import { ComponentType } from 'react';
import { Container, Row } from 'react-bootstrap';

export const FluidForm: ComponentType<any> = (props) => {
  return (
    <Container fluid={true}>
      <Row className={'gy-2'}>{props.children}</Row>
    </Container>
  );
};

export default FluidForm;
