import React, { useEffect, useState } from 'react';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridNoRecords
} from '@progress/kendo-react-grid';
import { DataResult, State } from '@progress/kendo-data-query';
import {
  CardViewDataStateChangeEvent,
  OrganisationsCardView
} from './organisations-cardview';
import { useNavigate } from 'react-router-dom';
import {
  DeleteOrganisationAsync,
  GetGridOrganisationsAsync,
  GetOrganisationsForReportAsync,
  GetReportOrganisationsAsAdminAsync,
  GetUsersOnOrganisationCheckAsync
} from '../services/organisations';
import {
  ExcelExport,
  ExcelExportColumn
} from '@progress/kendo-react-excel-export';
import { ColumnMenu } from '../components/columnMenu';
import Toolbar from '../components/page-toolbar';
import {
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../components/grid/grid-tool-bar';
import { Button } from '@progress/kendo-react-buttons';
import { NoRecords } from '../components/grid/no-records';
import { DefaultGridSettings } from '../constants/grid-settings';
import { toastStore } from '../stores/toast-store';
import { ActionCell } from '../components/grid/action-cell';
import { ActionsCell } from '../components/grid/actions-cell';
import { YesNoDialogResult } from '../types/dialog-result';
import YesNoDialog from '../components/dialogs/yes-no-dialog';
import { appStore } from '../stores/app-store';

const initialDataState: State = {
  sort: [{ field: 'name', dir: 'asc' }],
  ...DefaultGridSettings.initialDataState
};

const OrganisationsGrid = () => {
  const navigate = useNavigate();

  const [dataState, setDataState] = useState<State>(initialDataState);
  const [gridMode, setGridMode] = useState<string>('grid');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>(0);
  const [organisations, setOrganisations] = useState<DataResult>(() => ({
    data: [],
    total: 0
  }));

  const [cardImage, setCardImage] = useState<DataResult>(() => ({
    data: [],
    total: 0
  }));
  const fetchOrganisations = async () => {
    GetGridOrganisationsAsync(dataState)
      .then((response) => {
        setOrganisations(response);

        setCardImage(response);
      })
      .catch();
  };

  const onDelete = (id: number) => {
    handleDeleteOrganisation(id);
  };

  const handleDeleteOrganisation = (id: number) => {
    setSelectedId(id);
    setShowDeleteConfirm(true);
  };

  const onConfirmDelete = async (result: YesNoDialogResult) => {
    if (result === 'yes') {
      try {
        appStore.showLoading();

        const isAvailable = await GetUsersOnOrganisationCheckAsync(selectedId);
        if (isAvailable) {
          toastStore.show(
            'Organisation',
            <div>Failed to delete organisation. Delete all members first.</div>,
            'error'
          );
        } else {
          await DeleteOrganisationAsync(selectedId);
          fetchOrganisations();

          toastStore.show(
            'Organisation',
            <div>Organisation deleted.</div>,
            'success'
          );
        }
      } catch {
        toastStore.show(
          'Organisation',
          <div>Failed to delete oranisation.</div>,
          'error'
        );
      } finally {
        appStore.hideLoading();
      }
    }
    setShowDeleteConfirm(false);
  };

  const closeDeleteDialog = () => {
    setShowDeleteConfirm(false);
  };
  //edit organisation

  const editEventById = (id: number) => {
    fetchOrganisations();
  };

  const onEditEvent = (id: number) => {
    navigate(`../organisation/${id.toString()}`);

    editEventById(id);
  };

  useEffect(() => {
    fetchOrganisations();
  }, [dataState]);

  const onStatusColoring = (props: GridCellProps) => (
    <StatusColoring {...props} />
  );

  const onActionRow = (props: GridCellProps) => {
    return (
      <>
        <ActionsCell {...props}>
          <ActionCell
            title={'View organisation'}
            icon={'visibility'}
            themeColor={'primary'}
            className={'fs-2'}
            onClick={() => navigateToOrganisationDetail(props.dataItem.id)}
          />
          <ActionCell
            title={'Edit organisation'}
            icon={'edit'}
            themeColor={'primary'}
            className={'fs-2'}
            onClick={() => onEditEvent(props.dataItem.id)}
          />
          <ActionCell
            title={'Delete organisation'}
            icon={'delete'}
            themeColor={'error'}
            className={'fs-2'}
            onClick={() => onDelete(props.dataItem.id)}
          />
        </ActionsCell>
      </>
    );
  };

  const StatusColoring = (props: GridCellProps) => {
    if (props.dataItem.status === 'Active') {
      return (
        <td>
          <span className={'text-success'}>{props.dataItem.status}</span>
        </td>
      );
    } else {
      return (
        <td>
          <span className={'text-muted'}>{props.dataItem.status}</span>
        </td>
      );
    }
  };

  const onAddUser = () => {
    navigate('new');
  };

  const onColumnTextClick = (props: GridCellProps) => (
    <EditCommandCell {...props} />
  );

  const EditCommandCell = (props: GridCellProps) => {
    return (
      <td>
        <span
          className={'link-primary cursor-pointer'}
          onClick={() => handleNameClick(props.dataItem.id, 'grid')}>
          {props.dataItem.name}
        </span>
      </td>
    );
  };

  const WebsiteColumn = (props: GridCellProps) => (
    <td>
      <a
        href={`https://${props.dataItem.website}`}
        target={'_blank'}
        className={'link-primary cursor-pointer'}>
        {props.dataItem.website}
      </a>
    </td>
  );

  const handleNameClick = (id: number, viewSource?: string) => {
    navigate('/administration/organisation/' + id + '?view=' + viewSource);
  };

  const navigateToOrganisationDetail = (id: number) => {
    navigate('/administration/organisation-details/' + id);
  };

  const onExportExcel = async () => {
    appStore.showLoading();
    try {
      await GetReportOrganisationsAsAdminAsync().then((response) => {
        if (gridExportRef.current !== null) {
          gridExportRef.current.save(response);
        }
      });
    } catch (error) {
      console.error(error);
    } finally {
      appStore.hideLoading();
    }
  };

  const gridExportRef = React.useRef<ExcelExport | null>(null);

  return (
    <>
      <Toolbar title={'Organisations'}>
        <Button onClick={onAddUser} themeColor={'primary'}>
          Add new
        </Button>
      </Toolbar>
      <div className={'pt-2'}>
        <GridToolBar
          searchPlaceholder={'Search organisations'}
          columnsToSearch={['name']}
          exportRef={gridExportRef}
          useCustomExportExcel={true}
          handleCustomExportToExcel={onExportExcel}
          onGridModeChange={(e) => {
            setGridMode(e.gridMode);
          }}
          {...dataState}
          onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
            setDataState(e.dataState);
          }}></GridToolBar>
        <ExcelExport ref={gridExportRef} fileName={`organisations.xlsx`}>
          <ExcelExportColumn field="name" title="Organisation" width={270} />
          <ExcelExportColumn
            field="territory"
            title="Territory(S)"
            width={270}
          />
          <ExcelExportColumn field="email" title="Email" width={200} />
          <ExcelExportColumn field="phoneNumber" title="Phone" width={200} />
          <ExcelExportColumn field="languages" title="Language" width={250} />
          <ExcelExportColumn field={'website'} title={'Website'} width={250} />
          <ExcelExportColumn field={'status'} title={'Status'} width={250} />
        </ExcelExport>
        {gridMode === 'grid' && (
          <Grid
            id={'grid-organisations'}
            pageable={DefaultGridSettings.pagerSettings}
            sortable={true}
            data={organisations}
            {...dataState}
            onDataStateChange={(e: GridDataStateChangeEvent) => {
              setDataState(e.dataState);
            }}>
            <Column
              field={'name'}
              title={'Organisation'}
              cell={onColumnTextClick}
              columnMenu={ColumnMenu}
            />
            <Column field={'territory'} title={'Territory(S)'} />
            <Column field={'email'} title={'Email'} />
            <Column field={'phoneNumber'} title={'Phone'} />
            <Column field={'languages'} title={'Language'} />
            <Column field={'website'} title={'Website'} cell={WebsiteColumn} />
            <Column
              field={'status'}
              title={'Status'}
              columnMenu={ColumnMenu}
              cell={onStatusColoring}
            />
            <Column
              field={'actions'}
              title={'Actions'}
              cell={onActionRow}
              width={210}
            />
            <GridNoRecords>
              <NoRecords />
            </GridNoRecords>
          </Grid>
        )}
        {gridMode === 'card' && (
          <OrganisationsCardView
            data={organisations.data}
            onNameClick={handleNameClick}
            total={organisations.total}
            {...dataState}
            onDataStateChange={(e: CardViewDataStateChangeEvent) => {
              setDataState(e.dataState);
            }}
          />
        )}

        {showDeleteConfirm && (
          <YesNoDialog
            title={'Remove Organisation'}
            onConfirm={onConfirmDelete}
            onClose={closeDeleteDialog}>
            Are you sure you want to delete this organisation?
          </YesNoDialog>
        )}
      </div>
    </>
  );
};
export default OrganisationsGrid;
