import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import debounce from 'lodash/debounce';
import { Coordinate } from '../../../types/place';
import MapCanvas from '../../../components/map/map-canvas';
import { MapContext } from '../../../components/map/map-context';
import MapEditor from '../../../components/map/map-editor';
import MapIllustrationCanvas from '../../../components/map/map-illustration-canvas';
import { GameDocumentContext } from '../../../contexts/game-document';
import { ZoneEditorWindow } from '../../../features/game-document/zones';
import { isEmpty } from 'lodash';
import {
  AreaEntity,
  EntityEditor,
  MapEntity,
  ResourceEntity,
  TaskEntity,
  ZoneEntity
} from '../../../types/game-document/';
import {
  AddAreaEntityAsync,
  AddMapEntityAsync,
  AddResourceAsync,
  AddTaskEntityAsync,
  AddZoneEntityAsync,
  DeleteResourceAsync,
  GetAreasByZoneId,
  GetAreasWorldMap,
  GetMapByIdAsync,
  GetResourceValue,
  GetTaskById,
  GetTasksByIds,
  GetZonesByIds,
  ResourceIdExistsAsync,
  UpdateAreaAsync,
  UpdateGameDocState,
  UpdateMapAsync,
  UpdateTaskAsync,
  UpdateZone
} from '../../../utils/game-document';
import { toLonLat } from 'ol/proj';
import { TaskEditorWindow } from '../../../features/game-document/tasks';
import { uuid } from '../../../types/common-helper';
import YesNoDialog from '../../../components/dialogs/yes-no-dialog';
import { YesNoDialogResult } from '../../../types/dialog-result';
import { GenerateDefaultWorldMap } from '../../../utils/game-document/factory';
import { TaskRoute } from '../../../types/game-document/entities/routes';
import { MapImage } from '../../../types/game-document';
import { UpdateMapImageAsync } from '../../../utils/game-document/assets/map-image';
import { ZoneAssetId } from '../../../types/game-document/entities/zone';
import { useParams } from 'react-router-dom';
import { useGameDocumentResources } from '../../../hooks/use-game-document-resources';
import { useGameTask } from '../../../hooks/use-game-task';
import { AreaEditorWindow } from '../../../features/game-document/areas';
import { v4 as uuidv4 } from 'uuid';
import { AddActionToTask } from '../../../utils/game-document/settings';

export type MapsOptions =
  | 'GPS Map'
  | 'Illustration Map'
  | 'GPS + Illustration Overlay';
export type MapAction = 'Zone' | 'Task' | 'Area' | 'Annotation' | 'Images' | '';

const WorldMap = () => {
  const [state, setState] = useContext(GameDocumentContext);
  const gameDocumentFiles = useGameDocumentResources();
  const map = useContext(MapContext);
  const [zones, setZones] = useState<ZoneEntity[]>([]);
  const [tasks, setTasks] = useState<TaskEntity[]>([]);
  const [routes, setRoutes] = useState<TaskRoute[]>([]);
  const [selectAllAssets, setSelectAllAssets] = useState<string>('');
  const [entityZoneEditorIsVisible, setEntityZoneEditorIsVisible] =
    useState<boolean>(false);
  const [entityAreaEditorIsVisible, setEntityAreaEditorIsVisible] =
    useState<boolean>(false);
  const [entityTaskEditorIsVisible, setEntityTaskEditorIsVisible] =
    useState<boolean>(false);
  const [entityAreaEditorValue, setEntityAreaEditorValue] =
    useState<EntityEditor<AreaEntity>>();
  const [coordinate, setCoordinate] = useState<Coordinate>();
  const [areas, setAreas] = useState<AreaEntity[]>([]);
  const [tempArea, setTempArea] = useState<AreaEntity>({
    id: '',
    name: '',
    description: ''
  });
  const [tempZone, setTempZone] = useState<ZoneEntity>({
    id: '',
    name: '',
    description: ''
  });
  const [tempTask, setTempTask] = useState<TaskEntity>({
    id: '',
    name: '',
    description: ''
  });
  const [selectedPanel, setSelectedPanel] = useState<MapAction>('');
  const [mapInfo, setMapInfo] = useState<MapEntity>();
  const [isShowChangeMapConfirmation, setIsShowChangeMapconfirmation] =
    useState<boolean>(false);
  const { zoneId } = useParams();

  const toggleZoneEntityEditor = useCallback(() => {
    setEntityZoneEditorIsVisible(!entityZoneEditorIsVisible);
  }, [entityZoneEditorIsVisible]);

  const toggleAreaEntityEditor = () => {
    setEntityAreaEditorIsVisible(!entityAreaEditorIsVisible);
  };

  const toggleTaskEntityEditor = useCallback(() => {
    setEntityTaskEditorIsVisible(!entityTaskEditorIsVisible);
  }, [entityTaskEditorIsVisible]);

  const setToBrowserLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        setCoordinate({
          lat: pos.coords.latitude,
          lng: pos.coords.longitude
        });
      });
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  }, []);

  const getWorldMapAsset = useCallback(async () => {
    return await GetMapByIdAsync(
      state?.gameDocument!,
      state?.gameDocument?.rules?.worldMap?.mapAssId!
    );
  }, [state?.gameDocument]);

  const onSelectedPanel = useCallback((MapAction: MapAction) => {
    setSelectedPanel(MapAction);
  }, []);

  const updateWorldMapAsset = useCallback(
    async (map: MapEntity) => {
      UpdateMapAsync(state?.gameDocument!, map.id, map).then((response) => {
        setState((prev) => UpdateGameDocState(prev, response));
      });
    },
    [setState, state?.gameDocument]
  );

  //Zones
  const populateWorldMapZones = useCallback(async () => {
    if (state.gameDocument) {
      const zoneIds = state.gameDocument?.rules?.worldMap?.zones?.map(
        (zone) => zone.zoneAssId
      );

      if (zoneIds) {
        const zones = await GetZonesByIds(state.gameDocument, zoneIds);
        return zones;
      }
    }
  }, [state.gameDocument]);

  const calculateCenter = (geometry: any) => {
    let centerZone: any, coordinates;

    let x = 0;
    let y = 0;
    let i = 0;
    coordinates = geometry.coordinates[0];
    coordinates.forEach(function (coordinate: any) {
      x += coordinate[0];
      y += coordinate[1];
      i++;
    });
    centerZone = [x / i, y / i];
    return centerZone;
  };

  const handleZoneEntityEditorSubmit = useCallback(
    async (
      editorEntity: EntityEditor<ZoneEntity>,
      resourceEntity: EntityEditor<ResourceEntity>[]
    ) => {
      editorEntity.entity.boundary = tempZone.boundary;
      editorEntity.entity.id = uuid();
      editorEntity.entity.isVisible = true;
      if (state.gameDocument) {
        //add zone into Map
        const centerZone = calculateCenter(tempZone.boundary.geometry);
        const type: string =
          state.gameDocument.assets && state.gameDocument.assets.maps
            ? state.gameDocument.assets.maps[0].type
            : 'openStreetMap';
        const zoomLevel: number = 17;

        if (centerZone !== undefined) {
          let zoneMap: MapEntity = {
            id: uuid(),
            name: editorEntity.entity.name,
            description: editorEntity.entity.description,
            type,
            latitude: type === 'illustration' ? undefined : centerZone[1],
            longitude: type === 'illustration' ? undefined : centerZone[0],
            zoomLevel: type === 'illustration' ? undefined : zoomLevel,
            url: `https://www.openstreetmap.org/#map=${zoomLevel}/${centerZone[1]}/${centerZone[0]}`
          };

          let resZone = AddMapEntityAsync(state.gameDocument, zoneMap);

          if (resZone !== undefined) {
            // add new resource
            AddResourceAsync(
              state.gameDocument!,
              `${editorEntity.entity.id}-title`,
              'Zone title',
              'text',
              resourceEntity.find(
                (res) => res.entity.id === editorEntity.entity.titleResId!
              )?.entity.value!,
              editorEntity.entity.titleResId
            ).then((response) => {
              setState((state) => UpdateGameDocState(state, response!));
            });

            let result = await AddZoneEntityAsync(
              state.gameDocument!,
              editorEntity.entity
            );
            if (result !== undefined) {
              result.rules.worldMap.zones.push({
                zoneAssId: editorEntity.entity.id,
                mapAssId: zoneMap.id
              });
              setState((prev) => UpdateGameDocState(prev, result));
              setEntityZoneEditorIsVisible(false);
            }
          }
        }
      }
    },
    [setState, state.gameDocument, tempZone.boundary]
  );

  const onAddZone = useCallback((zone: ZoneEntity) => {
    setTempZone({
      id: '',
      name: '',
      description: '',
      titleResId: '',
      boundary: {
        id: uuid(),
        type: 'Feature',
        geometry: {
          type: zone.boundary?.type ?? 'Polygon',
          coordinates: zone.boundary?.geometry as any
        }
      }
    });

    setEntityZoneEditorIsVisible(true);
  }, []);

  //Tasks
  const populateWorldMapTasks = useCallback(async () => {
    if (state.gameDocument) {
      const taskIds = state.gameDocument?.rules?.worldMap?.tasks?.map(
        (task) => task.taskAssId
      );

      if (taskIds) {
        const tasks = await GetTasksByIds(state.gameDocument!, taskIds);

        if (tasks) {
          return tasks;
        }
      }
    }
  }, [state.gameDocument]);

  //Areas
  const populateAreas = useCallback(async () => {
    if (state.gameDocument) {
      return GetAreasWorldMap(state.gameDocument);
    }
  }, [state.gameDocument]);

  const onAddArea = (area: AreaEntity) => {
    setTempArea({
      id: uuidv4(),
      name: '',
      description: '',
      boundary: {
        id: uuidv4(),
        type: 'Feature',
        geometry: {
          type: area.boundary?.type ?? 'Polygon',
          coordinates: area.boundary?.geometry as any
        }
      }
    });

    setEntityAreaEditorValue({
      isNew: true,
      entity: { id: '', name: '', description: '' }
    });
    toggleAreaEntityEditor();
  };

  const handleAreaEntityEditorSubmit = async (
    editorEntity: EntityEditor<AreaEntity>
  ) => {
    editorEntity.entity.boundary = tempArea.boundary;
    editorEntity.entity.id = uuidv4();
    editorEntity.entity.isVisible = true;

    if (state.gameDocument) {
      await AddAreaEntityAsync(state.gameDocument, editorEntity.entity).then(
        (updatedGameDocument) => {
          setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
          toggleAreaEntityEditor();
        }
      );
    }
  };

  /**
   * Handle when area resize, move or edit
   * @param area
   */
  const handleEditAreaChange = (area: AreaEntity) => {
    UpdateAreaAsync(state.gameDocument!, area.id, area).then(
      (updatedGameDocument) => {
        setState((state) => UpdateGameDocState(state, updatedGameDocument));
      }
    );
  };

  const titleGuid = uuid();
  const imageGuid = uuid();
  const completeImageGuid = uuid();

  const entity: TaskEntity = {
    id: '',
    name: '',
    description: '',
    titleResId: titleGuid,
    imageResId: imageGuid,
    completeImageResId: completeImageGuid,
    hideWhenComplete:
      state.gameDocument && !state.gameDocument.settings.designer
        ? false
        : !Boolean(
            state.gameDocument?.settings.designer?.taskCompletionVisibility
          )
  };

  const titleResource: ResourceEntity = {
    id: titleGuid,
    name: 'Title',
    description: '',
    type: 'text',
    value: ''
  };

  const { imageRes: imageResource, completeImageRes: completeImageResource } =
    gameDocumentFiles.createNewTaskResources(imageGuid, completeImageGuid);

  const handleSelectedExistingTask = (taskId: string) => {
    let task = GetTaskById(state?.gameDocument!, taskId);
    if (task) {
      task!.boundary = tempTask.boundary;
      task.isVisible = true;

      UpdateTaskAsync(state?.gameDocument!, task.id, task).then((result) => {
        result.rules.worldMap.tasks.push({
          taskAssId: task!.id
        });
        setState((prev) => UpdateGameDocState(prev, result));
        setEntityTaskEditorIsVisible(false);
      });
    }
  };

  const handleTaskEntityEditorSubmit = useCallback(
    async (
      editorEntity: EntityEditor<TaskEntity>,
      resourceEntity: EntityEditor<ResourceEntity>[]
    ) => {
      const addTitleResource = await AddResourceAsync(
        state.gameDocument!,
        'Title',
        '',
        'text',
        resourceEntity.find(
          (task) => task.entity.id === editorEntity.entity.titleResId!
        )?.entity.value!,
        titleGuid
      );

      const imageResource = resourceEntity.find(
        (task) => task.entity.id === editorEntity.entity.imageResId!
      )?.entity;

      const addImageResource = await AddResourceAsync(
        state.gameDocument!,
        imageGuid,
        imageResource?.description ?? '',
        'image',
        imageResource?.value ?? '',
        imageGuid,
        imageResource?.size ?? 0
      );

      const completeImageResource = resourceEntity.find(
        (task) => task.entity.id === editorEntity.entity.completeImageResId!
      )?.entity;

      const addCompleteImageResource = await AddResourceAsync(
        state.gameDocument!,
        completeImageGuid,
        completeImageResource?.description ?? '',
        'image',
        completeImageResource?.value ?? '',
        completeImageGuid,
        completeImageResource?.size ?? 0
      );

      Promise.all([
        addTitleResource,
        addImageResource,
        addCompleteImageResource
      ]).then(() => {
        editorEntity.entity.boundary = tempTask.boundary;
        editorEntity.entity.id = uuid();
        editorEntity.entity.isVisible = true;
        editorEntity.entity.imageResId = imageGuid;
        editorEntity.entity.completeImageResId = completeImageGuid;
        editorEntity.entity.titleResId = titleGuid;

        if (state.gameDocument) {
          AddActionToTask(state.gameDocument!, editorEntity.entity);
          AddTaskEntityAsync(state.gameDocument!, editorEntity.entity).then(
            (result) => {
              result.rules.worldMap.tasks.push({
                taskAssId: editorEntity.entity.id
              });
              setState((prev) => UpdateGameDocState(prev, result));
              setEntityTaskEditorIsVisible(false);
            }
          );
        }
      });
    },
    [
      imageGuid,
      titleGuid,
      tempTask.boundary,
      completeImageGuid,
      state.gameDocument,
      setState
    ]
  );

  const onAddTask = useCallback((coordinates: [number, number]) => {
    setTempTask({
      id: '',
      name: '',
      description: '',
      boundary: {
        id: uuid(),
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates
        }
      }
    });

    setEntityTaskEditorIsVisible(true);
  }, []);

  const centerToMyLocation = useCallback(() => {
    if (!isEmpty(state.gameDocument) && !isEmpty(state.gameDocument.assets)) {
      if (!isEmpty(state.gameDocument?.assets?.maps![0])) {
        setCoordinate({
          lat: state.gameDocument?.assets?.maps![0].latitude!,
          lng: state.gameDocument?.assets?.maps![0].longitude!
        });
        if (state.gameDocument?.assets?.maps![0].zoomLevel) {
          map.getView().setZoom(state.gameDocument?.assets?.maps![0].zoomLevel);
        }
      }
    }
  }, [map, state.gameDocument]);

  /**
   * Handle when zone resize, move or edit
   * @param worldZones
   */
  /**
   * Handle when zone resize, move or edit
   * @param worldZones
   */
  const handleEditZoneChange = async (zone: ZoneEntity) => {
    setState((prev) => {
      const resp = UpdateZone(prev.gameDocument!, zone.id, zone);
      return UpdateGameDocState(prev, resp);
    });
  };

  /**
   * Handle World Map changes the coordinate
   * @param event
   */
  const getMapInfo = () => {
    const center = map.getView().getCenter();
    if (!center || !center[0] || !center[1]) {
      return undefined;
    }
    const latLon = toLonLat(center);
    const currentCoordinate: Coordinate = {
      lat: latLon[1],
      lng: latLon[0]
    };
    const zoom = map.getView().getZoom() || 4;
    const mapEntity: MapEntity = {
      titleResId: state.gameDocument?.assets?.maps![0].id,
      id: state.gameDocument?.assets?.maps![0].id || uuid(),
      longitude: currentCoordinate.lng,
      latitude: currentCoordinate.lat,
      name: state.gameDocument?.assets?.maps![0].name || '',
      description: state.gameDocument?.assets?.maps![0].description || '',
      type: state.gameDocument?.assets?.maps![0].type!,
      zoomLevel: zoom,
      url: `https://www.openstreetmap.org/#map=${zoom}/${currentCoordinate.lat}/${currentCoordinate.lng}"`,
      imageResId: state.gameDocument?.assets?.maps![0]?.imageResId ?? '',
      imageOverlay: state.gameDocument?.assets.maps![0]?.imageOverlay
    };
    return mapEntity;
  };

  const onMapMoveEnd = () => {
    const mapEntity = getMapInfo();
    setMapInfo(mapEntity);
  };

  const updateMapInfo = async () => {
    if (mapInfo) {
      UpdateMapAsync(state?.gameDocument!, mapInfo?.id, mapInfo).then(
        (response) => {
          setState((prev) => UpdateGameDocState(prev, response));
        }
      );
    }
  };

  const resetWorldMap = async (
    id: string,
    latitude?: number,
    longitude?: number,
    isMapIncludeIllustration?: boolean
  ) => {
    const worldMap: MapEntity = GenerateDefaultWorldMap(
      id,
      latitude,
      longitude
    );
    isMapIncludeIllustration &&
      (worldMap.imageOverlay = {
        imageResId: ''
      });
    await updateWorldMapAsset(worldMap);
    setMapInfo(worldMap);
  };

  const onChangeMapOptions = async (MapsOptions: MapsOptions) => {
    let mapEntity = await getWorldMapAsset();
    if (mapEntity) {
      if (MapsOptions === 'Illustration Map') {
        mapEntity.type = 'illustration';
        mapEntity.zoomLevel = undefined;
        mapEntity.longitude = undefined;
        mapEntity.latitude = undefined;
        await updateWorldMapAsset(mapEntity);
        setMapInfo(mapEntity);
      } else {
        const isMapExist =
          mapEntity.imageResId &&
          (await ResourceIdExistsAsync(
            state.gameDocument?.resources ?? [],
            mapEntity.imageResId
          ));
        if (isMapExist) {
          setIsShowChangeMapconfirmation(true);
        } else {
          const isMapIncludeIllustration =
            MapsOptions === 'GPS + Illustration Overlay';
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              async (position) => {
                const { latitude, longitude } = position.coords;
                await resetWorldMap(
                  mapEntity!.id,
                  latitude,
                  longitude,
                  isMapIncludeIllustration
                );
              },
              async () =>
                await resetWorldMap(
                  mapEntity!.id,
                  undefined,
                  undefined,
                  isMapIncludeIllustration
                )
            );
          } else {
            await resetWorldMap(
              mapEntity.id,
              undefined,
              undefined,
              isMapIncludeIllustration
            );
          }
        }
      }
    }
  };

  const onEditTask = (task: TaskEntity) => {
    UpdateTaskAsync(state?.gameDocument!, task.id, task).then((response) => {
      setState((prev) => UpdateGameDocState(prev, response));
    });
  };

  const onEditMapImage = (mapImage: MapImage) => {
    UpdateMapImageAsync(state?.gameDocument!, mapImage.id, mapImage).then(
      (response) => {
        setState((prev) => UpdateGameDocState(prev, response));
      }
    );
  };

  const handleConfirmChangeMap = async (result: YesNoDialogResult) => {
    if (result === 'yes') {
      let mapEntity = await getWorldMapAsset();
      if (mapEntity) {
        const updatedGameDocument = await DeleteResourceAsync(
          state.gameDocument!,
          mapEntity.imageResId!
        );
        setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              const { latitude, longitude } = position.coords;
              await resetWorldMap(mapEntity!.id, latitude, longitude);
            },
            async () => await resetWorldMap(mapEntity!.id)
          );
        } else {
          await resetWorldMap(mapEntity.id);
        }
      }
    }
    setIsShowChangeMapconfirmation(false);
  };

  //Routes
  const populateAssetRoutes = useCallback(async () => {
    if (state.gameDocument) {
      const allRoute = state?.gameDocument?.assets?.routes ?? [];
      const mapAssetId = state?.gameDocument?.rules.worldMap.mapAssId;
      return allRoute.filter((item) => item.mapId === mapAssetId);
    }
    return [];
  }, [state?.gameDocument]);

  const debounceFn = useMemo(() => debounce(onMapMoveEnd, 200), [onMapMoveEnd]);

  const getMapImages = () => {
    if (state.gameDocument) {
      if (zoneId) {
        return state?.gameDocument?.assets?.mapImages?.filter(
          (item) =>
            item.mapId ===
              state.gameDocument?.rules.worldMap.zones.find(
                (zone: ZoneAssetId) => zone.zoneAssId === zoneId
              )?.mapAssId || ''
        );
      } else {
        return (
          state?.gameDocument?.assets?.mapImages?.filter(
            (item) =>
              item.mapId === state?.gameDocument?.rules?.worldMap?.mapAssId!
          ) ?? []
        );
      }

      return [];
    }
    return [];
  };

  const getMapLabel = useMemo(() => {
    const mapType = state.gameDocument?.assets?.maps?.[0]?.type;
    const isOpenStreetMap = mapType === 'openStreetMap';
    const isSatelliteMap = mapType === 'satelliteMap';
    if (isOpenStreetMap || isSatelliteMap) {
      return state.gameDocument?.assets?.maps?.[0]?.imageOverlay
        ? 'GPS + Illustration Overlay'
        : 'GPS Map';
    }
    return 'Illustration Map';
  }, [state.gameDocument?.assets.maps]);

  useEffect(() => {
    const populateWorldMap = async () => {
      const areas = await populateAreas();
      const zones = await populateWorldMapZones();
      const tasks = await populateWorldMapTasks();
      const assetRoute = await populateAssetRoutes();

      if (areas) setAreas(areas);
      if (zones) setZones(zones);
      if (tasks) setTasks(tasks);
      if (assetRoute) setRoutes(assetRoute);
    };

    populateWorldMap();
  }, [
    populateAreas,
    populateWorldMapZones,
    populateWorldMapTasks,
    populateAssetRoutes
  ]);

  useEffect(() => {
    updateMapInfo();
  }, [mapInfo]);

  const {
    populateTasks,
    removeTaskOverlay,
    addTaskOverlay,
    updateTaskOverlayPosition
  } = useGameTask({
    selectAllAssets,
    tasks: tasks || [],
    zones: zones || [],
    mapImages: getMapImages() || [],
    mapType: state?.gameDocument?.assets?.maps![0].type || ''
  });

  useEffect(() => {
    populateTasks();
  }, [tasks]);

  useEffect(() => {
    centerToMyLocation();
    const mapEntity = getMapInfo();
    setMapInfo(mapEntity);
  }, []);

  return (
    <>
      <div className={'map-designer'}>
        <div className={'layout__left'}>
          {state.gameDocument &&
            (state.gameDocument.assets?.maps![0].type === 'openStreetMap' ||
              state.gameDocument.assets?.maps![0].type === 'satelliteMap') && (
              <MapCanvas
                mapInfo={mapInfo}
                coordinate={coordinate}
                zones={zones}
                tasks={tasks}
                areas={areas}
                routes={routes}
                resourceImages={state.gameDocument.resources}
                isClearInteractions={true}
                onMoveEnd={debounceFn}
                onEditZones={handleEditZoneChange}
                onAddZone={onAddZone}
                onAddTask={onAddTask}
                onAddArea={onAddArea}
                onEditAreas={handleEditAreaChange}
                onEditTasks={onEditTask}
                onEditMapImages={onEditMapImage}
                selectedPanel={selectedPanel}
                mapImages={getMapImages()}
                selectedObjectType={(selectAllAssets as any) ?? ''}
                onRemoveSelectAllAssets={() => setSelectAllAssets('')}
                addTaskOverlay={addTaskOverlay as any}
                removeTaskOverlay={removeTaskOverlay}
                updateTaskOverlayPosition={updateTaskOverlayPosition as any}
                imageOverlay={state.gameDocument.assets?.maps![0].imageOverlay}
              />
            )}
          {state.gameDocument &&
            state.gameDocument.assets?.maps![0].type === 'illustration' && (
              <MapIllustrationCanvas
                zones={zones}
                tasks={tasks}
                routes={routes}
                onEditZones={handleEditZoneChange}
                onAddZone={onAddZone}
                onAddTask={onAddTask}
                onEditTasks={onEditTask}
                resourceImages={state.gameDocument.resources}
                url={GetResourceValue(
                  state.gameDocument,
                  state.gameDocument.assets?.maps![0].imageResId!
                )}
                selectedPanel={selectedPanel}
                isClearInteractions={true}
                mapImages={getMapImages()}
                onEditMapImages={onEditMapImage}
                selectedObjectType={(selectAllAssets as any) ?? ''}
                onRemoveSelectAllAssets={() => setSelectAllAssets('')}
                addTaskOverlay={addTaskOverlay as any}
                removeTaskOverlay={removeTaskOverlay}
                updateTaskOverlayPosition={updateTaskOverlayPosition as any}
              />
            )}
        </div>

        <div className={'layout__right'}>
          {state.gameDocument && (
            <MapEditor
              onChangeMapOptions={onChangeMapOptions}
              onSelectedPanel={onSelectedPanel}
              mapOptions={getMapLabel}
              showMapAccordion={true}
              showZonesAccordion={true}
              showAreasAccordion={true}
              showTasksAccordion={true}
              onCenterMap={setToBrowserLocation}
              mapInfo={mapInfo}
              zones={zones}
              currentImageResource={
                GetResourceValue(
                  state.gameDocument,
                  state.gameDocument.assets?.maps![0].imageResId!
                ) ?? ''
              }
              onSelectAllAssets={(value) => setSelectAllAssets(value)}
              selectedAllObjectType={selectAllAssets}
              removeTaskOverlay={removeTaskOverlay}
            />
          )}

          {entityZoneEditorIsVisible && (
            <ZoneEditorWindow
              toggleDialog={toggleZoneEntityEditor}
              onSubmit={handleZoneEntityEditorSubmit}
              editorEntity={{
                isNew: true,
                entity: {
                  id: '',
                  name: '',
                  description: '',
                  titleResId: titleGuid
                }
              }}
              editorMode={'basic'}
              editorResource={[
                {
                  isNew: true,
                  entity: titleResource
                }
              ]}
            />
          )}

          {entityAreaEditorIsVisible && (
            <AreaEditorWindow
              toggleDialog={toggleAreaEntityEditor}
              onSubmit={handleAreaEntityEditorSubmit}
              onClose={toggleAreaEntityEditor}
              editorEntity={entityAreaEditorValue!}
              editorMode={'basic'}
            />
          )}

          {entityTaskEditorIsVisible && (
            <TaskEditorWindow
              toggleDialog={toggleTaskEntityEditor}
              onSubmit={handleTaskEntityEditorSubmit}
              onSelectedExistingTask={handleSelectedExistingTask}
              editorEntity={{
                isNew: true,
                entity: entity
              }}
              editorMode={'basic'}
              editorResource={[
                {
                  isNew: true,
                  entity: titleResource
                },
                {
                  isNew: true,
                  entity: imageResource
                },
                {
                  isNew: true,
                  entity: completeImageResource
                }
              ]}
              showExistingTask={true}
            />
          )}

          {isShowChangeMapConfirmation && (
            <YesNoDialog
              onClose={() => setIsShowChangeMapconfirmation(false)}
              onConfirm={handleConfirmChangeMap}
              title={'Confirm change map type'}>
              Are you sure you want to change? All changes will be lost.
            </YesNoDialog>
          )}
        </div>
      </div>
    </>
  );
};

export default WorldMap;
