import {
  Window,
  WindowActionsBar,
  WindowProps
} from '@progress/kendo-react-dialogs';
import { TaskRoute } from '../../../types/game-document/entities/routes';
import { EditorMode } from '../../../types/editor-mode';
import { ComponentType, useContext, useState } from 'react';
import { GameDocumentContext } from '../../../contexts/game-document';
import cloneDeep from 'lodash.clonedeep';
import RequiredFields from '../../../types/required-fields';
import { InputChangeEvent } from '@progress/kendo-react-inputs';
import { FluidForm, StandardInput } from '../../../components/forms';
import { Button } from '@progress/kendo-react-buttons';
import { EntityEditor } from '../../../types/game-document/entity-editor';
import { ComboboxInput } from '../../../components/form-input';
import { TaskEntity } from '../../../types/game-document/entities';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';

interface RouteEditorWindowProps extends WindowProps {
  editorEntity: EntityEditor<TaskRoute>;
  toggleDialog: Function;
  onSubmit: (editorEntity: EntityEditor<TaskRoute>) => void;
  editorMode: EditorMode;
  tasks?: TaskEntity[];
  routes?: TaskRoute[];
}

export const RouteEditorWindow: ComponentType<RouteEditorWindowProps> = ({
  editorEntity,
  toggleDialog,
  onSubmit,
  editorMode,
  ...props
}: RouteEditorWindowProps) => {
  const [state] = useContext(GameDocumentContext);
  const [entity, setEntity] = useState<TaskRoute>(
    cloneDeep(editorEntity.entity)
  );

  const [requiredFields, setRequiredFields] = useState<
    RequiredFields<TaskRoute>[]
  >([
    { name: 'name', errorMessage: '' },
    { name: 'toTaskId', errorMessage: '' }
  ]);

  const handleInputChange = (event: InputChangeEvent) => {
    const { name, value } = event.target;

    setEntity({ ...entity, [name!]: value });
  };

  const handleComboboxChange = (event: ComboBoxChangeEvent) => {
    const { name, value } = event.target;

    setEntity({ ...entity, [name!]: (value! as unknown as TaskEntity).id });
  };

  const isInputValid = (): boolean => {
    const inputValidation = requiredFields.map(
      (input: RequiredFields<TaskRoute>) => {
        const entityValue = entity[input.name as keyof TaskRoute];
        const itemIndex = props?.routes?.findIndex(
          (item) => item.name === entityValue
        );

        input.errorMessage = '';

        if (input.name === 'name') {
          // Check validation for input name
          if (!entityValue || entityValue === '') {
            input.errorMessage = 'This field is required';
          }
          if (itemIndex! > -1 && props!.routes![itemIndex!]?.id !== entity.id) {
            input.errorMessage = 'Please enter unique name';
          }
        }

        if (input.name === 'toTaskId') {
          if (entity.fromTaskId === entity.toTaskId) {
            input.errorMessage = 'Task start and Task end cannot be the same';
          }
        }

        return input;
      }
    );
    setRequiredFields(inputValidation);
    return (
      inputValidation.findIndex((input) => input.errorMessage !== '') === -1
    );
  };

  return (
    <Window
      modal={true}
      minimizeButton={undefined}
      className={'shadow'}
      initialWidth={editorEntity.isNew ? 500 : 700}
      initialHeight={editorEntity.isNew ? 450 : 650}
      title={editorEntity.isNew ? 'New annotations' : editorEntity.entity.name}
      {...props}>
      <FluidForm>
        <div className={'d-flex flex-row'}>
          <div className={'flex-column flex-fill'}>
            <StandardInput
              name={'name'}
              label={'Name'}
              value={entity?.name}
              onChange={handleInputChange}
              validationMessage={
                requiredFields?.find((item) => item.name === 'name')
                  ?.errorMessage
              }
              autoFocus={true}
            />
            <StandardInput
              name={'titleResId'}
              className={'mb-2'}
              label={'Title'}
              value={entity?.titleResId}
              validationMessage={
                requiredFields?.find((task) => task.name === 'titleResId')
                  ?.errorMessage ?? ''
              }
              onChange={(e) => handleInputChange(e)}
            />
            <ComboboxInput
              label={'Task start'}
              placeholder={'Select a task'}
              data={props?.tasks?.filter((x) => x.id !== entity.toTaskId) ?? []}
              textField={'name'}
              value={props.tasks?.find((x) => x.id === entity.fromTaskId)}
              name={'fromTaskId'}
              onChange={handleComboboxChange}
              clearButton={false}
            />
            <ComboboxInput
              label={'Task end'}
              placeholder={'Select a task'}
              data={
                props?.tasks?.filter((x) => x.id !== entity.fromTaskId) ?? []
              }
              textField={'name'}
              value={props.tasks?.find((x) => x.id === entity.toTaskId)}
              name={'toTaskId'}
              clearButton={false}
              onChange={handleComboboxChange}
              validationMessage={
                requiredFields?.find((task) => task.name === 'toTaskId')
                  ?.errorMessage ?? ''
              }
            />
          </div>
        </div>
      </FluidForm>
      <WindowActionsBar>
        <Button themeColor={'secondary'} onClick={() => toggleDialog()}>
          Cancel
        </Button>
        <Button
          themeColor={'primary'}
          onClick={() => {
            if (isInputValid()) {
              onSubmit({ isNew: editorEntity.isNew, entity });
            }
          }}>
          Save
        </Button>
      </WindowActionsBar>
    </Window>
  );
};
