import { useEffect, useRef, useState } from 'react';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridNoRecords
} from '@progress/kendo-react-grid';
import { DataResult, State } from '@progress/kendo-data-query';
import { ColumnMenu } from '../components/columnMenu';
import { useNavigate } from 'react-router-dom';
import { GetGridUsersAsync } from '../services/users';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Button } from '@progress/kendo-react-buttons';
import {
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../components/grid/grid-tool-bar';
import Toolbar from '../components/page-toolbar';
import { DefaultGridSettings } from '../constants/grid-settings';
import { NoRecords } from '../components/grid/no-records';
import { UsersCardView } from './users-cardview';
import { CardViewDataStateChangeEvent } from './organisations-cardview';
import { LinkCell } from '../components/grid';
import HelpSupport from '../components/help-support';

const initialDataState: State = {
  sort: [{ field: 'username', dir: 'asc' }],
  ...DefaultGridSettings.initialDataState
};

const UsersGrid = () => {
  const navigate = useNavigate();
  const [dataState, setDataState] = useState<State>(initialDataState);
  const [gridMode, setGridMode] = useState<string>('grid');
  const [users, setUsers] = useState<DataResult>({
    data: [],
    total: 0
  });

  const fetchUsers = async () => {
    await GetGridUsersAsync(dataState)
      .then((response) => {
        // TODO: Why is this response different to all other API endpoints?
        setUsers(response);
      })
      .catch((ex) => {
        console.error(ex);
      });
  };

  useEffect(() => {
    fetchUsers();
  }, [dataState]);

  const LinkedNameCell = (props: GridCellProps) => (
    <LinkCell to={props.dataItem['id'].toString()} {...props}></LinkCell>
  );

  const onStatusColoring = (props: GridCellProps) => (
    <StatusColoring {...props} />
  );

  const StatusColoring = (props: GridCellProps) => {
    if (props.dataItem.status === 'Enabled') {
      return (
        <td>
          <span className={'text-success'}>{props.dataItem.status}</span>
        </td>
      );
    } else {
      return (
        <td>
          <span className={'text-muted'}>{props.dataItem.status}</span>
        </td>
      );
    }
  };

  const onAddUser = () => {
    navigate('/users/invite-user');
  };

  const gridExportRef = useRef<ExcelExport | null>(null);

  return (
    <>
      <Toolbar title={'Users'}>
        <HelpSupport
          title="Adding and removing members"
          url="https://forum.catalystglobal.com/t/my-organisation-members"
        />
        <Button onClick={onAddUser} themeColor={'primary'}>
          Add new
        </Button>
      </Toolbar>
      <div className={'pt-2'}>
        <GridToolBar
          searchPlaceholder={'Search users'}
          columnsToSearch={['username', 'fullName', 'preferredName']}
          showCardMode={true}
          exportRef={gridExportRef}
          onGridModeChange={(e) => {
            setGridMode(e.gridMode);
          }}
          {...dataState}
          onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
            setDataState(e.dataState);
          }}></GridToolBar>
        <ExcelExport
          data={users.data}
          ref={gridExportRef}
          fileName={`users.xlsx`}>
          {gridMode === 'grid' && (
            <Grid
              id={'grid-user'}
              pageable={DefaultGridSettings.pagerSettings}
              sortable={true}
              data={users}
              {...dataState}
              onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
                setDataState(e.dataState);
              }}>
              <Column
                field={'username'}
                filter={'text'}
                title={'Username'}
                columnMenu={ColumnMenu}
                cell={LinkedNameCell}
              />
              <Column
                field={'fullName'}
                title={'Name'}
                columnMenu={ColumnMenu}
              />
              <Column
                field={'country'}
                title={'Country'}
                columnMenu={ColumnMenu}
              />
              <Column
                field={'phoneNumber'}
                title={'Phone'}
                columnMenu={ColumnMenu}
              />
              <Column
                field={'department'}
                title={'Department'}
                columnMenu={ColumnMenu}
              />
              <Column
                field={'status'}
                title={'Status'}
                columnMenu={ColumnMenu}
                cell={onStatusColoring}
              />
              <GridNoRecords>
                <NoRecords />
              </GridNoRecords>
            </Grid>
          )}
          {gridMode === 'card' && (
            <UsersCardView
              data={users.data}
              onUsernameClick={(id: number) => navigate(`/users/${id}`)}
              total={users.total}
              {...dataState}
              onDataStateChange={(e: CardViewDataStateChangeEvent) => {
                setDataState(e.dataState);
              }}
            />
          )}
        </ExcelExport>
      </div>
    </>
  );
};
export default UsersGrid;
