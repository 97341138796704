import { useEffect, useRef, useState } from 'react';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridNoRecords
} from '@progress/kendo-react-grid';
import {
  DataResult,
  State,
  toDataSourceRequestString
} from '@progress/kendo-data-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { ColumnMenu } from '../components/columnMenu';
import { toastStore } from '../stores/toast-store';
import Toolbar from '../components/page-toolbar';
import {
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../components/grid/grid-tool-bar';
import { DefaultGridSettings } from '../constants/grid-settings';
import { NoRecords } from '../components/grid/no-records';
import { ActionCell } from '../components/grid/actions-cell';
import { LinkCell } from '../components/grid';
import { appStore } from '../stores/app-store';
import {
  GetSupportTicketsAsync,
  PatchSupportTicketAsync
} from '../services/support-tickets';
import { SupportTicket } from '../types/support-ticket';
import { formatDateToDDMMYYYY } from '../utils/date';
import { isEmpty } from 'lodash';

const initialDataState: State = {
  sort: [{ field: 'id', dir: 'desc' }],
  ...DefaultGridSettings.initialDataState
};

const SupportTicketsGrid = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [dataState, setDataState] = useState<State>({ ...initialDataState });
  const [dataGrid, setDataGrid] = useState<DataResult>(() => ({
    data: [],
    total: 0
  }));

  const fetchDataGrid = async () => {
    try {
      appStore.showLoading();
      const param =
        !isEmpty(params.get('status')) &&
        ['New', 'InProgress'].includes(params.get('status') as string);
      const response = await GetSupportTicketsAsync(
        `${toDataSourceRequestString(dataState)}${
          param
            ? `&filter=status~eq~%27${
                params.get('status') === 'New' ? 'New' : 'InProgress'
              }%27`
            : ''
        }`
      );
      setDataGrid(response);
    } catch (ex) {
      console.error(ex);
    } finally {
      appStore.hideLoading();
    }
  };

  const handleClosedTicket = async (dataItem: SupportTicket) => {
    let newData = { ...dataItem };
    newData.status = 'Closed';
    try {
      await PatchSupportTicketAsync(dataItem.id as number, newData);
      await fetchDataGrid();
      toastStore.show(
        'Support Tickets',
        <div>Support ticket status changed.</div>,
        'success'
      );
    } catch (err) {
      toastStore.show(
        'Support Tickets',
        <div>Failed to change support ticket status.</div>,
        'error'
      );
    }
  };

  useEffect(() => {
    fetchDataGrid();
  }, [dataState]);

  const gridTicketDate = (props: GridCellProps) => (
    <td>
      <span>{formatDateToDDMMYYYY(props.dataItem.createdAt, true)}</span>
    </td>
  );

  const gridTitle = (props: GridCellProps) => (
    <LinkCell
      to={`/administration/support_tickets/${props.dataItem['id']}`}
      field={'createdAt'}
      valueCell={props.dataItem.title}
      {...props}
    />
  );

  const generateTicketNumber = (idTicket: number) => {
    let numberStr = idTicket.toString();

    // Determines the number of digits that need to be added
    let numZeros = Math.max(0, 5 - numberStr.length);

    // Generates a string by adding leading 0 (if needed)
    let resultStr = '0'.repeat(numZeros) + numberStr;

    return resultStr;
  };

  const gridTicketNumber = (props: GridCellProps) => {
    return <span>{generateTicketNumber(props.dataItem.id)}</span>;
  };

  const gridActionsColumn = (props: GridCellProps) => {
    return (
      <div>
        <ActionCell
          title={'Edit'}
          icon={'edit'}
          themeColor={'primary'}
          className={'fs-2'}
          onClick={() =>
            navigate(`/administration/support_tickets/${props.dataItem.id}`)
          }
        />
        <ActionCell
          isDisable={props.dataItem.status === 'Closed'}
          title={'Mark as closed'}
          icon={'check_circle'}
          themeColor={'success'}
          className={'fs-2'}
          onClick={() => handleClosedTicket(props.dataItem as SupportTicket)}
        />
      </div>
    );
  };

  const gridExportRef = useRef<ExcelExport | null>(null);

  return (
    <>
      <Toolbar title={'Support Tickets'}></Toolbar>
      <div className={'pt-2'}>
        <GridToolBar
          searchPlaceholder={'Search tickets'}
          columnsToSearch={['title', 'requestedBy']}
          showCardMode={false}
          exportRef={gridExportRef}
          {...dataState}
          onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
            setDataState(e.dataState);
          }}></GridToolBar>
        <ExcelExport
          data={dataGrid.data}
          ref={gridExportRef}
          fileName={`support-tickets.xlsx`}>
          <Grid
            id={'grid-support-tickets'}
            pageable={DefaultGridSettings.pagerSettings}
            sortable={true}
            data={dataGrid}
            {...dataState}
            onDataStateChange={(e: GridDataStateChangeEvent) => {
              setDataState(e.dataState);
            }}>
            <Column
              field={'title'}
              title={'Ticket Title'}
              cell={gridTitle}
              columnMenu={ColumnMenu}
            />
            <Column
              field={'createdAt'}
              title={'Ticket Date'}
              format={'{0:g}'}
              cell={gridTicketDate}
              columnMenu={ColumnMenu}
            />
            <Column
              field={'ticketNumber'}
              title={'Ticket Number'}
              cell={gridTicketNumber}
              columnMenu={ColumnMenu}
            />

            <Column
              field={'requestedBy'}
              title={'Requester'}
              columnMenu={ColumnMenu}
            />
            <Column
              field={'status'}
              title={'Status'}
              filter={'text'}
              columnMenu={ColumnMenu}
            />
            <Column title={'Actions'} cell={gridActionsColumn} width={110} />
            <GridNoRecords>
              <NoRecords />
            </GridNoRecords>
          </Grid>
        </ExcelExport>
      </div>
    </>
  );
};
export default SupportTicketsGrid;
