import { GameDocumentContext } from '../../../contexts/game-document';
import React, { useContext, useEffect, useState } from 'react';
import Toolbar from '../toolbar';
import {
  AddItemAsync,
  AddResourceAsync,
  CopyItemAsync,
  DeleteItemAsync,
  ExportItems,
  ImportItemsAsync,
  UpdateGameDocState,
  UpdateItemAsync,
  UpdateResourceAsync
} from '../../../utils/game-document';
import { Button } from '@progress/kendo-react-buttons';
import { process, State } from '@progress/kendo-data-query';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridNoRecords,
  GridSelectionChangeEvent
} from '@progress/kendo-react-grid';
import { LinkCell } from '../../../components/grid';
import { ItemEntity, ResourceEntity } from '../../../types/game-document/';
import { ItemEditorWindow } from '../../../features/game-document/items';
import { EntityEditor } from '../../../types/game-document/entity-editor';
import EditDeleteCloneCell from '../../../components/grid/edit-delete-clone-cell';
import {
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../../../components/grid/grid-tool-bar';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { NoRecords } from '../../../components/grid/no-records';
import { uuid } from '../../../types/common-helper';
import { DefaultGridSettings } from '../../../constants/grid-settings';
import { useSessionStorage } from 'usehooks-ts';
import { Item } from '../../../types/game-document/entities/item';
import { getCurrentTimeStamp } from '../../../utils/date';
import { downloadJSON, handleFileUpload } from '../../../utils/files';
import { ExportedItemData } from '../../../types/game-document/entities/exported-item';
import { toastStore } from '../../../stores/toast-store';

const initialDataState: State = {
  sort: [{ field: 'name', dir: 'asc' }],
  ...DefaultGridSettings.initialDataState
};

interface ItemGrid extends Item {
  selected: boolean;
}

const SELECTED_FIELD = 'selected';

const Inventory = () => {
  const [state, setState] = useContext(GameDocumentContext);
  const [dataState, setDataState] = useSessionStorage<State>(
    'datastate-inventory',
    initialDataState
  );
  const [entityEditorValue, setEntityEditorValue] =
    useState<EntityEditor<ItemEntity>>();
  const [resourceEditorValue, setResourceEditorValue] =
    useState<EntityEditor<ResourceEntity>[]>();
  const [entityEditorIsVisible, setEntityEditorIsVisible] =
    useState<boolean>(false);
  const toggleEntityEditor = () => {
    setEntityEditorIsVisible(!entityEditorIsVisible);
  };

  const [items, setItems] = useState<ItemGrid[] | undefined>([]);
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);

  const titleGuid = uuid();
  const summaryGuid = uuid();
  const imageGuid = uuid();
  const entity: ItemEntity = {
    id: '',
    name: '',
    description: '',
    titleResId: titleGuid,
    summaryResId: summaryGuid,
    imageResId: imageGuid
  };

  const titleResource: ResourceEntity = {
    id: titleGuid,
    name: 'Title',
    description: '',
    type: 'text',
    value: ''
  };

  const summaryResource: ResourceEntity = {
    id: summaryGuid,
    name: 'Summary',
    description: '',
    type: 'text',
    value: ''
  };

  const imageResource: ResourceEntity = {
    id: imageGuid,
    name: imageGuid,
    description: '',
    type: 'image',
    value: '',
    size: 0
  };

  useEffect(() => {
    // Set the page title.
    document.title = `Inventory - ${state.gameDocument?.name}`;
    const items = state.gameDocument?.assets.items as ItemGrid[] | undefined;
    setItems(items);
  }, [state]);

  const LinkedNameCell = (props: GridCellProps) => (
    <LinkCell to={props.dataItem['id'].toString()} {...props}></LinkCell>
  );

  const DeleteCell = (props: GridCellProps) => (
    <EditDeleteCloneCell
      onEditClick={() => onEditEntity(props.dataItem['id'])}
      onCloneClick={() => onCloneEntity(props.dataItem['id'])}
      onDeleteClick={() => onDeleteEntity(props.dataItem['id'])}
      {...props}></EditDeleteCloneCell>
  );

  const handleJsonExport = async () => {
    if (selectedItemIds.length === 0) {
      toastStore.show(
        'Error',
        'Please select the inventory you want to export first.',
        'error'
      );
      return;
    }
    const exportedItems = ExportItems(state.gameDocument!, selectedItemIds);
    const formattedTimestamp = getCurrentTimeStamp();

    downloadJSON(exportedItems, `inventory-${formattedTimestamp}.json`);
  };

  const handleJsonImport = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleFileUpload<ExportedItemData>(
      event,
      async (exportedItem) => {
        ImportItemsAsync(state.gameDocument!, exportedItem).then(
          (updatedGameDocument) => {
            setState((state) => UpdateGameDocState(state, updatedGameDocument));
            toastStore.show('Info', 'Import Success', 'info');
          }
        );
      },
      (message) => {
        toastStore.show('Error', message, 'error');
      }
    );
  };

  const handleEntityEditorSubmit = (
    editorEntity: EntityEditor<ItemEntity>,
    resourceEntity: EntityEditor<ResourceEntity>[]
  ) => {
    if (editorEntity.isNew) {
      const addTitleResource = AddResourceAsync(
        state.gameDocument!,
        'Title',
        '',
        'text',
        resourceEntity.find(
          (item) => item.entity.id === editorEntity.entity.titleResId!
        )?.entity.value!,
        titleGuid
      );
      const addSummaryResource = AddResourceAsync(
        state.gameDocument!,
        'Summary',
        '',
        'text',
        resourceEntity.find(
          (item) => item.entity.id === editorEntity.entity.summaryResId!
        )?.entity.value!,
        summaryGuid
      );

      const imageResource = resourceEntity.find(
        (item) => item.entity.id === editorEntity.entity.imageResId!
      )?.entity;
      const addImageResource = AddResourceAsync(
        state.gameDocument!,
        imageGuid,
        imageResource?.description ?? '',
        'image',
        imageResource?.value ?? '',
        imageGuid,
        imageResource?.size ?? 0
      );

      Promise.all([
        addTitleResource,
        addSummaryResource,
        addImageResource
      ]).then(() => {
        const {
          entity: { name, description }
        } = editorEntity;
        AddItemAsync(
          state.gameDocument!,
          name,
          description,
          titleGuid,
          summaryGuid,
          imageGuid,
          editorEntity.entity.hideInGame
        ).then((updatedGameDocument) => {
          setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
          setEntityEditorIsVisible(false);
        });
      });
    } else {
      const titleResource = resourceEntity.find(
        (resource) => resource.entity.id === editorEntity.entity.titleResId
      );
      const summaryResource = resourceEntity.find(
        (resource) => resource.entity.id === editorEntity.entity.summaryResId
      );
      const imageResource = resourceEntity.find(
        (resource) => resource.entity.id === editorEntity.entity.imageResId
      );

      const updateTitleResource = UpdateResourceAsync(
        state.gameDocument!,
        editorEntity.entity.titleResId!,
        titleResource?.entity!
      );
      const updateSummaryResource = UpdateResourceAsync(
        state.gameDocument!,
        editorEntity.entity.summaryResId!,
        summaryResource?.entity!
      );
      const updateImageResource = UpdateResourceAsync(
        state.gameDocument!,
        editorEntity.entity.imageResId!,
        imageResource?.entity!
      );

      Promise.all([
        updateTitleResource,
        updateSummaryResource,
        updateImageResource
      ]).then(() => {
        UpdateItemAsync(
          state.gameDocument!,
          editorEntity.entity.id,
          editorEntity.entity
        ).then((updatedGameDocument) => {
          setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
          setEntityEditorIsVisible(false);
        });
      });
    }
  };

  const onAddEntity = () => {
    setEntityEditorValue({
      isNew: true,
      entity
    });
    // Create 3 resources for item (titleResId, summaryResId, imageResId)
    setResourceEditorValue([
      {
        isNew: true,
        entity: titleResource
      },
      {
        isNew: true,
        entity: summaryResource
      },
      {
        isNew: true,
        entity: imageResource
      }
    ]);
    toggleEntityEditor();
  };

  const onEditEntity = (entityId: string) => {
    const editedEntity = state.gameDocument?.assets.items?.find(
      (i) => i.id === entityId
    )!;
    setEntityEditorValue({
      isNew: false,
      entity: editedEntity
    });
    // Set 3 resources for item (titleResId, summaryResId, imageResId)
    setResourceEditorValue([
      {
        isNew: true,
        entity: state.gameDocument?.resources.find(
          (item) => item.id === editedEntity.titleResId
        ) as ResourceEntity
      },
      {
        isNew: true,
        entity: state.gameDocument?.resources.find(
          (item) => item.id === editedEntity.summaryResId
        ) as ResourceEntity
      },
      {
        isNew: true,
        entity: state.gameDocument?.resources.find(
          (item) => item.id === editedEntity.imageResId
        ) as ResourceEntity
      }
    ]);
    toggleEntityEditor();
  };

  const onCloneEntity = (entityId: string) => {
    CopyItemAsync(state.gameDocument!, entityId).then((updatedGameDocument) => {
      setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
    });
  };

  const onDeleteEntity = async (entityId: string) => {
    DeleteItemAsync(state.gameDocument!, entityId).then(
      (updatedGameDocument) => {
        setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
      }
    );
  };

  const onSelectionChange = (event: GridSelectionChangeEvent) => {
    const selectedItemId = event.dataItem['id'];

    const updatedItems = items?.map((item) =>
      item.id === selectedItemId ? { ...item, selected: !item.selected } : item
    );
    setItems(updatedItems);

    const item = updatedItems?.find((item) => item.id === selectedItemId);

    if (item) {
      const updatedSelectedItemIds = item.selected
        ? [...selectedItemIds, selectedItemId]
        : selectedItemIds.filter((id) => id !== selectedItemId);

      setSelectedItemIds(updatedSelectedItemIds);
    }
  };

  const onHeaderSelectionChange = () => {
    const updatedItems = items?.map((item) => ({
      ...item,
      selected: !item.selected
    }));

    const updatedSelectedTaskContentIds = updatedItems?.reduce<string[]>(
      (ids, item) => {
        if (item.selected) {
          return [...ids, item.id];
        } else {
          return ids.filter((id) => id !== item.id);
        }
      },
      [...(selectedItemIds || [])]
    );

    setSelectedItemIds(updatedSelectedTaskContentIds || []);
    setItems(updatedItems);
  };

  const gridExportRef = React.useRef<ExcelExport | null>(null);

  return (
    <>
      <Toolbar title={'Inventory'}>
        <Button onClick={onAddEntity} themeColor={'primary'}>
          Add item
        </Button>
        {entityEditorIsVisible && (
          <ItemEditorWindow
            toggleDialog={toggleEntityEditor}
            onSubmit={handleEntityEditorSubmit}
            onClose={toggleEntityEditor}
            editorEntity={entityEditorValue!}
            editorResource={resourceEditorValue!}
            editorMode={'basic'}
          />
        )}
      </Toolbar>
      <div className={'pt-2'}>
        <GridToolBar
          searchPlaceholder={'Search items'}
          columnsToSearch={['name', 'description']}
          showCardMode={false}
          exportRef={gridExportRef}
          {...dataState}
          onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
            setDataState(e.dataState);
          }}
          showJsonExport={true}
          handleJsonExport={handleJsonExport}
          showJsonImport={true}
          handleJsonImport={handleJsonImport}
        />
        <ExcelExport
          data={state.gameDocument?.assets.items ?? []}
          ref={gridExportRef}
          fileName={`${state.gameDocument?.name} inventory.xlsx`}>
          <Grid
            pageable={DefaultGridSettings.pagerSettings}
            sortable={true}
            className={'cg-grid3'}
            data={process(items ?? [], dataState)}
            dataItemKey={'id'}
            {...dataState}
            onDataStateChange={(e: GridDataStateChangeEvent) => {
              setDataState(e.dataState);
            }}
            selectedField={SELECTED_FIELD}
            onSelectionChange={onSelectionChange}
            onHeaderSelectionChange={onHeaderSelectionChange}>
            <Column filterable={false} field={SELECTED_FIELD} width={50} />
            <Column field={'name'} title={'Name'} cell={LinkedNameCell} />
            <Column field={'description'} title={'Description'} />
            <Column cell={DeleteCell} />
            <GridNoRecords>
              <NoRecords />
            </GridNoRecords>
          </Grid>
        </ExcelExport>
      </div>
    </>
  );
};

export default Inventory;
