import {
  DeleteAsync,
  PostAsync,
  GetAllAsync,
  PatchAsync,
  PutAsync
} from './api';
import { AddressResponse } from '../types/responses/address-response';

/**
 * Applies a POST to the OrganisationAddress.
 * requiring only the fields and values that require modification.
 * @param organisationId - the ID of the Organisation to PATCH
 * @param organisation - the Organisation or partial Organisation to update [PATCH]
 * @returns the updated Organisation object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PATCH
 */
export const PostOrganisationAddressAsync = async (
  organisationId: number,
  organisationAddress: AddressResponse
) =>
  PostAsync<AddressResponse>(
    `organisations/${organisationId}/addresses`,
    organisationAddress
  );

/**
 * Gets the OrganisationAddress by id.
 * @param organisationId - the ID of the Organisation to retrieve
 * @returns the Organisation object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetOrganisationAddressesAsync = async (organisationId: number) =>
  GetAllAsync<AddressResponse>(`organisations/${organisationId}/addresses`);

/**
 * Updates the OrganisationAddress by completely replacing it.
 * @param organisationId - the ID of the entity to update [PUT]
 * @param organisation - the Organisation to update [PUT]
 * @returns the updated Organisation object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PUT
 */
export const PatchOrganisationAddressAsync = async (
  organisationId: number,
  organisationAddressId: number,
  organisationAddress: AddressResponse
) =>
  PatchAsync<AddressResponse>(
    `organisations/${organisationId}/addresses/${organisationAddressId}`,
    organisationAddress
  );

/**
 * Applies a PATCH to the OrganisationAddress. This can be considered a partial update
 * requiring only the fields and values that require modification.
 * @param organisationId - the ID of the Organisation to PATCH
 * @param organisation - the Organisation or partial Organisation to update [PATCH]
 * @returns the updated Organisation object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PATCH
 */
export const PutOrganisationAddressAsync = async (
  organisationId: number,
  organisationAddressId: number,
  organisationAddress: AddressResponse
) =>
  PutAsync<AddressResponse>(
    `organisations/${organisationId}/addresses/${organisationAddressId}`,
    organisationAddress
  );

/**
 * Deletes a OrganisationAddress by id.
 * @param organisationId - the ID of the Organisation to DELETE
 * @returns the deleted Organisation object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/DELETE
 */
export const DeleteOrganisationAddressAsync = async (
  organisationId: number,
  organisationAddressId: number
) =>
  DeleteAsync<AddressResponse>(
    `organisations/${organisationId}/addresses/${organisationAddressId}`
  );
