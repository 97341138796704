import { Checkbox, NumericTextBox } from '@progress/kendo-react-inputs';
import { Col, Row } from 'react-bootstrap';

type MultipleAttemptsProps = {
  isMultipleAttempts?: boolean;
  maxAttempts?: number | undefined;
  pointDepreciation?: number | undefined;
  onChangeValue?: (
    isMultipleAttempts: boolean,
    maxAttempts: number | undefined,
    pointDepreciation: number | undefined
  ) => void;
};

export function ScoringMultipleAttempts({
  onChangeValue = () => {},
  ...props
}: MultipleAttemptsProps) {
  const onValueChangedHandler = (
    maxAttempts: number,
    pointDepreciation: number
  ) => {
    onChangeValue(props.isMultipleAttempts!, maxAttempts, pointDepreciation);
  };

  const onCheckedChangeHandler = (isChecked: boolean) => {
    if (!isChecked) {
      onChangeValue(isChecked, undefined, undefined);
    }

    if (isChecked) {
      onChangeValue(isChecked, 1, 0);
    }
  };

  return (
    <>
      <Row className={'mt-2 p-1 row'}>
        <Col>
          <Checkbox
            label={'Multiple attempts'}
            checked={props?.isMultipleAttempts}
            onChange={(e) => onCheckedChangeHandler(e.target.value as boolean)}
          />
        </Col>
      </Row>
      {props?.isMultipleAttempts && (
        <>
          <Row className={'mt-2 mt-2 p-1 row'}>
            <Col
              className={'d-flex justify-content-between align-items-center'}>
              <span>Maximum attempts</span>
              <NumericTextBox
                width={170}
                value={props?.maxAttempts}
                min={1}
                onChange={(e) => {
                  const maxAttempts =
                    e.value !== null ? (e.value < 1 ? 1 : e.value) : 1;
                  onValueChangedHandler(
                    maxAttempts,
                    props?.pointDepreciation ?? 0
                  );
                }}
              />
            </Col>
          </Row>
          <Row className={'mt-2 mt-2 p-1 row'}>
            <Col
              className={'d-flex justify-content-between align-items-center'}>
              <span>Point depreciation per attempt</span>
              <NumericTextBox
                width={170}
                value={props?.pointDepreciation}
                onChange={(e) =>
                  onValueChangedHandler(props?.maxAttempts ?? 1, e.value ?? 0)
                }
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
