import React from 'react';
import { Progress } from '../../layouts';
import { ReactComponent as Logo } from '../../assets/cg-icon-globe.svg';

interface AuthenticatingErrorProps {
  children?: React.ReactNode;
}

const AuthenticationError = ({ children }: AuthenticatingErrorProps) => (
  <Progress>
    <div className={'text-center'}>
      <Logo className={'spin'} style={{ width: 100 }} />
      <h1 className={'mt-3'}>Authentication error</h1>
      <p>There was an error during authentication.</p>
      <>{children}</>
    </div>
  </Progress>
);

export default AuthenticationError;
