import { PrivatePage } from '../../components/private-page';
import { useEffect } from 'react';
import { ctGameDefault, ctGameTitle } from '../../constants/text';
import { Row } from 'react-bootstrap';
import NetworkOrganisationsGrid from '../../features/network-organisations-grid';

export default function NetworkOrganisations() {
  useEffect(() => {
    document.title = ctGameTitle + ' - Organisations';
    return () => {
      document.title = ctGameDefault;
    };
  }, []);

  return (
    <>
      <PrivatePage
        breadcrumb={[
          { label: 'Dashboard', href: 'dashboard' },
          { label: 'Organisations', href: 'networkorganisations' }
        ]}
        pageTitle={'Organisations'}>
        <Row>
          <NetworkOrganisationsGrid />
        </Row>
      </PrivatePage>
    </>
  );
}
