import { useEffect } from 'react';
import { PrivatePage } from '../../components/private-page';
import { ctGameDefault, ctGameTitle } from '../../constants/text';
import GlobalFeedbackGrid from '../../features/global-feedback-grid';

export default function GlobalFeedback() {
  useEffect(() => {
    document.title = ctGameTitle + ' - Global Feedback';
    return () => {
      document.title = ctGameDefault;
    };
  }, []);

  return (
    <PrivatePage
      breadcrumb={[
        { label: 'Dashboard', href: '../dashboard' },
        { label: 'Global Feedback', href: '../global-feedback' }
      ]}
      pageTitle={'Global Library'}>
      <GlobalFeedbackGrid />
    </PrivatePage>
  );
}
