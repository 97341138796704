import GlobalLibraryGrid from '../features/global-library-grid';
import { PrivatePage, BreadcrumbLocation } from '../components/private-page';
import { useEffect } from 'react';
import { ctGameDefault, ctGameTitle } from '../constants/text';
export default function GlobalLibraries() {
  const breadCrumb: BreadcrumbLocation[] = [
    { label: 'Dashboard', href: 'dashboard' },
    { label: 'Global Library', href: 'global-games' }
  ];

  useEffect(() => {
    document.title = ctGameTitle + '- Global Library';
    return () => {
      document.title = ctGameDefault;
    };
  }, []);
  return (
    <PrivatePage
      breadcrumb={[
        {
          label: 'Dashboard',
          href: '../dashboard'
        },
        {
          label: 'Global Games',
          href: '../global-games'
        }
      ]}
      pageTitle={'Global Library'}>
      <GlobalLibraryGrid />
    </PrivatePage>
  );
}
