import { Button } from '@progress/kendo-react-buttons';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import cloneDeep from 'lodash.clonedeep';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { TaskContentType } from '../../features/game-document/task-contents/task-content-full-editor';
import {
  AnswerType,
  ManualScoring,
  ScoringReductionEntity,
  TaskContentAnswer
} from '../../types/game-document/entities/task-content';
import { ManualScoring as ManualScoringComponent } from './manual-scoring';
import { ScoringMultipleAttempts } from './scoring-multiple-attempts';
import { ScoringSingleAnswer } from './scoring-single-answer';
import { ScoringReductionAnswer } from './scoring-reduction';
import { AnswerCheckboxScoring } from './answer-checkbox-scoring';
import { AnswerOptionsScoring } from './answer-options-scoring';

export interface ScoringProps {
  id?: string;
  answerType?: AnswerType;
  answers?: TaskContentAnswer[];
  contentTypes?: TaskContentType[];
  isManualScoring?: boolean;
  manualScoring?: ManualScoring;
  isScoringReduction?: boolean;
  scoringReduction?: ScoringReductionEntity;
  maxAttempts?: number | undefined;
  pointDepreciation?: number | undefined;
  score?: number | undefined;
  onShowScoring?: (id: string) => void;
  onChangeAnswers?: (
    score?: number | undefined,
    isManualScoring?: boolean,
    manualScoring?: ManualScoring,
    maxAttempts?: number | undefined,
    pointDepreciation?: number | undefined,
    isScoringReduction?: boolean,
    scoringReduction?: ScoringReductionEntity,
    answersWithScoring?: TaskContentAnswer[]
  ) => void;
}
export function Scoring({
  id = '',
  score,
  answerType,
  answers,
  isManualScoring,
  manualScoring,
  isScoringReduction = false,
  scoringReduction = {},
  maxAttempts,
  pointDepreciation,
  onShowScoring = () => {},
  onChangeAnswers = () => {}
}: ScoringProps) {
  const [scoreState, setScoreState] = useState<number | undefined>();
  const [answersState, setAnswersState] = useState<TaskContentAnswer[]>([]);
  const [manualScoreError, setManualScoreError] = useState<string>('');
  const [isManualScoringState, setIsManualScoringState] =
    useState<boolean>(false);
  const [isMultipleAttemptsState, setIsMultipleAttemptsState] =
    useState<boolean>(false);
  const [manualScoringState, setManualScoringState] = useState<ManualScoring>(
    {}
  );
  const [isAbleSetAttempts, setAbleSetAttempts] = useState<boolean>(false);
  const [maxAttemptsState, setMaxAttemptsState] = useState<
    number | undefined
  >();
  const [pointDepreciationState, setPointDepreciationState] = useState<
    number | undefined
  >();
  const [isScoringReductionInput, setIsScoringReductionInput] =
    useState<boolean>(isScoringReduction);
  const [scoringReductionState, setScoringReductionState] =
    useState<ScoringReductionEntity>(scoringReduction);
  const [errorMessageScoreReduction, setErrorMessageScoreReduction] =
    useState<string>('');

  const [isElapsedPointReductionEnabled, setIsElapsedPointReductionEnabled] =
    useState<boolean>(false);

  const onChangeScoringReductionCheckbox = () => {
    setIsScoringReductionInput(!isScoringReductionInput);
    if (!isScoringReductionInput) {
      setScoringReductionState({});
    }
  };
  const onChangeScoringReductionInput = (
    scoringReduction?: ScoringReductionEntity
  ) => {
    setScoringReductionState(scoringReduction!);
  };

  const onChangeInputScore = (value: number | undefined) => {
    setScoreState(value);
  };

  const onChangeAnswerHandler = (answers: TaskContentAnswer[]) => {
    setAnswersState(answers);
  };

  const onConfirmScoring = () => {
    if (!isErrorManualScoring()) {
      onChangeAnswers(
        isManualScoringState ||
          answerType === 'radio' ||
          answerType === 'checkbox'
          ? undefined
          : scoreState,
        isManualScoringState,
        manualScoringState,
        maxAttemptsState,
        pointDepreciationState,
        isScoringReductionInput,
        scoringReductionState,
        answersState
      );
    }
  };

  const isErrorManualScoring = () => {
    let min: number = parseInt((manualScoringState?.min || 0).toString());
    let max: number = parseInt((manualScoringState?.max || 0).toString());

    if (min > max && isManualScoringState) {
      setManualScoreError('Field `To` must be greater than Field `From`');
      return true;
    }

    if (isScoringReductionInput) {
      let areValuesCorrect = true;
      if (
        !scoringReductionState.maximumTimeLimit ||
        (isElapsedPointReductionEnabled &&
          (!scoringReductionState.scoreReductionInterval ||
            !scoringReductionState.scoreReduction))
      ) {
        areValuesCorrect = false;
      }

      if (
        scoringReductionState.maximumTimeLimit !== null &&
        scoringReductionState.maximumTimeLimit !== 0
      ) {
        areValuesCorrect = true;
      }

      if (isElapsedPointReductionEnabled) {
        areValuesCorrect =
          scoringReductionState.scoreReductionInterval != null &&
          scoringReductionState.scoreReduction != null &&
          scoringReductionState.scoreReduction !== 0 &&
          scoringReductionState.scoreReductionInterval !== 0;
      }

      if (!areValuesCorrect) {
        setErrorMessageScoreReduction('This field is required');
        return true;
      }
    }

    setManualScoreError('');
    setErrorMessageScoreReduction('');
    return false;
  };

  const onChangeAnswersHandler = (
    answers: TaskContentAnswer[],
    isManualScoring?: boolean,
    manualScoring?: ManualScoring
  ) => {
    setAnswersState(answers);
    setIsManualScoringState(isManualScoring!);
    setManualScoringState(manualScoring!);
    if (isManualScoring) {
      setIsMultipleAttemptsState(false);
      setIsScoringReductionInput(false);
      setScoringReductionState({});
    }
  };

  const setAttemptsVisibility = () => {
    if (
      answerType === 'checkbox' ||
      answerType === 'radio' ||
      answerType === 'text' ||
      answerType === 'textarea' ||
      answerType === 'number' ||
      answerType === 'time'
    ) {
      setAbleSetAttempts(true);
    }
  };

  const onChangeMultipleAttemptsHandler = (
    isMultipleAttempts: boolean,
    maxAttempts: number | undefined,
    pointDepreciation: number | undefined
  ) => {
    setIsMultipleAttemptsState(isMultipleAttempts);
    setMaxAttemptsState(maxAttempts);
    setPointDepreciationState(pointDepreciation);

    if (isMultipleAttempts) {
      setIsManualScoringState(false);
      setManualScoringState({ min: undefined, max: undefined });
    }
  };

  const onChangeElapsedPointReductionEnabledCheckbox = (
    isEllapsedPointReductionEnabled: boolean
  ) => {
    setIsElapsedPointReductionEnabled(isEllapsedPointReductionEnabled);
  };

  useEffect(() => {
    if (answers) {
      setAnswersState(cloneDeep(answers));
    }
  }, [answers]);

  useEffect(() => {
    setIsManualScoringState(isManualScoring!);
  }, [isManualScoring]);

  useEffect(() => {
    if (manualScoring) {
      setManualScoringState(manualScoring);
    }
  }, [manualScoring]);

  useEffect(() => {
    setAttemptsVisibility();
  }, [answerType]);

  useEffect(() => {
    setMaxAttemptsState(maxAttempts);

    if (maxAttempts) {
      setIsMultipleAttemptsState(true);
    }
  }, [maxAttempts]);

  useEffect(() => {
    setPointDepreciationState(pointDepreciation);
  }, [pointDepreciation]);

  useEffect(() => {
    if (isManualScoringState) {
      setIsMultipleAttemptsState(false);
      setMaxAttemptsState(undefined);
      setPointDepreciationState(undefined);
    }
  }, [isManualScoringState]);

  useEffect(() => {
    if (isMultipleAttemptsState) {
      setIsManualScoringState(false);
      setManualScoringState({ min: undefined, max: undefined });
    }
  }, [isMultipleAttemptsState]);

  useEffect(() => {
    if (maxAttempts! >= 0) {
      setIsMultipleAttemptsState(true);
    }
  }, [maxAttempts]);

  useEffect(() => {
    setScoreState(score);
  }, [score]);

  useEffect(() => {
    if (scoringReduction) {
      setIsElapsedPointReductionEnabled(
        scoringReduction.scoreReduction !== undefined &&
          scoringReduction.scoreReductionInterval !== undefined &&
          scoringReduction.scoreReduction !== null &&
          scoringReduction.scoreReductionInterval !== null
      );
    }
  }, []);

  return (
    <>
      <Row>
        <Col>
          <h3 className={'fw-bold'}>Answer and scoring</h3>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          {(answerType === 'radio' || answerType === 'checkbox') && (
            <>
              <Row>
                {answerType === 'radio' && (
                  <AnswerOptionsScoring
                    group={id}
                    options={answersState}
                    onChangeAnswers={
                      onChangeAnswerHandler
                    }></AnswerOptionsScoring>
                )}

                {answerType === 'checkbox' && (
                  <AnswerCheckboxScoring
                    group={id}
                    options={answersState}
                    onChangeAnswers={onChangeAnswerHandler}
                  />
                )}
              </Row>
            </>
          )}
          <Row>
            <Col>
              <ScoringSingleAnswer
                score={scoreState}
                answerType={answerType}
                isManualScoring={isManualScoringState}
                answers={answersState}
                onChangeInputScore={onChangeInputScore}></ScoringSingleAnswer>
            </Col>
          </Row>
        </Col>
        <Col md={6}>
          <ManualScoringComponent
            answers={answersState}
            isManualScoring={isManualScoringState}
            manualScoring={manualScoringState}
            errorMessage={manualScoreError}
            onChangeAnswers={onChangeAnswersHandler}></ManualScoringComponent>

          {isAbleSetAttempts && (
            <>
              <hr></hr>
              <ScoringMultipleAttempts
                maxAttempts={maxAttemptsState}
                pointDepreciation={pointDepreciationState}
                isMultipleAttempts={isMultipleAttemptsState}
                onChangeValue={onChangeMultipleAttemptsHandler}
              />
            </>
          )}
        </Col>
        <Col md={12}>
          <ScoringReductionAnswer
            isDisabled={isManualScoringState}
            isScoringReduction={isScoringReductionInput}
            scoringReductionState={scoringReductionState}
            onChangeScoringReductionCheckbox={onChangeScoringReductionCheckbox}
            onChangeScoringReductionInput={onChangeScoringReductionInput}
            errorMessage={errorMessageScoreReduction}
            isElapsedPointReductionEnabled={isElapsedPointReductionEnabled}
            onChangeElapsedPointReductionEnabledCheckbox={
              onChangeElapsedPointReductionEnabledCheckbox
            }
          />
        </Col>
      </Row>
      <Row>
        <Col className={'d-flex justify-content-end mt-3'}>
          <Button
            themeColor={'light'}
            className={'mr-2'}
            onClick={() => onShowScoring(id)}>
            Cancel
          </Button>
          <Button themeColor={'primary'} onClick={onConfirmScoring}>
            Confirm
          </Button>
        </Col>
      </Row>
    </>
  );
}
