import { useParams } from 'react-router-dom';
import { useApi, useAssessment, useAssessmentDocument } from '../../hooks';
import { useEffect, useRef } from 'react';
import * as monaco from 'monaco-editor';
import Editor, { Monaco } from '@monaco-editor/react';
import { PrivatePage } from '../../components/private-page';
import PageToolbar from '../../components/page-toolbar';
import { Button } from '@progress/kendo-react-buttons';

export const AssessmentMonacoPage = () => {
  const { id } = useParams();
  const api = useApi();
  const assessment = useAssessment(id);
  const assessmentDocument = useAssessmentDocument(id);

  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor>();

  // Format the document and scroll to the top
  useEffect(() => {
    if (assessmentDocument && assessmentDocument.content != null) {
      editorRef.current?.setValue(JSON.stringify(assessmentDocument.content));
      editorRef.current?.setPosition({ column: 1, lineNumber: 1 });
      editorRef.current?.getAction('editor.action.formatDocument')?.run();
    }
  }, [assessmentDocument, assessmentDocument.content, editorRef]);

  // TODO: loading
  if (assessment.isLoading || assessmentDocument.isLoading) return <></>;

  // TODO: no data
  if (assessment.data == null || assessmentDocument.content == null)
    return <></>;

  const handleEditorDidMount = (
    editor: monaco.editor.IStandaloneCodeEditor,
    monaco: Monaco
  ) => {
    editorRef.current = editor;
    editor.getAction('editor.action.formatDocument')?.run();
  };

  return (
    <PrivatePage pageTitle={'Assessment'} className={'page-base--full'}>
      <div>
        <PageToolbar
          title={id ? (assessment?.data.name ?? '') : 'New Assessment'}>
          <Button
            themeColor={'warning'}
            onClick={() =>
              api.assessments.patchAssessmentDocument(
                id!,
                assessmentDocument.document!.id,
                JSON.parse(editorRef.current?.getValue() ?? '{}')
              )
            }>
            Overwrite
          </Button>
        </PageToolbar>
        <div
          className={'text-danger text-center py-2'}
          style={{ fontSize: '0.75rem' }}>
          <strong>Warning</strong>: This function bypasses validation and will
          overwrite your assessment document, potentially causing unrecoverable
          corruption. Use with extreme caution.
        </div>
      </div>
      <Editor
        defaultLanguage={'json'}
        options={{ minimap: { enabled: false }, tabSize: 2 }}
        value={JSON.stringify(assessmentDocument?.content)}
        onMount={handleEditorDidMount}
      />
    </PrivatePage>
  );
};
