import { makeAutoObservable } from 'mobx';
import { ToastProps, ToastStyle } from '../components/toast';

export class ToastStore {
  private _props?: ToastProps;
  constructor() {
    makeAutoObservable(this);
  }
  show(
    title: string,
    message: string | React.ReactNode,
    style: ToastStyle,
    timeout?: number
  ) {
    this._props = {
      style: style,
      children: message,
      show: true,
      title: title,
      timeout: timeout
    };
  }
  hide() {
    this._props = undefined;
  }
  isShow() {
    return this._props?.show;
  }
  getTitle() {
    return this._props?.title;
  }
  getMessage() {
    return this._props?.children;
  }
  getStyle() {
    return this._props?.style;
  }

  getTimeout() {
    return this._props?.timeout;
  }
}

export const toastStore = new ToastStore();
