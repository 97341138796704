import {
  DataResult,
  State,
  toDataSourceRequestString
} from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridColumnMenuFilter,
  GridDataStateChangeEvent,
  GridNoRecords
} from '@progress/kendo-react-grid';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ColumnMenu } from '../components/columnMenu';
import YesNoDialog from '../components/dialogs/yes-no-dialog';
import {
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../components/grid/grid-tool-bar';
import Toolbar from '../components/page-toolbar';
import { DefaultGridSettings } from '../constants/grid-settings';
import {
  DeleteAssessmentAsync,
  DuplicateAssessmentAsync,
  getAssessments,
  getAssessmentsPendingApproval
} from '../services/assessment';
import { appStore } from '../stores/app-store';
import { toastStore } from '../stores/toast-store';
import { YesNoDialogResult } from '../types/dialog-result';
import { AssessmentCardView } from './assessment-cardview';
import { CustomFilterLanguage } from './filter/custom-filter-language';
import { CardViewDataStateChangeEvent } from './organisations-cardview';
import { NoRecords } from '../components/grid/no-records';
import { ActionsMylibrary } from './command-grid/command-grid-mylibrary';
import { formatDateToDDMMYYYY } from '../utils/date';
import HelpSupport from '../components/help-support';

const initialDataState: State = {
  sort: [{ field: 'title', dir: 'asc' }],
  ...DefaultGridSettings.initialDataState
};

interface AssessmentGridProps {
  isApproval?: boolean;
}

const AssessmentGrid = ({ ...props }: AssessmentGridProps) => {
  const navigate = useNavigate();

  const [dataState, setDataState] = useState<State>(initialDataState);
  const [gridMode, setGridMode] = useState<string>('grid');
  const [selectedId, setSelectedId] = useState<number>(0);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);
  const [assessments, setAssessments] = useState<DataResult>(() => ({
    data: [],
    total: 0
  }));

  const fetchAssessments = async () => {
    try {
      appStore.showLoading();

      if (props.isApproval) {
        const response = await getAssessmentsPendingApproval(
          `${toDataSourceRequestString(dataState)}`
        );

        setAssessments(response);
      } else {
        const response = await getAssessments(
          `${toDataSourceRequestString(dataState)}`
        );

        setAssessments(response);
      }
    } catch (ex) {
      console.error(ex);
    } finally {
      appStore.hideLoading();
    }
  };

  useEffect(() => {
    fetchAssessments();
  }, [dataState]);

  const handleTitleClick = (id: number) => {
    if (props.isApproval) {
      navigate(`../assessment-detail-view/${id}`);
    } else {
      navigate(`../assessment-details/${id}`);
    }
  };

  const onColumnTitleClick = (props: GridCellProps) => (
    <EditCommandCell {...props} />
  );

  const EditCommandCell = (props: GridCellProps) => {
    return (
      <td>
        <span
          className={'link-primary cursor-pointer p-0 text-start'}
          onClick={() => handleTitleClick(props.dataItem.id)}>
          {props.dataItem.name}
        </span>
      </td>
    );
  };

  const onActionRow = (props: GridCellProps) => (
    <ActionsMylibrary
      onCopy={onCopyAssessment}
      onView={(e: any) => navigate(`../assessment-detail-view/${e as any}`)}
      onEdit={handleTitleClick}
      onDelete={onDelete}
      {...props}
    />
  );

  const StatusName = (props: GridCellProps) => {
    if (props.dataItem.status === 'New') {
      return (
        <td>
          <span>Draft</span>
        </td>
      );
    } else if (['Published', 'Rejected'].includes(props.dataItem.status)) {
      return (
        <td>
          <span>Published</span>
        </td>
      );
    } else if (props.dataItem.status === 'Pending') {
      return (
        <td>
          <span>Published/Submitted</span>
        </td>
      );
    } else if (props.dataItem.status === 'Approved') {
      return (
        <td>
          <span>Published/Global Library</span>
        </td>
      );
    } else {
      return (
        <td>
          <span>{props.dataItem.status}</span>
        </td>
      );
    }
  };

  const onAddAssessment = () => {
    navigate('../mylibrary/assessment/new');
  };

  const onEditAssessment = (id: number) => {
    // navigate(`../assessment-details/${id}`);
  };

  const onCopyAssessment = async (id: number) => {
    try {
      appStore.showLoading();

      await DuplicateAssessmentAsync(id);

      toastStore.show(
        'Assessment List',
        <div>Assessment copied to Assessment List.</div>,
        'success'
      );
    } catch (err: any) {
      toastStore.show(
        'Assessment List',
        <div>Failed to copy to Assessment List.</div>,
        'error'
      );
    } finally {
      appStore.hideLoading();
      fetchAssessments();
    }
  };

  const onDelete = (id: number) => {
    onDeleteAssessment(id);
  };

  const onDeleteAssessment = async (id: number) => {
    setSelectedId(id);
    setShowDeleteConfirm(true);
  };

  const onConfirmDelete = async (result: YesNoDialogResult) => {
    if (result === 'yes') {
      try {
        appStore.showLoading();

        await DeleteAssessmentAsync(selectedId);
        fetchAssessments();

        toastStore.show(
          'Assessment List',
          <div>Assessment deleted.</div>,
          'success'
        );
      } catch {
        toastStore.show(
          'Assessment List',
          <div>Failed to delete assessment.</div>,
          'error'
        );
      } finally {
        appStore.hideLoading();
      }
    }
    setShowDeleteConfirm(false);
  };

  const closeDeleteDialog = () => {
    setShowDeleteConfirm(false);
  };

  const gridExportRef = useRef<ExcelExport | null>(null);

  const DateCell = (props: GridCellProps) => {
    return <td>{formatDateToDDMMYYYY(props.dataItem.createdDateUtc, true)}</td>;
  };

  const CellEllipsisAndTooltip = (props: GridCellProps) => {
    return (
      <td title={props.dataItem.language}>
        <div className={'grid-ellipsis-cell'}>{props.dataItem.language}</div>
      </td>
    );
  };

  return (
    <>
      <Toolbar
        title={`${
          props.isApproval ? 'Assessment pending approval' : 'Assessment'
        }`}>
        {!props.isApproval && (
          <>
            <HelpSupport
              title="My Library - Assessment"
              url="https://forum.catalystglobal.com/t/my-library-assessment-library"
            />
            <Button onClick={onAddAssessment} themeColor={'primary'}>
              Add new
            </Button>
          </>
        )}
      </Toolbar>
      <div className={'pt-2'}>
        <GridToolBar
          searchPlaceholder={'Search assessment'}
          columnsToSearch={['name']}
          exportRef={gridExportRef}
          onGridModeChange={(e) => {
            setGridMode(e.gridMode);
          }}
          {...dataState}
          onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
            setDataState(e.dataState);
          }}></GridToolBar>
        <ExcelExport
          data={assessments.data}
          ref={gridExportRef}
          fileName={`assessment.xlsx`}>
          {gridMode === 'grid' && (
            <Grid
              id={'grid-assessment'}
              pageable={DefaultGridSettings.pagerSettings}
              sortable={true}
              data={assessments}
              {...dataState}
              onDataStateChange={(e: GridDataStateChangeEvent) => {
                setDataState(e.dataState);
              }}>
              <Column
                field={'name'}
                title={'Name'}
                columnMenu={ColumnMenu}
                cell={onColumnTitleClick}
              />
              <Column
                field={'createdDateUtc'}
                title={'Created'}
                cell={DateCell}
              />
              <Column
                field={'source'}
                title={'Source'}
                filter={'text'}
                columnMenu={ColumnMenu}
              />
              <Column title={'Status'} field={'status'} cell={StatusName} />
              <Column
                field={'language'}
                title={'Languages'}
                cell={CellEllipsisAndTooltip}
                columnMenu={(props: any) => (
                  <GridColumnMenuFilter
                    {...props}
                    filterUI={CustomFilterLanguage}
                  />
                )}
              />
              {!props.isApproval && (
                <Column title={'Actions'} cell={onActionRow} width={210} />
              )}

              <GridNoRecords>
                <NoRecords />
              </GridNoRecords>
            </Grid>
          )}

          {gridMode === 'card' && (
            <AssessmentCardView
              data={assessments.data}
              onTitlelick={handleTitleClick}
              onCopy={onCopyAssessment}
              onView={handleTitleClick}
              onEdit={onEditAssessment}
              onDelete={onDeleteAssessment}
              total={assessments.total}
              {...dataState}
              onDataStateChange={(e: CardViewDataStateChangeEvent) => {
                setDataState(e.dataState);
              }}
            />
          )}
        </ExcelExport>

        {showDeleteConfirm && (
          <YesNoDialog
            title={'Remove Assessment'}
            onConfirm={onConfirmDelete}
            onClose={closeDeleteDialog}>
            Are you sure you want to delete this assessment?
          </YesNoDialog>
        )}
      </div>
    </>
  );
};
export default AssessmentGrid;
