import { StringifiedType } from './stringified-type';

export type FluentError<T> = { [x in keyof T]?: string[] };

export interface ApiError<T> extends Error {
  type: string;
  title: string;
  status: number;
  traceId: string;
  errors: FluentError<T>;
}

export class BadRequestError<T> extends Error {
  name: string = 'BadRequestError';
  errors?: StringifiedType<T>;

  constructor(errMessage: string, errors?: StringifiedType<T>) {
    super(errMessage);
    this.errors = errors;
  }
}

export class InternalServerError extends Error {
  name: string = 'BadRequestError';
  errors: any;
  constructor(errors?: any) {
    super('Internal server error');
    this.errors = errors;
  }
}

export interface UploaderError {
  title: string;
  message: string;
}
