import useSWR from 'swr';
import { Assessment } from '../types/assessment';
import { useApi } from './use-api';

export const useAssessment = (id: number | string | undefined) => {
  const api = useApi();

  // load the game document. immutable as the document shouldn't change.
  const { data, isLoading, error } = useSWR<Assessment>(
    () => (id ? `assessments/${id}` : null),
    api.fetchers.apiWithAuth,
    { suspense: true }
  );

  if (error) console.log(error);

  return {
    data,
    isLoading,
    isError: error
  };
};
