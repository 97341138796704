import { useEffect, useState } from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { TextArea } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { PrivatePage } from '../../components/private-page';

import { toastStore } from '../../stores/toast-store';
import Avatar from '../../components/avatar';
import {
  GetSupportTicketByIdAsync,
  GetTicketAttachByIdAsync,
  PatchSupportTicketAsync
} from '../../services/support-tickets';
import { SupportTicket, TicketAttachments } from '../../types/support-ticket';
import { formatDateToDDMMYYYY } from '../../utils/date';
import { Browsers } from '../../constants/user';

const SupportTicketDetail = () => {
  const { id } = useParams();
  const statusList = ['New', 'InProgress', 'Closed'];
  const [data, setData] = useState<SupportTicket>({});
  type BrowserKeys = keyof typeof Browsers;

  // fetch environment data function
  const fetchData = async (envId: number) => {
    await GetSupportTicketByIdAsync(envId)
      .then((response) => {
        setData(response);
      })
      .catch((err) => console.error(err));
    await GetTicketAttachByIdAsync(envId)
      .then((response) => {
        setData((prev) => ({
          ...prev,
          supportTicketAttachments: response as TicketAttachments[]
        }));
      })
      .catch((err) => console.error(err));
  };

  // update notes values on change event
  const onChangeNotesEvent = (event: string) => {
    setData({
      ...data,
      notes: event ?? ''
    });
  };

  // update status values on change event
  const onChangeStatusEvent = (event: string) => {
    setData({
      ...data,
      status: event ?? 'New'
    });
  };

  const updateSupportTicket = async () => {
    try {
      if (data) {
        const request = { ...data };
        await PatchSupportTicketAsync(data.id!, request);
        await fetchData(parseInt(id!));
        toastStore.show(
          'Support Tickets',
          <div>Support ticket saved.</div>,
          'success'
        );
      }
    } catch (err) {
      toastStore.show(
        'Support Tickets',
        <div>Failed to save support ticket.</div>,
        'error'
      );
    }
  };

  const getBrowserName = (value: BrowserKeys) => {
    if (Browsers.hasOwnProperty(value)) {
      return Browsers[value];
    } else {
      return '';
    }
  };

  useEffect(() => {
    if (id) {
      fetchData(parseInt(id));
    }
  }, [id]);

  return (
    <PrivatePage
      breadcrumb={[
        { label: 'Dashboard', href: 'dashboard' },
        { label: 'Administration', href: 'administration' },
        { label: 'Support Tickets', href: 'administration/support_tickets' },
        {
          label: 'Support Ticket details',
          href: `administration/support_tickets/${id}`
        }
      ]}
      pageTitle={'Administration'}>
      <Container>
        <Row className={'border-bottom pb-4'}>
          <Col lg={4} md={6} sm={6} className={'d-flex align-items-center'}>
            <h1 className={'m-0'}>{data.title}</h1>
          </Col>
          <Col lg={4} md={6} sm={6}>
            <div className={'d-flex gap-2 justify-content-end'}>
              <Button
                variant={'primary'}
                className={'text-light'}
                onClick={updateSupportTicket}>
                Update
              </Button>
            </div>
          </Col>
        </Row>
        <Row className={'mt-3'}>
          <Col lg={4} md={6}>
            <div
              className={
                'author-card border border-1 bg-light p-2 rounded d-inline-block w-100 mb-2'
              }>
              <Avatar
                name={data.requestedBy}
                img={
                  'https://images.unsplash.com/photo-1657003100487-3ab018a43b67?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDh8NnNNVmpUTFNrZVF8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60'
                }
              />
              <Row className={'mb-2'}>
                <Col>
                  <div>Created on:</div>
                  <div>{formatDateToDDMMYYYY(data.createdAt!, true)}</div>
                </Col>
                <Col>
                  <div>Last updated on:</div>
                  <div>{formatDateToDDMMYYYY(data.modifiedDateUtc!, true)}</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={'text-muted text-sm'}>OS:</div>
                  <div>{data.operatingSystem}</div>
                </Col>
                <Col>
                  <div>Browser:</div>
                  <div>
                    {data.browser
                      ? getBrowserName(data.browser as BrowserKeys)
                      : '-'}
                  </div>
                </Col>
              </Row>
            </div>
            <div
              className={
                'author-card border border-1 bg-light p-2 rounded d-inline-block w-100 mb-2'
              }>
              <Row>
                <Col>
                  <div>Description:</div>
                  <div>{data.description || '-'}</div>
                </Col>
              </Row>
            </div>
            <div
              className={
                'author-card border border-1 bg-light p-2 rounded d-inline-block w-100'
              }>
              <Row>
                <Col>
                  <div>Attachments:</div>
                  {data?.supportTicketAttachments &&
                  data?.supportTicketAttachments?.length > 0
                    ? data.supportTicketAttachments?.map(
                        (item: TicketAttachments, index: number) => (
                          <div key={index}>{item.fileName}</div>
                        )
                      )
                    : '-'}
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={4} md={6}>
            <DropDownList
              style={{
                backgroundColor: 'white',
                width: '100%'
              }}
              defaultValue={'New'}
              value={data.status}
              data={statusList}
              popupSettings={{
                animate: false
              }}
              onChange={(e) => onChangeStatusEvent(e.value)}
            />
            <Label>Notes</Label>
            <TextArea
              id={'notes'}
              maxLength={256}
              value={data?.notes}
              onChange={(e) => {
                onChangeNotesEvent(e.value);
              }}
            />
          </Col>
        </Row>
      </Container>
    </PrivatePage>
  );
};

export default SupportTicketDetail;
