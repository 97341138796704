import { StandardInput } from '../../../components/forms';
import { ComponentType, ReactNode } from 'react';
import { TaskContentEntity } from '../../../types/game-document/';
import { EditorMode } from '../../../types/editor-mode';
import { ComboboxInput } from '../../../components/form-input';
import { TaskContentFullEditor } from './task-content-full-editor';
import RequiredFields from '../../../types/required-fields';

interface TaskContentEditorProps {
  editorMode: EditorMode;
  entity: TaskContentEntity;
  handleEntityChange?: (entityKey: string, value: string) => void;
  children?: ReactNode | undefined;
  requiredFields?: RequiredFields<TaskContentEntity>[];
}

export const TaskContentEditor: ComponentType<TaskContentEditorProps> = ({
  editorMode,
  entity,
  handleEntityChange = () => {},
  requiredFields,
  ...props
}: TaskContentEditorProps) => {
  return (
    <>
      {editorMode === 'basic' && (
        <>
          <StandardInput
            name={'name'}
            label={'Name'}
            value={entity?.name}
            validationMessage={
              requiredFields?.find((item) => item.name === 'name')?.errorMessage
            }
            onChange={(e) =>
              handleEntityChange('name', e.target.value as string)
            }
          />
          <StandardInput
            name={'description'}
            label={'Description'}
            value={entity?.description}
            onChange={(e) =>
              handleEntityChange('description', e.target.value as string)
            }
          />
          <ComboboxInput
            label={'Body type'}
            value={entity?.bodyType}
            name={'bodyType'}
            clearButton={false}
            onChange={(e) =>
              handleEntityChange('bodyType', e.target.value as string)
            }
            data={['HTML', 'Markdown']}
          />
        </>
      )}

      {editorMode === 'full' && (
        <TaskContentFullEditor
          entity={entity}
          handleEntityChange={handleEntityChange}
          requiredFields={requiredFields}
        />
      )}
      {props.children}
    </>
  );
};
