import { KendoGridResult } from '../types/KendoGridResult';
import {
  DeleteAsync,
  GetAllAsync,
  PostAsync,
  GetAsync,
  PatchAsync,
  PutAsync
} from './api';
import {
  OrganisationReportItemResponse,
  OrganisationResponse
} from '../types/responses/organisation-response';
import { State, toDataSourceRequestString } from '@progress/kendo-data-query';
import { AvailabilityCheck } from '../types/responses/email-address-response';

const OrganisationsEndpoint = `organisations`;

/**
 * Gets ALL Organisations encapsulated in a paged payload.
 * @returns all Organisation objects.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */

export const GetOrganisationsAsync = async () =>
  GetAllAsync<OrganisationResponse>(`${OrganisationsEndpoint}`);

/**
 * Gets ALL Organisations encapsulated in a paged payload.
 * @returns all Organisation objects.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */

export const GetGridOrganisationsAsync = async (dataState: State) =>
  GetAllAsync<KendoGridResult<OrganisationResponse>>(
    `${OrganisationsEndpoint}?${toDataSourceRequestString(dataState)}`
  );

/**
 * Gets ALL Organisations encapsulated in a paged payload.
 * @returns all Organisation objects.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */

export const GetReportOrganisationsAsAdminAsync = async () =>
  GetAsync<OrganisationResponse[]>(`${OrganisationsEndpoint}/admin/reports`);

/**
 * Gets ALL Organisations for report purposes.
 * @returns all Organisation objects.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetOrganisationsForReportAsync = async () =>
  GetAsync<OrganisationReportItemResponse[]>(
    `${OrganisationsEndpoint}/reports`
  );

/**
 * Gets ALL Organisations encapsulated in a paged payload.
 * @returns all Organisation objects.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */

export const GetOrganisationsForNetworkAsync = async (dataState: State) =>
  GetAllAsync<KendoGridResult<OrganisationResponse>>(
    `${OrganisationsEndpoint}/network?${toDataSourceRequestString(dataState)}`
  );

/**
 * Applies a POST to the Organisation.
 * requiring only the fields and values that require modification.
 * @param organisation - the Organisation or partial Organisation to update [POST]
 * @returns the created object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/POST
 */
export const PostOrganisationAsync = async (
  organisation: OrganisationResponse
) => PostAsync<OrganisationResponse>('organisations', organisation);

/**
 * Gets the Organisation by id.
 * @param organisationId - the ID of the Organisation to retrieve
 * @returns the Organisation object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetOrganisationAsync = async (organisationId: number) =>
  GetAsync<OrganisationResponse>(`${OrganisationsEndpoint}/${organisationId}`);

/**
 * Gets the Territories Organisation by id.
 * @param organisationId - the ID of the Organisation to retrieve
 * @returns the Organisation object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetTerritoriesOrganisationAsync = async (organisationId: number) =>
  GetAsync<string[]>(
    `${OrganisationsEndpoint}/organisations/${organisationId}/territories`
  );

/**
 * Add the Organisation Territories by completely replacing it.
 * @param organisationId - the ID of the entity to update [PUT]
 * @param territories - the Organisation Territories to update [PUT]
 * @returns the updated Organisation Territories object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PUT
 */
export const AddOrganisationTerritoriesAsync = async (
  organisationId: number,
  territories: string[]
) =>
  PostAsync<string[]>(
    `${OrganisationsEndpoint}/organisations/${organisationId}/territories`,
    territories
  );

/**
 * Updates the Organisation Territories by completely replacing it.
 * @param organisationId - the ID of the entity to update [PUT]
 * @param territories - the Organisation Territories to update [PUT]
 * @returns the updated Organisation Territories object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PUT
 */
export const PutOrganisationTerritoriesAsync = async (
  organisationId: number,
  territories: string[]
) =>
  PutAsync<string[]>(
    `${OrganisationsEndpoint}/organisations/${organisationId}/territories`,
    territories
  );

/**
 * Updates the Organisation by completely replacing it.
 * @param organisationId - the ID of the entity to update [PUT]
 * @param organisation - the Organisation to update [PUT]
 * @returns the updated Organisation object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PUT
 */
export const PatchOrganisationAsync = async (
  organisationId: number,
  organisation: OrganisationResponse
) =>
  PatchAsync<OrganisationResponse>(
    `${OrganisationsEndpoint}/${organisationId}`,
    organisation
  );

/**
 * Applies a PATCH to the Organisation. This can be considered a partial update
 * requiring only the fields and values that require modification.
 * @param organisationId - the ID of the Organisation to PATCH
 * @param organisation - the Organisation or partial Organisation to update [PATCH]
 * @returns the updated Organisation object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PATCH
 */
export const PutOrganisationAsync = async (
  organisationId: number,
  organisation: OrganisationResponse
) =>
  PutAsync<OrganisationResponse>(
    `${OrganisationsEndpoint}/${organisationId}`,
    organisation
  );

/**
 * Deletes a Organisation by id.
 * @param organisationId - the ID of the Organisation to DELETE
 * @returns the deleted Organisation object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/DELETE
 */
export const DeleteOrganisationAsync = async (organisationId: number) =>
  DeleteAsync<OrganisationResponse>(
    `${OrganisationsEndpoint}/${organisationId}`
  );

/**
 * Applies a POST to the organisation email to check if a email is available or not.
 * requiring only the fields and values that require modification.
 */
export const PostOrganisationEmailAvailabilityAsync = async (
  availabilitycheck: AvailabilityCheck
) =>
  PostAsync<boolean>(
    `${OrganisationsEndpoint}/availabilitycheck`,
    availabilitycheck as never
  );

/**
 * Applies a GET to the organisation if any users available or not.
 */
export const GetUsersOnOrganisationCheckAsync = async (
  organisationId: number
) =>
  GetAsync<boolean>(
    `${OrganisationsEndpoint}/${organisationId}/organisationuserscheck`
  );
