import { Button } from '@progress/kendo-react-buttons';
import React, { ComponentType, useContext } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { GameDocumentContext } from '../../contexts/game-document';
import {
  CleanMapIllustrationArea,
  PublishGameDocumentAsync,
  SubmitToGlobalLibraryGameDocumentAsync,
  UpdateGameDocumentAsync
} from '../../utils/game-document';
import {
  GetGameDocumentContentAsync,
  GetGameDocumentLatestAsync
} from '../../services/json-document';
import PageToolbar from '../../components/page-toolbar';
import { toastStore } from '../../stores/toast-store';
import { appStore } from '../../stores/app-store';
import SubmitConfirmation from '../../components/submit-to-global-library';

interface ToolbarProps {
  children?: React.ReactNode;
  showDefaultContent?: boolean;
  title: string;
  notificationTitle?: string;
}

const Toolbar: ComponentType<ToolbarProps> = ({
  showDefaultContent = true,
  notificationTitle,
  ...props
}: ToolbarProps) => {
  const [state, setState] = useContext(GameDocumentContext);
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const [gameStatus, setGameStatus] = React.useState<
    string | 'New || Pending || Approved || Rejected || Published'
  >('');

  const toggleDialog = () => {
    setShowDialog(!showDialog);
  };

  const getLatestDocument = () => {
    if (state.gameId) {
      GetGameDocumentLatestAsync(parseInt(state.gameId.toString())).then(
        (response) => {
          setGameStatus(response.status as string);
        }
      );
    }
  };

  React.useEffect(() => {
    getLatestDocument();
  }, [state.gameId, state.gameDocument?.version]);

  const onSaveDraft = async () => {
    try {
      appStore.showLoading();
      if (!state.isValid) {
        throw Error();
      }
      const cleanedGameDocument = CleanMapIllustrationArea(state.gameDocument!);

      const updatedGameDocument = await UpdateGameDocumentAsync(
        state.gameId!,
        cleanedGameDocument
      );

      setState((state) => ({
        ...state,
        isDirty: false,
        gameDocument: updatedGameDocument
      }));

      toastStore.show(
        `${notificationTitle !== undefined ? notificationTitle : ''}`,
        <div>
          Draft {notificationTitle ? notificationTitle : 'game'} saved.
        </div>,
        'success'
      );
    } catch (err) {
      toastStore.show(
        `${notificationTitle !== undefined ? notificationTitle : ''}`,
        <div>
          Draft {notificationTitle ? notificationTitle : 'game'} failed to save.
          Please try again.
        </div>,
        'error'
      );
    } finally {
      appStore.hideLoading();
    }
  };

  const onReload = async () => {
    try {
      appStore.showLoading();
      let documentLatestResponse = await GetGameDocumentLatestAsync(
        state.gameId!
      );
      if (documentLatestResponse) {
        GetGameDocumentContentAsync(
          state.gameId!,
          documentLatestResponse.id!
        ).then((response) => {
          setState((state) => ({
            ...state,
            isLoaded: true,
            gameDocument: response
          }));
        });
      }
    } catch (err) {
      toastStore.show(
        `${notificationTitle !== undefined ? notificationTitle : ''}`,
        <div>
          {notificationTitle ? notificationTitle : 'Game'} failed to load.
          Please try again.
        </div>,
        'error'
      );
    } finally {
      appStore.hideLoading();
    }
  };

  const onPublish = async () => {
    try {
      appStore.showLoading();
      if (!state.isValid) {
        throw Error();
      }
      let updatedGameDocument = await PublishGameDocumentAsync(
        state.gameId!,
        state.gameDocument!
      );
      setState((state) => ({
        ...state,
        isDirty: false,
        gameDocument: updatedGameDocument
      }));

      getLatestDocument();

      toastStore.show('Game overview', <div>Game published .</div>, 'success');
    } catch (err) {
      toastStore.show(
        'Game overview',
        <div>Game failed to publish. Please try again.</div>,
        'error'
      );
    } finally {
      appStore.hideLoading();
    }
  };

  const onSubmitToGlobal = async () => {
    try {
      appStore.showLoading();
      if (!state.isValid) {
        throw Error();
      }
      let updatedGameDocument = await SubmitToGlobalLibraryGameDocumentAsync(
        state.gameId!,
        state.gameDocument!
      );
      setState((state) => ({
        ...state,
        isDirty: false,
        gameDocument: updatedGameDocument
      }));

      getLatestDocument();

      toastStore.show(
        'Game overview',
        <div>Game submitted and waiting for approval.</div>,
        'success'
      );
    } catch (err) {
      toastStore.show(
        'Game overview',
        <div>Game failed to submit. Please try again.</div>,
        'error'
      );
    } finally {
      appStore.hideLoading();
      toggleDialog();
    }
  };

  return (
    <>
      <PageToolbar {...props}>
        {showDefaultContent && (
          <>
            <code className={'me-1'}>v{state.gameDocument?.version}</code>
            <Button
              onClick={onReload}
              themeColor={'info'}
              className={'me-1'}
              fillMode={'flat'}>
              <span className={'material-symbols-outlined'}>sync</span>
            </Button>
            <Button
              onClick={onSaveDraft}
              disabled={!state.isDirty}
              themeColor={'secondary'}
              className={'me-1'}>
              Save
            </Button>
            {gameStatus === 'Published' && props.title === 'Overview' && (
              <Button
                onClick={toggleDialog}
                themeColor={'primary'}
                className={'me-1'}>
                Submit to Global Library
              </Button>
            )}
            <Button
              onClick={onPublish}
              themeColor={'warning'}
              disabled={['', 'Pending'].includes(gameStatus)}
              className={'me-1'}>
              Publish
            </Button>
          </>
        )}
        {props.children}
      </PageToolbar>

      {showDialog && (
        <Dialog
          width={'50vw'}
          title={
            <span className="fw-bold text-primary">
              Submit to global library
            </span>
          }
          onClose={toggleDialog}>
          <SubmitConfirmation
            toggleDialog={toggleDialog}
            isPendingApproval={gameStatus === 'Pending'}
            onSubmit={onSubmitToGlobal}
          />
        </Dialog>
      )}
    </>
  );
};

export default Toolbar;
