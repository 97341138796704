
export interface MediaProps {
  imageUrl?: string;
}

const MediaCardBackground = ({ imageUrl }: MediaProps) => {
  return (
    <>
      {imageUrl ? (
        <div
          className={'round-card rounded-full '}
          style={{
            width: 90,
            height: 90,
            backgroundImage: `url('${imageUrl}')`
          }}
        />
      ) : (
        <div
          className={
            'rounded-circle bg-primary d-flex justify-content-center align-items-center mt-auto mb-auto'
          }
          style={{ width: 90, height: 90 }}>
          <i className={'bi bi-person text-white font-size-3'} />
        </div>
      )}
    </>
  );
};

export default MediaCardBackground;
