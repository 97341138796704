import { Label } from '@progress/kendo-react-labels';
import CustomColumnContainer, {
  CustomColumnProps
} from './custom-column-container';

interface CustomColumnImageProps extends CustomColumnProps {
  id?: string;
  url?: string;
  maxHeight?: number;
  label?: string;
  onEdit?: (id?: string) => void;
}

const CustomColumnImage = ({
  onEdit = () => {},
  ...props
}: CustomColumnImageProps) => {
  const onEditHandler = (id: string) => {
    onEdit(id);
  };
  return (
    <CustomColumnContainer
      columnClass={props?.columnClass}
      containerClass={props?.containerClass}>
      {props.label && <Label editorId={props.id}>{props.label}</Label>}
      <div className="d-flex w-full p-2">
        <div className={'w-100'}>
          {!props?.url && <span>No image found</span>}
          {props?.url && props?.url !== '' && (
            <img
              height={props?.maxHeight}
              src={props?.url}
              alt={'resource image'}></img>
          )}
        </div>
        <div className="d-flex align-items-center">
          <span
            onClick={() => onEditHandler(props.id!)}
            className={'material-symbols-outlined'}
            style={{ cursor: 'pointer' }}>
            edit
          </span>
        </div>
      </div>
    </CustomColumnContainer>
  );
};

export default CustomColumnImage;
