import { useState } from 'react';

export default function Avatar(props: any) {
  const [userInitials, setUserInitials] = useState('');

  const userInitialsName = () => {
    const matches = props.name.match(/\b(\w)/g);

    let initials = matches.join('');

    setUserInitials(initials);
  };
  return (
    <div
      className={'d-flex flex-items-center gap-2 mt-2 mb-2 ' + props.className}>
      <div
        className={
          'rounded-full border border-3 border-success h-2 w-2 img-fluid img-center img-cover'
        }
        onChange={userInitialsName}
        style={{
          backgroundImage: `url(${props.img})`
        }}>
        {props.img.value ? '' : userInitials}
      </div>
      {props.name}
    </div>
  );
}
