import { Button } from '@progress/kendo-react-buttons';

interface HeroProps {
  onEditClick?: () => void;
  src?: string | ArrayBuffer | null;
  editable?: boolean;
}

export default function Hero({ editable = true, src, onEditClick }: HeroProps) {
  return (
    <div
      className={'hero h-17 d-flex justify-content-end'}
      style={{
        backgroundImage: `url("${src}")`
      }}>
      {editable && (
        <div
          className={
            'hero__content d-flex flex-column align-items-center h-full gap-3'
          }>
          <Button
            className={'hero--btn border border-2 bg-primary'}
            icon={'edit'}
            fillMode={'flat'}
            rounded={'full'}
            size={'small'}
            onClick={onEditClick}
          />
          <h5 className={'hero__content--title fw-bold'}>Edit Header</h5>
        </div>
      )}
    </div>
  );
}
