import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { ThemeColor } from '../../types/theme-color';

export interface ActionCellProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  title: string;
  icon: string | undefined;
  className?: string;
  themeColor: ThemeColor;
  isDisable?: boolean;
}

export const ActionCell = ({
  onClick,
  title,
  icon = 'edit',
  className,
  themeColor = 'primary',
  isDisable = false,
  ...props
}: ActionCellProps) => {
  return (
    <td>
      <div className={'d-flex justify-content-center align-items-center'}>
        <Button
          disabled={isDisable}
          title={title}
          onClick={onClick}
          themeColor={themeColor}
          size={'small'}
          className={'small'}
          fillMode={'flat'}>
          <span className={'material-symbols-outlined ' + className}>
            {icon}
          </span>
        </Button>
      </div>
    </td>
  );
};
