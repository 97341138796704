import { GameDocumentContext } from '../../../contexts/game-document';
import React, { useContext, useEffect, useState } from 'react';
import Toolbar from '../toolbar';
import {
  DeleteTitleAsync,
  UpdateGameDocState,
  UpdateResourceAsync,
  UpdateTitleAsync
} from '../../../utils/game-document';
import { Button } from '@progress/kendo-react-buttons';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CheckboxChangeEvent,
  InputChangeEvent
} from '@progress/kendo-react-inputs';
import { FluidForm } from '../../../components/forms';
import { ResourceEntity, TitleEntity } from '../../../types/game-document/';
import {
  TitleEditor,
  TitleEditorWindow
} from '../../../features/game-document/titles';
import { EntityEditor } from '../../../types/game-document/entity-editor';
import cloneDeep from 'lodash.clonedeep';
import RequiredFields from '../../../types/required-fields';
import {
  ERR_DUPLICATE_NAME_VALUE,
  ERR_INPUT_REQUIRED
} from '../../../constants/text';
import { useGameDocumentResources } from '../../../hooks/use-game-document-resources';

const Title = () => {
  const { titleId } = useParams();
  const [state, setState] = useContext(GameDocumentContext);
  const navigate = useNavigate();

  const [titleResource, setTitleResource] = useState<ResourceEntity>();
  const [summaryResource, setSummaryResource] = useState<ResourceEntity>();
  const [imageResource, setImageResource] = useState<ResourceEntity>();
  const [resources, setResources] = useState<ResourceEntity[]>(
    [] as ResourceEntity[]
  );
  const [requiredFields, setRequiredFields] = useState<
    RequiredFields<TitleEntity>[]
  >([
    { name: 'name', errorMessage: '' },
    { name: 'titleResId', errorMessage: '' }
  ]);

  const gameDocumentFiles = useGameDocumentResources();

  const getEntityById = (titleId: string) =>
    state.gameDocument?.assets.titles?.find((i) => i.id === titleId)!;

  const [entity, setEntity] = useState<TitleEntity>(() =>
    getEntityById(titleId!)
  );

  useEffect(() => {
    setEntity(getEntityById(titleId!));
  }, [state]);

  useEffect(() => {
    // Set the page title.
    document.title = `${entity.name} - ${state.gameDocument?.name}`;
    if (entity) {
      const titleRes = state.gameDocument?.resources.find(
        (title) => title.id === entity.titleResId
      )! as ResourceEntity;
      const summaryRes = state.gameDocument?.resources.find(
        (title) => title.id === entity.summaryResId
      )! as ResourceEntity;
      const imageRes = state.gameDocument?.resources.find(
        (title) => title.id === entity.imageResId
      )! as ResourceEntity;
      setTitleResource(titleRes);
      setSummaryResource(summaryRes);
      setImageResource(imageRes);
      setResources([titleRes, summaryRes, imageRes]);
    }
  }, [state, entity]);

  // handle change when edit fields
  const handleInputChange = (event: InputChangeEvent) => {
    const { name, value } = event.target;
    if (name === 'titleResId' || name === 'summaryResId') {
      setResources(
        resources.map((item) =>
          item.id === entity[name]
            ? { ...item, value: value as string }
            : { ...item }
        )
      );
      const resource = resources.find((item) => item.id === entity[name])!;
      resource.value = value as string;
      UpdateResourceAsync(state.gameDocument!, resource?.id, resource).then(
        (updatedGameDocument) => {
          setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
        }
      );
    } else {
      setRequiredFields((prev) =>
        prev.map((title: RequiredFields<TitleEntity>) => {
          const copyTitle = cloneDeep({ ...title });
          if (title.name === (name as keyof TitleEntity)) {
            const duplicateValueIndex: number =
              state.gameDocument?.assets.titles
                ?.filter((item: TitleEntity) => item.id !== entity.id)
                .findIndex(
                  (item: TitleEntity) =>
                    item.name.toLowerCase() === (value as string)?.toLowerCase()
                ) ?? -1;
            if ((value as string) === '') {
              copyTitle.errorMessage = ERR_INPUT_REQUIRED;
            } else if (duplicateValueIndex !== -1) {
              copyTitle.errorMessage = ERR_DUPLICATE_NAME_VALUE;
            }
          }
          return copyTitle;
        })
      );
      setEntity((prev) => ({
        ...prev,
        [name!]: value
      }));
      UpdateTitleAsync(
        state.gameDocument!,
        titleId!,
        {
          ...entity,
          [name!]: value
        },
        false
      ).then((updatedGameDocument) => {
        setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
      });
    }
  };

  const [entityEditorIsVisible, setEntityEditorIsVisible] =
    useState<boolean>(false);
  const toggleEntityEditor = () => {
    setEntityEditorIsVisible(!entityEditorIsVisible);
  };

  const handleImageSelected = (url: string, size: number, name?: string) => {
    gameDocumentFiles.updateResource(
      entity.imageResId!,
      url,
      size,
      name ?? '',
      (resource) => {
        // update the resources in state...
        // TODO: is this even needed? the game document state is updated which would trigger the effect on line 55...
        setResources((current) =>
          current.map((item) =>
            item.id === entity.imageResId ? resource : item
          )
        );
      }
    );
  };

  const handleEntityEditorSubmit = (
    editorEntity: EntityEditor<TitleEntity>
  ) => {
    UpdateTitleAsync(
      state.gameDocument!,
      editorEntity.entity.id,
      editorEntity.entity
    ).then((updatedGameDocument) => {
      setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
      setEntityEditorIsVisible(false);
    });

    const resource = resources.find((item) => item.id === entity.imageResId)!;
    UpdateResourceAsync(state.gameDocument!, resource?.id, resource).then(
      (updatedGameDocument) => {
        setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
        setResources(
          resources.map((item) =>
            item.id === entity.imageResId ? { ...item, resource } : { ...item }
          )
        );
      }
    );
  };

  const onDeleteEntity = async (entityId: string) => {
    DeleteTitleAsync(state.gameDocument!, entityId).then(
      (updatedGameDocument) => {
        setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
        navigate('../');
      }
    );
  };

  const handleHideTitleChange = (event: CheckboxChangeEvent) => {
    const { name, value } = event.target;
    UpdateTitleAsync(
      state.gameDocument!,
      titleId!,
      {
        ...entity,
        [name!]: value
      },
      false
    ).then((updatedGameDocument) => {
      setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
    });
  };

  return (
    <>
      <Toolbar title={entity?.name}>
        <Button
          onClick={() => onDeleteEntity(entity.id!)}
          themeColor={'error'}
          fillMode={'flat'}>
          <span className="material-symbols-outlined">delete</span>
        </Button>
        <Button
          onClick={toggleEntityEditor}
          themeColor={'success'}
          fillMode={'flat'}>
          <span className="material-symbols-outlined">edit</span>
        </Button>
        {entityEditorIsVisible && (
          <TitleEditorWindow
            toggleDialog={toggleEntityEditor}
            onSubmit={handleEntityEditorSubmit}
            onClose={toggleEntityEditor}
            editorEntity={{ isNew: false, entity: entity }}
            editorResource={[
              { isNew: false, entity: titleResource! },
              { isNew: false, entity: summaryResource! },
              { isNew: false, entity: imageResource! }
            ]}
            editorMode={'basic'}
          />
        )}
      </Toolbar>
      <FluidForm>
        <TitleEditor
          editorMode={'full'}
          entity={entity}
          resources={resources}
          imageResourceVisibility={true}
          handleInputChange={handleInputChange}
          handleImageSelected={handleImageSelected}
          requiredFields={requiredFields}
          handleHideTitleChange={handleHideTitleChange}
        />
      </FluidForm>
    </>
  );
};

export default Title;
