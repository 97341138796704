import { useAuth } from 'react-oidc-context';
import { ComponentPropsWithRef, useEffect, useState } from 'react';
import PopupMenu, { popupMenu as MenuList } from '../../components/popupMenu';
import Logout from '../../components/dialogs/logout';
export interface ProfileProps extends ComponentPropsWithRef<'div'> {
  profileImg: string;
  name?: string;
  updateSelectedMenuItem?: any;
  onDeletePicture?: () => void;
  selectedMenu?: string;
}

const ProfileSide = ({
  profileImg,
  name,
  updateSelectedMenuItem,
  onDeletePicture = () => {},
  selectedMenu,
  ...props
}: ProfileProps) => {
  const auth = useAuth();
  const [showLogoutConfirm, setShowLogoutConfirm] = useState<boolean>(false);
  const [menuList, setMenuList] = useState<MenuList[]>([]);
  const generateLogoMenu = () => {
    let newMenus: MenuList[] = [
      { classIcon: 'edit', textMenu: 'Edit', textClass: '' },
      { classIcon: 'delete', textMenu: 'Delete', textClass: 'text-danger' }
    ];

    setMenuList(newMenus);
  };

  const handleMenuSelected = (id: number, menu: MenuList) => {
    if (menu.textMenu === 'Edit') {
      updateSelectedMenuItem('UploadPhoto');
    } else if (menu.textMenu === 'Delete') {
      onDeletePicture();
    }
  };

  const onLogout = () => {
    setShowLogoutConfirm(true);
  };
  const onConfirmLogout = () => {
    setShowLogoutConfirm(false);
    auth.signoutRedirect();
  };

  useEffect(() => {
    generateLogoMenu();
  }, []);
  return (
    <>
      <div className={'d-flex flex-column h-100 w-100 gap-4'}>
        <div
          className={
            'd-flex align-items-center justify-content-center position-relative'
          }>
          <div
            className={
              'user-avatar border border-4 border-success overflow-hidden bg-white p-0 rounded-circle d-flex align-items-center justify-content-center fs-1'
            }
            style={{
              height: 110,
              width: 110,
              backgroundImage: `url(${profileImg})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center'
            }}></div>
          <div className={'position-absolute top-0 end-0'}>
            <PopupMenu
              id={0}
              menus={menuList}
              onMenuSelected={handleMenuSelected}></PopupMenu>
          </div>
        </div>

        <div className={'d-flex flex-column flex-grow-1 overflow-auto px-3'}>
          <WidgetItem
            id={'widget-item-profile'}
            icon={'account_circle'}
            selectedMenu={selectedMenu === 'Profile'}
            onClick={() => updateSelectedMenuItem('Profile')}>
            Profile
          </WidgetItem>
          <WidgetItem
            id={'widget-item-settings'}
            icon={'settings'}
            selectedMenu={selectedMenu === 'Settings'}
            onClick={() => updateSelectedMenuItem('Settings')}>
            Settings
          </WidgetItem>
          <WidgetItem
            id={'widget-item-support'}
            icon={'notifications'}
            selectedMenu={selectedMenu === 'Notifications'}
            onClick={() => updateSelectedMenuItem('Notifications')}>
            Notifications
          </WidgetItem>
          <WidgetItem
            id={'widget-item-support'}
            icon={'support'}
            selectedMenu={
              selectedMenu === 'Support' || selectedMenu === 'SupportTicket'
            }
            onClick={() => updateSelectedMenuItem('Support')}>
            Support
          </WidgetItem>
        </div>
        <div className={'mt-auto border-top border-2 py-2 px-4'}>
          <WidgetItem
            id={'widget-item-logout'}
            icon={'logout'}
            selectedMenu={selectedMenu === 'Logout'}
            onClick={onLogout}>
            Log out
          </WidgetItem>
        </div>
      </div>
      {showLogoutConfirm && (
        <Logout
          onCancelLogout={() => setShowLogoutConfirm(false)}
          onConfirmLogout={onConfirmLogout}
        />
      )}
    </>
  );
};

export default ProfileSide;

interface WidgetItemProps {
  id?: string;
  icon?: string;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  selectedMenu: boolean;
}

const WidgetItem = ({
  id,
  children,
  icon,
  onClick,
  selectedMenu = false
}: WidgetItemProps) => {
  return (
    <div
      id={id}
      className={['widget-item d-flex align-items-center gap-3 py-2'].join(' ')}
      onClick={onClick}>
      <span
        className={`material-symbols-outlined icon ${
          selectedMenu ? 'link-success' : 'link-dark-blue'
        }`}>
        {icon}
      </span>
      <span
        className={`label cursor-pointer fw-bold ${
          selectedMenu ? 'link-success' : 'link-dark'
        }`}>
        {children}
      </span>
    </div>
  );
};
