import { GameDocumentContext } from '../../../contexts/game-document';
import React, { useContext, useEffect, useState } from 'react';
import Toolbar from '../toolbar';
import {
  DeleteAssessmentAsync,
  UpdateAssessmentAsync,
  UpdateGameDocState
} from '../../../utils/game-document';
import { Button } from '@progress/kendo-react-buttons';
import { useNavigate, useParams } from 'react-router-dom';
import { InputChangeEvent } from '@progress/kendo-react-inputs';
import {
  AssessmentEditor,
  AssessmentEditorWindow
} from '../../../features/game-document/assessments';
import { EntityEditor } from '../../../types/game-document/entity-editor';
import { AssessmentEntity } from '../../../types/game-document/';
import { FluidForm } from '../../../components/forms';

const Assessment = () => {
  const { assessmentId } = useParams();
  const [state, setState] = useContext(GameDocumentContext);
  const navigate = useNavigate();

  const getEntityById = (entityId: string) =>
    state.gameDocument?.assets.assessments?.find((i) => i.id === entityId)!;

  const [entity, setEntity] = useState<AssessmentEntity>(() =>
    getEntityById(assessmentId!)
  );

  useEffect(() => {
    setEntity(getEntityById(assessmentId!));
  }, [state]);

  useEffect(() => {
    // Set the page title.
    document.title = `${entity.name} - ${state.gameDocument?.name}`;
  }, [state, entity]);

  const handleInputChange = (event: InputChangeEvent) => {
    const { name, value } = event.target;
    setEntity((prev) => ({
      ...prev,
      [name!]: value
    }));
    UpdateAssessmentAsync(state.gameDocument!, assessmentId!, {
      ...entity,
      [name!]: value
    }).then((updatedGameDocument) => {
      setState((state) => UpdateGameDocState(state, updatedGameDocument));
    });
  };

  const [entityEditorIsVisible, setEntityEditorIsVisible] =
    useState<boolean>(false);
  const toggleEntityEditor = () => {
    setEntityEditorIsVisible(!entityEditorIsVisible);
  };

  const handleEntityEditorSubmit = (
    editorEntity: EntityEditor<AssessmentEntity>
  ) => {
    UpdateAssessmentAsync(
      state.gameDocument!,
      editorEntity.entity.id,
      editorEntity.entity
    ).then((updatedGameDocument) => {
      setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
      setEntityEditorIsVisible(false);
    });
  };

  const onDeleteEntity = async (entityId: string) => {
    DeleteAssessmentAsync(state.gameDocument!, entityId).then(
      (updatedGameDocument) => {
        setState((state) => UpdateGameDocState(state, updatedGameDocument));
        navigate('../');
      }
    );
  };

  return (
    <>
      <Toolbar title={entity?.name}>
        <Button
          onClick={() => onDeleteEntity(entity.id!)}
          themeColor={'error'}
          fillMode={'flat'}>
          <span className="material-symbols-outlined">delete</span>
        </Button>
        <Button
          onClick={toggleEntityEditor}
          themeColor={'success'}
          fillMode={'flat'}>
          <span className="material-symbols-outlined">edit</span>
        </Button>
        {entityEditorIsVisible && (
          <AssessmentEditorWindow
            toggleDialog={toggleEntityEditor}
            onSubmit={handleEntityEditorSubmit}
            onClose={toggleEntityEditor}
            editorEntity={{ isNew: false, entity: entity }}
            editorMode={'basic'}
            editorResource={[]}
          />
        )}
      </Toolbar>
      <FluidForm>
        <AssessmentEditor
          editorMode={'full'}
          entity={entity}
          handleInputChange={handleInputChange}
          resources={[]} // titleResId data still not inside resources array
        />
      </FluidForm>
    </>
  );
};

export default Assessment;
