import {
  DataResult,
  State,
  toDataSourceRequestString
} from '@progress/kendo-data-query';
import { getter } from '@progress/kendo-react-common';
import { TableSelectionChangeEvent } from '@progress/kendo-react-data-tools';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import {
  GridColumn as Column,
  Grid,
  GridCellProps,
  GridDataStateChangeEvent,
  GridHeaderSelectionChangeEvent,
  getSelectedState
} from '@progress/kendo-react-grid';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ColumnMenu } from '../../components/columnMenu';
import { ActionCell } from '../../components/grid/actions-cell';
import {
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../../components/grid/grid-tool-bar';
import { DefaultGridSettings } from '../../constants/grid-settings';
import {
  DownloadReportTeamAsync,
  GetEventReportTeamAsync
} from '../../services/event-reports';
import { appStore } from '../../stores/app-store';
import { toastStore } from '../../stores/toast-store';
import { getSelectedIds } from '../../utils/report/grid-helper';
import { EventReportHeader } from '../../types/event-report';

interface DataResultGrid extends DataResult {
  selected?: boolean;
}

interface TeamProps {
  summaryHeaderReport: EventReportHeader;
}

export const ReportTeam = ({ ...props }: TeamProps) => {
  const { id } = useParams();
  const initialDataState: State = {
    sort: [{ field: 'name', dir: 'asc' }],
    ...DefaultGridSettings.initialDataState
  };
  const idGetter = getter('id');

  const [dataState, setDataState] = useState<State>(initialDataState);
  const [gridMode, setGridMode] = useState<string>('grid');
  const [dataGrid, setDataGrid] = useState<DataResultGrid>(() => ({
    data: [],
    selected: false,
    total: 0
  }));
  const [selectedState, setSelectedState] = useState<{
    [id: string]: boolean | number[];
  }>({});

  const fetchDataGrid = async () => {
    try {
      appStore.showLoading();
      const response = await GetEventReportTeamAsync(
        id!,
        `${toDataSourceRequestString(dataState)}`
      );
      const newData = {
        data: response.data,
        total: response.total,
        selected: false
      };
      setDataGrid(newData);
    } catch (ex) {
      console.error(ex);
    } finally {
      appStore.hideLoading();
    }
  };

  const onActionRow = (props: GridCellProps) => {
    return (
      <>
        <ActionCell
          title={''}
          icon={'file_download'}
          themeColor={'primary'}
          className={'fs-2'}
          onClick={() => onDownloadRowToPdf(props.dataItem.id)}
        />
      </>
    );
  };

  const onDownloadRowToPdf = async (teamId: number) => {
    try {
      appStore.showLoading();
      await DownloadReportTeamAsync(
        parseInt(id!),
        props.summaryHeaderReport.name!,
        [teamId]
      );

      toastStore.show('Team Report', <div>Report downloaded.</div>, 'success');
    } catch (err: any) {
      toastStore.show(
        'Team Report',
        <div>Failed to download report.</div>,
        'error'
      );
    } finally {
      appStore.hideLoading();
    }
  };

  const onDownloadToPdfFile = async () => {
    try {
      appStore.showLoading();
      const ids = getSelectedIds(selectedState);
      await DownloadReportTeamAsync(
        parseInt(id!),
        props.summaryHeaderReport.name!,
        ids
      );

      toastStore.show('Team Report', <div>Report downloaded.</div>, 'success');
    } catch (err: any) {
      toastStore.show(
        'Team Report',
        <div>Failed to download report.</div>,
        'error'
      );
    } finally {
      appStore.hideLoading();
    }
  };

  const onSelectionChange = useCallback(
    (event: TableSelectionChangeEvent<any>) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: 'id'
      });
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );

  const onHeaderSelectionChange = useCallback(
    (event: GridHeaderSelectionChangeEvent) => {
      const checkboxElement = event.syntheticEvent.currentTarget;
      const checked = checkboxElement.checked;
      const newSelectedState: any = {};

      event.dataItems.forEach((item) => {
        newSelectedState[idGetter(item)] = checked;
      });
      setSelectedState(newSelectedState);
    },
    []
  );

  const gridExportRef = useRef<ExcelExport | null>(null);

  useEffect(() => {
    fetchDataGrid();
  }, []);

  return (
    <>
      <div className={'pt-2'}>
        <GridToolBar
          searchPlaceholder={'Search team'}
          columnsToSearch={['name']}
          exportRef={gridExportRef}
          showGridMode={false}
          showCardMode={false}
          showPDFExport={true}
          handleExportToPdf={onDownloadToPdfFile}
          isDisablePdfButton={getSelectedIds(selectedState).length === 0}
          {...dataState}
          onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
            setDataState(e.dataState);
          }}></GridToolBar>
        <ExcelExport
          data={dataGrid.data}
          ref={gridExportRef}
          fileName={`team.xlsx`}>
          {gridMode === 'grid' && (
            <Grid
              id={'grid-team'}
              pageable={DefaultGridSettings.pagerSettings}
              sortable={true}
              data={dataGrid.data.map((item) => ({
                ...item,
                ['selected']: selectedState[idGetter(item)]
              }))}
              selectedField={'selected'}
              selectable={{
                enabled: true,
                drag: false,
                cell: false,
                mode: 'multiple'
              }}
              onSelectionChange={onSelectionChange}
              onHeaderSelectionChange={onHeaderSelectionChange}
              {...dataState}
              onDataStateChange={(e: GridDataStateChangeEvent) => {
                setDataState(e.dataState);
              }}>
              <Column
                field={'selected'}
                width="50px"
                headerSelectionValue={
                  dataGrid.data.findIndex(
                    (item) => !selectedState[idGetter(item)]
                  ) === -1
                }
              />
              <Column
                field={'name'}
                title={'Team Name'}
                columnMenu={ColumnMenu}
              />
              <Column
                field={'groupName'}
                title={'Group Name'}
                filter={'text'}
                columnMenu={ColumnMenu}
              />
              <Column
                field={'totalPlayers'}
                title={'Total Player'}
                columnMenu={ColumnMenu}
              />
              <Column
                field={'activity'}
                title={'Game'}
                columnMenu={ColumnMenu}
              />
              <Column title={''} cell={onActionRow} width={50} />
            </Grid>
          )}
        </ExcelExport>
      </div>
    </>
  );
};
