import {
  Event,
  EventFeedback,
  EventPlaylist,
  EventPlaylistFacilitator,
  EventPlaylistLocation,
  ExpandedEvent,
  MediaLogo
} from '../types/event';
import { KendoGridResult } from '../types/KendoGridResult';
import {
  DeleteAsync,
  GetAllAsync,
  GetAsync,
  PatchAsync,
  PostAsync,
  PutAsync
} from './api';

const EventsEndpoint = `events`;

export const GetEvents = async (params?: string) =>
  GetAllAsync<KendoGridResult<Event>>(`${EventsEndpoint}/?${params}`);

export const GetEventsAsAdmin = async (params?: string) =>
  GetAllAsync<KendoGridResult<Event>>(`admin/${EventsEndpoint}/?${params}`);

export const GetExpandedEventsAsAdmin = async (params?: string) =>
  GetAllAsync<ExpandedEvent>(`admin/${EventsEndpoint}/?${params}`);

export const GetEventById = async (eventId: number) =>
  GetAsync<Event>(`${EventsEndpoint}/${eventId}`);

export const GetEventPlaylists = async (eventId: number) =>
  GetAsync<KendoGridResult<EventPlaylist>>(
    `${EventsEndpoint}/${eventId}/playlists`
  );

export const GetEventFeedbacks = async (eventId: number) =>
  GetAsync<KendoGridResult<EventFeedback>>(
    `${EventsEndpoint}/${eventId}/feedbacks`
  );

export const GetEventPlaylistFacilitators = async (
  eventId: number,
  playlistId: number
) =>
  GetAsync<KendoGridResult<EventPlaylistFacilitator>>(
    `${EventsEndpoint}/${eventId}/playlists/${playlistId}/facilitators`
  );

export const GetEventPlaylistLocations = async (
  eventId: number,
  playlistId: number
) =>
  GetAsync<KendoGridResult<EventPlaylistLocation>>(
    `${EventsEndpoint}/${eventId}/playlists/${playlistId}/locations`
  );

export const PostEventAsync = async (event: Event) =>
  PostAsync<Event>(`events`, event);

export const PatchEventAsync = async (id: number, event: Event) =>
  PatchAsync<Event>(`events/${id}`, event);

export const PutEventAsync = async (id: number, event: Event) =>
  PutAsync<Event>(`events/${id}`, event);

export const PostEventFeedbackAsync = async (
  eventId: number,
  eventFeedback: EventFeedback
) => PostAsync<EventFeedback>(`events/${eventId}/feedbacks`, eventFeedback);

export const PathcEventFeedbackAsync = async (
  eventId: number,
  eventFeedbackId: number,
  eventFeedback: EventFeedback
) =>
  PatchAsync<EventFeedback>(
    `events/${eventId}/feedbacks/${eventFeedbackId}`,
    eventFeedback
  );

export const DeleteEventFeedbackAsync = async (
  eventId: number,
  eventFeedbackId: number
) =>
  await DeleteAsync<EventFeedback>(
    `events/${eventId}/feedbacks/${eventFeedbackId}`
  );

export const PostEventPlaylistAsync = async (
  eventId: number,
  eventPlaylist: EventPlaylist
) => PostAsync<EventPlaylist>(`events/${eventId}/playlists`, eventPlaylist);

export const DeleteEventPlaylistAsync = async (
  eventId: number,
  playlistId: number
) => DeleteAsync<EventPlaylist>(`events/${eventId}/playlists/${playlistId}`);

export const PatchEventPlaylistAsync = async (
  eventId: number,
  playlistId: number,
  eventPlaylist: EventPlaylist
) =>
  PatchAsync<EventPlaylist>(
    `events/${eventId}/playlists/${playlistId}`,
    eventPlaylist
  );

export const PostEventPlaylistFacilitatorAsync = async (
  eventId: number,
  playlistId: number,
  facilitator: EventPlaylistFacilitator
) =>
  PostAsync<EventPlaylistFacilitator>(
    `events/${eventId}/playlists/${playlistId}/facilitators`,
    facilitator
  );

export const PatchEventPlaylistFacilitatorAsync = async (
  eventId: number,
  playlistId: number,
  facilitatorId: number,
  facilitator: EventPlaylistFacilitator
) =>
  PatchAsync<EventPlaylistFacilitator>(
    `events/${eventId}/playlists/${playlistId}/facilitators/${facilitatorId}`,
    facilitator
  );

export const DeleteEventPlaylistFacilitatorAsync = async (
  eventId: number,
  playlistId: number,
  facilitatorId: number,
  facilitator: EventPlaylistFacilitator
) =>
  DeleteAsync<EventPlaylistFacilitator>(
    `events/${eventId}/playlists/${playlistId}/facilitators/${facilitatorId}`,
    facilitator
  );

export const PostEventPlaylistLocationAsync = async (
  eventId: number,
  playlistId: number,
  location: EventPlaylistLocation
) =>
  PostAsync<EventPlaylistLocation>(
    `events/${eventId}/playlists/${playlistId}/locations`,
    location
  );

export const PatchEventPlaylistLocationAsync = async (
  eventId: number,
  playlistId: number,
  locationId: number,
  location: EventPlaylistLocation
) =>
  PatchAsync<EventPlaylistLocation>(
    `events/${eventId}/playlists/${playlistId}/locations/${locationId}`,
    location
  );

export const DeleteEventPlaylistLocationAsync = async (
  eventId: number,
  playlistId: number,
  locationId: number,
  location: EventPlaylistLocation
) =>
  DeleteAsync<EventPlaylistLocation>(
    `events/${eventId}/playlists/${playlistId}/locations/${locationId}`,
    location
  );
export const DeleteEventAsync = async (eventId: number) =>
  DeleteAsync<Event>(`events/${eventId}`);

//upload image for post image

export const PostImageAsync = async (media: MediaLogo) =>
  PostAsync<MediaLogo>(`media`, media);

export const GetEventImageAsync = async (mediaId: number) => {
  GetAsync<MediaLogo>(`media/${mediaId}`);
};
