import { GameDocumentContext } from '../../../contexts/game-document';
import React, { useContext, useEffect, useState } from 'react';
import Toolbar from '../toolbar';
import {
  CopyTimerAsync,
  DeleteTimerAsync,
  SaveTimerEntityEditorAsync,
  UpdateGameDocState
} from '../../../utils/game-document';

import { Button } from '@progress/kendo-react-buttons';
import { process, State } from '@progress/kendo-data-query';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridNoRecords
} from '@progress/kendo-react-grid';
import { LinkCell } from '../../../components/grid';
import { ResourceEntity, TimerEntity } from '../../../types/game-document/';
import { TimerEditorWindow } from '../../../features/game-document/timers';
import { EntityEditor } from '../../../types/game-document/entity-editor';
import EditDeleteCloneCell from '../../../components/grid/edit-delete-clone-cell';
import {
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../../../components/grid/grid-tool-bar';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { NoRecords } from '../../../components/grid/no-records';
import { DefaultGridSettings } from '../../../constants/grid-settings';
import { uuid } from '../../../types/common-helper';
import { ColumnMenu } from '../../../components/columnMenu';
import { useSessionStorage } from 'usehooks-ts';

const initialDataState: State = {
  sort: [{ field: 'name', dir: 'asc' }],
  ...DefaultGridSettings.initialDataState
};

const Timers = () => {
  const [state, setState] = useContext(GameDocumentContext);
  const [dataState, setDataState] = useSessionStorage<State>(
    'datastate-timers',
    initialDataState
  );
  const [resourceEditorValue, setResourceEditorValue] =
    useState<EntityEditor<ResourceEntity>[]>();
  const [entityEditorValue, setEntityEditorValue] =
    useState<EntityEditor<TimerEntity>>();
  const [entityEditorIsVisible, setEntityEditorIsVisible] =
    useState<boolean>(false);
  const toggleEntityEditor = () => {
    setEntityEditorIsVisible(!entityEditorIsVisible);
  };

  const titleGuid = uuid();

  const entity: TimerEntity = {
    id: '',
    name: '',
    description: '',
    titleResId: titleGuid,
    direction: 'down',
    repeats: false
  };

  const titleResource: ResourceEntity = {
    id: titleGuid,
    name: 'Title',
    description: '',
    type: 'text',
    value: ''
  };

  useEffect(() => {
    // Set the page timer.
    document.title = `Timers - ${state.gameDocument?.name}`;
  }, [state]);

  const LinkedNameCell = (props: GridCellProps) => (
    <LinkCell to={props.dataItem['id'].toString()} {...props} />
  );

  const DeleteCell = (props: GridCellProps) => (
    <EditDeleteCloneCell
      onEditClick={() => onEditEntity(props.dataItem['id'])}
      onCloneClick={() => onCloneEntity(props.dataItem['id'])}
      onDeleteClick={() => onDeleteEntity(props.dataItem['id'])}
      {...props}
    />
  );

  const handleEntityEditorSubmit = (
    editorEntity: EntityEditor<TimerEntity>,
    resourceEntity: EntityEditor<ResourceEntity>[]
  ) => {
    SaveTimerEntityEditorAsync(
      state?.gameDocument!,
      editorEntity,
      resourceEntity
    ).then((response) => {
      if (response) {
        setState((prev) => UpdateGameDocState(prev, response));
      }
      toggleEntityEditor();
    });
  };

  const onAddEntity = () => {
    setEntityEditorValue({
      isNew: true,
      entity
    });

    setResourceEditorValue([
      {
        isNew: true,
        entity: titleResource
      }
    ]);

    toggleEntityEditor();
  };

  const onEditEntity = (entityId: string) => {
    const editedEntity = state.gameDocument?.assets.timers?.find(
      (i) => i.id === entityId
    )!;

    setEntityEditorValue({
      isNew: false,
      entity: editedEntity
    });
    setResourceEditorValue([
      {
        isNew: true,
        entity: state.gameDocument?.resources.find(
          (item) => item.id === editedEntity.titleResId
        ) as ResourceEntity
      }
    ]);

    toggleEntityEditor();
  };

  const onCloneEntity = (entityId: string) => {
    CopyTimerAsync(state.gameDocument!, entityId).then(
      (updatedGameDocument) => {
        setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
      }
    );
  };

  const onDeleteEntity = async (entityId: string) => {
    DeleteTimerAsync(state.gameDocument!, entityId).then(
      (updatedGameDocument) => {
        setState((state) => UpdateGameDocState(state, updatedGameDocument));
      }
    );
  };

  const gridExportRef = React.useRef<ExcelExport | null>(null);

  return (
    <>
      <Toolbar title={'Timers'}>
        <Button onClick={onAddEntity} themeColor={'primary'}>
          Add timer
        </Button>

        {entityEditorIsVisible && (
          <TimerEditorWindow
            toggleDialog={toggleEntityEditor}
            onSubmit={handleEntityEditorSubmit}
            onClose={toggleEntityEditor}
            editorResource={resourceEditorValue!}
            editorEntity={entityEditorValue!}
            editorMode={'basic'}
          />
        )}
      </Toolbar>

      <div className={'pt-2'}>
        <GridToolBar
          searchPlaceholder={'Search timers'}
          columnsToSearch={['name', 'description']}
          showCardMode={false}
          exportRef={gridExportRef}
          {...dataState}
          onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
            setDataState(e.dataState);
          }}
        />
        <ExcelExport
          data={state.gameDocument?.assets.timers ?? []}
          ref={gridExportRef}
          fileName={`${state.gameDocument?.name} timers.xlsx`}>
          <Grid
            pageable={DefaultGridSettings.pagerSettings}
            sortable={true}
            className={'cg-grid3'}
            data={process(state.gameDocument?.assets.timers ?? [], dataState)}
            {...dataState}
            onDataStateChange={(e: GridDataStateChangeEvent) => {
              setDataState(e.dataState);
            }}>
            <Column
              field={'name'}
              title={'Name'}
              columnMenu={ColumnMenu}
              cell={LinkedNameCell}
            />
            <Column
              field={'description'}
              columnMenu={ColumnMenu}
              title={'Description'}
            />

            <Column cell={DeleteCell} />
            <GridNoRecords>
              <NoRecords />
            </GridNoRecords>
          </Grid>
        </ExcelExport>
      </div>
    </>
  );
};

export default Timers;
