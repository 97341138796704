import { useEffect } from 'react';
import { PrivatePage } from '../../../components/private-page';
import { ctGameDefault, ctGameTitle } from '../../../constants/text';
import FeedbackGrid from '../../../features/feedback-grid';

export default function FeedbackApproval() {
  useEffect(() => {
    document.title = ctGameTitle + ' - Feedback Pending Approval';

    return () => {
      // return default value when component unmounts
      document.title = ctGameDefault;
    };
  }, []);

  return (
    <PrivatePage
      breadcrumb={[
        { label: 'Dashboard', href: 'dashboard' },
        { label: 'Administration', href: '../administration' },

        {
          label: ' Pending approval',
          href: '../administration'
        },
        {
          label: ' Feedback approval',
          href: 'administration/feedbacks/pending'
        }
      ]}
      pageTitle={'Administration'}>
      <FeedbackGrid isApproval={true} />
    </PrivatePage>
  );
}
