import { GameDocument } from '../../../types/game-document';
import { AssessmentEntity } from '../../../types/game-document/';
import { uuid } from '../../../types/common-helper';
import { GetNextAssetNameAsync, MergeAssets } from './index';

/**
 * Adds a new Assessment to the Game document.
 * @param gameDocument - The Game Document to modify
 * @param name - The Name of the new Assessment
 * @param description - The Description for the new Assessment
 * @returns The updated Game Document
 */
export const AddAssessmentAsync = async (
  gameDocument: GameDocument,
  name: string,
  description: string
) => {
  let assessments = gameDocument.assets.assessments ?? [];
  let assessmentName = await GetNextAssetNameAsync(assessments, name);
  assessments.push({ id: uuid(), name: assessmentName, description });
  return MergeAssets(gameDocument, assessments, 'assessments');
};

/**
 * Deletes the identified Assessment from the Game Document.
 * @param gameDocument - The Game Document to modify
 * @param assessmentId - The ID of the Assessment to delete
 * @returns The updated Game Document
 */
export const DeleteAssessmentAsync = async (
  gameDocument: GameDocument,
  assessmentId: string
) => {
  let assessments = gameDocument.assets.assessments ?? [];
  let assessmentIndex = assessments.findIndex((i) => i.id === assessmentId)!;
  assessments.splice(assessmentIndex, 1);
  return MergeAssets(gameDocument, assessments, 'assessments');
};

/**
 * Updates the identified Assessment in the Game Document.
 * @param gameDocument - The Game Document to modify
 * @param assessmentId - The ID of the Assessment to update
 * @param assessment - The updated Assessment
 * @constructor
 */
export const UpdateAssessmentAsync = async (
  gameDocument: GameDocument,
  assessmentId: string,
  assessment: AssessmentEntity
) => {
  let assessments = gameDocument.assets.assessments ?? [];
  let assessmentIndex = assessments.findIndex((i) => i.id === assessmentId)!;
  assessment.name = await GetNextAssetNameAsync(
    assessments,
    assessment.name,
    assessment.id
  );
  assessments[assessmentIndex] = assessment;
  return MergeAssets(gameDocument, assessments, 'assessments');
};

/**
 * Create a copy of the Assessment in the Game document.
 * @param gameDocument - The Game Document to modify
 * @param assessmentId - The ID of the Assessment to copy
 * @returns The updated Game Document
 */
export const CopyAssessmentAsync = async (
  gameDocument: GameDocument,
  assessmentId: string
) => {
  let assessments = gameDocument.assets.assessments ?? [];
  let assessmentIndex = assessments.findIndex((i) => i.id === assessmentId)!;
  let assessmentCopy = { ...assessments[assessmentIndex] };
  assessmentCopy.id = uuid();
  assessmentCopy.name += '-copy';
  assessmentCopy.name = await GetNextAssetNameAsync(
    assessments,
    assessmentCopy.name,
    assessmentCopy.id
  );
  assessments.push(assessmentCopy);
  return MergeAssets(gameDocument, assessments, 'assessments');
};
