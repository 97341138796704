import { Col, Container, Row } from 'react-bootstrap';

export const TermsOfUse = () => {
  return (
    <Container>
      <Row>
        <Col>
          <h1>Terms of Use</h1>
          <p className="lead">
            Go Team is owned and operated by Catalyst Global Limited. The Sites
            (as defined below) are offered to you, the customer, by Catalyst
            Global. Your use of any of the Sites and/or your acceptance of these
            Site Terms &amp; Conditions, including our Privacy and Security
            Statement constitutes your agreement to all such terms, conditions
            and statements pertaining to the Sites. If you do not agree with
            these Site Terms and Conditions you are not authorized to use the
            Site and should leave the Site immediately. We reserve the right to
            amend these Site Terms &amp; Conditions from time to time without
            notice to you and at our sole discretion. It is your responsibility
            to review this page for updates which shall come into effect once
            posted. The Sites are made available by Catalyst Global and its
            licensees, subsidiaries, affiliates, parent companies and any of
            their related businesses are referred to in these Site Terms &amp;
            Conditions as 'Catalyst Team Building', 'Catalyst Global', 'we',
            'us' or 'our'.
          </p>
          <h3>Definitions</h3>
          <p>In this Agreement, unless the contrary intention appears:</p>
          <ul>
            <li>
              'Intellectual Property Rights' means copyright, patents,
              trademarks, and other statutory rights and all common law or
              equitable rights protecting business or personal reputation or
              confidential information;
            </li>
            <li>'Site' means any of the Sites;</li>
            <li>
              'Sites' means the internet sites found at{' '}
              <a href="https://www.catalystglobal.com" target="_blank">
                www.catalystglobal.com
              </a>
              , goteam.guru,{' '}
              <a href="https://www.catalystteambuilding.co.uk/" target="_blank">
                www.catalystteambuilding.co.uk
              </a>{' '}
              and includes all webpages attached thereto and such other websites
              as we incorporate.
            </li>
          </ul>
          <h3>Rights</h3>
          <p>
            All rights in all material and content (including, but not limited
            to, Intellectual Property Rights, text, images, web pages, sound,
            software (including, code, interface and website structure) and
            video, and the look and feel, design and compilation thereof)
            accessed at or comprising this Site are owned by us or our
            licensors. You acknowledge and agree that you are permitted to use
            the material and/or content only as set out in these Site Terms
            &amp; Conditions or as otherwise expressly authorised in writing by
            us or our licensors, and that you may not otherwise copy, reproduce,
            transmit, publicly perform, distribute, commercially exploit, adapt,
            translate, modify, bundle, merge, share or make available to any
            person, or create derivative works of such material or content.
          </p>
          <h3>Intellectual Property</h3>
          <p>
            We are the owner and/or permitted to use of all Intellectual
            Property Rights (including without restriction trademarks, service
            marks, patents, copyrights, database rights and all other
            Intellectual Property Rights) appearing on or contained within the
            Site, unless otherwise indicated. You acknowledge and agree that use
            of the Site does not grant to you any right of use or interest in
            any Intellectual Property Rights in the Site. Except as provided in
            these Site Terms &amp; Conditions, any use or reproduction of such
            Intellectual Property Rights by you is prohibited and to do so is a
            breach of these terms and may infringe the Intellectual Property
            Rights of third parties or our Intellectual Property Rights.
          </p>
          <h3>Copying</h3>
          <p>
            You may view this Site and print hard copies of content, information
            or material appearing on the Site solely for your lawful, personal,
            non-commercial use. You agree not to copy, modify, download,
            reproduce, transmit, publicly perform, distribute, adapt, translate,
            modify, bundle, merge, store, sell, share or make available to any
            person, any such content, information or material, or use it for any
            commercial purposes. All other rights are reserved.
          </p>
          <h3>Your conduct</h3>
          <p>You must not, and must not attempt to:</p>
          <p>
            3(a) Use the Site in any way that causes, or is likely to cause, the
            Site or access to it to be interrupted, damaged, rendered less
            efficient or such that the effectiveness or functionality of the
            Site is in any way impaired;
          </p>
          <p>
            3(b) Authorise any other person to do any act which would, if that
            act were to be done by you, infringe any Intellectual Property
            Rights of Catalyst Global or its licensors;
          </p>
          <p>
            (c) Reverse engineer, decompile, disassemble, modify, translate, or
            otherwise uncover the source code of any software application
            forming part of the Site;
          </p>
          <p>
            (d) Capture or copy any software on the Site for any reason
            whatsoever (temporary copies for facilitating a technical computing
            process excepted);
          </p>
          <p>
            (e) Hack, infiltrate or otherwise do anything which may compromise
            the Site;
          </p>
          <p>
            (f) Create liability for us or cause us to lose (in whole or in
            part) the services of our ISPs or other suppliers;
          </p>
          <p>
            (g) Contain any computer viruses, macro viruses, Trojan horses,
            worms or anything else designed to interfere with, interrupt or
            disrupt the normal operating procedures of a computer or to
            surreptitiously intercept, access without authority or expropriate
            any system, data or personal information;
          </p>
          <p>
            (h) Prevent or restrict us from complying with any applicable
            industry code or Court order.
          </p>
          <p>
            You understand that you, and not Catalyst Global nor a licensee and,
            are responsible for all electronic communications and content sent
            from your computer to us and you must use the Site for lawful
            purposes only.
          </p>
          <p>Further, you must not do any of the following:</p>
          <p>
            1. Use the Site or any of Our Services for any improper, unlawful or
            immoral purpose, causing any nuisance by your use of Our Services or
            causing the operation of Our Services to be jeopardised or impaired;
          </p>
          <p>
            2. Use the Site or any of Our Services to create, host or transmit
            (whether in a live chat room, on a bulletin board or otherwise) any
            defamatory, offensive, or obscene material or engaging in activities
            which would cause offence to others on grounds of race, religion,
            creed or sex;
          </p>
          <p>
            3. Use the Site or any of Our Services to harm, or attempt to harm,
            persons (including persons under the age of 18 years) in any way;
          </p>
          <p>
            4. Use the Site or any of Our Services to create, host or transmit
            any material that threatens or encourages bodily harm or the
            destruction of property or would constitute a criminal offence or
            give rise to civil liability;
          </p>
          <p>
            5. Use the Site or any of our Services to create, host or transmit
            material which infringes the Intellectual Property Rights (including
            without restriction copyright, trademark, patent, trade secret or
            other intellectual property rights) of any other party;
          </p>
          <p>
            6. Use the Site or any of our Services to create, host or transmit
            unsolicited advertising material to other users;
          </p>
          <p>
            7. Use the Site or any of our Services to create, host or transmit
            any material that harasses another person; defame, abuse, harass,
            stalk, threaten, or otherwise violate the legal rights (such as
            rights of privacy and publicity) of others, or use the Site or any
            of Our Services to make fraudulent offers to sell or buy products,
            items or services to any person;
          </p>
          <p>
            8. Add, removing or modifying identifying network header information
            in an effort to deceive or mislead;
          </p>
          <p>
            9. Use the Site or any of Our Services to access, or to attempt to
            access, the accounts of others or to penetrate, or attempt to
            penetrate, our or a third party's security measures, computer
            software, hardware, electronic communication system, or
            telecommunications systems;
          </p>
          <p>
            10. Use the Site or any of Our Services to collect, or attempt to
            collect, personal information about third parties without their
            knowledge or consent or to engage in screen scraping, database
            scraping or any other activity with the purpose of obtaining lists
            of users or other data;
          </p>
          <p>
            11. Use the Site or any of Our Services for any activity which
            adversely affects the ability of other people or systems to use the
            Site or any of Our Services, or the Internet generally, including
            the uploading of files that contain viruses, corrupted files, or any
            other similar software or programs that may damage the operation of
            another's computer;
          </p>
          <p>
            12. Resell, repurposing or redistributing any part of the Site or
            any of Our Services without our prior written consent;
          </p>
          <p>
            13. At any time while you are using the Site or any of Our Services,
            impersonate any person or entity or use or provide a false name or a
            name that you are not authorised to use.
          </p>
          <p>
            14. Use the Site or any of Our Services for fraudulent purposes or
            to send, use or reuse any material that is abusive, is otherwise
            injurious to third parties or which consists of or contains software
            viruses, political campaigning, commercial solicitation, chain
            letters, mass mailings or any 'spam', or to cause annoyance,
            inconvenience or needless anxiety.
          </p>
          <p>
            This list only serves to provide examples of misuse of the Site and
            Our Services and is not intended to be an exclusive list of
            unauthorised conduct. If you breach any of these requirements or if
            we determine in our sole discretion that your conduct constitutes
            misuse of the Site or any of Our Services, we may restrict, suspend
            or terminate your access to the Site and any of Our Services without
            first providing notice to you. All users hereby agree to comply with
            all local rules regarding on-line conduct and acceptable content.
            Users also agree to comply with any applicable rules regarding the
            export of any data from any country.
          </p>
          <h3>Photographs, testimonials, case studies and other</h3>
          <p>
            Users authorised by Catalyst Global or its licensees may contribute
            photographs, testimonials, case studies and other content to the
            Catalyst websites and any user of the site may send communications;
            and submit suggestions, ideas, comments, questions, or other
            information, as long as the content is not illegal, obscene,
            abusive, threatening, defamatory, invasive of privacy, infringing of
            Intellectual Property. Rights, or otherwise injurious to third
            parties, or objectionable and does not consist of or contain
            software viruses, political campaigning, commercial solicitation,
            chain letters, mass mailings, or any form of 'spam'. You may not use
            a false e-mail address, impersonate any person or entity, or
            otherwise mislead as to the origin of any content or your identity.
            We reserve the right (but not the obligation) to remove or edit any
            content at any time in our sole discretion and without first
            providing notice to you.
          </p>
          <p>
            If you do post content or submit material to the Site, and unless we
            indicate otherwise, you grant to us a non-exclusive, royalty-free,
            perpetual, irrevocable, and fully sub licensable (through multiple
            tiers) right to use, reproduce, modify, adapt, publish, translate,
            create derivative works from, distribute, and display such content
            and/or material throughout the world in any media now known or not
            currently known but which becomes known. You grant us and our sub
            licensees the right to use the name that you submit in connection
            with any such content, if we elect to do so. You agree to waive your
            right to be identified as the author of such content and your right
            to object to derogatory treatment of such content. You also waive or
            consent to us engaging in conduct which infringes any moral rights
            you have in your content and warrant to us that a license and
            consent in the same terms has been obtained from any third party who
            owns copyright in or was a creator of all or part of such content.
            You agree to perform all further acts necessary to perfect any of
            the above rights granted by you to us including the execution of
            deeds and documents, at our request.
          </p>
          <p>You represent and warrant that:</p>
          <p>
            (a) You own or otherwise control all of the rights to the content or
            material that you post to the Site;
          </p>
          <p>(b) As at the date that such content or material is submitted:</p>
          <p>(i) the content and material is accurate;</p>
          <p>
            (ii) use of the content and material you supply does not breach any
            applicable Catalyst Global policies or guidelines or these Site
            Terms and Conditions
          </p>
          <p>
            (iii) use of the content and material you supply will not cause
            injury to any person or entity (including that the content or
            material is not defamatory).
          </p>
          <p>
            This Site may contain photographs, testimonials, case studies and
            other content ('User Content'). We do not actively monitor use of
            any of the ‘User Content’ available on the Site. Similarly, we do
            not exercise editorial control over the content of any third party
            sites, electronic mail transmission, text message, news group,
            bulletin board, chat room or other material created or accessible
            over or through the Site. As a result, you may be exposed to content
            on the Site or on sites accessible over or through the Site that you
            find offensive or objectionable. However, we do reserve the right to
            monitor any of the ‘User Content’ available on the Site and to
            remove any content which, in our sole discretion, constitutes a
            misuse of the ‘User Content’ and may restrict, suspend or terminate
            your use of the ‘User Content’ or the Site in our sole discretion.
          </p>
          <p>
            The opinion(s) expressed by users or other ‘User Content’ do not
            represent the opinion(s) of Catalyst Global or its licensees. Any
            statements, advice and opinions made by such persons are those of
            such participants only. We neither endorse nor shall be held liable
            in any way or at all responsible for any such statements, advice or
            opinions.
          </p>
          <h3>No Warranty</h3>
          <p>
            We (and our parent, subsidiaries, affiliates, officers, directors,
            agents and employees) do not guarantee continuous, uninterrupted or
            secure access to the Site or any of Our Services, and operation of
            the Site may be interfered with by numerous factors outside of our
            control. Our Site and Our Services are provided "as is" and as and
            when available, and to the extent permissible by law we exclude all
            implied warranties, conditions or other terms, whether implied by
            statute or otherwise, including without limitation any terms as to
            skill and care or timeliness of performance.
          </p>
          <p>
            All implied terms, conditions and warranties (whether statutory or
            otherwise) are excluded from your use of the Site and the supply of
            Our Services.
          </p>
        </Col>
      </Row>
    </Container>
  );
};
export default TermsOfUse;
