import _ from 'lodash';
import { GameDocument } from '../../../types/game-document';
import { ResourceEntity, TitleEntity } from '../../../types/game-document/';
import { uuid } from '../../../types/common-helper';
import { GetNextAssetNameAsync, MergeAssets } from './index';
import { CopyResourceAsync, ImportResourceAsync } from '../resources';
import { DeleteResourceAsync } from '../resources';
import { ExportedTitleData } from '../../../types/game-document/entities/exported-title';
import { FilterResourcePacks } from '../resource-packs';

/**
 * Adds a new Title to the Game document.
 * @param gameDocument - The Game Document to modify
 * @param name - The Name of the new Title
 * @param description - The Description for the new Title
 * @returns The updated Game Document
 */
export const AddTitleAsync = async (
  gameDocument: GameDocument,
  name: string,
  description: string,
  titleResId: string,
  summaryResId: string,
  imageResId: string,
  hideInGame?: boolean
) => {
  let titles = gameDocument.assets.titles ?? [];
  let titleName = await GetNextAssetNameAsync(titles, name);
  titles.push({
    id: uuid(),
    name: titleName,
    description,
    titleResId,
    summaryResId,
    imageResId,
    hideInGame
  });
  return MergeAssets(gameDocument, titles, 'titles');
};

/**
 * Deletes the identified Title from the Game Document.
 * @param gameDocument - The Game Document to modify
 * @param titleId - The ID of the Title to delete
 * @returns The updated Game Document
 */
export const DeleteTitleAsync = async (
  gameDocument: GameDocument,
  titleId: string
) => {
  let titles = gameDocument.assets.titles ?? [];
  let titleIndex = titles.findIndex((i) => i.id === titleId)!;
  if (titleIndex !== -1) {
    await DeleteResourceAsync(gameDocument, titles[titleIndex].titleResId!);
    await DeleteResourceAsync(gameDocument, titles[titleIndex].summaryResId!);
    await DeleteResourceAsync(gameDocument, titles[titleIndex].imageResId!);
    titles.splice(titleIndex, 1);
  }
  return MergeAssets(gameDocument, titles, 'titles');
};

/**
 * Updates the identified Title in the Game Document.
 * @param gameDocument - The Game Document to modify
 * @param titleId - The ID of the Title to update
 * @param title - The updated Title
 * @param renameDuplicate - Auto rename 'name' key if duplicate
 * @constructor
 */
export const UpdateTitleAsync = async (
  gameDocument: GameDocument,
  titleId: string,
  title: TitleEntity,
  renameDuplicate: boolean = true
) => {
  let titles = gameDocument.assets.titles ?? [];
  let titleIndex = titles.findIndex((i) => i.id === titleId)!;
  if (renameDuplicate)
    title.name = await GetNextAssetNameAsync(titles, title.name, title.id);
  titles[titleIndex] = title;
  return MergeAssets(gameDocument, titles, 'titles');
};

/**
 * Create a copy of the Title in the Game document.
 * @param gameDocument - The Game Document to modify
 * @param titleId - The ID of the Title to copy
 * @param copiedTitleId - The title id to copy but different
 * @returns The updated Game Document
 */
export const CopyTitleAsync = async (
  gameDocument: GameDocument,
  titleId: string,
  copiedTitleId: string = uuid()
) => {
  let titles = gameDocument.assets.titles ?? [];
  let titleIndex = titles.findIndex((i) => i.id === titleId)!;

  if (titleIndex !== -1) {
    let titleCopy: TitleEntity = {
      ...titles[titleIndex],
      titleResId: uuid(),
      summaryResId: uuid(),
      imageResId: uuid()
    };
    await CopyResourceAsync(
      gameDocument,
      titles[titleIndex].titleResId!,
      titleCopy.titleResId
    );
    await CopyResourceAsync(
      gameDocument,
      titles[titleIndex].summaryResId!,
      titleCopy.summaryResId
    );
    await CopyResourceAsync(
      gameDocument,
      titles[titleIndex].imageResId!,
      titleCopy.imageResId
    );
    titleCopy.id = copiedTitleId;
    titleCopy.name += '-copy';
    titleCopy.name = await GetNextAssetNameAsync(
      titles,
      titleCopy.name,
      titleCopy.id
    );
    titles.push(titleCopy);
  }
  return MergeAssets(gameDocument, titles, 'titles');
};

/**
 * Get all titles from game document local storage.
 * @param gameDocument - The Game Document as datasource
 * @constructor
 */
export const GetTitles = (gameDocument: GameDocument | undefined) => {
  return gameDocument?.assets?.titles ?? [];
};

export const ExportTitle = (
  gameDocument: GameDocument,
  selectedIds: string[]
): ExportedTitleData => {
  const selectedTitles = gameDocument.assets?.titles?.filter((item) =>
    selectedIds.includes(item.id)
  );

  const currentResources = gameDocument.resources;

  let relatedResources: ResourceEntity[] = [];

  selectedTitles?.forEach((item) => {
    relatedResources = _.concat(
      relatedResources,
      GetTitleResourcesWithResId(item, currentResources)
    );
  });

  const resourcePacks = FilterResourcePacks(gameDocument, relatedResources);

  return {
    titles: selectedTitles!,
    resources: relatedResources,
    resourcePacks: resourcePacks
  };
};

export const ImportTitlesAsync = async (
  gameDocument: GameDocument,
  exportedTitleData: ExportedTitleData
) => {
  const titles = gameDocument.assets.titles;

  const exportedResources = exportedTitleData.resources;
  const exportedResourcePacks = exportedTitleData.resourcePacks;
  for (const title of exportedTitleData.titles) {
    const newTitle = _.cloneDeep(title);
    newTitle.id = uuid();

    newTitle.titleResId = await ImportResourceAsync(
      gameDocument,
      title.titleResId,
      exportedResources,
      exportedResourcePacks
    );

    newTitle.summaryResId = await ImportResourceAsync(
      gameDocument,
      title.summaryResId,
      exportedResources,
      exportedResourcePacks
    );

    newTitle.imageResId = await ImportResourceAsync(
      gameDocument,
      title.imageResId,
      exportedResources,
      exportedResourcePacks
    );

    titles?.push(newTitle);
  }

  return MergeAssets(gameDocument, titles!, 'titles');
};

export const GetTitleResourcesWithResId = (
  title: TitleEntity,
  resources: ResourceEntity[]
): ResourceEntity[] => {
  const resIds: string[] = [];
  if (title.titleResId) resIds.push(title.titleResId);
  if (title.summaryResId) resIds.push(title.summaryResId);
  if (title.imageResId) resIds.push(title.imageResId);

  return _.filter(resources, (resource) => _.includes(resIds, resource.id));
};
