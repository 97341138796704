import { SupportTicket, TicketAttachments } from '../types/support-ticket';
import { GetAllAsync, GetAsync, PatchAsync } from './api';

const SupportTicketEndpoint = `supports`;

export const GetSupportTicketsAsync = async (params?: string) =>
  GetAllAsync<SupportTicket[]>(`${SupportTicketEndpoint}?${params}`);

export const GetSupportTicketByIdAsync = async (supportTicketId: number) =>
  GetAsync<SupportTicket>(`${SupportTicketEndpoint}/${supportTicketId}`);

export const GetTicketAttachByIdAsync = async (supportTicketId: number) =>
  GetAsync<TicketAttachments[]>(
    `${SupportTicketEndpoint}/${supportTicketId}/attachments`
  );

export const PatchSupportTicketAsync = async (
  supportTicketId: number,
  supportTicket: SupportTicket
) =>
  PatchAsync<SupportTicket>(
    `${SupportTicketEndpoint}/${supportTicketId}`,
    supportTicket
  );
