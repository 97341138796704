import { AssetEntity, ResourceEntity } from '../../../types/game-document/';
import { GameDocument } from '../../../types/game-document';
import { merge } from 'lodash';

/**
 * Checks to see if a Asset with the defined name already exists.
 * @param assets - The list of Assets to check
 * @param name - The Name of the Title
 * @param id - The ID of the current Title to ignore
 * @returns True if a title with the same name already exists.
 */
export const AssetExistsAsync = async (
  assets: Array<AssetEntity>,
  name: string,
  id?: string
) => {
  let titleIndex = assets.findIndex((i) => i.name === name && i.id !== id);
  return titleIndex !== -1;
};

/**
 * Gets the next unique Asset name.
 * @param assets - The list of Assets to check
 * @param name - The Name of the Title
 * @param id - The ID of the current Title to ignore. I.e. for Edit.
 * @returns The next unique title name.
 */
export const GetNextAssetNameAsync = async (
  assets: Array<AssetEntity>,
  name: string,
  id?: string
) => {
  let titleExists = await AssetExistsAsync(assets, name, id);
  let counter = 1;
  let nextTitleName = name;
  while (titleExists) {
    nextTitleName = `${name}-${counter}`;
    titleExists = await AssetExistsAsync(assets, nextTitleName, id);
    counter += 1;
  }
  return nextTitleName;
};

/**
 * Combine assessments with the current game document.
 * @param gameDocument - The Game Document to modify
 * @param assets - The assets to combine
 * @param assetList - The name of the asset list to replace.
 */
export const MergeAssets = (
  gameDocument: GameDocument,
  assets: Array<AssetEntity>,
  assetList:
    | 'assessments'
    | 'items'
    | 'maps'
    | 'tasks'
    | 'taskContents'
    | 'timers'
    | 'titles'
    | 'zones'
    | 'areas'
    | 'routes'
    | 'mapImages'
) =>
  merge(gameDocument, {
    assets: { [assetList]: assets }
  });

/**
 * Combine resources with the current game document.
 * @param gameDocument - The Game Document to modify
 * @param resources - The resources to combine
 */
export const MergeResources = (
  gameDocument: GameDocument,
  resources: Array<ResourceEntity>
) =>
  merge(gameDocument, {
    resources
  });

export * from './zones';
export * from './assessments';
export * from './items';
export * from './maps';
export * from './tasks';
export * from './task-contents';
export * from './timers';
export * from './titles';
export * from './areas';
export * from './routes';
export * from './map-images';
