import React, { useEffect } from 'react';
import {
  ComboBox,
  ComboBoxFilterChangeEvent,
  ComboBoxProps
} from '@progress/kendo-react-dropdowns';
import { filterBy, FilterDescriptor } from '@progress/kendo-data-query';
import { GetCountriesAsync } from '../../services/country';
import { CountryResponse } from '../../types/responses/country-response';

const delay: number = 500;

export const CountryComboBox = (props: ComboBoxProps) => {
  const countries = React.useRef<CountryResponse[]>();
  const [data, setData] = React.useState<CountryResponse[]>([]);
  const [loading, setLoading] = React.useState(false);

  // load the initial set of countries
  useEffect(() => {
    // cache hit
    if (countries.current) {
      setData(countries.current);
      setLoading(false);
      return;
    }
    // fetch new data
    GetCountriesAsync().then((res) => {
      // sort alphabetically and store in ref with an intermediate collection for filtering
      countries.current = res.data.sort((a, b) =>
        a.country!.localeCompare(b.country!)
      );
      setData(countries.current);
      setLoading(false);
    });
  }, []);

  // allow filtering of the country data
  const filterData = (filter: FilterDescriptor) => {
    const data = countries.current!.slice();
    return filterBy(data, filter);
  };

  // debounce 500ms so filtering doesnt take affect after every keystroke
  const timeout = React.useRef<any>();
  const filterChange = (event: ComboBoxFilterChangeEvent) => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setData(filterData(event.filter));
      setLoading(false);
    }, delay);
    setLoading(true);
  };

  return (
    <ComboBox
      data={data}
      textField={'country'}
      dataItemKey={'iso'}
      filterable={true}
      onFilterChange={filterChange}
      loading={loading}
      allowCustom={false}
      {...props}
    />
  );
};

export default CountryComboBox;
