const localStorageName = 'last-authenticated-url';

export const setLastAuthenticatedURL = (value: string): void => {
  try {
    localStorage.setItem(localStorageName, value);
  } catch (error) {
    console.error('Error setting last authenticated URL:', error);
  }
};

export const getLastAuthenticatedURL = (): string => {
  return localStorage.getItem(localStorageName) || '';
};
