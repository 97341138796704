import { useEffect, useState } from 'react';
import { Row, Tab, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { PrivatePage } from '../components/private-page';

import { ReportGroup } from '../features/report/group';
import { ReportInformation } from '../features/report/information';
import { ReportPlayer } from '../features/report/player';
import { ReportResource } from '../features/report/rescource';
import { ReportTask } from '../features/report/task';
import { ReportTeam } from '../features/report/team';
import {
  GetEventReportDetailsAsync,
  GetEventReportHeaderAsync
} from '../services/event-reports';
import { appStore } from '../stores/app-store';
import { toastStore } from '../stores/toast-store';
import { EventReportDetail, EventReportHeader } from '../types/event-report';
import { ReportFeedback } from '../features/report/feedback';
import { ReportAssessment } from '../features/report/assessment';

const Report = () => {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState('information');
  const [summaryHeaderReport, setSummaryHeaderReport] =
    useState<EventReportHeader>();
  const [summaryDetailReport, setSummaryDetailReport] =
    useState<EventReportDetail[]>();

  const populateSummaryReportInformation = async () => {
    try {
      appStore.showLoading();
      GetEventReportHeaderAsync(id!).then((response) => {
        setSummaryHeaderReport(response as EventReportHeader);
      });

      GetEventReportDetailsAsync(id!).then((detailResponse) => {
        setSummaryDetailReport(detailResponse.data as EventReportDetail[]);
      });
    } catch {
      toastStore.show('Information', <div>Failed to load data.</div>, 'error');
    } finally {
      appStore.hideLoading();
    }
  };

  const handleSelect = (key: string) => {
    setActiveTab(key);
    if (key === 'information') {
      populateSummaryReportInformation();
    }
  };

  useEffect(() => {
    populateSummaryReportInformation();
  }, []);

  return (
    <PrivatePage
      breadcrumb={[
        {
          label: 'Reports',
          href: 'reports'
        },
        {
          label: `${summaryHeaderReport?.name!}`,
          href: `reports/${id}`
        }
      ]}
      pageTitle={'Reports'}>
      <Row>
        <Tabs
          defaultActiveKey={'information'}
          id={'timer-tabs'}
          className={'mb-3'}
          onSelect={(key) => handleSelect(key as string)}>
          <Tab eventKey={'information'} title={'Information'}>
            <ReportInformation
              summaryHeaderReport={summaryHeaderReport!}
              summaryDetailReport={summaryDetailReport!}
            />
          </Tab>
          <Tab eventKey={'group'} title={'Group'}>
            {activeTab === 'group' && (
              <ReportGroup summaryHeaderReport={summaryHeaderReport!} />
            )}
          </Tab>
          <Tab eventKey={'team'} title={'Team'}>
            {activeTab === 'team' && (
              <ReportTeam summaryHeaderReport={summaryHeaderReport!} />
            )}
          </Tab>
          <Tab eventKey={'player'} title={'Player'}>
            {activeTab === 'player' && (
              <ReportPlayer summaryHeaderReport={summaryHeaderReport!} />
            )}
          </Tab>
          <Tab eventKey={'task'} title={'Task'}>
            {activeTab === 'task' && (
              <ReportTask summaryHeaderReport={summaryHeaderReport!} />
            )}
          </Tab>
          <Tab eventKey={'feedback'} title={'Feedback'}>
            {activeTab === 'feedback' && (
              <ReportFeedback summaryHeaderReport={summaryHeaderReport!} />
            )}
          </Tab>
          <Tab eventKey={'assessment'} title={'Assessment'}>
            {activeTab === 'assessment' && (
              <ReportAssessment summaryHeaderReport={summaryHeaderReport!} />
            )}
          </Tab>
          <Tab eventKey={'resource'} title={'Resource'}>
            {activeTab === 'resource' && (
              <ReportResource summaryHeaderReport={summaryHeaderReport!} />
            )}
          </Tab>
        </Tabs>
      </Row>
    </PrivatePage>
  );
};

export default Report;
