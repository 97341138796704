import { LanguageResponse } from '../types/responses/language-response';
import { ApiBaseUrl, GetBearerHeader } from './api';

const LanguagesEndpoint = `countries/languages`;

export async function GetLanguangeAsync<LanguageResponse>(
  endpoint: string
): Promise<LanguageResponse> {
  return fetch(`${ApiBaseUrl}/${endpoint}`, {
    method: 'GET',
    headers: { ...GetBearerHeader() }
  }).then(async (response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    const res = await response.json();
    return res.data as LanguageResponse;
  });
}

export const GetLanguages = async () =>
  GetLanguangeAsync<LanguageResponse[]>(`${LanguagesEndpoint}`);
