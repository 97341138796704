import { Button } from '@progress/kendo-react-buttons';
import { Label } from '@progress/kendo-react-labels';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLocalStorage } from 'usehooks-ts';
import {
  ResetPasswordAsync,
  ResetPasswordByOrganisationAsync
} from '../../services/users';
import { getTotalMinutes } from '../../utils/date';

type ResetPasswordProps = {
  title?: string;
  buttonText?: string;
  userId?: number;
  organisationId?: number;
};

export const ResetPassword = ({ ...props }: ResetPasswordProps) => {
  const delayResetPassword: number = 30;

  const [expiredResetPassword, setExpiredResetPassword] = useLocalStorage<
    Date | undefined
  >(`date-reset-password-${props.userId}`, undefined);
  const [isEnableResetPassword, setIsEnableResetPassword] =
    useState<boolean>(false);

  const abilityResetPasswordHandler = () => {
    if (!expiredResetPassword) {
      setIsEnableResetPassword(true);
    } else {
      const resetDate = new Date(expiredResetPassword);
      if (getTotalMinutes(resetDate, new Date()) > delayResetPassword) {
        setIsEnableResetPassword(true);
      } else {
        setIsEnableResetPassword(false);
      }
    }
  };

  const onResetPassword = async () => {
    if (!isEnableResetPassword) return;
    setExpiredResetPassword(new Date());
    if (props.organisationId) {
      Promise.all([
        ResetPasswordByOrganisationAsync(props.organisationId!, props.userId!)
      ]).then((res) => {
        setIsEnableResetPassword(false);
      });
    } else {
      Promise.all([ResetPasswordAsync(props.userId!)]).then((res) => {
        setIsEnableResetPassword(false);
      });
    }
  };

  useEffect(() => {
    abilityResetPasswordHandler();
  }, [isEnableResetPassword]);

  return (
    <Row>
      <Col className="d-flex flex-column ">
        <Label>{props?.title}</Label>
        <Button
          themeColor={'secondary'}
          style={{
            width: 'fit-content'
          }}
          onClick={onResetPassword}>
          {isEnableResetPassword ? (
            <span>
              {props.buttonText
                ? props.buttonText
                : 'Send reset password to registered email address'}
            </span>
          ) : (
            <span>Please check your email</span>
          )}
        </Button>
      </Col>
    </Row>
  );
};
