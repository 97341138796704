import { GRID_COL_INDEX_ATTRIBUTE } from '@progress/kendo-react-grid';
import React from 'react';
import { useTableKeyboardNavigation } from '@progress/kendo-react-data-tools';
import { Button } from '@progress/kendo-react-buttons';
import { EditDeleteCellProps } from './edit-delete-cell';
import { ThemeColor } from '../../types/theme-color';

interface EditDeleteCloneCellProps extends EditDeleteCellProps {
  onCloneClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  cloneIcon?: string | 'content_copy';
  cloneThemeColor?: ThemeColor;
}

export const ViewEditDeleteCell = ({
  onViewClick,
  viewIcon,
  viewThemeColor,
  onEditClick,
  editIcon,
  editThemeColor,
  onDeleteClick,
  deleteIcon,
  deleteThemeColor,
  ...props
}: EditDeleteCloneCellProps) => {
  const field = props.field || '';
  const value = props.dataItem[field];
  const navigationAttributes = useTableKeyboardNavigation(props.id);
  return (
    <td
      colSpan={props.colSpan}
      role={'gridcell'}
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      {...{ [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex }}
      {...navigationAttributes}>
      <div className={'d-flex justify-content-end align-items-center'}>
        <Button
          onClick={onViewClick}
          themeColor={viewThemeColor ?? 'primary'}
          size={'small'}
          className={'small'}
          fillMode={'flat'}
          title={'View'}>
          <span className={'material-symbols-outlined'}>
            {viewIcon ?? 'visibility'}
          </span>
        </Button>
        <Button
          onClick={onEditClick}
          themeColor={editThemeColor ?? 'primary'}
          size={'small'}
          className={'small'}
          fillMode={'flat'}
          title={'Edit'}>
          <span className={'material-symbols-outlined'}>
            {editIcon ?? 'edit'}
          </span>
        </Button>
        <Button
          onClick={onDeleteClick}
          themeColor={deleteThemeColor ?? 'error'}
          size={'small'}
          className={'small'}
          fillMode={'flat'}
          title={'Delete'}>
          <span className={'material-symbols-outlined'}>
            {deleteIcon ?? 'delete'}
          </span>
        </Button>
      </div>
    </td>
  );
};

export default ViewEditDeleteCell;
