import { Fade } from '@progress/kendo-react-animation';
import { useContext, useState } from 'react';
import { GameDocumentContext } from '../../contexts/game-document';
import {
  FormCustomFeedback,
  ManualScoring,
  ScoringReductionEntity,
  TaskContentAnswer,
  TaskContentForm
} from '../../types/game-document/entities/task-content';
import { AnswerProps } from './answer';
import { CustomFeedback } from './custom-feedback';
import { QuestionnaireContent } from './questionnaire-content';
import { Scoring } from './scoring';

export interface QuestionnaireProps extends AnswerProps {
  question?: string;
  isVisibleScoring?: boolean;
  isManualScoring?: boolean;
  manualScoring?: ManualScoring;
  isScoringReduction?: boolean;
  scoringReduction?: ScoringReductionEntity;
  maxAttempts?: number | undefined;
  pointDepreciation?: number | undefined;
  score?: number | undefined;
  customFeedback?: FormCustomFeedback;
  taskContentId: string;
  onDeleteForm?: (id: string) => void;
  onCopyForm?: (id: string) => void;
  onChangeAnswers?: (answers: TaskContentAnswer[]) => void;
  onChangeScoring?: (
    score?: number | undefined,
    isManualScoring?: boolean,
    manualScoring?: ManualScoring,
    maxAttempts?: number | undefined,
    pointDepreciation?: number | undefined,
    isScoringReduction?: boolean,
    scoringReduction?: ScoringReductionEntity,
    answersWithScoring?: TaskContentAnswer[]
  ) => void;
  handleInputChange?: (
    value: string,
    type: 'question' | 'answer',
    answerResId?: string
  ) => void;
  onCustomFeedbackChange?: (customFeedback?: FormCustomFeedback) => void;
  onAddMedia?: () => void;
  taskContentForm?: TaskContentForm;
  onDeleteMedia?: (mediaIndex: number) => void;
  onEditMedia?: (mediaIndex: number) => void;
}
export function Questionnaire({
  score,
  question,
  isManualScoring = false,
  manualScoring,
  isScoringReduction,
  scoringReduction,
  customFeedback,
  taskContentId,
  onDeleteForm = () => {},
  onCopyForm = () => {},
  onChangeAnswers = () => {},
  onChangeScoring = () => {},
  handleInputChange = () => {},
  onCustomFeedbackChange = () => {},
  onAddMedia = () => {},
  onDeleteMedia = () => {},
  onEditMedia = () => {},
  taskContentForm,
  ...props
}: QuestionnaireProps) {
  const QUESTIONNAIRE = 'questionnaire';
  const SCORING = 'scoring';
  const CUSTOMFEEDBACK = 'custom-feedback';

  const [view, setView] = useState<string>(QUESTIONNAIRE);
  const [answerState, setAnswerState] = useState<boolean>(false);
  const [state, setState] = useContext(GameDocumentContext);

  const onDeleteFormHandler = () => {
    if (props.id !== '') {
      onDeleteForm(props.id!);
    }
  };

  const onCopyFormHandler = () => {
    if (props.id !== '') {
      onCopyForm(props.id!);
    }
  };

  const onChangeAnswersHandler = (answers: TaskContentAnswer[]) => {
    onChangeAnswers(answers);
  };

  /**
   * Set view questionnaire
   */
  const viewQuestionnaire = (id?: string, answerType?: string) => {
    setView(QUESTIONNAIRE);
  };

  /**
   * Set view scoring
   */
  const viewScoring = () => {
    setView(SCORING);
  };

  /**
   * Set view custom feedback
   */
  const viewCustomFeedback = async (id: string, answerType?: string) => {
    setAnswerState(answerType === 'checkbox' ? true : false);
    setView(CUSTOMFEEDBACK);
  };

  const onChangeAnswersScoringHandler = (
    score?: number,
    isManualScoring?: boolean,
    manualScoring?: ManualScoring,
    maxAttempts?: number | undefined,
    pointDepreciation?: number | undefined,
    isScoringReduction?: boolean,
    scoringReduction?: ScoringReductionEntity,
    answersWithScoring?: TaskContentAnswer[]
  ) => {
    onChangeScoring(
      score,
      isManualScoring,
      manualScoring,
      maxAttempts,
      pointDepreciation,
      isScoringReduction,
      scoringReduction,
      answersWithScoring
    );
    viewQuestionnaire();
  };

  const scoringContent =
    view === SCORING ? (
      <Scoring
        score={score}
        isManualScoring={isManualScoring}
        manualScoring={manualScoring}
        isScoringReduction={isScoringReduction}
        scoringReduction={scoringReduction}
        onShowScoring={() => {
          viewQuestionnaire();
        }}
        onChangeAnswers={onChangeAnswersScoringHandler}
        {...props}
      />
    ) : null;

  const questionnaireContent =
    view === QUESTIONNAIRE ? (
      <QuestionnaireContent
        question={question}
        taskContentId={taskContentId}
        onDeleteForm={onDeleteFormHandler}
        onCopyForm={onCopyFormHandler}
        onShowScoring={() => {
          viewScoring();
        }}
        onShowCustomFeedback={(id: string, answerType?: string) => {
          viewCustomFeedback(id, answerType);
        }}
        onChangeAnswers={onChangeAnswersHandler}
        handleInputChange={handleInputChange}
        onAddMedia={onAddMedia}
        taskContentForm={taskContentForm}
        onDeleteMedia={onDeleteMedia}
        onEditMedia={onEditMedia}
        {...props}
      />
    ) : null;

  const customFeedbackContent =
    view === CUSTOMFEEDBACK ? (
      <CustomFeedback
        taskContentForm={taskContentForm!}
        taskContentId={taskContentId!}
        gameDocument={state.gameDocument!}
        entity={customFeedback}
        onCancel={() => {
          viewQuestionnaire();
        }}
        onSubmit={(val) => {
          onCustomFeedbackChange(val);
          viewQuestionnaire();
        }}
        isShowPartial={answerState}
      />
    ) : null;

  let contentQuestion = (
    <Fade transitionEnterDuration={800} transitionExitDuration={400}>
      {questionnaireContent}
    </Fade>
  );
  let contentScoring = (
    <Fade transitionEnterDuration={800} transitionExitDuration={400}>
      {scoringContent}
    </Fade>
  );

  let contentCustomFeedback = (
    <Fade transitionEnterDuration={800} transitionExitDuration={400}>
      {customFeedbackContent}
    </Fade>
  );

  return (
    <>
      {contentQuestion}
      {contentScoring}
      {contentCustomFeedback}
    </>
  );
}
