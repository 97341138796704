import { GameDocumentContext } from '../../contexts/game-document';
import { useContext, useEffect } from 'react';
import Toolbar from './toolbar';
import WorldMap from '../game-designer/assets/world-map';

const GameMap = () => {
  const [state, setState] = useContext(GameDocumentContext);

  useEffect(() => {
    // Set the page title.
    document.title = `World Map - ${state.gameDocument?.name}`;
  }, [state]);

  return (
    <>
      <Toolbar title={'World Map'}></Toolbar>
      <WorldMap />
    </>
  );
};

export default GameMap;
