import { UserResponse } from '../types/responses/user-response';
import { GetAllAsync, GetAsync } from './api';
import { KendoGridResult } from '../types/KendoGridResult';
import { State, toDataSourceRequestString } from '@progress/kendo-data-query';

const UserEndpoint = `admin/users`;

export const GetGridAllUsersAsync = async (dataState: State) =>
  GetAllAsync<KendoGridResult<UserResponse>>(
    `${UserEndpoint}?${toDataSourceRequestString(dataState)}`
  );

export const GetReportsAllUsersAsync = async () =>
  GetAllAsync<UserResponse>(`${UserEndpoint}/reports`);
