import { Window, WindowProps } from '@progress/kendo-react-dialogs';
import { ComponentType, useContext, useEffect, useState } from 'react';
import { Slider } from '@progress/kendo-react-inputs';
import { FluidForm } from '../../../components/forms';
import { GameDocumentContext } from '../../../contexts/game-document';
import { MapImage } from '../../../types/game-document';
import debounce from 'lodash/debounce';
import {
  GetDetailMapImageByMapImageId,
  UpdateGameDocState,
  UpdateMapImagesAsync
} from '../../../utils/game-document';
interface MapImagePropertyEditorWindowProps extends WindowProps {
  id: string;
  onChange?: (image?: MapImage) => void;
  onClose?: () => void;
}

export const MapImagePropertyEditorWindow: ComponentType<
  MapImagePropertyEditorWindowProps
> = ({ ...props }: MapImagePropertyEditorWindowProps) => {
  const [state, setState] = useContext(GameDocumentContext);
  const [id] = useState<string>(props.id);
  const [opacity, setOpacity] = useState<number>(0);
  const [imageScale, setImageScale] = useState<number>(0);

  const updateMapImageAsset = (mapImage: MapImage) => {
    UpdateMapImagesAsync(state.gameDocument!, mapImage).then((response) => {
      setState((prev) => UpdateGameDocState(prev, response));
    });
  };

  const updateImageSize = async (value: number) => {
    let image = await GetDetailMapImageByMapImageId(state?.gameDocument!, id);
    if (image) {
      image.imageScale = value;
      updateMapImageAsset(image);
    }
  };

  const updateOpacitySize = async (value: number) => {
    let image = await GetDetailMapImageByMapImageId(state?.gameDocument!, id);
    if (image) {
      image.opacity = value;
      updateMapImageAsset(image);
    }
  };

  const populateDataAsync = async () => {
    let newImage = await GetDetailMapImageByMapImageId(
      state?.gameDocument!,
      id
    );
    if (newImage) {
      setOpacity(newImage?.opacity ?? 100);
      setImageScale(newImage?.imageScale ?? 25);
    }
  };

  const updateImageSizeDebounced = debounce(updateImageSize, 500);
  const updateOpacitySizeDebounced = debounce(updateOpacitySize, 500);

  useEffect(() => {
    populateDataAsync();
  }, []);

  return (
    <Window
      modal={true}
      minimizeButton={undefined}
      maximizeButton={undefined}
      className={'shadow'}
      initialWidth={400}
      initialHeight={250}
      style={{
        left: '65%', // This sets the element to the right side of its containing element
        top: '3%' // Adjust this value as needed to position vertically
      }}
      overlayStyle={{
        display: 'none'
      }}
      title={''}
      {...props}>
      <FluidForm>
        <div className={'d-flex flex-row'}>
          <div className={'flex-column flex-fill'}>
            <div className={'d-flex flex-column'}>
              <span>Image size</span>
              <Slider
                className={'w-100'}
                step={1}
                value={imageScale}
                min={1}
                max={200}
                onChange={(v) => {
                  setImageScale(v?.value);
                  updateImageSizeDebounced(v.value);
                }}
              />
            </div>
            <div className={'d-flex flex-column mt-3'}>
              <span>Opacity</span>
              <Slider
                className={'w-100'}
                step={1}
                value={opacity}
                min={1}
                max={100}
                onChange={(v) => {
                  setOpacity(v?.value);
                  updateOpacitySizeDebounced(v.value);
                }}
              />
            </div>
          </div>
        </div>
      </FluidForm>
    </Window>
  );
};
