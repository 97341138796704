import {
  DeleteAsync,
  GetAllAsync,
  PostAsync,
  GetAsync,
  PatchAsync,
  PutAsync
} from './api';
import { UserResponse } from '../types/responses/user-response';
import { AddressResponse } from '../types/responses/address-response';

/**
 * Gets ALL GetAllUserAddress from the response of Address
 * @returns all User objects.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */

export const GetAllUserAddressAsync = async (userId: number) =>
  GetAllAsync<AddressResponse>(`users/${userId}/addresses`);

/**
 * Gets ALL logged-in User addresses from the response of Address
 * @returns all User objects.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetMyUserAddressesAsync = async () =>
  GetAllAsync<AddressResponse>(`users/myaddresses`);

/**
 * Gets ALL UserAddress by OrganisationId from the response of Address
 * @returns all User objects.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */

export const GetAllUserAddressByOrganisationAsync = async (
  organisationId: number,
  userId: number
) =>
  GetAllAsync<AddressResponse>(
    `organisations/${organisationId}/users/${userId}/addresses`
  );

/**
 * Gets ALL UsersAddress encapsulated in a paged payload.
 * @returns all User objects.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetUsersAddressAsync = async (userId: number) =>
  GetAllAsync<UserResponse>(`users/${userId}/addresses`);

/**
 * Applies a POST to the UserAddress.
 * requiring only the fields and values that require modification.
 * @param userId - the ID of the User to POST
 * @param user - the User or partial User to update [POST]
 * @returns the updated User object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/POST
 */
export const PostUserAddressAsync = async (
  userId: number,
  userAddress: AddressResponse
) => PostAsync<AddressResponse>(`users/${userId}/addresses`, userAddress);

/**
 * Applies a POST to the UserAddress.
 * requiring only the fields and values that require modification.
 * @param userAddress - the User address or partial User address to update [POST]
 * @returns the updated User object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/POST
 */
export const PostMyUserAddressAsync = async (userAddress: AddressResponse) =>
  PostAsync<AddressResponse>(`users/myaddresses`, userAddress);

/**
 * Applies a POST to the UserAddress by organisation.
 * requiring only the fields and values that require modification.
 * @param organisationId - the Organisation ID of the User belong to
 * @param userId - the ID of the User to POST
 * @param userAddress - the UserAddress or partial UserAddress to update [POST]
 * @returns the updated User object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/POST
 */
export const PostUserAddressByOrganisationAsync = async (
  organisationId: number,
  userId: number,
  userAddress: AddressResponse
) =>
  PostAsync<AddressResponse>(
    `organisations/${organisationId}/users/${userId}/addresses`,
    userAddress
  );

/**
 * Gets the UserAddress by id.
 * @param userId - the ID of the User to retrieve
 * @returns the User object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetUserAddressAsync = async (
  userId: number,
  userAddress: number
) => GetAsync<AddressResponse>(`users/${userId}/addresses/${userAddress}`);

/**
 * Applies a PATCH to the UserAddress. This can be considered a partial update
 * requiring only the fields and values that require modification.
 * @param userId - the ID of the User to PATCH
 * @param user - the User or partial User to update [PATCH]
 * @returns the updated User object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PATCH
 */
export const UpdateUserAddressAsync = async (
  userId: number,
  userAddress: AddressResponse
) =>
  PatchAsync<AddressResponse>(
    `users/${userId}/addresses/${userAddress.id!}`,
    userAddress
  );

/**
 * Applies a PATCH to the UserAddress. This can be considered a partial update
 * requiring only the fields and values that require modification.
 * @param user - the User or partial User to update [PATCH]
 * @returns the updated User object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PATCH
 */
export const UpdateMyUserAddressAsync = async (userAddress: AddressResponse) =>
  PatchAsync<AddressResponse>(
    `users/myaddresses/${userAddress.id!}`,
    userAddress
  );

/**
 * Applies a PATCH to the UserAddress by Organisation. This can be considered a partial update
 * requiring only the fields and values that require modification.
 * @param organisationId - the ID of the Organisation the users belong to
 * @param userId - the ID of the User to PATCH
 * @param userAddress - the UserAddress or partial UserAddress to update [PATCH]
 * @returns the updated User object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PATCH
 */
export const UpdateUserAddressByOrganisationAsync = async (
  organisationId: number,
  userId: number,
  userAddress: AddressResponse
) =>
  PatchAsync<AddressResponse>(
    `organisations/${organisationId}/users/${userId}/addresses/${userAddress.id!}`,
    userAddress
  );

/**
 * Updates the UserAddress by completely replacing it.
 * @param userId - the ID of the entity to update [PUT]
 * @param user - the User to update [PUT]
 * @returns the updated User object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PUT
 */
export const PutUserAddressAsync = async (
  userId: number,
  userAddressId: number,
  userAddress: AddressResponse
) =>
  PutAsync<AddressResponse>(
    `users/${userId}/addresses/${userAddressId}`,
    userAddress
  );

/**
 * Deletes a UserAddress by id.
 * @param userId - the ID of the User to DELETE
 * @returns the deleted User object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/DELETE
 */
export const DeleteUserAddressAsync = async (
  userId: number,
  userAddressId: number
) => DeleteAsync<AddressResponse>(`users/${userId}/addresses/${userAddressId}`);
