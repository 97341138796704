import { Outlet } from 'react-router-dom';
import { Spinner } from '../components/spinner';
import { Toast } from '../components/toast';
import { appStore } from '../stores/app-store';
import { toastStore } from '../stores/toast-store';
import { observer } from 'mobx-react';
import { AuthRequired } from '../components/authentication';
import React from 'react';
import AdministrationMenu from './administration-menu';

/**
 * The layout for admin portal pages.
 * @returns
 */
const Administration = observer(() => {
  const getStyle = () => {
    const style = toastStore.getStyle();
    return style ? style : 'none';
  };

  return (
    <AuthRequired>
      <div className={'layout layout--admin'}>
        {appStore.isLoading() && <Spinner />}
        <AdministrationMenu />
        <div className={'app-main'}>
          <Outlet />
        </div>
        <Toast
          style={getStyle()}
          show={toastStore.isShow()}
          title={toastStore.getTitle()}>
          {toastStore.getMessage()}
        </Toast>
      </div>
    </AuthRequired>
  );
});

export default Administration;
