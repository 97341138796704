import { useEffect } from 'react';
import { PrivatePage } from '../../components/private-page';
import { ctGameDefault, ctGameTitle } from '../../constants/text';
import GlobalAssessmentGrid from '../../features/global-assessment-grid';
export default function GlobalAssessment() {
  useEffect(() => {
    document.title = ctGameTitle + '- Global Assessment';
    return () => {
      document.title = ctGameDefault;
    };
  }, []);
  return (
    <PrivatePage
      breadcrumb={[
        {
          label: 'Dashboard',
          href: '../dashboard'
        },
        {
          label: 'Global Assessment',
          href: '../global-assessment'
        }
      ]}
      pageTitle={'Global Library'}>
      <GlobalAssessmentGrid />
    </PrivatePage>
  );
}
