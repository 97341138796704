import {
  DeleteAsync,
  GetAllAsync,
  PostAsync,
  GetAsync,
  PatchAsync,
  PutAsync
} from './api';
import { UserResponse } from '../types/responses/user-response';
import {
  AvailabilityCheck,
  EmailAddressResponse
} from '../types/responses/email-address-response';

/**
 * Gets ALL UsersEmails encapsulated in a paged payload.
 * @returns all User objects.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetUsersEmailsAsync = async (userId: number) =>
  GetAllAsync<UserResponse>(`users/${userId}/emailaddresses`);

/**
 * Applies a POST to the UserEmail.
 * requiring only the fields and values that require modification.
 * @param userId - the ID of the User to POST
 * @param user - the User or partial User to update [POST]
 * @returns the updated User object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/POST
 */
export const PostUserEmailAsync = async (
  userId: number,
  userEmail: EmailAddressResponse
) =>
  PostAsync<EmailAddressResponse>(`users/${userId}/emailaddresses`, userEmail);

/**
 * Gets the UserEmail by id.
 * @param userId - the ID of the User to retrieve
 * @returns the User object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetUserEmailAsync = async (userId: number, userEmail: number) =>
  GetAsync<EmailAddressResponse>(`users/${userId}/emailaddresses/${userEmail}`);

/**
 * Applies a PATCH to the UserEmail. This can be considered a partial update
 * requiring only the fields and values that require modification.
 * @param userId - the ID of the User to PATCH
 * @param user - the User or partial User to update [PATCH]
 * @returns the updated User object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PATCH
 */
export const PatchUserEmailAsync = async (
  userId: number,
  userEmailId: number,
  userEmail: any | EmailAddressResponse
) =>
  PatchAsync<EmailAddressResponse>(
    `users/${userId}/emailaddresses/${userEmailId}`,
    userEmail
  );

/**
 * Updates the UserEmail by completely replacing it.
 * @param userId - the ID of the entity to update [PUT]
 * @param user - the User to update [PUT]
 * @returns the updated User object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PUT
 */
export const PutUserEmailAsync = async (
  userId: number,
  userEmailId: number,
  userEmail: EmailAddressResponse
) =>
  PutAsync<EmailAddressResponse>(
    `users/${userId}/emailaddresses/${userEmailId}`,
    userEmail
  );

/**
 * Deletes a UserEmail by id.
 * @param userId - the ID of the User to DELETE
 * @returns the deleted User object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/DELETE
 */
export const DeleteUserEmailAsync = async (
  userId: number,
  userEmailId: number
) =>
  DeleteAsync<EmailAddressResponse>(
    `users/${userId}/emailaddresses/${userEmailId}`
  );

/**
 * Applies a POST to the UserEmail to check if a email is available or not.
 * requiring only the fields and values that require modification.
 */
export const PostEmailAvailabilityAsync = async (
  availabilitycheck: AvailabilityCheck
) => PostAsync<boolean>(`users/availabilitycheck`, availabilitycheck as never);
