import { Button } from '@progress/kendo-react-buttons';
import { Window, WindowActionsBar } from '@progress/kendo-react-dialogs';

type LogoutProps = {
  onConfirmLogout: () => void;
  onCancelLogout: () => void;
};

const Logout = ({
  onConfirmLogout = () => {},
  onCancelLogout = () => {}
}: LogoutProps) => {
  return (
    <Window
      modal={true}
      minimizeButton={undefined}
      maximizeButton={undefined}
      onClose={onCancelLogout}
      className={'shadow'}
      initialWidth={400}
      initialHeight={200}
      title={'Log out'}>
      <p>Are you sure you want to log out?</p>
      <WindowActionsBar>
        <Button themeColor={'secondary'} onClick={onCancelLogout}>
          Cancel
        </Button>
        <Button themeColor={'primary'} onClick={onConfirmLogout}>
          Confirm
        </Button>
      </WindowActionsBar>
    </Window>
  );
};

export default Logout;
