import { GameDocument } from '../../../types/game-document';
import { MapEntity } from '../../../types/game-document/';
import { uuid } from '../../../types/common-helper';
import { GetNextAssetNameAsync, MergeAssets } from './index';
import { ZoneAssetId } from '../../../types/game-document/entities/zone';

/**
 * Adds a new Map to the Game document.
 * @param gameDocument - The Game Document to modify
 * @param name - The Name of the new Map
 * @param description - The Description for the new Map
 * @returns The updated Game Document
 */
export const AddMapAsync = async (
  gameDocument: GameDocument,
  name: string,
  description: string,
  titleResId: string
) => {
  let maps = gameDocument.assets.maps ?? [];
  let mapName = await GetNextAssetNameAsync(maps, name);

  maps.push({
    id: uuid(),
    name: mapName,
    description,
    type: 'gps',
    titleResId
  });
  return MergeAssets(gameDocument, maps, 'maps');
};

/**
 * Adds a new Map to the Game document.
 * @param gameDocument - The Game Document to modify
 * @param map - The new map
 * @returns The updated Game Document
 */
export const AddMapEntityAsync = async (
  gameDocument: GameDocument,
  map: MapEntity
) => {
  let maps = gameDocument.assets.maps ?? [];
  let mapName = await GetNextAssetNameAsync(maps, map.name);
  map.name = mapName;
  maps.push(map);

  return MergeAssets(gameDocument, maps, 'maps');
};

/**
 * Deletes the identified Map from the Game Document.
 * @param gameDocument - The Game Document to modify
 * @param mapId - The ID of the Map to delete
 * @returns The updated Game Document
 */
export const DeleteMapAsync = async (
  gameDocument: GameDocument,
  mapId: string
) => {
  let maps = gameDocument.assets.maps ?? [];
  let mapIndex = maps.findIndex((i) => i.id === mapId)!;
  maps.splice(mapIndex, 1);
  return MergeAssets(gameDocument, maps, 'maps');
};

/**
 * Updates the identified Map in the Game Document.
 * @param gameDocument - The Game Document to modify
 * @param mapId - The ID of the Map to update
 * @param map - The updated Map
 * @constructor
 */
export const UpdateMapAsync = async (
  gameDocument: GameDocument,
  mapId: string,
  map: MapEntity
) => {
  let maps = gameDocument.assets?.maps ?? [];
  let mapIndex = maps.findIndex((i) => i.id === mapId)!;
  map.name = await GetNextAssetNameAsync(maps, map.name, map.id);
  maps[mapIndex] = map;
  return MergeAssets(gameDocument, maps, 'maps');
};

/**
 * Create a copy of the Map in the Game document.
 * @param gameDocument - The Game Document to modify
 * @param mapId - The ID of the Map to copy
 * @param copiedMapId - The map id to copy but different
 * @returns The updated Game Document
 */
export const CopyMapAsync = async (
  gameDocument: GameDocument,
  mapId: string,
  copiedMapId: string = uuid()
) => {
  let maps = gameDocument.assets.maps ?? [];
  let mapIndex = maps.findIndex((i) => i.id === mapId)!;
  let mapCopy = { ...maps[mapIndex] };
  mapCopy.id = copiedMapId;
  mapCopy.name += '-copy';

  mapCopy.name = await GetNextAssetNameAsync(maps, mapCopy.name, mapCopy.id);

  maps.push(mapCopy);

  return MergeAssets(gameDocument, maps, 'maps');
};

/**
 * Updates the identified Map zone in the Game Document.
 * @param gameDocument - The Game Document to modify
 * @param zones - The Zones of the map to copy
 * @return The updated Game Document
 */
export const UpdateMapZoneAsync = async (
  gameDocument: GameDocument,
  zones: ZoneAssetId
) => {
  let maps = gameDocument.assets.zones ?? [];
  let mapCopy = { ...maps, zones };
  return MergeAssets(gameDocument, mapCopy, 'zones');
};

/**
 * Get map by ID async.
 * @param gameDocument - The Game Document
 * @param mapId - The ID of the Map to get the Map Entity
 * @constructor
 */
export const GetMapByIdAsync = async (
  gameDocument: GameDocument,
  mapAssetId: string
) => {
  let maps = gameDocument.assets?.maps ?? [];
  return maps.find((i) => i.id === mapAssetId);
};

/**
 * Get map by ID.
 * @param gameDocument - The Game Document
 * @param mapId - The ID of the Map to get the Map Entity
 * @constructor
 */
export const GetMapById = (gameDocument: GameDocument, mapAssetId: string) => {
  let maps = gameDocument.assets?.maps ?? [];
  return maps.find((i) => i.id === mapAssetId);
};
