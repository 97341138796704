import { UserResponse } from '../types/responses/user-response';
import {
  DeleteAsync,
  GetAllAsync,
  GetAsync,
  PatchAsync,
  PostAsync,
  PutAsync
} from './api';
import { KendoGridResult } from '../types/KendoGridResult';
import { AddUserRequest } from '../types/user';
import { getAsync } from './http-client';
import { State, toDataSourceRequestString } from '@progress/kendo-data-query';

const UserEndpoint = `users`;

/*
This function is still hit the version one , because in version 2 of endpoint we dont have a sign up 
**/

export async function getUsers(params?: string): Promise<any> {
  return getAsync('users', params);
}

export const GetGridUsersAsync = async (dataState: State) =>
  GetAllAsync<KendoGridResult<UserResponse>>(
    `${UserEndpoint}?${toDataSourceRequestString(dataState)}`
  );

/**
 * Gets ALL User encapsulated in a paged payload.
 * @returns all User objects.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetUsersAsync = async () =>
  GetAllAsync<UserResponse>(UserEndpoint);

/**
 * Gets ALL User encapsulated in a paged payload.
 * @returns all User objects.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetAllUsersForNetworkAsync = async (dataState: State) =>
  GetAllAsync<KendoGridResult<UserResponse>>(
    `${UserEndpoint}/network?${toDataSourceRequestString(dataState)}`
  );

/**
 * Gets ALL Users for report purposes.
 * @returns all User objects.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetAllUsersForNetworkReportAsync = async () =>
  GetAsync<UserResponse[]>(`${UserEndpoint}/reports`);

/**
 * Applies a POST to the User.
 * requiring only the fields and values that require modification.
 * @param user - the User or partial User to update [POST]
 * @returns the updated User object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PATCH
 */
export const PostUserAsync = async (user: AddUserRequest) =>
  PostAsync<UserResponse>(`${UserEndpoint}`, user);

/**
 * Gets the User by id.
 * @param userId - the ID of the User to retrieve
 * @returns the User object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetUserAsync = async (userId: number) =>
  GetAsync<UserResponse>(`${UserEndpoint}/${userId}`);

/**
 * Gets the User by the logged in user.
 * @returns the User object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetMyUserProfileAsync = async () =>
  GetAsync<UserResponse>(`${UserEndpoint}/myprofile`);

/**
 * Gets the User by id as an Admin.
 * @param userId - the ID of the User to retrieve
 * @returns the User object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetUserAsAdminAsync = async (userId: number) =>
  GetAsync<UserResponse>(`admin/${UserEndpoint}/${userId}`);

/**
 * Updates the User by completely replacing it.
 * @param userId - the ID of the entity to update [PATCH]
 * @param user - the User to update [PATCH]
 * @returns the updated User object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PATCH
 */
export const UpdateUserAsync = async (
  userId: number,
  user: any | UserResponse
) => PatchAsync<UserResponse>(`${UserEndpoint}/${userId}`, user);

/**
 * Applies a PUT to the User. This can be considered a partial update
 * requiring only the fields and values that require modification.
 * @param userId - the ID of the User to PUT
 * @param user - the User or partial User to update [PUT]
 * @returns the updated User object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PUT
 */
export const PutUserAsync = async (userId: number, user: UserResponse) =>
  PutAsync<UserResponse>(`${UserEndpoint}/${userId}`, user);

/**
 * Applies a PUT to the logged-in User. This can be considered a partial update
 * requiring only the fields and values that require modification.
 * @param user - the User or partial User to update [PUT]
 * @returns the updated User object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PUT
 */
export const PutMyUserProfileAsync = async (user: UserResponse) =>
  PutAsync<UserResponse>(`${UserEndpoint}/myprofile`, user);

/**
 * Applies a PUT to the User. This can be considered a partial update
 * requiring only the fields and values that require modification.
 * @param organisationId - the ID of the organisation
 * @param userId - the ID of the User to PUT
 * @param user - the User or partial User to update [PUT]
 * @returns the updated User object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PUT
 */
export const PutUserByOrganisationAsync = async (
  organisationId: number,
  userId: number,
  user: UserResponse
) =>
  PutAsync<UserResponse>(
    `organisations/${organisationId}/${UserEndpoint}/${userId}`,
    user
  );

/**
 * Deletes a User by id on the given organisation Id.
 * @param organisationId - the Organisation that owns the User
 * @param userId - the ID of the User to DELETE
 * @returns the deleted User object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/DELETE
 */
export const DeleteUserByOrganisationAsync = async (
  organisationId: number,
  userId: number
) =>
  DeleteAsync<UserResponse>(`organisations/${organisationId}/users/${userId}`);

/**
 * Deletes a User by id.
 * @param userId - the ID of the User to DELETE
 * @returns the deleted User object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/DELETE
 */
export const DeleteUserAsync = async (userId: number) =>
  DeleteAsync<UserResponse>(`${UserEndpoint}/${userId}`);

/**
 * Applies a POST to send reset password.
 * @returns the notification description.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/POST
 */
export const ResetPasswordAsync = async (userId: number) =>
  PostAsync<string>(`${UserEndpoint}/${userId}/password/reset`, {} as never);

/**
 * Applies a POST to send reset password by organisation.
 * @returns the notification description.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/POST
 */
export const ResetPasswordByOrganisationAsync = async (
  organisationId: number,
  userId: number
) =>
  PostAsync<string>(
    `organisations/${organisationId}/${UserEndpoint}/${userId}/password/reset`,
    {} as never
  );

/**
 * Applies a POST to invite user.
 * @returns the user response.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/POST
 */
export const InviteUserAsync = async (
  organisationId: number,
  user: InviteUser
) => PostAsync<UserResponse>(`organisations/${organisationId}/users`, user);

/**
 * Applies a POST to resend invitation user.
 * @returns the user response.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/POST
 */
export const ResendInviteUserAsync = async (
  organisationId: number,
  userProfileId: number
) => PostAsync<string>(`organisations/${organisationId}/users/${userProfileId}/resendinvitation`, {} as never);

/**
 * Applies a POST to invite user by reseller.
 * @returns the user response.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/POST
 */
export const ResllerInviteUserAsync = async (user: InviteUser) =>
  PostAsync<UserResponse>(`users`, user);

export interface InviteUser {
  firstName?: string;
  lastName?: string;
  emailAddress?: EmailAddress;
}

export interface EmailAddress {
  address?: string;
}
