import React, { useEffect, useState } from 'react';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridNoRecords
} from '@progress/kendo-react-grid';
import {
  DataResult,
  State,
  toDataSourceRequestString
} from '@progress/kendo-data-query';
import { useNavigate } from 'react-router-dom';
import {
  DeleteEventAsync,
  GetEventById,
  GetEventFeedbacks,
  GetEventPlaylistFacilitators,
  GetEventPlaylistLocations,
  GetEventPlaylists,
  GetEvents,
  PostEventAsync,
  PostEventFeedbackAsync,
  PostEventPlaylistAsync,
  PostEventPlaylistFacilitatorAsync,
  PostEventPlaylistLocationAsync
} from '../services/events';
import { ColumnMenu } from '../components/columnMenu';
import { toastStore } from '../stores/toast-store';
import { Button } from '@progress/kendo-react-buttons';
import Toolbar from '../components/page-toolbar';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import {
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../components/grid/grid-tool-bar';
import { DefaultGridSettings } from '../constants/grid-settings';
import { NoRecords } from '../components/grid/no-records';
import EventsScheduler from './events-scheduler';
import { ActionCell, ActionsCell } from '../components/grid/actions-cell';
import YesNoDialog from '../components/dialogs/yes-no-dialog';
import { YesNoDialogResult } from '../types/dialog-result';
import { appStore } from '../stores/app-store';
import { formatDateToDDMMYYYY } from '../utils/date';
import HelpSupport from '../components/help-support';

const initialDataState: State = {
  sort: [{ field: 'startDateUtc', dir: 'desc' }],
  filter: DefaultGridSettings.initialDataState.filter,
  skip: DefaultGridSettings.initialDataState.skip,
  group: DefaultGridSettings.initialDataState.group
};

const EventsGrid = () => {
  const navigate = useNavigate();
  const [dataState, setDataState] = useState<State>({
    ...initialDataState,
    take: DefaultGridSettings.initialDataState.take
  });
  const [gridMode, setGridMode] = useState<string>('grid');
  const [selectedId, setSelectedId] = useState<number>(0);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);
  const [events, setEvents] = useState<DataResult>(() => ({
    data: [],
    total: 0
  }));

  const addDaysToDate = (date: Date, daysToAdd: number) => {
    const addDays = (date: Date, days: number): Date => {
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    };

    const modifiedDate = addDays(date, daysToAdd);

    return modifiedDate;
  };

  const copyEventById = async (id: number) => {
    appStore.showLoading();
    //Get event by id
    await GetEventById(id).then(async (responseEvent) => {
      //Get event playlists
      let event = responseEvent;
      event.name = event.name + '-copy';
      event.id = 0;
      event.status = 'New';
      const dataEventPlaylistById = await GetEventPlaylists(id);
      const dataEventFeedbackById = await GetEventFeedbacks(id);
      await PostEventAsync(event).then((responsePostEvent) => {
        let counterLoopingPlaylist = 0;
        dataEventPlaylistById?.data?.forEach(async (playlist) => {
          playlist.eventId = responsePostEvent.id;
          const nowPlusWeek = new Date(
            new Date().setDate(new Date().getDate() + 7)
          );
          playlist.startDateUtc = nowPlusWeek;
          playlist.endDateUtc = nowPlusWeek;

          await PostEventPlaylistAsync(responsePostEvent.id!, playlist!).then(
            async (responsePostPlaylist) => {
              const [facilitators, locations] = await Promise.all([
                GetEventPlaylistFacilitators(id, playlist.id!),
                GetEventPlaylistLocations(id, playlist.id!)
              ]);

              facilitators?.data?.forEach((facilitator) => {
                facilitator.id = 0;
                PostEventPlaylistFacilitatorAsync(
                  responsePostEvent?.id!,
                  responsePostPlaylist?.id!,
                  facilitator
                );
              });

              locations?.data?.forEach((location) => {
                location.id = 0;
                PostEventPlaylistLocationAsync(
                  responsePostEvent?.id!,
                  responsePostPlaylist?.id!,
                  location
                );
              });
              if (
                counterLoopingPlaylist ===
                  dataEventPlaylistById.data.length - 1 &&
                dataEventFeedbackById?.data.length > 0
              ) {
                dataEventFeedbackById?.data
                  .filter((item) => item.isDeleted === false)
                  .forEach(async (feedbackItem) => {
                    await PostEventFeedbackAsync(
                      responsePostEvent?.id!,
                      feedbackItem
                    );
                  });
              }
              if (
                counterLoopingPlaylist ===
                dataEventPlaylistById.data.length - 1
              ) {
                navigate(
                  `/myeventdetails/${responsePostEvent?.id!}?action=copy`
                );
              }
            }
          );

          counterLoopingPlaylist++;
        });
      });
    });
  };

  const fetchEvents = async () => {
    GetEvents(`${toDataSourceRequestString(dataState)}`)
      .then((response) => {
        setEvents(response);
      })
      .catch((ex) => {
        console.error(ex);
      });
  };

  useEffect(() => {
    fetchEvents();
    //eslint-disable-next-line
  }, [dataState]);

  const onAddEvent = () => {
    navigate('/myeventdetails/new');
  };

  const onCopyEvent = async (id: number) => {
    copyEventById(id);
  };

  const onDelete = (id: number) => {
    onDeleteEvent(id);
  };

  const onDeleteEvent = async (id: number) => {
    setSelectedId(id);
    setShowDeleteConfirm(true);
  };

  const gameColumn = (props: GridCellProps) => {
    return (
      <td>
        <span>
          {props.dataItem.game ? 'Game Template' : 'Assessment Template'}
        </span>
      </td>
    );
  };

  const clientColumn = (props: GridCellProps) => {
    return (
      <td>
        <span>{props.dataItem.clientType}</span>
      </td>
    );
  };

  const onActionRow = (props: GridCellProps) => {
    return (
      <>
        <ActionsCell {...props}>
          <ActionCell
            title={'View event'}
            icon={'visibility'}
            themeColor={'primary'}
            className={'fs-2'}
            onClick={() => onViewEvent(props.dataItem.id)}
          />
          <ActionCell
            title={'Edit event'}
            icon={'edit'}
            themeColor={'primary'}
            className={'fs-2'}
            onClick={() => onEditEvent(props.dataItem.id)}
          />
          <ActionCell
            title={'Copy event'}
            icon={'content_copy'}
            themeColor={'primary'}
            className={'fs-2'}
            onClick={() => onCopyEvent(props.dataItem.id)}
          />
          <ActionCell
            title={'Delete event'}
            icon={'delete'}
            themeColor={'error'}
            className={'fs-2'}
            onClick={() => onDelete(props.dataItem.id)}
          />
        </ActionsCell>
      </>
    );
  };

  const onColumnTextClick = (props: GridCellProps) => (
    <EditCommandCell {...props} />
  );

  const EditCommandCell = (props: GridCellProps) => {
    return (
      <td>
        <span
          className={'link-primary cursor-pointer'}
          onClick={() => handleNameClick(props.dataItem.id, 'grid')}>
          {props.dataItem.name}
        </span>
      </td>
    );
  };

  const handleNameClick = (id: number, viewSource?: string) => {
    navigate('/myeventview/' + id);
  };

  const onEditEvent = (id: number) => {
    navigate('/myeventdetails/' + id);
  };

  const onViewEvent = (id: number) => {
    navigate('/myeventview/' + id);
  };

  const onConfirmDelete = async (result: YesNoDialogResult) => {
    if (result === 'yes') {
      try {
        appStore.showLoading();

        await DeleteEventAsync(selectedId);
        fetchEvents();
        toastStore.show('My events', <div>Event deleted.</div>, 'success');
      } catch {
        toastStore.show(
          'My events',
          <div>Failed to delete event.</div>,
          'error'
        );
      } finally {
        appStore.hideLoading();
      }
    }
    setShowDeleteConfirm(false);
  };

  const closeDeleteDialog = () => {
    setShowDeleteConfirm(false);
  };

  const gridExportRef = React.useRef<ExcelExport | null>(null);

  const DateCell = (props: GridCellProps) => {
    return <td>{formatDateToDDMMYYYY(props.dataItem.startDateUtc, true)}</td>;
  };

  return (
    <>
      <Toolbar title={gridMode === 'grid' ? 'My events' : 'Schedule'}>
        <HelpSupport
          title="My Events"
          url="https://forum.catalystglobal.com/t/my-events/"
        />
        <Button onClick={onAddEvent} themeColor={'primary'}>
          Add new
        </Button>
      </Toolbar>
      <div className={'pt-2'}>
        <GridToolBar
          searchPlaceholder={'Search events'}
          columnsToSearch={[
            'name',
            'game',
            'clientName',
            'leadFacilitatorName'
          ]}
          showCalendarMode={true}
          showCardMode={false}
          exportRef={gridExportRef}
          onGridModeChange={(e) => {
            const newState = {
              ...initialDataState
            };
            if (e.gridMode === 'grid') {
              newState.take = DefaultGridSettings.initialDataState.take;
            }
            setDataState(newState);
            setGridMode(e.gridMode);
          }}
          {...dataState}
          onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
            setDataState(e.dataState);
          }}></GridToolBar>
        <ExcelExport
          data={events.data}
          ref={gridExportRef}
          fileName={`events.xlsx`}>
          {gridMode === 'grid' && (
            <Grid
              id={'grid-events'}
              pageable={DefaultGridSettings.pagerSettings}
              sortable={true}
              data={events}
              {...dataState}
              onDataStateChange={(e: GridDataStateChangeEvent) => {
                setDataState(e.dataState);
              }}>
              <Column field={'startDateUtc'} title={'Date'} cell={DateCell} />
              <Column
                field={'name'}
                title={'Name'}
                cell={onColumnTextClick}
                columnMenu={ColumnMenu}
              />
              <Column cell={gameColumn} title={'Game'} />
              <Column
                field={'clientType'}
                title={'Client'}
                columnMenu={ColumnMenu}
              />
              <Column
                field={'leadFacilitatorName'}
                title={'Facilitator'}
                columnMenu={ColumnMenu}
              />
              <Column
                field={'approxPax'}
                title={'Pax'}
                columnMenu={ColumnMenu}
              />
              <Column
                field={'status'}
                title={'Status'}
                columnMenu={ColumnMenu}
              />
              <Column
                field={'eventActions'}
                title={'Actions'}
                cell={onActionRow}
                width={210}
              />
              <GridNoRecords>
                <NoRecords />
              </GridNoRecords>
            </Grid>
          )}
          {gridMode === 'cal' && <EventsScheduler eventData={events} />}
        </ExcelExport>
        {showDeleteConfirm && (
          <YesNoDialog
            title={'Remove Event'}
            onConfirm={onConfirmDelete}
            onClose={closeDeleteDialog}>
            Are you sure you want to delete this event?
          </YesNoDialog>
        )}
      </div>
    </>
  );
};
export default EventsGrid;
