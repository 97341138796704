import {
  GRID_COL_INDEX_ATTRIBUTE,
  GridCellProps
} from '@progress/kendo-react-grid';
import { Link, To } from 'react-router-dom';
import { useTableKeyboardNavigation } from '@progress/kendo-react-data-tools';
import React from 'react';

interface LinkCellProps extends GridCellProps {
  to: To;
  valueCell?: any;
}

export const LinkCell = ({ to, valueCell, ...props }: LinkCellProps) => {
  const field = props.field || '';
  const value = valueCell ? valueCell : props.dataItem[field];
  const navigationAttributes = useTableKeyboardNavigation(props.id);
  return (
    <td
      colSpan={props.colSpan}
      role={'gridcell'}
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      {...{ [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex }}
      {...navigationAttributes}>
      <Link to={to} className={'link-primary'}>
        {value ? value : ''}
      </Link>
    </td>
  );
};

export default LinkCell;
