import useSWR from 'swr';
import { Assessment } from '../types/assessment';
import { useApi } from './use-api';

export const useAssessmentDocument = (id: number | string | undefined) => {
  const api = useApi();

  const {
    data: document,
    isLoading: documentIsLoading,
    error: documentError
  } = useSWR<Assessment>(
    () => (id ? `assessments/${id}/documents/latest` : null),
    api.fetchers.apiWithAuth,
    { suspense: true }
  );

  // load the game document. immutable as the document shouldn't change.
  const {
    data: content,
    isLoading: contentIsLoading,
    error: contentError
  } = useSWR<Assessment>(
    () => (id ? `assessments/${id}/documents/latest/content` : null),
    api.fetchers.apiWithAuth,
    { suspense: true }
  );

  if (documentError) console.error(documentError);
  if (contentError) console.error(contentError);

  return {
    document,
    content,
    isLoading: documentIsLoading || contentIsLoading,
    isError: documentError || contentError
  };
};
