import React, { FormEvent, useState } from 'react';
import { Label } from '@progress/kendo-react-labels';
import { DropDownList } from '@progress/kendo-react-dropdowns';

import { StandardInput, StandardTextarea } from '../../components/forms';
import SupportTicketFormSubmit from './support-ticket-form-submit';
import {
  SupportTicketPayload,
  SupportTicketResponse
} from '../../types/responses/support-ticket';
import { appStore } from '../../stores/app-store';
import {
  PostSupportTicket,
  PostSupportTicketAttachment
} from '../../services/support-ticket';
import { toastStore } from '../../stores/toast-store';
import {
  ResourceWindow,
  UploadedImage
} from '../../features/game-document/image-resource/resource-window';
import cloneDeep from 'lodash.clonedeep';
import PopupMenu from '../../components/popupMenu';
import Video from '../../components/video';
import { isEmpty } from 'lodash';
import { MenuList } from '../../features/game-document/maps/map-images-window';
import { PostResourceAsync } from '../../services/files';

const browserValueMap = {
  'Google Chrome': 'GoogleChrome',
  'Microsoft Edge': 'MicrosoftEdge',
  'Mozilla Firefox': 'MozillaFirefox',
  Opera: 'Opera'
};

interface UserSupportTicketFormProps {
  setSelectedMenuItemName: React.Dispatch<React.SetStateAction<string>>;
}

const UserSupportTicketForm: React.FC<UserSupportTicketFormProps> = ({
  setSelectedMenuItemName
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [supportTicket, setSupportTicket] = useState<SupportTicketPayload | {}>(
    { browser: 'GoogleChrome', operatingSystem: 'Windows' }
  );
  const [media, setMedia] = useState<UploadedImage[]>([]);
  const [mediaPreview, setMediaPreview] = useState<UploadedImage>();

  const onMultipleFilesHandler = (files: UploadedImage[]) => {
    const temp = cloneDeep(media);
    files?.forEach((file) => {
      temp.push(file);
    });
    setMedia(temp);
    setShowModal(false);
  };

  const onSelectedMenu = (id: number, menu: MenuList) => {
    const temp = cloneDeep(media);
    if (menu.textMenu === 'Delete') {
      if (mediaPreview) {
        const getIdx = media.findIndex(
          (e) => e.fileName === mediaPreview.fileName
        );
        if (getIdx === id) setMediaPreview(undefined);
      }
      temp.splice(id, 1);
    }
    setMedia(temp);
  };

  const onSubmit = React.useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      try {
        appStore.showLoading();

        const respSupportTicket = (await PostSupportTicket(
          supportTicket as SupportTicketPayload
        )) as SupportTicketResponse;

        const mediaResp = await Promise.all(
          media.map(async (e) => {
            const respBlob = await fetch(e.blobUrl);
            const blob = await respBlob.blob();
            const file = new File([blob], e.fileName, { type: blob.type });
            const respUpload = await PostResourceAsync(file);
            return { path: respUpload.value, fileName: respUpload.name || '' };
          })
        );

        const mediaPayload = mediaResp.map((e) => ({
          supportTicketId: respSupportTicket.id,
          fileName: e.fileName,
          filePath: e.path
        }));

        await PostSupportTicketAttachment(respSupportTicket.id, mediaPayload);
        setSelectedMenuItemName('Support');

        toastStore.show(
          'Support Ticket',
          <div>Support ticket submitted.</div>,
          'success'
        );
      } catch (error) {
        toastStore.show(
          'Support Ticket',
          <div>Support ticket failed to submit. Please try again later.</div>,
          'error'
        );
      } finally {
        appStore.hideLoading();
      }
    },
    [supportTicket, media, setSelectedMenuItemName]
  );

  return (
    <div className={'w-100 flex-grow-1 d-flex flex-column'}>
      <div className={'flex-grow-1 pt-2 flex-column gap-2'}>
        <div className={'border-2 border-bottom'}>
          <div className={'text-success d-flex align-items-center pb-2 gap-2'}>
            <span className={'material-symbols-outlined'}>support</span>
            <h2 className={'m-0'}>Support</h2>
          </div>
        </div>

        <div className={'mt-3 pb-3 border-2 border-bottom'}>
          New support ticket
        </div>

        <form
          className={'mt-3 d-flex flex-column gap-3'}
          id={'supportTicketForm'}
          onSubmit={onSubmit}>
          <StandardInput
            label={'Title'}
            name={'Title'}
            onChange={(e) =>
              setSupportTicket({ ...supportTicket, title: e.target.value })
            }
            validationMessage={''}
          />

          <div className={'row'}>
            <div className={'col-4'}>
              <div className={'d-flex flex-column gap-2'}>
                <Label>Operating System</Label>
                <DropDownList
                  style={{
                    backgroundColor: 'white',
                    width: '100%'
                  }}
                  defaultValue={'Windows'}
                  data={['Mac', 'Linux', 'Windows']}
                  popupSettings={{
                    animate: false
                  }}
                  onChange={(e) =>
                    setSupportTicket({
                      ...supportTicket,
                      operatingSystem: e.target.value
                    })
                  }
                />
              </div>
            </div>
            <div className={'col-4'}>
              <div className={'d-flex flex-column gap-2'}>
                <Label>Browser</Label>
                <DropDownList
                  style={{
                    backgroundColor: 'white',
                    width: '100%'
                  }}
                  defaultValue={'Google Chrome'}
                  data={[
                    'Google Chrome',
                    'Microsoft Edge',
                    'Mozilla Firefox',
                    'Opera'
                  ]}
                  popupSettings={{
                    animate: false
                  }}
                  onChange={(e) =>
                    setSupportTicket({
                      ...supportTicket,
                      browser:
                        browserValueMap[
                          e.target.value as keyof typeof browserValueMap
                        ]
                    })
                  }
                />
              </div>
            </div>
          </div>

          <StandardTextarea
            label={'Description'}
            name={'Description'}
            rows={4}
            onChange={(e) =>
              setSupportTicket({
                ...supportTicket,
                description: e.target.value
              })
            }
          />

          <div className={'d-flex flex-column gap-2 mb-4'}>
            <Label>Attachment</Label>

            <div className={'row'}>
              <div className={'d-flex flex-column gap-2'}>
                {!media.length && (
                  <div
                    className={
                      'w-50 h-3 border border-1 bg-light rounded d-flex gap-2 justify-content-center align-items-center cursor-pointer text-decoration-none'
                    }
                    onClick={() => setShowModal(true)}>
                    <span className={'material-symbols-outlined text-primary'}>
                      add_photo_alternate
                    </span>
                    Browse
                  </div>
                )}

                {mediaPreview && !isEmpty(mediaPreview.blobUrl) && (
                  <div className="d-flex flex-column gap-2 w-100">
                    <Video
                      url={mediaPreview.blobUrl}
                      loop={true}
                      type={
                        mediaPreview.mimeType.includes('video')
                          ? 'video'
                          : 'image'
                      }
                    />
                    <button
                      className="btn btn-danger"
                      onClick={() => setMediaPreview(undefined)}>
                      Close Preview
                    </button>
                  </div>
                )}

                {media.length > 0 && (
                  <div className="d-flex w-100 flex-wrap gap-2">
                    {media.map((file, idx) => (
                      <div className={'d-flex'} key={idx}>
                        <div
                          key={idx}
                          className={
                            'w-8 h-8 bg-light position-relative rounded d-flex overflow-hidden flex-column justify-content-center align-items-center'
                          }
                          onClick={() => setMediaPreview(file)}>
                          {file && file.mimeType.includes('video') && (
                            <>
                              <video className={'rounded'}>
                                <source src={file.blobUrl} type={'video/mp4'} />
                              </video>
                              <div
                                className={
                                  'mask position-absolute top-0 end-0 h-full w-full d-flex align-items-center justify-content-center'
                                }
                                style={{
                                  backgroundImage: `url("${file.blobUrl}")`,
                                  backgroundPosition: 'center'
                                }}>
                                <button
                                  type="button"
                                  className={'btn text-light'}>
                                  <span className={'material-symbols-outlined'}>
                                    play_arrow
                                  </span>
                                </button>
                              </div>
                            </>
                          )}
                          {file && file.mimeType.includes('image') && (
                            <img
                              src={file.blobUrl}
                              className={'rounded'}
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover'
                              }}
                              alt={file.fileName}
                            />
                          )}
                        </div>
                        <PopupMenu
                          menus={[
                            {
                              classIcon: 'delete',
                              textMenu: 'Delete',
                              textClass: 'text-danger'
                            }
                          ]}
                          id={idx}
                          onMenuSelected={onSelectedMenu}
                          idString={idx.toString()}
                        />
                      </div>
                    ))}

                    <div
                      className={
                        'w-8 h-8 border border-1 bg-light rounded d-flex flex-column justify-content-center align-items-center'
                      }
                      onClick={() => setShowModal(true)}>
                      <span
                        className={'material-symbols-outlined text-primary'}>
                        add_photo_alternate
                      </span>
                      Add
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>

      {showModal && (
        <ResourceWindow
          title={'Upload files'}
          onSubmitMultipleFiles={onMultipleFilesHandler}
          toggleDialog={() => {
            setShowModal(!showModal);
          }}
          isMultipleFiles={true}
          acceptedExtension={'image/*, video/*'}
          hideResourcesTab={true}
          hideUrlTab={true}
        />
      )}

      <SupportTicketFormSubmit
        onClose={() => setSelectedMenuItemName('Support')}
      />
    </div>
  );
};

export default UserSupportTicketForm;
