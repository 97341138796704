import { useNavigate } from 'react-router-dom';
import { Button } from '@progress/kendo-react-buttons';

import CardItem from './card-item';
import { GetSupportTicketsAsync } from '../../../services/support-tickets';

const SupportTicketCard = () => {
  const navigate = useNavigate();

  const support = [
    {
      text: 'New',
      url: '/administration/support_tickets?status=New'
    },
    {
      text: 'Open',
      url: '/administration/support_tickets?status=InProgress'
    }
  ];

  return (
    <div className={'d-flex flex-column py-2 w-100 h-100 gap-1'}>
      <span className={`material-symbols-outlined`}>support</span>
      <div
        className={'d-flex justify-content-between align-items-center fw-bold'}>
        <span>Support Tickets</span>
        <span className={`material-symbols-outlined fw-bold text-primary`}>
          chevron_right
        </span>
      </div>
      <hr className={'my-2 w-100'} />
      <div className={'flex-grow-1'}>
        {support.map((item, idx) => (
          <CardItem
            key={idx}
            goTo={item.url}
            text={item.text}
            isLastIndex={idx === support.length - 1}
            // @ts-ignore
            fetcher={GetSupportTicketsAsync(
              `filter=status~eq~%27${
                item.text === 'New' ? 'New' : 'InProgress'
              }%27&pageSize=1`
            )}
          />
        ))}
      </div>
      <hr className={'my-2 w-100'} />
      <Button
        themeColor={'primary'}
        onClick={() => navigate('/administration/support_tickets')}>
        View all
      </Button>
    </div>
  );
};

export default SupportTicketCard;
