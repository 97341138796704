import React from 'react';

import DropdownSelector from './dropdown-selector';
import SelectionItem from '../../types/selection-item';

export interface ArgumentSelectorProps {
  argumentList: SelectionItem[];
  onArgumentSelected: Function;
  selectedArgument?: SelectionItem;
}

export const ArgumentSelector: React.FC<ArgumentSelectorProps> = (props) => {
  const className = `argument--${
    props.selectedArgument === undefined ? 'select' : 'selected'
  }`;

  const onClick = (item: SelectionItem) => {
    props.onArgumentSelected(item);
  };

  React.useEffect(() => {
    if (props.argumentList.length > 0) {
      props.argumentList.sort((a, b) => {
        if (a.description && b.description) {
          if (a.description < b.description) return -1;
          if (a.description > b.description) return 1;
        }
        return 0;
      });
    }
  }, [props.argumentList]);

  return (
    <DropdownSelector<SelectionItem, 'name'>
      fieldName="name"
      items={props.argumentList}
      onClick={(e) => onClick(e)}
      additionalClassname={className}
      selected={props.selectedArgument?.name}
    />
  );
};
