import { GameDocument } from '../../../types/game-document';
import { ZoneEntity } from '../../../types/game-document/';
import { uuid } from '../../../types/common-helper';
import { DeleteMapAsync, GetNextAssetNameAsync, MergeAssets } from './index';
import { CopyResourceAsync, DeleteResourceAsync } from '../resources';

/**
 * Adds a new Zone to the Game document.
 * @param gameDocument - The Game Document to modify
 * @param name - The Name of the new Zone
 * @param description - The Description for the new Zone
 * @returns The updated Game Document
 */
export const AddZoneAsync = async (
  gameDocument: GameDocument,
  name: string,
  description: string,
  titleResId: string
) => {
  let zones = gameDocument.assets.zones ?? [];
  let zoneName = await GetNextAssetNameAsync(zones, name);

  zones.push({
    id: uuid(),
    name: zoneName,
    description,
    titleResId,
    isVisible: true
  });
  return MergeAssets(gameDocument, zones, 'zones');
};

/**
 * Add Zone.
 * @param gameDocument - The Game Document to modify
 * @param tasks - The new zone
 * @return The updated Game Document
 */
export const AddZoneEntityAsync = async (
  gameDocument: GameDocument,
  zone: ZoneEntity
) => {
  let zones = gameDocument.assets.zones ?? [];
  let zoneName = await GetNextAssetNameAsync(zones, zone?.name);
  zone.name = zoneName;
  zones.push(zone);

  return MergeAssets(gameDocument, zones, 'zones');
};

/**
 * Deletes the identified Zone from the Game Document.
 * @param gameDocument - The Game Document to modify
 * @param zoneId - The ID of the Zone to delete
 * @returns The updated Game Document
 */
export const DeleteZoneAsync = async (
  gameDocument: GameDocument,
  zoneId: string
) => {
  let zones = gameDocument.assets.zones ?? [];
  let zoneIndex = zones.findIndex((i) => i.id === zoneId)!;

  //Delete map from map assets

  const mapAssetId = gameDocument?.rules?.worldMap?.zones?.find(
    (x) => x.zoneAssId === zoneId
  )?.mapAssId;

  if (mapAssetId !== undefined || mapAssetId !== '') {
    await DeleteMapAsync(gameDocument, mapAssetId!);
  }

  //End delete map from map assets

  if (zoneIndex !== -1) {
    await DeleteResourceAsync(gameDocument, zones[zoneIndex].titleResId!);
    zones.splice(zoneIndex, 1);
  }
  return MergeAssets(gameDocument, zones, 'zones');
};

/**
 * Updates the identified Zone in the Game Document.
 * @param gameDocument - The Game Document to modify
 * @param zoneId - The ID of the Zone to update
 * @param zone - The updated Zone
 * @constructor
 */
export const UpdateZoneAsync = async (
  gameDocument: GameDocument,
  zoneId: string,
  zone: ZoneEntity
) => {
  let zones = gameDocument.assets.zones ?? [];
  let zoneIndex = zones.findIndex((i) => i.id === zoneId)!;
  zone.name = await GetNextAssetNameAsync(zones, zone.name, zone.id);
  zones[zoneIndex] = zone;

  return MergeAssets(gameDocument, zones, 'zones');
};

export const UpdateZone = (
  gameDocument: GameDocument,
  zoneId: string,
  zone: ZoneEntity
) => {
  let zones = gameDocument.assets.zones ?? [];
  let zoneIndex = zones.findIndex((i) => i.id === zoneId)!;
  GetNextAssetNameAsync(zones, zone.name, zone.id)
    .then((nextName: string) => {
      zone.name = nextName;
      zones[zoneIndex] = zone;
      return MergeAssets(gameDocument, zones, 'zones');
    })
    .catch((error: any) => {
      // Handle errors here
      console.error('Error:', error);
    });

  zones[zoneIndex] = zone;

  return MergeAssets(gameDocument, zones, 'zones');
};

/**
 * Create a copy of the Zone in the Game document.
 * @param gameDocument - The Game Document to modify
 * @param zoneId - The ID of the Zone to copy
 * @param copiedZoneId - The zone id to copy but different
 * @returns The updated Game Document
 */
export const CopyZoneAsync = async (
  gameDocument: GameDocument,
  zoneId: string,
  copiedZoneId: string = uuid()
) => {
  let zones = gameDocument.assets.zones ?? [];
  let zoneIndex = zones.findIndex((i) => i.id === zoneId)!;
  if (zoneIndex !== -1) {
    let zoneCopy: ZoneEntity = {
      ...zones[zoneIndex],
      titleResId: uuid()
    };
    await CopyResourceAsync(
      gameDocument,
      zones[zoneIndex].titleResId!,
      zoneCopy.titleResId
    );

    zoneCopy.id = copiedZoneId;
    zoneCopy.name += '-copy';
    zoneCopy.name = await GetNextAssetNameAsync(
      zones,
      zoneCopy.name,
      zoneCopy.id
    );

    zones.push(zoneCopy);
  }
  return MergeAssets(gameDocument, zones, 'zones');
};

/**
 * Get all zones from game document local storage.
 * @param gameDocument - The Game Document as datasource
 * @constructor
 */
export const GetZones = (gameDocument: GameDocument | undefined) => {
  return gameDocument?.assets.zones ?? [];
};

/**
 * Get zones by zone id from game document local storage.
 * @param gameDocument - The Game Document as datasource
 * @param zoneId - The zone id you are looking for
 * @constructor
 */
export const GetZoneById = (
  gameDocument: GameDocument | undefined,
  zoneId: string
) => {
  return gameDocument?.assets?.zones?.find((x) => x.id === zoneId);
};

/**
 * Get zones by map id from game document local storage.
 * @param gameDocument - The Game Document as datasource
 * @param mapId - The map id you are looking for
 * @constructor
 */
export const GetZoneByMapId = (
  gameDocument: GameDocument | undefined,
  mapId: string
) => {
  return gameDocument?.rules?.worldMap?.zones.find((x) => x.mapAssId === mapId);
};

/**
 * Get zones by Ids from game document local storage.
 * @param gameDocument - The Game Document as datasource
 * @constructor
 */
export const GetZonesByIds = (gameDocument: GameDocument, ids: string[]) => {
  return (
    gameDocument?.assets?.zones?.filter((x) => ids.indexOf(x.id) !== -1) ?? []
  );
};
