import { Button } from '@progress/kendo-react-buttons';
import cloneDeep from 'lodash.clonedeep';
import { useContext, useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { GameDocumentContext } from '../../contexts/game-document';
import { uuid } from '../../types/common-helper';
import { GameDocument } from '../../types/game-document';
import {
  FormCustomFeedback,
  TaskContentForm
} from '../../types/game-document/entities/task-content';
import {
  AddResourceAsync,
  AllCorrectCustomFeedback,
  AllWrongCustomFeedback,
  GetResourceEntity,
  GetResourceValue,
  GetTaskContentById,
  PartialCorrectCustomFeedback,
  UpdateGameDocState,
  UpdateResourceAsync,
  UpdateTaskContentAsync
} from '../../utils/game-document';
import { CustomFeedbackMessage } from './custom-feedback-message';
import { defaultFeedbackMessage } from '../../constants/feedback-content';

interface CustomFeedbackProps {
  taskContentForm: TaskContentForm;
  taskContentId: string;
  gameDocument: GameDocument;
  entity?: FormCustomFeedback;
  onSubmit?: (entity: FormCustomFeedback) => void;
  onCancel?: () => void;
  isShowPartial?: boolean;
}

const defaultImage = {
  defaultFeedbackAllCorrectIconResId:
    'https://stsharedappstorage.blob.core.windows.net/prod-catalyst-files/checked.png',
  defaultFeedbackAllWrongIconResId:
    'https://stsharedappstorage.blob.core.windows.net/prod-catalyst-files/cross.png'
};

export const CustomFeedback = ({ ...props }: CustomFeedbackProps) => {
  const [entity, setEntity] = useState<FormCustomFeedback>(props.entity ?? {});
  const [state, setState] = useContext(GameDocumentContext);

  const handleResourceSubmit = async (
    allCorrectMessage?: string,
    partialCorrectMessage?: string,
    allWrongMessage?: string,
    cloneEntity?: FormCustomFeedback
  ) => {
    if (!cloneEntity?.isDefaultAllCorrect) {
      if (!cloneEntity?.allCorrectMessageResId) {
        addNewCustomFeedback(
          'All correct feedback message custom',
          allCorrectMessage ?? '',
          'allCorrect'
        );
      } else {
        updateCustomFeedback('allCorrect');
      }
    }

    if (props.isShowPartial && !cloneEntity?.isDefaultPartiallyCorrect) {
      if (!cloneEntity?.partiallyCorrectMessageResId) {
        addNewCustomFeedback(
          'Partial correct feedback message custom',
          partialCorrectMessage ?? '',
          'partialCorrect'
        );
      } else {
        updateCustomFeedback('partialCorrect');
      }
    }

    if (!cloneEntity?.isDefaultAllWrong) {
      if (!cloneEntity?.allWrongMessageResId) {
        addNewCustomFeedback(
          'All wrong feedback message custom',
          allWrongMessage ?? '',
          'allWrong'
        );
      } else {
        updateCustomFeedback('allWrong');
      }
    }
  };

  const addNewCustomFeedback = async (
    feedbackName: string,
    feedbackMessage: string,
    action: string
  ) => {
    let uuidFeedback = uuid();

    AddResourceAsync(
      state.gameDocument!,
      feedbackName,
      '',
      'text',
      feedbackMessage,
      uuidFeedback
    ).then((response) => {
      // set entity state for local storage
      let newEntity = cloneDeep(entity);
      if (action === 'allCorrect') {
        newEntity['allCorrectMessageResId'] = uuidFeedback;
      } else if (action === 'partialCorrect') {
        newEntity['partiallyCorrectMessageResId'] = uuidFeedback;
      } else if (action === 'allWrong') {
        newEntity['allWrongMessageResId'] = uuidFeedback;
      }
      setEntity(newEntity);

      // update task content for messageResId update
      let newTaskContentForm = cloneDeep(props.taskContentForm);
      if (action === 'allCorrect') {
        newTaskContentForm!.customFeedback!.allCorrectMessageResId =
          uuidFeedback;
      } else if (action === 'partialCorrect') {
        newTaskContentForm!.customFeedback!.partiallyCorrectMessageResId =
          uuidFeedback;
      } else if (action === 'allWrong') {
        newTaskContentForm!.customFeedback!.allWrongMessageResId = uuidFeedback;
      }

      let taskContent = GetTaskContentById(
        props.gameDocument,
        props.taskContentId
      );
      let taskContentFormIndex = taskContent?.forms!.findIndex(
        (item) => item.id === newTaskContentForm.id
      );
      taskContent!.forms![taskContentFormIndex!] = newTaskContentForm;

      UpdateTaskContentAsync(
        response,
        props.taskContentForm.id,
        taskContent!
      ).then((responseUpdateTask) => {
        setState((prev) => UpdateGameDocState(prev, responseUpdateTask));
      });
    });
  };
  const addDefaultCustomFeedbackToResource = async (
    feedbackName: string,
    feedbackMessage: string,
    action: string,
    uuidFeedback: string
  ) => {
    AddResourceAsync(
      state.gameDocument!,
      feedbackName,
      '',
      'text',
      feedbackMessage,
      uuidFeedback
    ).then((response) => {
      // set entity state for local storage
      let newEntity = cloneDeep(entity);
      if (action === 'allCorrect') {
        newEntity['allCorrectMessageResId'] = uuidFeedback;
      } else if (action === 'partialCorrect') {
        newEntity['partiallyCorrectMessageResId'] = uuidFeedback;
      } else if (action === 'allWrong') {
        newEntity['allWrongMessageResId'] = uuidFeedback;
      }
      setEntity(newEntity);

      // update task content for messageResId update
      let newTaskContentForm = cloneDeep(props.taskContentForm);
      if (action === 'allCorrect') {
        newTaskContentForm!.customFeedback!.allCorrectMessageResId =
          uuidFeedback;
      } else if (action === 'partialCorrect') {
        newTaskContentForm!.customFeedback!.partiallyCorrectMessageResId =
          uuidFeedback;
      } else if (action === 'allWrong') {
        newTaskContentForm!.customFeedback!.allWrongMessageResId = uuidFeedback;
      }

      let taskContent = GetTaskContentById(
        props.gameDocument,
        props.taskContentId
      );
      let taskContentFormIndex = taskContent?.forms!.findIndex(
        (item) => item.id === newTaskContentForm.id
      );
      taskContent!.forms![taskContentFormIndex!] = newTaskContentForm;

      UpdateTaskContentAsync(
        response,
        props.taskContentForm.id,
        taskContent!
      ).then((responseUpdateTask) => {
        setState((prev) => UpdateGameDocState(prev, responseUpdateTask));
      });
    });
  };

  const updateCustomFeedback = async (action: string) => {
    let messageResId = '';
    let message = '';

    if (action === 'allCorrect') {
      messageResId = entity?.allCorrectMessageResId!;
      message = entity.allCorrectMessage!;
    } else if (action === 'partialCorrect') {
      messageResId = entity?.partiallyCorrectMessageResId!;
      message = entity.partiallyCorrectMessage!;
    } else if (action === 'allWrong') {
      messageResId = entity?.allWrongMessageResId!;
      message = entity.allWrongMessage!;
    }

    let customFeedbackResource = GetResourceEntity(
      state.gameDocument!,
      messageResId
    );
    customFeedbackResource.value = message;
    await UpdateResourceAsync(
      state.gameDocument!,
      messageResId,
      customFeedbackResource
    );
  };

  const imageUrl = useCallback(
    (
      res:
        | 'defaultFeedbackAllCorrectIconResId'
        | 'defaultFeedbackAllWrongIconResId'
    ) => {
      if (
        state.gameDocument?.settings &&
        state.gameDocument?.settings.designer
      ) {
        const feedbackIcon = GetResourceValue(
          state.gameDocument,
          state.gameDocument.settings.designer[res]
        );

        return feedbackIcon !== '' ? feedbackIcon : defaultImage[res];
      } else {
        return res === 'defaultFeedbackAllCorrectIconResId'
          ? defaultImage.defaultFeedbackAllCorrectIconResId
          : defaultImage.defaultFeedbackAllWrongIconResId;
      }
    },
    [state.gameDocument?.settings]
  );

  const defaultMessage = useCallback(
    (
      type:
        | 'defaultAllCorrectFeedbackMessageResId'
        | 'defaultAllWrongFeedbackMessageResId'
    ) => {
      if (
        state.gameDocument?.settings &&
        state.gameDocument?.settings.designer
      ) {
        const message = GetResourceValue(
          state.gameDocument,
          state.gameDocument.settings.designer[type] ?? ''
        );

        const value = message == '' ? defaultFeedbackMessage[type] : message;
        return value;
      }

      return defaultFeedbackMessage[type];
    },
    [state.gameDocument?.settings]
  );

  return (
    <>
      <Row>
        <Col lg={4} md={12} sm={12} xs={12}>
          <CustomFeedbackMessage
            title={'Excellent!'}
            message={
              entity?.isDefaultAllCorrect
                ? defaultMessage('defaultAllCorrectFeedbackMessageResId')
                : entity.allCorrectMessage
            }
            isDefault={entity?.isDefaultAllCorrect}
            url={
              entity?.isDefaultAllCorrect || !entity?.allCorrectUrlResId
                ? imageUrl('defaultFeedbackAllCorrectIconResId')
                : GetResourceValue(
                    state?.gameDocument!,
                    entity?.allCorrectUrlResId
                  )
            }
            onMessageChange={(message) => {
              setEntity((prev) => ({
                ...prev,
                allCorrectMessage: message
              }));
            }}
            onChangeIsDefault={(value) => {
              setEntity((prev) => ({
                ...prev,
                isDefaultAllCorrect: value,
                allCorrectMessage: value
                  ? defaultMessage('defaultAllCorrectFeedbackMessageResId')
                  : entity?.allCorrectMessage,
                allCorrectUrlResId: value ? '' : entity?.allCorrectUrlResId
              }));
            }}
            onUpload={(resId) => {
              setEntity((prev) => ({
                ...prev,
                allCorrectUrlResId: resId
              }));
            }}
          />
        </Col>
        {props.isShowPartial && (
          <Col lg={4} md={12} sm={12} xs={12}>
            <CustomFeedbackMessage
              title={'Partially correct'}
              message={
                entity?.isDefaultPartiallyCorrect
                  ? 'That answer was partially correct! Partial points awarded.'
                  : entity.partiallyCorrectMessage
              }
              isDefault={entity?.isDefaultPartiallyCorrect}
              url={
                entity?.isDefaultPartiallyCorrect ||
                !entity?.partiallyCorrectUrlResId
                  ? `/checked.png`
                  : GetResourceValue(
                      state?.gameDocument!,
                      entity?.partiallyCorrectUrlResId
                    )
              }
              onMessageChange={(message) => {
                setEntity((prev) => ({
                  ...prev,
                  partiallyCorrectMessage: message
                }));
              }}
              onChangeIsDefault={(value) => {
                setEntity((prev) => ({
                  ...prev,
                  isDefaultPartiallyCorrect: value,
                  partiallyCorrectMessage: value
                    ? 'That answer was partially correct! Partial points awarded.'
                    : entity?.partiallyCorrectMessage,
                  partiallyCorrectUrlResId: value
                    ? ''
                    : entity?.partiallyCorrectUrlResId
                }));
              }}
              onUpload={(resId) => {
                setEntity((prev) => ({
                  ...prev,
                  partiallyCorrectUrlResId: resId
                }));
              }}
            />
          </Col>
        )}
        <Col lg={4} md={12} sm={12} xs={12}>
          <CustomFeedbackMessage
            title={'Unlucky.'}
            message={
              entity?.isDefaultAllWrong
                ? defaultMessage('defaultAllWrongFeedbackMessageResId')
                : entity.allWrongMessage
            }
            isDefault={entity?.isDefaultAllWrong}
            url={
              entity?.isDefaultAllWrong || !entity?.allWrongUrlResId
                ? imageUrl('defaultFeedbackAllWrongIconResId')
                : GetResourceValue(
                    state?.gameDocument!,
                    entity?.allWrongUrlResId
                  )
            }
            onMessageChange={(message) => {
              setEntity((prev) => ({
                ...prev,
                allWrongMessage: message
              }));
            }}
            onChangeIsDefault={(value) => {
              setEntity((prev) => ({
                ...prev,
                isDefaultAllWrong: value,
                allWrongMessage: value
                  ? defaultMessage('defaultAllWrongFeedbackMessageResId')
                  : entity?.allWrongMessage,
                allWrongUrlResId: value ? '' : entity?.allWrongUrlResId
              }));
            }}
            onUpload={(resId) => {
              setEntity((prev) => ({
                ...prev,
                allWrongUrlResId: resId
              }));
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col className={'d-flex justify-content-end mt-3'}>
          <Button
            themeColor={'light'}
            className={'mr-2'}
            onClick={() => {
              if (props.onCancel) {
                props.onCancel();
              }
            }}>
            Cancel
          </Button>
          <Button
            themeColor={'primary'}
            onClick={async () => {
              if (props.onSubmit) {
                let cloneEntity = cloneDeep(entity);

                if (
                  cloneEntity.isDefaultAllCorrect &&
                  cloneEntity.allCorrectMessageResId === ''
                ) {
                  const correctMessage = await AllCorrectCustomFeedback(
                    state.gameDocument!,
                    defaultMessage('defaultAllCorrectFeedbackMessageResId')
                  );
                  const correctMessageId = uuid();
                  cloneEntity.allCorrectMessage = correctMessage;

                  cloneEntity.allCorrectMessageResId =
                    cloneEntity.allCorrectMessageResId === ''
                      ? correctMessageId
                      : cloneEntity.allCorrectMessageResId;

                  await addDefaultCustomFeedbackToResource(
                    'All correct feedback message custom',
                    correctMessage ?? '',
                    'allCorrect',
                    correctMessageId
                  );
                }
                if (
                  props.isShowPartial &&
                  cloneEntity.isDefaultPartiallyCorrect &&
                  cloneEntity.partiallyCorrectMessageResId === ''
                ) {
                  const partialyMessage = await PartialCorrectCustomFeedback(
                    state.gameDocument!
                  );
                  cloneEntity.partiallyCorrectMessage = partialyMessage;
                  const partialyMessageId = uuid();
                  cloneEntity.partiallyCorrectMessageResId =
                    cloneEntity.partiallyCorrectMessageResId === ''
                      ? partialyMessageId
                      : cloneEntity.partiallyCorrectMessageResId;

                  await addDefaultCustomFeedbackToResource(
                    'Partial correct feedback message custom',
                    partialyMessage ?? '',
                    'partialCorrect',
                    partialyMessageId
                  );
                }
                if (
                  cloneEntity.isDefaultAllWrong &&
                  cloneEntity.allWrongMessageResId === ''
                ) {
                  const wrongMessage = await AllWrongCustomFeedback(
                    state.gameDocument!,
                    defaultMessage('defaultAllWrongFeedbackMessageResId')
                  );
                  const wrongMessageId = uuid();
                  cloneEntity.allWrongMessage = wrongMessage;
                  cloneEntity.allWrongMessageResId =
                    cloneEntity.allWrongMessageResId === ''
                      ? wrongMessageId
                      : cloneEntity.allWrongMessageResId;

                  await addDefaultCustomFeedbackToResource(
                    'All wrong feedback message custom',
                    wrongMessage ?? '',
                    'allWrong',
                    wrongMessageId
                  );
                }
                handleResourceSubmit(
                  cloneEntity.allCorrectMessage ?? undefined,
                  cloneEntity.partiallyCorrectMessage ?? undefined,
                  cloneEntity.allWrongMessage ?? undefined,
                  cloneEntity
                ).then(() => {
                  props!.onSubmit!(cloneEntity!);
                });
              }
            }}>
            Confirm
          </Button>
        </Col>
      </Row>
    </>
  );
};
