import {
  ComponentType,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import {
  Window,
  WindowActionsBar,
  WindowProps
} from '@progress/kendo-react-dialogs';
import { FluidForm } from '../../../components/forms';
import { TaskEditor } from './task-editor';
import { ResourceEntity, TaskEntity } from '../../../types/game-document/';

import { Button } from '@progress/kendo-react-buttons';
import { EditorMode } from '../../../types/editor-mode';
import { EntityEditor } from '../../../types/game-document/entity-editor';
import { GameDocumentContext } from '../../../contexts/game-document';
import cloneDeep from 'lodash.clonedeep';
import TaskEditorFull from './task-editor-full';
import RequiredFields from '../../../types/required-fields';
import { TaskEvent } from '../../../types/game-document/entities/task';
import {
  ERR_DUPLICATE_NAME_VALUE,
  ERR_INPUT_REQUIRED
} from '../../../constants/text';
import { uuid } from '../../../types/common-helper';
import {
  AddResourceAsync,
  UpdateGameDocState
} from '../../../utils/game-document';
import { AddTaskContentAsync } from '../../../utils/game-document/assets/task-contents';
import { UploadedImage } from '../image-resource/resource-window';
import { useGameDocumentResources } from '../../../hooks/use-game-document-resources';

interface TaskEditorWindowProps extends WindowProps {
  editorEntity: EntityEditor<TaskEntity>;
  editorResource: EntityEditor<ResourceEntity>[];
  toggleDialog: Function;
  onSubmit: (
    editorEntity: EntityEditor<TaskEntity>,
    resourceEntity: EntityEditor<ResourceEntity>[]
  ) => void;
  editorMode: EditorMode;
  //Existing task only show tasks that have no boundaries/coordinate
  showExistingTask?: boolean;
  onSelectedExistingTask?: (taskId: string) => void;
}

export const TaskEditorWindow: ComponentType<TaskEditorWindowProps> = ({
  editorEntity,
  editorResource,
  toggleDialog,
  onSubmit,
  editorMode,
  onSelectedExistingTask = () => {},
  ...props
}: TaskEditorWindowProps) => {
  const [state, setState] = useContext(GameDocumentContext);
  const resourceFiles = useGameDocumentResources();
  const [selectedExistingTask, setSelectedExistingTask] = useState<string>('');
  const [entity, setEntity] = useState<TaskEntity>(
    cloneDeep(editorEntity.entity)
  );

  const [resources, setResources] = useState<ResourceEntity[]>(
    editorResource.length > 0
      ? [...editorResource.map((task) => task.entity)]
      : []
  );

  const [requiredFields, setRequiredFields] = useState<
    RequiredFields<TaskEntity>[]
  >([
    { name: 'name', errorMessage: '' },
    { name: 'titleResId', errorMessage: '' }
  ]);

  const isInputValid = (): boolean => {
    const inputValidation = requiredFields.map((input) => {
      const entityValue = entity[input.name as keyof TaskEntity];
      const taskIndex = state.gameDocument?.assets?.tasks?.findIndex(
        (task) =>
          task.name?.toLowerCase() === (entityValue as string)?.toLowerCase()
      );

      if (input.name === 'name') {
        // Check validation for input name
        if (entityValue === '') {
          input.errorMessage = ERR_INPUT_REQUIRED;
        } else if (
          taskIndex !== -1 &&
          state.gameDocument?.assets?.tasks![taskIndex!]?.id !== entity.id
        ) {
          input.errorMessage = ERR_DUPLICATE_NAME_VALUE;
        } else {
          input.errorMessage = '';
        }
      } else if (input.name === 'titleResId') {
        // Check validation for input titleResId
        const resourceIndex = resources.findIndex(
          (resource) => resource.id === entityValue
        );
        if (resources[resourceIndex]?.value === '') {
          input.errorMessage = ERR_INPUT_REQUIRED;
        } else {
          input.errorMessage = '';
        }
      }

      return input;
    });
    setRequiredFields(inputValidation);
    return (
      inputValidation.findIndex((input) => input.errorMessage !== '') === -1
    );
  };

  const addMissingResource = useCallback(
    (
      name: string,
      description: string,
      resourceType: string,
      value: string,
      id: string
    ) => {
      if (state.gameDocument) {
        AddResourceAsync(
          state.gameDocument,
          name,
          description,
          resourceType,
          value,
          id
        ).then((doc) => setState((prev) => UpdateGameDocState(prev, doc)));
      }
    },
    [state.gameDocument]
  );

  useEffect(() => {
    if (
      !editorEntity.isNew &&
      [...editorResource.map((task) => task.entity)].some(
        (res) => res === undefined
      )
    ) {
      const resValue = {
        completeImageResId:
          'https://cdn.catalystglobal.games/resources/map-task--complete.png',
        imageResId: 'https://cdn.catalystglobal.games/resources/map-task.png',
        titleResId: entity.name
      };
      const requiredRes = ['completeImageResId', 'imageResId', 'titleResId'];
      const requiredId = requiredRes.map(
        (res) => entity[res as keyof TaskEntity]
      );
      setResources((prev) => {
        const cloneRes = cloneDeep(prev).filter((res) => res !== undefined);
        cloneRes.forEach((res) => {
          const findRes = requiredId.findIndex((id) => id === res.id);
          if (findRes !== -1) {
            requiredRes.splice(findRes, 1);
            requiredId.splice(findRes, 1);
          }
        });

        requiredId.forEach((id, idx) => {
          const newResource = {
            id,
            name: requiredRes[idx],
            description: '',
            type: requiredRes[idx].includes('image') ? 'image' : 'text',
            value: resValue[requiredRes[idx] as keyof typeof resValue]
          };
          addMissingResource(
            newResource.name,
            newResource.description,
            newResource.type,
            newResource.value,
            id as string
          );
          cloneRes.push(newResource as TaskEntity);
        });

        return cloneRes;
      });
    }
  }, [editorEntity.isNew, editorResource, entity]);

  const handleTaskResourceUpload = useCallback(
    (field: keyof TaskEntity, upload: UploadedImage) => {
      setResources((prev) =>
        prev.map((resource: ResourceEntity) =>
          resource.id === entity[field]
            ? resourceFiles.mergeResource(resource, upload)
            : { ...resource }
        )
      );
    },
    [entity, resourceFiles]
  );

  const handleTaskChange = useCallback(
    (
      field: keyof TaskEntity,
      value: boolean | string | TaskEvent,
      name?: string
    ) => {
      if (value !== '-') {
        if (field.toLowerCase().endsWith('resid')) {
          setResources((prev) =>
            prev.map((resource: ResourceEntity) =>
              resource.id === entity[field]
                ? { ...resource, value: value as string, name: name ?? '' }
                : { ...resource }
            )
          );
        } else if (field === 'events') {
          setEntity((prev) => ({ ...prev, events: value as TaskEvent }));
        } else {
          setEntity((prev) => ({ ...prev, [field!]: value as string }));
        }
      } else {
        const titleGuid = uuid();
        const preMessageGuid = uuid();
        const contentGuid = uuid();
        const taskContentId = uuid();
        const addTitleResource = AddResourceAsync(
          state.gameDocument!,
          'Title',
          '',
          'text',
          '',
          titleGuid
        );
        const addContentResource = AddResourceAsync(
          state.gameDocument!,
          'Content',
          '',
          'text-md',
          '',
          contentGuid
        );
        const addPreMessageResource = AddResourceAsync(
          state.gameDocument!,
          'PreMessage',
          '',
          'text-md',
          '',
          preMessageGuid
        );
        Promise.all([
          addTitleResource,
          addContentResource,
          addPreMessageResource
        ]).then(() => {
          AddTaskContentAsync(
            state.gameDocument!,
            `${entity.name} - ${
              field === 'taskContentId' ? 'task content' : 'task callout'
            }`, // Task content name
            `${entity.name} - ${
              field === 'taskContentId' ? 'task content' : 'task callout'
            }`, // Task content description
            'HTML',
            titleGuid,
            preMessageGuid,
            contentGuid,
            taskContentId
          ).then((updatedGameDocument) => {
            setState((state) => UpdateGameDocState(state, updatedGameDocument));
            setEntity((prev) => ({ ...prev, [field!]: taskContentId }));
          });
        });
      }
    },
    []
  );

  const onSubmitHandler = () => {
    if (isInputValid()) {
      const entityResources = resources.map((item, index) => ({
        isNew: editorResource[index]?.isNew ?? true,
        entity: item
      })) as EntityEditor<ResourceEntity>[];
      onSubmit({ isNew: editorEntity.isNew, entity }, entityResources);
    }
  };

  return (
    <>
      <Window
        modal={true}
        minimizeButton={undefined}
        className={'shadow'}
        initialWidth={editorEntity.isNew ? 550 : 900}
        initialHeight={editorEntity.isNew ? 550 : 750}
        onClose={() => toggleDialog()}
        title={editorEntity.isNew ? 'New task' : editorEntity.entity.name}
        {...props}>
        <FluidForm>
          {editorEntity.isNew ? (
            <TaskEditor
              editorMode={editorMode}
              entity={entity}
              resources={resources}
              taskContents={state.gameDocument?.assets?.taskContents}
              handleTaskChange={handleTaskChange}
              requiredFields={requiredFields}
              showExistingTask={props.showExistingTask}
              handleExistingTask={(v) => setSelectedExistingTask(v)}
            />
          ) : (
            <TaskEditorFull
              entity={entity}
              requiredFields={requiredFields!}
              taskContents={state.gameDocument?.assets?.taskContents}
              resources={resources}
              handleTaskChange={handleTaskChange}
              handleTaskResourceUpload={handleTaskResourceUpload}
            />
          )}
        </FluidForm>
        <WindowActionsBar>
          <Button themeColor={'secondary'} onClick={() => toggleDialog()}>
            Cancel
          </Button>
          <Button
            themeColor={'primary'}
            onClick={
              selectedExistingTask === ''
                ? () => onSubmitHandler()
                : () => onSelectedExistingTask(selectedExistingTask)
            }>
            Save
          </Button>
        </WindowActionsBar>
      </Window>
    </>
  );
};
