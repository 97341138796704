import { Button } from '@progress/kendo-react-buttons';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Checkbox } from '@progress/kendo-react-inputs';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

type Props = {
  isPendingApproval?: boolean;
  toggleDialog: () => void;
  onSubmit: () => void;
};

const SubmitConfirmation = ({
  isPendingApproval,
  toggleDialog,
  onSubmit
}: Props) => {
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);

  useEffect(() => {
    setIsConfirmed(isPendingApproval ?? false);
    setIsAuthorized(isPendingApproval ?? false);
  }, [isPendingApproval]);

  return (
    <>
      <Row className={'mb-2'}>
        <Col>
          <div className={'d-flex flex-column justify-content-between'} style={{ height: `6em` }}>
            <Checkbox
              disabled={isPendingApproval}
              checked={isConfirmed}
              className={'mr-2  align-items-center'}
              label={
                'I confirm that all content within this templates is not subject to copyright.'
              }
              onChange={(e) => setIsConfirmed(e.target?.value as boolean)}
            />

            <div className={'d-flex flex-column mb-2'}>
              <Checkbox
                disabled={isPendingApproval}
                checked={isAuthorized}
                className={'mr-2 align-items-center'}
                label={
                  'I authorize other organizations to use, reproduce, and modify a copy of this template.'
                }
                onChange={(e) => setIsAuthorized(e.target?.value as boolean)}
              />
              <div className={'d-flex'}>
                <div
                  className={'material-symbols-outlined me-1 '}
                  style={{
                    opacity: '0.25',
                    fontSize: '1rem',
                    paddingTop: '0.25rem'
                  }}>
                  lightbulb
                </div>
                <div className={'d-flex'}>
                  <small className={'text-muted'}>
                    We endeavor to authorize templates within 5 working days,
                    Please contact&nbsp;
                    <a
                      href={
                        'mailto:support@catalystglobal.games?subject=Catalyst Games Support&body='
                      }
                      className={'text-decoration-none'}>
                      support&nbsp;
                    </a>
                    if your request is urgent.
                  </small>
                </div>
              </div>

            </div>
          </div>
        </Col>
      </Row>

      <DialogActionsBar>
        <div className={'w-full d-flex justify-content-end gap-2'}>
          <Button
            onClick={toggleDialog}
            themeColor={'secondary'}
            className={'me-1'}>
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            disabled={!isAuthorized || !isConfirmed || isPendingApproval}
            themeColor={'success'}
            className={'me-1'}>
            Submit
          </Button>
        </div>
      </DialogActionsBar>
    </>
  );
};

export default SubmitConfirmation;
