import { useEffect, useRef, useState } from 'react';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridNoRecords
} from '@progress/kendo-react-grid';
import {
  DataResult,
  State,
  toDataSourceRequestString
} from '@progress/kendo-data-query';
import { useNavigate } from 'react-router-dom';
import {
  DeleteEnvironmentAsync,
  GetEnvironmentAsync
} from '../services/environments';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { ColumnMenu } from '../components/columnMenu';
import { toastStore } from '../stores/toast-store';
import Toolbar from '../components/page-toolbar';
import {
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../components/grid/grid-tool-bar';
import { Button } from '@progress/kendo-react-buttons';
import { DefaultGridSettings } from '../constants/grid-settings';
import { NoRecords } from '../components/grid/no-records';
import { ActionCell, ActionsCell } from '../components/grid/actions-cell';
import { LinkCell } from '../components/grid';
import { appStore } from '../stores/app-store';
import YesNoDialog from '../components/dialogs/yes-no-dialog';
import { YesNoDialogResult } from '../types/dialog-result';

const initialDataState: State = {
  sort: [{ field: 'name', dir: 'asc' }],
  ...DefaultGridSettings.initialDataState
};

const EnvironmentGrid = () => {
  const navigate = useNavigate();

  const [dataState, setDataState] = useState<State>(initialDataState);
  const [selectedId, setSelectedId] = useState<number>(0);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);
  const [environments, setEnvironments] = useState<DataResult>(() => ({
    data: [],
    total: 0
  }));

  const fetchEnvironments = async () => {
    try {
      appStore.showLoading();
      const response = await GetEnvironmentAsync(
        `${toDataSourceRequestString(dataState)}`
      );
      setEnvironments(response);
    } catch (ex) {
      console.error(ex);
    } finally {
      appStore.hideLoading();
    }
  };

  useEffect(() => {
    fetchEnvironments();
  }, [dataState]);

  const onAddEnvironment = () => {
    navigate('/administration/environment/new');
  };

  const onDelete = (id: number) => {
    onDeleteEnvironment(id);
  };

  const onDeleteEnvironment = async (id: number) => {
    setSelectedId(id);
    setShowDeleteConfirm(true);
  };

  const onConfirmDelete = async (result: YesNoDialogResult) => {
    if (result === 'yes') {
      try {
        appStore.showLoading();

        await DeleteEnvironmentAsync(selectedId);
        fetchEnvironments();

        toastStore.show(
          'Environments',
          <div>Environment deleted.</div>,
          'success'
        );
      } catch {
        toastStore.show(
          'Environments',
          <div>Failed to delete environment.</div>,
          'error'
        );
      } finally {
        appStore.hideLoading();
      }
    }
    setShowDeleteConfirm(false);
  };

  const closeDeleteDialog = () => {
    setShowDeleteConfirm(false);
  };

  const gridNameColumn = (props: GridCellProps) => (
    <LinkCell
      to={`/administration/environment/${props.dataItem['id'].toString()}`}
      {...props}></LinkCell>
  );

  const gridActionsColumn = (props: GridCellProps) => {
    return (
      <ActionsCell {...props}>
        <ActionCell
          title={'Edit'}
          icon={'edit'}
          themeColor={'primary'}
          className={'fs-2'}
          onClick={() =>
            navigate(`/administration/environment/${props.dataItem.id}`)
          }
        />
        <ActionCell
          title={'Delete'}
          icon={'delete'}
          className={'fs-2'}
          themeColor={'error'}
          onClick={() => onDelete(props.dataItem.id)}
        />
      </ActionsCell>
    );
  };

  const gridExportRef = useRef<ExcelExport | null>(null);

  return (
    <>
      <Toolbar title={'Environments'}>
        <Button onClick={onAddEnvironment} themeColor={'primary'}>
          Add host
        </Button>
      </Toolbar>
      <div className={'pt-2'}>
        <GridToolBar
          searchPlaceholder={'Search hosts'}
          columnsToSearch={['name', 'region', 'hostUrl']}
          showCardMode={false}
          exportRef={gridExportRef}
          {...dataState}
          onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
            setDataState(e.dataState);
          }}></GridToolBar>
        <ExcelExport
          data={environments.data}
          ref={gridExportRef}
          fileName={`environments.xlsx`}>
          <Grid
            id={'grid-environments'}
            pageable={DefaultGridSettings.pagerSettings}
            sortable={true}
            data={environments}
            {...dataState}
            onDataStateChange={(e: GridDataStateChangeEvent) => {
              setDataState(e.dataState);
            }}>
            <Column
              field={'name'}
              title={'Name'}
              cell={gridNameColumn}
              columnMenu={ColumnMenu}
            />
            <Column field={'region'} title={'Region'} columnMenu={ColumnMenu} />
            <Column
              field={'hostUrl'}
              title={'Endpoint'}
              columnMenu={ColumnMenu}
            />
            <Column title={'Actions'} cell={gridActionsColumn} width={110} />
            <GridNoRecords>
              <NoRecords />
            </GridNoRecords>
          </Grid>
        </ExcelExport>
      </div>
      {showDeleteConfirm && (
        <YesNoDialog
          title={'Remove Environment'}
          onConfirm={onConfirmDelete}
          onClose={closeDeleteDialog}>
          Are you sure you want to delete this environment?
        </YesNoDialog>
      )}
    </>
  );
};
export default EnvironmentGrid;
