import React from 'react';
import Actions from './actions';
import UserCard from './user-card';
import UserIcon from './user-icon';
import { Link, To } from 'react-router-dom';

export { default as Actions } from './actions';
export { default as LogoutButton } from './logout-button';
export { default as UserCard } from './user-card';
export { default as UserIcon } from './user-icon';
export { default as UserMenu } from './user-menu';

export interface HeaderBreadcrumb {
  text?: string;
  icon?: React.ReactNode;
  to: To;
}

interface HeaderProps {
  title: string;
  breadCrumbs?: HeaderBreadcrumb[];
}

export const Header = (props: HeaderProps) => {
  return (
    <div className={'page-base__header'}>
      <div className={'header__title'}>
        <h1>{props.title}</h1>
        <nav aria-label={'breadcrumb'}>
          <ol className={'breadcrumb'}>
            {props.breadCrumbs?.map((crumb, index) => {
              return (
                <li className={'breadcrumb-item'} key={`crumb-${index}`}>
                  <Link to={crumb.to}>{crumb.text}</Link>
                </li>
              );
            })}
          </ol>
        </nav>
      </div>
      <Actions />
      <UserCard />
      <UserIcon />
    </div>
  );
};

export default Header;
