import React from 'react';
import { Dropdown, Form } from 'react-bootstrap';

import { uuid } from '../../types/common-helper';

interface DropdownSelector<T, K extends keyof T> {
  additionalClassname: string;
  selected?: string;
  items: T[];
  fieldName: K;
  onClick: (e: T) => void;
}

const DropdownSelector = <T, K extends keyof T>({
  additionalClassname,
  selected,
  items,
  fieldName,
  onClick
}: DropdownSelector<T, K>) => {
  const [searchTerm, setSearchTerm] = React.useState('');

  const handleSearchChange = (e: string) => {
    setSearchTerm(e);
  };

  const filteredItems =
    searchTerm === ''
      ? items
      : items.filter((item) =>
          (item[fieldName] as string)
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        );

  return (
    <Dropdown
      as={'span'}
      className={`me-2 step-editor__selector step-editor__selector--identifier ${additionalClassname}`}>
      <Dropdown.Toggle size={'sm'} variant={'primary'} className={'badge'}>
        {selected ?? 'select'}
      </Dropdown.Toggle>

      <Dropdown.Menu
        style={{
          maxHeight: '300px',
          overflowY: 'auto',
          padding: '0 0 8px 0'
        }}>
        {items.length === 0 ? (
          <div
            className={'d-flex align-items-center justify-content-center'}
            style={{
              fontSize: '12px',
              padding: '8px 8px 0 8px'
            }}>
            No item
          </div>
        ) : (
          <>
            <Form className={'p-2 bg-white sticky-top'}>
              <Form.Control
                type={'text'}
                size={'sm'}
                placeholder={'search'}
                onChange={(e) => handleSearchChange(e.target.value)}
              />
            </Form>
            {filteredItems.map((item) => (
              <Dropdown.Item key={uuid()} onClick={() => onClick(item)}>
                {item[fieldName] as React.ReactNode}
              </Dropdown.Item>
            ))}
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownSelector;
