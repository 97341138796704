import { InputChangeEvent } from '@progress/kendo-react-inputs';
import { useState } from 'react';
import StandardInput from '../../forms/standard-input';
import CustomColumnContainer, {
  CustomColumnProps
} from './custom-column-container';
import { Label } from '@progress/kendo-react-labels';
interface CustomColumnPlainTextProps extends CustomColumnProps {
  id?: string;
  text?: string;
  isDisable?: boolean;
  showNote?: boolean;
  onChange?: (id?: string, value?: string) => void;
  note?: string;
}

const CustomColumnInputText = ({
  onChange = () => {},
  isDisable = false,
  showNote,
  note,
  ...props
}: CustomColumnPlainTextProps) => {
  const [textState, setTextState] = useState<string>(props?.text ?? '');
  const onChangeHandler = (e: InputChangeEvent) => {
    setTextState(e.target.value!.toString());
  };
  const onBlurHandler = () => {
    onChange(props?.id, textState);
  };
  return (
    <CustomColumnContainer
      columnClass={props?.columnClass}
      containerClass={props?.containerClass}>
      <StandardInput
        disabled={isDisable}
        name={'text'}
        value={textState}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}></StandardInput>
      {showNote && <Label className={'display-language-note'}>{note}</Label>}
    </CustomColumnContainer>
  );
};

export default CustomColumnInputText;
