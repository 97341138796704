import { GetAllAsync, GetAsync, PutAsync } from './api';
import { UserDepartmentResponse } from '../types/responses/user-department';

const UserDepartmentEndpoint = `users/departments`;

/**
 * Gets the Departments list.
 * @returns the list of Departments.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetUserDepartmentsAsync = async () =>
  GetAsync<UserDepartmentResponse[]>(`${UserDepartmentEndpoint}`);

/**
 * Gets the My Departments.
 * @param userId - the ID of the User to retrieve
 * @returns the list of Department Id.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetMyDepartmentsAsync = async () =>
  GetAllAsync<number>(`users/mydepartments`);

/**
 * Updates My Departments by completely replacing it.
 * @param departments - the User Departments to update [PUT]
 * @returns the updated User Departments object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PUT
 */
export const PutMyDepartmentsAsync = async (departments: number[]) =>
  PutAsync<number[]>(`users/mydepartments`, departments);

/**
 * Gets the User Departments by id.
 * @param userId - the ID of the User to retrieve
 * @returns the list of Department Id.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetUserProfileDepartmentsAsync = async (userId: number) =>
  GetAllAsync<number>(`users/${userId}/departments`);

/**
 * Updates the User Departments by completely replacing it.
 * @param userId - the ID of the entity to update [PUT]
 * @param departments - the User Departments to update [PUT]
 * @returns the updated User Departments object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PUT
 */
export const PutUserProfileDepartmentsAsync = async (
  userId: number,
  departments: number[]
) => PutAsync<number[]>(`users/${userId}/departments`, departments);

/**
 * Gets the User Departments by id.
 * @param userId - the ID of the User to retrieve
 * @returns the list of Department Id.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const AdminGetUserProfileDepartmentsAsync = async (
  organisationId: number,
  userId: number
) =>
  GetAllAsync<number>(
    `organisations/${organisationId}/users/${userId}/departments`
  );

/**
 * Updates the User Departments by completely replacing it.
 * @param userId - the ID of the entity to update [PUT]
 * @param departments - the User Departments to update [PUT]
 * @returns the updated User Departments object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PUT
 */
export const AdminPutUserProfileDepartmentsAsync = async (
  organisationId: number,
  userId: number,
  departments: number[]
) =>
  PutAsync<number[]>(
    `organisations/${organisationId}/users/${userId}/departments`,
    departments
  );
