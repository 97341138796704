import React, { useEffect, useRef, useState } from 'react';
import QrCodeDialog from './dialogs/qr-code-dialog';
import { toastStore } from '../stores/toast-store';

interface QRLinkProps {
  title?: string;
  url?: string;
}

export const QRLink = ({ ...props }: QRLinkProps) => {
  const [isOpenQR, setIsOpenQR] = useState<boolean>(false);
  const anchor = useRef<HTMLButtonElement>(null);
  return (
    <>
      <div className="d-flex flex-row bd-highlight mb-3">
        <div
          className={
            'd-flex align-items-center justify-items-between text-break'
          }>
          <a target={'_blank'} href={props.url}>
            {`${props.url}`}
          </a>
          <span
            className={
              'material-symbols-outlined ml-1 text-primary cursor-pointer'
            }
            ref={anchor}
            onClick={() => {
              navigator.clipboard.writeText(`${props?.url}`);
              toastStore.show('', <div>URL copied.</div>, 'success', 2000);
            }}>
            content_copy
          </span>
          <span
            className="material-symbols-outlined text-primary cursor-pointer"
            onClick={() => setIsOpenQR(true)}>
            qr_code_scanner
          </span>
        </div>
      </div>
      {isOpenQR && (
        <QrCodeDialog
          title={props.title ?? ''}
          url={props.url ?? ''}
          onClose={() => setIsOpenQR(false)}
        />
      )}
    </>
  );
};
