import { State, toDataSourceRequestString } from '@progress/kendo-data-query';
import { GetAllAsync, GetAsync, PatchAsync, PostAsync } from './api';

interface DisplayLanguageList {
  id: number;
  languageCode: string;
  languageName: string;
  description: string;
  version?: any;
  createdDateUtc: string;
}

interface AdminDisplayLanguage {
  languageCode?: string;
  description?: string;
}

interface AdminDisplayLanguageDocument {
  documentStatus?:
    | string
    | 'New'
    | 'Pending'
    | 'Approved'
    | 'Rejected'
    | 'Published';
  version?: string;
  fileName?: string;
}

export interface AdminDisplayLanguageResponse {
  id?: number;
  languageCode?: string;
  languageName?: string;
  description?: string;
  version?: string;
  createdDateUtc?: Date;
}

export interface AdminDisplayLanguageDocumentResponse {
  id?: number;
  displayLanguageMaskingId?: string;
  status?: string;
  version?: string;
  fileName?: string;
  createdDateUtc?: Date;
}

export interface DisplayLanguagePublished {
  id?: number;
  languageName?: string;
  languageCode?: string;
  description?: string;
  blobFileUrl?: string;
}
export interface DisplayLanguage {
  id?: number;
  languageCode?: string;
  description?: string;
  resources?: DisplayLanguageResource[];
}
export interface DisplayLanguageResource {
  id?: string;
  name?: string;
  description?: string;
  value?: string;
  type?: string;
}

const endpoint = `displaylanguages`;

export const GetDisplayLanguagePublished = async () =>
  GetAllAsync<DisplayLanguageList>(`library/${endpoint}`);

export const GetAllAdminDisplayLanguageAsync = async (dataState: State) =>
  GetAllAsync<DisplayLanguageList>(
    `${endpoint}?${toDataSourceRequestString(dataState)}`
  );

export const GetAllAdminDisplayLanguageDefaultValuesAsync = async (
  dataState: State
) =>
  GetAllAsync<DisplayLanguageList>(
    `${endpoint}/default?${toDataSourceRequestString(dataState)}`
  );

export const GetAdminDisplayLanguageAsync = async (
  id: number,
  displayLanguageId: number
) =>
  GetAsync<AdminDisplayLanguageResponse>(
    `${endpoint}/${id}?displayLanguageId=${displayLanguageId}`
  );

export const GetAdminDisplayLanguageLatestDocumentAsync = async (
  displayLanguageId: number
) =>
  GetAsync<AdminDisplayLanguageDocumentResponse>(
    `${endpoint}/${displayLanguageId}/documents/latest`
  );

export const GetAdminDisplayLanguageLatestContentAsync = async (
  displayLanguageId: number
) =>
  GetAsync<DisplayLanguage>(
    `${endpoint}/${displayLanguageId}/documents/latest/content`
  );

export const AdminAddDisplayLanguageAsync = async (
  displayLanguage: AdminDisplayLanguage
) => PostAsync<AdminDisplayLanguageResponse>(endpoint, displayLanguage);

export const AdminAddDisplayLanguageDocumentAsync = async (
  displayLanguageId?: number,
  document?: AdminDisplayLanguageDocument
) =>
  PostAsync<AdminDisplayLanguageDocumentResponse>(
    `${endpoint}/${displayLanguageId}/documents`,
    document as any
  );

export const AdminAddDisplayLanguageContentAsync = async (
  displayLanguageId?: number,
  documentId?: number,
  content?: any
) =>
  PostAsync<any>(
    `${endpoint}/${displayLanguageId}/document/${documentId}/contents`,
    content
  );

export const AdminUpdateDisplayLanguageAsync = async (
  displayLanguageId: number,
  displayLanguage: AdminDisplayLanguage
) =>
  PatchAsync<AdminDisplayLanguageResponse>(
    `${endpoint}/${displayLanguageId}`,
    displayLanguage
  );

export const AdminUpdateDisplayLanguageDocumentAsync = async (
  displayLanguageId: number,
  documentId: number,
  document?: AdminDisplayLanguageDocument
) =>
  PatchAsync<AdminDisplayLanguageDocumentResponse>(
    `${endpoint}/${displayLanguageId}/documents/${documentId}`,
    document as any
  );

export const AdminUpdateDisplayLanguageContentAsync = async (
  displayLanguageId?: number,
  documentId?: number,
  content?: any
) =>
  PatchAsync<any>(
    `${endpoint}/${displayLanguageId}/document/${documentId}/contents`,
    content
  );
