import UsersGrid from '../../features/users-grid';
import { PrivatePage } from '../../components/private-page';
import { useEffect } from 'react';
import { ctGameDefault, ctGameTitle } from '../../constants/text';
export default function Users() {
  useEffect(() => {
    document.title = ctGameTitle + '- Users';
    return () => {
      document.title = ctGameDefault;
    };
  }, []);
  return (
    <PrivatePage
      breadcrumb={[
        {
          label: 'Dashboard',
          href: 'dashboard'
        },
        {
          label: 'Users',
          href: `users`
        }
      ]}
      pageTitle={'User management'}>
      <UsersGrid />
    </PrivatePage>
  );
}
