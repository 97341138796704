import { Button } from '@progress/kendo-react-buttons';
import { Label } from '@progress/kendo-react-labels';
import { TextareaInput } from '../form-input';
import { Checkbox } from '@progress/kendo-react-inputs';
import { useContext, useState } from 'react';
import {
  ResourceWindow,
  UploadedImage
} from '../../features/game-document/image-resource/resource-window';
import {
  AddResourceAsync,
  UpdateGameDocState
} from '../../utils/game-document';
import { GameDocumentContext } from '../../contexts/game-document';
import { uuid } from '../../types/common-helper';

interface CustomFeedbackMessageProps {
  title?: string;
  url?: string;
  isDefault?: boolean;
  message?: string;
  onMessageChange?: (message: string) => void;
  onUpload?: (resourceId: string) => void;
  onChangeIsDefault?: (isDefault: boolean) => void;
}

export const CustomFeedbackMessage = ({
  ...props
}: CustomFeedbackMessageProps) => {
  const [showUploader, setShowUploader] = useState<boolean>(false);
  const [state, setState] = useContext(GameDocumentContext);

  /**
   * On upload image and save to local storage
   * @param uploadedImage
   */
  const onUploadImage = (uploadedImage: UploadedImage) => {
    if (uploadedImage.resource?.id) {
      if (props.onUpload) {
        props.onUpload(uploadedImage.resource?.id);
      }
    } else {
      let resourceId = uuid();
      const resourceType = uploadedImage?.mimeType?.split('/')[0];
      AddResourceAsync(
        state.gameDocument!,
        uploadedImage?.fileName,
        resourceId,
        resourceType,
        uploadedImage.blobUrl,
        resourceId,
        uploadedImage.size
      ).then((response) =>
        setState((prev) => UpdateGameDocState(prev, response))
      );

      if (props.onUpload) {
        props.onUpload(resourceId);
      }
    }
    setShowUploader(!showUploader);
  };

  return (
    <>
      <div className={'d-flex flex-column'}>
        <Checkbox
          name={'checkbox-use-default'}
          label={'Use default'}
          value={props.isDefault}
          onChange={(e) => {
            if (props.onChangeIsDefault) {
              props.onChangeIsDefault(e.value);
            }
          }}
        />
        <Label className={'mt-3'}>{props.title}</Label>
      </div>

      <div className={'d-flex flex-row'}>
        <div className={'mt-3'}>
          <img height={100} src={props.url} alt="Checked" />
        </div>
        <div className={'p-1 align-self-center mt-3 ml-3'}>
          <Button
            disabled={props.isDefault}
            onClick={() => {
              setShowUploader(!showUploader);
            }}>
            Upload image
          </Button>
        </div>
      </div>
      <div className={'mt-3 w-full'}>
        <TextareaInput
          disabled={props.isDefault}
          value={props.message ?? ''}
          className={'w-full'}
          label={'Custom message'}
          name={'custom-message'}
          maxLength={300}
          rows={3}
          onChange={(e) => {
            if (props.onMessageChange) {
              props.onMessageChange(e.target.value ?? '');
            }
          }}
        />
      </div>
      {showUploader ? (
        <ResourceWindow
          toggleDialog={() => {
            setShowUploader(!showUploader);
          }}
          onSubmit={(uploadedFile) => onUploadImage(uploadedFile)}
          acceptedExtension={'image/*'}
        />
      ) : null}
    </>
  );
};
