import { useEffect } from 'react';
import { BreadcrumbLocation, PrivatePage } from '../../components/private-page';
import AllUserGrid from '../../features/allusers-grid';
import { ctGameTitle, ctGameDefault } from '../../constants/text';

export default function AllUsers() {
  const breadcrumbs: BreadcrumbLocation[] = [
    { label: 'Dashboard', href: '/' },
    { label: 'Administration', href: '../administration' },
    { label: 'All users', href: '../administration/users' }
  ];

  useEffect(() => {
    document.title = ctGameTitle + ' - All Users';

    return () => {
      // return default value when component unmounts
      document.title = ctGameDefault;
    }
  }, []);
  return (
    <PrivatePage pageTitle={'Administration'} breadcrumb={breadcrumbs}>
      <AllUserGrid />
    </PrivatePage>
  );
}
