import { useEffect, useState } from 'react';
import { Algorithm } from '../../types/algorithm';
import EventAlgorithm from '../../components/map/event-configuration/event--algorithm';
import { GameDocument } from '../../types/game-document';
import cloneDeep from 'lodash.clonedeep';
import { WindowActionsBar, Window } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { Tab, Tabs } from 'react-bootstrap';

interface WorldMapEventProps {
  zoneId?: string;
  gameDocument?: GameDocument;
  title?: string;
  onCancel?: () => void;
  onConfirm?: (
    algorithmOnEnter: Algorithm,
    algorithmOnExit: Algorithm,
    algorithmOnTaskCompleted: Algorithm
  ) => void;
}

const WorldMapEvent = ({
  zoneId = '',
  gameDocument,
  title,
  onCancel,
  onConfirm
}: WorldMapEventProps) => {
  const [algorithmOnEnter, setAlgorithmOnEnter] = useState<Algorithm>({});
  const [algorithmOnExit, setAlgorithmOnExit] = useState<Algorithm>({});
  const [algorithmOnTaskCompleted, setAlgorithmOnTaskCompleted] =
    useState<Algorithm>({});

  const getGameEvents = (gameDocument: GameDocument) => {
    let onEnterMap: Algorithm = {};
    let onExitMap: Algorithm = {};
    let onTaskCompletedMap: Algorithm = {};
    if (zoneId === '') {
      //World-map
      const worldMap = cloneDeep(gameDocument.rules.worldMap);
      if (worldMap) {
        onEnterMap = worldMap?.events?.onEnterMap!;
        onExitMap = worldMap?.events?.onExitMap!;
        onTaskCompletedMap = worldMap?.events?.onTaskCompletedMap!;
      }
    } else {
      //Zone
      const zone = cloneDeep(
        gameDocument?.assets.zones?.find((x) => x.id === zoneId)
      );
      if (zone) {
        onEnterMap = zone?.events?.onEnterMap!;
        onExitMap = zone?.events?.onExitMap!;
        onTaskCompletedMap = zone?.events?.onTaskCompletedMap!;
      }
    }

    if (onEnterMap) {
      let gameOnEnter: Algorithm = { ...onEnterMap };
      if (gameOnEnter) {
        setAlgorithmOnEnter(gameOnEnter);
      }
    }

    if (onExitMap) {
      let gameOnExit: Algorithm = { ...onExitMap };

      if (gameOnExit) {
        setAlgorithmOnExit(gameOnExit);
      }
    }

    if (onTaskCompletedMap) {
      let gameOnTaskCompleted: Algorithm = { ...onTaskCompletedMap };

      if (gameOnTaskCompleted) {
        setAlgorithmOnTaskCompleted(gameOnTaskCompleted);
      }
    }
  };

  const handleEventOnEnterChange = (algorithm: Algorithm) => {
    setAlgorithmOnEnter(algorithm);
  };

  const handleEventOnExitChange = (algorithm: Algorithm) => {
    setAlgorithmOnExit(algorithm);
  };

  const handleEventOnTaskCompletedChange = (algorithm: Algorithm) => {
    setAlgorithmOnTaskCompleted(algorithm);
  };

  const handleOnCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const handleOnConfirm = () => {
    if (onConfirm) {
      onConfirm(algorithmOnEnter, algorithmOnExit, algorithmOnTaskCompleted);
    }
  };

  useEffect(() => {
    if (gameDocument) {
      getGameEvents(gameDocument);
    }
  }, []);

  return (
    <Window
      onClose={handleOnCancel}
      title={title}
      modal={true}
      className={'shadow'}
      initialWidth={800}
      initialHeight={650}
      minimizeButton={undefined}
      maximizeButton={undefined}>
      <Tabs defaultActiveKey="onEnter">
        <Tab eventKey="onEnter" title="onEnter">
          <EventAlgorithm
            title={'WorldMap.onEnter'}
            description={
              'is triggered every time a player enters the world map. This includes return trips.'
            }
            onChange={handleEventOnEnterChange}
            algorithm={algorithmOnEnter}
          />
        </Tab>

        <Tab eventKey="onExit" title="onExit">
          <EventAlgorithm
            title={'WorldMap.onExit'}
            description={
              'is triggered every time a player exits the world map.'
            }
            onChange={handleEventOnExitChange}
            algorithm={algorithmOnExit}
          />
        </Tab>
        <Tab eventKey={'onTaskCompleted'} title={'onTaskCompleted'}>
          <EventAlgorithm
            onChange={handleEventOnTaskCompletedChange}
            algorithm={algorithmOnTaskCompleted}
            title={'WorldMap.onTaskCompleted'}
            description={'is triggered whenever a task is completed.'}
          />
        </Tab>
      </Tabs>

      <WindowActionsBar>
        <Button themeColor={'secondary'} onClick={handleOnCancel}>
          Cancel
        </Button>
        <Button themeColor={'primary'} onClick={handleOnConfirm}>
          Confirm
        </Button>
      </WindowActionsBar>
    </Window>
  );
};

export default WorldMapEvent;
