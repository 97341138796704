import { ComponentType, useState, useContext } from 'react';
import {
  Window,
  WindowActionsBar,
  WindowProps
} from '@progress/kendo-react-dialogs';
import { FluidForm } from '../../../components/forms';
import { TimerEntity, ResourceEntity } from '../../../types/game-document/';
import { Button } from '@progress/kendo-react-buttons';
import { EditorMode } from '../../../types/editor-mode';
import { EntityEditor } from '../../../types/game-document/entity-editor';
import { GameDocumentContext } from '../../../contexts/game-document';
import TimerEditorFull from './timer-editor-full';
import cloneDeep from 'lodash.clonedeep';
import { ResourceType } from '../../../types/game-document/resource-type';
import { uuid } from '../../../types/common-helper';
import {
  ERR_DUPLICATE_NAME_VALUE,
  ERR_INPUT_REQUIRED
} from '../../../constants/text';
import RequiredFields from '../../../types/required-fields';

interface TimerEditorWindowProps extends WindowProps {
  editorEntity: EntityEditor<TimerEntity>;
  editorResource: EntityEditor<ResourceEntity>[];
  toggleDialog: Function;
  onSubmit: (
    editorEntity: EntityEditor<TimerEntity>,
    resourceEntity: EntityEditor<ResourceEntity>[]
  ) => void;
  editorMode: EditorMode;
}

export const TimerEditorWindow: ComponentType<TimerEditorWindowProps> = ({
  editorEntity,
  toggleDialog,
  editorResource,
  onSubmit,
  editorMode,
  ...props
}: TimerEditorWindowProps) => {
  const [state] = useContext(GameDocumentContext);
  const [entity, setEntity] = useState<TimerEntity>(
    cloneDeep({ ...editorEntity.entity })
  );
  const [resources, setResources] = useState<ResourceEntity[]>([
    ...editorResource.map((item) => item.entity)
  ]);

  const [requiredFields, setRequiredFields] = useState<
    RequiredFields<TimerEntity>[]
  >([
    { name: 'name', errorMessage: '' },
    { name: 'titleResId', errorMessage: '' }
  ]);

  const updateEntityAsync = async (
    name: keyof TimerEntity,
    value: string | number,
    type: ResourceType,
    isClearValue?: boolean
  ) => {
    if (!name?.toLowerCase().includes('resid')) {
      setEntity((prev) => ({ ...prev, [name]: isClearValue ? '' : value }));
    } else {
      const resourceId = entity[name];
      let newResources: ResourceEntity[] = [...resources];
      if (resourceId === undefined || resourceId === '') {
        const newId: string = uuid();
        let resourceEntity: ResourceEntity = {
          id: newId,
          description: '',
          name: name,
          type: type,
          value: value as string
        };
        newResources.push(resourceEntity);
        setResources(newResources);
        setEntity((prev) => ({ ...prev, [name]: newId }));
      } else {
        setResources(
          newResources.map((res) =>
            res?.id === resourceId
              ? { ...res, value: isClearValue ? '' : (value as string) }
              : { ...res }
          )
        );
        setEntity((prev) => ({ ...prev, [name]: resourceId }));
      }
    }
  };

  const isInputValid = (): boolean => {
    const inputValidation = requiredFields.map((input) => {
      const entityValue = entity[input.name as keyof TimerEntity];

      if (input.name === 'name') {
        // Check validation for input name
        const itemIndex = state.gameDocument?.assets?.timers?.findIndex(
          (item) =>
            item.name?.toLowerCase() === (entityValue as string)?.toLowerCase()
        );
        if (entityValue === undefined || entityValue === '') {
          input.errorMessage = ERR_INPUT_REQUIRED;
        } else if (
          itemIndex !== -1 &&
          state.gameDocument?.assets?.timers![itemIndex!]?.id !== entity.id
        ) {
          input.errorMessage = ERR_DUPLICATE_NAME_VALUE;
        } else {
          input.errorMessage = '';
        }
      } else if (input.name === 'titleResId') {
        // Check validation for input titleResId
        const resourceIndex = resources.findIndex(
          (resource) => resource?.id === entityValue
        );
        if (
          resources[resourceIndex] === undefined ||
          resources[resourceIndex]?.value === ''
        ) {
          input.errorMessage = ERR_INPUT_REQUIRED;
        } else {
          input.errorMessage = '';
        }
      }

      return input;
    });
    setRequiredFields(inputValidation);
    return (
      inputValidation.findIndex((input) => input.errorMessage !== '') === -1
    );
  };

  return (
    <Window
      modal={true}
      minimizeButton={undefined}
      className={'shadow'}
      initialWidth={900}
      initialHeight={650}
      title={editorEntity.isNew ? 'New timer' : editorEntity.entity?.name}
      {...props}>
      <FluidForm>
        <TimerEditorFull
          timer={entity}
          resources={resources}
          requiredFields={requiredFields}
          handleEntityChange={(
            name: keyof TimerEntity,
            value: string | number,
            type: ResourceType,
            isClearValue?: boolean
          ) => {
            updateEntityAsync(name, value, type, isClearValue);
          }}
          handleEventAction={(entity) => setEntity(entity)}
        />
      </FluidForm>
      <WindowActionsBar>
        <Button
          themeColor={'secondary'}
          onClick={() => {
            toggleDialog();
          }}>
          Cancel
        </Button>
        <Button
          themeColor={'primary'}
          onClick={() => {
            if (isInputValid()) {
              const entityResources = resources.map((item, index) => ({
                isNew: editorResource[index]?.isNew ?? true,
                entity: item
              })) as EntityEditor<ResourceEntity>[];
              onSubmit({ isNew: editorEntity.isNew, entity }, entityResources);
            }
          }}>
          Save
        </Button>
      </WindowActionsBar>
    </Window>
  );
};
