import { ComponentType, useContext, useState } from 'react';
import {
  Window,
  WindowActionsBar,
  WindowProps
} from '@progress/kendo-react-dialogs';
import { FluidForm } from '../../../components/forms';
import { ZoneEditor } from './zone-editor';
import { ResourceEntity, ZoneEntity } from '../../../types/game-document/';
import {
  CheckboxChangeEvent,
  InputChangeEvent
} from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { EditorMode } from '../../../types/editor-mode';
import { EntityEditor } from '../../../types/game-document/entity-editor';
import { GameDocumentContext } from '../../../contexts/game-document';

interface ZoneEditorWindowProps extends WindowProps {
  editorEntity: EntityEditor<ZoneEntity>;
  editorResource: EntityEditor<ResourceEntity>[];
  toggleDialog: Function;
  onSubmit: (
    editorEntity: EntityEditor<ZoneEntity>,
    resourceEntity: EntityEditor<ResourceEntity>[]
  ) => void;
  editorMode: EditorMode;
}

export interface RequiredInput {
  name: string;
  errorMessage: string;
}

export const ZoneEditorWindow: ComponentType<ZoneEditorWindowProps> = ({
  editorEntity,
  toggleDialog,
  editorResource,
  onSubmit,
  editorMode,
  ...props
}: ZoneEditorWindowProps) => {
  const [state, setState] = useContext(GameDocumentContext);
  const [entity, setEntity] = useState({ ...editorEntity.entity });
  const [resources, setResources] = useState<ResourceEntity[]>([
    ...editorResource.map((title) => title.entity)
  ]);

  const [requiredInputs, setRequiredInputs] = useState<RequiredInput[]>([
    { name: 'name', errorMessage: '' }
  ]);

  const handleInputChange = (event: InputChangeEvent | CheckboxChangeEvent) => {
    if (event.target.name === 'titleResId') {
      const { name, value } = event.target;
      setResources(
        resources.map((item) =>
          item.id === entity![name]
            ? { ...item, value: value! as string }
            : { ...item }
        )
      );
    } else {
      setEntity({ ...entity, [event.target.name!]: event.target.value });
    }
  };

  const isInputValid = (): boolean => {
    const inputValidation = requiredInputs.map((input) => {
      const entityValue = entity[input.name as keyof ZoneEntity];
      const itemIndex = state.gameDocument?.assets?.zones?.findIndex(
        (item) => item.name === entityValue
      );
      const resourceIndex = resources.findIndex(
        (resource) => resource.id === entityValue
      );

      if (input.name === 'name') {
        // Check validation for input name
        if (entityValue === '') {
          input.errorMessage = 'This field is required';
        } else if (
          itemIndex !== undefined &&
          itemIndex !== -1 &&
          state.gameDocument?.assets?.zones![itemIndex!]?.id !== entity.id
        ) {
          input.errorMessage = 'Please enter unique name';
        } else {
          input.errorMessage = '';
        }
      }

      return input;
    });
    setRequiredInputs(inputValidation);
    return (
      inputValidation.findIndex((input) => input.errorMessage !== '') === -1
    );
  };

  return (
    <>
      <Window
        modal={true}
        minimizeButton={undefined}
        className={'shadow'}
        initialWidth={500}
        initialHeight={375}
        onClose={() => toggleDialog()}
        title={editorEntity.isNew ? 'New zone' : editorEntity.entity.name}
        {...props}>
        <FluidForm>
          <ZoneEditor
            editorMode={editorMode}
            entity={entity}
            resources={resources}
            handleInputChange={handleInputChange}
            requiredInputs={requiredInputs}
          />
        </FluidForm>
        <WindowActionsBar>
          <Button themeColor={'secondary'} onClick={() => toggleDialog()}>
            Cancel
          </Button>
          <Button
            themeColor={'primary'}
            onClick={() => {
              if (isInputValid()) {
                const entityResources = resources.map((item, index) => ({
                  isNew: editorResource[index].isNew,
                  entity: item
                })) as EntityEditor<ResourceEntity>[];
                onSubmit(
                  { isNew: editorEntity.isNew, entity },
                  entityResources
                );
              }
            }}>
            Save
          </Button>
        </WindowActionsBar>
      </Window>
    </>
  );
};
