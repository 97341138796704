import {
  NotificationName,
  NotificationTypeResponse
} from '../types/responses/notification-type-response';
import { GetAllAsync, PutAsync } from './api';

/**
 * Gets All Notification Types encapsulated in a paged payload.
 * @returns all Phone Number objects.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetNotificationTypesAsync = async () =>
  GetAllAsync<NotificationTypeResponse>(`notifications/types`);

/**
 * Gets All App Notifications encapsulated in a paged payload.
 * @returns all Phone Number objects.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetAppNotificationAsync = async () =>
  GetAllAsync<NotificationTypeResponse>(`users/myappnotifications/settings`);

/**
 * Applies a PUT to the App Notification. This can be considered a partial update
 * requiring only the fields and values that require modification.
 * @param appNotifications - the array of string of notification type name to PUT
 * @returns the updated App Notification object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PUT
 */
export const PutAppNotificationAsync = async (
  appNotifications: NotificationName[]
) =>
  PutAsync<NotificationName[]>(
    `users/myappnotifications/settings`,
    appNotifications
  );

export const PutAppNotificationByUserIdAsync = async (
  userId: number,
  appNotifications: NotificationName[]
) => PutAsync(`users/${userId}/appnotifications/settings`, appNotifications);

/**
 * Gets All Email Notifications encapsulated in a paged payload.
 * @returns all Phone Number objects.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/GET
 */
export const GetEmailNotificationAsync = async () =>
  GetAllAsync<NotificationTypeResponse>(`users/myemailnotifications/settings`);

/**
 * Applies a PUT to the Email Notification. This can be considered a partial update
 * requiring only the fields and values that require modification.
 * @param appNotifications - the array of string of notification type name to PUT
 * @returns the updated Email Notification object.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/PUT
 */
export const PutEmailNotificationAsync = async (
  emailNotifications: NotificationName[]
) =>
  PutAsync<NotificationName[]>(
    `users/myemailnotifications/settings`,
    emailNotifications
  );

export const PutEmailNotificationByUserIdAsync = async (
  userId: number,
  emailNotifications: NotificationName[]
) =>
  PutAsync(`users/${userId}/emailnotifications/settings`, emailNotifications);
