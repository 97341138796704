import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  ResourceWindow,
  UploadedImage
} from '../features/game-document/image-resource/resource-window';
import PopupMenu, { popupMenu } from './popupMenu';

type SingleUploadProps = {
  uploadTitle?: string;
  url?: string;
  allowedExtension?: string;
  width?: number;
  onUpload?: (image: UploadedImage) => void;
  source?: string;
};

export const SingleUpload = ({
  url = '',
  onUpload = () => {},
  ...props
}: SingleUploadProps) => {
  const [uploadVisible, setUploadVisible] = useState<boolean>(false);
  const [menuList, setMenuList] = useState<popupMenu[]>([]);
  const onOpenUploader = () => {
    setUploaderVisibility();
  };

  const handleUploadSubmit = (uploadedImage: UploadedImage) => {
    onUpload(uploadedImage);
    setUploaderVisibility();
  };

  const setUploaderVisibility = () => {
    setUploadVisible(!uploadVisible);
  };

  const generateMediaPopupMenu = () => {
    let newMenus: popupMenu[] = [
      { classIcon: 'edit', textMenu: 'Edit', textClass: '' },
      { classIcon: 'delete', textMenu: 'Delete', textClass: 'text-danger' }
    ];
    setMenuList(newMenus);
  };

  const onMenuSelectedHandler = (id: number, menu: popupMenu) => {
    if (menu.textMenu === 'Edit') {
      setUploaderVisibility();
    }

    if (menu.textMenu === 'Delete') {
      onUpload({ blobUrl: '', mimeType: '', fileName: '', size: 0 });
    }
  };

  useEffect(() => {
    generateMediaPopupMenu();
  }, []);

  return (
    <>
      <Row>
        <Col className={'d-flex flex-column'}>
          {url !== '' && (
            <div className={'d-flex'}>
              <div className={'rounded'}>
                <img
                  src={url}
                  className={'img-fluid rounded'}
                  alt={props?.uploadTitle}
                  width={props?.width}></img>
              </div>
              <div>
                <PopupMenu
                  menus={menuList}
                  id={0}
                  onMenuSelected={onMenuSelectedHandler}></PopupMenu>
              </div>
            </div>
          )}

          {url === '' && (
            <div
              className={
                'w-8 h-8 border border-1 rounded d-flex flex-column justify-content-center align-items-center cursor-pointer'
              }
              onClick={onOpenUploader}>
              <span className={'material-symbols-outlined text-primary'}>
                add_photo_alternate
              </span>
              <small>{props?.uploadTitle}</small>
            </div>
          )}
        </Col>
      </Row>
      {uploadVisible && (
        <ResourceWindow
          onClose={() => {
            setUploadVisible(!uploadVisible);
          }}
          onSubmit={handleUploadSubmit}
          acceptedExtension={props?.allowedExtension}
          toggleDialog={() => setUploadVisible(!uploadVisible)}
          imageUrl={url}
          imageSource={
            props.source === 'Logo'
              ? 'Game Design | Theme - Logo'
              : props.source === 'Footer'
                ? 'Game Design | Theme - Footer'
                : undefined
          }
        />
      )}
    </>
  );
};
