import React from 'react';
import { NavLink } from 'react-router-dom';
import { KendoGridResult } from '../../../types/KendoGridResult';

const CardItem: React.FC<{
  text: string;
  isLastIndex: boolean;
  goTo: string;
  fetcher: Promise<KendoGridResult<{ total: number }>>;
}> = ({ text, isLastIndex, goTo, fetcher }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [total, setTotal] = React.useState<number>(0);

  React.useEffect(() => {
    (async function fetchData() {
      try {
        setIsLoading(true);
        const response = await fetcher;
        setTotal(response.total);
      } catch (ex) {
        console.error(ex);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [fetcher]);

  return (
    <div
      className={`d-flex gap-2 align-items-center justify-content-between ${
        !isLastIndex && 'border-bottom'
      }  py-2 w-100`}>
      <NavLink to={goTo}>
        <span>{text}</span>
      </NavLink>
      {isLoading ? (
        <span
          className={`k-icon k-i-loading`}
          style={{
            fontSize: '20px',
            color: '#4698cb'
          }}
        />
      ) : (
        <span>({total})</span>
      )}
    </div>
  );
};

export default CardItem;
