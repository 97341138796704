import { useEffect, useRef, useState } from 'react';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridNoRecords,
  GridColumnMenuFilter
} from '@progress/kendo-react-grid';
import {
  DataResult,
  State,
  toDataSourceRequestString
} from '@progress/kendo-data-query';
import { ColumnMenu } from '../components/columnMenu';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { toastStore } from '../stores/toast-store';
import Toolbar from '../components/page-toolbar';
import {
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../components/grid/grid-tool-bar';
import { DefaultGridSettings } from '../constants/grid-settings';
import { NoRecords } from '../components/grid/no-records';
import { CardViewDataStateChangeEvent } from './organisations-cardview';
import { GetGlobalGamesAsync } from '../services/games';
import { appStore } from '../stores/app-store';
import { ActionCell } from '../components/grid/actions-cell';
import { YesNoDialogResult } from '../types/dialog-result';
import YesNoDialog from '../components/dialogs/yes-no-dialog';
import {
  GetApprovedGameDocumentLatestAsync,
  PatchGameDocumentAsync
} from '../services/json-document';
import { GamesManagementCardView } from './games-management-cardview';
import { formatDateToDDMMYYYY } from '../utils/date';
import { CustomFilterLanguage } from './filter/custom-filter-language';

const initialDataState: State = {
  sort: [{ field: 'name', dir: 'asc' }],
  ...DefaultGridSettings.initialDataState
};

const GamesManagementGrid = () => {
  const [dataState, setDataState] = useState<State>(initialDataState);
  const [gridMode, setGridMode] = useState<string>('grid');
  const [selectedId, setSelectedId] = useState<number>(0);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);
  const [libraries, setLibraries] = useState<DataResult>(() => ({
    data: [],
    total: 0
  }));

  const fetchLibrary = async () => {
    try {
      appStore.showLoading();
      const response = await GetGlobalGamesAsync(
        `${toDataSourceRequestString(dataState)}`
      );
      setLibraries(response);
    } catch (error) {
      console.error(error);
    } finally {
      appStore.hideLoading();
    }
  };

  useEffect(() => {
    fetchLibrary();
  }, [dataState]);

  const onAddCustomAction = (props: GridCellProps) => (
    <AddCustomAction {...props} />
  );

  const onDelete = (id: number) => {
    onDeleteGame(id);
  };

  const onDeleteGame = async (id: number) => {
    setSelectedId(id);
    setShowDeleteConfirm(true);
  };

  const unpublishGame = async (gameId: number) => {
    // Get latest document
    let latestDocument = await GetApprovedGameDocumentLatestAsync(gameId);

    // Patch the game document status
    await PatchGameDocumentAsync(latestDocument?.gameId!, latestDocument?.id!, {
      id: latestDocument?.id,
      gameId: latestDocument?.gameId,
      status: 'New',
      isGlobal: false,
      version: latestDocument?.version,
      fileName: latestDocument?.fileName
    });
  };

  const onConfirmDelete = async (result: YesNoDialogResult) => {
    if (result === 'yes') {
      try {
        appStore.showLoading();

        await unpublishGame(selectedId);
        fetchLibrary();

        toastStore.show(
          'Global library games',
          <div>Game deleted.</div>,
          'success'
        );
      } catch {
        toastStore.show(
          'Global library games',
          <div>Failed to delete game.</div>,
          'error'
        );
      } finally {
        appStore.hideLoading();
      }
    }
    setShowDeleteConfirm(false);
  };

  const closeDeleteDialog = () => {
    setShowDeleteConfirm(false);
  };

  const AddCustomAction = (props: GridCellProps) => {
    return (
      <>
        <ActionCell
          title={'Delete game'}
          icon={'delete'}
          themeColor={'error'}
          className={'fs-2'}
          onClick={() => onDelete(props.dataItem.id)}
        />
      </>
    );
  };

  const gridExportRef = useRef<ExcelExport | null>(null);

  const DateCell = (props: GridCellProps) => {
    return <td>{formatDateToDDMMYYYY(props.dataItem.createdDateUtc, true)}</td>;
  };

  const CellEllipsisAndTooltip = (props: GridCellProps) => {
    return (
      <td title={props.dataItem.languages}>
        <div className={'grid-ellipsis-cell'}>{props.dataItem.languages}</div>
      </td>
    );
  };

  return (
    <>
      <Toolbar title={'Global Games'}></Toolbar>
      <div className={'pt-2'}>
        <GridToolBar
          searchPlaceholder={'Search global games'}
          columnsToSearch={['name', 'languages', 'createdBy']}
          exportRef={gridExportRef}
          onGridModeChange={(e) => {
            setGridMode(e.gridMode);
          }}
          {...dataState}
          onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
            const newState = {
              ...e.dataState,
              filter: {
                logic: 'and',
                filters: [e.dataState.filter!]
              }
            } as State;
            setDataState(newState);
          }}></GridToolBar>
        <ExcelExport
          data={libraries.data}
          ref={gridExportRef}
          fileName={`libraries.xlsx`}>
          {gridMode === 'grid' && (
            <Grid
              id={'grid-library'}
              pageable={DefaultGridSettings.pagerSettings}
              sortable={true}
              data={libraries}
              {...dataState}
              onDataStateChange={(e: GridDataStateChangeEvent) => {
                setDataState(e.dataState);
              }}>
              <Column field={'name'} title={'Name'} columnMenu={ColumnMenu} />
              <Column
                field={'createdDateUtc'}
                title={'Date Created'}
                cell={DateCell}
              />
              <Column
                field={'createdBy'}
                title={'Game Designer'}
                columnMenu={ColumnMenu}
              />
              <Column
                field={'languages'}
                title={'Languages'}
                cell={CellEllipsisAndTooltip}
                columnMenu={(props: any) => (
                  <GridColumnMenuFilter
                    {...props}
                    filterUI={CustomFilterLanguage}
                  />
                )}
              />
              <Column
                field={'version'}
                title={'Version'}
                columnMenu={ColumnMenu}
              />
              <Column
                field={'organisation'}
                title={'Organisation Name'}
                columnMenu={ColumnMenu}
              />
              <Column title={'Actions'} cell={onAddCustomAction} width={120} />
              <GridNoRecords>
                <NoRecords />
              </GridNoRecords>
            </Grid>
          )}
          {gridMode === 'card' && (
            <GamesManagementCardView
              data={libraries.data}
              total={libraries.total}
              {...dataState}
              onDelete={onDelete}
              onDataStateChange={(e: CardViewDataStateChangeEvent) => {
                setDataState(e.dataState);
              }}
            />
          )}
        </ExcelExport>

        {showDeleteConfirm && (
          <YesNoDialog
            title={'Remove Game'}
            onConfirm={onConfirmDelete}
            onClose={closeDeleteDialog}>
            Are you sure you want to delete this game from Global Library?
          </YesNoDialog>
        )}
      </div>
    </>
  );
};
export default GamesManagementGrid;
