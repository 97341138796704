import Button from 'react-bootstrap/Button';

type SupportTicketFormSubmitProps = {
  onClose?: () => void;
  onSave?: () => void;
};

const SupportTicketFormSubmit = ({
  ...props
}: SupportTicketFormSubmitProps) => {
  return (
    <div
      className={
        'w-100 py-3 border-top border-2 d-flex position-sticky bottom-0 bg-white'
      }>
      <div
        className={'col d-flex align-items-center justify-content-end gap-3'}>
        <Button
          variant={'secondary'}
          className={'w-14'}
          onClick={props?.onClose}>
          Cancel
        </Button>
        <Button
          variant={'success'}
          type={'submit'}
          form={'supportTicketForm'}
          className={'text-white w-14'}
          onClick={props?.onSave}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default SupportTicketFormSubmit;
