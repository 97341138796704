import { ComponentType, useState } from 'react';
import {
  Window,
  WindowActionsBar,
  WindowActionsEvent,
  WindowProps
} from '@progress/kendo-react-dialogs';
import { FluidForm } from '../../../components/forms';
import { Button } from '@progress/kendo-react-buttons';
import { MarkdownEditor } from '../../../components/markdown/markdown';
import { HtmlEditor } from '../../../components/editor/html-editor';
import { defaultMarkdownSerializer } from 'prosemirror-markdown';

interface ResourcePackHtmlEditorWindowProps extends WindowProps {
  text?: string;
  type?: 'Markdown' | 'Html';
  toggleDialog?: Function;
  isViewMode?: boolean;
  onSubmit: (text: string) => void;
  onClose?: (event?: WindowActionsEvent) => void;
}

export const ResourcePackHtmlEditorWindow: ComponentType<
  ResourcePackHtmlEditorWindowProps
> = ({
  text,
  type,
  toggleDialog,
  isViewMode,
  onSubmit,
  onClose = () => {},
  ...props
}: ResourcePackHtmlEditorWindowProps) => {
  const [textState, setTextState] = useState<string>(text ?? '');

  const onMarkdownChangeHandler = (content: string) => {
    setTextState(content!);
  };

  const onHtmlChangeHandler = (content: string) => {
    setTextState(content!);
  };

  return (
    <Window
      modal={true}
      minimizeButton={undefined}
      className={'shadow'}
      initialWidth={900}
      initialHeight={700}
      onClose={(e) => onClose(e)}
      {...props}>
      <FluidForm>
        {type === 'Markdown' && (
          <MarkdownEditor
            value={textState}
            handleMarkdownChange={(e) =>
              onMarkdownChangeHandler(
                // @ts-ignore expect mismatched types.
                defaultMarkdownSerializer.serialize(e.value)
              )
            }
          />
        )}
        {type === 'Html' && (
          <HtmlEditor
            value={textState}
            handleHtmlChange={(e) => onHtmlChangeHandler(e.html)}
          />
        )}
      </FluidForm>
      <WindowActionsBar>
        <Button
          themeColor={'secondary'}
          onClick={(e) => {
            onClose(e as any);
          }}>
          Cancel
        </Button>
        {!isViewMode && (
          <Button
            themeColor={'primary'}
            onClick={() => {
              onSubmit(textState);
            }}>
            Save
          </Button>
        )}
      </WindowActionsBar>
    </Window>
  );
};
