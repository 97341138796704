import React from 'react';
import { useNavigate } from 'react-router-dom';

export const Actions = () => {
  const navigate = useNavigate();
  return (
    <div className={'header__actions'}>
      {/* <button className={'btn btn-flat'}>
        <span className={'material-symbols-outlined'}>search</span>
      </button> */}
      <button
        className={'btn btn-flat'}
        onClick={() => navigate(`?showtype=user-notifications`)}>
        <span className={'material-symbols-outlined'}>notifications</span>
      </button>
    </div>
  );
};

export default Actions;
