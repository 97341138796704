import { GameDocument } from '../../../types/game-document';
import { DesignerSettings } from '../../../types/game-document/settings';
import { uuid } from '../../../types/common-helper';
import { MergeGameSettings } from './index';
import { ResourceEntity } from '../../../types/game-document/entities';
import {
  AddResourceAsync,
  AddResourceEntityAsync,
  GetResourceByName,
  UpdateResourceAsync
} from '../resources';
import {
  AllCorrectCustomFeedback,
  AllWrongCustomFeedback,
  MergeAssets
} from '../assets';
import { Task } from '../../../types/game-document/entities/task';

/**
 * Adds a new Setting to the Game document.
 * @param gameDocument - The Game Document to modify
 * @returns The updated Game Document
 */
export const AddDesignerSettingAsync = async (gameDocument: GameDocument) => {
  let updatedGameDocument = gameDocument;
  const designerSettingResource: ResourceEntity[] = [
    {
      id: uuid(),
      name: 'Designer Setting - default available task icon',
      description: "The game's default available task icon",
      value: 'https://cdn.catalystglobal.games/resources/map-task.png',
      type: 'text'
    },
    {
      id: uuid(),
      name: 'Designer Setting - default completed task icon',
      description: "The game's default completed task icon",
      value:
        'https://cdn.catalystglobal.games/resources/map-task--complete.png',
      type: 'text'
    },
    {
      id: uuid(),
      name: 'Designer Setting - default feedback all correct icon',
      description: "The game's default completed feedback all correct icon",
      value:
        'https://stsharedappstorage.blob.core.windows.net/prod-catalyst-files/checked.png',
      type: 'text'
    },
    {
      id: uuid(),
      name: 'Designer Setting - default feedback all wrong icon',
      description: "The game's default completed feedback all wrong icon",
      value:
        'https://stsharedappstorage.blob.core.windows.net/prod-catalyst-files/cross.png',
      type: 'text'
    }
  ];

  for (const res of designerSettingResource) {
    updatedGameDocument = await AddResourceEntityAsync(gameDocument, res);
  }

  const defaultAllCorrectFeedbackMessageResId =
    await DefaultAllCorrectFeedbackMessageResource(
      updatedGameDocument,
      'All correct feedback message default'
    );
  const defaultAllWrongFeedbackMessageResId =
    await DefaultAllWrongFeedbackMessageResource(
      updatedGameDocument,
      'All wrong feedback message default'
    );

  const designerSetting = {
    taskCompletionVisibility: false,
    defaultTaskAvailableIconResId: designerSettingResource[0].id,
    defaultTaskCompletedIconResId: designerSettingResource[1].id,
    defaultFeedbackAllCorrectIconResId: designerSettingResource[2].id,
    defaultFeedbackAllWrongIconResId: designerSettingResource[3].id,
    defaultAllCorrectFeedbackMessageResId,
    defaultAllWrongFeedbackMessageResId,
    shouldGivePlayerScore: false,
    shouldGiveTeamScore: false
  };

  return MergeGameSettings(updatedGameDocument, designerSetting, 'designer');
};

export const AddDesignerSettingOnLoadAsync = async (
  gameDocument: GameDocument
): Promise<GameDocument> => {
  let updatedGameDocument = await EnsureDesignerSetting(gameDocument);

  const tasks = updatedGameDocument.assets.tasks;

  if (tasks?.length) {
    tasks.forEach((item) => {
      AddActionToTask(updatedGameDocument, item);
    });

    return MergeAssets(updatedGameDocument, tasks, 'tasks');
  }

  return updatedGameDocument;
};

export const AddGiveScoreToAllTask = async (
  gameDocument: GameDocument,
  identifier: string | 'player' | 'team'
) => {
  let updatedGameDocument = gameDocument;
  const tasks = updatedGameDocument.assets.tasks;

  if (tasks?.length) {
    tasks.forEach((item) => {
      AddGiveScoreToTask(item, identifier);
    });

    return MergeAssets(updatedGameDocument, tasks, 'tasks');
  }

  return updatedGameDocument;
};

export const EnsureDesignerSetting = async (
  gameDocument: GameDocument
): Promise<GameDocument> => {
  if (!gameDocument.settings?.designer) {
    return AddDesignerSettingAsync(gameDocument);
  }
  return gameDocument;
};

export const AddActionToTask = (
  gameDocument: GameDocument,
  task: Task
): void => {
  const { designer } = gameDocument.settings!;

  if (designer?.shouldGivePlayerScore) {
    AddGiveScoreToTask(task, 'player');
  }

  if (designer?.shouldGiveTeamScore) {
    AddGiveScoreToTask(task, 'team');
  }
};

export const AddGiveScoreToTask = (
  item: Task,
  identifier: string | 'player' | 'team'
) => {
  // Ensure events object exists
  if (!item.events) {
    item.events = {};
  }

  // Ensure onCompleteTask exists
  if (!item.events.onCompleteTask) {
    item.events.onCompleteTask = { steps: [] };
  }

  // Ensure steps exist
  let steps = item.events.onCompleteTask.steps ?? [];

  // Check if 'giveScore(score)' already exists, if not, add it
  if (
    !steps.some(
      (step) =>
        step.operation === 'giveScore(score)' && step.identifier === identifier
    )
  ) {
    steps.push({
      id: uuid(),
      action: 'Task.onComplete(score)',
      identifier: identifier,
      operation: 'giveScore(score)',
      argumentAssetId: '',
      argumentQuantity: 0,
      useFallbackValue: true
    });
  }

  // Assign the updated steps array back to onCompleteTask
  item.events.onCompleteTask.steps = steps;
};

export const DefaultAllCorrectFeedbackMessageResource = async (
  gameDocument: GameDocument,
  message: string
) => {
  let allCorrectMessageResource = await GetResourceByName(
    gameDocument!,
    'All correct feedback message default'
  );

  const defaultAllCorrectFeedbackMessageResId = allCorrectMessageResource
    ? allCorrectMessageResource.id
    : uuid();

  if (!allCorrectMessageResource) {
    await AllCorrectCustomFeedback(
      gameDocument,
      message,
      defaultAllCorrectFeedbackMessageResId
    );
  }

  if (allCorrectMessageResource && !allCorrectMessageResource.value) {
    const updatedResource = { ...allCorrectMessageResource, value: message };
    await UpdateResourceAsync(
      gameDocument,
      allCorrectMessageResource.id,
      updatedResource
    );
  }

  if (!allCorrectMessageResource) {
    await AddResourceAsync(
      gameDocument,
      'All correct feedback message default',
      'All correct feedback message default',
      'text',
      message,
      defaultAllCorrectFeedbackMessageResId
    );
  }

  return defaultAllCorrectFeedbackMessageResId;
};

export const DefaultAllWrongFeedbackMessageResource = async (
  gameDocument: GameDocument,
  message: string
) => {
  let allWrongMessageResource = await GetResourceByName(
    gameDocument!,
    'All wrong feedback message default'
  );

  const defaultAllWrongFeedbackMessageResId = allWrongMessageResource
    ? allWrongMessageResource.id
    : uuid();

  if (!allWrongMessageResource) {
    await AllWrongCustomFeedback(
      gameDocument,
      message,
      defaultAllWrongFeedbackMessageResId
    );
  }

  if (allWrongMessageResource && !allWrongMessageResource.value) {
    const updatedResource = { ...allWrongMessageResource, value: message };
    await UpdateResourceAsync(
      gameDocument,
      allWrongMessageResource.id,
      updatedResource
    );
  }

  if (!allWrongMessageResource) {
    await AddResourceAsync(
      gameDocument,
      'All wrong feedback message default',
      'All wrong feedback message default',
      'text',
      message,
      defaultAllWrongFeedbackMessageResId
    );
  }

  return defaultAllWrongFeedbackMessageResId;
};

/**
 * Updates the identified Setting in the Game Document.
 * @param gameDocument - The Game Document to modify
 * @param designerSetting - The new designer settings
 * @constructor
 */
export const UpdateDesignerSettingAsync = async (
  gameDocument: GameDocument,
  designerSetting: DesignerSettings
) => {
  return MergeGameSettings(gameDocument, designerSetting, 'designer');
};
