import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Switch } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { PrivatePage } from '../../components/private-page';
import { AddUserRequest } from '../../types/user';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@progress/kendo-react-buttons';
import {
  GetUserAsync,
  PostUserAsync,
  PutUserAsync
} from '../../services/users';
import Toolbar from '../../components/page-toolbar';
import { isEmpty } from 'lodash';
import { PhoneNumberResponse } from '../../types/responses/phone-number-response';
import { AddressResponse } from '../../types/responses/address-response';
import {
  GetAllUserPhoneNumbersAsync,
  UpdateUserPhoneNumberAsync,
  PostUserPhoneNumberAsync
} from '../../services/user-phone-number';

import {
  GetAllUserAddressAsync,
  UpdateUserAddressAsync,
  PostUserAddressAsync
} from '../../services/user-address';
import { toastStore } from '../../stores/toast-store';
import { StandardInput, StandardTextarea } from '../../components/forms';
import { GetAllAsync } from '../../services/api';
import { PostEmailAvailabilityAsync } from '../../services/user-email';
import UploadFileDialog from '../../components/dialogs/upload-file-dialog';
import { FileExtension } from '../../types/fileExtensions';
import { Media } from '../../types/media';
import PopupMenu, { popupMenu as MenuList } from '../../components/popupMenu';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';

import CountryComboBox from '../../components/combobox/country-combobox';
import UserPermission from '../../components/permission';
import { Permission, PermissionType } from '../../types/permission';
import { useAuth } from 'react-oidc-context';
import { Roles } from '../../types/roles';
import {
  PostResellerUserRolesAsync,
  PutResellerUserRolesAsync
} from '../../services/roles';
import { appStore } from '../../stores/app-store';
import CountryCodeCombobox from '../../components/combobox/country-code-combobox';
import {
  GetUserDepartmentsAsync,
  GetUserProfileDepartmentsAsync,
  PutUserProfileDepartmentsAsync
} from '../../services/user-department';
import { Autocomplete } from '../../components/auto-complete';
import { UserDepartmentResponse } from '../../types/responses/user-department';
import cloneDeep from 'lodash.clonedeep';

export interface RequiredInput {
  id: string;
  name: string;
  errorMessage: string;
}

export default function UserPage() {
  const navigate = useNavigate();
  const auth = useAuth();
  const { id } = useParams();

  //if param is undefined or not contain any valid userId, it will be treated as new user page
  const isNewUser = !id;
  const userId = isNewUser ? 0 : parseInt(id!);

  const [activeTab, setActiveTab] = useState('info');
  const [menuList, setMenuList] = React.useState<MenuList[]>([]);
  const [openFileDialog, setOpenFileDialog] = useState<boolean>(false);
  const [logoSrc, setLogoSrc] = useState<
    string | ArrayBuffer | null | undefined
  >();
  const [userPermission, setUserPermission] = useState<string[]>();

  const departments = useRef<UserDepartmentResponse[]>();
  const [departmentData, setDepartmentData] = useState<
    UserDepartmentResponse[]
  >([]);
  const [userDepartments, setUserDepartments] = useState<
    UserDepartmentResponse[]
  >([]);

  // add User Request
  const [userRequest, setUserRequest] = useState<AddUserRequest>({
    id: 0,
    userId: 0,
    status: 'Enabled',
    firstName: '',
    lastName: '',
    organisationId: 0,
    preferredName: '',
    avatarMediaId: null,
    gender: 'N',
    countryIso: '',
    countryName: '',
    emailAddress: {
      type: 'Primary',
      address: '',
      isPrimary: true
    }
  });

  // add User Address Request
  const [userAddressRequest, setUserAddressRequest] = useState<AddressResponse>(
    {
      id: 0,
      type: 'Primary',
      line1: '',
      countryIso: ''
    }
  );

  const [requiredInputs, setRequiredInputs] = useState<RequiredInput[]>([
    {
      id: 'firstName',
      name: 'First Name',
      errorMessage: ''
    },
    {
      id: 'lastName',
      name: 'Last Name',
      errorMessage: ''
    },
    {
      id: 'userName',
      name: 'Username',
      errorMessage: ''
    }
  ]);

  const [userPhoneNumberRequest, setUserPhoneNumberRequest] =
    useState<PhoneNumberResponse>({
      type: 'Work',
      number: '',
      countryCode: '',
      countryIso: ''
    });

  const generateLogoMenu = () => {
    let newMenus: MenuList[] = [
      { classIcon: 'edit', textMenu: 'Edit', textClass: '' },
      { classIcon: 'delete', textMenu: 'Delete', textClass: 'text-danger' }
    ];

    setMenuList(newMenus);
  };

  const handleSelect = (key: string) => {
    setActiveTab(key);
  };

  const onPermissionChange = useCallback(
    (permission: string, value: boolean) => {
      if (value) {
        setUserPermission((prev) => [
          ...(prev as PermissionType[]),
          permission
        ]);
      } else {
        setUserPermission((prev) => prev?.filter((o) => o !== permission));
      }
    },
    []
  );

  const handleFilesSelected = (files?: Media[]) => {
    if (!files || files.length <= 0) {
      return;
    }
    const file = files[0];

    if (file.mediaId) {
      setUserRequest({
        ...userRequest,
        avatarMediaId: file.mediaId
      });
    }

    let fr = new FileReader();
    fr.readAsDataURL(file.file);
    fr.onload = function () {
      setLogoSrc(fr.result);
    };
    setOpenFileDialog(false);
  };

  const closeUploader = () => {
    setOpenFileDialog(false);
  };

  const handleMenuSelected = (id: number, menu: MenuList) => {
    if (menu.textMenu === 'Edit') {
      setOpenFileDialog(true);
    } else if (menu.textMenu === 'Delete') {
      setUserRequest({ ...userRequest, avatarMediaId: null });
      setLogoSrc('');
    }
  };

  const fetchUserDetails = async () => {
    // call API get user
    const userProfileResponse = await GetUserAsync(userId);
    setUserRequest({
      ...userRequest,
      id: userProfileResponse?.id!,
      userId: userProfileResponse?.userId!,
      emailAddress: {
        address: userProfileResponse?.username!
      },
      organisationId: userProfileResponse?.organisationId!,
      preferredName: userProfileResponse?.preferredName!,
      status: userProfileResponse?.status!,
      firstName: userProfileResponse?.firstName!,
      lastName: userProfileResponse?.lastName!,
      createdDateUtc: userProfileResponse?.createdDateUtc
    });

    //set user profile picture here
    setLogoSrc(userProfileResponse?.avatarMediaUrl!);

    // call API Get User Address
    GetAllUserAddressAsync(userId)
      .then((response) => {
        const mainAddress = response?.data.find((a) => a.type === 'Primary');

        if (mainAddress) {
          // Map to user address request
          setUserAddressRequest({
            ...userAddressRequest,
            id: mainAddress.id!,
            line1: mainAddress?.line1!,
            countryIso: mainAddress?.countryIso!,
            country: mainAddress?.country!
          });
        }
      })
      .catch((ex) => {
        console.error(ex);
      });

    GetAllUserPhoneNumbersAsync(userId)
      .then((response) => {
        const workPhoneNumber = response?.data.find((p) => p.type === 'Work');

        if (workPhoneNumber) {
          setUserPhoneNumberRequest({
            ...userPhoneNumberRequest,
            id: workPhoneNumber?.id!,
            number: workPhoneNumber?.number,
            countryCode: workPhoneNumber.countryCode
              ? `+${workPhoneNumber.countryCode}`
              : '',
            countryIso: workPhoneNumber.countryIso
          });
        }
      })
      .catch((ex) => {
        console.error(ex);
      });
  };

  const emailCheckValid = (value: string) => {
    const emailRegex: RegExp = new RegExp(/\S+@\S+\.\S+/);

    return emailRegex.test(value) ? '' : 'Please enter a valid email.';
  };

  useEffect(() => {
    async function getUserPermission() {
      try {
        const data = await GetAllAsync(`users/${id}/roles`);
        const permission = data.data as Roles[];
        setUserPermission(permission.map((o) => o.name));
      } catch (err) {
        console.error(err);
      }
    }
    generateLogoMenu();
    getUserPermission();
    if (!isNewUser) {
      fetchUserDetails();
      getDepartments();
    }
  }, []);

  const onBack = () => {
    navigate('/users');
  };

  const addRequestValidation = () => {
    const newState = requiredInputs.map((obj) => {
      if (obj.id === 'userName') {
        if (userRequest?.emailAddress?.address === '') {
          return { ...obj, errorMessage: 'This field is required.' };
        } else {
          let emailError = emailCheckValid(userRequest?.emailAddress?.address!);
          if (emailError !== '') {
            return {
              ...obj,
              errorMessage: emailError
            };
          }
        }
      } else if (
        obj.id === 'organisationId' &&
        userRequest?.organisationId === undefined
      ) {
        return { ...obj, errorMessage: 'This field is required.' };
      } else if (
        userRequest.hasOwnProperty(obj.id) &&
        userRequest[obj.id as keyof AddUserRequest] === ''
      ) {
        return { ...obj, errorMessage: 'This field is required.' };
      }
      return obj;
    });
    setRequiredInputs(newState);
    if (newState.filter((f) => f.errorMessage !== '').length > 0) {
      // if invalid stop all process
      return false;
    }
    return true;
  };

  const removeRequestValidation = (name: string) => {
    const updateRequestValidationState = requiredInputs.map((obj) => {
      if (obj.id === name) {
        return { ...obj, errorMessage: '' };
      }
      return obj;
    });
    setRequiredInputs(updateRequestValidationState);
  };

  const update = async () => {
    await PutUserAsync(userId, userRequest);
    // address request
    if (userAddressRequest?.id! > 0) {
      // patch
      await UpdateUserAddressAsync(userId, userAddressRequest);
    } else if (
      userAddressRequest.line1 !== '' ||
      userAddressRequest.countryIso !== ''
    ) {
      await PostUserAddressAsync(userId, userAddressRequest);
    }

    // Phone nummber request
    if (userPhoneNumberRequest?.id! > 0) {
      //patch phone number
      await UpdateUserPhoneNumberAsync(
        userId as number,
        userPhoneNumberRequest!
      );
    } else if (userPhoneNumberRequest.number !== '') {
      // Post
      await PostUserPhoneNumberAsync(userId as number, userPhoneNumberRequest!);
    }

    // Department update
    if (userDepartments) {
      const paramDepartments = userDepartments.map((i) => i.id!) ?? [];
      await PutUserProfileDepartmentsAsync(userId, paramDepartments);
    }

    await PutResellerUserRolesAsync(userRequest.id!, userPermission!);
    toastStore.show(
      'Users',
      <div>Details and permissions updated.</div>,
      'success'
    );
    navigate('/users');
  };

  const save = async () => {
    if (addRequestValidation()) {
      try {
        appStore.showLoading();
        if (!isNewUser) {
          // direct to update request
          await update();
          return;
        }

        const available = await PostEmailAvailabilityAsync({
          emailAddress: userRequest?.emailAddress?.address!
        });

        //POST User
        if (available) {
          const userResponse = await PostUserAsync(userRequest);
          if (userResponse) {
            if (
              userAddressRequest.line1 !== '' ||
              userAddressRequest.countryIso !== ''
            ) {
              await PostUserAddressAsync(userResponse.id!, userAddressRequest);
            }

            if (userPhoneNumberRequest.number !== '') {
              await PostUserPhoneNumberAsync(
                userResponse.id!,
                userPhoneNumberRequest
              );
            }

            if (!isEmpty(userPermission)) {
              await PostResellerUserRolesAsync(
                userResponse.id!,
                userPermission!.filter(
                  (permission, index) =>
                    permission.split('.')[0] !== Permission.Admin && index > 0
                )
              );
            }

            toastStore.show('Users', <div>User added.</div>, 'success');
            navigate('/users');
          }
        } else {
          toastStore.show(
            'Users',
            <div>Email is already registered.</div>,
            'error'
          );
        }
      } catch (err) {
        console.error(err);
      } finally {
        appStore.hideLoading();
      }
    }
  };

  const changeCountryHandler = (event: ComboBoxChangeEvent) => {
    setUserAddressRequest({
      ...userAddressRequest,
      countryIso: event.value?.iso ?? '',
      country: event.value?.country ?? ''
    });
  };

  const onChangeDepartmentEventHandler = (department: any) => {
    const exist = userDepartments.findIndex((x) => x.id === department.id);
    if (department && exist === -1) {
      let newUserDepartments = [...userDepartments];
      newUserDepartments.push({
        name: department.name ?? '',
        id: department.id ?? ''
      });
      setUserDepartments(newUserDepartments);
    }
  };

  const onDeleteDepartment = (department: UserDepartmentResponse) => {
    if (userDepartments) {
      const idx = userDepartments.indexOf(department);
      if (idx > -1 && userDepartments.length > 1) {
        let newData = cloneDeep(userDepartments);
        newData.splice(idx, 1);
        setUserDepartments(newData);
      } else if (userDepartments.length === 1) {
        setUserDepartments([]);
      }
    }
  };

  const getDepartments = async () => {
    // fetch new data
    GetUserDepartmentsAsync().then((res) => {
      departments.current = res;
      setDepartmentData(departments.current);
      fetchUserDepartments(userId);
    });
  };

  const fetchUserDepartments = async (userId: number) => {
    GetUserProfileDepartmentsAsync(userId).then((response) => {
      if (response) {
        if (departments.current) {
          const data = departments.current.filter((department) =>
            response.data.includes(department.id!)
          );
          setUserDepartments(data);
        } else {
          setUserDepartments([]);
        }
      }
    });
  };

  return (
    <PrivatePage
      breadcrumb={[
        {
          label: 'Dashboard',
          href: 'dashboard'
        },
        {
          label: 'Users',
          href: 'users'
        },
        {
          label: 'User details',
          href: 'users/:id'
        }
      ]}
      pageTitle={'User details'}>
      <>
        <Toolbar title={`${userRequest.firstName} ${userRequest.lastName}`}>
          <Button
            themeColor={'secondary'}
            className={'text-light mr-5'}
            onClick={onBack}>
            Back
          </Button>

          <Button themeColor={'primary'} onClick={save}>
            {isNewUser ? 'Save' : 'Update'}
          </Button>
        </Toolbar>
        <hr />
        <Row className={'h-60 w-full overflow-auto'}>
          <Col md={12}>
            <Tabs
              defaultActiveKey={'info'}
              id={'timer-tabs'}
              className={'mb-3'}
              onSelect={(key) => handleSelect(key as string)}>
              <Tab eventKey={'info'} title={'Info'}>
                <Row>
                  <Col xs={2}>
                    <div className={'d-flex'}>
                      {logoSrc && (
                        <div
                          onClick={() => setOpenFileDialog(true)}
                          className={'round-card rounded-full h-9 w-9'}
                          style={{
                            backgroundImage: `url('${logoSrc}')`
                          }}></div>
                      )}
                      {!logoSrc && (
                        <div
                          onClick={() => setOpenFileDialog(true)}
                          className={
                            'rounded-circle bg-primary d-flex justify-content-center align-items-center'
                          }
                          style={{ width: 150, height: 150 }}>
                          <i
                            className={'bi bi-person'}
                            style={{ fontSize: '5rem', color: 'white' }}
                          />
                        </div>
                      )}

                      <div>
                        <PopupMenu
                          id={userRequest?.avatarMediaId ?? 0}
                          menus={menuList}
                          onMenuSelected={handleMenuSelected}></PopupMenu>
                      </div>
                    </div>
                  </Col>
                  <Col xs={8}>
                    <div className={'mb-3'}>Information</div>
                    <div
                      className={
                        'card p-4 border-top-0 border-right-0 border-bottom-0'
                      }
                      style={{ borderRight: 'none' }}>
                      <Row className={'p-1'}>
                        <Col md={4}>
                          <StandardInput
                            label={'First Name'}
                            name={'First Name'}
                            value={userRequest?.firstName}
                            onChange={(e) => {
                              setUserRequest({
                                ...userRequest,
                                firstName: e.value
                              });
                              if (e.value !== '') {
                                removeRequestValidation('firstName');
                              }
                            }}
                            validationMessage={
                              requiredInputs?.find(
                                (item) => item.name === 'First Name'
                              )?.errorMessage
                            }
                          />
                        </Col>
                        <Col md={4}>
                          <StandardInput
                            label={'Last Name'}
                            name={'Last Name'}
                            value={userRequest?.lastName}
                            onChange={(e) => {
                              setUserRequest({
                                ...userRequest,
                                lastName: e.value
                              });
                              if (e.value !== '') {
                                removeRequestValidation('lastName');
                              }
                            }}
                            validationMessage={
                              requiredInputs?.find(
                                (item) => item.name === 'Last Name'
                              )?.errorMessage
                            }
                          />
                        </Col>
                      </Row>
                      <Row className={'p-1'}>
                        <Col md={8}>
                          <StandardInput
                            label={'Username (email)'}
                            name={'Username'}
                            disabled={true}
                            value={userRequest?.emailAddress?.address ?? ''}
                            onChange={(e) => {
                              setUserRequest((currentRequest) => {
                                return {
                                  ...currentRequest,
                                  emailAddress: {
                                    ...currentRequest.emailAddress,
                                    address: e.value
                                  }
                                };
                              });

                              if (e.value !== '') {
                                removeRequestValidation('userName');
                              }
                            }}
                            validationMessage={
                              requiredInputs?.find(
                                (item) => item.name === 'Username'
                              )?.errorMessage
                            }
                          />
                        </Col>
                      </Row>
                    </div>
                    <div className={'mt-4'}>Optional Information </div>
                    <div className={'card p-4'}>
                      <Row className={'p-1'}>
                        <Col md={4}>
                          <StandardInput
                            label={'Nickname'}
                            name={'Nickname'}
                            value={userRequest?.preferredName}
                            onChange={(e) => {
                              setUserRequest({
                                ...userRequest,
                                preferredName: e.value
                              });
                            }}
                          />
                        </Col>
                        <Col md={4}>
                          <Label>Country</Label>
                          <CountryComboBox
                            value={{
                              iso: userAddressRequest?.countryIso,
                              country: userAddressRequest?.country
                            }}
                            clearButton={false}
                            onChange={changeCountryHandler}
                          />
                        </Col>
                      </Row>
                      <Row className={'p-1'}>
                        <Col md={4}>
                          <Label>Phone Number</Label>
                          <div className="d-flex flex-column gap-2">
                            <CountryCodeCombobox
                              value={userPhoneNumberRequest?.countryCode ?? ''}
                              columns={[
                                {
                                  field: 'code',
                                  header: 'Code',
                                  width: '100px'
                                },
                                {
                                  field: 'countryIso',
                                  header: 'Iso',
                                  width: '50px'
                                },
                                {
                                  field: 'country',
                                  header: 'Country'
                                }
                              ]}
                              onChange={(e) => {
                                setUserPhoneNumberRequest({
                                  ...userPhoneNumberRequest,
                                  countryIso: e.value.countryIso,
                                  countryCode: e.value.code
                                });
                              }}
                            />

                            <StandardInput
                              name={'Phone Number'}
                              value={userPhoneNumberRequest?.number ?? ''}
                              onChange={(e) => {
                                setUserPhoneNumberRequest({
                                  ...userPhoneNumberRequest,
                                  number: e.value
                                });
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={8}>
                          <StandardTextarea
                            label="Address"
                            name="Address"
                            style={{
                              height: '83px'
                            }}
                            value={userAddressRequest?.line1 ?? ''}
                            onChange={(e) => {
                              setUserAddressRequest({
                                ...userAddressRequest,
                                line1: e.value
                              });
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <Label>Department</Label>
                          <Autocomplete<UserDepartmentResponse>
                            className={'d-flex justify-content-start w-100'}
                            items={departmentData ?? []}
                            textField="name"
                            onChange={
                              onChangeDepartmentEventHandler
                            }></Autocomplete>
                        </Col>
                        <Col md={8} className={'mt-2'}>
                          <ul className="list-group list-group-flush">
                            {userDepartments &&
                              userDepartments.map((department, index) => {
                                return (
                                  <li
                                    key={index}
                                    className="list-group-item d-flex justify-content-between">
                                    <span className={'text-primary'}>
                                      {department?.name ?? ''}
                                    </span>
                                    <span
                                      className={
                                        'material-symbols-outlined text-danger'
                                      }
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        onDeleteDepartment(department);
                                      }}>
                                      close
                                    </span>
                                  </li>
                                );
                              })}
                          </ul>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <Row className={'d-flex align-items-center mt-3'}>
                        <Col md={4}>
                          <div className={'mt-3'}>
                            <Label>Status</Label>
                          </div>
                          <div className={'text-primary'}>
                            <Switch
                              name={'Status'}
                              onChange={(e) => {
                                setUserRequest({
                                  ...userRequest,
                                  status: e.value ? 'Enabled' : 'Disabled'
                                });
                              }}
                              checked={
                                userRequest?.status === 'Enabled' ? true : false
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey={'permissions'} title={'Permissions'}>
                {activeTab === 'permissions' && (
                  <UserPermission
                    permissions={auth.user?.profile.role as PermissionType[]}
                    userPermissions={userPermission as PermissionType[]}
                    onChange={onPermissionChange}
                    showAdminPermission={false}
                  />
                )}
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </>
      {openFileDialog && (
        <UploadFileDialog
          isMultiple={false}
          isVisibleDropFile={true}
          isVisibleUploadPhoto={true}
          isVisibleTakePhoto={false}
          isDirectUpload={true}
          maxImageSize={1}
          allowedExtension={[FileExtension.JPG, FileExtension.PNG]}
          onFilesSelected={handleFilesSelected}
          onConfirm={() => {
            setOpenFileDialog(false);
          }}
          onClose={closeUploader}
          isIcon={true}
          title={'Upload user profile'}
        />
      )}
    </PrivatePage>
  );
}
