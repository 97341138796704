import React, { useEffect } from 'react';
import { GameDocument } from '../types/game-document';
import { useLocalStorage } from 'usehooks-ts';
import {
  GetGameDocumentContentAsync,
  GetGameDocumentLatestAsync
} from '../services/json-document';
import { appStore } from '../stores/app-store';

const GameDocumentContext = React.createContext<
  [
    initialState: GameDocumentState,
    setState: React.Dispatch<React.SetStateAction<GameDocumentState>>
  ]
>([{ isLoaded: false, isDirty: false, isValid: true }, () => {}]);

const GameDocumentProvider = (props: any) => {
  const [state, setState] = useLocalStorage<GameDocumentState>(
    `game-${props.gameId}`,
    {
      gameId: parseInt(props.gameId!),
      isLoaded: false,
      isDirty: false,
      isValid: true
    }
  );

  const loadDocumentContent = async () => {
    try {
      appStore.showLoading();
      let documentLatestResponse = await GetGameDocumentLatestAsync(
        state.gameId!
      );
      if (documentLatestResponse) {
        GetGameDocumentContentAsync(
          state.gameId!,
          documentLatestResponse.id!
        ).then((response) => {
          setState((state) => ({
            ...state,
            isLoaded: true,
            gameDocument: response
          }));
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      appStore.hideLoading();
    }
  };

  useEffect(() => {
    // check if state is not loaded
    if (!state.isLoaded) {
      loadDocumentContent();
    }
  }, []);

  return (
    <GameDocumentContext.Provider value={[state, setState]}>
      {props.children}
    </GameDocumentContext.Provider>
  );
};

export interface GameDocumentState {
  gameId?: number;
  gameDocument?: GameDocument;
  isLoaded?: boolean;
  isDirty: boolean;
  isValid: boolean;
}

export { GameDocumentContext, GameDocumentProvider };
