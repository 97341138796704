import { Geolocation, Place } from '../types/place';
import { GetAllAsync, GetAsync } from './api';
import { SearchMapResponse } from '../types/responses/map-response.d d';

interface SearchResult {
  predictions: Place[];
}

interface GeolocationResult {
  status: string;
  results: Geolocation[];
}

const MapEndpoint = `maps`;

export const SearchPlaceAsync = async (keyword: string) =>
  GetAllAsync<SearchMapResponse>(`${MapEndpoint}/SearchPlace?input=${keyword}`);

export const GetGeolocationAsync = async (placeId: string) =>
  GetAsync<GeolocationResult>(`${MapEndpoint}/GetPlace?input=${placeId}`);
