import * as React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { EditorUtils } from '@progress/kendo-react-editor';
import { InsertMediaDialog } from './insert-media-dialog';
import { capitalize } from 'lodash';
import * as svgIcons from '@progress/kendo-svg-icons';

interface MediaType {
  mediaType: 'image' | 'audio' | 'video';
}

type InsertMediaProps = MediaType & { [key: string]: any };

const icon = {
  image: svgIcons.imageAbsolutePositionIcon,
  audio: svgIcons.musicNotesIcon,
  video: svgIcons.youtubeIcon
};

export const InsertMedia: React.FC<InsertMediaProps> = ({
  mediaType,
  ...props
}) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const toggleDialog = () => setOpenDialog(!openDialog);
  const { view } = props;
  const state = view && view.state;
  const nodeType = state ? state.schema.nodes[mediaType] : undefined;

  return (
    <React.Fragment>
      <Button
        onClick={toggleDialog}
        disabled={
          !nodeType || !state || !EditorUtils.canInsert(state, nodeType)
        }
        onMouseDown={(e) => e.preventDefault()}
        onPointerDown={(e) => e.preventDefault()}
        title={`Insert ${capitalize(mediaType)}`}
        type={'button'}
        svgIcon={icon[mediaType]}
      />
      {openDialog && view && (
        <InsertMediaDialog
          view={view}
          onClose={toggleDialog}
          mediaType={mediaType}
          resizeMedia={props.resizeMedia}
        />
      )}
    </React.Fragment>
  );
};
