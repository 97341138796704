import { useEffect } from 'react';
import MylibraryGrid from '../features/mylibrary-grid';
import { PrivatePage } from '../components/private-page';
import { observer } from 'mobx-react';
import { ctGameDefault, ctGameTitle } from '../constants/text';

const Mylibrary = observer(() => {
  useEffect(() => {
    document.title = ctGameTitle + ' - My Library';
    return () => {
      document.title = ctGameDefault;
    };
  }, []);

  return (
    <PrivatePage
      breadcrumb={[
        { label: 'Dashboard', href: '../dashboard' },
        { label: 'My Library', href: '../mylibrary/games' },
        { label: 'Games', href: '../mylibrary/games' }
      ]}
      pageTitle={'My Library'}>
      <MylibraryGrid />
    </PrivatePage>
  );
});

export default Mylibrary;
