import { Button } from '@progress/kendo-react-buttons';
import { Label } from '@progress/kendo-react-labels';
import { Col, Row } from 'react-bootstrap';
import {
  ResendInviteUserAsync
} from '../../services/users';
import { toastStore } from '../../stores/toast-store';
import { appStore } from '../../stores/app-store';

type ResendRegistrationProps = {
  buttonText?: string;
  userId?: number;
  organisationId?: number;
  emailConfirmed?: boolean;
};

export const ResendRegistration = ({ ...props }: ResendRegistrationProps) => {

  const onResendRegistration = async () => {
    appStore.showLoading();
    Promise.all([
      ResendInviteUserAsync(props.organisationId!, props.userId!)
    ]).then((res) => {

      appStore.hideLoading();
      toastStore.show(
        'Resend Registration',
        <div>{res}</div>,
        'success'
      );
    }).catch((error) => {
      toastStore.show(
        'Resend Registration',
        <div>{error}</div>,
        'error'
      );
    });
  }


  return (
    <Row>
      <Col className="d-flex flex-column ">
        {
          props.emailConfirmed ?
            (<Label>User Registered</Label>) :
            (<>
              <Label>Resend Registration Email</Label>
              <Button
                themeColor={'primary'}
                style={{
                  width: 'fit-content'
                }}
                onClick={onResendRegistration}>

                <span>
                  {props.buttonText
                    ? props.buttonText
                    : 'Re-Send email invitation'}
                </span>

              </Button></>)
        }
      </Col>
    </Row>
  )
}