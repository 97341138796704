import { useContext, useMemo, useState } from 'react';
import cloneDeep from 'lodash.clonedeep';
import { Slider, SliderLabel } from '@progress/kendo-react-inputs';
import CoverImage from '../../../components/cover-image';
import PopupMenu from '../../../components/popupMenu';
import { MapEntity } from '../../../types/game-document/';
import {
  AddResourceAsync,
  GetResourceValue,
  UpdateGameDocState
} from '../../../utils/game-document';
import { uuid } from '../../../types/common-helper';
import { GameDocumentContext } from '../../../contexts/game-document';
import {
  ResourceWindow,
  UploadedImage
} from '../image-resource/resource-window';
import { GameDocument } from '../../../types/game-document';
import { MapContext } from '../../../components/map/map-context';

export interface MapImageOverlayEditorProps {
  mapInfo?: MapEntity;
}

export interface MenuList {
  classIcon?: string;
  textMenu?: string;
  textClass?: string;
}

export const MapImageOverlayEditor = ({
  mapInfo
}: MapImageOverlayEditorProps) => {
  const [state, setState] = useContext(GameDocumentContext);
  const map = useContext(MapContext);
  const [dialogUploadVisible, setDialogUploadVisible] =
    useState<boolean>(false);

  const selectedMapEntity = useMemo(() => {
    return state.gameDocument?.assets.maps?.find(
      (map) => map.id === mapInfo?.id
    );
  }, [state.gameDocument, mapInfo?.id]);

  const imageValue = useMemo(() => {
    if (state.gameDocument) {
      return GetResourceValue(
        state.gameDocument,
        selectedMapEntity?.imageOverlay?.imageResId ?? ''
      );
    }
    return '';
  }, [state.gameDocument, selectedMapEntity?.imageOverlay?.imageResId]);

  const opacityValue = useMemo(() => {
    return (selectedMapEntity?.imageOverlay?.opacity ?? 1) * 100;
  }, [selectedMapEntity?.imageOverlay?.opacity]);

  const taskMenu: MenuList[] = [
    { classIcon: 'edit', textMenu: 'Edit', textClass: '' },
    { classIcon: 'delete', textMenu: 'Delete', textClass: 'text-danger' }
  ];

  const handleOpacityChange = (value: number) => {
    const updatedGameDocument = cloneDeep(state.gameDocument);
    if (updatedGameDocument?.assets.maps) {
      const currentMapId = updatedGameDocument.assets.maps.findIndex(
        (map) => map.id === mapInfo?.id
      );
      if (currentMapId !== -1) {
        const newMapEntity = {
          ...updatedGameDocument.assets.maps[currentMapId],
          imageOverlay: {
            ...updatedGameDocument.assets.maps[currentMapId].imageOverlay!,
            opacity: value / 100
          }
        };
        updatedGameDocument.assets.maps.splice(
          currentMapId,
          1,
          newMapEntity as MapEntity
        );
        setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
      }
    }
  };

  const handleImageSelected =
    (field: string) => (id: number, menu: MenuList) => {
      if (field === 'availableImage' && menu.textMenu === 'Edit') {
        setDialogUploadVisible(true);
      } else if (field === 'availableImage' && menu.textMenu === 'Delete') {
        const imageLayer = map
          .getAllLayers()
          .find((layer) => layer.get('id') === 'gpsOverlay');
        const vectorLayer = map
          .getAllLayers()
          .find((layer) => layer.get('id') === 'gpsOverlayVector');

        imageLayer && map.removeLayer(imageLayer);
        vectorLayer && map.removeLayer(vectorLayer);

        if (state.gameDocument) {
          const updatedGameDocument = cloneDeep(state.gameDocument);
          if (updatedGameDocument?.assets.maps) {
            const currentMapId = updatedGameDocument.assets.maps.findIndex(
              (map) => map.id === mapInfo?.id
            );
            if (currentMapId !== -1) {
              const newMapEntity: MapEntity = {
                ...updatedGameDocument.assets.maps[currentMapId],
                imageOverlay: {
                  imageResId: ''
                }
              };
              updatedGameDocument.assets.maps.splice(
                currentMapId,
                1,
                newMapEntity as MapEntity
              );
              setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
            }
          }
        }
      }
    };

  const updateMapResource = async (newImage: UploadedImage) => {
    setDialogUploadVisible(false);
    if (state.gameDocument && map) {
      const view = map.getView().getCenter();
      const addImageOverlay = (
        gameDocument: GameDocument,
        imageResId: string
      ) => {
        const updatedGameDocument = cloneDeep(gameDocument);
        if (updatedGameDocument?.assets.maps) {
          const currentMapId = updatedGameDocument.assets.maps.findIndex(
            (map) => map.id === mapInfo?.id
          );
          if (currentMapId !== -1) {
            const newMapEntity: MapEntity = {
              ...updatedGameDocument.assets.maps[currentMapId],
              imageOverlay: {
                ...selectedMapEntity?.imageOverlay,
                scale: [100, 100],
                center: (view as [number, number]) ?? [0, 0],
                imageResId
              }
            };
            updatedGameDocument.assets.maps.splice(
              currentMapId,
              1,
              newMapEntity as MapEntity
            );
            setState((prev) => UpdateGameDocState(prev, updatedGameDocument));
          }
        }
      };

      if (newImage.resource) {
        addImageOverlay(state.gameDocument, newImage.resource.id as string);
        return;
      }

      const resourceId = uuid();
      AddResourceAsync(
        state.gameDocument!,
        newImage.fileName ?? '',
        newImage.fileName ?? '',
        'image',
        newImage.blobUrl,
        resourceId,
        newImage.size
      ).then((response) => {
        addImageOverlay(response, resourceId);
      });
    }
  };

  return (
    <>
      <div className="col col-12">
        <label>Map Image</label>
        <CoverImage
          containerStyle={'square'}
          imageUrl={imageValue}
          style={{ width: '100%' }}>
          <div className={'mr-3 mt-1'}>
            <PopupMenu
              id={0}
              menus={taskMenu}
              onMenuSelected={handleImageSelected('availableImage')}
            />
          </div>
        </CoverImage>
      </div>
      {selectedMapEntity?.imageOverlay?.imageResId && (
        <div className="col col-12 mb-4">
          <div className={'d-flex flex-column mt-2'}>
            <label>Opacity</label>
            <Slider
              className={'w-100'}
              buttons={true}
              step={25}
              min={0}
              max={100}
              value={opacityValue}
              onChange={(e) => handleOpacityChange(e.value)}>
              {[0, 25, 50, 75, 100].map((perc, i) => (
                <SliderLabel
                  key={i}
                  position={perc}
                  onClick={() => {
                    handleOpacityChange(perc);
                  }}>
                  {perc.toString()}
                </SliderLabel>
              ))}
            </Slider>
          </div>
        </div>
      )}

      {dialogUploadVisible && (
        <ResourceWindow
          onClose={() => {
            setDialogUploadVisible(false);
          }}
          onSubmit={updateMapResource}
          toggleDialog={() => setDialogUploadVisible(false)}
        />
      )}
    </>
  );
};
