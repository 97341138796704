import { useEffect, useRef, useState } from 'react';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridNoRecords
} from '@progress/kendo-react-grid';
import { DataResult, State } from '@progress/kendo-data-query';
import { ColumnMenu } from '../components/columnMenu';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  ExcelExport,
  ExcelExportColumn
} from '@progress/kendo-react-excel-export';
import { Button } from '@progress/kendo-react-buttons';
import {
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../components/grid/grid-tool-bar';
import Toolbar from '../components/page-toolbar';
import { DefaultGridSettings } from '../constants/grid-settings';
import { NoRecords } from '../components/grid/no-records';
import { CardViewDataStateChangeEvent } from './allusers-cardview';
import { LinkCell } from '../components/grid';
import { AllUsersCardView } from './allusers-cardview';
import {
  GetGridAllUsersAsync,
  GetReportsAllUsersAsync
} from '../services/allusers';
import { appStore } from '../stores/app-store';

const initialDataState: State = {
  sort: [{ field: 'username', dir: 'asc' }],
  ...DefaultGridSettings.initialDataState
};

type GridMode = 'grid' | 'card';

const AllUserGrid = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [dataState, setDataState] = useState<State>({
    ...initialDataState,
    ...(params.get('isNewUser') === 'true' && {
      filter: {
        logic: 'and',
        filters: [
          {
            field: 'emailConfirmed',
            operator: 'eq',
            value: false
          }
        ]
      }
    })
  });
  const [gridMode, setGridMode] = useState<GridMode>('grid');
  const [users, setUsers] = useState<DataResult>({
    data: [],
    total: 0
  });

  useEffect(() => {
    async function fetchUsers() {
      try {
        const response = await GetGridAllUsersAsync(dataState);
        setUsers(response);
      } catch (ex) {
        console.error(ex);
      }
    }
    fetchUsers();
  }, [dataState]);

  const LinkedNameCell = (props: GridCellProps) => (
    <LinkCell to={props.dataItem['id'].toString()} {...props}></LinkCell>
  );

  const onStatusColoring = (props: GridCellProps) => (
    <StatusColoring {...props} />
  );

  const StatusColoring = (props: GridCellProps) => {
    if (props.dataItem.status === 'Enabled') {
      return (
        <td>
          <span className={'text-success'}>{props.dataItem.status}</span>
        </td>
      );
    } else {
      return (
        <td>
          <span className={'text-muted'}>{props.dataItem.status}</span>
        </td>
      );
    }
  };

  const onRegistrationColoring = (props: GridCellProps) => {
    if (props.dataItem.emailConfirmed) {
      return (
        <td>
          <span className={'text-success'}>Registered</span>
        </td>
      );
    } else {
      return (
        <td>
          <span className={'text-muted'}>Not registered</span>
        </td>
      );
    }
  };

  const onAddUser = () => {
    navigate('/administration/users/invite-user');
  };

  const gridExportRef = useRef<ExcelExport | null>(null);

  const onExportExcel = async () => {
    appStore.showLoading();
    try {
      await GetReportsAllUsersAsync().then((response) => {
        if (gridExportRef.current !== null) {
          let mappedResponse = response.data.map((item) => ({
            ...item,
            registration: item.emailConfirmed ? 'Registered' : 'Not registered'
          }));
          gridExportRef.current.save(mappedResponse);
        }
      });
    } catch (error) {
      console.error(error);
    } finally {
      appStore.hideLoading();
    }
  };

  return (
    <>
      <Toolbar title={'All users'}>
        <Button onClick={onAddUser} themeColor={'primary'}>
          Add new
        </Button>
      </Toolbar>
      <div className={'pt-2'}>
        <GridToolBar
          searchPlaceholder={'Search users'}
          columnsToSearch={['username', 'fullName']}
          showCardMode={true}
          exportRef={gridExportRef}
          useCustomExportExcel={true}
          handleCustomExportToExcel={onExportExcel}
          onGridModeChange={(e) => {
            setGridMode(e.gridMode as GridMode);
          }}
          {...dataState}
          onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
            setDataState(e.dataState);
          }}></GridToolBar>
        <ExcelExport ref={gridExportRef} fileName={`allusers.xlsx`}>
          <ExcelExportColumn field="username" title="Username" width={250} />
          <ExcelExportColumn field={'fullName'} title={'Name'} width={250} />
          <ExcelExportColumn
            field="organisation"
            title="Organisation"
            width={270}
          />
          <ExcelExportColumn field="country" title="Country" width={200} />
          <ExcelExportColumn field="phoneNumber" title="Phone" width={200} />
          <ExcelExportColumn
            field="department"
            title="Department"
            width={250}
          />
          <ExcelExportColumn field={'status'} title={'Status'} width={250} />
          <ExcelExportColumn
            field={'registration'}
            title={'Registration'}
            width={250}
          />
        </ExcelExport>
        {gridMode === 'grid' && (
          <Grid
            id={'grid-user'}
            pageable={DefaultGridSettings.pagerSettings}
            sortable={true}
            data={users}
            {...dataState}
            onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
              setDataState(e.dataState);
            }}>
            <Column
              field={'username'}
              filter={'text'}
              title={'Username'}
              columnMenu={ColumnMenu}
              cell={LinkedNameCell}
            />
            <Column field={'fullName'} title={'Name'} columnMenu={ColumnMenu} />
            <Column
              field={'organisation'}
              title={'Organisation'}
              columnMenu={ColumnMenu}
            />
            <Column
              field={'country'}
              title={'Country'}
              columnMenu={ColumnMenu}
            />
            <Column
              field={'phoneNumber'}
              title={'Phone'}
              columnMenu={ColumnMenu}
            />
            <Column
              field={'department'}
              title={'Department'}
              columnMenu={ColumnMenu}
            />
            <Column
              field={'status'}
              title={'Status'}
              columnMenu={ColumnMenu}
              cell={onStatusColoring}
            />
            <Column
              field={'emailConfirmed'}
              title={'Registration'}
              columnMenu={ColumnMenu}
              cell={onRegistrationColoring}
            />
            <GridNoRecords>
              <NoRecords />
            </GridNoRecords>
          </Grid>
        )}
        {gridMode === 'card' && (
          <AllUsersCardView
            data={users.data}
            onUsernameClick={(id: number) =>
              navigate(`/administration/users/${id}`)
            }
            total={users.total}
            {...dataState}
            onDataStateChange={(e: CardViewDataStateChangeEvent) => {
              setDataState(e.dataState);
            }}
          />
        )}
      </div>
    </>
  );
};
export default AllUserGrid;
