import { useEffect, useState } from 'react';
import { State } from '@progress/kendo-data-query';
import PopupMenu, { popupMenu as MenuList } from '../components/popupMenu';
import { PageChangeEvent, Pager } from '@progress/kendo-react-data-tools';
import {
  DefaultGridSettings,
  DefaultPagerSettings
} from '../constants/grid-settings';
import { Feedback } from '../types/feedback';
import { Assessment } from '../types/assessment';

export interface CardViewDataStateChangeEvent {
  dataState: State;
}

export interface FeedbackCardViewProps extends State {
  data?: Feedback[];
  onTitlelick?: (id: number) => void;
  onCopy?: (feedback: Feedback) => void;
  onView?: (id: number) => void;
  onEdit?: (id: number) => void;
  onCreateEvent?: (id: number) => void;
  onDataStateChange?: (event: CardViewDataStateChangeEvent) => void;
  total?: number;
}
export const GlobalFeedbackCardView = ({
  data,
  onTitlelick,
  onCopy,
  onView,
  onCreateEvent,
  total = 0,
  ...props
}: FeedbackCardViewProps) => {
  const [menuList, setMenuList] = useState<MenuList[]>([]);

  const handleTitleClick = (id: number) => {
    if (onTitlelick) {
      onTitlelick(id);
    }
  };

  const generatePopupMenu = () => {
    let newMenus: MenuList[] = [
      { classIcon: 'visibility', textMenu: 'View', textClass: '' },
      { classIcon: 'content_copy', textMenu: 'Copy', textClass: '' }
    ];

    setMenuList(newMenus);
  };

  const onMenuSelected = (
    id: number,
    menu: MenuList,
    idString?: string,
    assessment?: Assessment,
    data?: Feedback
  ) => {
    if (!id) return;

    if (menu.textMenu === 'Copy') {
      if (onCopy && data) {
        onCopy(data);
      }
    }
    if (menu.textMenu === 'View') {
      if (onView) {
        onView(id);
      }
    }
    if (menu.textMenu === 'Create Event') {
      if (onCreateEvent) {
        onCreateEvent(id);
      }
    }
  };

  const handlePageChange = (event: PageChangeEvent) => {
    raiseDataStateChangeEvent({ skip: event.skip, take: event.take });
  };

  /**
   * Raise the Grid DataState change event. I.e. filters have changed.
   * @param moreData
   */
  const raiseDataStateChangeEvent = (moreData: State) => {
    props.onDataStateChange?.({
      dataState: { ...getDataState(), ...moreData }
    });
  };

  const getDataState = () => {
    return {
      filter: props.filter,
      sort: props.sort,
      skip: props.skip,
      take:
        props.take !== undefined ? props.take : DefaultGridSettings.pageSize,
      group: props.group
    };
  };

  useEffect(() => {
    generatePopupMenu();
  }, []);

  return (
    <div className={'mt-3'}>
      <div className={'row grid-card-library'}>
        {data &&
          data.map((lib, index) => {
            return (
              <div
                className={'col-xs-12 col-sm-12 col-md-6 d-flex'}
                key={index}>
                <div className={'card mt-4 card-view card-library flex-grow-1'}>
                  <div className={'row p-3'}>
                    <div className={'col-3'}>
                      <div
                        className={`avatar ${
                          Boolean(lib.logoUrl) ? 'bg-white' : 'bg-primary'
                        }`}>
                        {Boolean(lib.logoUrl) ? (
                          <img
                            className={'w-100 h-100 rounded-full'}
                            src={lib.logoUrl}
                            alt="logo"
                          />
                        ) : (
                          <i className={'bi bi-person icon'} />
                        )}
                      </div>
                    </div>
                    <div className={'col-9'}>
                      <div className={'card-body-right pt-2 pb-2 pr-2 pl-4'}>
                        <div
                          className={
                            'd-flex justify-content-between align-items-center'
                          }>
                          <h3
                            className={'title link-primary cursor-pointer'}
                            onClick={() => handleTitleClick(lib.id!)}
                            role={'button'}>
                            {' '}
                            {lib?.name}
                          </h3>
                          <PopupMenu
                            menus={menuList}
                            id={lib.id!}
                            data={lib as Feedback}
                            onMenuSelected={onMenuSelected}></PopupMenu>
                        </div>
                        <hr className={'mt-0'} />

                        <div className={'d-flex flex-wrap'}>
                          <div> Creator : </div>
                          <div className="text-success">
                            {'\u00A0' + (lib.createdBy ? lib.createdBy : '')}
                          </div>
                        </div>
                        <div className={'d-flex flex-wrap'}>
                          <div> Status : </div>
                        </div>
                        <div className={'d-flex flex-wrap'}>
                          <div> Source : </div>
                          <div className="ml-1 text-success"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <div className={'row mt-3'}>
        <div className={'col-md-12'}>
          <Pager
            onPageChange={handlePageChange}
            total={total}
            take={
              props.take !== undefined
                ? props.take
                : DefaultGridSettings.pageSize
            }
            skip={props.skip !== undefined ? props.skip : 0}
            {...DefaultPagerSettings}
          />
        </div>
      </div>
    </div>
  );
};
