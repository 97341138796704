import {
  DesignerSettings,
  InGameSettings,
  PreGameSettings
} from '../../../types/game-document/settings';
import { GameDocument } from '../../../types/game-document';
import { merge } from 'lodash';

/**
 * Combine assessments with the current game document.
 * @param gameDocument - The Game Document to modify
 * @param settings - The settings to combine
 * @param settingList - The name of the asset list to replace.
 */
export const MergeGameSettings = (
  gameDocument: GameDocument,
  settings: DesignerSettings | PreGameSettings | InGameSettings,
  settingList: 'designer' | 'preGame' | 'inGame'
) =>
  merge(gameDocument, {
    settings: { [settingList]: settings }
  });

export * from './pre-game';
export * from './in-game';
export * from './designer';
