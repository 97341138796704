import { useEffect } from 'react';
import { PrivatePage } from '../components/private-page';
import { ctGameDefault, ctGameTitle } from '../constants/text';
import ReportsGrid from '../features/reports-grid';

const Reports = () => {
  useEffect(() => {
    document.title = ctGameTitle + '- Reports';
    return () => {
      document.title = ctGameDefault;
    };
  }, []);
  return (
    <PrivatePage
      pageTitle={'Reports'}
      breadcrumb={[
        {
          label: 'Dashboard',
          href: 'dashboard'
        },
        {
          label: 'Reports',
          href: 'reports'
        }
      ]}>
      <ReportsGrid />
    </PrivatePage>
  );
};

export default Reports;
